import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Divider,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { NavCustomizationSetting } from '../../../common/constants/NavCustomizationConstant';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import {
    getSetting,
    ISetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import {
    accountGlobalId,
    accountType,
} from '../../../store/selectors';
import {
    mapNavCustomizationDataToKeyValuePairs,
    mapSettingArrayToNavCustomizationData,
} from '../../../util/setting/NavCustomizationSettingUtil';
import UiForm from '../../common/UiForm';
import NavCustomizationComponent, {
    defaultNavCustomizationData,
    INavCustomizationData,
} from './navigationCustomization/NavCustomizationComponent';
import OrganizationDelete from './OrganizationDelete';
import OrganizationMigration from './OrganizationMigration';
import UserLicensingSettingsComponent from './UserLicensingSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        advancedSettingsContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        advancedSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        advancedSettings: { maxWidth: '518px' },
        advancedSettingsRevamp: { width: '100%' },
        divider: { color: theme.palette.semantic.colorBorderDeEmp },
        section: { marginTop: '24px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginLeft: '10px' },
    }),
);

export const AdvancedSettingsTabComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableOrgMigration = useFeatureFlagValue(Features.EnableOrgMigration.name);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const userAccountType = useSelector(accountType);
    const isEnterpriseOrTrial = useMemo(() => AccountLicense[userAccountType] <= AccountLicense.TRIAL, [ userAccountType ]);

    const isUserCommunityAccountType = useMemo(() => AccountLicense[userAccountType] === AccountLicense.COMMUNITY, [ userAccountType ]);

    const showDeleteOrg = useMemo(() =>
        !process.buildConfigs.disableOrganizationSettingsOrganizationDelete && !DisableFeatureFedRamp &&
        AccountLicense[userAccountType] > AccountLicense.ENTERPRISE,
    [ DisableFeatureFedRamp, userAccountType ]);

    const methods = useForm<INavCustomizationData>({
        mode: 'onSubmit',
        defaultValues: defaultNavCustomizationData,
    });

    const {
        reset, handleSubmit, formState,
    } = methods;
    const {
        isDirty, isSubmitting,
    } = formState;

    const partitionGlobalId = useSelector(accountGlobalId);
    const keys = useMemo(() => [ NavCustomizationSetting.AppsHidden, NavCustomizationSetting.ResourceCenterHidden ], []);
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: INavCustomizationData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapNavCustomizationDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ createNotification, mutate, partitionGlobalId, translate ],
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToNavCustomizationData(fetchedSettings));
        }
    }, [ reset, fetchedSettings ]);

    const formContents = useMemo(() => (
        <div className={clsx({ [classes.advancedSettingsContainer]: isAdminRevampEnabled })}>
            <div
                className={clsx(!isAdminRevampEnabled ? classes.advancedSettings : classes.advancedSettingsRevamp)}
                data-cy="advanced-settings-page"
                style={isAdminRevampEnabled ? { paddingTop: '20px' } : undefined}>
                {!isAdminRevampEnabled && <Typography
                    className={classes.advancedSettingsTitle}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_ADVANCED_SETTINGS' })}
                </Typography>}
                <NavCustomizationComponent />
                {!isUserCommunityAccountType && <UserLicensingSettingsComponent />}
                {isEnterpriseOrTrial && EnableOrgMigration && !DisableFeatureFedRamp && <OrganizationMigration />}

                {showDeleteOrg && (
                    <>
                        <Divider
                            className={classes.divider}
                            style={{ visibility: isAdminRevampEnabled ? 'hidden' : 'visible' }} />
                        <OrganizationDelete />
                    </>
                )}
            </div>
        </div>
    ), [
        DisableFeatureFedRamp,
        EnableOrgMigration,
        classes.advancedSettings,
        classes.advancedSettingsContainer,
        classes.advancedSettingsRevamp,
        classes.advancedSettingsTitle,
        classes.divider,
        isAdminRevampEnabled,
        isEnterpriseOrTrial,
        isUserCommunityAccountType,
        showDeleteOrg,
        translate,
    ]);

    return isAdminRevampEnabled
        ? (
            <UiForm
                centerChild={true}
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    isDirty && <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => {
                                handleCancel();
                            }}
                            color="primary"
                            data-cy="nav-customization-cancel-button"
                        >
                            {translate({ id: 'CLIENT_DISCARD' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="nav-customization-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE_CHANGES' })}
                        </UiProgressButton>
                    </div>
                }
                heightOffset="0px"
            >
                <FormProvider {...methods}>
                    {formContents}
                </FormProvider>
            </UiForm>
        )
        : formContents;
};
