import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { isString } from 'lodash';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { deleteDirectoryIntegrationSetting } from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';

const useStyles = makeStyles((theme) =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
        cancelButton: { marginRight: '10px' },
        deleteSSOConfirmation: { maxWidth: '466px' },
    }),
);

const DeleteSSOConfigurationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, refreshCallback,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const [ loading, setLoading ] = useState(false);
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const deleteAndClose = useCallback(async () => {
        try {
            setLoading(true);
            await deleteDirectoryIntegrationSetting(partitionGlobalId);
            closeDialog();
            setLoading(false);
            refreshCallback();
        } catch (error) {
            setLoading(false);
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
        }
    }, [ closeDialog, getErrorMessage, getErrorObject, partitionGlobalId, refreshCallback, setErrorMessage ]);

    return (
        <div className={classes.deleteSSOConfirmation}>
            <Typography data-cy="delete-sso-warning">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_1_REVAMP' })}
            </Typography>
            <br />
            <Typography data-cy="delete-sso-ask">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_2' })}
            </Typography>

            <div className={classes.buttonContainer}>
                <Button
                    className={classes.cancelButton}
                    color="primary"
                    data-cy="delete-sso-cancel-button"
                    onClick={() => closeDialog()}
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiDeleteButton
                    loading={loading}
                    variant="contained"
                    data-cy="delete-sso-confirmation-button"
                    onClick={() => deleteAndClose()}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiDeleteButton>
            </div>
        </div>
    );
};

export default DeleteSSOConfigurationDialogBody;
