import React from 'react';
import { useIntl } from 'react-intl';
import { renderRoutes } from 'react-router-config';
import { useRouteMatch } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SecuritySettingsTabs from './subcomponents/SecuritySettingsTabs';

const SecuritySettingsPageComponent: React.FC = () => {

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const organizationName = useOrganizationName();
    const isIPRestriction = useRouteMatch(RouteNames.IPRestriction);

    const { formatMessage: translate } = useIntl();

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                position={isIPRestriction ? 'left' : 'center'}>
                <SecuritySettingsTabs />
            </UiPageContainer> :
            <UiPanel
                licenseInGracePeriodBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={!process.buildConfigs.disableUserLicensing && <UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={!process.buildConfigs.disableUserLicensing && <UiOverAllocationBanner />}
                header={{ title: translate({ id: 'CLIENT_SECURITY_SETTINGS' }) }}
                data-cy="security-settings-panel"
            >
                <SecuritySettingsTabs />
            </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <SecuritySettingsPageComponent />
        {renderRoutes(route.routes)}
    </>
);
