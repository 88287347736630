import GlobalStyles from '@experiences/theme';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { AboutAccountsLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAADEnabled } from '../../../common/hooks/useCheckAADEnabled';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import UiAlertBanner from '../../common/UiAlertBanner';

const useStyles = makeStyles(theme => GlobalStyles(theme));

const UsersPageAADBanner: FC = () => {
    const classes = useStyles();
    const isAADConnectionEnabled = useCheckAADEnabled();
    const isAADForm = useRouteMatch(RouteNames.AuthSettings);
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    return (
        <>
            {isAADConnectionEnabled && !isAADForm && (
                <UiAlertBanner
                    type="info"
                    closeable={false}>
                    {translate({ id: 'CLIENT_AZURE_USERS_PAGE_BANNER' })}
                    <a
                        className={classes.a}
                        target="_blank"
                        rel="noreferrer"
                        href={getLocalizedLink(AboutAccountsLink)}
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginLeft: '8px',
                        }}
                    >
                        {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                        <LaunchIcon style={{
                            fontSize: '14px',
                            marginLeft: '4px',
                        }} />
                    </a>
                </UiAlertBanner>
            )}
        </>
    );
};

export default UsersPageAADBanner;
