import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { isHostModeSelector } from '../../../store/selectors';
import ExternalProvidersComponent from '../../authsettings/subcomponents/ExternalProvidersComponent.default';
import ExternalProvidersComponentRevamp from '../../authsettings/subcomponents/ExternalProvidersComponentRevamp';
import EncryptionComponent from './Encryption/EncryptionComponent';
import IPRestrictionComponent from './IPRestriction/IPRestrictionComponent';
import SessionPolicyComponent from './SessionPolicyComponent';

const SecuritySettingsTab = {
    AUTHENTICATION: 'authenticationsettings',
    SESSION_POLICY: 'sessionpolicy',
    IP_RESTRICTION: 'iprestriction',
    ENCRYPTION: 'encryption',
};

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();
    switch (value) {
        case SecuritySettingsTab.AUTHENTICATION:
            return isSecuritySettingsRevampEnabled
                ? <ExternalProvidersComponentRevamp /> : <ExternalProvidersComponent />;
        case SecuritySettingsTab.SESSION_POLICY:
            return <SessionPolicyComponent />;
        case SecuritySettingsTab.IP_RESTRICTION:
            return <IPRestrictionComponent />;
        case SecuritySettingsTab.ENCRYPTION:
            return <EncryptionComponent />;
    }

    return null;
};

const SecuritySettingsTabs: React.FC = () => {

    const { formatMessage: translate } = useIntl();
    const {
        isOffersRevampAndCommunity, isUserEnterprise,
    } = useCheckLicense();

    const isHostMode = useSelector(isHostModeSelector);

    const EnableSecuritySettingsSessionPolicy = useFeatureFlagValue(Features.EnableSecuritySettingsSessionPolicy.name);
    const EnableSecuritySettingsIPRestriction = useFeatureFlagValue(Features.EnableSecuritySettingsIPRestriction.name);
    const EnableSecuritySettingsEncryption = useFeatureFlagValue(Features.EnableSecuritySettingsEncryption.name);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const route = useRouteMatch();
    const isOnPrem = useIsOnPrem();

    let currentTab = SecuritySettingsTab.AUTHENTICATION;
    const isActive = route.path;
    switch (isActive) {
        case RouteNames.SessionPolicy:
            currentTab = SecuritySettingsTab.SESSION_POLICY;
            break;
        case RouteNames.IPRestriction:
            currentTab = SecuritySettingsTab.IP_RESTRICTION;
            break;
        case RouteNames.Encryption:
            currentTab = SecuritySettingsTab.ENCRYPTION;
            break;
    }

    const [ tabValue, setTabValue ] = useState(currentTab);

    const handleChange = useCallback((_, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={
                    isAdminRevampEnabled
                        ? {
                            width: '100%',
                            alignItems: 'center',
                            marginBottom: '32px',
                        }
                        : undefined
                }>
                <Tab
                    value={SecuritySettingsTab.AUTHENTICATION}
                    label={translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS' })}
                    onClick={() => history.push(getRoute(RouteNames.SecuritySettings))}
                    data-cy="auth-settings-tab"
                    className="default"
                />
                {!isHostMode && EnableSecuritySettingsIPRestriction && (isUserEnterprise || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingsTab.IP_RESTRICTION}
                        label={translate({ id: 'CLIENT_IP_RESTRICTION' })}
                        onClick={() => history.push(getRoute(RouteNames.IPRestriction))}
                        data-cy="ip-restrictions-tab"
                        className="default"
                    />
                )}
                {EnableSecuritySettingsSessionPolicy && (isUserEnterprise || isOnPrem || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingsTab.SESSION_POLICY}
                        label={translate({ id: 'CLIENT_SESSION_POLICY' })}
                        onClick={() => history.push(getRoute(RouteNames.SessionPolicy))}
                        data-cy="session-policy-tab"
                        className="default"
                    />
                )}
                {!isHostMode && EnableSecuritySettingsEncryption && isUserEnterprise && (
                    <Tab
                        value={SecuritySettingsTab.ENCRYPTION}
                        label={translate({ id: 'CLIENT_ENCRYPTION_CERTIFICATE_LOCATION_USAGE' })}
                        onClick={() => history.push(getRoute(RouteNames.Encryption))}
                        data-cy="encryption-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default SecuritySettingsTabs;
