import { getFullName } from '@experiences/util';
import {
    Avatar,
    Divider,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useCheckAADEnabled } from '../../common/hooks/useCheckAADEnabled';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import {
    accountPictureUrlSelector,
    emailSelector,
    firstNameSelector,
    isAdminSelector,
    lastNameSelector,
} from '../../store/selectors';
import ProfileOrganizationComponent from './subcomponents/ProfileOrganizationComponent';
import ProfilePreferencesComponent from './subcomponents/ProfilePreferencesComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: { marginBottom: '20px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        subHeadingArea: { paddingBottom: '20px' },
        infoLabel: { width: '110px' },
        infoRow: {
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            paddingBottom: '20px',
        },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
    }),
);

const ProfileGeneralSettingsComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const accountPictureUrl = useSelector(accountPictureUrlSelector);
    const email = useSelector(emailSelector);
    const firstName = useSelector(firstNameSelector);
    const lastName = useSelector(lastNameSelector);
    const isAdmin = useSelector(isAdminSelector);

    const isAAD = useCheckAADEnabled();
    const isSSO = useCheckSSOEnabled();

    return (
        <div>
            <Typography
                className={clsx(classes.heading, classes.mainHeading)}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_GENERAL' })}
            </Typography>
            <div className={classes.heading}>
                <Typography
                    className={classes.heading}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_PROFILE' })}
                </Typography>
                {isAAD && !isAdmin && (
                    <Typography data-cy="profile-page-managed-profile-description">
                        {translate({ id: 'CLIENT_PROFILE_DESCRIPTION' })}
                    </Typography>
                )}
            </div>
            {accountPictureUrl && (
                <div className={classes.infoRow}>
                    <Typography className={classes.infoLabel}>
                        {translate({ id: 'CLIENT_IMAGE' })}
                    </Typography>
                    <Avatar
                        data-cy="profile-page-picture"
                        src={accountPictureUrl} />
                </div>
            )}
            <div className={classes.infoRow}>
                <Typography className={classes.infoLabel}>
                    {translate({ id: 'CLIENT_NAME' })}
                </Typography>
                <Typography data-cy="profile-page-name">
                    {getFullName(firstName, lastName) || translate({ id: 'CLIENT_SYSTEM_ADMINISTRATOR' })}
                </Typography>
            </div>
            {email && (
                <div className={classes.infoRow}>
                    <Typography className={classes.infoLabel}>
                        {translate({ id: 'CLIENT_EMAIL' })}
                    </Typography>
                    <Typography data-cy="profile-page-email">
                        {email}
                    </Typography>
                </div>
            )}
            <Divider className={classes.divider} />
            <ProfilePreferencesComponent />
            {!process.buildConfigs.disableUsersLeaveOrg && !isSSO &&
                <>
                    <Divider className={classes.divider} />
                    <ProfileOrganizationComponent />
                </>}
        </div>
    );
};

export default ProfileGeneralSettingsComponent;
