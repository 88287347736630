import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    CircularProgress,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    Link as RouterLink,
    useHistory,
} from 'react-router-dom';
import { Row } from 'react-table';
import useSWR, { mutate } from 'swr';

import { IPRestrictionLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import {
    IIPRestriction,
    IIPRestrictionStatus,
} from '../../../../common/interfaces/iprestriction';
import { getIpConfigurationStatus } from '../../../../services/access-policy/IPConfigurationStatusService';
import {
    getCurrentIp,
    getIpRangeByOrganizationId,
    ipRangeUrl,
} from '../../../../services/access-policy/IPRangeService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../../store/selectors';
import UiAlertBanner from '../../../common/UiAlertBanner';
import { UiGrid } from '../../../common/UiGrid';
import {
    ButtonType,
    GridActionType,
    IAction,
    IActionHeader,
} from '../../../common/UiGrid/grid';
import UpgradeForFeature from '../../../common/UpgradeForFeature';
import IPRestrictionConfirmationDialogBody from './IPRestrictionConfirmationDialogBody';
import IPRestrictionDeleteDialogBody from './IPRestrictionDeleteDialogBody';
import {
    checkIPv4InRange,
    checkIPv6InRange,
} from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        addIPRangeButton: { marginLeft: '5px' },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        centerLoader: { margin: 'auto' },
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },
        enforcementTypeSelect: { width: '240px' },
        IPRestrictionDescription: {
            marginTop: '25px',
            marginBottom: '25px',
        },
        label: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            marginRight: '24px',
        },
        link: { marginLeft: '8px' },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '24px',
        },
    }),
);

export const ipStatusKey = '/api/accessPolicy/ipRangeStatus';
export const ipCurrentKey = '/api/accessPolicy/ip';

export const IPRestrictionComponent: React.FC = () => {

    const classes = useStyles();
    const EnableSecuritySettingsIPRestrictionAudit = useFeatureFlagValue(Features.EnableSecuritySettingsIPRestrictionAudit.name);
    const history = useHistory();
    const createDialog = useShowDialog();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();

    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const { formatMessage: translate } = useIntl();
    const { isOffersRevampAndCommunity } = useCheckLicense();

    const [ ipStatus, setIpStatus ] = useState<string>(EnforcementType.DISABLED);

    const {
        data: ipRanges, isValidating: isValidatingIpRanges, error: ipRangesError,
    } = useSWR<IIPRestriction[], Error>(
        [ ipRangeUrl, partitionGlobalId ],
        getIpRangeByOrganizationId,
    );

    const {
        data: ipStatusData, isValidating: isValidatingStatus, error: ipStatusError,
    } = useSWR<IIPRestrictionStatus, Error>(
        [ ipStatusKey, partitionGlobalId ],
        getIpConfigurationStatus,
    );

    const { data: currentUserIp } = useSWR<string, Error>([ ipCurrentKey ], getCurrentIp);

    const inIpRange = useMemo(() => {
        if (!currentUserIp || !ipRanges) {
            return false;
        }
        for (const ipRange of ipRanges) {
            if (checkIPv4InRange(currentUserIp, ipRange.startIp, ipRange.endIp)
                || checkIPv6InRange(currentUserIp, ipRange.startIp, ipRange.endIp)) {
                return true;
            }
        }
        return false;

    }, [ currentUserIp, ipRanges ]);

    const refreshGrid = useCallback(() => {
        mutate([ ipRangeUrl, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const refreshRadioGroup = useCallback(() => {
        mutate([ ipStatusKey, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const ipConfigStatus = useMemo(
        () => ({
            [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_ENABLED',
            [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_DISABLED',
            [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_AUDIT',
        }),
        [],
    );

    const handleAddIPRange = useCallback(() => {
        history.push({
            pathname: `${getRoute(RouteNames.IPRestriction)}/add`,
            state: { currentIp: currentUserIp },
        });
    }, [ currentUserIp, getRoute, history ]);

    const clickedDelete = useCallback(
        async (row: Row<IIPRestriction>) => {
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: row?.original,
                    ipStatus: ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, currentUserIp, ipStatus, refreshGrid, translate ],
    );

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const addTenant: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_IP_RANGE' }),
            icon: <AddIcon />,
            click: handleAddIPRange,
            disable: false,
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.addIPRangeButton,
            dataCy: 'add-ip-restriction-button',
        };
        return [ addTenant ];
    }, [
        isAdmin,
        translate,
        classes.addIPRangeButton,
        handleAddIPRange,
    ]);

    useEffect(() => {
        if (ipStatusData) {
            setIpStatus(ipStatusData.status ?? EnforcementType.DISABLED);
        }
    }, [ ipStatusData, ipStatusError, setIpStatus ]);

    const gridData = useMemo(() => {
        return ipRanges?.map(obj => {
            if (obj.startIp?.toLowerCase() !== obj.endIp?.toLowerCase()) {
                obj = Object.assign({ ipRange: obj.startIp + ' - ' + obj.endIp }, obj);
            } else {
                obj = Object.assign({ ipRange: obj.startIp }, obj);
            }
            return obj;
        });
    }, [ ipRanges ]);

    const rowActions = useMemo(() => {
        const editIPRange: IAction<IIPRestriction> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_EDIT' }),
            tooltip: translate({ id: 'CLIENT_EDIT' }),
            actionType: GridActionType.Row,
            icon: <EditIcon />,
            click: row => {
                const ipRange: IIPRestriction = row.original;
                history.push({
                    pathname: `${getRoute(RouteNames.IPRestriction)}/edit/${row.original.id}`,
                    state: { ipRange: ipRange },
                });
            },
            dataCy: 'edit-ip-restriction-button',
        };

        const deleteIPRange: IAction<IIPRestriction> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_DELETE' }),
            tooltip: translate({ id: 'CLIENT_DELETE' }),
            actionType: GridActionType.Row,
            icon: <DeleteIcon />,
            click: clickedDelete,
            dataCy: 'delete-ip-restriction-button',
        };
        return isAdmin ? [ editIPRange, deleteIPRange ] : [];
    }, [ clickedDelete, getRoute, history, isAdmin, translate ]);

    const dialogTitle = useMemo(() => ({
        [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_DISABLE',
        [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_ENABLE',
        [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_AUDIT',
    }), []);

    const handleChange = useCallback(async (e, value) => {
        await createDialog({
            title: translate({ id: dialogTitle[value as EnforcementType] }),
            customDialogContent: IPRestrictionConfirmationDialogBody,
            customDialogContentProps: {
                inIpRange: inIpRange,
                ipStatus: value,
                refreshCallback: (newVal: React.SetStateAction<string>) => {
                    setIpStatus(newVal);
                    refreshRadioGroup();
                },
            },
            icon: 'info',
        });
    }, [ createDialog, dialogTitle, inIpRange, refreshRadioGroup, translate ]);

    const options = useMemo(() => {
        const opt = [
            {
                label: translate({ id: ipConfigStatus.Disabled }),
                value: EnforcementType.DISABLED,
                dataCy: 'disable',
            },
            {
                label: translate({ id: ipConfigStatus.Enabled }),
                value: EnforcementType.ENABLED,
                dataCy: 'enable',
            },
        ];
        if (EnableSecuritySettingsIPRestrictionAudit) {
            opt.push({
                label: translate({ id: ipConfigStatus.Audit }),
                value: EnforcementType.AUDIT,
                dataCy: 'audit',
            });
        }
        return opt;
    }, [ EnableSecuritySettingsIPRestrictionAudit, ipConfigStatus.Audit, ipConfigStatus.Disabled, ipConfigStatus.Enabled, translate ]);

    const auditBanner = useMemo(() => {
        return (
            <UiAlertBanner
                type="info"
                closeable={false}
                enterpriseTrialAlertBar={true}>
                <div className={classes.banner}>
                    {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_BANNER_MESSAGE' })}
                    <RouterLink
                        className={classes.link}
                        data-cy="ip-restriction-banner-audit-log-link"
                        onClick={() => {}}
                        to={getRoute(RouteNames.AuditLogs)}
                    >
                        {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_LINK' })}
                    </RouterLink>
                </div>
            </UiAlertBanner>);
    }
    , [ classes.banner, classes.link, getRoute, translate ]);

    return (
        isOffersRevampAndCommunity ?
            <UpgradeForFeature
                upgradeTitle={translate({ id: 'CLIENT_UPGRADE_IP_TITLE' })}
                upgradeMessage={translate({ id: 'CLIENT_UPGRADE_IP_DESCRIPTION' })}
                documentationLink={getLocalizedLink(IPRestrictionLink)}
                level={AccountLicense.ENTERPRISE} />
            :
            <div className={classes.mainContent}>

                {ipStatus === EnforcementType.AUDIT && auditBanner}
                <div className={classes.container}>

                    <div className={classes.leftContainer}>
                        <Typography className={classes.IPRestrictionDescription}>
                            {translate({ id: 'CLIENT_IP_RESTRICTION_DESCRIPTION' })}
                        </Typography>
                        <UiGrid<IIPRestriction>
                            data={gridData ?? []}
                            dataCy="ip-restriction-ui-grid"
                            error={ipRangesError}
                            loading={isValidatingIpRanges}
                            columns={[
                                {
                                    accessor: 'name',
                                    Header: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                    width: 30,
                                },
                                {
                                    accessor: 'startIp',
                                    Header: translate({ id: 'CLIENT_IP_RANGE_START' }),
                                    width: 50,
                                },
                                {
                                    accessor: 'endIp',
                                    Header: translate({ id: 'CLIENT_IP_RANGE_END' }),
                                    width: 50,
                                },
                            ]}
                            pagination
                            filters={true}
                            search={true}
                            searchPlaceholder={translate({ id: 'CLIENT_SEARCH_IP_RANGE_TEXT' })}
                            extraActionButtons={extraActionHeaderButtons}
                            rowActions={rowActions}
                            wrapHeader={true}
                        />
                    </div>
                    <Divider
                        orientation='vertical'
                        flexItem />

                    <div className={classes.rightContainer}>
                        <Typography
                            id="enforcement-type"
                            className={classes.label}>
                            {translate({ id: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE' })}
                        </Typography>
                        {!isValidatingStatus ?
                            <RadioGroup
                                aria-labelledby="enforcement-type"
                                onChange={handleChange}
                                value={ipStatus}
                            >
                                {options.map((option, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label}
                                        data-cy={`ip-restriction-radio-${option.dataCy}`}
                                    />
                                ))}
                            </RadioGroup> :
                            <div className={classes.centerLoader}>
                                <CircularProgress />
                            </div>}
                    </div>
                </div>
            </div>
    );
};

export default IPRestrictionComponent;
