import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import {
    Button,
    CircularProgress,
    FormControlLabel,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../services/identity/SettingService';
import {
    accountGlobalId,
    userGlobalId,
} from '../../store/selectors';
import {
    daysToSeconds,
    secondsToDays,
} from '../../util/DateUtil';
import UiForm from '../common/UiForm';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        personalAccessTokenContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),

);

interface IExternalAppsPATData {
    enableRefToken: boolean;
    maxLifetime: string;
}

const ExternalApplicationsPATPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const [ loading, setLoading ] = useState(false);
    const [ loadSettings, setLoadSettings ] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        formState: {
            isDirty, dirtyFields,
        },
    } = useForm<IExternalAppsPATData>({
        mode: 'onChange',
        defaultValues: {
            enableRefToken: false,
            maxLifetime: '',
        },
    });

    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const createDialog = useShowDialog();

    const getDefaultSettings = useCallback(async () => {
        setLoadSettings(true);
        const result = await getSetting(settingUrl, [ 'ReferenceTokensEnabled', 'ReferenceTokensLifetimeMax' ], partitionGlobalId, userId);
        const getSettingWithKeyEnabled = !!result?.length && result.find(setting => setting.key === 'ReferenceTokensEnabled');
        const getSettingWithKeyLifetimeMax = !!result?.length && result.find(setting => setting.key === 'ReferenceTokensLifetimeMax');

        reset({
            enableRefToken: getSettingWithKeyEnabled ? getSettingWithKeyEnabled.value === 'true' : false,
            maxLifetime: getSettingWithKeyLifetimeMax ? secondsToDays(parseInt(getSettingWithKeyLifetimeMax.value)) : '365',
        });

        setLoadSettings(false);

    }, [ partitionGlobalId, reset, userId ]);

    useEffect(() => {
        (async () => {
            getDefaultSettings();

        })();
    }, [ getDefaultSettings, partitionGlobalId, reset, userId ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            setValue(name, checked, { shouldDirty: true });
            callback(checked);
        },
        [ setValue ],
    );

    const onSubmit = useCallback(async (data: IExternalAppsPATData) => {
        setLoading(true);
        const enableToken = data.enableRefToken;

        if (dirtyFields.enableRefToken) {
            if (!data.enableRefToken) {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_DISABLE_PERSONAL_ACCESS_TOKEN' }),
                    body: <Typography data-cy="disable-token-message">
                        {translate({ id: 'CLIENT_DISABLE_TOKEN_MESSAGE' })}
                    </Typography>,
                    icon: 'warning',
                    primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
                    showCancel: true,
                });

                if (!proceed) {
                    reset();
                    setLoading(false);
                    return;
                }
            }
        }

        try {
            await saveSetting(settingUrl, {
                settings: [
                    {
                        key: 'ReferenceTokensEnabled',
                        value: String(enableToken),
                    },
                    {
                        key: 'ReferenceTokensLifetimeMax',
                        value: daysToSeconds(parseInt(data.maxLifetime)),
                    },
                ],
                partitionGlobalId,
                userId,
            });

            reset({
                enableRefToken: enableToken,
                maxLifetime: data.maxLifetime,
            });
        } catch (error) {
            const response = await getErrorMessage(error);
            setErrorMessage(response);
        } finally {
            setLoading(false);
        }

    },
    [ createDialog, dirtyFields.enableRefToken, getErrorMessage, partitionGlobalId, reset, setErrorMessage, translate, userId ]);

    return loadSettings ? (
        <div className={classes.loader}>
            <CircularProgress />
        </div>
    ) : (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.cancelButton}
                        onClick={() => reset()}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                        variant='contained'
                        disabled={!isDirty}
                        data-cy='token-settings-save-button'>
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
        >
            <div className={classes.personalAccessTokenContainer}>
                <Typography className={classes.inputMargin}>
                    {translate({ id: 'CLIENT_MANAGE_PERSONAL_ACCESS_TOKEN' })}
                </Typography>
                <Controller
                    name="enableRefToken"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.value}
                                    onChange={e => handleSwitch('enableRefToken', e.target.checked, props.onChange)}
                                    data-cy="enable-pat-switch"
                                />
                            }
                            label={
                                <Typography>
                                    {translate({ id: 'CLIENT_ENABLE_PERSONAL_ACCESS_TOKEN' })}
                                </Typography>
                            }
                        />
                    )}
                />
                <Typography
                    style={{ marginTop: '12px' }}
                    className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_MAXIMUM_LIFESPAN' })}
                </Typography>
                <TextField
                    name="maxLifetime"
                    type="number"
                    variant="outlined"
                    sx={{ width: '30ch' }}
                    inputRef={register({ required: true })}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            days
                        </InputAdornment>,
                        inputProps: {
                            min: 1,
                            max: 1800,
                        },
                    }}
                    data-cy="token-maximum-lifetime"
                />
            </div>
        </UiForm>
    );

};

export default ExternalApplicationsPATPageComponent;
