import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

/**
 * JIRA: OR-19788
 * Due to a misconfigured service worker, we are caching the html file, the css files
 * and a bunch of other files. However, we are not caching the main js bundle. Due to this
 * we are getting into a state where the html file (and CSS files) are mismatched with the
 * main js file. The code below is an attempt to detect the mismatch and refresh the page if
 * this is the case.
 */
export default function checkHtmlJSMismatch() {
    const metaTag = document.querySelector('meta[name="ui-version"]');
    const htmlCommitId = (metaTag as any)?.content as string;
    const jsCommitId = process.env.NX_REACT_APP_GIT_SHA;

    const isLocal = window.location.origin.includes('localhost');

    if (!isLocal && htmlCommitId !== jsCommitId) {
        const reloadedPreviously =
      localStorage.getItem(`reloadBecauseOfHtmlJSMismatch-${window.location.pathname}`) === 'true';

        if (reloadedPreviously) {
            const message = 'Mismatch detected again. This is out of the ordinary. Skipping reloading.';
            console.log(message);
            portalTelemetry.trackTrace(
                {
                    message,
                    severityLevel: SeverityLevel.Error,
                },
                { eventName: 'htmlJsMismatch' },
            );
        } else {
            const message = 'Html and JS version mismatch. Reloading after 1 second.';
            console.log(message);
            portalTelemetry.trackTrace(
                {
                    message,
                    severityLevel: SeverityLevel.Information,
                },
                { eventName: 'htmlJsMismatch' },
            );

            setTimeout(() => {
                localStorage.setItem(`reloadBecauseOfHtmlJSMismatch-${window.location.pathname}`, 'true');
                window.location.reload();
            }, 1000);
        }
    }
}
