import { AnnouncementsState } from '../reducer/AnnouncementReducer';

export const announcementsList = (state: { announcements: AnnouncementsState }) => state.announcements.announcementList;

export const announcementsCount = (state: { announcements: AnnouncementsState }) =>
    state.announcements.announcementList.length;

export const unReadAnnouncementCount = (state: { announcements: AnnouncementsState }) => {
    return state.announcements.announcementList.filter(announcement => !announcement.read).length ?? 0;
};
