import { Token } from '@experiences/interfaces';
import React from 'react';

export interface IAuthContext {
    token: string;
    logout: (returnUrl?: string) => void;
    claims?: Token;
    auth0AccessToken?: string; // intended for Auth0 Management API
}

// standardize authentication object so it can be accessible from anywhere
// and implemented by different providers based on path
export const AuthContext = React.createContext<IAuthContext>({
    token: '',
    logout: () => {},
});

export const useAuthContext = () => {
    return React.useContext(AuthContext);
};
