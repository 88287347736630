import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import EditNavBarIconFormComponent from './EditNavBarIconFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        appsIcon: { stroke: theme.palette.semantic.colorForeground },
        description: {
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        grid: {
            marginTop: '16px',
            marginBottom: '16px',
            width: '100%',
        },
        icon: {
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
            color: theme.palette.semantic.colorForeground,
        },
        title: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        tableHeaderCell: {
            minWidth: '100%',
            height: '60px',
        },
        tableHeaderCellRevamp: {
            minWidth: '75%',
            height: '60px',
        },
        tableHeaderCellAction: {
            minWidth: '25%',
            height: '60px',
        },
    }),
);

const NavCustomizationForm: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <>
            <Typography className={classes.title}>
                {translate({ id: 'CLIENT_UI_CUSTOMIZATION' })}
            </Typography>
            <Typography className={classes.description}>
                {translate({ id: 'CLIENT_NAVIGATION_CUSTOMIZATION_DESCRIPTION' })}
            </Typography>
            <TableContainer className={classes.grid}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={clsx({
                                [classes.tableHeaderCell]: !isAdminRevampEnabled,
                                [classes.tableHeaderCellRevamp]: isAdminRevampEnabled,
                            })}>
                                <Typography>
                                    {translate({ id: 'CLIENT_NAVIGATION_BAR_ICONS' })}
                                </Typography>
                            </TableCell>
                            {isAdminRevampEnabled && <TableCell
                                className={classes.tableHeaderCellAction}
                                align='center'>
                                <Typography>
                                    {translate({ id: 'CLIENT_HIDE_SHOW' })}
                                </Typography>
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <EditNavBarIconFormComponent
                            id="apps"
                            textId="CLIENT_APPS"
                            icon={
                                <div className={classes.icon}>
                                    <portal-custom-icon name="apps" />
                                </div>
                            }
                        />
                        <EditNavBarIconFormComponent
                            id="resourceCenter"
                            textId="CLIENT_RESOURCE_CENTER"
                            icon={<HelpOutlineIcon className={classes.icon} />}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default NavCustomizationForm;
