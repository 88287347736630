import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    ITenant,
    ITenantService,
} from '../../common/interfaces/tenant/tenant';
import {
    deleteTenant,
    getTenants,
    getTenantsV2,
    IServiceError,
    IServiceStatusMap,
    setServiceStatus,
    setTenantStatus,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../store/selectors';
import { useServiceDependency } from './subcomponents/helpers/useServiceDependency';
import {
    serviceOrder,
    TenantStatusConstants,
} from './TenantConstants';

interface ITenantsContext {
    data: ITenantService[];
    tenantsWithoutServices: ITenant[];
    selectedTenant: ITenantDetails;
    error?: Error;
    isValidating: boolean;
    // eslint-disable-next-line no-unused-vars
    changeTenantStatus: (tenant: ITenant, status: string) => Promise<IServiceError[] | undefined>;
    // eslint-disable-next-line no-unused-vars
    changeServiceStatus: (tenant: ITenant, services: IServiceStatusMap) => Promise<IServiceError[] | undefined>;
    // eslint-disable-next-line no-unused-vars
    removeTenant: (tenant: ITenant) => void;
    // eslint-disable-next-line no-unused-vars
    changeSelectedTenant: (tenantDetails: ITenantDetails) => void;
    getTenantData: (tenantId: string) => ITenant | undefined;
}

export interface ITenantResponse {
    totalCount: number;
    results: ITenantService[];
}

export interface ITenantDetails {
    id: string;
    name: string;
    selectedTenantId: string;
}

export const serviceInstanceUrl = '/api/tenant/tenantservices';

const defaultSelectedTenant = {
    id: '',
    name: '',
    selectedTenantId: '',
};

export const TenantsContext = createContext<ITenantsContext>({
    data: [],
    tenantsWithoutServices: [],
    selectedTenant: defaultSelectedTenant,
    isValidating: false,
    changeTenantStatus: (_tenant: ITenant, _status: string) => Promise.resolve(undefined),
    changeServiceStatus: (_tenant: ITenant, _services: IServiceStatusMap) => Promise.resolve(undefined),
    removeTenant: (_tenant: ITenant) => {},
    changeSelectedTenant: (_tenantDetails: ITenantDetails) => {},
    getTenantData: (_tenantId: string) => undefined,
});

export const useTenantsContext = () => {
    return useContext(TenantsContext);
};

function isDefined<T>(argument: T | undefined): argument is T {
    return argument !== undefined;
}

export const TenantsContextProvider: React.FC = props => {
    const {
        safeParse, parseErrorObject,
    } = useGetErrorInfo();

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);

    const accountName = useSelector(accountLogicalName);
    const accountGUID = useSelector(accountGlobalId);

    const {
        data,
        error: tenantsError,
        isValidating,
    } = useSWR<ITenant[], Error>(
        !EnableGetTenantsRedesign
            ? [ serviceInstanceUrl, accountGUID, accountName, true ]
            : [ serviceInstanceUrl, accountGUID, accountName, 'All', undefined, undefined, true ],
        !EnableGetTenantsRedesign ? getTenants : getTenantsV2,
    );

    const { servicesToHide } = useServiceDependency();

    const tenantsAndServices = useMemo(() => {
        return data && Array.isArray(data)
            ? data
                ?.map(tenant => {
                    const tenantsWithAllowedServices = tenant.tenantServiceInstances?.filter(
                        s => servicesToHide.indexOf(s.serviceType) < 0,
                    );

                    return tenantsWithAllowedServices.length > 0
                        ? tenantsWithAllowedServices.map<ITenantService>(service => {
                            return {
                                ...service,
                                tenant,
                            };
                        })
                        : ({ tenant } as ITenantService);
                })
                ?.flat()
                ?.filter(isDefined)
                .sort(
                    (serviceA, serviceB) =>
                        serviceOrder.indexOf(serviceA.serviceType) - serviceOrder.indexOf(serviceB.serviceType),
                )
            : [];
    }, [ data, servicesToHide ]);

    const [ selectedTenant, setSelectedTenant ] = useState(defaultSelectedTenant);

    const changeTenantStatus = useCallback(
        async (tenant: ITenant, status: string) => {
            try {
                return await setTenantStatus(tenant.id, status === TenantStatusConstants.ENABLE);
            } catch (error) {
                throw await parseErrorObject(error);
            }
        },
        [ parseErrorObject ],
    );

    const changeServiceStatus = useCallback(
        async (tenant: ITenant, services: IServiceStatusMap) => {
            try {
                return await setServiceStatus(accountGUID, tenant.id, services);
            } catch (error) {
                const parsedError = await parseErrorObject(error);
                const message = parsedError.Message;
                throw safeParse(message);
            }
        },
        [ accountGUID, parseErrorObject, safeParse ],
    );

    const removeTenant = useCallback(
        async (tenant: ITenant) => {
            try {
                await deleteTenant(tenant.id);
            } catch (error) {
                throw await parseErrorObject(error);
            }
        },
        [ parseErrorObject ],
    );

    const changeSelectedTenant = useCallback((tenantDetails: ITenantDetails) => {
        setSelectedTenant(tenantDetails);
    }, []);

    const getTenantData = useCallback((tenantId: string) =>
        data?.find(tenantIterator => tenantIterator.id === tenantId),
    [ data ]);

    useEffect(() => {
        const cachedTenantName = localStorage.getItem('PORTAL_CURRENT_TENANT');
        if (!selectedTenant.name && cachedTenantName) {
            const cachedTenant = tenantsAndServices.find(tenantService =>
                tenantService.tenant.name.toLowerCase() === cachedTenantName.toLowerCase())?.tenant;
            if (cachedTenant) {
                changeSelectedTenant({
                    name: cachedTenant.name,
                    id: cachedTenant.id,
                    selectedTenantId: cachedTenant.id,
                });
            }
        }
    }, [ changeSelectedTenant, selectedTenant.name, tenantsAndServices ]);

    return (
        <TenantsContext.Provider
            value={{
                data: tenantsAndServices,
                tenantsWithoutServices: data ?? [],
                selectedTenant,
                error: tenantsError,
                isValidating,
                changeTenantStatus,
                changeServiceStatus,
                removeTenant,
                changeSelectedTenant,
                getTenantData,
            }}
        >
            {props.children}
        </TenantsContext.Provider>
    );
};
