import InfoIcon from '@mui/icons-material/Info';
import {
    Divider,
    Fade,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { LegacyProductsToUserBundleLicenseMap } from '../../../common/constants/Constant';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { concurrentLicensesOpted } from '../../../store/selectors';
import {
    ITenantServiceLicense,
    ITenantServiceProduct,
} from '../../tenants/interfaces/service';
import {
    concurrencyMapping,
    productSubLabel,
    translationCode,
} from '../../tenants/subcomponents/helpers/ManageLicensesHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        groups: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        accordion: {
            width: '100%',
            boxShadow: 'none',
            border: `1px ${theme.palette.semantic.colorBorderDeEmp} solid`,
        },
        inputLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        labelGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        inputMargin: { marginBottom: '12px' },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        formButtons: { display: 'flex' },
        overAllocatedWarning: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginBottom: '6px',
        },
        statusMessage: {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '24px',
            marginBottom: '24px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        serviceLicenseHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '34px',
        },
        serviceIcon: {
            margin: '0px',
            width: '18px',
        },
        serviceHeader: { marginLeft: '12px' },
        availableLicenses: { marginTop: '8px' },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        disabledField: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            color: theme.palette.semantic.colorForegroundDisable,
            borderColor: theme.palette.semantic.colorBorderDisabled,
        },
    }),
);

const UiLicenseAllocationPerServiceComponent: React.FC<{
    type?: 'add' | 'edit';
    name: string;
    serviceLicense: ITenantServiceLicense;
}> = ({
    type = 'edit', name, serviceLicense,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const theme = useTheme();

    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const products = useMemo(() => serviceLicense.products, [ serviceLicense ]);

    const {
        register, watch, formState,
    } = useFormContext();
    const { errors } = formState;

    const disableCallback = useCallback((product: ITenantServiceProduct) => {
        return {
            disableInput: concurrentLicenseEnabled && watch(`${name}.${concurrencyMapping[product.code]}`, 0) > 0,
            disableByOverusage: (product.used > 0 && product.used >= product.allocated + product.available)
                || (type === 'add' && product.allocated >= product.available)
                || (type === 'edit' && product.available <= 0 && product.quantity === 0),
        };
    }, [ concurrentLicenseEnabled, name, type, watch ]);

    const minMaxCallback = useCallback((product: ITenantServiceProduct) => {
        return {
            minQuantity: product.reserved + product.used,
            maxQuantity: product.quantity + product.available >= 0
                ? product.quantity + product.available
                : product.quantity,
        };
    }, []);

    const getTooltip = useCallback((disableInput: boolean, newLicensingUIEnabled: boolean) => {
        if (!disableInput) {
            return '';
        }

        return newLicensingUIEnabled
            ? translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_MULTIUSER' })
            : translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_CONCURRENT' });
    }, [ translate ]);

    const getHelperText = useCallback((product: ITenantServiceProduct) => {
        const {
            minQuantity, maxQuantity,
        } = minMaxCallback(product);

        if (errors[name]?.[product.code]?.type === 'required') {
            return translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' });
        } else if (errors[name]?.[product.code]?.type === 'min'
            || (errors[name]?.[product.code]?.type === 'max' && maxQuantity > 0)) {
            return translate({ id: 'CLIENT_WARN_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (errors[name]?.[product.code]?.type === 'max' && maxQuantity === 0) {
            return translate({ id: 'CLIENT_WARN_NO_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (product.available < 0) {
            return (
                <div className={classes.overAllocatedWarning}>
                    {translate(
                        { id: 'CLIENT_TENANTS_LICENSE_OVERALLOCATED' },
                        { 0: Math.abs(product.available) },
                    )}
                    <Tooltip
                        data-cy="overallocation-tooltip"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={translate(
                            { id: 'CLIENT_LICENSE_OVERALLOCATION_TOOLTIP' },
                        )}
                        arrow
                    >
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                </div>
            );
        }
        return isAdminRevampEnabled ? '' : `${product.available} ${translate({ id: 'CLIENT_AVAILABLE' })}`;
    }, [
        classes.infoIcon,
        classes.overAllocatedWarning,
        errors,
        isAdminRevampEnabled,
        minMaxCallback,
        name,
        translate,
    ]);

    const mappedProductCallback = useCallback((product: ITenantServiceProduct) => {
        const mappedProduct = LegacyProductsToUserBundleLicenseMap[product.code];
        const newLicensingUIEnabled = !!mappedProduct;
        const productCodeForTranslation = newLicensingUIEnabled
            ? mappedProduct.slice(0, -2)
            : product.code;
        const productLabel = newLicensingUIEnabled
            ? mappedProduct.slice(-2)
            : productSubLabel(translationCode(productCodeForTranslation));

        return {
            newLicensingUIEnabled,
            productCodeForTranslation,
            productLabel,
        };
    }, []);

    return <div>
        <div className={classes.serviceLicenseHeader}>
            <portal-custom-icon
                name={serviceLicense.serviceType}
                size="18px" />
            <Typography
                className={clsx(classes.inputLabel, classes.serviceHeader)}
                variant="h3"
                data-cy="license-service-type">
                {getFriendlyName(serviceLicense.serviceType)}
            </Typography>
        </div>
        <Divider />
        {products.map((product, j) => {
            const {
                disableInput, disableByOverusage,
            } = disableCallback(product);
            const {
                minQuantity, maxQuantity,
            } = minMaxCallback(product);

            const {
                newLicensingUIEnabled, productCodeForTranslation, productLabel,
            } = mappedProductCallback(product);

            return (
                <div
                    key={j}
                    className={clsx(classes.input, classes.inputLine, classes.inputMargin)}>
                    <div className={classes.labelGroup}>
                        <Typography
                            className={classes.inputLabel}
                            id={`${productCodeForTranslation}Label`}>
                            {translate({ id: `CLIENT_${translationCode(productCodeForTranslation)}` })}
                        </Typography>
                        {productLabel && (
                            <Typography className={clsx(classes.inputLabel, classes.subText)}>
                                {translate({ id: `CLIENT_${productLabel}` })}
                            </Typography>
                        )}
                        <Typography
                            className={classes.availableLicenses}
                            style={{
                                color: product.available > 0
                                    ? theme.palette.semantic.colorSuccessText
                                    : theme.palette.semantic.colorForeground,
                            }}>
                            {translate({ id: 'CLIENT_LICENSES_AVAILABLE' }, { count: product.available })}
                        </Typography>
                    </div>
                    <Tooltip
                        arrow
                        title={getTooltip(disableInput, newLicensingUIEnabled)}
                    >
                        <TextField
                            inputProps={{ className: clsx({ [classes.disabledField]: disableInput || disableByOverusage }) }}
                            name={`${name}.${product.code}`}
                            inputRef={register(
                                disableByOverusage ? undefined : {
                                    required: true,
                                    min: minQuantity,
                                    max: maxQuantity,
                                },
                            ) as any}
                            InputProps={{ readOnly: disableInput || disableByOverusage }}
                            error={!!errors[name]?.[`${product.code}`] || product.available < 0}
                            type="number"
                            helperText={getHelperText(product)}
                            variant="outlined"
                            size="small"
                            style={{ maxWidth: '100px' }}
                            aria-labelledby={`${productCodeForTranslation}Label`}
                        />
                    </Tooltip>
                </div>
            );
        })}
    </div>;
};

export default UiLicenseAllocationPerServiceComponent;
