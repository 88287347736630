import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSelect } from '@experiences/ui-common';
import {
    FormControlLabel,
    Switch,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    certificateLocationUsageOptions,
    Saml2BindingType,
    samlBindingTypeOptions,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import { ISamlFormData } from '../../../common/interfaces/cis/saml';

const useStyles = makeStyles(() =>
    createStyles({
        sectionTitle: {
            marginTop: '20px',
            marginBottom: '0px',
        },
        section: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        field: {
            marginBottom: '16px',
            maxWidth: '420px',
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        switchLabel: { lineHeight: '20px' },
    }),
);

const SecuritySettingsSAMLAdvancedDetailsForm: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, errors, setValue,
    } = useFormContext<ISamlFormData>();

    const EnableSamlHttpPost = useFeatureFlagValue(Features.EnableSamlHttpPost.name);

    const bindingTypes = useMemo(() => {
        const options: any = { ...samlBindingTypeOptions };
        if (!EnableSamlHttpPost) {
            delete options[Saml2BindingType.HttpPost];
        }
        delete options[Saml2BindingType.Artifact];

        return options;
    }, [ EnableSamlHttpPost ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <>
            <div className={classes.formSection}>
                <Controller
                    name="AllowUnsolicitedAuthnResponse"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            className={classes.field}
                            control={
                                <Switch
                                    checked={props.value}
                                    onChange={e => handleSwitch('AllowUnsolicitedAuthnResponse', e.target.checked, props.onChange)}
                                    color="primary"
                                    data-cy="configure-saml-allow-unsolicited-authn-response"
                                />
                            }
                            label={
                                <Typography className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ALLOW_UNSOLICITED_AUTHENTICATION_RESPONSE' })}
                                </Typography>
                            }
                        />
                    )}
                />
                <UiSelect
                    control={control}
                    className={classes.field}
                    name="Saml2BindingType"
                    inputLabel={translate({ id: 'CLIENT_SAML_BINDING_TYPE' })}
                    error={!!errors.Saml2BindingType}
                    options={bindingTypes}
                    required
                    helperText={errors.Saml2BindingType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    fullWidth
                    dataCy="configure-saml2-binding-type"
                />
                <UiSelect
                    control={control}
                    name="ServiceCertificateUsage"
                    inputLabel={translate({ id: 'CLIENT_SERVICE_CERTIFICATE_USAGE' })}
                    error={!!errors.ServiceCertificateUsage}
                    options={certificateLocationUsageOptions}
                    fullWidth
                    dataCy="edit-certificate-locations-service-usage"
                    required
                    className={classes.field}
                />
            </div>
        </>
    );
};

export default SecuritySettingsSAMLAdvancedDetailsForm;
