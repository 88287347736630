import { useLocalization } from '@experiences/locales';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';
import { IDiscountBase } from '../interfaces/ecommerce';
import { EcommercePrice } from './EcommercePrice';

const useStyles = makeStyles(theme =>
    createStyles({
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        circularLoading: { borderRadius: '30px' },
        priceText: {
            fontSize: '14px',
            fontWeight: '400px',
            color: theme.palette.semantic.colorForegroundLight,
            textDecorationLine: 'line-through',
            marginRight: '4px',
        },
        savesPercentageOffText: {
            color: theme.palette.semantic.colorSuccessText,
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
        },
    }),
);

export const EcommerceDiscountedPrice: React.FC<{
    fullPrice?: number;
    discountedPrice?: number;
    billingPeriod?: string;
    discountDetails?: IDiscountBase;
    loading?: boolean;
    currency: string;
    shouldDisplaySavesPercentageOffText?: boolean;
    dataCy: string;
}> = ({
    fullPrice,
    discountedPrice,
    billingPeriod,
    discountDetails,
    loading,
    currency,
    shouldDisplaySavesPercentageOffText = true,
    dataCy,
}) => {
    const classes = useStyles();
    const language = useLocalization();

    return (
        <div className={classes.column}>
            <div className={classes.row}>
                <div data-cy={dataCy}>
                    {loading ? (
                        <CircularProgress
                            size={16}
                            className={classes.circularLoading} />
                    ) : (
                        <Typography className={classes.priceText}>
                            {getPriceString(fullPrice!, currency, language)}
                        </Typography>
                    )}
                </div>
                <EcommercePrice
                    value={discountedPrice}
                    loading={loading}
                    gap={true}
                    billingPeriod={billingPeriod}
                    currency={currency}
                    dataCy="discounted-subscription-price"
                />
            </div>
            {shouldDisplaySavesPercentageOffText && (
                <Typography
                    className={classes.savesPercentageOffText}
                    data-cy="saves-percentage-off-text">
                    <FormattedMessage
                        id="CLIENT_ORDER_SUMMARY_SAVES_PERCENTAGE"
                        values={{ percentageOff: discountDetails?.percentageOff }}
                    />
                </Typography>
            )}
        </div>
    );
};
