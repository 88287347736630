import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        container: { textAlign: 'center' },
        link: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': { textDecoration: 'underline' },
        },
        inputAdornment: {
            width: 'unset',
            marginRight: '0px',
            marginBottom: '2px',
        },
    }),
);

interface IUiTextLink {
    text: string;
    link: string;
    onClick: () => any;
    dataCy?: string;
    className?: string;
}

export function UiTextLink({
    text, link, onClick, dataCy, className,
}: IUiTextLink) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={clsx(classes.container, className)}>
            <Typography display="inline">
                {translate({ id: text })}
            &nbsp;
            </Typography>
            <Typography
                display="inline"
                className={classes.link}
                onClick={onClick}
                data-cy={dataCy}
                tabIndex={0}>
                {translate({ id: link })}
            </Typography>
        </div>
    );
}
