import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    CircularProgress,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import {
    getUserNotificationSubscription,
    resetUserNotificationSubscription,
    userNotificationSubscriptionUri,
} from '../../services/notification-preferences';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import { INotificationMode } from './interfaces/notificationSettings';
import NotificationPreferencesContainerComponent from './NotificationPreferencesContainerComponent';
import NotificationTabsComponent from './NotificationTabsComponent';
import {
    initialNotificationSettingsReducerState,
    notificationSettingsReducer,
} from './reducers';
import { ActionTypes } from './reducers/actionTypes';
import NotificationModePreferencesComponent from './v2/NotificationModePreferencesComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontSize: '16px',
            fontWeight: 'bold',
            paddingBottom: '20px',
        },
        previewHeadingSuffix: {
            fontSize: '10px',
            color: theme.palette.semantic.colorForegroundLight,
            fontWeight: 400,
            lineHeight: '16px',
            paddingLeft: '8px',
        },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
        restoreDefaultSubscriptionBlock: { margin: '20px 0' },
        restoreDefaultSubscriptionLink: {
            marginLeft: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            textDecoration: 'none',
        },
    }),
);

const NotificationSettingsComponent: React.FC = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    const DisableNotificationPreview = useFeatureFlagValue(
        Features.DisableNotificationPreview.name,
    );
    const enableNotificationSettingsUiV2 = useFeatureFlagValue(
        Features.EnableNotificationSettingsUiV2.name,
    );
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const { enqueueSnackbar } = useSnackbar();

    const {
        data: publishersData,
        isValidating,
        error,
    } = useSWR(
        tenantId ? [ userNotificationSubscriptionUri, tenantId ] : null,
        getUserNotificationSubscription,
    );

    const [ notificationSettings, dispatch ] = React.useReducer(
        notificationSettingsReducer,
        initialNotificationSettingsReducerState,
    );

    useEffect(() => {
        if (publishersData && tenantId) {
            dispatch({
                type: ActionTypes.NS_INITIALIZE_NOTIFICATION_SETTINGS,
                data: publishersData.publishers,
            });
        }
    }, [ publishersData, tenantId ]);

    const createErrorNotification = useCallback(
        (message: string) => {
            enqueueSnackbar(message, { variant: notificationType.ERROR as any });
        },
        [ enqueueSnackbar ],
    );

    const handleError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createErrorNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`);
        },
        [ createErrorNotification, translate ],
    );

    const tabsList = useMemo(
        () =>
            notificationSettings
                .publishersWithGroupedTopics
                ?.map((publisher: any) => publisher.name),
        [ notificationSettings.publishersWithGroupedTopics ],
    );

    const isContentReady = useMemo(() => tabsList.length && !isValidating, [ tabsList.length, isValidating ]);
    const noPublisherDataFound = useMemo(
        () => publishersData && notificationSettings.publishers.length === 0,
        [ publishersData, notificationSettings.publishers.length ]);

    const { currentPublisher } = useMemo(
        () => ({ currentPublisher: notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex] }),
        [ notificationSettings.publishersWithGroupedTopics, notificationSettings.tabIndex ],
    );

    const restoreDefaultSubscriptions = useCallback(async () => {
        dispatch({
            type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS,
            data: { publisherIndex: currentPublisher.publisherIndex },
        });

        try {
            dispatch({
                type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED,
                data: {
                    publisherIndex: currentPublisher.publisherIndex,
                    resetData: (await resetUserNotificationSubscription(currentPublisher.publisherId, tenantId))
                        .publishers,
                },
            });
        } catch (e) {
            handleError(e as Error);
            dispatch({
                type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED,
                data: { publisherIndex: currentPublisher.publisherIndex },
            });
        }
    }, [ dispatch, currentPublisher?.publisherId, currentPublisher?.publisherIndex, tenantId, handleError ]);

    const getNotificationSettingsUiV2 = () => {
        return <>
            {currentPublisher.modes.every((mode: INotificationMode) => !mode.isSubscribed && !mode.isIndeterminate) && (
                <div className={classes.restoreDefaultSubscriptionBlock}>
                    <portal-alert-bar
                        status="warning"
                        cancelable={false}>
                        <div>
                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOT_SUBSCRIBED_TO_ANY_TEXT' })}
                            <Link
                                className={classes.restoreDefaultSubscriptionLink}
                                onClick={restoreDefaultSubscriptions}>
                                {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_RESTORE_DEFAULT_SUBSCRIPTIONS' })}
                            </Link>
                        </div>
                    </portal-alert-bar>
                </div>
            )}
            <NotificationModePreferencesComponent
                {...{
                    notificationSettings,
                    dispatch,
                    handleError,
                }} />
        </>;
    };

    return (
        <div>
            <Typography
                className={classes.heading}
                role="heading"
                aria-level={1}>
                {translate({ id: 'CLIENT_NOTIFICATIONS' })}
                {!DisableNotificationPreview && (
                    <span className={classes.previewHeadingSuffix}>
                        {translate({ id: 'CLIENT_PREVIEW' })}
                    </span>
                )}
            </Typography>
            {isContentReady ? (
                <>
                    <NotificationTabsComponent
                        {...{
                            notificationSettings,
                            tabsList,
                            dispatch,
                        }}
                    />
                    {
                        enableNotificationSettingsUiV2 ?
                            getNotificationSettingsUiV2() :
                            <NotificationPreferencesContainerComponent
                                {...{
                                    notificationSettings,
                                    dispatch,
                                    restoreDefaultSubscriptions,
                                    handleError,
                                }} />
                    }

                </>
            ) : null}
            {!isContentReady && error ? (
                <div className={classes.mainContentPlaceholder}>
                    <div>
                        {translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }) +
                            '. ' +
                            translate({ id: 'CLIENT_TRIAL_REQUESTED_FAILED' })}
                    </div>
                </div>
            ) : null}
            {(!isContentReady && !error) && noPublisherDataFound ? (
                <div className={classes.mainContentPlaceholder}>
                    <div>
                        {translate({ id: 'CLIENT_NO_USAGE_DATA_FOUND' })}
                    </div>
                </div>
            ) : null}
            {(!isContentReady && !error) && !noPublisherDataFound && (
                <div className={classes.mainContentPlaceholder}>
                    <CircularProgress
                        thickness={2}
                        size={36} />
                </div>
            )}
        </div>
    );
};

export default NotificationSettingsComponent;
