import { useRouteResolver } from '@experiences/util';
import {
    Chip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { isAdminSelector } from '../../store/selectors';
import { UiDropdownButton } from '../common/UiDropdownButton/UiDropdownButton';

const useStyles = makeStyles(theme =>
    createStyles({
        unlicensedContainer: {
            background: theme.palette.semantic.colorBackground,
            flexDirection: 'column',
            flexGrow: 'inherit',
            display: 'flex',
        },
        unlicensedOptionsContainer: {
            display: 'flex',
            flexGrow: 'inherit',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        centered: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            marginBottom: '12px',
        },
        regularText: { marginBottom: '12px' },
        activateAction: { marginBottom: '12px' },
        header: {
            display: 'flex',
            alignItems: 'baseline',
            padding: '24px',
        },
        chip: {
            background: `${theme.palette.semantic.colorBackgroundHigh} !important`,
            color: theme.palette.semantic.colorForegroundHigh,
            height: '24px',
            marginLeft: '4px',
        },
    }),
);

interface UnlicensedProps {
    showSubscription?: boolean;
}

export default function Unlicensed(props: UnlicensedProps) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    const history = useHistory();
    const getRoute = useRouteResolver();

    return (
        <div className={classes.unlicensedContainer}>
            {!!props.showSubscription && (
                <div className={classes.header}>
                    <Typography className={classes.headerText}>
                        {translate({ id: 'CLIENT_LICENSES' })}
                    </Typography>
                    <Chip
                        label={
                            <Typography data-cy="licenses-account-plan">
                                {translate({ id: `CLIENT_UNLICENSED_PLAN` })}
                            </Typography>
                        }
                        className={classes.chip}
                    />
                </div>
            )}
            <div
                className={classes.unlicensedOptionsContainer}
                data-cy="unlicensed-container">
                <div className={classes.centered}>
                    <Typography className={classes.headerText}>
                        {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                    </Typography>
                    <Typography className={classes.regularText}>
                        {translate({ id: 'CLIENT_ACTIVATE_LICENSE_GET_STARTED' })}
                    </Typography>
                    <div>
                        <UiDropdownButton
                            showDropdownButton
                            label={translate({ id: 'CLIENT_ACTIVATE_LICENSE' })}
                            disabled={!isAdmin}
                            tooltip={isAdmin ? undefined : translate({ id: 'CLIENT_ACTIVATE_LICENSE_ADMINS_ONLY' })}
                            menuItems={[
                                {
                                    onClick: () => {
                                        history.push(`${getRoute(RouteNames.LicensingActivateLicenseOnline)}`);
                                    },
                                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        history.push(`${getRoute(RouteNames.ActivateOffline)}`);
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
