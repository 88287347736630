import {
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingLeft: '0px',
            paddingRight: '0px',
            marginLeft: '-8px',
            marginRight: '-8px',
            marginBottom: '32px',
            backgroundColor: 'unset',
        },
    }),
);

const EcommerceFormStepper: React.FC<{ activeStep: number }> = ({ activeStep }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const steps = useMemo(() => [ 'CLIENT_ACCOUNT_INFORMATION', 'CLIENT_PAYMENT_INFO' ], []);

    return (
        <Stepper
            activeStep={activeStep}
            className={classes.root}>
            {steps.map((label, index) => {
                return (
                    <Step key={index}>
                        <StepLabel>
                            {translate({ id: label })}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default EcommerceFormStepper;
