import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    Button,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { AzureADIntegrationLink } from '../../../common/constants/documentation/AzureADIntegrationDocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import useShowDeleteAADDialog from '../../../common/hooks/useShowDeleteAADDialog';
import {
    AuthenticationSettingType,
    deleteDirectoryIntegrationSetting,
    IAuthenticationSettingResponse,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import ExternalProvidersAADForm from '../../authsettings/subcomponents/ExternalProvidersAADForm';
import { useShowSuccessConfirmationDialog } from '../../authsettings/subcomponents/useShowSuccessConfirmationDialog';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        subtitle: {
            fontWeight: 600,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        description: { lineHeight: '20px' },
        descriptionLinks: { fontWeight: 600 },
        deleteButton: {
            color: theme.palette.semantic.colorErrorText,
            width: 'fit-content',
            position: 'absolute',
            right: '24px',

            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important` },
            '&:focus': {
                color: `${theme.palette.semantic.colorErrorText} !important`,
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            },
        },
    }),
}));

const SecuritySettingsAADForm: React.FC<{
    authenticationSetting: IAuthenticationSettingResponse;
    handleError: (error: any) => Promise<void>;
}> = ({
    authenticationSetting, handleError,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const [ loading, setLoading ] = useState(false);

    const partitionGlobalId = useSelector(accountGlobalId);

    const openShowSuccessConfirmationDialog = useShowSuccessConfirmationDialog(AuthenticationSettingType.AAD);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const showDeleteAADDialog = useShowDeleteAADDialog();

    const handleDelete = useCallback(async () => {
        const proceed = await showDeleteAADDialog();
        if (proceed) {
            setLoading(true);
            try {
                await deleteDirectoryIntegrationSetting(partitionGlobalId);

                await openShowSuccessConfirmationDialog();

                history.push({ pathname: getRoute(RouteNames.SecuritySettings) });
            } catch (error) {
                handleError(error);
                setLoading(false);
            }
        }
    }, [ showDeleteAADDialog, partitionGlobalId, openShowSuccessConfirmationDialog, history, getRoute, handleError ]);

    return (
        <>
            {authenticationSetting.authenticationSettingType === AuthenticationSettingType.AAD && (
                <Button
                    className={classes.deleteButton}
                    onClick={handleDelete}
                    startIcon={<DeleteIcon />}>
                    {translate({ id: 'CLIENT_LOGS_DELETE' })}
                </Button>
            )}
            <Typography className={classes.subtitle}>
                {translate({ id: 'CLIENT_CONFIGURATION_DETAILS' })}
            </Typography>
            <Typography className={classes.description}>
                {translate(
                    { id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DESCRIPTION' },
                    {
                        guideLink: (
                            <Link
                                href={getLocalizedLink(AzureADIntegrationLink)}
                                rel="noreferrer"
                                target="_blank"
                                underline='none'
                            >
                                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_STEP_BY_STEP_GUIDE' })}
                            </Link>
                        ),
                        powershellScriptLink: (
                            <Link
                                href="https://github.com/UiPath/platform-quickstart/tree/master/azure-ad-integration"
                                rel="noreferrer"
                                target="_blank"
                                underline='none'
                            >
                                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_POWERSHELL_SCRIPT' })}
                            </Link>
                        ),
                    },
                )}
            </Typography>
            <ExternalProvidersAADForm
                authenticationSetting={authenticationSetting}
                loading={loading}
                setLoading={setLoading}
                onError={handleError}
                showButtonFooter={true}
            />
        </>
    );
};

export default SecuritySettingsAADForm;
