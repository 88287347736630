import { CancelTokenSource } from 'axios';

import { getLicenseUsageTimeline } from '../../services/licensing/LicenseUsageService';
import createAndPushPromiseLog from '../../util/LogsUtil';

export async function getLicenseTimelineData(
    clientIds: string[],
    numberOfDays: number,
    usageAggregationType: string,
    source?: CancelTokenSource,
) {
    try {
        return await getLicenseUsageTimeline(clientIds, numberOfDays, usageAggregationType, source);
    } catch (error) {
        createAndPushPromiseLog(
            'error',
            error,
            'An error occcured while fetching license timeline data for dashboard chart',
        );
        throw error;
    }
}
