import { HeaderNames } from '@experiences/constants';
import {
    IFile,
    IRequestPropsWithToken,
} from '@experiences/interfaces';
import axios from 'axios';
import {
    isUndefined,
    omitBy,
} from 'lodash';

import { getBasePath } from '../static/PathUtil';
import { getHeaders } from './Headers';
import {
    getRequestBody,
    getRequestParams,
} from './RequestUtil';
import { getFileName } from './ResponseUtil';

export async function get<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps?.accessToken, requestProps.headers);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath();

    const response = await axios.get<T>(
        `${basePath}${url}` +
      getRequestParams(
          requestProps?.urlParams,
          omitBy(requestProps?.pagination, isUndefined),
          requestProps?.shouldEnumerate,
      ),
        {
            headers,
            data: {}, // content-type header is not passed if data is undefined
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    return response.data;
}

export async function getFile(url: string, requestProps: IRequestPropsWithToken): Promise<IFile> {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const basePath = getBasePath();

    const response = await axios.get<string>(
        `${basePath}${url}` +
      getRequestParams(
          requestProps?.urlParams,
          omitBy(requestProps?.pagination, isUndefined),
          requestProps?.shouldEnumerate,
      ),
        {
            headers,
            data: {}, // content-type header is not passed if data is undefined
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    const filename = getFileName(response.headers[HeaderNames.ContentDisposition]);
    const data = response.data;
    return {
        filename,
        data,
    };
}

export async function post<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const body = getRequestBody(requestProps);
    const basePath = requestProps?.ignoreBasePath ? '' : getBasePath();

    const response = await axios.post<T>(
        `${basePath}${url}` + getRequestParams(requestProps?.urlParams, omitBy(requestProps?.pagination, isUndefined)),
        body,
        {
            headers,
            xsrfCookieName: process.buildConfigs.xsrfCookieName,
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    return response.data;
}

export async function put<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps?.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = getBasePath();

    const response = await axios.put<T>(`${basePath}${url}`, body, {
        headers,
        xsrfCookieName: process.buildConfigs.xsrfCookieName,
        cancelToken: requestProps?.cancelTokenSource?.token,
    });

    return response.data;
}

export async function patch<T>(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = getBasePath();

    const response = await axios.patch<T>(`${basePath}${url}` + getRequestParams(requestProps?.urlParams), body, {
        headers,
        xsrfCookieName: process.buildConfigs.xsrfCookieName,
        cancelToken: requestProps?.cancelTokenSource?.token,
    });

    return response.data;
}

export async function axiosDelete(url: string, requestProps: IRequestPropsWithToken) {
    const headers = await getHeaders(requestProps.accessToken, requestProps.headers);
    const body = requestProps?.body ? JSON.stringify(requestProps?.body) : {};
    const basePath = getBasePath();

    const response = await axios.delete(
        `${basePath}${url}` + getRequestParams(requestProps?.urlParams, omitBy(requestProps?.pagination, isUndefined)),
        {
            headers,
            data: body,
            xsrfCookieName: process.buildConfigs.xsrfCookieName,
            cancelToken: requestProps?.cancelTokenSource?.token,
        },
    );

    return response.data;
}
