import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import { getEnvironment } from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    Paper,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 380,
            height: 310,
            overflow: 'auto',
            marginTop: '100px',
            padding: '0px 16px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            marginTop: '8px',
            textAlign: 'center',
        },
        button: {
            width: '280px',
            marginTop: '16px',
        },
    }),
);

export default function SessionExpiry() {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        const env = getEnvironment();
        if (env === 'local') {
            console.log(
                '____██████████████████████████████████████████████████████████____\r\n' +
          '__██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒██__\r\n' +
          '██░░░░░░██████░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒████████▒▒▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░██████████░░░░████████▒▒▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░██░░░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░░░██████░░░░░░░░██░░▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░░░░░██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██████████▒▒▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░░░▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '██░░░░░░░░░░░░██░░▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒██▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '██░░░░████████░░░░▒▒▒▒██▒▒▒▒▒▒▒▒████████▒▒▒▒▒▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒██\r\n' +
          '__██░░░░░░░░░░░░▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒██__\r\n' +
          '____██████████████████████████████████████████████████████████____\r\n',
                '\r\n',
                'If you are running into this page in a loop, please refer to https://github.com/UiPath/Cloud-RPA/tree/dev/Portal#running-locally-development-build',
            );
        }
    }, []);

    const login = useCallback(() => {
        window.location.assign('/');
    }, []);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.headerContainer}>
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <Typography className={classes.headerText}>
                        {translate({ id: 'CLIENT_SESSION_EXP_HEADER' })}
                    </Typography>
                    <Typography className={classes.headerSubText}>
                        {translate({ id: 'CLIENT_SESSION_EXP_MESSAGE' })}
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={login}>
                        {translate({ id: 'CLIENT_SIGN_IN' })}
                    </Button>
                </div>
            </Paper>
        </UiBackground>
    );
}
