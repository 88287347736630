import { IEmailSettingsData } from '../../common/interfaces/emailSetting';
import { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const settingUrl = `${process.buildConfigs.identityApiBaseRoute}/Setting`;

export interface ISetting {
    id: number;
    key: string;
    value: string;
    partitionGlobalId: string;
    userId: string;
}

export interface ISettingPayload {
    settings: IKeyValuePair[];
    partitionGlobalId: string;
    userId?: string;
}

export function getSetting(url: string, key: string[], partitionGlobalId: string, userId?: string) {
    return get<ISetting[]>(settingUrl, {
        urlParams: {
            key,
            partitionGlobalId,
            userId,
        },
    });
}

export function saveSetting(url: string, payload: ISettingPayload) {
    return put<ISetting[]>(settingUrl, { body: payload });
}

export function deleteSetting(url: string, settingId: string) {
    return axiosDelete(`${settingUrl}/${settingId}`);
}

export function verifySmtpSettings(recipient: string, smtpSettings: IEmailSettingsData) {
    return post(`${settingUrl}/VerifySmtpSettings`, {
        body: {
            recipient,
            smtpSettings,
        },
    });
}
