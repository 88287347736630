import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import useIsBasicAuthEnabled from '../../common/hooks/useIsBasicAuthEnabled';
import { isHostModeSelector } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import NotificationSettingsComponent from '../notificationSettings/NotificationSettingsComponent';
import PersonalAccessTokenSettingsComponent from './PersonalAccessTokenSettingsComponent';
import PrivacyAndSecuritySettingsComponent from './PrivacyAndSecuritySettingsComponent.default';
import ProfileGeneralSettingsComponent from './ProfileGeneralSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        closeIcon: { cursor: 'pointer' },
        content: {
            display: 'flex',
            flexDirection: 'row',
        },
        panelHeader: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '20px',
        },
        tab: {
            textTransform: 'none',
            minHeight: '0px',
            textAlign: 'left',
            lineHeight: '20px',
            padding: '2px 8px 2px 8px!important',
            margin: '4px',
            fontWeight: 400,
        },
        tabPanelWrapper: {
            display: 'flex',
            flexDirection: 'column',
            padding: '6px',
            width: '100%',
        },
        tabs: {
            width: '200px',
            '& .MuiTabs-flexContainer, .MuiTab-wrapper, .MuiTab-root': { alignItems: 'flex-start' },
            '& .MuiTabs-indicator': {
                left: '0px',
                width: '4px',
                backgroundColor: theme.palette.orange[500],
            },
            '& .Mui-selected': {
                fontWeight: 600,
                color: theme.palette.semantic.colorForeground,
            },
        },
    }),
);

enum ProfileTab {
    GeneralSettings = 'GeneralSettings',
    NotificationSettings = 'NotificationSettings',
    PrivacyAndSecuritySettings = 'PrivacyAndSecuritySettings',
    PersonalAccessTokenSettings = 'PersonalAccessTokenSettings',
}

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case ProfileTab.GeneralSettings:
            return <ProfileGeneralSettingsComponent />;
        case ProfileTab.NotificationSettings:
            return <NotificationSettingsComponent />;
        case ProfileTab.PrivacyAndSecuritySettings:
            return <PrivacyAndSecuritySettingsComponent />;
        case ProfileTab.PersonalAccessTokenSettings:
            return <PersonalAccessTokenSettingsComponent />;
        default:
            return null;
    }
};

const ProfilePageComponent: FC = () => {
    const EnableNotificationSettings = useFeatureFlagValue(Features.EnableNotificationSettings.name);
    const EnablePrivacyAndSecuritySettings = useFeatureFlagValue(Features.EnablePrivacyAndSecuritySettings.name);
    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isPrivacyAndSecuritySettings = useRouteMatch(RouteNames.PrivacyAndSecuritySettings);
    const isNotificationSettings = useRouteMatch(RouteNames.NotificationSettings);
    const isPersonalAccessTokenSettings = useRouteMatch(RouteNames.PersonalAccessToken);
    const isBasicAuthEnabled = useIsBasicAuthEnabled();
    const [ tabValue, setTabValue ] = useState<ProfileTab>(
        (isNotificationSettings && ProfileTab.NotificationSettings) ||
        (isPrivacyAndSecuritySettings && ProfileTab.PrivacyAndSecuritySettings) ||
        (isPersonalAccessTokenSettings && ProfileTab.PersonalAccessTokenSettings) ||
        ProfileTab.GeneralSettings,
    );

    const isHostMode = useSelector(isHostModeSelector);

    const handleTabChange = useCallback((_, newValue: string) => setTabValue(newValue as ProfileTab), [ setTabValue ]);

    return (
        <UiPanel header={{
            title: translate({ id: 'CLIENT_PREFERENCES' }),
            className: classes.panelHeader,
        }}>
            <div className={classes.content}>
                <Tabs
                    className={classes.tabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    orientation="vertical"
                >
                    <Tab
                        className={classes.tab}
                        value={ProfileTab.GeneralSettings}
                        label={translate({ id: 'CLIENT_GENERAL' })}
                        onClick={() => history.push(getRoute(RouteNames.Profile))}
                    />
                    {EnablePrivacyAndSecuritySettings &&
                        (!process.buildConfigs.enableUserProfileChangePassword || isBasicAuthEnabled) && (
                        <Tab
                            className={classes.tab}
                            value={ProfileTab.PrivacyAndSecuritySettings}
                            label={translate({ id: 'CLIENT_PRIVACY_AND_SECURITY' })}
                            onClick={() => history.push(getRoute(RouteNames.PrivacyAndSecuritySettings))}
                        />
                    )}
                    {EnableNotificationSettings && !isHostMode && (
                        <Tab
                            className={classes.tab}
                            value={ProfileTab.NotificationSettings}
                            label={translate({ id: 'CLIENT_NOTIFICATIONS' })}
                            onClick={() => history.push(getRoute(RouteNames.NotificationSettings))}
                        />
                    )}
                    {EnableReferenceTokens && !isHostMode && (
                        <Tab
                            className={classes.tab}
                            value={ProfileTab.PersonalAccessTokenSettings}
                            label={translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN' })}
                            onClick={() => history.push(getRoute(RouteNames.PersonalAccessToken))}
                        />
                    )}
                </Tabs>
                <div className={classes.tabPanelWrapper}>
                    <TabPanel value={tabValue} />
                </div>
            </div>
        </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <ProfilePageComponent />
        {renderRoutes(route?.routes)}
    </>
);
