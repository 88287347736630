import { Features } from '@experiences/feature-flags';

import { IWidgetMetadata } from '../../../common/interfaces/widget';
import { UserGroup } from '../../common/UserGroups';
import AppsComponent from '../../orchestrator/apps/AppsWidgetComponent';
import ProcessesComponent from '../../orchestrator/processes/ProcessesWidgetComponent';
import RecentRunsComponent from '../../orchestrator/processes/recentRuns/RecentRuns';
import TotalRunsComponent from '../../orchestrator/processes/totalRuns/TotalRuns';
import TaskComponent from '../../orchestrator/tasks/TaskWidgetComponent';
import { HomePageLicensesComponent } from '../HomePageLicensesComponent';
import { HomePageServicesComponent } from '../HomePageServicesComponent';
import { HomePageSideBarComponent } from '../HomePageSideBarComponent';

export const HomeWidgets: IWidgetMetadata[] = [
    {
        name: 'HomePageServices',
        priority: 1,
        component: HomePageServicesComponent,
        groups: [ UserGroup.Everyone ],
        position: 'center',
    },
    {
        name: 'HomePageSidebar',
        priority: 1,
        component: HomePageSideBarComponent,
        groups: [ UserGroup.Everyone ],
        position: 'right',
    },
    {
        name: 'Licenses',
        priority: 5,
        component: HomePageLicensesComponent,
        groups: [ UserGroup.Administrators ],
        position: 'center',
    },
    {
        name: 'TotalRuns',
        priority: 5,
        component: TotalRunsComponent,
        groups: [ UserGroup.AutomationUsers ],
        position: 'right',
    },
    {
        name: 'Tasks',
        priority: 10,
        component: TaskComponent,
        groups: [ UserGroup.AutomationUsers ],
        position: 'center',
        featureFlag: Features.DisableFeatureFedRamp.name,
        invertFeatureFlag: true,
    },
    {
        name: 'RecentRuns',
        priority: 10,
        component: RecentRunsComponent,
        groups: [ UserGroup.AutomationUsers ],
        position: 'right',
    },
    {
        name: 'Apps',
        priority: 15,
        component: AppsComponent,
        groups: [ UserGroup.AutomationUsers ],
        position: 'center',
        featureFlag: Features.DisableFeatureFedRamp.name,
        invertFeatureFlag: true,
    },
    {
        name: 'Processes',
        priority: 20,
        component: ProcessesComponent,
        groups: [ UserGroup.AutomationUsers ],
        position: 'center',
        featureFlag: Features.DisableFeatureFedRamp.name,
        invertFeatureFlag: true,
    },
];
