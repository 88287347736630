import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { styled } from '@mui/system';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { contentfulSlide } from '../../common/interfaces/slide';
import { getContentfulResource } from '../../services/CarouselCMS';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.semantic.colorPrimary),
    backgroundColor: theme.palette.semantic.colorPrimary,
    height: '40px',
}));

const CustomButtonFilled = styled(IconButton)(({ theme }) => ({
    color: theme.palette.semantic.colorPrimary,
    height: '12px',
    width: '12px',
}));

const CustomButtonUnfilled = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    color: theme.palette.semantic.colorForegroundLight,
    height: '12px',
    width: '12px',
    '&:hover': { color: theme.palette.semantic.colorPrimary },
}));

const CustomIconFilled = styled(CircleIcon)(({ theme }) => ({
    color: theme.palette.semantic.colorForegroundDark,
    height: '12px',
    width: '12px',
    'stroke-width': '1px',
}));

const CustomIconUnfilled = styled(CircleOutlinedIcon)`
    width: 12px;
    height: 12px;
`;

const useStyles = makeStyles(theme =>
    createStyles({
        carouselInner:
        {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            rowGap: '20px',
        },
        carouselSlidesBackground: {
            backgroundColor: theme.palette.semantic.colorToggleOffHover,
            width: '100%',
            height: '232px',
            padding: '0px',
            overflow: 'hidden',
            borderRadius: '8px',
        },
        carouselSlideDisplay:
        {
            width: '100%',
            height: '232px',
            maxHeight: '232px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            transition: 'transform .5s',
        },
        carouselButtonDisplay:
        {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '20px',
            width: '100%',
            height: '10px',
            alignItems: 'flex-start',

        },
        carouselSlideBackground:
        {
            width: '100%',
            minWidth: '100%',
            height: '232px',
            display: 'flex',
            flexDirection: 'column',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'border-box',
            backgroundPosition: 'right bottom',
            paddingLeft: '33px',
            paddingBottom: '28px',
        },
        carouselSlideBackground2: {
            width: '100%',
            minWidth: '100%',
            height: '232px',
            display: 'flex',
            flexDirection: 'column',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'border-box',
            backgroundPosition: 'left bottom',
            alignItems: 'end',
            paddingLeft: '33px',
            paddingBottom: '28px',
        },
        carouselSlideItems: {
            width: '70%',
            minWidth: '0',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            justifyContent: 'space-between',
        },
        carouselSlideItems2: {
            width: '70%',
            minWidth: '0',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            justifyContent: 'space-between',
        },
        closeButton:
        {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'start',
            height: '32px',
        },
        title:
        {
            width: '0',
            minWidth: '100%',
            fontFamily: 'Poppins',
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 'bold',
            marginBottom: '12px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            height: '32px',
            color: theme.palette.semantic.colorForeground,
            wordWrap: 'break-word',

        },
        description:
        {
            width: '0',
            minWidth: '100%',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            wordWrap: 'break-word',

        },
        lineImage:
        {
            marginBottom: '10px',
            marginTop: '16px',
            height: '32px',
        },
        lineImageInner:
        {
            height: '32px',
            overflow: 'hidden',
        },
        slideButton:
    {
        marginTop: 'auto',
        overflow: 'hidden',
    },
    }),
);
export interface CarouselInnerProps{
    slides: CarouselSlideParameters[];
    setOpen: Function;
    dataCy?: string;
}
export interface CarouselSlideParameters{
    id: string;
    lineImage?: string;
    title?: string;
    description?: string;
    backgroundImage?: string;
    buttonText?: string;
    buttonRedirectUrl?: string;
    backgroundImagePosition?: string;
    buttonType?: string;
}
export interface CarouselSlideProps extends CarouselSlideParameters{
    setOpen: Function;
}
export interface CarouselButtonProps{
    idx: number;
    currentSlide: number;
    setSlide: Function;
}

export default function Carousel() {
    const carouselMinWidth = useMediaQuery('(min-width:768px)');
    const [ isOpen, setOpen ] = useState(localStorage.getItem('cloudRPA_carousel_enabled') !== 'false');
    const [ slides, setSlides ] = useState<CarouselSlideParameters[]>([]);
    const { data } = useSWR('/api/content/carousel/getCarouselCMSData', getContentfulResource);
    const carouselSlidesDataArray = useMemo(() => {
        if (data) {
            return data.homePageCarousel.slidesCollection.items.filter(obj => obj != null).map((obj: contentfulSlide) => (
                {
                    ...obj,
                    lineImage: obj.lineImage ? obj.lineImage.url : undefined,
                    backgroundImage: obj.backgroundImage ? obj.backgroundImage.url : undefined,
                }
            ));
        }
    }, [ data ]);
    useEffect(() => {
        if (carouselSlidesDataArray !== undefined) {
            setSlides(carouselSlidesDataArray);
        }
    }, [ carouselSlidesDataArray, slides ]);

    return (<>
        {isOpen && slides.length !== 0 && carouselMinWidth && <CarouselInner
            slides={slides}
            setOpen={setOpen}
            dataCy='carousel-inner' />}
    </>);
}
export function CarouselInner({
    slides, setOpen, dataCy,
}: CarouselInnerProps) {
    const [ slideState, setSlide ] = useState({ currentSlide: 0 });
    const classes = useStyles();

    return (
        <div
            data-cy={dataCy}
            className={classes.carouselInner}>
            <div className={classes.carouselSlidesBackground}>
                <div
                    className={classes.carouselSlideDisplay}
                    style={{ transform: 'translateX(-' + String(slideState.currentSlide * 100) + '%)' }}>
                    {slides.map((slide) => {
                        return (<CarouselSlide
                            {...slide}
                            setOpen={setOpen}
                            key={slide.id}
                        />);
                    })}
                </div>
            </div>
            <div className={classes.carouselButtonDisplay} >
                {slides.map((_slide, idx) => {
                    const buttonProps = {
                        idx: idx,
                        currentSlide: slideState.currentSlide,
                        setSlide: setSlide,
                    };
                    return (<CarouselButton
                        {...buttonProps}
                        key={idx.toString()}
                    />);
                })}
            </div>
        </div>);
}

export function CarouselSlide({
    id, lineImage, title, description, backgroundImage, buttonText, buttonRedirectUrl, backgroundImagePosition, buttonType,
    setOpen,
}: CarouselSlideProps) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const bgImageMap = new Map([
        [ 'Bottom Right', classes.carouselSlideBackground ],
        [ 'Bottom Left', classes.carouselSlideBackground2 ],
    ]);
    const buttonTypeMap = new Map([
        [ 'Arrow', <ArrowForwardIcon key={'carouselSlideButton ' + id} /> ],
        [ 'Download', <DownloadOutlinedIcon key={'carouselSlideButton ' + id} /> ],
        [ 'No Icon', undefined ],
    ]);
    const slideItemsMap = new Map([
        [ 'Bottom Right', classes.carouselSlideItems ],
        [ 'Bottom Left', classes.carouselSlideItems2 ],
    ]);
    return (<div
        data-cy={`slide ${id}`}
        className={backgroundImagePosition ? bgImageMap.get(backgroundImagePosition) : classes.carouselSlideBackground}
        style={{ backgroundImage: 'url("' + backgroundImage?.toString() + '")' }}>
        <div className={classes.closeButton}>
            <IconButton
                aria-label={'button to close carousel ' + id}
                size="small"
                onClick={() => {
                    setOpen(false);
                    localStorage.setItem('cloudRPA_carousel_enabled', 'false');
                }}>
                <CloseIcon />
            </IconButton>
        </div >
        <div className={backgroundImagePosition ? slideItemsMap.get(backgroundImagePosition) : classes.carouselSlideItems}>
            {title && <div className={classes.title}>
                {title}
            </div>}
            {description &&
                <div
                    className={classes.description}
                    style={{ WebkitLineClamp: lineImage ? 1 : 3 }}>
                    {description}
                </div>}
            {lineImage && <div className={classes.lineImage}>
                <img
                    src={lineImage}
                    className={classes.lineImageInner}
                    alt={translate({ id: 'CLIENT_CAROUSEL_LINE_IMAGE' })} />
            </div>}
            {buttonText && <div className={classes.slideButton}>
                <CustomButton
                    endIcon={buttonType ? buttonTypeMap.get(buttonType) : undefined}
                    data-cy={`slide-button-` + title}
                    aria-label={'button on slide ' + title}
                    href={buttonRedirectUrl}
                    style={{ fontSize: '14px' }}>
                    {buttonText}
                </CustomButton>
            </div>}
        </div>
    </div>);
}

export function CarouselButton({
    idx, currentSlide, setSlide,
}: CarouselButtonProps) {
    const handleClick = () => {
        setSlide({ currentSlide: idx });
    };
    const { formatMessage: translate } = useIntl();
    return (currentSlide !== idx ? (<CustomButtonUnfilled
        aria-label={translate({ id: 'CLIENT_UNFILLED_CAROUSEL_BUTTON' }, { 0: idx.toString() })}
        size="small"
        onClick={() => {
            handleClick();
        }}>
        <CustomIconUnfilled />
    </CustomButtonUnfilled>) : (<CustomButtonFilled
        aria-label={translate({ id: 'CLIENT_FILLED_CAROUSEL_BUTTON' })}
        size="small"
        onClick={() => {
            handleClick();
        }}>
        <CustomIconFilled />
    </CustomButtonFilled>));
}
