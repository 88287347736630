import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import {
    isEqual,
    omit,
} from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import { getSortedUserBundleConfigurations } from '../../../common/constants/LicensingConfig';
import * as RouteNames from '../../../common/constants/RouteNames';
import {
    DirectoryEntityType,
    IDirectoryEntry,
} from '../../../common/interfaces/cis/directory';
import { IUserCIS } from '../../../common/interfaces/cis/user';
import { SourceFilters } from '../../../services/identity/DirectoryService';
import {
    getAccountLicenseAllocation,
    ISubmitUsersAllocations,
    putUserLicenseAllocation,
    retrieveExplicitAllocationsForUserIds,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import { EnableUserLicensingSelector } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { IUserLicenseAllocation } from '../../users/interfaces/userLicense';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        topMargin: { marginTop: '28px' },
        selectLicenses: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '20px',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

interface ILicenseExplicitAllocation {
    emails: IDirectoryEntry[];
    userBundleLicenses: string[];
    useExternalLicense: boolean;
}

const AddUserAllocationComponent: React.FC<{
    location?: { state?: { users: IUserCIS[]; previousLocation: string } };
}> = ({ location }) => {
    const EnableExternalUserLicense = useFeatureFlagValue(Features.EnableExternalUserLicense.name);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const createDialog = useShowDialog();
    const { token } = useUserInfo();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const [ users, previousLocation ] = useMemo(
        () => [ location?.state?.users, location?.state?.previousLocation ],
        [ location ],
    );

    const {
        open, close,
    } = useModalState(previousLocation ?? RouteNames.Licensing);

    const [ loading, setLoading ] = useState(false);

    const confirmAllocationOverrideDialog = useCallback(
        async (userEmails: string) => {
            return await createDialog({
                title: `${translate({ id: 'CLIENT_USER_LICENSE_ALLOCATION' })}`,
                body: (
                    <div>
                        <p>
                            {translate({ id: 'CLIENT_OVERRIDE_EXPLICIT_ALLOCATION_P1' })}
                        </p>
                        <p>
                            {userEmails}
                        </p>
                        <p>
                            {translate({ id: 'CLIENT_OVERRIDE_EXPLICIT_ALLOCATION_P2' })}
                        </p>
                    </div>
                ),
                icon: 'warning',
                primaryButtonText: `${translate({ id: 'CLIENT_SAVE' })}`,
                showCancel: true,
            });
        },
        [ translate, createDialog ],
    );

    const methods = useForm<ILicenseExplicitAllocation>({
        mode: 'onChange',
        defaultValues: {
            emails:
                users?.map(user => ({
                    identifier: user.id,
                    identityName: user.email,
                    email: user.email,
                    displayName: user.email,
                    objectType: 'LocalUser',
                    source: 'local',
                    type: DirectoryEntityType.user,
                    chipType: 'resolved',
                })) ?? [],
            userBundleLicenses: [],
            useExternalLicense: false,
        },
    });

    const {
        control, getValues, setValue, handleSubmit, setError, clearErrors, formState,
    } = useMemo(
        () => methods,
        [ methods ],
    );

    const { isDirty } = formState;

    const {
        data: availableUserBundleLicensesForAccount,
        isValidating: availableUserBundleLicensesForAccountIsLoading,
    } = useSWR<IUserLicenseAllocation[], Error>(userLicenseUrl, getAccountLicenseAllocation);

    useEffect(() => {
        if (!EnableUserLicensing) {
            console.warn('Feature not enabled or missing data. Closing...');
            history.push('/invalidurl');
        }
    }, [ EnableUserLicensing, history ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: ILicenseExplicitAllocation) => {
            setLoading(true);
            const userIds = data.emails.map(directoryEntry => directoryEntry.identifier);
            const userLicenses = await retrieveExplicitAllocationsForUserIds(userIds);
            const usersWithOverride = data.emails
                .filter(e => userLicenses.some(ul => ul.id === e.identifier))
                .map(e => e.email);

            if (usersWithOverride && usersWithOverride.length) {
                const proceed = await confirmAllocationOverrideDialog(usersWithOverride.join(', '));
                if (!proceed) {
                    setLoading(false);
                    return;
                }
            }

            if (data.emails.length === 0) {
                setError('emails', { type: 'invalid' });
                setLoading(false);
                return;
            }

            const oneUserEmail = data.emails.length === 1 ? data.emails[0].displayName : '';

            const payload: ISubmitUsersAllocations = {
                userIds: data.emails.map(directoryEntry => directoryEntry.identifier),
                licenseCodes: data.useExternalLicense ? [] : data.userBundleLicenses,
                useExternalLicense: data.useExternalLicense,
            };

            try {
                await putUserLicenseAllocation(payload);
            } catch (error) {
                createNotification(
                    translate(
                        { id: `CLIENT_ALLOCATION_EXPLICITLY_SUBMIT_FAILED${oneUserEmail ? '' : '_MULTIPLE'}` },
                        { 0: oneUserEmail },
                    ),
                    notificationType.ERROR,
                );
                close();
                return;
            }

            createNotification(
                translate(
                    { id: `CLIENT_ALLOCATION_EXPLICITLY_SUBMIT${oneUserEmail ? '' : '_MULTIPLE'}` },
                    { 0: oneUserEmail },
                ),
            );

            await mutate(trialServicesSwrKey);
            setLoading(false);
            close(true);
        },
        [ close, createNotification, translate, setError, confirmAllocationOverrideDialog ],
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);
    const filteredAvailableProducts = useMemo(() => {
        if (availableUserBundleLicensesForAccount && Array.isArray(availableUserBundleLicensesForAccount)) {
            return availableUserBundleLicensesForAccount
                .filter(p => p.allocated !== 0 || p.total !== 0)
                .filter(p => validUserBundleCodes.includes(p.code));
        }
        return [];
    }, [ availableUserBundleLicensesForAccount, validUserBundleCodes ]);

    const orgHasUserLicenses = useMemo(
        () => EnableExternalUserLicense || filteredAvailableProducts.length > 0,
        [ EnableExternalUserLicense, filteredAvailableProducts.length ],
    );

    const peoplePickerElementCallback = useCallback(
        (ref: HTMLPortalPeoplePickerElement | null) => {
            ref?.addEventListener('peoplePickerChanged', (event: any) => {
                const prevWithoutChip =
                    getValues('emails')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
                const curWithoutChip = event.detail.data.map((chip: IDirectoryEntry) => {
                    return omit(chip, [ 'optionId', 'chipType' ]);
                });
                const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
                setValue('emails', event.detail.data, {
                    shouldDirty: valuesChanged,
                    shouldValidate: valuesChanged,
                });
            });

            ref?.addEventListener('peoplePickerError', (event: any) => {
                if (event.detail) {
                    setError('emails', { type: 'invalid' });
                } else {
                    clearErrors('emails');
                }
            });

            ref?.addEventListener('peoplePickerLoading', (event: any) => setLoading(event.detail));
        },
        [ clearErrors, getValues, setError, setValue ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ALLOCATE_LICENSES' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={availableUserBundleLicensesForAccountIsLoading || !availableUserBundleLicensesForAccount}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    {orgHasUserLicenses && (
                        <>
                            <div className={classes.topMargin} />
                            <Controller
                                control={control}
                                name="emails"
                                render={props => (
                                    <portal-people-picker
                                        ref={peoplePickerElementCallback}
                                        token={token || undefined}
                                        source-filters={JSON.stringify([
                                            'localUsers',
                                            'directoryUsers',
                                        ] as SourceFilters[])}
                                        value={JSON.stringify(props.value)}
                                        max-height="200px"
                                    />
                                )}
                            />
                            <Typography className={classes.selectLicenses}>
                                {translate({ id: 'CLIENT_SELECT_LICENSES' })}
                            </Typography>
                        </>
                    )}
                    <LicensingAllocationFormComponent
                        availableUserBundles={
                            availableUserBundleLicensesForAccount &&
                            Array.isArray(availableUserBundleLicensesForAccount)
                                ? filteredAvailableProducts
                                : undefined
                        }
                        allocatedUserBundles={[]}
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default AddUserAllocationComponent;
