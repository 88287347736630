import { merge } from 'lodash';

import { NavCustomizationSetting } from '../../common/constants/NavCustomizationConstant';
import { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import {
    defaultNavCustomizationData,
    INavCustomizationData,
} from '../../component/organizationsettings/subcomponents/navigationCustomization/NavCustomizationComponent';
import { ISetting } from '../../services/identity/SettingService';

export const mapSettingArrayToNavCustomizationData = (fetchedSettings: ISetting[]): INavCustomizationData => {
    const appsHidden = fetchedSettings.find((setting) => setting.key === NavCustomizationSetting.AppsHidden);
    const resourceCenterHidden = fetchedSettings.find(
        (setting) => setting.key === NavCustomizationSetting.ResourceCenterHidden,
    );

    return merge({}, defaultNavCustomizationData, {
        appsHidden: appsHidden?.value === undefined ? undefined : appsHidden.value === 'true',
        resourceCenterHidden: resourceCenterHidden?.value === undefined ? undefined : resourceCenterHidden.value === 'true',
    });
};

export function mapNavCustomizationDataToKeyValuePairs(data: INavCustomizationData): IKeyValuePair[] {
    return [
        {
            key: NavCustomizationSetting.AppsHidden,
            value: data.appsHidden.toString(),
        },
        {
            key: NavCustomizationSetting.ResourceCenterHidden,
            value: data.resourceCenterHidden.toString(),
        },
    ];
}
