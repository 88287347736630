import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import produce from 'immer';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import { AuthSettingKey } from '../../../common/constants/AuthSettingConstant';
import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import {
    getSetting,
    ISetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';
import {
    mapAADConfigDataToExternalIdentityPayload,
    mapADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToAADConfigData,
    mapExternalIdentityPayloadToADConfigData,
    mapExternalIdentityPayloadToErrorCode,
    mapExternalIdentityPayloadToGoogleConfigData,
    mapExternalIdentityPayloadToSamlConfigData,
    mapGoogleConfigDataToExternalIdentityPayload,
    mapSamlConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import {
    mapAuthSettingsRestrictionsDataToKeyValuePairs,
    mapSettingArrayToAuthSettingsRestrictionsData,
} from '../../../util/setting/AuthSettingUtil';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        centerLoader: { margin: 'auto' },
        radioPrimary: {
            fontWeight: 600,
            fontSize: '14px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px',
        },
        spacer: { marginTop: '8px' },
        buttonWidth: { width: '160px' },
        link: {
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        ssoConfigureButton: { marginRight: '8px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        authSettingsPage: { marginTop: '14px' },
        button: {
            width: 'fit-content',
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        content: { padding: '0 12px' },
        header: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        headerRevamp: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        switchLabel: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        switchMargin: { marginLeft: '12px' },
        switchDescription: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '4px',
        },
        switchDescriptionPadding: { marginLeft: '46px' },
        signInContainer: { marginBottom: '24px' },
        hostEnforced: { fontWeight: 400 },
    }),
}));

export interface IAuthSettingsData {
    enableBasicAuthentication?: boolean;
    enableBasicAuthenticationForHostTenant?: boolean;
    google?: boolean;
    aad?: boolean;
    saml2?: boolean;
    ad?: boolean;
}

export const initialData = {
    enableBasicAuthentication: true,
    enableBasicAuthenticationForHostTenant: true,
    google: false,
    aad: false,
    saml2: false,
    ad: false,
};

export const useExternalIdentityProvidersForm = (reset: (_?: any) => void) => {
    const { formatMessage: translate } = useIntl();

    const { enqueueSnackbar } = useSnackbar();

    const isHostMode = useSelector(isHostModeSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);

    const keys = useMemo(
        () => [
            ...(isHostMode ? [ AuthSettingKey.EnableBasicAuthenticationForHostTenant ] : []),
            AuthSettingKey.RestrictBasicAuthentication,
        ],
        [ isHostMode ],
    );

    const {
        data: fetchedSettings, isValidating, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const fetchedExternalIdentityGoogle = useExternalIdentity(ExternalAuthenticationScheme.Google);
    const fetchedExternalIdentityAzureAD = useExternalIdentity(ExternalAuthenticationScheme.AzureAD);
    const fetchedExternalIdentitySaml = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const fetchedExternalIdentityAD = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    useEffect(() => {
        if (fetchedSettings
                || fetchedExternalIdentityGoogle
                || fetchedExternalIdentityAzureAD
                || fetchedExternalIdentitySaml
                || fetchedExternalIdentityAD) {
            const settings = fetchedSettings ? mapSettingArrayToAuthSettingsRestrictionsData(fetchedSettings) : undefined;
            reset(produce(initialData, draftState => {
                if (settings) {
                    draftState.enableBasicAuthentication = !settings.restrictBasicAuthentication;
                    draftState.enableBasicAuthenticationForHostTenant = settings.enableBasicAuthenticationForHostTenant ?? true;
                }
                if (fetchedExternalIdentitySaml?.isActive) {
                    draftState.saml2 = fetchedExternalIdentitySaml.isActive;
                }
                if (fetchedExternalIdentityAD?.isActive) {
                    draftState.ad = fetchedExternalIdentityAD.isActive;
                }
                if (fetchedExternalIdentityGoogle?.isActive) {
                    draftState.google = fetchedExternalIdentityGoogle.isActive;
                }
                if (fetchedExternalIdentityAzureAD?.isActive) {
                    draftState.aad = fetchedExternalIdentityAzureAD.isActive;
                }
            }));
        }
    }, [
        fetchedExternalIdentityAD,
        fetchedExternalIdentityAzureAD,
        fetchedExternalIdentityGoogle,
        fetchedExternalIdentitySaml,
        fetchedSettings,
        reset,
    ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const externalProviderAPI = useCallback(async (payload, fetchedExternalIdentity) => {
        const newExternalIdentityConfig = fetchedExternalIdentity
            ? await updateExternalIdentityProvider(payload)
            : await createExternalIdentityProvider(payload);

        mapExternalIdentityPayloadToErrorCode(newExternalIdentityConfig);
    }, []);

    const onSubmit = useCallback(
        async (data: IAuthSettingsData) => {
            try {
                setLoading(true);
                const authSettingsRestrictionsData = {
                    restrictBasicAuthentication: !data.enableBasicAuthentication,
                    enableBasicAuthenticationForHostTenant: data.enableBasicAuthenticationForHostTenant,
                };
                const settings = fetchedSettings ? mapSettingArrayToAuthSettingsRestrictionsData(fetchedSettings) : undefined;
                if (authSettingsRestrictionsData.restrictBasicAuthentication !== settings?.restrictBasicAuthentication
                    || authSettingsRestrictionsData.enableBasicAuthenticationForHostTenant
                        !== settings?.enableBasicAuthenticationForHostTenant) {
                    await saveSetting(settingUrl, {
                        settings: mapAuthSettingsRestrictionsDataToKeyValuePairs(authSettingsRestrictionsData),
                        partitionGlobalId,
                    });
                }

                if (fetchedExternalIdentityGoogle && data.google !== fetchedExternalIdentityGoogle.isActive) {
                    const googleConfigData = mapExternalIdentityPayloadToGoogleConfigData(fetchedExternalIdentityGoogle);
                    googleConfigData.isActive = data.google;
                    const payload = mapGoogleConfigDataToExternalIdentityPayload(googleConfigData, fetchedExternalIdentityGoogle);
                    externalProviderAPI(payload, fetchedExternalIdentityGoogle);
                }
                if (fetchedExternalIdentityAzureAD && data.aad !== fetchedExternalIdentityAzureAD.isActive) {
                    const azureAdConfigData = mapExternalIdentityPayloadToAADConfigData(fetchedExternalIdentityAzureAD);
                    azureAdConfigData.isActive = data.aad;
                    const payload = mapAADConfigDataToExternalIdentityPayload(azureAdConfigData, fetchedExternalIdentityAzureAD);
                    externalProviderAPI(payload, fetchedExternalIdentityAzureAD);
                }
                if (fetchedExternalIdentityAD && data.ad !== fetchedExternalIdentityAD.isActive) {
                    const adConfigData = mapExternalIdentityPayloadToADConfigData(fetchedExternalIdentityAD);
                    adConfigData.isActive = data.ad;
                    const payload = mapADConfigDataToExternalIdentityPayload(adConfigData, fetchedExternalIdentityAD);
                    externalProviderAPI(payload, fetchedExternalIdentityAD);
                }
                if (fetchedExternalIdentitySaml && data.saml2 !== fetchedExternalIdentitySaml.isActive) {
                    const samlConfigData = mapExternalIdentityPayloadToSamlConfigData(fetchedExternalIdentitySaml);
                    samlConfigData.isActive = data.saml2;
                    const payload = mapSamlConfigDataToExternalIdentityPayload(samlConfigData, fetchedExternalIdentitySaml);
                    externalProviderAPI(payload, fetchedExternalIdentitySaml);
                }
                reset(data);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
                mutate();
                setLoading(false);
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            } finally {
                setLoading(false);
            }
        }, [
            createNotification,
            externalProviderAPI,
            fetchedExternalIdentityAD,
            fetchedExternalIdentityAzureAD,
            fetchedExternalIdentityGoogle,
            fetchedExternalIdentitySaml,
            fetchedSettings,
            mutate,
            partitionGlobalId,
            reset,
            translate,
        ],
    );

    return {
        onSubmit,
        loading: loading || isValidating,
    };
};

export const ExternalIdentityProvidersFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const getRoute = useRouteResolver();

    const isHostMode = useSelector(isHostModeSelector);

    const {
        control, watch, setValue,
    } = useFormContext();

    const fetchedExternalIdentityGoogle = useExternalIdentity(ExternalAuthenticationScheme.Google);
    const fetchedExternalIdentityAzureAD = useExternalIdentity(ExternalAuthenticationScheme.AzureAD);
    const fetchedExternalIdentitySaml = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const fetchedExternalIdentityAD = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            setValue(name, checked, { shouldDirty: true });
            callback(checked);
        },
        [ setValue ],
    );

    const handleEditPasswordPolicy = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsPasswordPolicy));
    }, [ getRoute, history ]);

    const handleConfigureGoogle = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureGoogle));
    }, [ getRoute, history ]);

    const handleConfigureAAD = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureAAD));
    }, [ getRoute, history ]);

    const handleConfigureSaml = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureSaml));
    }, [ getRoute, history ]);

    const handleConfigureAD = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureAD));
    }, [ getRoute, history ]);

    return <>
        <FormControl
            component='fieldset'
            className={classes.signInContainer}>
            <Typography
                role='heading'
                aria-level={2}
                component='legend'
                className={classes.headerRevamp}>
                {translate({ id: 'CLIENT_SIGN_IN_OPTIONS_LOCAL' })}
            </Typography>
            <FormGroup>
                <Controller
                    name="enableBasicAuthentication"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.switchMargin}
                                    data-cy='auth-settings-basic-sign-in-switch'
                                    checked={props.value}
                                    onChange={e => {
                                        handleSwitch(
                                            'enableBasicAuthentication',
                                            e.target.checked,
                                            props.onChange,
                                        );
                                        if (e.target.checked === true) {
                                            setValue('enableBasicAuthenticationForHostTenant', true);
                                        }
                                    }}
                                />
                            }
                            label={
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>
                                    <Typography className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_BASIC_SIGN_IN' })}
                                    </Typography>

                                </div>
                            }
                        />
                    )} />
                <Typography className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                    {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_BASIC' })}
                </Typography>
                <Button
                    className={clsx(classes.button, classes.switchDescriptionPadding)}
                    variant='outlined'
                    onClick={handleEditPasswordPolicy}
                    data-cy="authsettings-edit-password-policy-button">
                    {translate({ id: 'CLIENT_EDIT_PASSWORD_POLICY' })}
                </Button>
                {isHostMode && <Controller
                    name="enableBasicAuthenticationForHostTenant"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            className={classes.switchDescriptionPadding}
                            control={
                                <Checkbox
                                    disabled={!watch('enableBasicAuthentication')}
                                    checked={props.value}
                                    onChange={e => handleSwitch(
                                        'enableBasicAuthenticationForHostTenant',
                                        e.target.checked,
                                        props.onChange,
                                    )}
                                    color="primary"
                                    data-cy="authsettings-restrictions-enable-basic-authentication-for-host-tenant"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ENABLE_BASIC_AUTHENTICATION_FOR_HOST_TENANT' })}
                                </div>
                            }
                        />
                    )}
                />}
                {(isHostMode || watch('google')) && <>
                    <Controller
                        name="google"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-google-switch'
                                        disabled={!isHostMode}
                                        checked={props.value}
                                        onChange={e => handleSwitch('google', e.target.checked, props.onChange)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_GOOGLE_SSO' })}
                                            {!isHostMode && watch('google') && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </Typography>

                                    </>
                                }
                            />
                        )}
                    />
                    <Typography className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Google' })}
                    </Typography>
                    <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureGoogle}
                        data-cy="authsettings-edit-password-policy-button">
                        {translate({ id: fetchedExternalIdentityGoogle ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </>}
                {(isHostMode || watch('aad')) && <>
                    <Controller
                        name="aad"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-azure-ad-switch'
                                        disabled={!isHostMode}
                                        checked={props.value}
                                        onChange={e => handleSwitch('aad', e.target.checked, props.onChange)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_AZURE_AD_SSO' })}
                                            {!isHostMode && watch('aad') && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </Typography>

                                    </>
                                }
                            />
                        )}
                    />
                    <Typography className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Azure AD' })}
                    </Typography>
                    <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureAAD}
                        data-cy="authsettings-configure-aad-button">
                        {translate({ id: fetchedExternalIdentityAzureAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </>}
                {(isHostMode || watch('ad')) && <>
                    <Controller
                        name="ad"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-ad-switch'
                                        disabled={!isHostMode}
                                        checked={props.value}
                                        onChange={e => handleSwitch('ad', e.target.checked, props.onChange)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_AD_INTEGRATION' })}
                                            {!isHostMode && watch('ad') && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </Typography>

                                    </>
                                }
                            />
                        )}
                    />
                    <Typography className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'Active Directory' })}
                    </Typography>
                    <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureAD}
                        data-cy="authsettings-configure-ad-button">
                        {translate({ id: fetchedExternalIdentityAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </>}
                {(isHostMode || watch('saml2')) && <>
                    <Controller
                        name="saml2"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switchMargin}
                                        data-cy='auth-settings-saml2-switch'
                                        disabled={!isHostMode}
                                        checked={props.value}
                                        onChange={e => handleSwitch('saml2', e.target.checked, props.onChange)}
                                    />
                                }
                                label={
                                    <>
                                        <Typography className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_SAML_SSO' })}
                                            {!isHostMode && watch('saml2') && <span className={classes.hostEnforced}>
                                                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                                                &nbsp;({translate({ id: 'CLIENT_HOST_ENFORCED' })})
                                            </span>}
                                        </Typography>
                                    </>
                                }
                            />

                        )}
                    />
                    <Typography className={clsx(classes.switchDescription, classes.switchDescriptionPadding)}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_SSO_ONLY_REVAMP' }, { ssoAccount: 'SAML' })}
                    </Typography>
                    <Button
                        className={clsx(classes.button, classes.switchDescriptionPadding)}
                        variant='outlined'
                        onClick={handleConfigureSaml}
                        data-cy='auth-settings-edit-password-policy-button'>
                        {translate({ id: fetchedExternalIdentitySaml ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </>}
            </FormGroup>
        </FormControl>
    </>;
};
