import { IChangePasswordDto } from '../../common/interfaces/cis/account';
import {
    get,
    post,
} from '../utility/Requests.default';

export const accountUrl = `${process.buildConfigs.identityApiBaseRoute}/Account`;

export function getClientAccessToken() {
    return get<string>(`${accountUrl}/ClientAccessToken`);
}

export function changePassword(changePasswordDto: IChangePasswordDto) {
    return post(`${accountUrl}/ChangePassword`, { body: changePasswordDto });
}
