import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { isGuid } from '../../util/TypeUtil';

const invalidOrganizationNames = [ 'portal_', 'host' ];

export default function useAuthParams() {
    const location = useLocation();

    const [ organizationId, organizationName ] = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const path = location.pathname.split('/');
        return [ searchParams.get('organizationId'), path[1] ];
    }, [ location.search, location.pathname ]);

    const authParams = useMemo(() => {
        const acr_values =
      organizationId && isGuid(organizationId)
          ? `tenant:${organizationId}`
          : organizationName && invalidOrganizationNames.indexOf(organizationName) === -1
              ? `tenantName:${organizationName}`
              : undefined;

        return { acr_values };
    }, [ organizationName, organizationId ]);

    return authParams;
}
