import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import {
    companyName,
    isHostModeSelector,
    profile,
} from '../../store/selectors';
import UiCopyButton from '../common/UiCopyButton';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import AdminBreadCrumbs from './AdminBreadCrumbs';
import GeneralSettingsTabContent from './subcomponents/GeneralSettingsTabContent';
import OrganizationSettingsTabs from './subcomponents/OrganizationSettingsTabs';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        generalSettingsTabContent: { marginTop: '-8px' },
        supportIDAction: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        supportID: {
            fontWeight: 600,
            marginLeft: '4px',
            marginRight: '4px',
        },
    }),
);

export const OrganizationSettingsPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const accountProfile = useSelector(profile);
    const organizationName = useSelector(companyName);
    const hostMode = useSelector(isHostModeSelector);

    const { licenseCode: supportId } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName ?? translate({ id: 'CLIENT_HOST' }),
        },
        {
            link: RouteNames.OrganizationSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    return isAdminRevampEnabled
        ? (<UiPageContainer
            position="center"
            header={AdminBreadCrumbs(breadCrumbLinks)}
            primaryActions={
                !process.buildConfigs.hideForMSI && supportId !== 'Unlicensed' && <div className={classes.supportIDAction}>
                    <Typography
                        data-cy="organization-settings-primary-supportId">
                        {`${translate({ id: 'CLIENT_SUPPORT_ID' })}:`}
                    </Typography>
                    <Typography className={classes.supportID}>
                        {supportId}
                    </Typography>
                    <UiCopyButton
                        textToCopy={supportId}
                        aria-label={translate({ id: 'CLIENT_COPY_SUPPORT_ID' })}
                        data-cy="organization-settings-form-primary-supportId-copy-button"
                    />
                </div>
            }
        >
            <div style={{
                maxWidth: '900px',
                height: '100%',
            }}>
                <OrganizationSettingsTabs />
            </div>
        </UiPageContainer>
        )
        :
        (<UiPanel
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={<UiTrialPerSkuLicenseInGracePeriodBanners />}
            licenseOverAllocationBanner={<UiOverAllocationBanner />}
            header={{ title: translate({ id: hostMode ? 'CLIENT_GLOBAL_SETTINGS' : 'CLIENT_ACCOUNT_SETTINGS' }) }}
            data-cy="account-settings-panel"
        >
            {process.buildConfigs.adminRevampOnPrem
                ? <OrganizationSettingsTabs />
                : (<div className={classes.generalSettingsTabContent}>
                    <GeneralSettingsTabContent />
                </div>)}
        </UiPanel>
        );
};

export default ({ route }: { route?: any }) => (
    <>
        <OrganizationSettingsPageComponent />
        {renderRoutes(route.routes)}
    </>
);
