import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import { Button } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    AuthSettingPasswordKey,
    AuthSettingUserLockOutKey,
    ShouldChangePassword,
} from '../../../common/constants/AuthSettingConstant';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { ISecurityAuthenticationSettingsData } from '../../../common/interfaces/authSetting';
import {
    getSetting,
    ISetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapSecurityAuthSettingDataToKeyValuePairs,
    mapSettingArrayToSecurityAuthSettingsData,
} from '../../../util/setting/AuthSettingUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import PasswordPolicyForm from './PasswordPolicyForm';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
);

export const defaultSecurityAuthenticationSettingsData: ISecurityAuthenticationSettingsData = {
    password: {
        defaultExpirationDays: 0,
        previousUseLimit: 0,
        shouldChangePasswordAfterFirstLogin: ShouldChangePassword.NotRequired,
        passwordComplexity: {
            hasSpecialCharacter: false,
            hasLowercase: false,
            hasUppercase: false,
            hasDigit: false,
            Length: 6,
        },
    },
    userLockOut: {
        isEnabled: false,
        defaultAccountLockoutSeconds: 0,
        maxFailedAccessAttemptsBeforeLockout: 2,
    },
};

const EditPasswordPolicyComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const partitionGlobalId = useSelector(accountGlobalId);

    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);

    const methods = useForm<ISecurityAuthenticationSettingsData>({
        mode: 'onSubmit',
        defaultValues: defaultSecurityAuthenticationSettingsData,
    });
    const {
        handleSubmit, reset, formState: { isDirty },
    } = methods;

    const keys = useMemo(
        () => [
            AuthSettingPasswordKey.DefaultExpirationDays,
            AuthSettingPasswordKey.PasswordComplexity,
            AuthSettingPasswordKey.PreviousUseLimit,
            AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
            AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
            AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
            AuthSettingUserLockOutKey.IsEnabled,
        ],
        [],
    );
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToSecurityAuthSettingsData(fetchedSettings));
        }
    }, [ reset, fetchedSettings ]);

    const {
        open, close,
    } = useModalState(EnableSecuritySettingsRevamp ? RouteNames.SecuritySettings : RouteNames.AuthSettings);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: ISecurityAuthenticationSettingsData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapSecurityAuthSettingDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
                close();
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ close, createNotification, mutate, partitionGlobalId, translate ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_EDIT_PASSWORD_POLICY' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="medium"
            loading={!fetchedSettings}
            themeProps={{ disableGutters: [ 'bottom', 'right' ] }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            onClick={() => close()}
                            color="primary"
                            data-cy="password-policy-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            // className={classes.saveButton}
                            disabled={!isDirty}
                            type="submit"
                            variant="contained"
                            loading={false}
                            data-cy="password-policy-save-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
                addScrollPadding
            >
                <FormProvider {...methods}>
                    <PasswordPolicyForm />
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default EditPasswordPolicyComponent;
