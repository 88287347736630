import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useRouteResolver,
} from '@experiences/util';
import {
    Button,
    duration,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { getSortedUserBundleConfigurations } from '../../../common/constants/LicensingConfig';
import * as RouteNames from '../../../common/constants/RouteNames';
import { IUserWithExplicitAllocations } from '../../../common/interfaces/licenses';
import {
    getEditableUserLicense,
    ISubmitUsersAllocations,
    putUserLicenseAllocation,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import { EnableUserLicensingSelector } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { IAvailableUserLicense } from '../../users/interfaces/userLicense';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        selectLicenses: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '28px',
            marginBottom: '2px',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

export enum LicenseAllocationMethod {
    EXPLICIT = 'explicitlyAllocated',
    GROUP = 'groupAllocation',
}

export interface IUserLicenseExplicitAllocation {
    userBundleLicenses: string[];
    useExternalLicense: boolean;
    licenseAllocationMethod: LicenseAllocationMethod;
}

const EditUserAllocationComponent: React.FC<{
    location?: { state?: { user: IUserWithExplicitAllocations; previousLocation: string } };
}> = ({ location }) => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const getRoute = useRouteResolver();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const [ user, previousLocation ] = useMemo(
        () => [ location?.state?.user!, location?.state?.previousLocation ],
        [ location ],
    );

    const {
        open, close,
    } = useModalState(previousLocation ?? getRoute(RouteNames.Licensing));

    const [ loading, setLoading ] = useState(false);

    const methods = useForm<IUserLicenseExplicitAllocation>({
        mode: 'onSubmit',
        defaultValues: {
            licenseAllocationMethod: LicenseAllocationMethod.EXPLICIT,
            userBundleLicenses: [],
            useExternalLicense: false,
        },
    });

    const {
        handleSubmit, formState, reset,
    } = useMemo(() => methods, [ methods ]);

    const { isDirty } = formState;

    const { data: availableUserLicenses } = useSWR<IAvailableUserLicense, Error>(userLicenseUrl, () =>
        getEditableUserLicense(user.id),
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);

    const [ allocatedUserBundles, availableUserBundles, useExternalLicense, allocationType, licenseInheritance ] =
        useMemo(
            () => [
                availableUserLicenses?.allocatedUserBundles,
                availableUserLicenses?.availableUserBundles
                    ?.filter(p => p.allocated !== 0 || p.total !== 0)
                    .filter(p => validUserBundleCodes.includes(p.code)),
                availableUserLicenses?.useExternalLicense ?? false,
                availableUserLicenses?.explicitAllocation
                    ? LicenseAllocationMethod.EXPLICIT
                    : LicenseAllocationMethod.GROUP,
                availableUserLicenses?.groupLicenseInheritance,
            ],
            [ availableUserLicenses, validUserBundleCodes ],
        );

    useEffect(() => {
        if (!EnableUserLicensing || !user) {
            console.warn('Feature not enabled or missing data. Closing...');
            history.push('/invalidurl');
        }
    }, [ EnableUserLicensing, history, user ]);

    useEffect(() => {
        if (allocatedUserBundles) {
            reset({
                userBundleLicenses: allocatedUserBundles,
                useExternalLicense: useExternalLicense,
                licenseAllocationMethod: allocationType,
            });
        }
    }, [ allocatedUserBundles, useExternalLicense, allocationType, reset ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: IUserLicenseExplicitAllocation) => {
            setLoading(true);

            const payload: ISubmitUsersAllocations = {
                userIds: [ user.id ],
                licenseCodes: data.useExternalLicense ? [] : data.userBundleLicenses,
                useExternalLicense: data.useExternalLicense,
            };

            try {
                await putUserLicenseAllocation(payload);
            } catch (error) {
                close();
                return;
            }

            createNotification(
                translate(
                    { id: 'CLIENT_SUCCESS_EDITING_EXPLICIT_ALLOCATIONS' },
                    { 0: `${user.name} ${user.surname ?? ''}` },
                ),
            );
            await mutate(trialServicesSwrKey);
            setLoading(false);
            close(true);
        },
        [ user, close, createNotification, translate ],
    );

    const userDisplayName = useMemo(() => user?.displayName ?? `${user?.name} ${user?.surname ?? ''}`, [ user ]);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_EDIT_EXPLICIT_ALLOCATIONS_FOR_USER' }, { 0: userDisplayName })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
                transitionDuration: history.action !== 'POP' ? duration.enteringScreen : 0,
            }}
            loading={!availableUserLicenses}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    <Typography className={classes.selectLicenses}>
                        {translate({ id: 'CLIENT_USER_LICENSE_ALLOCATION' })}
                    </Typography>
                    <LicensingAllocationFormComponent
                        availableUserBundles={availableUserBundles}
                        allocatedUserBundles={allocatedUserBundles}
                        groupLicenseInheritance={licenseInheritance}
                        userId={user?.id}
                        withRadioGroup
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default EditUserAllocationComponent;
