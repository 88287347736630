import { Log } from 'oidc-client-ts';
import React, {
    FC,
    useEffect,
} from 'react';
import { AuthProvider } from 'react-oidc-context';

import AuthLogger from './AuthLogger';
import { useAuthConfiguration } from './configuration/AuthConfiguration.default';
import useAuthCallback from './hooks/Callback';

export const PortalAuthenticationProvider: FC = ({ children }) => {
    const configuration = useAuthConfiguration();
    const onSigninCallback = useAuthCallback();

    useEffect(() => {
        const isDebug = localStorage.getItem('EnableAuthLogDebugger') === 'true';

        Log.setLevel(isDebug ? Log.DEBUG : Log.ERROR);
        Log.setLogger(AuthLogger.getInstance());
    }, [ ]);

    return (
        <AuthProvider
            onSigninCallback={onSigninCallback}
            {...configuration}
        >
            {children}
        </AuthProvider>
    );
};
