import { ITrialServices } from '@experiences/interfaces';
import {
    Grid,
    Skeleton,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

import { CurrentTrialsPanel } from './CurrentTrials';
import { FreeTrialsPanel } from './FreeTrialsPanel';

const useStyles = makeStyles(() =>
    createStyles({
        panel: { marginTop: '21px' },
        loadingGrid: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
    }),
);

export const TrialPerSkuPanel: React.FC<{ trialServices: ITrialServices | undefined }> = ({ trialServices }) => {
    const classes = useStyles();

    return trialServices?.freeTrials && trialServices.currentTrials ? (
        <>
            <div className={classes.panel}>
                <FreeTrialsPanel freeTrials={trialServices.freeTrials} />
            </div>
            <div className={classes.panel}>
                <CurrentTrialsPanel currentTrials={trialServices.currentTrials} />
            </div>
        </>
    ) : (
        <Grid
            container
            spacing={3}>
            {[ 1, 2 ].map(number => (
                <Grid
                    key={number}
                    item
                    xs={4}>
                    <div className={classes.loadingGrid}>
                        <div style={{ flex: 0.9 }}>
                            <Skeleton
                                variant="text"
                                width={150}
                                style={{ marginBottom: '8px' }} />
                            <Skeleton
                                variant="rectangular"
                                height={36} />
                        </div>
                        <Skeleton
                            variant="circular"
                            height={28}
                            width={28} />
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
