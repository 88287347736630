import { REQUEST_BASE_ROUTE } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

const EnableXHRWithORGName = getFeatureFlagValue(Features.EnableXHRWithOrgName.name);

export const isPathExactly = (route: string): boolean => {
    const portalBaseRoute = process.buildConfigs.portalBaseRoute;
    return window.location.pathname.endsWith(`${portalBaseRoute}${route}`);
};

export const isPathIncluding = (route: string): boolean => {
    const portalBaseRoute = process.buildConfigs.portalBaseRoute;
    return window.location.pathname.includes(`${portalBaseRoute}${route}`);
};

export const getBasePath = () => {
    const firstPath = window.location.pathname.split('/')[1];
    const accountName = EnableXHRWithORGName && firstPath !== 'portal_' ? `/${firstPath}` : '';

    return `${accountName}${REQUEST_BASE_ROUTE}`;
};
