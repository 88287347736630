import { User } from 'oidc-client-ts';
import {
    useCallback,
    useEffect,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router';

import { setToken } from '../utils/Token';

export default function useCustomEvents() {
    const {
        events, removeUser,
    } = useAuth();
    const history = useHistory();

    const expireSession = useCallback(() => {
        sessionStorage.clear();
        setToken(''); // TODO: remove once token can be accessed async
        removeUser();
        history.push('/portal_/sessionexpired');
    }, []);

    const refreshAccessToken = useCallback((user: User) => {
        setToken(user.access_token);
    }, []);

    useEffect(() => {
        events.addUserSignedOut(expireSession);
        events.addAccessTokenExpired(expireSession);
        events.addUserLoaded(refreshAccessToken);

        return () => {
            events.removeUserSignedOut(expireSession);
            events.removeAccessTokenExpired(expireSession);
            events.removeUserLoaded(refreshAccessToken);
        };
    }, []);
}
