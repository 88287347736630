/* eslint-disable react/no-unstable-nested-components */
import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { portalTelemetry } from '@experiences/telemetry';
import GlobalStyles from '@experiences/theme';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import AddIcon from '@mui/icons-material/Add';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    IconButton,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import clsx from 'clsx';
import produce from 'immer';
import {
    isArray,
    isString,
} from 'lodash';
import React, {
    FC,
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR from 'swr';
import urljoin from 'url-join';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import { notificationType } from '../../common/constants/Constant';
import { TenantRegionDocsLinks } from '../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../common/constants/RouteNames';
import { getFriendlyName } from '../../common/constants/ServicesMapping';
import useApiAccessInstanceModal from '../../common/hooks/useApiAccessInstanceModal';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useTenantOperations } from '../../common/hooks/useTenantOperations';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import { ServiceErrorCode } from '../../common/interfaces/error';
import {
    IService,
    ITenant,
} from '../../common/interfaces/tenant/tenant';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import { getTenantServiceLicenses } from '../../services/licensing/LicenseManagementService';
import {
    getAvailableServices,
    getTenantById,
    IServiceError,
    organizationManagementTenantUri,
    tenantAvailableServicesUri,
} from '../../services/organization/TenantService';
import { post } from '../../services/utility/Requests.default';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isAdminSelector,
    isUnlicensedSelector,
    userGlobalId,
} from '../../store/selectors';
import UiAlertBanner from '../common/UiAlertBanner';
import {
    ICardAction,
    UiMoreActionsComponent,
    UiServiceCard,
} from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiRefreshButton } from '../common/UiRefreshButton';
import { UiTenantStatusBanner } from '../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import {
    IServiceMetadata,
    ITenantServiceLicense,
} from '../tenants/interfaces/service';
import * as manageUserMapping from '../tenants/subcomponents/constant/manageUserMapping.json';
import { addHiddenDependenciesHelper } from '../tenants/subcomponents/helpers/AddHiddenDependenciesHelper';
import { getHiddenServices } from '../tenants/subcomponents/helpers/HiddenServicesHelper';
import { navigateToLicenseConfigure } from '../tenants/subcomponents/helpers/ManageLicensesHelper';
import { isUserManagementEnabled } from '../tenants/subcomponents/helpers/ManageUserHelper';
import {
    getListOfDependencies,
    getListOfHiddenDependencies,
    getListOfParents,
} from '../tenants/subcomponents/helpers/ServiceDependencyGraph';
import { useServiceDependency } from '../tenants/subcomponents/helpers/useServiceDependency';
import ServiceDeleteDialogBody from '../tenants/subcomponents/ServiceDeleteDialogBody';
import { TenantStatusConstants } from '../tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        link: { marginLeft: '8px' },
        spacerText: { marginBottom: '24px' },
        dependencyText: { fontStyle: 'italic' },
        licenseDisabled: {
            '& .iconStroke': { stroke: theme.palette.semantic.colorBorder },
            '& .iconFill': { fill: theme.palette.semantic.colorBorder },
            '& .iconStrokeAndFill': {
                stroke: theme.palette.semantic.colorBorder,
                fill: theme.palette.semantic.colorBorder,
            },
        },
    }),
);

const getTenantConfigureServiceRoute = (
    tenantId: string,
    type: 'configure' | 'enable' | 'addLicenses',
    service?: string) =>
    RouteNames.TenantConfigureService
        .replace(':tenantId', tenantId)
        .replace(':type(configure|enable|addLicenses)', type)
        .replace(':serviceType?', service ?? '');

const TenantServicesComponent: FC = () => {
    const getRoute = useRouteResolver();
    const match = useRouteMatch<{ tenantId: string }>();
    const adminRevampEnabled = useIsAdminRevampEnabled();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const tenantOperations = useTenantOperations();

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const { formatMessage: translate } = useIntl();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();
    const { changeServiceStatus } = useTenantsContext();
    const { servicesToHide } = useServiceDependency();
    const { checkTokenTypeIsAuth0 } = useCheckAuthTypes();
    const {
        ApiAccessInstanceModalComponent, setCurrentTenant,
    } = useApiAccessInstanceModal();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const logicalName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);
    const isAdmin = useSelector(isAdminSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const { tenantId } = useMemo(() => {
        return { tenantId: match.params.tenantId };
    }, [ match ]);

    const createNotification = useUiSnackBar();

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ tenantAvailableServicesUri, accountId, logicalName ],
        getAvailableServices,
    );

    const {
        data: tenant, isValidating, mutate: mutateTenant,
    } = useSWR<ITenant>(adminRevampEnabled ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null, getTenantById);

    const serviceTypes = useMemo(() => {
        if (!tenant?.tenantServiceInstances) {
            return [];
        }

        return tenant.tenantServiceInstances
            .map(service => service.serviceType);
    }, [ tenant ]);

    const { data: tenantServiceLicenseData } = useSWR<ITenantServiceLicense[], Error>(
        tenantId && tenant
            ? [
                `/api/manageLicense/${accountId}/service-licenses/${tenantId}`,
                accountId,
                tenantId,
                serviceTypes,
            ]
            : null,
        getTenantServiceLicenses,
    );

    const tenantHasServiceLicenses = useCallback((service: IService) => {
        const tenantServiceLicenses = tenantServiceLicenseData?.filter(
            tenantServiceLicense => tenantServiceLicense.serviceType === service.serviceType
            && (!process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType) ?? true));
        return !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0);
    }, [ tenantServiceLicenseData ]);

    const clickedManageUsers = useCallback(
        (service: IService) => {
            const configFromJson = (manageUserMapping as { [key: string]: string | undefined })[service.serviceType];
            return configFromJson ?
                urljoin(window.location.origin, logicalName, tenant!.name, configFromJson) :
                urljoin(window.location.origin, logicalName, tenant!.name, service.serviceType + '_', 'users');
        },
        [ logicalName, tenant ],
    );

    const getServiceIcon = useCallback(
        (service: IService) => {
            switch (service.serviceType) {
                case 'connections' : return 'integrations';
                default: return service.serviceType;
            }
        },
        [],
    );

    const showServiceGenericError = useCallback(
        async (results: IServiceError[] | undefined, status: string) => {
            if (!isArray(results)) {
                return [];
            }

            const genericFailures = results?.filter(service => service.ErrorCode !== ServiceErrorCode.InsufficientLicense);
            if (genericFailures?.length) {
                const genericFailureServiceString = genericFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                await createDialog({
                    title: translate({ id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_HEADER' }),
                    body: translate(
                        { id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_BODY' },
                        {
                            status,
                            services: genericFailureServiceString,
                        },
                    ),
                    icon: 'info',
                    showCancel: true,
                });
            }

            return genericFailures;
        },
        [ createDialog, translate ],
    );

    const showServiceLicenseError = useCallback(
        async (results: IServiceError[] | undefined) => {
            if (!isArray(results)) {
                return [];
            }

            const licenseFailures = results?.filter(service => service.ErrorCode === ServiceErrorCode.InsufficientLicense);
            if (licenseFailures?.length) {
                const licenseFailureServiceString = licenseFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_HEADER' }),
                    body: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_BODY' }, { services: licenseFailureServiceString }),
                    icon: 'info',
                    primaryButtonText: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                    showCancel: true,
                });

                if (proceed) {
                    navigateToLicenseConfigure(
                        getRoute(getTenantConfigureServiceRoute(tenantId, 'configure')),
                        getRoute(RouteNames.TenantServices.replace(':tenantId', tenantId)),
                    );
                }
            }
            return licenseFailures;
        },
        [ createDialog, getRoute, tenantId, translate ],
    );

    const clickedRetryService = useCallback(async (service: IService) => {
        try {
            const changedServices = addHiddenDependenciesHelper([ service.serviceType ], availableServices);
            const changedServicesObj = Object.assign(
                {},
                ...changedServices.map(s => {
                    return { [s]: true };
                }),
            );
            mutateTenant(
                async () => {
                    await tenantOperations.tenantEdit({
                        name: tenant!.name,
                        services: tenant!.tenantServiceInstances.map(serviceIterator => serviceIterator.serviceType),
                        color: tenant!.color,
                        region: tenant!.region,
                    }, tenant, changedServicesObj);

                    return produce(tenant, (draftState: ITenant | undefined) => {
                        const tempService = draftState?.tenantServiceInstances
                            .find(serviceIterator => serviceIterator.id === service.id);
                        if (tempService) {
                            tempService.status = TenantStatusConstants.ENABLED;
                        }
                    });
                },
                {
                    optimisticData: produce(tenant, (draftState: ITenant | undefined) => {
                        const tempService = draftState?.tenantServiceInstances
                            .find(serviceIterator => serviceIterator.id === service.id);
                        if (tempService) {
                            tempService.status = TenantStatusConstants.UPDATING;
                        }
                    }),
                },
            );

        } catch (error) {
            const errorObject = await getErrorObject(error);

            if (errorObject.response?.status === 504) {
                setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
            } else {
                const errorData = errorObject.response?.data;
                const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            }
        }
    }, [
        availableServices,
        getErrorMessage,
        getErrorObject,
        mutateTenant,
        setErrorMessage,
        tenant,
        tenantOperations,
        translate,
    ]);

    const clickedEnableService = useCallback(
        async (service: IService) => {
            const serviceStatusMap = { [service.serviceType]: TenantStatusConstants.ENABLED };
            try {
                const parentDependencyList = getListOfDependencies(service.serviceType);
                const implicitServicesList = getListOfHiddenDependencies(service.serviceType);
                const disabledParents = parentDependencyList.filter(dep => {
                    const depService = tenant?.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });
                const implicitServices = implicitServicesList.filter(dep => {
                    const depService = tenant?.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });

                implicitServices.forEach(implicit => (serviceStatusMap[implicit] = TenantStatusConstants.ENABLED));
                if (disabledParents.length > 0) {
                    const parentString = disabledParents.map(parent => getFriendlyName(parent)).join(', ');
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_ENABLE_SERVICE' }, { service: getFriendlyName(service.serviceType) }),
                        body: (
                            <>
                                <Typography>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY1' }, { parents: parentString })}
                                </Typography>
                                <Typography style={{ marginTop: '12px' }}>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY2' }, { parents: parentString })}
                                </Typography>
                            </>
                        ),
                        icon: 'info',
                        primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
                        showCancel: true,
                    });

                    if (!proceed) {
                        return;
                    }

                    disabledParents.forEach(parent => (serviceStatusMap[parent] = TenantStatusConstants.ENABLED));
                }

                await mutateTenant(
                    async () => {
                        await changeServiceStatus(tenant!, serviceStatusMap);
                        return produce(tenant, (draftState: ITenant | undefined) => {
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.ENABLED;
                            }
                        });
                    },
                    {
                        optimisticData: produce(tenant, (draftState: ITenant | undefined) => {
                            draftState!.status = TenantStatusConstants.UPDATING;
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.ENABLE_STATUS_INPROGRESS;
                            }
                        }),
                    },
                );

                refreshAfterComplete(tenantId, true);

                createNotification(
                    translate(
                        { id: 'CLIENT_ENABLE_SERVICE_NOTIFICATION_REVAMP' },
                        {
                            service: getFriendlyName(service.serviceType),
                            tenantName: tenant?.name,
                        },
                    ),
                    notificationType.SUCCESS,
                );

                if (tenantHasServiceLicenses(service)) {
                    history.push(
                        getRoute(getTenantConfigureServiceRoute(tenantId, 'enable', service.serviceType)),
                        { services: Object.keys(serviceStatusMap) },
                    );
                }
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                const licenseFailures = await showServiceLicenseError(results);
                if (licenseFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            mutateTenant,
            tenant,
            refreshAfterComplete,
            createNotification,
            translate,
            tenantHasServiceLicenses,
            createDialog,
            changeServiceStatus,
            history,
            getRoute,
            tenantId,
            showServiceGenericError,
            showServiceLicenseError,
            setErrorMessage,
        ],
    );

    const clickedDisableService = useCallback(
        async (service: IService) => {
            try {
                const childDependencyList = getListOfParents(service.serviceType);
                const enabledChildren = childDependencyList.filter(dep => {
                    const depService = tenant!.tenantServiceInstances.find(serviceIterator => serviceIterator.serviceType === dep);
                    return depService?.status?.toUpperCase() === TenantStatusConstants.ENABLED;
                });
                const serviceStatusMap = { [service.serviceType]: TenantStatusConstants.DISABLED };
                const childrenString = enabledChildren
                    ?.filter(child => !servicesToHide.includes(child))
                    .map(child => getFriendlyName(child))
                    .join(', ');
                const proceed = await createDialog({
                    title: translate(
                        { id: 'CLIENT_DISABLE_SERVICE' },
                        { service: getFriendlyName(service.serviceType) },
                    ),
                    body: (
                        <>
                            <div className={classes.spacerText}>
                                <FormattedMessage
                                    id="CLIENT_DISABLE_SERVICE_BODY_REVAMP"
                                    values={{
                                        b: (chunk: string) => <b>
                                            {chunk}
                                        </b>,
                                        service: getFriendlyName(service.serviceType),
                                    }}
                                />
                            </div>
                            {childrenString && <div className={clsx(classes.spacerText, classes.dependencyText)}>
                                <FormattedMessage
                                    id="CLIENT_DISABLE_SERVICE_BODY_REVAMP_DEPENDENCIES"
                                    values={{
                                        b: (chunk: string) => <b>
                                            {chunk}
                                        </b>,
                                        parent: getFriendlyName(service.serviceType),
                                        children: childrenString,
                                    }}
                                />
                            </div>}
                            <FormattedMessage
                                id="CLIENT_DISABLE_SERVICE_BODY_REVAMP_CONFIRM"
                                values={{
                                    b: (chunk: string) => <b>
                                        {chunk}
                                    </b>,
                                    service: getFriendlyName(service.serviceType),
                                }}
                            />
                        </>
                    ),
                    icon: 'warning',
                    primaryButtonText: translate({ id: 'CLIENT_DISABLE' }),
                    showCancel: true,
                });

                if (!proceed) {
                    return;
                }

                await mutateTenant(
                    async () => {
                        enabledChildren.forEach(child => (serviceStatusMap[child] = TenantStatusConstants.DISABLED));
                        await changeServiceStatus(tenant!, serviceStatusMap);
                        return produce(tenant, (draftState: ITenant | undefined) => {
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.DISABLED;
                            }
                        });
                    },
                    {
                        optimisticData: produce(tenant, (draftState: ITenant | undefined) => {
                            draftState!.status = TenantStatusConstants.UPDATING;
                            const tempService = draftState?.tenantServiceInstances
                                .find(serviceIterator => serviceIterator.id === service.id);
                            if (tempService) {
                                tempService.status = TenantStatusConstants.DISABLE_STATUS_INPROGRESS;
                            }
                        }),
                    },
                );

                refreshAfterComplete(tenantId, true);

                createNotification(
                    translate(
                        { id: 'CLIENT_DISABLE_SERVICE_NOTIFICATION' },
                        { service: getFriendlyName(service.serviceType) },
                    ),
                    notificationType.SUCCESS,
                );
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            createDialog,
            translate,
            classes.spacerText,
            classes.dependencyText,
            mutateTenant,
            tenant,
            refreshAfterComplete,
            tenantId,
            createNotification,
            servicesToHide,
            changeServiceStatus,
            showServiceGenericError,
            setErrorMessage,
        ],
    );

    const clickedDeleteService = useCallback(
        async (service: IService) => {
            try {
                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_REMOVE_SERVICE' }, { 0: getFriendlyName(service.serviceType) }),
                    customDialogContent: ServiceDeleteDialogBody,
                    customDialogContentProps: {
                        tenant: tenant!,
                        service: service.serviceType,
                        refreshCallback: () => {
                            refreshAfterComplete(tenantId, true);
                        },
                        tenantEdit: tenantOperations.tenantEdit,
                    },
                    icon: 'error',
                });

                if (!proceed) {
                    return;
                }

                await mutateTenant(
                    async () => {
                        return produce(tenant, (draftState: ITenant | undefined) => {
                            draftState!.status = TenantStatusConstants.UPDATING;
                            draftState!.tenantServiceInstances =
                                draftState!.tenantServiceInstances.filter(serviceIterator => serviceIterator.id !== service.id);
                        });
                    },
                    { revalidate: false },
                );
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            }
        },
        [
            createDialog,
            translate,
            tenant,
            tenantOperations.tenantEdit,
            refreshAfterComplete,
            tenantId,
            showServiceGenericError,
            setErrorMessage,
            mutateTenant,
        ],
    );

    const clickedConfigureLicense = useCallback(
        (service: IService) => {
            navigateToLicenseConfigure(
                getRoute(getTenantConfigureServiceRoute(tenantId, 'configure', service.serviceType)),
                getRoute(RouteNames.TenantServices.replace(':tenantId', tenantId)),
            );
        },
        [ getRoute, tenantId ],
    );

    const clickedConfigureRobotLogs = useCallback(
        (service: IService) => {
            history.push({
                pathname: getRoute(RouteNames.TenantServiceLogExport.replace(':tenantId', tenantId)),
                state: { tenant: tenant },
            });
        },
        [ getRoute, history, tenant, tenantId ],
    );

    const navigateToService = useCallback(async (service: IService) => {
        try {
            await post(`/api/telemetry/logCustomEvent`, {
                body: {
                    name: 'Service.Open',
                    properties: {
                        ApplicationName: 'UiPath.AutomationCloud',
                        CloudOrganizationId: accountId,
                        CloudTenantId: tenantId,
                        CloudUserId: userId,
                        SubscriptionType: subscriptionType,
                        ServiceName: service.serviceType,
                    },
                },
            });
            if (tenant) {
                window.location.assign(urljoin(window.location.origin, logicalName, tenant.name, service.serviceType + '_'));
            }
        } catch (e) {
            portalTelemetry.trackTrace({
                message: `Error tracking Service.Open event ${service.serviceType}`,
                severityLevel: SeverityLevel.Error,
            }, { revampEnabled: adminRevampEnabled });
        }
    }, [ accountId, adminRevampEnabled, logicalName, subscriptionType, tenant, tenantId, userId ]);

    const moreActions: ICardAction[] = useMemo(() => [
        {
            label: translate({ id: 'CLIENT_LAUNCH' }),
            click: navigateToService,
            dataCy: 'launch-service',
            ariaLabel: translate({ id: 'CLIENT_LAUNCH' }),
            invisible: (service: IService) => {
                return service.status.toUpperCase() !== TenantStatusConstants.ENABLED;
            },
        },
        {
            label: translate({ id: 'CLIENT_ENABLE' }),
            click: clickedEnableService,
            dataCy: 'enable-service',
            ariaLabel: translate({ id: 'CLIENT_ENABLE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => {
                return service.status.toUpperCase() !== TenantStatusConstants.DISABLED;
            },
        },
        {
            label: translate({ id: 'CLIENT_DISABLE' }),
            click: clickedDisableService,
            dataCy: 'disable-service',
            ariaLabel: translate({ id: 'CLIENT_DISABLE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => {
                return service.status.toUpperCase() !== TenantStatusConstants.ENABLED;
            },
        },
        {
            label: translate({ id: 'CLIENT_LOG_EXPORT_CONFIGURATION' }),
            click: clickedConfigureRobotLogs,
            dataCy: 'export-log',
            ariaLabel: translate({ id: 'CLIENT_LOG_EXPORT_CONFIGURATION' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => {
                return service.serviceType !== 'orchestrator' || !(
                    process.buildConfigs.enableLogExport &&
                    isAdmin &&
                    !isUnlicensedMode &&
                    AccountLicense[subscriptionType] < AccountLicense.COMMUNITY &&
                    !DisableFeatureFedRamp);
            },
        },
        {
            label: translate({ id: 'CLIENT_API_ACCESS' }),
            click: () => setCurrentTenant(tenant!.name),
            dataCy: 'api-access',
            ariaLabel: translate({ id: 'CLIENT_API_ACCESS' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: (service: IService) => {
                return service.serviceType !== 'orchestrator' || !(checkTokenTypeIsAuth0 && !DisableFeatureFedRamp);
            },
        },
        {
            label: translate({ id: 'CLIENT_REMOVE' }),
            click: clickedDeleteService,
            dataCy: 'delete-service',
            ariaLabel: translate({ id: 'CLIENT_REMOVE' }),
            disable: tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED,
        },
    ], [
        DisableFeatureFedRamp,
        checkTokenTypeIsAuth0,
        clickedConfigureRobotLogs,
        clickedDeleteService,
        clickedDisableService,
        clickedEnableService,
        isAdmin,
        isUnlicensedMode,
        navigateToService,
        setCurrentTenant,
        subscriptionType,
        tenant,
        translate,
    ]);

    const services = useMemo(() => {
        const listOfServices = tenant?.tenantServiceInstances;
        const tempServices = listOfServices
            ?.filter(service => !servicesToHide.includes(service.serviceType))
            .filter(item => item.serviceType !== 'orchestrator');
        const orchestrator = listOfServices?.find(item => item.serviceType === 'orchestrator');
        if (orchestrator) {
            tempServices?.unshift(orchestrator);
        }
        return tempServices;
    }, [ servicesToHide, tenant?.tenantServiceInstances ]);

    const checkedServices = useMemo(
        () => services?.map(service => service.serviceType),
        [ services ],
    );

    const editAvailableServices = useMemo(() => {
        return availableServices?.filter(i => !checkedServices?.includes(i.id));
    }, [ availableServices, checkedServices ]);

    const hiddenServices = useMemo(() => services ? getHiddenServices(services, availableServices) : null, [ availableServices, services ]);

    const displayedServices = useMemo(() => {
        const data = editAvailableServices;
        return data?.filter(service => servicesToHide.indexOf(service.id) < 0 && hiddenServices && hiddenServices.indexOf(service.id) < 0);
    }, [ editAvailableServices, hiddenServices, servicesToHide ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.TenantHome.replace(':tenantId', tenantId),
            name: tenant?.name ?? translate({ id: 'CLIENT_TENANT' }),
        },
        {
            link: RouteNames.TenantServices.replace(':tenantId', tenantId),
            name: translate({ id: 'CLIENT_TENANTS' }),
        },
    ], [ tenant, tenantId, translate ]);

    const cardActions = useCallback((service: IService) => {
        return (
            <>
                {service.status.toUpperCase() !== TenantStatusConstants.FAILED && <>
                    {tenantHasServiceLicenses(service) && <Tooltip title={translate({ id: 'CLIENT_CONFIGURE_LICENSE_ALLOCATION' })}>
                        <IconButton
                            disabled={service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                || service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                                || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}
                            onClick={() => clickedConfigureLicense(service)}>
                            <AllocateLicenseIcon
                                className={{ [classes.licenseDisabled]: service.status.toUpperCase() !== TenantStatusConstants.ENABLED }} />
                        </IconButton>
                    </Tooltip>}
                    {isUserManagementEnabled(service.serviceType) &&
                    <Tooltip title={translate({ id: 'CLIENT_MANAGE_USERS' })}>
                        <IconButton
                            href={clickedManageUsers(service)}
                            target="_blank"
                            rel="noreferrer"
                            disabled={service.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                || service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                                || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}>
                            <PeopleAltOutlinedIcon />
                        </IconButton>
                    </Tooltip>}
                </>}
                {moreActions.every((action) =>
                    action.invisible !== undefined
                            && (typeof action.invisible === 'function' ?
                                action.invisible(service)
                                : action.invisible
                            )) ? null :
                    <UiMoreActionsComponent
                        service={service}
                        actions={moreActions}
                        disabled={service.status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                            || service.status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS}
                    />}
            </>
        );
    }, [
        classes.licenseDisabled,
        clickedConfigureLicense,
        clickedManageUsers,
        moreActions,
        tenantHasServiceLicenses,
        translate,
    ]);

    const regionBanner = useMemo(() => {
        return <>
            <UiTenantStatusBanner
                status={tenant?.status}
                tenantName={tenant?.name} />
            {services && services.filter(serviceIterator => serviceIterator.region !== tenant!.region).length > 0 &&
            <UiAlertBanner
                type="info"
                closeable={false}
                enterpriseTrialAlertBar={true}>
                <div className={classes.banner}>
                    {translate({ id: 'CLIENT_DEFAULT_TENANT_REGION_DIFFERENT_SERVICE' }, { 0: tenant!.region })}
                    <Link
                        className={classes.link}
                        data-cy="learn-region-hosting-button"
                        href={getLocalizedLink(TenantRegionDocsLinks)}
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE_REGION_HOSTING' })}
                    </Link>
                </div>
            </UiAlertBanner>}
        </>;
    }
    , [ tenant, services, classes.banner, classes.link, translate, getLocalizedLink ]);

    const tenantServiceButtons = useMemo(() => (
        <div style={{ marginRight: '20px' }}>
            <UiRefreshButton
                isValidating={isValidating}
                swrKey={[ `${organizationManagementTenantUri}/tenantById`, tenantId ]} />
            {displayedServices && displayedServices.length > 0 &&
            <Button
                size='small'
                variant='contained'
                startIcon={<AddIcon />}
                style={{ marginLeft: '20px' }}
                disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}
                onClick={() => {
                    history.push({ pathname: getRoute(`${RouteNames.TenantServicesAdd}`).replace(':tenantId', tenantId) });
                }}
                data-cy='tenant-add-services-button'>
                {translate({ id: 'CLIENT_ADD_SERVICES' })}
            </Button>}
        </div>
    ), [ displayedServices, getRoute, history, isValidating, tenant?.status, tenantId, translate ]);

    return (
        <>
            <ApiAccessInstanceModalComponent />
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                banner={regionBanner}
                primaryActions={tenantServiceButtons}
                loading={!services || !availableServices}>
                <UiCardGrid maxCardWidth='344px'>
                    {services?.map((service, i) =>
                        <UiServiceCard
                            key={i}
                            style={{
                                width: '344px',
                                height: '168px',
                            }}
                            title={getFriendlyName(service.serviceType)}
                            titleIcon={
                                <portal-custom-icon
                                    name={getServiceIcon(service)}
                                    color={service.status.toUpperCase() === TenantStatusConstants.DISABLED
                                        ? theme.palette.semantic.colorForegroundDisable
                                        : theme.palette.semantic.colorInfoForeground}
                                />
                            }
                            dataCy={`${service.serviceType}-service-card`}
                            region={service.region !== tenant?.region ? service.region : ''}
                            status={service.status.toUpperCase()}
                            description={translate({ id: `CLIENT_${service.serviceType.toUpperCase()}_DESCRIPTION` })}
                            actions={cardActions(service)}
                            retry={<Button
                                style={{ marginLeft: '4px' }}
                                variant="text"
                                startIcon={<RefreshIcon />}
                                onClick={() => clickedRetryService(service)}
                                data-cy="retry-service"
                                aria-label={translate({ id: 'CLIENT_TRY_AGAIN' })}
                                disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}>
                                {translate({ id: 'CLIENT_TRY_AGAIN' })}
                            </Button>} />,
                    )}
                </UiCardGrid>
            </UiPageContainer>
        </>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantServicesComponent />
        {renderRoutes(route.routes)}
    </>
);
