import { UiDialog } from '@experiences/ui-common';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { notificationType } from '../../common/constants/Constant';
import {
    accountLogicalName,
    isAdminSelector,
} from '../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        copyIcon: {
            width: '16px',
            height: '16px',
            objectFit: 'contain',
            float: 'right',
            cursor: 'pointer',
        },
        refreshToken: {
            height: '50px',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '0px',
            marginBottom: '10px',
        },
        values: {
            height: '20px',
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        rectangle: {
            width: 'calc(100% - 20px)',
            height: '1px',
            border: `solid 1px ${theme.palette.semantic.colorForegroundDisable}`,
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        connectViaAPI: {
            height: '32px',
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '0px',
        },
        warningIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginRight: '8px',
            fontSize: '18px',
        },
        banner: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.semantic.colorWarningBackground,
            marginBottom: '12px',
            padding: '10px 24px',
            fontSize: '14px',
            fontWeight: 400,
            maxWidth: '500px',
        },
        transbox: {
            maxWidth: '480px',
            marginBottom: '0px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const RefreshTokenPopUp: React.FC<{
    close: () => void;
    refreshToken: string | null;
    tenantName: string | null;
    clientId: string;
    handleRevoke: () => void;
}> = ({
    close, refreshToken, tenantName, clientId, handleRevoke,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const accountName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);

    const getCopyIcon = useCallback(
        (copiedText: string | null) => (
            <CopyToClipboard text={copiedText || ''}>
                <FileCopyOutlinedIcon
                    className={classes.copyIcon}
                    onClick={() =>
                        enqueueSnackbar(translate({ id: 'CLIENT_COPIED' }), { variant: notificationType.SUCCESS as any })}
                />
            </CopyToClipboard>
        ),
        [ classes.copyIcon, enqueueSnackbar, translate ],
    );

    const getField = useCallback(
        (label: string, value: string | null, dataCy: string) => (
            <div className={classes.refreshToken}>
                {label}
                <div
                    className={classes.values}
                    data-cy={dataCy}>
                    {value}
                    {getCopyIcon(value)}
                </div>
                <div className={classes.rectangle} />
            </div>
        ),
        [ classes, getCopyIcon ],
    );

    const apiConnectionData = useMemo(
        () => (
            <>
                {getField(translate({ id: 'CLIENT_USER_KEY' }), refreshToken, 'APIAccessUserKey')}
                {getField(translate({ id: 'CLIENT_ACCOUNT_LOGICAL_NAME' }), accountName, 'APIAccessaccountName')}
                {getField(translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }), tenantName, 'APIAccessTenantLogicalName')}
                {getField(translate({ id: 'CLIENT_CLIENT_ID' }), clientId, 'APIAccessClientId')}
            </>
        ),
        [ accountName, clientId, getField, refreshToken, tenantName, translate ],
    );

    return (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_API_ACCESS' })}
            actions={[
                <Button
                    key="primaryButton"
                    variant="contained"
                    data-cy="refresh-token-popup-submit"
                    onClick={close}>
          OK
                </Button>,
            ]}
        >
            {isAdmin && (
                <div className={classes.banner}>
                    <WarningIcon className={classes.warningIcon} />
                    <div>
                        <Typography data-cy="external-api-access-text">
                            {translate({ id: 'CLIENT_EXTERNAL_APP_API_ACCESS' })}
&nbsp;
                            <Link
                                to="externalApps"
                                style={{ fontWeight: 'bold' }}
                                onClick={close}>
                                {translate({ id: 'CLIENT_VIEW' })}
                            </Link>
                        </Typography>
                    </div>
                </div>
            )}
            <div className={classes.connectViaAPI}>
                {translate({ id: 'CLIENT_CONNECT_VIA_API' })}
            </div>
            {apiConnectionData}
            <div className={classes.connectViaAPI}>
                {translate({ id: 'CLIENT_REVOKE_ACCESS' })}
            </div>
            <div className={classes.transbox}>
                <p>
                    {translate({ id: 'CLIENT_REVOKE_DESCRIPTION' })}
                </p>
                <Button
                    variant="outlined"
                    onClick={handleRevoke}
                    id="secondaryButton"
                    data-cy="refresh-token-popup-revoke-button"
                >
                    {translate({ id: 'CLIENT_REVOKE_ACCESS_CAPS' })}
                </Button>
            </div>
        </UiDialog>
    );
};

export default RefreshTokenPopUp;
