import {
    UiProgressButton,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useModalState,
    validateUsername,
} from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useRouteMatch,
} from 'react-router';

import {
    notificationType,
    UserPartition,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import {
    MaxFirstNameLength,
    MaxLastNameLength,
    MaxUsernameLength,
    ReservedUsernames,
} from '../../../common/constants/UsersConstant';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import useIsEmailTaken from '../../../common/hooks/useIsEmailTaken';
import { IUserCIS } from '../../../common/interfaces/cis/user';
import getUsersInPartition from '../../../services/identity/UserPartitionService';
import {
    createUser,
    putUser,
} from '../../../services/identity/UserService';
import {
    accountGlobalId,
    isHostModeSelector,
    userGlobalId,
} from '../../../store/selectors';
import reduceGroupCIS, {
    mapBasicUserDto,
    mapUpdateBasicUserDto,
} from '../../../util/UserGroupUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import EditPasswordFormComponent, {
    defaultEditPasswordData,
    IEditPasswordData,
} from '../../common/EditPasswordFormComponent';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import EditGroupMembershipFormComponent, {
    defaultEditGroupMembershipData,
    IEditGroupMembershipData,
} from './EditGroupMembershipFormComponent';
import EditUserAuthSettingsFormComponent, {
    getDefaultEditUserAuthSettingsData,
    IEditUserAuthSettingsData,
} from './EditUserAuthSettingsFormComponent';

export interface IAddEditUserData
    extends Partial<IEditUserAuthSettingsData>,
    Partial<IEditGroupMembershipData>,
    IEditPasswordData {
    userName: string;
    firstName: string;
    lastName: string;
    email?: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const AddEditUserComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { setData } = useUiDataContext<{ refresh: boolean }>();
    const {
        open, close: closeModal,
    } = useModalState(RouteNames.Users);
    const close = useCallback((refresh?: boolean) => {
        setData({ refresh: refresh ?? false });
        closeModal();
    }, [ closeModal, setData ]);
    const match = useRouteMatch<{ type: 'add' | 'edit' }>();
    const currentUserId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);
    const location: { state?: { user: IUserCIS } } = useLocation();
    const user = useMemo(() => location?.state?.user || ({} as IUserCIS), [ location ]);
    const type = useMemo(() => match.params.type, [ match ]);
    const { groups } = useSimpleGroup(!isHostMode);
    const [ loading, setLoading ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_ADD_USER_GENERIC_ERROR' }));
    const methods = useForm<IAddEditUserData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditPasswordData,
            ...(isHostMode ? {} : defaultEditGroupMembershipData),
            ...(isHostMode ? {} : getDefaultEditUserAuthSettingsData()),
            userName: '',
            firstName: '',
            lastName: '',
            email: '',
        },
    });
    const {
        control, handleSubmit, reset, errors, formState, setError,
    } = useMemo(() => methods, [ methods ]);
    const { isDirty } = formState;

    const initialFormData: IAddEditUserData | undefined = useMemo(() => {
        if (type === 'edit' && user) {
            return {
                ...defaultEditPasswordData,
                ...(isHostMode ? {} : { groupIds: reduceGroupCIS(groups, user.groupIDs) }),
                ...(isHostMode ? {} : getDefaultEditUserAuthSettingsData(user)),
                userName: user.userName,
                firstName: user.name,
                lastName: user.surname,
                email: user.email,
            };
        }
    }, [ type, user, groups, isHostMode ]);
    const isEmailTaken = useIsEmailTaken(initialFormData?.email);
    const defaultChecked = useMemo(() => {
        const groupIds = initialFormData?.groupIds ?? (type === 'add' && groups ? reduceGroupCIS(groups) : {});
        return Object.keys(groupIds).filter(g => groupIds[g]);
    }, [ initialFormData, type, groups ]);

    useEffect(() => {
        if (initialFormData) {
            reset(initialFormData);
        }
    }, [ reset, initialFormData ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const isUsernameTaken = useCallback(
        async (username: string) => {
            const response = await getUsersInPartition(
                {
                    top: UserPartition.MAX_RETURNED_USERS,
                    skip: 0,
                    searchTerm: username,
                },
                partitionGlobalId,
            );
            if (username === initialFormData?.userName) {
                return false;
            }
            const lowercaseUsername = username.toLowerCase();
            return (
                ReservedUsernames.some(reservedUsername => reservedUsername.toLowerCase() === lowercaseUsername) ||
        response.results.some(result => result.userName?.toLowerCase() === lowercaseUsername)
            );
        },
        [ initialFormData?.userName, partitionGlobalId ],
    );

    const onSubmit = useCallback(
        async (data: IAddEditUserData) => {
            setLoading(true);

            try {
                if (data.email && (await isEmailTaken(data.email))) {
                    setError('email', { type: 'available' });
                    setLoading(false);
                    return;
                }
                if (type === 'add') {
                    if (await isUsernameTaken(data.userName)) {
                        setError('userName', { type: 'available' });
                        setLoading(false);
                        return;
                    }
                    await createUser(mapBasicUserDto(data, partitionGlobalId));
                } else if (type === 'edit') {
                    await putUser(user.id, mapUpdateBasicUserDto(data), currentUserId === user.id);
                }
            } catch (error) {
                setShowDrawerError(true);
                const errors = (error as any)?.response?.data?.errors;
                if (errors && errors[0]) {
                    setErrorMessage(errors[0].description);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_ADD_USER_GENERIC_ERROR' }));
                }
                setLoading(false);
                return;
            }

            setLoading(false);
            createNotification(translate({ id: type === 'add' ? 'CLIENT_NEW_USER_ADDED' : 'CLIENT_USER_UPDATED' }));
            close(true);
        },
        [
            close,
            createNotification,
            currentUserId,
            isEmailTaken,
            isUsernameTaken,
            partitionGlobalId,
            setError,
            translate,
            type,
            user.id,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: type === 'add' ? 'CLIENT_ADD_USER' : 'CLIENT_EDIT_USER' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(true),
            }}
            loading={!isHostMode && !groups.length}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="add-edit-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={classes.input}>
                    <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_USERNAME' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        disabled={type === 'edit'}
                        rules={{
                            required: true,
                            maxLength: MaxUsernameLength,
                            validate: { valid: value => validateUsername(value) },
                        }}
                        error={!!errors.userName}
                        helperText={
                            (errors.userName?.type === 'required' &&
                translate(
                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                    { 0: translate({ id: 'CLIENT_USERNAME' }) },
                )) ||
              (errors.userName?.type === 'maxLength' &&
                translate({ id: 'CLIENT_USERNAME_MAX_LENGTH_ERROR' }, { 0: MaxUsernameLength })) ||
              (errors.userName?.type === 'available' && translate({ id: 'CLIENT_USERNAME_TAKEN_ERROR' })) ||
              (errors.userName?.type === 'valid' && translate({ id: 'CLIENT_INVALID_USERNAME_ERROR' }))
                        }
                        name="userName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="add-edit-username"
                    />
                </div>
                <div className={classes.input}>
                    <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_FIRST_NAME' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        rules={{
                            required: true,
                            maxLength: MaxFirstNameLength,
                        }}
                        error={!!errors.firstName}
                        helperText={
                            (errors.firstName?.type === 'required' &&
                translate(
                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                    { 0: translate({ id: 'CLIENT_FIRST_NAME' }) },
                )) ||
              (errors.firstName?.type === 'maxLength' &&
                translate({ id: 'CLIENT_FIRST_NAME_MAX_LENGTH_ERROR' }, { 0: MaxFirstNameLength }))
                        }
                        name="firstName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="add-edit-first-name"
                    />
                </div>
                <div className={classes.input}>
                    <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_LAST_NAME' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        rules={{
                            required: true,
                            maxLength: MaxLastNameLength,
                        }}
                        error={!!errors.lastName}
                        helperText={
                            (errors.lastName?.type === 'required' &&
                translate(
                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                    { 0: translate({ id: 'CLIENT_LAST_NAME' }) },
                )) ||
              (errors.lastName?.type === 'maxLength' &&
                translate({ id: 'CLIENT_LAST_NAME_MAX_LENGTH_ERROR' }, { 0: MaxLastNameLength }))
                        }
                        name="lastName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="add-edit-last-name"
                    />
                </div>
                <div className={classes.input}>
                    <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_EMAIL' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        name="email"
                        variant="outlined"
                        type="email"
                        rules={{ validate: { valid: value => !value || validateEmail(value) } }}
                        error={!!errors.email}
                        helperText={
                            errors.email?.type === 'valid'
                                ? translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })
                                : errors.email?.type === 'available' && translate({ id: 'CLIENT_EMAIL_TAKEN_ERROR' })
                        }
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="add-edit-email"
                    />
                </div>
                <FormProvider {...methods}>
                    <EditPasswordFormComponent respectPasswordRequirements />
                    {!isHostMode && <EditUserAuthSettingsFormComponent />}
                    {!isHostMode && <EditGroupMembershipFormComponent
                        groups={groups}
                        defaultCheckedGroupIds={defaultChecked} />}
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default AddEditUserComponent;
