import { useMemo } from 'react';
import { useLocation } from 'react-router';
import xss from 'xss';

export function useFilteredParams(paramsToGet: string[], windowSearch = false) {
    const location = useLocation();

    const filteredParams = useMemo(() => {
        const search = new URLSearchParams(windowSearch ? window.location.search : location.search);
        return paramsToGet.map(p => xss(decodeURIComponent(search.get(p) || '')));
    }, [ paramsToGet, location.search, windowSearch ]);

    return filteredParams;
}
