import {
    EcommerceCheckoutComponent,
    EcommerceOrderConfirmationComponent,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, { useMemo } from 'react';
import {
    RouteConfig,
    RouteConfigComponentProps,
} from 'react-router-config';
import { Redirect } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import AuditDialogComponent from '../../component/audit/AuditDialogComponent';
import AuditPageComponent from '../../component/audit/AuditPageComponent';
import AuthSettingsPageComponent from '../../component/authsettings/AuthSettingsPageComponent.onprem';
import ConfigureAADOrgModeComponent from '../../component/authsettings/subcomponents/ConfigureAADOrgModeComponent';
import { ConfigureSSOComponent } from '../../component/authsettings/subcomponents/ConfigureSSOComponent';
import CreateEditSAMLProvisioningRuleComponent from '../../component/authsettings/subcomponents/CreateEditSAMLProvisioningRuleComponent';
import EditPasswordPolicyComponent from '../../component/authsettings/subcomponents/EditPasswordPolicyComponent';
import SAMLProvisioningRulesComponent from '../../component/authsettings/subcomponents/SAMLProvisioningRulesComponent';
import EcommerceComponent from '../../component/ecommerce/EcommerceComponent';
import EcommerceManagePlanComponent from '../../component/ecommerce/EcommerceManagePlanComponent';
import ExternalApps from '../../component/externalApps/ExternalApps';
import CreateEditExternalApplicationsPageComponent
    from '../../component/externalApps/subcomponents/CreateEditExternalApplicationsPageComponent';
import ExternalApplicationsScopesDrawerComponent
    from '../../component/externalApps/subcomponents/ExternalApplicationsScopesDrawerComponent';
import Licenses from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import AddEditGroupAllocationComponent from '../../component/licensing/subcomponents/AddEditGroupAllocationComponent';
import AddUserAllocationComponent from '../../component/licensing/subcomponents/AddUserAllocationComponent';
import DeactivateLicenseOfflineComponent from '../../component/licensing/subcomponents/DeactivateLicenseOfflineComponent';
import EcommerceSuccessComponent from '../../component/licensing/subcomponents/EcommerceSuccessComponent';
import EditUserAllocationComponent from '../../component/licensing/subcomponents/EditUserAllocationComponent';
import InheritedLicenseComponent from '../../component/licensing/subcomponents/InheritedLicenseComponent';
import ViewUserAllocationsInGroupComponent from '../../component/licensing/subcomponents/ViewUserAllocationsInGroupComponent';
import UpgradeToEnterpriseDialogComponent from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import OrganizationSettingsPageComponent from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import AccountLogicalNameWarningDialogComponent
    from '../../component/organizationsettings/subcomponents/AccountLogicalNameWarningDialogComponent';
import VerifyEmailSettingsComponent from '../../component/organizationsettings/subcomponents/VerifyEmailSettingsComponent';
import ConfigureSSOPage from '../../component/securitySettings/ConfigureSSOPage';
import SecuritySettings from '../../component/securitySettings/SecuritySettings';
import ConfigureTenantLicensesComponent from '../../component/tenants/subcomponents/ConfigureTenantLicenseComponent';
import CreateEditTenantComponent from '../../component/tenants/subcomponents/CreateEditTenantComponent';
import OrchLogExportComponent from '../../component/tenants/subcomponents/OrchLogExportComponent';
import SkipServiceConfirmationDialogComponent from '../../component/tenants/subcomponents/SkipServiceConfirmationDialogComponent';
import ServicesUsagePageComponent from '../../component/tenants/subcomponents/usage/ServicesUsagePageComponent';
import TenantCreateComponent from '../../component/tenants/TenantCreate';
import TenantsPageComponent from '../../component/tenants/TenantsPageComponent';
import TenantAdminHome from '../../component/tenantsv2/TenantAdminHome';
import { TenantServicesAddComponent } from '../../component/tenantsv2/TenantServicesAddComponent';
import TenantServicesComponent from '../../component/tenantsv2/TenantServicesComponent';
import TenantSettingsComponent from '../../component/tenantsv2/TenantSettingsComponent';
import GroupsPageComponent from '../../component/users/GroupsPageComponent';
import RobotsPageComponent from '../../component/users/RobotsPageComponent';
import AddEditRobotAccountComponent from '../../component/users/subcomponents/AddEditRobotAccountComponent';
import AddEditUserComponent from '../../component/users/subcomponents/AddEditUserComponent';
import BulkInviteUserComponent from '../../component/users/subcomponents/BulkInviteUserComponent';
import CreateEditGroupComponent from '../../component/users/subcomponents/CreateEditGroupComponent';
import UsersPageComponent from '../../component/users/UsersPageComponent';
import CheckGuard from '../helpers/CheckGuard';

const useOrgAdminPrivateShellRoutes = () => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const { isAccountLicenseEnterpriseOrPro } = useCheckLicense();

    return useMemo<RouteConfig[]>(() => [
        {
            path: RouteNames.TenantSettings,
            render: props => CheckGuard(isAdminRevampEnabled, <TenantSettingsComponent {...props} />),
        },
        {
            path: RouteNames.TenantServices,
            render: props => CheckGuard(isAdminRevampEnabled, <TenantServicesComponent {...props} />),
            routes: (function() {
                const subroutes: any[] = [
                    {
                        path: RouteNames.TenantServicesAdd,
                        component: TenantServicesAddComponent,
                    },
                    {
                        path: RouteNames.TenantConfigureService,
                        component: ConfigureTenantLicensesComponent,
                    },
                    {
                        path: RouteNames.TenantServiceLogExport,
                        component: OrchLogExportComponent,
                    },
                ];
                return subroutes;
            })(),
        },
        {
            path: RouteNames.TenantHome,
            render: () => CheckGuard(isAdminRevampEnabled, <TenantAdminHome />),
        },
        {
            path: RouteNames.Services,
            component: TenantsPageComponent,
            routes: (function() {
                const subroutes: any[] = [
                    {
                        path: RouteNames.TenantCreateEdit,
                        component: CreateEditTenantComponent,
                        routes: [
                            {
                                path: RouteNames.TenantCreateEditSkip,
                                component: SkipServiceConfirmationDialogComponent,
                            },
                        ],
                    },
                    {
                        path: RouteNames.TenantConfigure,
                        component: ConfigureTenantLicensesComponent,
                        routes: [
                            {
                                path: RouteNames.TenantConfigureSkip,
                                component: SkipServiceConfirmationDialogComponent,
                            },
                        ],
                    },
                    {
                        path: RouteNames.OrchLogExport,
                        component: OrchLogExportComponent,
                    },
                ];

                return subroutes;
            })(),
        },
        {
            path: RouteNames.Users,
            component: UsersPageComponent,
            routes: (function() {
                const subroutes = [
                    {
                        path: RouteNames.UserAddEdit,
                        component: AddEditUserComponent,
                    },
                    {
                        path: RouteNames.Groups,
                        component: GroupsPageComponent,
                    },
                    {
                        path: RouteNames.UsersUserAddLicenseAllocation,
                        component: AddUserAllocationComponent,
                    },
                    {
                        path: RouteNames.UsersUserEditLicenseAllocation,
                        component: EditUserAllocationComponent,
                    },
                    {
                        path: RouteNames.UsersUserLicenseInheritance,
                        component: InheritedLicenseComponent,
                    },
                    {
                        path: RouteNames.BulkUserInvite,
                        component: BulkInviteUserComponent,
                    },
                ];

                subroutes.push({
                    path: RouteNames.Robots,
                    component: RobotsPageComponent,
                });
                return subroutes;
            })(),
        },
        {
            path: RouteNames.AuthSettings,
            component: AuthSettingsPageComponent,
            routes: [
                {
                    path: RouteNames.AuthSettingsConfigureAAD,
                    component: ConfigureAADOrgModeComponent,
                },
            ],
        },
        {
            path: RouteNames.SecuritySettingsConfigure,
            component: ConfigureSSOPage,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRules,
            exact: true,
            component: SAMLProvisioningRulesComponent,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesAddEdit,
            component: CreateEditSAMLProvisioningRuleComponent,
        },
        {
            path: RouteNames.Groups,
            component: UsersPageComponent,
            routes: [
                {
                    path: RouteNames.GroupsAddEdit,
                    component: CreateEditGroupComponent,
                },
                {
                    path: RouteNames.GroupAllocationRule,
                    component: AddEditGroupAllocationComponent,
                },
            ],
        },
        {
            path: RouteNames.Robots,
            component: UsersPageComponent,
            routes: [
                {
                    path: RouteNames.RobotsAddEdit,
                    component: AddEditRobotAccountComponent,
                },
            ],
        },
        {
            path: RouteNames.Licensing,
            component: Licenses,
            routes: [
                {
                    path: RouteNames.LicensingUpgradeToEnterprise,
                    component: UpgradeToEnterpriseDialogComponent,
                },
                {
                    path: RouteNames.LicensingUserAddLicenseAllocation,
                    component: AddUserAllocationComponent,
                },
                {
                    path: RouteNames.LicensingUserEditLicenseAllocation,
                    component: EditUserAllocationComponent,
                },
                {
                    path: RouteNames.LicensingUserLicenseInheritance,
                    component: InheritedLicenseComponent,
                },
                {
                    path: RouteNames.LicensingGroupAllocationAddEdit,
                    component: AddEditGroupAllocationComponent,
                },
                {
                    path: RouteNames.LicensingGroupAllocationView,
                    component: ViewUserAllocationsInGroupComponent,
                },
                {
                    path: RouteNames.LicensingTenantConfigure,
                    component: ConfigureTenantLicensesComponent,
                },
                {
                    path: RouteNames.ActivateOffline,
                    component: ActivateLicenseOfflineComponent,
                },
                {
                    path: RouteNames.BuyEnterpriseSuccess,
                    render: () => CheckGuard(EnableEcommerceIntegration, <EcommerceSuccessComponent />),
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    component: ActivateLicenseOnlineComponent,
                },
                {
                    path: RouteNames.DeactivateOffline,
                    component: DeactivateLicenseOfflineComponent,
                },
                {
                    path: RouteNames.AuthSettingsDeprecated,
                    component: (props: RouteConfigComponentProps<any>) => (
                        <Redirect to={RouteNames.AuthSettings.replace(':accountName', props.match.params.accountName)} />
                    ),
                },
            ],
        },
        {
            path: RouteNames.OrganizationAdminHome,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <OrganizationAdminHomeComponent />),
        },
        {
            path: RouteNames.TenantCreate,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <TenantCreateComponent />),
        },
        {
            path: RouteNames.BuyEnterprise,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(
                    EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                    <EcommerceComponent {...props} />,
                ),
            routes: [
                {
                    path: RouteNames.BuyEnterpriseCheckout,
                    component: EcommerceCheckoutComponent,
                },
                {
                    path: RouteNames.BuyEnterpriseConfirmation,
                    component: EcommerceOrderConfirmationComponent,
                },
            ],
        },
        {
            path: RouteNames.ManagePlan,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(
                    EnableEcommerceIntegration && isAccountLicenseEnterpriseOrPro(),
                    <EcommerceManagePlanComponent {...props} />,
                ),
        },
        {
            path: RouteNames.OrganizationSettings,
            component: OrganizationSettingsPageComponent,
            routes: [
                {
                    path: RouteNames.OrganizationSettingsWarning,
                    component: AccountLogicalNameWarningDialogComponent,
                },
            ],
        },
        {
            path: RouteNames.OrganizationSettingsAdvanced,
            render: props =>
                CheckGuard(isAdminRevampEnabled, <OrganizationSettingsPageComponent {...props} />),
        },
        {
            path: RouteNames.EmailSettings,
            component: OrganizationSettingsPageComponent,
            routes: [
                {
                    path: RouteNames.EmailSettingsTest,
                    component: VerifyEmailSettingsComponent,
                },
            ],
        },
        {
            path: RouteNames.LoginCustomization,
            component: OrganizationSettingsPageComponent,
        },
        {
            path: RouteNames.AuditLogs,
            component: AuditPageComponent,
            routes: [
                {
                    path: RouteNames.AuditLogDialog,
                    component: AuditDialogComponent,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplications,
            exact: true,
            component: ExternalApps,
        },
        {
            path: RouteNames.ExternalApplicationsPAT,
            component: ExternalApps,
        },
        {
            path: RouteNames.ExternalApplicationAddEdit,
            component: CreateEditExternalApplicationsPageComponent,
            routes: [
                {
                    path: RouteNames.ExternalApplicationScopes,
                    component: ExternalApplicationsScopesDrawerComponent,
                },
            ],
        },
        {
            path: RouteNames.ServicesUsage,
            component: ServicesUsagePageComponent,
            routes: [
                {
                    path: RouteNames.TenantServicesUsageAllocation,
                    component: ConfigureTenantLicensesComponent,
                },
            ],
        },
        {
            path: RouteNames.SessionPolicy,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
        },
        {
            path: RouteNames.SecuritySettings,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
            routes: [
                {
                    path: RouteNames.ConfigureSSO,
                    component: ConfigureSSOComponent,
                },
                {
                    path: RouteNames.AuthSettingsPasswordPolicy,
                    component: EditPasswordPolicyComponent,
                },
            ],
        },
    ], [ EnableEcommerceIntegration, isAccountLicenseEnterpriseOrPro, isAdminRevampEnabled ]);
};

export default useOrgAdminPrivateShellRoutes;
