import { IPortalUser } from '@experiences/interfaces';
import { LanguageCulture } from '@experiences/locales';
import {
    fixName,
    getFirstNameFromFullName,
    getSurnameFromFullName,
} from '@experiences/util';

import { IGroup } from '../common/interfaces/cis/group';
import { IUpdateRobotDto } from '../common/interfaces/cis/robot';
import {
    IBasicUserDto,
    IUpdateUserDto,
} from '../common/interfaces/cis/user';
import { SimpleGroup } from '../component/users/interfaces/user';
import { IAddEditDirectoryUserData } from '../component/users/subcomponents/AddEditDirectoryUserComponent';
import { AddEditRobotAccountData } from '../component/users/subcomponents/AddEditRobotAccountComponent';
import { IAddEditUserData } from '../component/users/subcomponents/AddEditUserComponent';
import { BulkInviteUserData } from '../component/users/subcomponents/BulkInviteUserComponent';
import { InviteEditUserData } from '../component/users/subcomponents/InviteEditUserComponent';
import { getNameFromEmail } from './EmailUtil';

export function mapInviteUserDto(data: InviteEditUserData) {
    const groupIDs = getGroupIdsToAdd(data.groupIds);
    return (data.email as string[]).map(email => ({
        name: getNameFromEmail(email),
        email,
        groupIDs,
    }));
}

export function mapBulkInviteUserDto(bulkInviteData: BulkInviteUserData[], language: LanguageCulture) {
    return bulkInviteData.map(data => ({
        name: getNameFromEmail(data.email),
        email: data.email,
        groupIDs: getGroupIdsToAdd(data.groupIds),
        language,
    }));
}

export function mapUpdateRobotDto(data: AddEditRobotAccountData, partitionGlobalId?: string): IUpdateRobotDto {
    const groupIDsToAdd = getGroupIdsToAdd(data.groupIds);
    const groupIDsToRemove = getGroupIdsToRemove(data.groupIds);

    return {
        name: data.name,
        displayName: data.displayName || data.name,
        partitionGlobalId,
        groupIDsToAdd,
        groupIDsToRemove,
    };
}

export function mapBasicUserDto(data: IAddEditUserData, partitionGlobalId: string): IBasicUserDto {
    const { groupIds } = data;
    const groupIDs = groupIds && getGroupIdsToAdd(groupIds);
    const userName = fixName(data.userName);
    return {
        name: fixName(data.firstName),
        surname: fixName(data.lastName),
        email: data.email || '',
        groupIDs,
        partitionGlobalId,
        password: data.password || undefined,
        userName,
        bypassBasicAuthRestriction: data.bypassBasicAuthRestriction,
    };
}

export function mapUpdateBasicUserDto(data: IAddEditUserData): IUpdateUserDto {
    const { groupIds } = data;
    const groupIDsToAdd = groupIds && getGroupIdsToAdd(groupIds);
    const groupIDsToRemove = groupIds && getGroupIdsToRemove(groupIds);
    return {
        name: fixName(data.firstName),
        surname: fixName(data.lastName),
        email: data.email || '',
        groupIDsToAdd,
        groupIDsToRemove,
        password: data.password || undefined,
        bypassBasicAuthRestriction: data.bypassBasicAuthRestriction,
    };
}

export function mapDirectoryUserDto(data: IAddEditDirectoryUserData, partitionGlobalId: string): IBasicUserDto {
    const {
        groupIds, email,
    } = data;
    const groupIDs = groupIds && getGroupIdsToAdd(groupIds);
    return {
    // TODO: Add the type `UserType.DirectoryUser` when the API is ready.
        name: getNameFromEmail(email),
        email: email || undefined,
        groupIDs,
        partitionGlobalId,
        userName: email,
    };
}

export function mapUpdateDirectoryUserDto(data: IAddEditDirectoryUserData): IUpdateUserDto {
    const {
        groupIds, email,
    } = data;
    const groupIDsToAdd = groupIds && getGroupIdsToAdd(groupIds);
    const groupIDsToRemove = groupIds && getGroupIdsToRemove(groupIds);
    return {
        name: getNameFromEmail(email),
        email: email || undefined,
        groupIDsToAdd,
        groupIDsToRemove,
    };
}

export function mapUserDto(data: InviteEditUserData, user?: IPortalUser): IUpdateUserDto {
    const groupIDsToAdd = getGroupIdsToAdd(data.groupIds);
    const groupIDsToRemove = getGroupIdsToRemove(data.groupIds);
    const userName = fixName(data.userName);
    return {
        name: getFirstNameFromFullName(userName),
        surname: getSurnameFromFullName(userName),
        email: user?.emailId || (data.email as string) || undefined,
        groupIDsToAdd,
        groupIDsToRemove,
    };
}

export default function reduceGroupCIS(groups?: IGroup[], userGroupIds?: string[]) {
    const simpleGroups =
    groups?.map(group => {
        let enabled = userGroupIds ? userGroupIds.indexOf(group.id) > -1 : false;
        if (group.name === 'Everyone') {
            enabled = true;
        }
        return { [group.id]: enabled };
    }) || [];

    return Object.assign({}, ...simpleGroups) as SimpleGroup;
}

function getGroupIdsToAdd(groupIds: SimpleGroup): string[] {
    return Object.keys(groupIds).filter(key => groupIds[key] === true);
}

function getGroupIdsToRemove(groupIds: SimpleGroup): string[] {
    return Object.keys(groupIds).filter(key => groupIds[key] === false);
}
