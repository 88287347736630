import crypto, { BinaryLike } from 'crypto';

const SHA256 = 'sha256';

export function base64URLEncode(bytes: Buffer) {
    return bytes
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function sha256(buffer: BinaryLike) {
    return crypto
        .createHash(SHA256)
        .update(buffer)
        .digest();
}

export function sha256Hex(buffer: BinaryLike) {
    return crypto
        .createHash(SHA256)
        .update(buffer)
        .digest('hex');
}
