// DO NOT MODIFY: Enum is used for comparison of various license types

export enum AccountLicense {
    'ENTERPRISE',
    'TRIAL',
    'PRO-TRIAL',
    'PRO',
    'COMMUNITY',
    'FREEKIT',
    'UNLICENSED',
}
