import { IUiLinkMap } from '@experiences/interfaces';

export const ContactUsLinks: IUiLinkMap = new Map([
    [ 'en', 'https://www.uipath.com/company/contact-us' ],
    [ 'ja', 'https://www.uipath.com/ja/company/contact-us' ],
    [ 'fr', 'https://www.uipath.com/fr/company/contact-us' ],
    [ 'de', 'https://www.uipath.com/de/company/contact-us' ],
    [ 'es', 'https://www.uipath.com/es/company/contact-us' ],
    [ 'ko', 'https://www.uipath.com/ko/company/contact-us' ],
    [ 'pt', 'https://www.uipath.com/pt/company/contact-us' ],
]);

export const ContactTechnicalSupportLinks: IUiLinkMap = new Map([
    [ 'en', 'https://www.uipath.com/company/contact-us/contact-technical-support' ],
    [ 'fr', 'https://www.uipath.com/fr/company/contact-us/contact-technical-support' ],
    [ 'de', 'https://www.uipath.com/de/company/contact-us/contact-technical-support' ],
    [ 'ko', 'https://www.uipath.com/ko/company/contact-us/contact-technical-support' ],
    [ 'zh-CN', 'https://www.uipath.com.cn/company/contact-us/contact-technical-support' ],
    [ 'zh-TW', 'https://www.uipath.com.cn/company/contact-us/contact-technical-support' ],
]);
