import React from 'react';
import {
    Column,
    Row,
} from 'react-table';

export interface IAction<T extends object> {
    type: ButtonType;
    label: React.ReactNode | ((row?: Row<T>) => React.ReactNode);
    actionType: GridActionType;
    click?: (_: any) => void;
    tooltip?: string | ((row?: Row<T>) => string);
    icon?: JSX.Element | ((row?: Row<T>, props?: any) => JSX.Element);
    disable?: boolean | ((row?: Row<T>) => boolean);
    invisible?: boolean | ((row?: Row<T>) => boolean);
    featureFlag?: boolean;
    dataCy?: string | ((row?: Row<T>) => string);
    ariaLabel?: string;
}

export interface IActionHeader {
    type: ButtonType;
    label: string;
    click?: (_: any) => void;
    icon?: JSX.Element;
    endIcon?: JSX.Element;
    disable?: boolean;
    invisible?: boolean;
    variant?: 'contained' | 'text';
    className?: string;
    toolTip?: string;
    dataCy?: string;
    subMenuList?: React.ReactNode[];
    id?: string;
    ariaControls?: string;
    ariaHaspopup?: 'true';
    ariaExpanded?: 'true';
    children?: React.ReactNode;
}

export interface UiColumn<T extends object> extends Column<T> {
    filterMap?: Map<any, any>;
    sortName?: string;
    justifyContent?: any;
}

export enum ButtonType {
    Icon,
    LinkIcon,
    Button,
    ButtonWithIcon,
}

export enum GridActionType {
    Main,
    Selected,
    Row,
    MainRow,
    SubRow,
}
