import { IPagination } from '@experiences/interfaces';

import { IUserCISResponse } from '../../component/users/UsersCISPageComponent';
import { cleanUserCISResponse } from '../mappers/UserPartitionServiceMapper';
import { get } from '../utility/Requests.default';

export const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/UserPartition`;

export default async function getUsersInPartition(
    pagination: IPagination,
    partitionGlobalId: string,
): Promise<IUserCISResponse> {
    const response = await get<IUserCISResponse>(`${userPartitionUrl}/users/${partitionGlobalId}`, { pagination });
    return cleanUserCISResponse(response);
}

export async function getUsersInPartitionWithLicenses(
    pagination: IPagination,
    partitionGlobalId: string,
): Promise<IUserCISResponse> {
    const response = await get<IUserCISResponse>(`${userPartitionUrl}/licenses`, {
        urlParams: { partitionGlobalId },
        pagination,
    });
    return cleanUserCISResponse(response);
}
