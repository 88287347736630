import { getEnvironment } from '@experiences/util';
import Cookies from 'js-cookie';
import { User } from 'oidc-client-ts';

export default function setSignInCookie(user: User, loginMethod: string) {
    const env = getEnvironment();
    Cookies.set(`lastUsedLoginEmail-${env}`, user.profile.email!, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });

    // On success, promote the currentLoginMethod cookie to lastUsedLoginMethod
    Cookies.set(`currentLoginMethod-${env}`, loginMethod, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
    Cookies.set(`lastUsedLoginMethod-${env}`, loginMethod, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
}
