import { Token } from '@experiences/interfaces';
import jwtDecode from 'jwt-decode';

let _token: string;

export function setToken(token: string) {
    _token = token;
}

export function getToken() {
    return _token;
}

export async function getSub(tokenToUse = undefined) {
    const token = tokenToUse || (await getToken());

    if (!token) {
        return null;
    }

    const decodedToken = jwtDecode<Token>(token);
    return decodedToken.sub;
}
