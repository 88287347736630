import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

import * as RouteNames from '../../common/constants/RouteNames';
import { ISettingRoute } from '../../common/interfaces/route';

const EnableAddDirectoryUsers = getFeatureFlagValue(Features.EnableAddDirectoryUsers.name);
const EnableSecuritySettingsPage = getFeatureFlagValue(Features.EnableSecuritySettingsPage.name);
const EnableReferenceTokens = getFeatureFlagValue(Features.EnableReferenceTokens.name);
const EnableSecuritySettingsSessionPolicy = getFeatureFlagValue(Features.EnableSecuritySettingsSessionPolicy.name);
const EnableSecuritySettingsIPRestriction = getFeatureFlagValue(Features.EnableSecuritySettingsIPRestriction.name);
const EnableSecuritySettingsEncryption = getFeatureFlagValue(Features.EnableSecuritySettingsEncryption.name);
const DisableUsersTab = getFeatureFlagValue(Features.DisableUsersTab.name);

export const SettingsRoutes: ISettingRoute[] = [
    {
        text: 'CLIENT_PAGE_TENANTS',
        route: RouteNames.Services,
        subRoutes: [ RouteNames.ServicesUsage ],
    },
    {
        text: 'CLIENT_ACCOUNTS_AND_GROUPS',
        route: DisableUsersTab ? RouteNames.Robots : RouteNames.Users,
        subRoutes: [
            RouteNames.Groups,
            ...[ RouteNames.Robots, RouteNames.RobotsAddEdit ],
            ...(!EnableSecuritySettingsPage ? [ RouteNames.AuthSettings ] : []),
            RouteNames.GroupsAddEdit,
            RouteNames.UserAddEdit,
            ...(EnableAddDirectoryUsers ? [ RouteNames.DirectoryUserAddEdit ] : []),
            RouteNames.UserInviteEdit,
        ],
    },
    {
        text: 'CLIENT_EXTERNAL_APPLICATIONS',
        route: RouteNames.ExternalApplications,
        subRoutes: [
            RouteNames.ExternalApplicationAddEdit,
            ...(EnableReferenceTokens ? [ RouteNames.ExternalApplicationsPAT ] : []),
        ],
    },
    {
        text: 'CLIENT_LICENSES',
        route: RouteNames.Licensing,
        subRoutes: [ RouteNames.ManagePlan ],
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        route: RouteNames.AuditLogs,
    },
    {
        text: 'CLIENT_ACCOUNT_SETTINGS_ACTION',
        route: RouteNames.OrganizationAdminHome,
        hide: true,
    },
    {
        text: 'CLIENT_PAGE_TENANTS',
        route: RouteNames.TenantHome,
        hide: true,
    },
    ...(EnableSecuritySettingsPage
        ? [
            {
                text: 'CLIENT_SECURITY_SETTINGS',
                route: RouteNames.SecuritySettings,
                subRoutes: [
                    RouteNames.SecuritySettingsConfigure,
                    ...(EnableSecuritySettingsSessionPolicy ? [ RouteNames.SessionPolicy ] : []),
                    ...(EnableSecuritySettingsIPRestriction ? [ RouteNames.IPRestriction ] : []),
                    ...(EnableSecuritySettingsEncryption ? [ RouteNames.Encryption ] : []),
                ],
            },
        ]
        : []),
    ...(process.buildConfigs.isOnPrem
        ? [
            {
                text: 'CLIENT_SECURITY_SETTINGS',
                route: RouteNames.AuthSettings,
                subRoutes: [
                    RouteNames.SecuritySettings,
                    RouteNames.AuthSettingsConfigureAD,
                    RouteNames.AuthSettingsConfigureAAD,
                    RouteNames.AuthSettingsConfigureGoogle,
                    RouteNames.AuthSettingsConfigureSaml,
                    RouteNames.SecuritySettingsConfigure,
                    RouteNames.AuthSettingsPasswordPolicy,
                    RouteNames.SessionPolicy,
                ],
            },
        ]
        : []),
    {
        text: 'CLIENT_ACCOUNT_SETTINGS',
        route: RouteNames.OrganizationSettings,
        subRoutes: [
            ...(process.buildConfigs.enableEmailSettings ? [ RouteNames.EmailSettings, RouteNames.EmailSettingsTest ] : []),
            ...(process.buildConfigs.enableLoginCustomization ? [ RouteNames.LoginCustomization ] : []),
            RouteNames.OrganizationSettingsAdvanced,
        ],
    },
];
