import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import ExternalApplicationsPageComponent from '../ExternalApplicationsPageComponent';
import ExternalApplicationsPATPageComponent from '../ExternalApplicationsPATPageComponent';

const ExternalAppGroupsTab = {
    OAUTH: 'oauth',
    PAT: 'PAT',
};

const useStyles = makeStyles(() => createStyles({ tabs: { marginBottom: '8px' } }));

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case ExternalAppGroupsTab.OAUTH:
            return <ExternalApplicationsPageComponent />;
        case ExternalAppGroupsTab.PAT:
            return <ExternalApplicationsPATPageComponent />;
    }

    return null;
};

const ExternalAppsTabs: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const getRoute = useRouteResolver();
    const isPAT = useRouteMatch(RouteNames.ExternalApplicationsPAT);

    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);

    const [ tabValue, setTabValue ] = useState(
        isPAT
            ? ExternalAppGroupsTab.PAT
            : ExternalAppGroupsTab.OAUTH,
    );

    const handleChange = useCallback((_, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={clsx('default', classes.tabs)}>
                <Tab
                    value={ExternalAppGroupsTab.OAUTH}
                    label={translate({ id: 'CLIENT_OAUTH_APPS' })}
                    onClick={() => history.push(getRoute(RouteNames.ExternalApplications))}
                    data-cy="oauth-tab"
                    className="default"
                />
                { EnableReferenceTokens && (
                    <Tab
                        value={ExternalAppGroupsTab.PAT}
                        label={translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN' })}
                        onClick={() => history.push(getRoute(RouteNames.ExternalApplicationsPAT))}
                        data-cy="pat-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default ExternalAppsTabs;

