import urljoin from 'url-join';

import * as manageUserMapping from '../constant/manageUserMapping.json';

export function navigateToExternalServiceManageUsers(
    serviceType: string,
    tenantName: string,
    accountLogicalName: string,
) {
    const configFromJson = (manageUserMapping as { [key: string]: string | undefined })[serviceType];
    if (configFromJson) {
        window.location.assign(urljoin(window.location.origin, accountLogicalName, tenantName, configFromJson));
        return;
    }
    window.location.assign(urljoin(window.location.origin, accountLogicalName, tenantName, serviceType + '_', 'users'));
}

export function isUserManagementEnabled(serviceType?: string) {
    return (
        serviceType === 'orchestrator'
            || serviceType === 'actions'
            || serviceType === 'processes'
            || serviceType === 'aifabric'
            || serviceType === 'dataservice'
            || serviceType === 'automationhub'
            || serviceType === 'automationstore'
            || serviceType === 'taskmining'
            || serviceType === 'processmining'
            || serviceType === 'insights'
    );
}
