import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export default (theme: Theme) =>
    createStyles({
        styleOverrides: {
            root: {},
            toolbar: {
                justifyContent: 'space-between',
                padding: '8px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            spacer: { display: 'none' },
            caption: {
                color: theme.palette.semantic.colorForegroundDeEmp,
                fontSize: '14px',
                '&:first-of-type': { display: 'none' },
            },
            selectRoot: {
                order: 3,
                marginRight: '0px',
                '&:before': {
                    color: theme.palette.semantic.colorForegroundDisable,
                    whiteSpace: 'nowrap',
                },
            },
            actions: {
                flex: 1,
                order: 2,
            },
        },
    });
