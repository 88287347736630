import { merge } from 'lodash';

import { ExternalAuthenticationScheme } from '../common/constants/ExternalIdentityProviderConstant';
import IExternalIdentity, { ISaml2ProviderSettings } from '../common/interfaces/externalIdentity';
import { IAADConfigData } from '../component/authsettings/subcomponents/ConfigureAADComponent';
import { IADConfigData } from '../component/authsettings/subcomponents/ConfigureADComponent';
import { IGoogleConfigData } from '../component/authsettings/subcomponents/ConfigureGoogleComponent';
import { ISamlConfigData } from '../component/authsettings/subcomponents/ConfigureSamlComponent';
import {
    removeUndefinedNullAndEmptyStringValues,
    replaceNullValuesWithUndefined,
} from './ObjectUtil';

export const mapADConfigDataToExternalIdentityPayload = (
    configData: IADConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.Windows },
): IExternalIdentity => {
    return removeUndefinedNullAndEmptyStringValues({
        ...externalIdentity,
        isActive: configData.isActive,
        isExclusive: configData.isExclusive,
        displayName: configData.displayName,
    });
};

export const mapExternalIdentityPayloadToADConfigData = (
    externalIdentity: IExternalIdentity,
): Partial<IADConfigData> => {
    return {
        isActive: externalIdentity.isActive,
        isExclusive: externalIdentity.isExclusive,
        displayName: externalIdentity.displayName,
    };
};

export const mapGoogleConfigDataToExternalIdentityPayload = (
    configData: IGoogleConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.Google },
): IExternalIdentity => {
    return removeUndefinedNullAndEmptyStringValues({
        ...externalIdentity,
        ...configData,
        clientSecret: configData.clientSecret || '********',
    });
};

export const mapExternalIdentityPayloadToGoogleConfigData = (
    externalIdentity: IExternalIdentity,
): Partial<IGoogleConfigData> => {
    return {
        isActive: externalIdentity.isActive,
        isExclusive: externalIdentity.isExclusive,
        displayName: externalIdentity.displayName,
        clientId: externalIdentity.clientId,
        clientSecret: '', // Do not include client secret since the secret is being used as a placeholder instead.
    };
};

export const mapAADConfigDataToExternalIdentityPayload = (
    configData: IAADConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.AzureAD },
): IExternalIdentity => {
    return removeUndefinedNullAndEmptyStringValues({
        ...externalIdentity,
        ...configData,
        clientSecret: configData.clientSecret || '********',
    });
};

export const defaultExternalIdentity: IAADConfigData = {
    isActive: false,
    isExclusive: false,
    displayName: '',
    clientId: '',
    clientSecret: '',
    authority: '',
    logoutUrl: '',
};

export const mapExternalIdentityPayloadToAADConfigData = (externalIdentity: IExternalIdentity): IAADConfigData => {
    return merge(defaultExternalIdentity, {
        isActive: externalIdentity.isActive,
        isExclusive: externalIdentity.isExclusive,
        displayName: externalIdentity.displayName,
        clientId: externalIdentity.clientId,
        clientSecret: '', // Do not include client secret since the secret is being used as a placeholder instead.
        authority: externalIdentity.authority,
        logoutUrl: externalIdentity.logoutUrl,
    });
};

export const mapSamlConfigDataToExternalIdentityPayload = (
    configData: ISamlConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.Saml2 },
): IExternalIdentity => {
    const { settings } = externalIdentity;
    const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
    const combinedSettings: ISaml2ProviderSettings = removeUndefinedNullAndEmptyStringValues({
        ...parsedSettings,
        ServiceProviderEntityId: configData.serviceProviderEntityId,
        IdentityProviderEntityId: configData.identityProviderEntityId,
        SingleSignOnServiceUrl: configData.singleSignOnServiceUrl,
        AllowUnsolicitedAuthnResponse: configData.allowUnsolicitedAuthnResponse,
        ReturnUrl: configData.returnUrl,
        ExternalAuthUserMappingStrategy: configData.externalAuthUserMappingStrategy,
        Saml2BindingType: configData.saml2BindingType,
        ExternalAuthUserIdentifierClaim: configData.externalAuthUserIdentifierClaim,
        SigningCertificateLocation: removeUndefinedNullAndEmptyStringValues({
            ...parsedSettings?.SigningCertificateLocation,
            ...configData.signingCertificateLocation,
        }),
        ServiceCertificateLocation: removeUndefinedNullAndEmptyStringValues({
            ...parsedSettings?.ServiceCertificateLocation,
            ...configData.serviceCertificateLocation,
        }),
    });
    return removeUndefinedNullAndEmptyStringValues({
        ...externalIdentity,
        isActive: configData.isActive,
        isExclusive: configData.isExclusive,
        displayName: configData.displayName,
        settings: JSON.stringify(combinedSettings),
    });
};

export const mapExternalIdentityPayloadToSamlConfigData = (
    externalIdentity: IExternalIdentity,
): Partial<ISamlConfigData> => {
    const { settings } = externalIdentity;
    const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
    return replaceNullValuesWithUndefined({
        isActive: externalIdentity.isActive,
        isExclusive: externalIdentity.isExclusive,
        displayName: externalIdentity.displayName,
        serviceProviderEntityId: parsedSettings?.ServiceProviderEntityId,
        identityProviderEntityId: parsedSettings?.IdentityProviderEntityId,
        singleSignOnServiceUrl: parsedSettings?.SingleSignOnServiceUrl,
        allowUnsolicitedAuthnResponse: parsedSettings?.AllowUnsolicitedAuthnResponse,
        returnUrl: parsedSettings?.ReturnUrl,
        externalAuthUserMappingStrategy: parsedSettings?.ExternalAuthUserMappingStrategy,
        saml2BindingType: parsedSettings?.Saml2BindingType,
        externalAuthUserIdentifierClaim: parsedSettings?.ExternalAuthUserIdentifierClaim,
        signingCertificateLocation: replaceNullValuesWithUndefined(parsedSettings?.SigningCertificateLocation),
        serviceCertificateLocation: replaceNullValuesWithUndefined(parsedSettings?.ServiceCertificateLocation),
    });
};

export const mapExternalIdentityPayloadToErrorCode = (externalIdentity: IExternalIdentity): string | undefined => {
    const { settings } = externalIdentity;
    if (!settings) {
        return undefined;
    }
    return (JSON.parse(settings) as ISaml2ProviderSettings).ErrorCode || undefined;
};
