import { useRouteResolver } from '@experiences/util';
import { Button } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';

export const useSuccessMessageActionsStyles = makeStyles(theme =>
    createStyles({
        secondaryButton: { marginLeft: '10px' },
        actionButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '12px',
            marginBottom: '12px',
            padding: '12px',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
    }),
);

const SuccessMessageActionsComponent: FC<{ close: (_: boolean) => void }> = ({ close }) => {
    const classes = useSuccessMessageActionsStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const isServicesPageEnabled = useIsServicesPageEnabled();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div className={classes.actionButtons}>
            <Button
                data-cy="success-message-actions-close-button"
                color="primary"
                variant={isServicesPageEnabled ? undefined : 'outlined'}
                onClick={() => close(true)}
            >
                {translate({ id: 'CLIENT_CLOSE' })}
            </Button>
            {isServicesPageEnabled && (
                <Link to={getRoute(isAdminRevampEnabled ? RouteNames.OrganizationAdminHome : RouteNames.Services)}>
                    <Button
                        data-cy="success-message-actions-go-to-services"
                        className={classes.secondaryButton}
                        variant="contained"
                    >
                        {translate({ id: isAdminRevampEnabled ? 'CLIENT_GO_TO_ORGANIZATION' : 'CLIENT_GO_TO_SERVICES' })}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default SuccessMessageActionsComponent;
