import {
    AccountType,
    IMlKey,
    IProductAllocation,
    ITabProductAllocation,
} from '@experiences/interfaces';
import _ from 'lodash';

import { IProductConfiguration } from '../common/constants/LicensingConfig';

const AccountLevelProductCodes = [ 'TIE', 'CV', 'AHUB', 'TMNG', 'PM', 'AIU' ];

export function extractProducts(
    productConfigs: IProductConfiguration[],
    productAllocations: IProductAllocation[],
    isServiceMode: boolean,
    isHome: boolean,
): ITabProductAllocation[] {
    return _.map(productConfigs, p => _.assign(p, _.find(productAllocations, { code: p.code }))).filter(p => {
        const isAvailable = !process.buildConfigs.unavailableProductCodes?.includes(p.code) ?? true;
        const hasQuantity = p.allocated > 0 || p.total > 0;
        const isAccountLevel = !isServiceMode;
        const isProductForServices = AccountLevelProductCodes.indexOf(p.code) === -1;
        const hideInService = p.hideInService && isServiceMode;
        const hideInHomepage = p.hideInHomepage && isHome;
        return (
            isAvailable && hasQuantity && (isAccountLevel || isProductForServices) && !(hideInService || hideInHomepage)
        );
    });
}

export function computeProductProperties(
    p: any,
    isHostMode: boolean,
    mlKeys: IMlKey[] | undefined,
    accountTypeSelector: AccountType | undefined,
): ITabProductAllocation {
    const extendedProduct = p as typeof p & Partial<ITabProductAllocation>;
    if (!isHostMode && extendedProduct.hasAPIKey && process.buildConfigs.enableApiKeys) {
        extendedProduct.licenseType = `MLSERVICE_${extendedProduct.customAPIKeyCode ?? extendedProduct.code}MODEL`;
        if (mlKeys) {
            const foundKey = mlKeys.find(key => key.licenseType === extendedProduct.licenseType);
            if (foundKey != null) {
                extendedProduct.mlServiceKey = foundKey.mlServiceKey;
            }
        }
    }

    if (extendedProduct.renderWithSubscriptionPlan) {
        extendedProduct.subscriptionPlan = accountTypeSelector;
    }

    if (extendedProduct.hideProgressForAccountType === accountTypeSelector) {
        extendedProduct.hideProgress = true;
    }

    if (extendedProduct.hideQuantityForAccountType === accountTypeSelector) {
        extendedProduct.hideQuantity = true;
    }

    if (extendedProduct.type === 'UNLIMITED' && isHostMode) {
        extendedProduct.hideProgress = false;
        extendedProduct.hideQuantity = false;
    }

    return extendedProduct;
}
