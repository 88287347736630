import { useGetErrorInfo } from '@experiences/error';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
    licenseTypes,
    notificationType,
} from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { deactivateLicenseOnline } from '../../services/licensing/management/ActivationService';
import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../store/action/UserProfileAction';
import {
    isHostModeSelector,
    profile,
} from '../../store/selectors';
import { UiDropdownButton } from '../common/UiDropdownButton/UiDropdownButton';
import LicenseActivationErrorDialogBody from './subcomponents/LicenseActivationErrorDialogBody';

const useStyles = makeStyles(() =>
    createStyles({
        buttons: { display: 'flex' },
        button: { marginLeft: '8px' },
    }),
);

const LicenseHeaderActionComponent: React.FC = () => {
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { getErrorObject } = useGetErrorInfo();
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);
    const licenseType = useMemo(
        () => accountProfile.accountUserDto.licenseType,
        [ accountProfile.accountUserDto.licenseType ],
    );
    const { licenseCode } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const deactivateOnline = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_DEACTIVATE_LICENSE' }),
            body: translate({ id: 'CLIENT_ONLINE_DEACTIVATE_CONFIRM' }),
            icon: 'warning',
            primaryButtonText: translate({ id: 'CLIENT_DEACTIVATE_ACTION' }),
            showCancel: true,
        });

        if (proceed) {
            try {
                const response = await deactivateLicenseOnline(licenseCode, isHostMode);
                if (response.operationStatus === 0) {
                    createNotification(translate({ id: 'CLIENT_DEACTIVATE_SUCCESSFULLY' }));
                }

                const userOrganizationInfo = await getUserOrganizationInfo();
                if (userOrganizationInfo) {
                    await setUserProfile(userOrganizationInfo);
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                if (errorObject.response?.status === 422) {
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                    });
                }
            }
        }
    }, [ createDialog, translate, createNotification, licenseCode, isHostMode, getErrorObject ]);

    return (
        <>
            {licenseType !== licenseTypes.HOSTED && (
                <div className={classes.buttons}>
                    <div className={classes.button}>
                        <UiDropdownButton
                            showDropdownButton
                            label={translate({ id: 'CLIENT_DEACTIVATE_LICENSE' })}
                            menuItems={[
                                {
                                    onClick: () => deactivateOnline(),
                                    label: translate({ id: 'CLIENT_ONLINE_DEACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        history.push(getRoute(RouteNames.DeactivateOffline));
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_DEACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                    <div className={classes.button}>
                        <UiDropdownButton
                            showDropdownButton
                            label={translate({ id: 'CLIENT_LICENSE_EXPIRED_ACTION_BANNER' })}
                            menuItems={[
                                {
                                    onClick: () => {
                                        history.push({
                                            pathname: `${getRoute(RouteNames.LicensingActivateLicenseOnline)}`,
                                            state: {
                                                previousLocation: location.pathname,
                                                isUpdateLicense: true,
                                            },
                                        });
                                    },
                                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                                },
                                {
                                    onClick: () => {
                                        history.push({
                                            pathname: `${getRoute(RouteNames.ActivateOffline)}`,
                                            state: {
                                                previousLocation: location.pathname,
                                                isUpdateLicense: true,
                                            },
                                        });
                                    },
                                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default LicenseHeaderActionComponent;
