import { ApolloThemeType } from '@uipath/portal-shell-util';

import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

export const cobrandingUrl = '/api/organization/cobranding';

export const getLogos = async () => {
    return await get(cobrandingUrl);
};

export const uploadLogo = async (theme: ApolloThemeType, image: File) => {
    const formData = new FormData();
    formData.append('file', image);
    const headers = {
        extendRequestHeaders: 'true',
        'Content-Type': 'multipart/form-data',
    };

    return await post(cobrandingUrl, {
        urlParams: { label: `${theme}Logo` },
        body: formData,
        isFormData: true,
        headers,
    });
};

export const deleteLogo = async (theme: string) => {
    await axiosDelete(cobrandingUrl, { urlParams: { label: `${theme}Logo` } });
};
