import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiPathLogo } from '@experiences/images';
import {
    mapAutomationCloudLanguageToStripe,
    useLocalization,
} from '@experiences/locales';
import {
    UiStorage,
    useModalState,
    useProtectRouteFromMissingParams,
} from '@experiences/util';
import {
    Button,
    Dialog,
    Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import {
    DIRECT_BUY_FLOW,
    DIRECT_BUY_SKU,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from './helpers/EcommerceHelpers';
import { BuyEnterprise } from './helpers/EcommerceRoutes';
import { useEcommerce } from './helpers/useEcommerce';
import {
    IBusinessInfoForm,
    ISkuPackage,
    ISubscriptionForm,
} from './interfaces/ecommerce';
import EcommerceBusinessInfoForm from './subcomponents/EcommerceBusinessInfoForm';
import EcommerceCheckoutForm from './subcomponents/EcommerceCheckoutForm';

const useStyles = makeStyles(theme =>
    createStyles({
        dialog: { padding: '0px' },
        dialogPaperProps: { padding: '0px !important' },
        backBar: {
            minHeight: '76px',
            maxHeight: '76px',
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '22px',
        },
        backLink: {
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
        },
        content: {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            margin: '32px 60px',
            justifyContent: 'space-between',
        },
        formContainer: { height: '100%' },
        summaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '60px',
            width: '500px',
        },
        infoText: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            margin: '24px 0px',
        },
        legalFormControl: {
            alignItems: 'unset',
            marginRight: '6px',
        },
        checkbox: {
            height: '42px',
            marginTop: '-9px',
        },
        submitError: {
            color: theme.palette.semantic.colorErrorText,
            fontSize: '12px',
        },
        payNowButton: {
            marginTop: '40px',
            width: '430px',
        },
        logo: { '& .iconOrange': { fill: theme.palette.semantic.colorForegroundHigh } },
    }),
);

interface IEcommerceCheckoutComponentState {
    skuPackage?: ISkuPackage;
    selectedCurrency?: string;
    businessInfo?: IBusinessInfoForm;
    showPaymentInfo?: boolean;
    billingInfo?: ISubscriptionForm;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    // expansion is needed twice because of strange Mui accessibility bug with transitions
    return <Slide
        direction="up"
        ref={ref}
        {...{
            ...props,
            role: 'dialog',
        }}
        aria-labelledby="checkoutDialog" />;
});

const EnableEcommerceIntegration = getFeatureFlagValue(Features.EnableEcommerceIntegration.name);

// Prevents stripe.js from making calls to js.stripe.com when the feature is not enabled
const { loadStripe } = EnableEcommerceIntegration ? require('@stripe/stripe-js') : require('@stripe/stripe-js/pure');

export const EcommerceCheckoutComponent: React.FC<{
    location?: {
        state?: IEcommerceCheckoutComponentState;
    };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        open, close: modalStateClose,
    } = useModalState(BuyEnterprise);

    const logEcommerceEvent = useEcommerceTelemetry();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();

    const {
        skuPackage, selectedCurrency, businessInfo, showPaymentInfo, billingInfo,
    } = useMemo(
        () => location?.state || {},
        [ location?.state ],
    );

    const _ga = Cookies.get('_ga');
    useEffect(() => {
        if (isDirectBuyInProgressFlow && !showPaymentInfo) {
            logEcommerceEvent('DirectBuy.OpenForm', {
                SelectedPlan: UiStorage.getItem(DIRECT_BUY_SKU),
                GAId: _ga,
            });
        }
    }, [ logEcommerceEvent, _ga, isDirectBuyInProgressFlow, showPaymentInfo ]);

    useProtectRouteFromMissingParams([ !skuPackage ], BuyEnterprise);
    const { publicKey } = useEcommerce(skuPackage, selectedCurrency);
    const locale = useLocalization();
    const [ stripePromise, setStripePromise ] = useState<Promise<Stripe | null> | null>(null);

    const close = () => {
        modalStateClose();
    };

    const stripeLocale = useMemo(() => mapAutomationCloudLanguageToStripe(locale), [ locale ]);

    useEffect(() => {
        if (publicKey) {
            setStripePromise(
                loadStripe(publicKey, { locale: stripeLocale }),
            );
        }
    }, [ publicKey, stripeLocale ]);

    return (
        <Dialog
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            className={classes.dialog}
            fullScreen
            open={open}
            onClose={() => close()}
            TransitionComponent={Transition}
            data-cy="checkout-dialog"
            aria-label={translate({ id: 'CLIENT_CHECKOUT_DIALOG' })}
            role="dialog"
            id="checkoutDialog"
            PaperProps={{
                className: classes.dialogPaperProps,
                'aria-labelledby': 'checkoutDialog',
            }}
        >
            <div className={classes.backBar}>
                <UiPathLogo className={classes.logo} />
                {!isSignupDirectBuyFlow && (
                    <Button
                        variant="text"
                        onClick={() => {
                            logEcommerceEvent(showPaymentInfo ? 'PaymentInfo.BackToAC' : 'BusinessInfo.BackToAC', {
                                SelectedPlan: skuPackage?.type,
                                Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                                GAId: Cookies.get('_ga'),
                            });

                            close();
                        }}
                        className={classes.backLink}
                        data-cy="back-to-automation-cloud-link"
                    >
                        {translate({ id: 'CLIENT_BACK_TO_AUTOMATION_CLOUD' })}
                    </Button>
                )}
            </div>
            {!showPaymentInfo ? (
                <EcommerceBusinessInfoForm completedBusinessInfo={businessInfo} />
            ) : (
                <Elements
                    stripe={stripePromise}
                    options={{ locale: stripeLocale }}
                    key={locale}>
                    <EcommerceCheckoutForm
                        savedBillingInfo={billingInfo}
                        businessInfo={businessInfo} />
                </Elements>
            )}
        </Dialog>
    );
};

