import { en } from '@experiences/locales';
import GlobalStyles from '@experiences/theme';
import {
    UiCheckBoxLabel,
    UiProgressButton,
} from '@experiences/ui-common';
import {
    UiStorage,
    useAuthContext,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import {
    DIRECT_BUY_FLOW,
    DIRECT_BUY_ORDER_CONFIRMATION,
    DIRECT_BUY_SKU,
    JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH,
    storeCountryCode,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from '../helpers/EcommerceHelpers';
import {
    BuyEnterpriseConfirmation,
    DirectBuyConfirmation,
} from '../helpers/EcommerceRoutes';
import {
    accountGlobalId,
    accountLogicalName,
    profile,
} from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import {
    IAddressDetails,
    IBusinessInfoForm,
    IOrderConfirmation,
    ISubscriptionForm,
} from '../interfaces/ecommerce';
import { StripeFormState } from '../interfaces/StripeElementClass';
import {
    billingSubscriptionUri,
    createDirectBuySubscription,
    createSubscription,
    getDirectBuyOrderConfirmationDetails,
    getOrderConfirmationDetails,
    validateUsTaxes,
} from '../services/BillingService';
import EcommerceCheckoutBillingAddress from './EcommerceCheckoutBillingAddress';
import { EcommerceCheckoutOrderSummary } from './EcommerceCheckoutOrderSummary';
import EcommerceCheckoutPaymentInfo from './EcommerceCheckoutPaymentInfo';
import EcommerceFormStepper from './EcommerceFormStepper';

export type enTranslationKeys = keyof typeof en;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        content: {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            margin: '32px 60px',
            justifyContent: 'space-between',
            maxWidth: '1600px',
        },
        formContainer: { height: '100%' },
        summaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '60px',
            width: '500px',
        },
        infoText: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            margin: '24px 0px',
        },
        nonTaxableEntity: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            margin: '8px 0px',
        },
        legalFormControl: {
            alignItems: 'unset',
            marginRight: '6px',
        },
        checkbox: {
            height: '42px',
            marginTop: '-9px',
        },
        checkboxLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '4px',
        },
        payNowButton: {
            marginTop: '40px',
            width: '430px',
        },
        japanPurchaseLegalText: {
            fontWeight: 400,
            lineHeight: '16px',
            fontSize: '12px',
            margin: '16px 0px -16px 0px',
        },
    }),
}));

const EcommerceCheckoutForm: React.FC<{ savedBillingInfo?: ISubscriptionForm; businessInfo?: IBusinessInfoForm }> = ({
    savedBillingInfo,
    businessInfo,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const accountId = useSelector(accountGlobalId);

    const logEcommerceEvent = useEcommerceTelemetry();
    const profileState = useSelector(profile);
    const currentAccountNameFromSelector = useSelector(accountLogicalName);
    const currentAccountName = useMemo(
        () => (isSignupDirectBuyFlow ? businessInfo?.logicalName : currentAccountNameFromSelector),
        [ isSignupDirectBuyFlow, currentAccountNameFromSelector, businessInfo?.logicalName ],
    );
    const [ stripeSubscriptionId, setStripeSubscriptionId ] = useState<string>();
    const [ organizationId, setOrganizationId ] = useState<string>();

    const stripe = useStripe();
    const elements = useElements();
    const stripeFormState = useRef(new StripeFormState());
    const {
        currentSkuPackageDetails,
        isTaxLoading: taxLoading,
        arePricesLoading: packageLoading,
        countryCode,
        setEcommerceCountry,
        isEuropeanUnionCountry,
        publicKey,
    } = useEcommerce();
    const { token } = useAuthContext();

    const [ loading, setLoading ] = useState(false);
    const [ waitingForUser, setWaitingForUser ] = useState(false);
    const createDialog = useShowDialog();
    const { enqueueSnackbar } = useSnackbar();
    const numberOfRetries = 24;

    const formMethods = useForm<ISubscriptionForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: savedBillingInfo ?? {
            firstName: '',
            lastName: '',
            country: countryCode ?? '',
            currency: currentSkuPackageDetails.defaultCurrency,
            city: '',
            state: '',
            addressLine1: '',
            addressLine2: '',
            zipcode: '',
            taxId: '',
            nameOnCard: '',
            termsAndConditionsAccepted: false,
        },
    });

    const {
        watch, setValue, formState, handleSubmit, register, setError, clearErrors,
    } = formMethods;

    const { isValid } = formState;

    const currentCurrency = watch('currency');

    const countryChangeHandler = useCallback(
        async (currentCountry: string) => {
            setWaitingForUser(true);
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_COUNTRY_WARNING' }),
                body: translate({ id: 'CLIENT_COUNTRY_WARNING_BODY' }),
                icon: 'warning',
                showCancel: true,
            });
            if (proceed) {
                setEcommerceCountry(currentCountry);
                storeCountryCode(currentCountry);
                history.replace({
                    ...history.location,
                    state: {
                        ...(history.location.state as any),
                        billingInfo: watch(),
                    },
                });
            } else {
                setValue('country', countryCode || '');
            }
            setWaitingForUser(false);
        },
        [ setWaitingForUser, createDialog, translate, countryCode, history, setEcommerceCountry, setValue, watch ],
    );

    useEffect(() => {
        if (currentSkuPackageDetails.defaultCurrency) {
            setValue('currency', currentSkuPackageDetails.defaultCurrency);
        }
    }, [ currentSkuPackageDetails.defaultCurrency, setValue ]);

    useEffect(() => {
        if (countryCode === 'US') {
            if (currentSkuPackageDetails.taxError?.status === 400 || currentSkuPackageDetails.tax?.isValid === false) {
                setError('zipcode', {
                    type: 'taxError',
                    message: translate({ id: 'CLIENT_BAD_ZIP_CODE' }),
                });
            } else if (currentSkuPackageDetails.taxError?.status && currentSkuPackageDetails.taxError.status >= 500) {
                setError('zipcode', {
                    type: 'avalaraError',
                    message: translate({ id: 'CLIENT_UNABLE_TO_CALCULATE_TAX_AVALARA_IS_DOWN' }),
                });
            } else {
                clearErrors('zipcode');
            }
        } else {
            if (currentSkuPackageDetails.taxError?.status && currentSkuPackageDetails.taxError.status >= 500) {
                (async () => {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TAX_CALCULATION_ERROR_TITLE' }),
                        body: translate({ id: 'CLIENT_TAX_CALCULATION_ERROR_BODY' }),
                        primaryButtonText: translate({ id: 'CLIENT_REFRESH' }),
                        icon: 'warning',
                        showCancel: false,
                    });
                    if (proceed) {
                        window.location.reload();
                    }
                })();
            }
        }
    }, [
        setError,
        translate,
        currentSkuPackageDetails.taxError,
        currentSkuPackageDetails.tax,
        countryCode,
        createDialog,
        history,
        clearErrors,
    ]);

    const retriesRef = useRef(0);

    const {
        data: orderConfirmation, isValidating,
    } = useSWR<IOrderConfirmation | undefined, Error>(
        stripeSubscriptionId ? [ currentAccountName, stripeSubscriptionId, billingSubscriptionUri ] : null,
        () => {
            if (stripeSubscriptionId && currentAccountName && organizationId) {
                if (isSignupDirectBuyFlow) {
                    return getDirectBuyOrderConfirmationDetails(
                        organizationId,
                        countryCode,
                        stripeSubscriptionId,
                        token!,
                    );
                }
                return getOrderConfirmationDetails(stripeSubscriptionId, currentAccountName, token);
            }
        },
    );

    useEffect(() => {
        if (isSignupDirectBuyFlow && orderConfirmation?.status === 'PAYMENT_PROCESSED') {
            UiStorage.setItem(DIRECT_BUY_ORDER_CONFIRMATION, JSON.stringify(orderConfirmation));
            history.push(DirectBuyConfirmation);
        }
    }, [ isSignupDirectBuyFlow, stripeSubscriptionId, history, orderConfirmation ]);

    useEffect(() => {
        if (stripeSubscriptionId && orderConfirmation) {
            if (
                !isValidating &&
                orderConfirmation.status === 'PAYMENT_INITIALIZED' &&
                retriesRef.current < numberOfRetries
            ) {
                setTimeout(() => {
                    retriesRef.current = retriesRef.current + 1;
                    mutate([ currentAccountName, stripeSubscriptionId, billingSubscriptionUri ]);
                }, 5000);
            } else if (orderConfirmation.status === 'PAYMENT_PROCESSED') {
                setLoading(false);
                if (isSignupDirectBuyFlow) {
                    orderConfirmation.organizationId = organizationId;
                    UiStorage.setItem(DIRECT_BUY_ORDER_CONFIRMATION, JSON.stringify(orderConfirmation));
                    UiStorage.removeItem(DIRECT_BUY_SKU);
                    history.push(DirectBuyConfirmation);
                } else {
                    UiStorage.removeItem(DIRECT_BUY_SKU);
                    history.push(getRoute(BuyEnterpriseConfirmation), { orderConfirmation });
                }
            } else if (orderConfirmation.status === 'PAYMENT_FAILED' || retriesRef.current >= numberOfRetries) {
                (async () => {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                        body: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY' }),
                        icon: 'warning',
                        showCancel: false,
                        primaryButtonText: translate({ id: 'CLIENT_OK' }),
                    });
                    if (proceed) {
                        setLoading(false);
                    }
                })();
            }
        }
    }, [
        orderConfirmation,
        isValidating,
        retriesRef,
        stripeSubscriptionId,
        currentAccountName,
        organizationId,
        getRoute,
        history,
        createDialog,
        translate,
        isSignupDirectBuyFlow,
    ]);

    const sendEcommerceEvent = useCallback(
        (eventName: string, data: ISubscriptionForm, otherProperties?) => {
            logEcommerceEvent(eventName, {
                SelectedPlan: currentSkuPackageDetails.type,
                Country: data.country,
                City: data.city,
                Currency: data.currency,
                Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                GAId: Cookies.get('_ga'),
                ...otherProperties,
            });
        },
        [ logEcommerceEvent, currentSkuPackageDetails.type, isDirectBuyInProgressFlow ],
    );

    useEffect(() => {
        if (
            currentSkuPackageDetails?.stripePublishableKey &&
            publicKey !== currentSkuPackageDetails?.stripePublishableKey
        ) {
            if (publicKey) {
                window.location.reload();
            }
        }
    }, [ currentSkuPackageDetails, publicKey ]);

    const submit = useCallback(
        async (data: ISubscriptionForm) => {
            setLoading(true);
            clearErrors('zipcode');
            clearErrors('addressLine1');

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                setLoading(false);
                return;
            }

            sendEcommerceEvent('Billing.Pay', data);

            try {
                const cardNumberElement = elements.getElement(CardNumberElement);
                const cardExpiryElement = elements.getElement(CardExpiryElement);
                const cardCvcElement = elements.getElement(CardCvcElement);
                if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
                    setLoading(false);
                    return;
                }

                const addressDetails = {
                    line1: data.addressLine1,
                    line2: data.addressLine2,
                    city: data.city,
                    country: data.country,
                    postalcode: data.zipcode,
                    state: data.state,
                } as IAddressDetails;

                if (data.country === 'US' && token) {
                    const validationResult = await validateUsTaxes(addressDetails, token, currentAccountName);

                    sendEcommerceEvent('Billing.ValidateAddress', data, { ValidationResult: validationResult.isValid });

                    if (!validationResult.isValid) {
                        setError('addressLine1', { type: 'addressError' });
                        setError('zipcode', { type: 'addressError' });
                        setError('city', { type: 'addressError' });
                        setError('state', { type: 'addressError' });

                        enqueueSnackbar(translate({ id: 'CLIENT_UNABLE_TO_VALIDATE_YOUR_BILLING_ADDRESS' }), { variant: 'error' });
                        setLoading(false);
                        return;
                    }
                }

                const stripePaymentObject = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        address: {
                            city: data.city,
                            country: data.country,
                            line1: data.addressLine1,
                            line2: data.addressLine2,
                            postal_code: data.zipcode,
                            state: data.state,
                        },
                        email: profileState.emailId,
                        name: data.nameOnCard,
                    },
                });

                sendEcommerceEvent('Billing.CreatePaymentMethod', data, { OperationSuccessful: stripePaymentObject.error == null });

                if (stripePaymentObject.error) {
                    enqueueSnackbar(stripePaymentObject.error.message, { variant: 'error' });
                    setLoading(false);
                    return;
                }
                const marketoData = localStorage.getItem('marketoData');
                const leadDetails = marketoData != null ? JSON.parse(marketoData) : null;
                const organizationName = businessInfo?.logicalName;
                const subscriptionDetails = {
                    packageType: currentSkuPackageDetails!.type,
                    currency: data.currency,
                    paymentMethod: stripePaymentObject.paymentMethod!.id,
                    countryCode: data.country,
                    addressDetails: addressDetails,
                    taxId: data.taxId,
                    leadDetails: leadDetails,
                    organizationName,
                    ...(isSignupDirectBuyFlow && { googleAnalyticsId: Cookies.get('_ga') }),
                    ...({ products: currentSkuPackageDetails!.products }),
                };
                const subDto =
                    isSignupDirectBuyFlow && token
                        ? await createDirectBuySubscription(subscriptionDetails, token)
                        : await createSubscription(subscriptionDetails, currentAccountNameFromSelector, token);
                setOrganizationId(isSignupDirectBuyFlow ? subDto.organizationId : accountId);

                if (subDto.subscriptionStatus === 'active') {
                    setStripeSubscriptionId(subDto.stripeSubscriptionId);
                } else if (
                    subDto.subscriptionStatus === 'incomplete' &&
                    subDto.paymentIntentStatus === 'requires_action'
                ) {
                    sendEcommerceEvent('Billing.AskForSCA', data);
                    const result = await stripe.confirmCardPayment(subDto.paymentIntentClientSecret,
                        { payment_method: stripePaymentObject.paymentMethod?.id });
                    sendEcommerceEvent('Billing.FinishSCA', data,
                        { OperationSuccessful: result.paymentIntent?.status === 'succeeded' });
                    if (result.paymentIntent?.status === 'succeeded') {
                        localStorage.removeItem('marketoData');
                        setStripeSubscriptionId(subDto.stripeSubscriptionId);
                    } else {
                        const errorMessage =
                            result.error?.message ?? translate({ id: 'CLIENT_PAYMENT_FAILURE_GENERAL_ERROR' });
                        enqueueSnackbar(errorMessage, { variant: 'error' });
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    sendEcommerceEvent('Billing.PayError', data, { Error: en['CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY'] });
                    await createDialog({
                        title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                        body: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY' }),
                        icon: 'warning',
                        showCancel: false,
                        primaryButtonText: translate({ id: 'CLIENT_OK' }),
                    });
                }
            } catch (error: any) {
                let errorBodyMessage, eventErrorMessage;
                if (error.message?.includes('The customer already has a subscription started')) {
                    eventErrorMessage = en['CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS'];
                    errorBodyMessage = (
                        <div>
                            {translate({ id: 'CLIENT_STRIPE_SUBSCRIPTION_ALREADY_EXISTS' })}
                        </div>
                    );
                } else {
                    eventErrorMessage = en[error.translationId as enTranslationKeys];
                    errorBodyMessage = (
                        <FormattedMessage
                            id={error.translationId}
                            values={{
                                p: (msg: string) => <p>
                                    {msg}
                                </p>,
                            }}
                        />
                    );
                }

                sendEcommerceEvent('Billing.PayError', data, { Error: eventErrorMessage });

                setLoading(false);
                await createDialog({
                    title: translate({ id: 'CLIENT_PAYMENT_TROUBLE_PROCESSING_TITLE' }),
                    body: errorBodyMessage,
                    icon: 'warning',
                    showCancel: false,
                    primaryButtonText: translate({ id: 'CLIENT_OK' }),
                });
            }
        },
        [
            elements,
            stripe,
            profileState,
            currentAccountName,
            currentSkuPackageDetails,
            clearErrors,
            translate,
            setError,
            enqueueSnackbar,
            createDialog,
            isSignupDirectBuyFlow,
            businessInfo,
            token,
            currentAccountNameFromSelector,
            accountId,
            sendEcommerceEvent,
        ],
    );

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(submit)}>
                <div className={classes.content}>
                    <div />
                    <div className={classes.formContainer}>
                        <EcommerceFormStepper activeStep={1} />
                        <EcommerceCheckoutBillingAddress
                            currencies={Object.keys(currentSkuPackageDetails.prices)}
                            disabled={loading || !!packageLoading || waitingForUser}
                            countryChangeHandler={countryChangeHandler}
                        />
                        <EcommerceCheckoutPaymentInfo
                            stripeFormState={stripeFormState}
                            disabled={loading || !!packageLoading}
                        />
                        <Button
                            variant="outlined"
                            data-cy="ecommerce-form-back"
                            onClick={() => {
                                logEcommerceEvent('PaymentInfo.Back', {
                                    SelectedPlan: currentSkuPackageDetails.type,
                                    Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                                    GAId: Cookies.get('_ga'),
                                });
                                history.replace({
                                    ...history.location,
                                    state: {
                                        ...(history.location.state as any),
                                        showPaymentInfo: false,
                                    },
                                });
                            }}
                        >
                            {translate({ id: 'CLIENT_BACK' })}
                        </Button>
                    </div>
                    <div className={classes.summaryContainer}>
                        {currentSkuPackageDetails && (
                            <EcommerceCheckoutOrderSummary
                                {...currentSkuPackageDetails}
                                taxLoading={taxLoading}
                                currency={currentCurrency}
                                price={currentSkuPackageDetails.prices[currentCurrency]}
                                discountedPrice={currentSkuPackageDetails.discountedPrices[currentCurrency]}
                                discountDetails={currentSkuPackageDetails.discountDetails}
                                productPricesLoading={packageLoading ?? !currentSkuPackageDetails.prices[currentCurrency]}
                                isFirstSubscription={true}
                            />
                        )}

                        {countryCode === 'JP' && (
                            <Link
                                className={clsx(classes.japanPurchaseLegalText)}
                                href={JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH}
                                data-cy="japan-purchase-legal-text"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translate({ id: 'CLIENT_JP_COMMERCIAL_TRANSACTION_ACT' })}
                            </Link>
                        )}

                        <Typography className={classes.infoText}>
                            {translate({ id: 'CLIENT_PAY_NOW_INFO' })}
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    data-cy="complete-invite-terms-conditions"
                                    className={classes.checkbox}
                                />
                            }
                            label={
                                countryCode === 'JP' ? (
                                    <UiCheckBoxLabel
                                        className={classes.checkboxLabel}
                                        label="CLIENT_ECOMMERCE_TERMS_JAPAN"
                                        href={[
                                            'https://www.uipath.com/hubfs/legalspot/UiPath_MSSA_EN_online_TCs.pdf',
                                            'https://www.uipath.com/ja/legal/privacy-policy',
                                        ]}
                                        hrefText={[ 'CLIENT_TERMS_OF_USE_FOR_ONLINE_SOFTWARE', 'CLIENT_UIPATH_PRIVACY_POLICY' ]}
                                        dataCy="japan-ecommerce-terms-label"
                                    />
                                ) : (
                                    <UiCheckBoxLabel
                                        className={classes.checkboxLabel}
                                        label="CLIENT_ECOMMERCE_TERMS"
                                        href={[ 'https://www.uipath.com/hubfs/legalspot/UiPath_MSSA_EN_online_TCs.pdf' ]}
                                        hrefText={[ 'CLIENT_TERMS_OF_USE_FOR_ONLINE_SOFTWARE' ]}
                                        dataCy="ecommerce-terms-label"
                                    />
                                )
                            }
                            className={classes.legalFormControl}
                            name="termsAndConditionsAccepted"
                            inputRef={register({ required: true })}
                            disabled={loading || !!packageLoading}
                            data-cy="ecommerce-termsAndConditions"
                        />

                        <UiProgressButton
                            className={classes.payNowButton}
                            style={{ width: '100%' }}
                            loading={loading || !!packageLoading}
                            disabled={
                                !isValid ||
                                stripeFormState.current.hasError() ||
                                !stripeFormState.current.allCompleted()
                            }
                            type="submit"
                            variant="contained"
                            data-cy="ecommerce-pay-button"
                        >
                            {translate({ id: 'CLIENT_PAY_NOW' })}
                        </UiProgressButton>

                        {isEuropeanUnionCountry && (
                            <Typography
                                className={classes.nonTaxableEntity}
                                data-cy="non-taxable-entity">
                                {translate(
                                    { id: 'CLIENT_EU_NON_TAXABLE_LEGAL_ENTITY' },
                                    {
                                        0: (
                                            <a
                                                className={classes.a}
                                                href="https://www.uipath.com/company/contact-us"
                                                data-cy="contact-sales-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {translate({ id: 'CLIENT_HERE' })}
                                            </a>
                                        ),
                                    },
                                )}
                            </Typography>
                        )}
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default EcommerceCheckoutForm;
