export interface IServiceMap {
    id: string;
    name: string;
}

export const serviceMap: IServiceMap[] = [
    {
        id: 'orchestrator',
        name: 'Orchestrator',
    },
    {
        id: 'portal',
        name: 'Cloud Portal',
    },
    {
        id: 'aifabric',
        name: 'AI Center',
    },
    {
        id: 'actions',
        name: 'Actions',
    },
    {
        id: 'apps',
        name: 'Apps',
    },
    {
        id: 'processes',
        name: 'Process Launcher',
    },
    {
        id: 'chatbots',
        name: 'Chatbots',
    },
    {
        id: 'dataservice',
        name: 'Data Service',
    },
    {
        id: 'aifabric',
        name: 'AI Center',
    },
    {
        id: 'testmanager',
        name: 'Test Manager',
    },
    {
        id: 'admin',
        name: 'Admin',
    },
    {
        id: 'help',
        name: 'Help',
    },
    {
        id: 'automationhub',
        name: 'Automation Hub',
    },
    {
        id: 'insights',
        name: 'Insights',
    },
    {
        id: 'taskmining',
        name: 'Task Mining',
    },
    {
        id: 'processmining',
        name: 'Process Mining',
    },
    {
        id: 'automationstore',
        name: 'Automation Store',
    },
    {
        id: 'connections',
        name: 'Integration Service',
    },
    {
        id: 'du',
        name: 'Document Understanding',
    },
];

export function getFriendlyName(id: string, useIdDefault = true) {
    const friendlyName = serviceMap.find(service => service.id === id)?.name;
    if (friendlyName) {
        return friendlyName;
    }

    return useIdDefault ? id : 'Service';
}
