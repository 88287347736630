import { ActionsLabelIcon } from '@experiences/images';
import GlobalStyles from '@experiences/theme';
import {
    Button,
    Tab,
    Tabs,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import getTask from '../../../services/orchestrator/TaskService';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import {
    accountLogicalName,
    profile,
} from '../../../store/selectors';
import HomePageHeader from '../../home/helper/HomePageHeader';
import { TaskOdata } from './interfaces/task';
import TaskInfo from './TaskInfo';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actionContainer: { padding: '20px 0' },
        actionHeader: {
            display: 'flex',
            padding: '15px 0 0 0;',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        actionTypeContainer: { display: 'flex' },
    }),
}));

export const TaskComponent: React.FC = () => {
    const [ currentTenant, setCurrentTenant ] = useState(localStorage.getItem('PORTAL_CURRENT_TENANT'));
    const logicalName = useSelector(accountLogicalName);
    const currentProfile = useSelector(profile);
    const unassignedTaskUrl = '/api/unassigned';
    const pendingTaskUrl = '/api/pending';
    const {
        data: unAssignedTaskOdata, isValidating: isValidatingUnassigned, mutate: mutateUnassigned,
    } = useSWR<TaskOdata, Error>(
        unassignedTaskUrl + '/' + currentTenant,
        (() => getTask(currentTenant, logicalName, 'Unassigned')) as () => Promise<TaskOdata>,
    );
    const {
        data: pendingTaskOdata, isValidating: isValidatingPending, mutate: mutatePendingTask,
    } = useSWR<TaskOdata, Error>(
        pendingTaskUrl + '/' + currentTenant,
        (() => getTask(currentTenant, logicalName, 'Pending')) as () => Promise<TaskOdata>,
    );
    const pendingTask = useMemo(() => pendingTaskOdata?.value, [ pendingTaskOdata ]);
    const unAssignedTask = useMemo(() => unAssignedTaskOdata?.value, [ unAssignedTaskOdata ]);

    const tenantChangeListener = useCallback(
        (e: any) => {
            setUserProfile({
                ...currentProfile,
                currentOrchestratorUser: null,
            });
            setCurrentTenant(e.detail.selectedTenantId);
        },
        [ currentProfile ],
    );

    useEffect(() => {
        window.addEventListener('tenantChanged', tenantChangeListener);
        mutateUnassigned();
        mutatePendingTask();
        return function cleanup() {
            window.removeEventListener('tenantChanged', tenantChangeListener);
        };
    // eslint-disable-next-line
  }, [currentTenant]);

    const classes = useStyles();
    const [ tabIndex, setIndex ] = React.useState(0);
    const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndex(newValue);
    };
    enum Action {
        Pending,
        Unassigned,
    }

    const TabPanel: React.FC<{
        selectedTab: number;
    }> = ({ selectedTab }) => {
        switch (selectedTab) {
            case Action.Pending:
                return (
                    <TaskInfo
                        taskList={pendingTask}
                        loading={isValidatingPending}
                        accountLogicalName={logicalName}
                        tenantName={currentTenant}
                        actionType={selectedTab}
                    />
                );
            case Action.Unassigned:
                return (
                    <TaskInfo
                        taskList={unAssignedTask}
                        loading={isValidatingUnassigned}
                        accountLogicalName={logicalName}
                        tenantName={currentTenant}
                        actionType={selectedTab}
                    />
                );
            default:
                return <div />;
        }
    };
    const onTaskViewAll = () => {
        window.location.assign(`${window.location.origin}/${logicalName}/${currentTenant}/actions_`);
    };
    const { formatMessage: translate } = useIntl();

    return (
        <div
            className={clsx(classes.actionContainer)}
            data-cy="home-page-action-widget">
            <HomePageHeader
                icon={<ActionsLabelIcon className={classes.svgIcon} />}
                label={translate({ id: 'CLIENT_ACTIONS' })}
                fontSize="20px"
            />
            <div className={clsx(classes.actionHeader)}>
                <Tabs
                    value={tabIndex}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label={translate({ id: 'CLIENT_ACTIONS' })}
                    className="default"
                >
                    <Tab
                        label={translate({ id: 'CLIENT_PENDING' })}
                        className="default"
                        data-cy="home-page-action-pending-tab" />
                    <Tab
                        label={translate({ id: 'CLIENT_UNASSIGNED' })}
                        className="default"
                        data-cy="home-page-action-unassigned-tab"
                    />
                </Tabs>
                <div className={clsx(classes.actionTypeContainer)}>
                    <Button
                        color="primary"
                        onClick={onTaskViewAll}
                        data-cy="home-page-action-view-all">
                        <HomePageHeader
                            label={translate({ id: 'CLIENT_VIEW_ALL' })}
                            variant="button" />
                    </Button>
                </div>
            </div>
            <TabPanel selectedTab={tabIndex} />
        </div>
    );
};

export default TaskComponent;
