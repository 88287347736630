import React, {
    useCallback,
    useState,
} from 'react';

export const PortalShellRefreshContext = React.createContext<{
    shouldRefreshPortalShell: boolean;
    triggerPortalShellRefresh: () => void;
}>({
            shouldRefreshPortalShell: false,
            triggerPortalShellRefresh: () => {},
        });

export const useTriggerPortalShellRefresh = () => {
    return React.useContext(PortalShellRefreshContext);
};

export const PortalShellRefreshProvider: React.FC = ({ children }) => {
    const [ shouldRefreshPortalShell, setRefreshPortalShell ] = useState(false);

    const triggerPortalShellRefresh = useCallback(() => {
        setRefreshPortalShell(changed => !changed);
    }, []);

    return (
        <PortalShellRefreshContext.Provider
            value={{
                shouldRefreshPortalShell,
                triggerPortalShellRefresh,
            }}
        >
            {children}
        </PortalShellRefreshContext.Provider>
    );
};
