import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Fade,
    Link,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import {
    useSnackbar,
    VariantType,
} from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';

import {
    concurrentProductCodes,
    LegacyProductsToUserBundleLicenseMap,
    notificationType,
} from '../../../common/constants/Constant';
import { EnablingDisablingServicesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getTenantServiceLicenses,
    saveServiceLicenses,
} from '../../../services/licensing/LicenseManagementService';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountType,
    concurrentLicensesOpted,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiLicenseAllocationPerServiceComponent from '../../common/UiLicenseAllocationComponent/UiLicenseAllocationComponent';
import {
    IServiceLicenseAllocationRequest,
    ITenantServiceLicense,
    ITenantServiceProduct,
} from '../interfaces/service';
import { TenantStatusConstants } from '../TenantConstants';
import NeedMoreLicensesComponent from './forms/NeedMoreLicensesComponent';
import { getProductsFromServices } from './helpers/getProductsForServices';
import {
    concurrencyMapping,
    productSubLabel,
    sortedProductCodes,
    sortedServiceTypes,
    translationCode,
} from './helpers/ManageLicensesHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        groups: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        accordion: {
            width: '100%',
            boxShadow: 'none',
            border: `1px ${theme.palette.semantic.colorBorderDeEmp} solid`,
        },
        inputLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        labelGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        inputMargin: { marginBottom: '12px' },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        formButtons: { display: 'flex' },
        overAllocatedWarning: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginBottom: '6px',
        },
        statusMessage: {
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '24px',
            marginBottom: '24px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        serviceLicenseHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '34px',
        },
        serviceIcon: {
            margin: '0px',
            width: '18px',
        },
        serviceHeader: { marginLeft: '12px' },
        availableLicenses: { marginTop: '8px' },
    }),
);

export interface IConfigureTenantLicenses {
    products: { [code: string]: string | number | undefined };
}

const LicenseAllocationPerService: React.FC<{
    name: string;
    serviceLicense: ITenantServiceLicense;
}> = ({
    name, serviceLicense,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const {
        register, watch, formState,
    } = useFormContext<IConfigureTenantLicenses>();
    const { errors } = formState;

    const disableCallback = useCallback((product: ITenantServiceProduct) => {
        return {
            disableInput: concurrentLicenseEnabled && watch(`products.${concurrencyMapping[product.code]}`, 0) > 0,
            disableByOverusage: product.used > 0 && product.used >= product.allocated + product.available,
        };
    }, [ concurrentLicenseEnabled, watch ]);

    const minMaxCallback = useCallback((product: ITenantServiceProduct) => {
        return {
            minQuantity: product.reserved + product.used,
            maxQuantity: product.quantity + product.available > 0
                ? product.quantity + product.available
                : product.quantity,
        };
    }, []);

    const getTooltip = useCallback((disableInput: boolean, newLicensingUIEnabled: boolean) => {
        if (!disableInput) {
            return '';
        }

        return newLicensingUIEnabled
            ? translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_MULTIUSER' })
            : translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_CONCURRENT' });
    }, [ translate ]);

    const getHelperText = useCallback((product: ITenantServiceProduct) => {
        const {
            minQuantity, maxQuantity,
        } = minMaxCallback(product);

        if (errors.products?.[product.code]?.type === 'required') {
            return translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' });
        } else if (errors.products?.[product.code]?.type === 'min'
            || (errors.products?.[product.code]?.type === 'max' && maxQuantity > 0)) {
            return translate({ id: 'CLIENT_WARN_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (errors.products?.[product.code]?.type === 'max' && maxQuantity === 0) {
            return translate({ id: 'CLIENT_WARN_NO_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (product.available < 0) {
            return (
                <div className={classes.overAllocatedWarning}>
                    {translate(
                        { id: 'CLIENT_TENANTS_LICENSE_OVERALLOCATED' },
                        { 0: Math.abs(product.available) },
                    )}
                    <Tooltip
                        data-cy="overallocation-tooltip"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={translate(
                            { id: 'CLIENT_LICENSE_OVERALLOCATION_TOOLTIP' },
                        )}
                        arrow
                    >
                        <InfoIcon
                            style={{
                                color: theme.palette.semantic.colorInfoForeground,
                                paddingLeft: '3px',
                                margin: '1px',
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        return isAdminRevampEnabled ? '' : `${product.available} ${translate({ id: 'CLIENT_AVAILABLE' })}`;
    }, [
        classes.overAllocatedWarning,
        errors.products,
        isAdminRevampEnabled,
        minMaxCallback,
        theme.palette.semantic.colorInfoForeground,
        translate,
    ]);

    const mappedProductCallback = useCallback((product: ITenantServiceProduct) => {
        const mappedProduct = LegacyProductsToUserBundleLicenseMap[product.code];
        const newLicensingUIEnabled = !!mappedProduct;
        const productCodeForTranslation = newLicensingUIEnabled
            ? mappedProduct.slice(0, -2)
            : product.code;
        const productLabel = newLicensingUIEnabled
            ? mappedProduct.slice(-2)
            : productSubLabel(translationCode(productCodeForTranslation));

        return {
            newLicensingUIEnabled,
            productCodeForTranslation,
            productLabel,
        };
    }, []);

    const AccordionLicenses = useMemo(() => (
        <Accordion
            className={classes.accordion}
            defaultExpanded={true}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                    {getFriendlyName(serviceLicense.serviceType)}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.groupColumn}>
                    {serviceLicense.products?.map((product, j) => {
                        const {
                            disableInput, disableByOverusage,
                        } = disableCallback(product);
                        const {
                            minQuantity, maxQuantity,
                        } = minMaxCallback(product);

                        const {
                            newLicensingUIEnabled, productCodeForTranslation, productLabel,
                        } = mappedProductCallback(product);

                        return (
                            <div
                                key={j}
                                className={clsx(classes.input, classes.inputLine, classes.inputMargin)}>
                                <div className={classes.labelGroup}>
                                    <Typography
                                        className={classes.inputLabel}
                                        id={`${productCodeForTranslation}Label`}>
                                        {translate({ id: `CLIENT_${translationCode(productCodeForTranslation)}` })}
                                    </Typography>
                                    {productLabel && (
                                        <Typography className={clsx(classes.inputLabel, classes.subText)}>
                                            {translate({ id: `CLIENT_${productLabel}` })}
                                        </Typography>
                                    )}
                                </div>
                                <Tooltip
                                    arrow
                                    title={getTooltip(disableInput, newLicensingUIEnabled)}
                                >
                                    <TextField
                                        name={`products.${product.code}`}
                                        inputRef={register(
                                            disableByOverusage ? undefined : {
                                                required: true,
                                                min: minQuantity,
                                                max: maxQuantity,
                                            },
                                        ) as any}
                                        InputProps={{ readOnly: disableInput || disableByOverusage }}
                                        error={!!errors.products?.[`${product.code}`] || product.available < 0}
                                        type="number"
                                        helperText={getHelperText(product)}
                                        variant="outlined"
                                        size="small"
                                        style={{ maxWidth: '50%' }}
                                        aria-labelledby={`${productCodeForTranslation}Label`}
                                    />
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
            </AccordionDetails>
        </Accordion>
    ), [
        classes.accordion,
        classes.groupColumn,
        classes.input,
        classes.inputLabel,
        classes.inputLine,
        classes.inputMargin,
        classes.labelGroup,
        classes.subText,
        disableCallback,
        errors.products,
        getHelperText,
        getTooltip,
        mappedProductCallback,
        minMaxCallback,
        register,
        serviceLicense.products,
        serviceLicense.serviceType,
        translate,
    ]);

    return !isAdminRevampEnabled
        ? AccordionLicenses
        : <UiLicenseAllocationPerServiceComponent
            name={name}
            serviceLicense={serviceLicense} />;
};

export const ConfigureTenantLicensesFormComponent: React.FC<{
    name: string;
    type: 'configure' | 'addLicenses' | 'enable';
    tenantServiceLicenses: ITenantServiceLicense[] | undefined;
    serviceType?: string;
}> = ({
    name, type, tenantServiceLicenses, serviceType,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    const getLocalizedLink = useDocumentationLinks();

    const currentAccountType = useSelector(accountType);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const { setValue } = useFormContext();

    const {
        tenantHasServiceLicenses, tenantHasLicenseProductUnavailable,
    } = useMemo(
        () => ({
            tenantHasServiceLicenses: !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0),
            tenantHasLicenseProductUnavailable: !!tenantServiceLicenses?.some(serviceLicense =>
                serviceLicense.products.some(product => product.available === 0),
            ),
        }),
        [ tenantServiceLicenses ],
    );

    const products = useMemo(() => getProductsFromServices(tenantServiceLicenses, concurrentLicenseEnabled),
        [ tenantServiceLicenses, concurrentLicenseEnabled ]);

    useEffect(() => {
        if (type === 'addLicenses' && products) {
            setValue(name, products);
        }
    }, [ name, products, setValue, type ]);

    const statusMessage = useMemo(() => {
        if (type === 'enable' && serviceType) {
            return translate({ id: 'CLIENT_ENABLING_SERVICE' }, { serviceType: getFriendlyName(serviceType) });
        }

        if (type === 'addLicenses') {
            return translate({ id: 'CLIENT_ADDING_SERVICES_TO_TENANT' });
        }

        return '';
    }, [ serviceType, translate, type ]);

    return <>
        {
            isAdminRevampEnabled
            && type !== 'configure'
            && (
                <>
                    <Typography
                        className={classes.statusMessage}
                        data-cy="licenses-adding-services">
                        <CircularProgress
                            size="18px"
                            style={{ marginRight: '16px' }} />
                        {statusMessage}
                    </Typography>
                    <Typography data-cy="licenses-adding-services-description">
                        {translate({ id: 'CLIENT_ALLOCATE_LICENSES_DESCRIPTION' })}
                        <Link
                            style={{
                                marginLeft: '4px',
                                color: theme.palette.semantic.colorForegroundLink,
                            }}
                            href={getLocalizedLink(EnablingDisablingServicesLink)}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </Typography>
                </>
            )
        }
        <div className={isAdminRevampEnabled ? undefined : clsx(classes.groups, classes.groupColumn)}>
            {tenantHasServiceLicenses ? (
                tenantServiceLicenses
                    ?.filter(serviceLicense => serviceLicense.products.length > 0)
                    .map((serviceLicense, i) => (
                        <React.Fragment key={i}>
                            <LicenseAllocationPerService
                                serviceLicense={serviceLicense}
                                name={name} />
                        </React.Fragment>
                    ))
            ) : (
                <div className={clsx(classes.groups, classes.groupColumn)}>
                    <Typography
                        className={classes.noLicensesAvailable}
                        data-cy="case-no-license-available">
                        {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                    </Typography>
                </div>
            )}
            {isAdminRevampEnabled && <div style={{ marginBottom: '36px' }} />}
            {tenantHasLicenseProductUnavailable && AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
            <NeedMoreLicensesComponent />}
        </div>
    </>;
};

const ConfigureTenantLicensesComponent: React.FC<{
    location?: {
        state?: {
            previousLocation: string;
            services: string[];
        };
    };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const { getErrorMessage } = useGetErrorInfo();

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as VariantType });
        },
        [ enqueueSnackbar ],
    );

    const match = useRouteMatch<{
        tenantId: string;
        serviceType: string;
        type: 'configure' | 'addLicenses' | 'enable';
    }>();

    const partitionGlobalId = useSelector(accountGlobalId);
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const setErrorMessage = useCentralErrorSetter();

    const {
        tenantId, serviceType, type, addedServices, previousLocation,
    } = useMemo(() => ({
        tenantId: match.params.tenantId,
        serviceType: match.params.serviceType,
        type: match.params.type,
        addedServices: location?.state?.services,
        previousLocation: location?.state?.previousLocation,
    }), [
        location?.state?.previousLocation,
        location?.state?.services,
        match.params.serviceType,
        match.params.tenantId,
        match.params.type,
    ]);

    const previousRoute = useMemo(() => {
        if (previousLocation) {
            return previousLocation;
        }
        return isAdminRevampEnabled
            ? `${RouteNames.TenantServices.replace(':tenantId', tenantId)}`
            : RouteNames.Services;
    }, [ isAdminRevampEnabled, previousLocation, tenantId ]);

    const {
        close, open,
    } = useModalState(previousRoute);

    const { data: tenant } = useSWR<ITenant, Error>(
        tenantId ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null,
        getTenantById,
    );

    const services = useMemo(() => {
        if (!tenant?.tenantServiceInstances) {
            return [];
        }

        if (addedServices) {
            return addedServices;
        }

        if (serviceType) {
            return [ serviceType ];
        }

        return tenant.tenantServiceInstances
            .filter(service => service.status.toUpperCase() === TenantStatusConstants.ENABLED)
            .map(service => service.serviceType);
    }, [ addedServices, serviceType, tenant ]);

    const { data: tenantServiceLicenseData } = useSWR<ITenantServiceLicense[], Error>(
        tenantId && tenant
            ? [
                `/api/manageLicense/${partitionGlobalId}/service-licenses/${tenantId}`,
                partitionGlobalId,
                tenantId,
                services,
            ]
            : null,
        getTenantServiceLicenses,
    );

    const tenantServiceLicenses = useMemo(() => {
        return tenantServiceLicenseData
            ?.filter(tenantServiceLicense => services.includes(tenantServiceLicense.serviceType)
            && (!process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType) ?? true));
    }, [ services, tenantServiceLicenseData ]);

    const products = useMemo(() => {
        const initialLicenses: { [code: string]: string | number | undefined } = {};
        tenantServiceLicenses
            ?.sort((l1, l2) => {
                return sortedServiceTypes.indexOf(l1.serviceType) - sortedServiceTypes.indexOf(l2.serviceType);
            }).forEach(serviceLicense => {
                serviceLicense.products = serviceLicense.products.filter(
                    product => !process.buildConfigs.unavailableProductCodes?.includes(product.code) ?? true);
                serviceLicense.products.sort((p1, p2) => {
                    return sortedProductCodes.indexOf(p1.code) - sortedProductCodes.indexOf(p2.code);
                });
                serviceLicense.products.forEach(product => {
                    initialLicenses[product.code] = product.quantity ?? 0;
                });
                if (serviceLicense.serviceType === 'orchestrator' && !concurrentLicenseEnabled) {
                    serviceLicense.products = serviceLicense.products.filter(
                        product => concurrentProductCodes.indexOf(product.code) === -1,
                    );
                }
            });
        return initialLicenses;
    }, [ tenantServiceLicenses, concurrentLicenseEnabled ]);

    const methods = useForm<IConfigureTenantLicenses>({
        mode: 'onChange',
        defaultValues: { products },
    });

    const {
        handleSubmit, reset, formState,
    } = methods;
    const {
        errors, isSubmitting,
    } = formState;

    useEffect(() => {
        if (products) {
            reset({ products });
        }
    }, [ products, reset ]);

    useEffect(() => {
        if (errors.products) {
            document.querySelector<HTMLInputElement>(`[name="products.${Object.keys(errors.products)[0]}"]`)?.focus();
        }
    }, [ errors ]);

    const drawerTitle = useMemo(() => {
        if (!isAdminRevampEnabled) {
            return translate({ id: 'CLIENT_ALLOCATE_TO_TENANT' }, { 0: tenant?.name });
        }

        if (type === 'enable') {
            return translate({ id: 'CLIENT_ALLOCATE_LICENSES' });
        }

        return translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' });
    }, [ isAdminRevampEnabled, tenant, translate, type ]);

    const tenantHasServiceLicenses = useMemo(
        () => !!tenantServiceLicenses?.some(serviceLicense => serviceLicense.products.length > 0),
        [ tenantServiceLicenses ],
    );

    const saveLicensePerService = useCallback(async (serviceLicense: ITenantServiceLicense) => {
        const serviceProducts = serviceLicense.products.map(p => {
            return {
                code: p.code,
                quantity: p.quantity,
            };
        });
        const request: IServiceLicenseAllocationRequest = { products: serviceProducts };
        try {
            await saveServiceLicenses(partitionGlobalId, tenantId, serviceLicense.serviceType, request);
        } catch (error) {
            return await getErrorMessage(error);
        }

        return '';
    }, [ getErrorMessage, partitionGlobalId, tenantId ]);

    const onSubmit = useCallback(
        async (data: IConfigureTenantLicenses) => {
            try {
                const changedLicenses = new Set<ITenantServiceLicense>();
                tenantServiceLicenses?.forEach(serviceLicense => {
                    serviceLicense.products.forEach(product => {
                        if (product.quantity !== Number(data.products[product.code])) {
                            product.quantity = Number(data.products[product.code]);
                            if (!changedLicenses.has(serviceLicense)) {
                                changedLicenses.add(serviceLicense);
                            }
                        }
                    });
                });
                let error = '';
                for (const changedLicense of changedLicenses) {
                    const serviceError = await saveLicensePerService(changedLicense);
                    error += serviceError as string;
                }
                if (error) {
                    setErrorMessage(error);
                } else {
                    createNotification(translate({ id: 'CLIENT_TENANT_EDITED' }, { 0: tenant?.name }));
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                close(true);
            }
        },
        [
            tenantServiceLicenses,
            saveLicensePerService,
            setErrorMessage,
            createNotification,
            translate,
            tenant,
            getErrorMessage,
            close,
        ],
    );

    return (
        <UiDrawer
            title={drawerTitle}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={!tenant || !tenantServiceLicenses}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                data-cy="configure-licenses-cancel"
                                color="primary"
                            >
                                {translate({ id: type === 'configure' ? 'CLIENT_CANCEL' : 'CLIENT_SKIP' })}
                            </Button>
                            <UiProgressButton
                                loading={isSubmitting}
                                disabled={!tenantHasServiceLicenses}
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                data-cy="configure-licenses-save"
                            >
                                {translate({ id: type === 'configure' ? 'CLIENT_SAVE' : 'CLIENT_ALLOCATE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                >
                    <ConfigureTenantLicensesFormComponent
                        name="products"
                        type={type}
                        tenantServiceLicenses={tenantServiceLicenses}
                        serviceType={serviceType} />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default ConfigureTenantLicensesComponent;
