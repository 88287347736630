import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { useRouteResolver } from '@experiences/util';
import { Breadcrumbs } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { IAuthenticationSettingResponse } from '../../services/identity/AuthenticationSettingService';
import { ExternalProvidersAADPage } from '../authsettings/subcomponents/ExternalProvidersAADPage';
import UiLicenseExpiredBanner from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SecuritySettingsAADForm from './subcomponents/SecuritySettingsAADForm';
import SecuritySettingsSAMLForm from './subcomponents/SecuritySettingsSAMLForm';
import SsoPrerequirementsComponent from './subcomponents/SsoPrerequisitesComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        breadcrumbText: {
            fontSize: '18px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const ConfigureSSOPage: React.FC<{
    location?: {
        state?: {
            authenticationSetting: IAuthenticationSettingResponse;
            showPreReqs: boolean;
            samlFormActiveStep?: number;
        };
    };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const match = useRouteMatch<{ type: 'aad' | 'saml' }>();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const organizationName = useOrganizationName();

    const type = useMemo(() => (match.params.type === 'aad' ? 'aad' : 'saml'), [ match ]);
    const {
        authenticationSetting, showPreReqs, samlFormActiveStep,
    } = useMemo(
        () => ({
            authenticationSetting: location?.state?.authenticationSetting,
            showPreReqs: location?.state?.showPreReqs,
            samlFormActiveStep: location?.state?.samlFormActiveStep ?? 0,
        }),
        [ location ],
    );

    const handleError = useCallback(
        async error => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: !process.buildConfigs.isOnPrem ? RouteNames.SecuritySettingsConfigure : RouteNames.AuthSettings,
            name: translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_${type.toUpperCase()}_TITLE` }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                position='center'
                data-cy={`security-settings-configure-${type}-panel`}
            >
                {showPreReqs && <SsoPrerequirementsComponent type={type} />}
                {!showPreReqs &&
                authenticationSetting &&
                (type === 'aad' ? (
                    <ExternalProvidersAADPage />
                ) : (
                    <SecuritySettingsSAMLForm
                        authenticationSetting={authenticationSetting}
                        activeStep={samlFormActiveStep}
                        handleError={handleError}
                    />
                ))}
            </UiPageContainer> :
            <UiPanel
                licenseInGracePeriodBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={!process.buildConfigs.disableUserLicensing && <UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={!process.buildConfigs.disableUserLicensing && <UiOverAllocationBanner />}
                header={{
                    title: (
                        <Breadcrumbs separator=">">
                            <span
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                onClick={() =>
                                    history.push(
                                        getRoute(!process.buildConfigs.isOnPrem ? RouteNames.SecuritySettings : RouteNames.AuthSettings),
                                    )}
                            >
                                {translate({ id: 'CLIENT_AUTHENTICATION' })}
                            </span>
                            <span
                                className={classes.breadcrumbText}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_${type.toUpperCase()}_TITLE` })}
                            </span>
                        </Breadcrumbs>
                    ),
                }}
                data-cy={`security-settings-configure-${type}-panel`}
            >
                {showPreReqs && <SsoPrerequirementsComponent type={type} />}
                {!showPreReqs &&
    authenticationSetting &&
    (type === 'aad' ? (
        <SecuritySettingsAADForm
            authenticationSetting={authenticationSetting}
            handleError={handleError} />
    ) : (
        <SecuritySettingsSAMLForm
            authenticationSetting={authenticationSetting}
            activeStep={samlFormActiveStep}
            handleError={handleError}
        />
    ))}
            </UiPanel>
    );
};

export default ConfigureSSOPage;
