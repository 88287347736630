import { ActionTypes } from '../reducers/actionTypes';

export enum NotificationMode {
    InApp = 'InApp',
    Email = 'Email',
}

export interface INotificationSettingsReducer {
    tabIndex: number;
    publishers: INotificationPublisherDto[];
    publishersWithGroupedTopics: INotificationTopicPublisher[];
}

export interface INotificationSettingsAction {
    type: ActionTypes;
    data: INotificationPublisherDto[] | INotificationSettingsActionUpdateTabIndexData | INotificationSettingsActionUpdateTopicData | INotificationSettingsActionUpdateModeData | INotificationSettingsActionRestoreDefaultData;
}

export interface INotificationTopicPublisher {
    name: string;
    modes: INotificationMode[];
    topicGroups: INotificationTopicGroup[];
    publisherIndex: number;
    publisherId: string;
    isUpdating?: boolean;
}

export interface INotificationPublisherDto {
    name: string;
    modes: INotificationMode[];
    topics: INotificationTopicDto[];
    id: string;
}

export interface INotificationMode {
    name: NotificationMode;
    isSubscribed?: boolean;
    isIndeterminate?: boolean;
    isUpdating?: boolean;
    isActive?: boolean;
}

export interface INotificationTopicGroup {
    groupName: string;
    topics: INotificationTopic[];
    modes: INotificationMode[];
    groupIndex: number;
    isUpdating?: boolean;
}

export interface INotificationTopicDto {
    displayName: string;
    group: string | null;
    id: string;
    isSubscribed: boolean;
    name: string;
    parentGroup: string | null;
    topicFilters?: string;
    modes: INotificationMode[];
    description: string;
}

export interface INotificationTopic {
    displayName: string;
    topicId: string;
    name: string;
    isSubscribed: boolean;
    topicFiltersUrl?: string;
    topicIndex: number;
    isUpdating?: boolean;
    modes: INotificationMode[];
    description: string;
}

export interface IUpdateUserSubscriptionsRequestData {
    topicId: string;
    isSubscribed: boolean;
    notificationMode: NotificationMode;
}

export interface IUpdateUserNotificationSubscriptionModeRequestData {
    publisherId: string;
    publisherMode: INotificationMode;
}

export interface INotificationSettingsActionUpdateTabIndexData {
    tabIndex: number;
}

export interface INotificationSettingsActionUpdateTopicData {
    publisherIndex: number;
    groupIndex?: number;
    topicIndex?: number;
    isSubscribed?: boolean;
    mode?: NotificationMode;
}

export interface INotificationSettingsActionUpdateModeData {
    publisherIndex: number;
    modeIndex: number;
    newModeStatus?: boolean;
}

export interface INotificationSettingsActionRestoreDefaultData {
    publisherIndex: number;
    resetData?: INotificationPublisherDto[];
}

