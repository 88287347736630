import CloseIcon from '@mui/icons-material/Close';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiErrorBoundary } from '../UiErrorBoundary/index';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: {
            borderRadius: '6px !important',
            border: 'none',
            minWidth: 'min(100%, 400px)',
            maxWidth: '1000px',
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        iconContainer: {
            height: '32px',
            width: '32px',
        },
        closeIconContainer: {
            position: 'absolute',
            top: '4px',
            right: '4px',
        },
        closeIcon: {
            height: '16px',
            width: '16px',
        },
        title: { padding: '0 0 0 14px !important' },
        noIconTitle: { paddingLeft: '0px' },
        titleText: {
            fontWeight: 600,
            fontSize: '18px',
        },
        bodyContainer: {},
        bodyText: {
            marginTop: '12px',
            paddingTop: '15px',
            fontSize: '14px',
        },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
        actions: { padding: '12px 0px 0px 0px' },
    }),
);

export const UiDialog: React.FC<{
    title?: string;
    icon?: React.ReactNode;
    width?: string;
    children?: React.ReactNode;
    actions?: React.ReactNode[];
    bodyActions?: boolean;
    close: () => void;
    dialogProps?: DialogProps;
    loading?: boolean;
    hideClose?: boolean;
    dataCy?: string;
}> = ({
    title, icon, width, children, actions, bodyActions, close, dialogProps, loading, hideClose, dataCy,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    return (
        <Dialog
            data-cy={dataCy}
            aria-labelledby="dialogtitle"
            open={true}
            onClose={close}
            {...dialogProps}
            BackdropProps={{
                style: {
                    backgroundColor: theme.palette.semantic.colorCurtain,
                    opacity: 0.5,
                },
                ...dialogProps?.BackdropProps,
            }}
            TransitionProps={{
                role: 'dialog',
                'aria-labelledby': 'dialogtitle',
            } as any}
        >
            <div
                className={clsx(classes.dialogContainer)}
                style={{ width: width ?? 'auto' }}>
                <UiErrorBoundary>
                    <div className={clsx(classes.titleContainer)}>
                        {icon ? <span className={clsx(classes.iconContainer)}>
                            {icon}
                        </span> : null}
                        <DialogTitle
                            className={icon ? classes.title : classes.noIconTitle}
                            id="dialogtitle"
                            data-cy="ui-dialog-title"
                        >
                            <Typography className={clsx(classes.titleText)}>
                                {title}
                            </Typography>
                        </DialogTitle>
                    </div>
                    {!hideClose && (
                        <IconButton
                            className={clsx(classes.closeIconContainer)}
                            onClick={close}
                            aria-label={translate({ id: 'CLIENT_CLOSE' })}
                            data-cy="close-dialog-button"
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    )}
                    <DialogContent
                        className={classes.bodyText}
                        style={{ paddingBottom: bodyActions ? '0px' : '30px' }}
                        data-cy="ui-dialog-content"
                    >
                        {loading ? (
                            <div className={clsx(classes.loading)}>
                                <CircularProgress />
                            </div>
                        ) : (
                            children
                        )}
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        {actions}
                    </DialogActions>
                </UiErrorBoundary>
            </div>
        </Dialog>
    );
};

export * from './DialogProvider';

export default UiDialog;
