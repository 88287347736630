import { AccountLicense } from '@experiences/constants';
import {
    Chip,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export const UiUpgradeChip: React.FC<{
    licenseType: AccountLicense.ENTERPRISE | AccountLicense.PRO ;
    variant?: 'outlined' | 'filled';
    size?: 'small' | 'medium';
    style?: React.CSSProperties;
    mini?: boolean;
    toolTipText?: string;
}> = ({
    licenseType, variant = 'filled', size = 'small', style, mini, toolTipText,
}) => {
    const { formatMessage: translate } = useIntl();

    return (<Tooltip title={toolTipText ?? ''}>
        <Chip
            style={style}
            variant={variant}
            size={size}
            label={translate({ id: licenseType === AccountLicense.ENTERPRISE ? 'CLIENT_ENTERPRISE_ONLY' : 'CLIENT_PRO_ONLY' })}
            className={`info${mini ? '-mini' : '' }`}
            data-cy="upgrade-license-chip" />
    </Tooltip>);
};
