import { IPagination } from '@experiences/interfaces';

import {
    IRobot,
    IUpdateRobotDto,
} from '../../common/interfaces/cis/robot';
import { IRobotResponse } from '../../component/users/RobotsPageComponent';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const robotUrl = `${process.buildConfigs.identityApiBaseRoute}/RobotAccount`;

export function getRobots(pagination: IPagination, partitionGlobalId: string) {
    return get<IRobotResponse>(`${robotUrl}/${partitionGlobalId}`, { pagination });
}

export function deleteRobots(partitionGlobalId: string, robotAccountIDs: string[]) {
    return axiosDelete(`${robotUrl}/${partitionGlobalId}`, { body: { robotAccountIDs } });
}

export function editRobot(robotAccountId: string, robotData: IUpdateRobotDto) {
    return put<IUpdateRobotDto>(`${robotUrl}/${robotAccountId}`, { body: robotData });
}

export function createRobot(robotData: IUpdateRobotDto) {
    return post<IRobot>(robotUrl, { body: robotData });
}
