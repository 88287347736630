export const TelemetryEvents = {
    /**
     * This is triggered when an admin goes to Admin → Licenses → and clicks on Trial Services.
     */
    TrialService_NavigateTab: 'TrialService.NavigateTab',
    /**
     * This is triggered when someone requests a trial for a service, but doesn’t click on confirmation pop-up → available only for Cloud.
     */
    TrialService_StartTrial: 'TrialService.StartTrial',
    /**
     * Triggered when they click on confirmation - this is when the trial license is allocated in Boon.
     */
    TrialService_Confirmed: 'TrialService.Confirmed',
};
