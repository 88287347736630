import { OperationTypeConstants } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { ICreateEditTenantPayload } from '@experiences/interfaces';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../../component/tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../../component/tenants/TenantOperationTrackerContextProvider';
import {
    createTenant,
    deleteTenant,
    editTenant,
    setServiceStatus,
    setTenantStatus,
} from '../../services/organization/TenantService';
import { notificationType } from '../constants/Constant';
import {
    IService,
    ITenant,
} from '../interfaces/tenant/tenant';

export const useTenantOperations = () => {

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const commandTableOperations = useTenantOperationTrackerContext();
    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const AsyncTenantCreationByDTFEnabled = useFeatureFlagValue(Features.AsyncTenantCreationByDTFEnabled.name);
    const AsyncEditTenantEnabled = useFeatureFlagValue(Features.AsyncEditTenantEnabled.name);
    const IsAsyncTenantDeletionEnabled = useFeatureFlagValue(Features.IsAsyncTenantDeletionEnabled.name);
    const EnableAsyncChangeTenantStatus = useFeatureFlagValue(Features.EnableAsyncChangeTenantStatus.name);

    const inProgressNotification = useCallback((label: string, tenantName: string) => {
        createNotification(translate({ id: label }, { 0: tenantName }), notificationType.INPROGRESS);
    }, []);
    const finishedNotification = useCallback((label: string, tenantName: string) => {
        createNotification(translate({ id: label }, { 0: tenantName }), notificationType.SUCCESS);
    }, []);

    const tenantCreate = useCallback(async (data: ICreateEditTenantPayload) => {
        const {
            name, operationId, id,
        } = await createTenant({ ...data });
        if (!AsyncTenantCreationByDTFEnabled) {
            finishedNotification('CLIENT_TENANT_CREATED', data.name);
            return;
        }
        commandTableOperations.addTenantOperation(operationId, name, id, OperationTypeConstants.TENANT);
        commandTableOperations.refreshAfterComplete(id);
        inProgressNotification('CLIENT_TENANT_CREATION_STARTED', data.name);
    }, []);

    const tenantEdit = useCallback(async (data: ICreateEditTenantPayload, tenant: ITenant | undefined, changedServicesObj: any) => {
        const operationId = await editTenant(tenant!.id, {
            name: data.name ?? tenant?.name,
            color: data.color,
            services: changedServicesObj,
            CustomProperties: data.CustomProperties,
        });
        const operationType = changedServicesObj ? OperationTypeConstants.SERVICE : OperationTypeConstants.TENANT;
        const tenantName = data.name ?? tenant?.name;

        if (!AsyncEditTenantEnabled) {
            finishedNotification('CLIENT_TENANT_EDITED', data.name);
            return;
        }
        commandTableOperations.addTenantOperation(operationId, tenantName, tenant!.id, operationType);
        commandTableOperations.refreshAfterComplete(tenant?.id);
        inProgressNotification('CLIENT_TENANT_EDIT_STARTED', data.name);
    }, []);

    const tenantDelete = useCallback(async (tenant: ITenant) => {
        const operationId = await deleteTenant(tenant.id);
        if (!IsAsyncTenantDeletionEnabled) {
            finishedNotification('CLIENT_TENANT_DELETED', tenant.name);
            return;
        }
        commandTableOperations.addTenantOperation(operationId, tenant.name, tenant.id, OperationTypeConstants.TENANT);
        inProgressNotification('CLIENT_TENANT_DELETION_STARTED', tenant.name);
    }, []);

    const tenantStatusModify = useCallback(async (service: IService, tenant: ITenant, accountGUID: string, status: string) => {
        if (!service) {
            const operationId = await setTenantStatus(tenant.id, status === TenantStatusConstants.ENABLE);
            commandTableOperations.addTenantOperation(operationId as unknown as string, tenant.name, tenant.id,
                OperationTypeConstants.TENANT);
        } else {
            await setServiceStatus(accountGUID, tenant.id, {
                [service.serviceType]:
        status === TenantStatusConstants.ENABLE ? TenantStatusConstants.ENABLED : TenantStatusConstants.DISABLED,
            });
        }
        if (!EnableAsyncChangeTenantStatus) {
            finishedNotification(status === TenantStatusConstants.ENABLE
                ? 'CLIENT_TENANT_ENABLED'
                : 'CLIENT_TENANT_DISABLED', tenant.name);
            return;
        }
        inProgressNotification(status === TenantStatusConstants.ENABLE
            ? 'CLIENT_TENANT_ENABLE_NOTIFICATION'
            : 'CLIENT_TENANT_DISABLE_NOTIFICATION', tenant.name);
    }, []);

    return {
        tenantCreate,
        tenantEdit,
        tenantDelete,
        tenantStatusModify,
    };
};
