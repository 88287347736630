import { UiDialog } from '@experiences/ui-common';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router';

import useAuthentication from '../../auth/hooks/Authentication';
import { accountLogicalName } from '../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        warningIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            fontSize: '32px',
        },
        orgsBodyText: { wordBreak: 'break-word' },
    }),
);

const OrganizationMismatchDialog: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();

    const [ shouldRenderDialog, setShouldRenderDialog ] = useState(true);

    const accountNameInStore = useSelector(accountLogicalName);
    const { accountName } = useParams<{ accountName: string }>();

    const { logout } = useAuthentication();

    const close = useCallback(() => {
        history.goBack();
        setShouldRenderDialog(false);
    }, [ history ]);

    return shouldRenderDialog ? (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_SWITCH_ORGS_HEADER' })}
            icon={<WarningIcon className={classes.warningIcon} />}
            width="420px"
            actions={[
                <Button
                    key={1}
                    variant="contained"
                    onClick={() => logout({ returnUrl: `${window.location.origin}/${accountName}/portal_/cloudrpa` })}
                    data-cy="organization-switcher-continue-button"
                >
                    {translate({ id: 'CLIENT_SIGN_OUT' })}
                </Button>,
                <Button
                    key={2}
                    variant="outlined"
                    onClick={close}
                    data-cy="organization-switcher-cancel-button">
                    {translate({ id: 'CLIENT_BACK' })}
                </Button>,
            ]}
        >
            <Typography
                className={classes.orgsBodyText}
                data-cy="organization-switcher-text">
                {translate(
                    { id: 'CLIENT_SWITCH_ORGS_BODY_TEXT' },
                    {
                        0: <strong>
                            {accountNameInStore}
                        </strong>,
                        1: <strong>
                            {accountName}
                        </strong>,
                    },
                )}
            </Typography>
        </UiDialog>
    ) : null;
};

export default OrganizationMismatchDialog;
