import {
    IDirectoryEntry,
    IResolveDirectoryEntry,
} from '../../common/interfaces/cis/directory';
import {
    get,
    post,
} from '../utility/Requests.default';

export const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/Directory`;

export interface IBulkResolveCommand {
    entityNames: string[];
    entityType: 'user' | 'group';
}

export interface IBulkResolveResponse {
    [key: string]: IResolveDirectoryEntry;
}

export type SourceFilters = 'localUsers' | 'directoryUsers' | 'localGroups' | 'directoryGroups' | 'robotAccounts';

export function getDirectoryEntities(
    url: string,
    partitionGlobalId: string,
    sourceFilter: SourceFilters[],
    startsWith: string,
) {
    return get<IDirectoryEntry[]>(`${userPartitionUrl}/Search/${partitionGlobalId}`, {
        urlParams: {
            sourceFilter,
            startsWith,
        },
    });
}

export function bulkResolveByName(partitionGlobalId: string, body: IBulkResolveCommand) {
    return post<IBulkResolveResponse>(`${userPartitionUrl}/BulkResolveByName/${partitionGlobalId}`, { body });
}
