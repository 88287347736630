import { AccountLicense } from '@experiences/constants';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import detectiveRobot from '../../images/detectiveRobot.png';
import flyingRobot from '../../images/flyingRobot.png';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        link: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': { textDecoration: 'underline' },
        },
        header: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '20px',
            textAlign: 'center',
        },
        description: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '16px',
        },
        spacer: { marginTop: '16px' },
    }),
);

const UpgradeForFeature: React.FC<{
    upgradeTitle: string;
    upgradeMessage: string;
    documentationLink: string;
    level: AccountLicense.PRO | AccountLicense.ENTERPRISE;
}> = ({
    upgradeTitle, upgradeMessage, documentationLink, level,
}) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();

    const { formatMessage: translate } = useIntl();

    const upgradeButton = useCallback(() => {
        level === AccountLicense.ENTERPRISE
            ? window.open('https://www.uipath.com/company/contact-us', '_blank', 'noopener,noreferrer')
            : history.push(getRoute(RouteNames.BuyEnterprise));
    }, [ getRoute, history, level ]);

    return (
        <div
            className={classes.mainContainer}
            style={{ height: '70%' }}>
            <div
                className={classes.mainContainer}
                style={{ maxWidth: '500px' }}>
                <Typography className={classes.header}>
                    {translate({ id: upgradeTitle })}
                </Typography>
                <Typography className={clsx(classes.description, classes.spacer)}>
                    {translate({ id: upgradeMessage })}
                </Typography>
                <img
                    src={level === AccountLicense.ENTERPRISE ? detectiveRobot : flyingRobot}
                    alt='UiPath robot'
                    className={classes.spacer}
                />
                <Button
                    onClick={upgradeButton}
                    className={classes.spacer}
                    variant='contained'
                    style={{ width: '166px' }}>
                    {translate({ id: level === AccountLicense.ENTERPRISE ? 'CLIENT_CONTACT_SALES' : 'CLIENT_UPGRADE' })}
                </Button>
                <Typography
                    component={Link}
                    target='_blank'
                    href={documentationLink}
                    className={clsx(classes.link, classes.spacer)}>
                    {translate({ id: 'CLIENT_VIEW_DOCUMENTATION' })}
                </Typography>
            </div>
        </div>
    );
};

export default UpgradeForFeature;
