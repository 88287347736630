import { IDirectoryEntry } from '../common/interfaces/cis/directory';

export function isDirectoryEntry(argument: string | IDirectoryEntry): argument is IDirectoryEntry {
    return (argument as IDirectoryEntry).source !== undefined;
}

export function convertToDownLevelLogonName(directoryEntry: IDirectoryEntry): string {
    const { domain } = directoryEntry;
    if (!domain) {
        return '';
    }
    return `${domain.split('.')[0]}\\${directoryEntry.displayName}`;
}
