import { useIntl } from 'react-intl';

import { IScope } from '../common/interfaces/cis/client';

export default function GetTranslatedDescription(scope: IScope): string {
    const { formatMessage: translate } = useIntl();

    if (scope.name.includes('Du')) {
        const service = scope.displayName?.split('.')[1];
        return translate({ id: 'CLIENT_PRODUCT_ACCESS_TO_DU' }, { service });
    }

    const first = scope.description ? scope.description.split(' ')[0] : '';
    const last = scope.description ? scope.description.split(' ').pop() : '';
    const access = scope.name.split('.')[2]?.toUpperCase();
    return [ first, translate({ id: `CLIENT_${access ? access : 'READ_WRITE'}_ACCESS` }), last ].join(' ');
}
