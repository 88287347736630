import { UiDialog } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';

const useStyles = makeStyles(theme =>
    createStyles({
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            height: '32px',
            width: '32px',
        },
    }),
);

export const SkipServiceConfirmationDialogComponent: React.FC<{
    location?: {
        state?: {
            type: 'add' | 'edit';
        };
    };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const getRoute = useRouteResolver();
    const type = useMemo(() => location?.state?.type, [ location ]);

    const close = useCallback(() => history.push({ pathname: getRoute(`${RouteNames.Services}/${type}`) }), [
        getRoute,
        history,
        type,
    ]);

    const callbackAndClose = useCallback(
        () => history.push({
            pathname: getRoute(`${RouteNames.Services}/${type}`),
            state: { skip: true },
        }),
        [ getRoute, history, type ],
    );

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_SKIP_SERVICE_CONFIRMATION_TITLE' })}
            icon={<InfoIcon className={clsx(classes.infoIcon)} />}
            actions={[
                <Button
                    key="cancel"
                    onClick={close}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="skip"
                    variant="contained"
                    onClick={callbackAndClose}>
                    {translate({ id: 'CLIENT_SKIP' })}
                </Button>,
            ]}
            close={close}
        >
            {translate({ id: 'CLIENT_SKIP_SERVICE_CONFIRMATION_MESSAGE' })}
        </UiDialog>
    );
};

export default SkipServiceConfirmationDialogComponent;
