import * as RouteNames from '../../common/constants/RouteNames';
import { ISettingRoute } from '../../common/interfaces/route';

export const HostSettingsRoutes: ISettingRoute[] = [
    {
        text: 'CLIENT_ORGANIZATIONS',
        route: RouteNames.Organizations,
        subRoutes: [ RouteNames.OrganizationsAdd, RouteNames.OrganizationsLicense ],
    },
    {
        text: 'CLIENT_USERS',
        route: RouteNames.Users,
        subRoutes: [ RouteNames.AuthSettings, RouteNames.UserAddEdit ],
    },
    {
        text: 'CLIENT_LICENSES',
        route: RouteNames.Licensing,
    },
    {
        text: 'CLIENT_AUDIT_LOG',
        route: RouteNames.AuditLogs,
    },
    {
        text: 'CLIENT_ACCOUNT_SETTINGS_ACTION',
        route: RouteNames.OrganizationAdminHome,
        hide: true,
    },
    {
        text: 'CLIENT_SECURITY_SETTINGS',
        route: RouteNames.AuthSettings,
        subRoutes: [
            RouteNames.SecuritySettings,
            RouteNames.AuthSettingsConfigureAD,
            RouteNames.AuthSettingsConfigureAAD,
            RouteNames.AuthSettingsConfigureGoogle,
            RouteNames.AuthSettingsConfigureSaml,
            RouteNames.SecuritySettingsConfigure,
            RouteNames.AuthSettingsPasswordPolicy,
            RouteNames.SessionPolicy,
        ],
    },
    {
        text: 'CLIENT_GLOBAL_SETTINGS',
        route: RouteNames.EmailSettings,
        subRoutes: [
            RouteNames.EmailSettingsTest,
            ...(process.buildConfigs.enableLoginCustomization ? [ RouteNames.LoginCustomization ] : []),
        ],
    },
    {
        text: 'CLIENT_ORGANIZATION_HOME',
        route: RouteNames.OrganizationHome,
        hide: true,
    },
];
