import { useCentralErrorSetter } from '@experiences/error';
import { portalTelemetry } from '@experiences/telemetry';
import { UiLoader } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { clearRefreshTokenSessionStorage } from '../../auth/utils/ClearStorage';
import RefreshTokenPopUp from '../../component/common/RefreshTokenPopUp';
import RevokeRefreshTokenConfirmation from '../../component/common/RevokeRefreshTokenConfirmation';
import { RequestUserToken } from '../../component/tenants/subcomponents/token/RequestUserToken';
import getRequestUserTokenClientId from '../../component/tenants/subcomponents/token/RequestUserTokenClientId';
import revokeRefreshToken from '../../store/action/RevokeRefreshToken';
import { AuthCode } from '../constants/Constant';
import { useIsAdminRevampEnabled } from './useIsAdminRevampEnabled';

const useApiAccessInstanceModal = () => {
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();

    const [ showApiModal, setShowApiModal ] = useState(sessionStorage.getItem(AuthCode.IS_TOKEN_RETRIEVED) === 'true');
    const [ revokeConfirmation, setRevokeConfirmation ] = useState(false);
    const [ showRevokeSpinner, setShowRevokeSpinner ] = useState(false);
    const [ fetchKeys, setFetchKeys ] = useState(false);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const openRevokeSuccessModal = useCallback(async () => {
        sessionStorage.setItem('revoked', true.toString());
        sessionStorage.setItem(AuthCode.IS_TOKEN_RETRIEVED, 'false');
        setRevokeConfirmation(false);
        setShowRevokeSpinner(false);

        await createDialog({
            title: translate({ id: 'CLIENT_REFRESH_TOKENS_REVOKED' }),
            body: translate({ id: 'CLIENT_REVOKE_REFRESH_TOKEN_SUCCESSFULL' }),
            icon: 'success',
        });

        clearRefreshTokenSessionStorage();
        sessionStorage.removeItem('revoked');
    }, [ setRevokeConfirmation, createDialog, translate ]);

    const openRevokeErrorModal = useCallback(async () => {
        sessionStorage.setItem('revoked', false.toString());
        setRevokeConfirmation(false);
        setShowRevokeSpinner(false);

        await createDialog({
            title: translate({ id: 'CLIENT_ERROR' }),
            body: translate({ id: 'CLIENT_REFRESH_TOKEN_ERROR' }),
            icon: 'error',
        });
    }, [ setRevokeConfirmation, createDialog, translate ]);

    const openFetchKeys = useCallback(() => {
        return <RequestUserToken />;
    }, []);

    const openRevokeConfirmationModal = useCallback(
        () => (
            <RevokeRefreshTokenConfirmation
                close={() => setRevokeConfirmation(false)}
                revokeTokenHandler={async () => {
                    setShowRevokeSpinner(true);
                    await revokeRefreshToken(openRevokeSuccessModal, openRevokeErrorModal);
                }}
            />
        ),
        [ setRevokeConfirmation, openRevokeSuccessModal, openRevokeErrorModal ],
    );

    const openApiAccessInstanceModal = useCallback(() => {
        if (sessionStorage.getItem(AuthCode.IS_TOKEN_RETRIEVED) === 'true') {
            portalTelemetry.trackTrace({
                message: 'Succesfully retrieved refresh token',
                severityLevel: SeverityLevel.Information,
            }, { revampEnabled: isAdminRevampEnabled });

            const closeApiAccess = () => {
                clearRefreshTokenSessionStorage();
                setErrorMessage(null);
                setShowApiModal(!showApiModal);
            };

            return (
                <RefreshTokenPopUp
                    tenantName={sessionStorage.getItem('tenantName')}
                    close={closeApiAccess}
                    refreshToken={sessionStorage.getItem('retrievedRefreshToken')}
                    clientId={getRequestUserTokenClientId()}
                    handleRevoke={() => setRevokeConfirmation(true)}
                />
            );
        } else if (sessionStorage.getItem('Error') === 'Error') {
            (async () => {
                await createDialog({
                    title: translate({ id: 'CLIENT_ERROR' }),
                    body: translate({ id: 'CLIENT_RETRIEVE_REFRESH_TOKEN_ERROR' }),
                    icon: 'error',
                });
                clearRefreshTokenSessionStorage();
                setRevokeConfirmation(false);
            })();
        }
        return null;
    }, [ createDialog, setErrorMessage, showApiModal, translate ]);

    const ApiAccessInstanceModalComponent = useCallback(
        () => (
            <>
                {fetchKeys && openFetchKeys()}
                {showApiModal && openApiAccessInstanceModal()}
                {revokeConfirmation && openRevokeConfirmationModal()}
                {showRevokeSpinner && <UiLoader type="backdrop" />}
            </>
        ),
        [
            fetchKeys,
            openApiAccessInstanceModal,
            openFetchKeys,
            openRevokeConfirmationModal,
            revokeConfirmation,
            showApiModal,
            showRevokeSpinner,
        ],
    );

    const setCurrentTenant = useCallback((tenantName: string) => {
        sessionStorage.setItem(AuthCode.TENANT_NAME, tenantName);
        setFetchKeys(true);
    }, []);

    useEffect(() => {
        const storageEventListener = (event: StorageEvent) => {
            if (event.storageArea === sessionStorage && event.key === AuthCode.IS_TOKEN_RETRIEVED) {
                setFetchKeys(false);
                setShowApiModal(true);
            }
        };
        window.addEventListener('storage', storageEventListener);
        return () => {
            window.removeEventListener('storage', storageEventListener);
        };
    }, [ setFetchKeys, setShowApiModal ]);

    return {
        ApiAccessInstanceModalComponent,
        setCurrentTenant,
    };
};

export default useApiAccessInstanceModal;
