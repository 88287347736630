import { IDirectoryConnectionDto } from '../common/interfaces/directoryConnection';
import {
    defaultADConfigData,
    IADConfigData,
} from '../component/authsettings/subcomponents/ConfigureADComponent';
import { IDirectoryConnectionPayload } from '../services/identity/DirectoryConnectionService';

export const mapADDataToDirectoryConnectionDto = (data: Partial<IADConfigData>): IDirectoryConnectionDto => {
    return {
        Domain: data.domain ?? defaultADConfigData.domain,
        Username: data.username?.trim() ?? defaultADConfigData.username,
        Password: data.password ?? defaultADConfigData.password,
        Ldaps: data.ldaps ?? defaultADConfigData.ldaps,
        UseKerberosAuth: data.useKerberosAuth ?? defaultADConfigData.useKerberosAuth,
    };
};

export const mapDirectoryConnectionToADConfigData = (payload: IDirectoryConnectionPayload): Partial<IADConfigData> => {
    const dto: IDirectoryConnectionDto = JSON.parse(payload.configuration);
    return {
        domain: dto.Domain,
        username: dto.Username,
        ldaps: dto.Ldaps,
        password: '', // Do not include password since it is taken care of by a placeholder.
        useKerberosAuth: dto.UseKerberosAuth,
    };
};
