import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { ITenant } from '../../common/interfaces/tenant/tenant';
import {
    getTenants,
    getTenantsV2,
    organizationManagementTenantUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../store/selectors';
import UsageChartsComponent from '../usage/UsageChartsComponent';
import HomePageHeader from './helper/HomePageHeader';

export const HomePageLicensesComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationGuid = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);

    const { data: tenants } = useSWR<ITenant[], Error>(
        [ organizationManagementTenantUri, organizationGuid, accountName ],
        !EnableGetTenantsRedesign ? getTenants : getTenantsV2,
    );

    const tenantIds = useMemo(() => (tenants ? tenants.map(tenant => tenant.id) : undefined), [ tenants ]);

    return (
        <div data-cy="home-page-license-widget">
            <HomePageHeader
                label={translate({ id: 'CLIENT_LICENSE_ALLOCATION' })}
                fontSize="20px" />
            <UsageChartsComponent
                tenantIds={tenantIds}
                isServiceMode={false} />
        </div>
    );
};
