import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useUserReadableTime } from '../../../util/DateUtil';

const AppsCard: React.FC<{ title: string; owner: string; id: number; latestPublishedDate: string; description: string; systemName: string; logicalName: string }> = ({
    title,
    owner,
    id,
    latestPublishedDate,
    description,
    systemName,
    logicalName,
}) => {
    const { formatMessage: translate } = useIntl();
    const cardref = React.createRef<HTMLPortalCardElement>();
    const { userReadableTime } = useUserReadableTime();
    const FIXED_WIDTH = '311px';
    const FIXED_HEIGHT = '112px';
    useEffect(() => {
        if (cardref.current) {
            cardref.current.addEventListener('cardClicked', () => {
                portalTelemetry.trackTrace({
                    message: '[IN-HP-01] - Homepage processes card clicked',
                    severityLevel: SeverityLevel.Information,
                });
                window.location.assign(`${window.location.origin}/${logicalName}/apps_/default/run/production/${systemName}?origin=portalHome`);
            });
        }
    });
    return (
        <portal-card
            data-cy={`apps-portal-card-${id}`}
            header={title}
            ref={cardref}
            id={id.toString()}
            icon='apps'
            description={description ? description : `${translate({ id: 'CLIENT_APPS_OWNER' })}: ${owner}`}
            subtitle={`${translate({ id: 'CLIENT_APPS_LAST_PUBLISHED' })}: ${userReadableTime(moment(latestPublishedDate))}`}
            clickable={true}
            fixed-heights={FIXED_HEIGHT}
            title-overflow={1}
            description-overflow={2}
            width={FIXED_WIDTH}
        />
    );
};

export default AppsCard;
