import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useUserReadableTime } from '../../../util/DateUtil';
import { IProcessesApp } from './interfaces/processes';

const ProcessesCard: React.FC<{
    processInfo: IProcessesApp;
    processURL: string;
    idx: number;
}> = (info) => {
    const cardref = React.createRef<HTMLPortalCardElement>();
    const FIXED_WIDTH = '311px';
    const FIXED_HEIGHT = '112px';
    useEffect(() => {
        if (cardref.current) {
            cardref.current.addEventListener('cardClicked', () => {
                portalTelemetry.trackTrace({
                    message: '[IN-HP-01] - Homepage processes card clicked',
                    severityLevel: SeverityLevel.Information,
                });
                window.location.assign(processURL);
            });
        }
    });

    const processInfo = info.processInfo;
    const processURL = info.processURL;
    const { formatMessage: translate } = useIntl();
    const { userReadableTime } = useUserReadableTime();
    const versionString = processInfo.Entity.CurrentVersion
        ? `\n${translate({ id: 'CLIENT_VERSION' })}: ${
            processInfo.Entity.CurrentVersion.VersionNumber
        }`
        : '';
    const altDescriptionString =
        `${translate({ id: 'CLIENT_APPS_FOLDER_NAME' })}: ${
            processInfo.Entity.OrganizationUnitFullyQualifiedName
        }` + versionString;
    const subtitleText = `${translate({ id: 'CLIENT_APPS_LAST_PUBLISHED' })}: ${userReadableTime(moment(processInfo.Entity.CreationTime))}`;
    return (
        <portal-card
            data-cy={`processes-portal-card-${info.idx}`}
            ref={cardref}
            id={info.idx.toString()}
            width={FIXED_WIDTH}
            fixed-heights={FIXED_HEIGHT}
            header={processInfo.Entity.Name}
            subtitle={subtitleText}
            icon="processes"
            description={
                processInfo.Entity.Description &&
                processInfo.Entity.Description !== ''
                    ? processInfo.Entity.Description
                    : altDescriptionString
            }
            clickable={true}
            title-overflow={1}
            description-overflow={2}
        />
    );
};

export default ProcessesCard;
