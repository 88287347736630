import { createReducer } from 'redux-act';

import immerReducer from '../../common/immerReducer';
import { IAnnouncement } from '../../common/interfaces/announcement';
import { markAllAnnouncementsAsRead } from '../action/AnnouncementAction';

const announcements: IAnnouncement[] = [];

const initialState = { announcementList: announcements };

export type AnnouncementsState = typeof initialState;

const reducer = createReducer({}, initialState);
reducer.on(
    markAllAnnouncementsAsRead,
    immerReducer(state => {
        state.announcementList.forEach(announcement => {
            announcement.read = true;
        });
        return state;
    }),
);

export default reducer;
