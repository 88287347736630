import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useEcommerce } from '../helpers/useEcommerce';
import { ISubscriptionForm } from '../interfaces/ecommerce';

const EcommerceZipCode: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const { formatMessage: translate } = useIntl();
    const {
        watch, control, errors,
    } =
    useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        zipcode: currentZipCode,
    } = watch();

    const { setEcommerceZipCodeValue } = useEcommerce();

    const [ debouncedZipCode ] = useDebounce(currentZipCode, 1000);
    useEffect(() => {
        if (debouncedZipCode) {
            setEcommerceZipCodeValue(debouncedZipCode);
        }
    }, [ setEcommerceZipCodeValue, errors.zipcode, debouncedZipCode ]);

    return (
        <Controller
            as={TextField}
            control={control}
            variant="outlined"
            rules={{
                required: true,
                minLength: 5,
                maxLength: 8,
            }}
            id="zipcode"
            label={translate({ id: 'CLIENT_ZIP_CODE' })}
            name="zipcode"
            error={!!errors.zipcode}
            helperText={
                (errors.zipcode?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
          ((errors.zipcode?.type === 'taxError' ||
              errors.zipcode?.type === 'maxLength' ||
              errors.zipcode?.type === 'minLength') &&
            translate({ id: 'CLIENT_BAD_ZIP_CODE' })) ||
          (errors.zipcode?.type === 'addressError' &&
            translate({ id: 'CLIENT_MAKE_SURE_THE_ZIP_CODE_MATCHES_BILLING_ADDRESS' })) ||
          (errors.zipcode?.type === 'avalaraError' &&
            translate({ id: 'CLIENT_UNABLE_TO_CALCULATE_TAX_AVALARA_IS_DOWN' }))
            }
            InputProps={{ className: 'Tall' }}
            data-cy="ecommerce-form-zipcode"
            disabled={disabled}
            fullWidth={currentCountry === 'US' || currentCountry === 'CA' || currentCountry === 'JP'}
        />
    );
};

export default EcommerceZipCode;
