import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    Chip,
    CircularProgress,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    Filters,
    HeaderGroup,
    Row,
    TableState,
} from 'react-table';

import {
    GridActionType,
    IAction,
    IActionHeader,
    UiColumn,
} from './grid';
import UiGridHeaderActionButtonsComponent from './UiGridHeaderActionButtonsComponent';
import { UiGridSearchComponent } from './UiGridSearchComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        tableActionBar: {
            height: '72px',
            display: 'flex',
            backgroundColor: theme.palette.semantic.colorBackground,
            padding: '16px',
            paddingLeft: '0px',
            paddingRight: '0px',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        filterActions: { display: 'flex' },
        refreshActions: { marginLeft: 'auto' },
        tableActionButton: {
            color: theme.palette.semantic.colorPrimary,
            fontSize: '14px',
        },
        selectedTableActions: {
            display: 'flex',
            marginLeft: 'auto',
        },
        expandDirection: {
            color: theme.palette.semantic.colorPrimary,
            verticalAlign: 'bottom',
        },
        filterBar: {
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            width: '100%',
        },
        filterBadgeBar: {
            overflowX: 'auto',
            borderTop: `1px solid ${theme.palette.semantic.colorBackgroundDisabled}`,
        },
        filterResults: { flex: '0 0 auto' },
        filterBadge: {
            display: 'flex',
            flex: '0 0 auto',
            paddingLeft: '16px',
            alignItems: 'center',
        },
        filterBadgeLabel: {
            color: theme.palette.semantic.colorForegroundDisable,
            marginRight: '8px',
        },
        filterChip: {
            marginRight: '2px',
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
        },
    }),
);

interface IPaginationHeaderProps {
    query?: { current?: string; set: (query: string) => void };
    onSearch?: (query?: string) => void;
}

interface IUiGridHeaderProps<T extends object> {
    search?: boolean;
    searchPlaceholder?: string;
    SearchComponent?: JSX.Element;
    filters?: boolean;
    filterQueries?: Filters<T>;
    tableActions?: Array<IAction<T>>;
    refresh?: () => any;
    extraActionButtons?: IActionHeader[];
    loading?: boolean;
    headerGroups: Array<HeaderGroup<T>>;
    rows: Array<Row<T>>;
    selectedFlatRows: Array<Row<T>>;
    setGlobalFilter: (filterValue: any) => void;
    paginationProps?: IPaginationHeaderProps;
    state: TableState<T>;
}

export function UiGridHeaderComponent<T extends object>({
    search,
    searchPlaceholder,
    SearchComponent,
    filters,
    filterQueries,
    tableActions,
    extraActionButtons,
    refresh,
    headerGroups,
    rows,
    selectedFlatRows,
    setGlobalFilter,
    paginationProps,
    state,
    loading,
}: IUiGridHeaderProps<T>) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ showFilters, setShowFilters ] = useState(false);

    const columns = useMemo(
        () => headerGroups.map(headerGroup => headerGroup.headers.map(column => column)).flat(1) as Array<UiColumn<T>>,
        // eslint-disable-next-line
    [headerGroups],
    );

    return (
        <>
            {!!search ||
      !!refresh ||
      tableActions?.some(
          action => action.actionType === GridActionType.Selected || action.actionType === GridActionType.Main,
      ) ? (
                    <div className={clsx(classes.tableActionBar)}>
                        {selectedFlatRows?.length ? (
                            <span
                                tabIndex={0}
                                data-cy="ui-grid-selected-indicator">
                                {translate({ id: 'CLIENT_SELECTED_ROWS' }, { 0: selectedFlatRows.length })}
                            </span>
                        ) : (
                            <div className={clsx(classes.filterActions)}>
                                {search ? (
                                    <UiGridSearchComponent
                                        query={paginationProps?.query?.current ?? state.globalFilter}
                                        setGlobalFilter={paginationProps?.query?.set ?? setGlobalFilter}
                                        onSearch={paginationProps?.onSearch}
                                        placeholder={searchPlaceholder ?? undefined}
                                        SearchComponent={SearchComponent}
                                    />
                                ) : null}
                                {filters ? (
                                    columns.filter(column => !!column.Filter).length <= 3 ? (
                                        columns
                                            .filter(column => !!column.Filter)
                                            .map(column => (
                                                <div
                                                    key={column.id}
                                                    data-cy="ui-grid-filter-action">
                                                    {column.Filter && typeof column.Filter === 'function'
                                                        ? (column.Filter as any)({ column })
                                                        : null}
                                                </div>
                                            ))
                                    ) : (
                                        <Button
                                            startIcon={<FilterListIcon />}
                                            onClick={() => setShowFilters(!showFilters)}
                                            data-cy="ui-grid-show-filters-button"
                                        >
                                            {translate({ id: 'CLIENT_FILTER' })}
                                            <span className={clsx(classes.expandDirection)}>
                                                {showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </span>
                                        </Button>
                                    )
                                ) : null}
                            </div>
                        )}
                        {(refresh || (tableActions && tableActions?.length > 0)) && (
                            <div className={clsx(classes.selectedTableActions)}>
                                {tableActions
                                    ?.filter(action => action.actionType === GridActionType.Main)
                                    .map((action, sa) => (
                                        <div key={sa}>
                                            <Button
                                                className={clsx(classes.tableActionButton)}
                                                variant="text"
                                                startIcon={action.icon}
                                                onClick={() => (action.click ? action.click(selectedFlatRows) : undefined)}
                                                data-cy="ui-grid-selection-action-buttons"
                                                disabled={typeof action.disable === 'function' ? (action.disable as any)() : action.disable}
                                            >
                                                {action.label}
                                            </Button>
                                        </div>
                                    ))}
                                {selectedFlatRows.length > 0 &&
                tableActions
                    ?.filter(action => action.actionType === GridActionType.Selected)
                    .map((action, sa) => (
                        <div key={sa}>
                            <Button
                                className={clsx(classes.tableActionButton)}
                                variant="text"
                                startIcon={action.icon}
                                onClick={() => (action.click ? action.click(selectedFlatRows) : undefined)}
                                data-cy="ui-grid-selection-action-buttons"
                            >
                                {action.label}
                            </Button>
                        </div>
                    ))}
                                {selectedFlatRows.length === 0 && refresh && (
                                    <Button
                                        className={clsx(classes.tableActionButton)}
                                        variant="text"
                                        startIcon={loading ? <CircularProgress
                                            size={16}
                                            thickness={4} /> : <RefreshIcon />}
                                        onClick={refresh}
                                        data-cy="ui-grid-refresh-button"
                                    >
                                        {translate({ id: 'CLIENT_REFRESH' })}
                                    </Button>
                                )}
                            </div>
                        )}
                        <UiGridHeaderActionButtonsComponent extraActionButtons={extraActionButtons} />
                    </div>
                ) : null}
            {showFilters ? (
                <div
                    className={clsx(classes.filterBar)}
                    data-cy="ui-grid-filter-bar">
                    {columns
                        .filter(column => !!column.Filter)
                        .map(column => (
                            <div
                                key={column.id}
                                data-cy="ui-grid-filter-action">
                                {column.Filter && typeof column.Filter === 'function' ? (column.Filter as any)({ column }) : null}
                            </div>
                        ))}
                </div>
            ) : null}
            {filterQueries?.length &&
      filterQueries.some(query => (Array.isArray(query.value) ? query.value.length : !!query.value)) ? (
                    <div
                        className={clsx(classes.filterBar, classes.filterBadgeBar)}
                        data-cy="ui-grid-filter-badges-bar">
                        <span className={clsx(classes.filterResults)}>
                            {translate({ id: 'CLIENT_NUMBER_RESULTS' }, { count: rows.length })}
                        </span>
                        {filterQueries
                            .filter(query => (Array.isArray(query.value) ? query.value.length : !!query.value))
                            .map(filter => {
                                const filteredColumn = columns.find(column => column.id === filter.id);
                                return (
                                    <div
                                        key={filter.id}
                                        className={clsx(classes.filterBadge)}>
                                        <span
                                            className={clsx(classes.filterBadgeLabel)}
                                            data-cy="ui-grid-filter-badge-label">
                                            {filteredColumn?.Header}
:
                                        </span>
                                        {Array.isArray(filter.value) ? (
                                            filter.value.map(value => (
                                                <Chip
                                                    key={`ui-grid-chip-${filter.id}-${value}`}
                                                    className={clsx(classes.filterChip)}
                                                    label={filteredColumn?.filterMap?.get(value) ?? value}
                                                    onDelete={() => {
                                                        const removedFilters = filter.value.filter((query: any) => query !== value);
                                                        if (filteredColumn) {
                                                            filteredColumn!.setFilter!(removedFilters);
                                                        }
                                                    }}
                                                    data-cy="ui-grid-filter-badge-value"
                                                />
                                            ))
                                        ) : (
                                            <Chip
                                                label={filteredColumn?.filterMap?.get(filter.value) ?? filter.value}
                                                onDelete={() => {
                                                    if (filteredColumn) {
                                                        filteredColumn!.setFilter!(undefined);
                                                    }
                                                }}
                                                className={classes.filterChip}
                                                data-cy="ui-grid-filter-badge-value"
                                            />
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                ) : null}
        </>
    );
}
