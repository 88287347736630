import {
    AccountLicense,
    Region,
    TranslatedRegionName,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import { UiSelect } from '@experiences/ui-common';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { TenantRegionDocsLinks } from '../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { ITenant } from '../../common/interfaces/tenant/tenant';
import {
    getAvailableServices,
    tenantAvailableServicesUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../store/selectors';
import UiColorPicker from '../common/UiColorPicker';
import { IServiceMetadata } from '../tenants/interfaces/service';
import { getSupportedRegionForTenant } from '../tenants/subcomponents/helpers/TenantRegionHelper';
import { ServiceErrorType } from '../tenants/subcomponents/helpers/TenantServiceErrorMessage';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        colorPicker: { marginLeft: '8px' },
        tenant: {
            display: 'flex',
            margin: '16px 0 16px 0',
            flexDirection: 'row',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        tenantSettingsForm: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        smallText: { fontSize: '12px' },
        mediumText: { fontSize: '14px' },
        canaryRegionHelperText: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '8px',
        },
        redAsterisk: {
            color: theme.palette.semantic.colorErrorText,
            fontSize: '12px',
        },
    }),
}));

const regex = /^[0-9a-zA-Z]+$/;

const TenantGeneralForm: React.FC<{
    type?: 'add' | 'edit';
    tenant?: ITenant;
    disableRegion?: boolean;
    disableForm?: boolean;
    width?: string; }> = ({
    type, tenant, disableRegion, disableForm, width,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'pt-BR', 'tr', 'zh-CN', 'zh-TW' ] });
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const canaryTenantFlag = useFeatureFlagValue(Features.EnableCanaryTenant.name);
    const EnableCanaryTenant = useMemo (() => isAdminRevampEnabled && canaryTenantFlag, [ canaryTenantFlag, isAdminRevampEnabled ]);

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { tenantsWithoutServices } = useTenantsContext();

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const [ validationErrors ] = validationErrorHandlers;

    const isCanaryTenant = useMemo(() => tenant?.isCanaryTenant, [ tenant?.isCanaryTenant ]);

    const canaryTenantExists = useMemo(() =>
        tenantsWithoutServices.filter(tenantIterator => tenantIterator.isCanaryTenant).length > 0, [ tenantsWithoutServices ]);

    const {
        register, control, formState: { errors }, watch, setValue,
    } = useFormContext();

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ tenantAvailableServicesUri, partitionGlobalId, accountName ],
        getAvailableServices,
    );

    const isCanaryChecked: boolean = useMemo(() => watch('customProperties.isCanaryTenant'), [ watch ]);

    const DefaultRegions = useMemo(
        () => getSupportedRegionForTenant(availableServices),
        [ availableServices ],
    );

    const translatedRegion = useMemo(() =>
        Object.keys(TranslatedRegionName)
            .filter((key) => DefaultRegions?.includes(key as Region))
            .reduce((obj, key) => {
                return Object.assign(obj, { [key]: TranslatedRegionName[key as Region] });
            }, {})
    , [ DefaultRegions ]);

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ],
    );

    const validateTenantName = useCallback((value: string) => {
        if (!(type === 'edit' && value && tenant?.name.toLowerCase() === value.toLowerCase())
        && (tenantsWithoutServices?.map(tenant => tenant.name.toLowerCase()).includes(value.toLowerCase()) ?? false)) {
            return translate({ id: 'CLIENT_TENANT_DUPLICATE_NAME' });
        }
        if (!value.match(regex)) {
            return translate({ id: 'CLIENT_TENANT_NAME_ALPHANUMERIC' });
        }
        return true;
    }, [ tenant?.name, tenantsWithoutServices, translate, type ]);

    useEffect(() => {
        if (isCanaryChecked) {
            setValue('region', 'Europe' as Region);
        }
    }, [ isCanaryChecked, setValue ]);

    return (
        DefaultRegions?.length ?
            <div
                className={classes.tenantSettingsForm}
                data-cy="tenant-settings-component"
                style={{
                    width: width,
                    padding: type === 'add' && isAdminRevampEnabled ? '0 16px' : '',
                }}>
                <div className={classes.tenant}>
                    <TextField
                        name="name"
                        disabled={disableForm}
                        inputRef={register({
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }) },
                            ),
                            maxLength: {
                                value: 32,
                                message: translate({ id: 'CLIENT_WARN_NAME' }, {
                                    0: 32,
                                    1: 2,
                                }),
                            },
                            minLength: {
                                value: 2,
                                message: translate({ id: 'CLIENT_WARN_NAME' }, {
                                    0: 32,
                                    1: 2,
                                }),
                            },
                            validate: (value: string) => validateTenantName(value),
                        })}
                        label={translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' })}
                        InputLabelProps={{ id: 'tenantNameLabel' }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        fullWidth
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-labelledby': 'tenantNameLabel' }}
                        data-cy="create-edit-tenant-name"
                        required
                    />
                    <div className={classes.colorPicker}>
                        <Controller
                            name="color"
                            control={control}
                            render={({
                                value, onChange,
                            }) => <UiColorPicker
                                value={value}
                                onChange={onChange}
                                disabled={disableForm}
                            />}
                        />
                    </div>
                </div>
                {AccountLicense[accountPlan] === AccountLicense.ENTERPRISE && EnableCanaryTenant
                && (type === 'add' || isCanaryTenant) && <div style={{ marginBottom: '24px' }}>
                    <FormControlLabel
                        control={<Checkbox
                            disabled={isCanaryTenant ?? canaryTenantExists}
                            defaultChecked={isCanaryChecked || isCanaryTenant}
                            data-cy="canary-tenant-checkbox"
                        />}
                        label={
                            <Typography className={classes.mediumText}>
                                {translate({ id: 'CLIENT_USE_AS_CANARY' })}
                            </Typography>
                        }
                        style={{ marginTop: '8px' }}
                        id="termsAndConditionsAccepted"
                        name="customProperties.isCanaryTenant"
                        inputRef={register()}
                        data-cy="canary-tenant-checkbox"
                    />
                    <Typography className={classes.smallText}>
                        {translate({
                            id: canaryTenantExists && type === 'add'
                                ? 'CLIENT_CANARY_TENANT_ONLY_ONE'
                                : 'CLIENT_CANARY_DESCRIPTION',
                        })}
                    </Typography>
                    <a
                        className={clsx(classes.a, classes.smallText)}
                        target="_blank"
                        rel="noreferrer"
                        href="#0"
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                    </a>
                </div> }
                {isRegionEnabled && DefaultRegions?.length && !DisableFeatureFedRamp &&
                    <>
                        <UiSelect
                            dataCy="create-edit-tenant-region"
                            name="region"
                            inputLabel={translate({ id: 'CLIENT_TENANT_REGION' })}
                            error={!!errors.region}
                            options={translatedRegion}
                            disabled={disableRegion ?? disableForm ?? isCanaryChecked}
                            required
                            control={control}
                            helperText={
                                (errors.region?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                            ((validationErrors.unsupported.length > 0 || validationErrors.altRegion.length > 0) &&
                                translate(
                                    { id: 'CLIENT_TENANT_REGION_HELPER_TEXT' },
                                    {
                                        LEARNMORE: (
                                            <a
                                                className={classes.a}
                                                href={getLocalizedLink(TenantRegionDocsLinks)}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                {translate({ id: 'CLIENT_LEARN_MORE' })}
                                            </a>
                                        ),
                                    })
                            )
                            }
                        />
                        {isCanaryChecked &&
                        <div className={classes.canaryRegionHelperText}>
                            <Typography className={classes.redAsterisk}>
                                *
                            </Typography>
                            <Typography className={classes.smallText}>
                                {translate({ id: 'CLIENT_CANARY_REGION_RESTRICTION' })}
                            </Typography>
                        </div>}
                    </> }
            </div> : <CircularProgress />
    );
};

export default TenantGeneralForm;
