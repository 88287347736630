import {
    PORTAL_PREFIX,
    REQUEST_BASE_ROUTE,
} from '@experiences/constants';

const PortalPrefix = PORTAL_PREFIX;

const Licensing = `${PortalPrefix}/licensing`;
export const DirectBuy = `${REQUEST_BASE_ROUTE}/directbuy`;
export const DirectBuyConfirmation = `${DirectBuy}/orderConfirmation`;
export const DirectBuyCheckout = `${DirectBuy}/checkout`;
export const BuyEnterprise = `${PortalPrefix}/buyenterprise`;
export const BuyEnterpriseConfirmation = `${BuyEnterprise}/orderConfirmation`;
export const BuyEnterpriseSuccess = `${Licensing}/success`;
