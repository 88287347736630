import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteConfig } from 'react-router-config';

export class UiErrorBoundary extends React.Component<
{ route?: RouteConfig; errorComponent?: React.ReactNode },
{ hasError: boolean }
> {
    constructor(props: { route?: RouteConfig; errorComponent?: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    UNSAFE_componentWillReceiveProps(nextProps: { route?: RouteConfig; errorComponent?: React.ReactNode }) {
        if (!_.isEqual(this.props.route, nextProps.route)) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.errorComponent ?? <FormattedMessage id="CLIENT_GENERIC_ERROR_MESSAGE" />;
        }

        return this.props.children;
    }
}
