import { Fade } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

import { notificationType } from '../constants/Constant';
import { useIsAdminRevampEnabled } from './useIsAdminRevampEnabled';

export const useUiSnackBar = () => {

    const { enqueueSnackbar } = useSnackbar();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const createNotification = useCallback(
        (message: string, type = notificationType.INPROGRESS) => {
            enqueueSnackbar(message, {
                variant: type as any,
                anchorOrigin: {
                    horizontal: isAdminRevampEnabled ? 'center' : 'left',
                    vertical: 'top',
                },
                TransitionComponent: Fade as React.ComponentType,
            });
        },
        [ enqueueSnackbar, isAdminRevampEnabled ],
    );

    return createNotification;
};
