import moment, { MomentInput } from 'moment';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function getTimeInSeconds(time: MomentInput) {
    const date = moment.utc(time).toDate();
    return (Date.now() - date.getTime()) / 1000;
}

export const useUserReadableTime = () => {
    const { formatMessage: translate } = useIntl();

    const userReadableTime = useCallback(
        (time: MomentInput) => {
            const seconds = getTimeInSeconds(time);

            if (seconds < 60) {
                return translate({ id: 'CLIENT_JUST_NOW' });
            } else if (seconds > 60 && seconds < 120) {
                return translate({ id: 'CLIENT_MINUTE_AGO' });
            } else if (seconds < 60 * 60) {
                return translate({ id: 'CLIENT_MINUTES_AGO' }, { num: Math.round(seconds / 60) });
            } else if (seconds > 60 * 60 && seconds < 2 * 60 * 60) {
                return translate({ id: 'CLIENT_HOUR_AGO' });
            } else if (seconds >= 2 * 60 * 60 && seconds < 24 * 60 * 60) {
                return translate({ id: 'CLIENT_HOURS_AGO' }, { num: Math.round(seconds / (60 * 60)) });
            } else if (seconds >= 24 * 60 * 60 && seconds < 2 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_DAY_AGO' });
            } else if (seconds >= 2 * 24 * 60 * 60) {
                return translate({ id: 'CLIENT_DAYS_AGO' }, { num: Math.round(seconds / (24 * 60 * 60)) });
            }
            return '';
        },
        [ translate ],
    );

    return { userReadableTime };
};

export function timestampToDateTime(timestamp: MomentInput, language?: string) {
    const date = moment.utc(timestamp).toDate();
    const dateString = date.toLocaleDateString(
        language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language,
        {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        },
    );
    const timeString = date
        .toLocaleTimeString(language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language, {
            timeZone: 'UTC',
            timeZoneName: 'short',
            hour12: false,
        })
        .replace('GMT', 'UTC');
    return `${dateString} ${timeString}`;
}

export function timestampToDate(timestamp: MomentInput, language?: string) {
    const date = moment.utc(timestamp).toDate();
    const dateString = date.toLocaleDateString(
        language === 'en' || language?.toLowerCase() === 'keys' ? 'en-US' : language,
        {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        },
    );

    return `${dateString}`;
}

export function getAgoDateFromNow(days: number) {
    const agoDate = new Date();
    agoDate.setDate(agoDate.getDate() - days);
    return agoDate;
}

export function getToDateFromNow(days: number) {
    const toDate = new Date(Date.now() + days * 86400000);
    return toDate;
}

export function isBeforeGivenDate(dateToTest: MomentInput, givenDate: MomentInput) {
    return moment(dateToTest).isBefore(givenDate);
}

export function secondsToDays(seconds: number) {
    return String(Math.floor(seconds / 86400));
}

export function daysToSeconds(days: number) {
    return String(days * 86400);
}
