class StripeElementState {
    name: string;
    complete: boolean;
    error: string | undefined;

    constructor(name: string) {
        this.name = name;
        this.complete = false;
        this.error = undefined;
    }

    setError(error: string) {
        this.error = error;
    }

    setComplete(complete: boolean) {
        this.complete = complete;
    }
}

export class StripeFormState {
    stripeCard: StripeElementState;
    stripeExpiration: StripeElementState;
    stripeCvc: StripeElementState;

    constructor() {
        this.stripeCard = new StripeElementState('stripeCard');
        this.stripeExpiration = new StripeElementState('stripeExpiration');
        this.stripeCvc = new StripeElementState('stripeCvc');
    }

    hasError() {
        return !!(this.stripeCard.error || this.stripeCvc.error || this.stripeExpiration.error);
    }

    allCompleted() {
        return this.stripeCard.complete && this.stripeCvc.complete && this.stripeExpiration.complete;
    }
}
