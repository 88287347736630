import { IAccountSettingsDto } from '../../component/organizationsettings/subcomponents/GeneralSettingsComponent';
import { IServiceMetadata } from '../../component/tenants/interfaces/service';
import {
    get,
    patch,
    post,
} from '../utility/Requests.default';

export const organizationManagementUri = '/api/organization';
export function updateOrganization(payload: IAccountSettingsDto) {
    if (process.buildConfigs.orgNameIsOrgLogicalName) {
        payload.logicalName = payload.companyName;
    }
    return patch(organizationManagementUri, { body: { ...payload } });
}

export function deleteOrganization(deletionQuestion: string) {
    return post<{ status: number; data: any }>(`${organizationManagementUri}/deleteOrganization`, { body: { deletionQuestion } });
}

export function getSupportedRegions(organizationGuid: string, accountName: string) {
    return get<IServiceMetadata[]>(`${organizationManagementUri}/availableservices`, {
        urlParams: {
            organizationGuid,
            accountName,
        },
    });
}

