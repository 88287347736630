import { stateInfo } from '@experiences/constants';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEcommerceEnabledCountries } from '../helpers/EcommerceHelpers';
import { ISubscriptionForm } from '../interfaces/ecommerce';
import EcommerceCity from './EcommerceCity';
import EcommerceState from './EcommerceState';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
    }),
);

const EcommerceStateAndCity: React.FC<{
    disabled: boolean;
    showCityFirst: boolean;
}> = ({
    disabled, showCityFirst,
}) => {
    const classes = useStyles();
    const { englishCountryCodeCountries: englishCodes } = useEcommerceEnabledCountries();
    const { watch } =
    useFormContext<ISubscriptionForm>();

    const { country: currentCountry } = watch();

    const currentCountryInEnglish = useMemo(
        () => (englishCodes as any)[currentCountry],
        [ currentCountry, englishCodes ],
    );
    const states = useMemo(
        () => (stateInfo as Record<string, string[]>)[currentCountryInEnglish]
        ,
        [ currentCountryInEnglish ],
    );

    return (
        <div className={classes.row}>
            {showCityFirst ? (
                <>
                    <EcommerceCity
                        disabled={disabled} />
                    {states && (
                        <EcommerceState
                            leftMargin={true}
                            disabled={disabled}
                            states={states} />
                    )}
                </>
            ) : (
                <>
                    {states && (
                        <EcommerceState
                            disabled={disabled}
                            states={states} />
                    )}
                    <EcommerceCity
                        leftMargin={states != null}
                        disabled={disabled} />
                </>
            )}
        </div>
    );
};

export default EcommerceStateAndCity;
