import {
    ICreateExternalClientPayload,
    IEditExternalClientPayload,
    IExternalClient,
    IExternalClientSecretPayload,
    ISecret,
} from '../../common/interfaces/cis/client';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const externalClientUrl = `${process.buildConfigs.identityApiBaseRoute}/ExternalClient`;

export function getExternalClients(url: string, partitionGlobalId: string) {
    return get<IExternalClient[]>(`${externalClientUrl}/${partitionGlobalId}`);
}

export function getExternalClientById(url: string, partitionGlobalId: string, id: string) {
    return get<IExternalClient>(`${externalClientUrl}/${partitionGlobalId}/${id}`);
}

export async function createExternalClient(payload: ICreateExternalClientPayload) {
    return post<IExternalClient>(externalClientUrl, { body: payload });
}

export function editExternalClient(id: string, payload: IEditExternalClientPayload) {
    return put<IExternalClient>(`${externalClientUrl}/${payload.partitionGlobalId}/${id}`, { body: payload });
}

export function deleteExternalClient(id: string, partitionGlobalId: string) {
    return axiosDelete(`${externalClientUrl}/${partitionGlobalId}/${id}`);
}

export function generateClientSecret(id: string, partitionGlobalId: string) {
    return get<string>(`${externalClientUrl}/${partitionGlobalId}/${id}/GenerateSecret`);
}

export async function createClientSecret(payload: IExternalClientSecretPayload) {
    return post<ISecret>(`${externalClientUrl}/GenerateSecret`, { body: payload });
}

export function deleteClientSecret(secretId: string, partitionGlobalId: string) {
    return axiosDelete(`${externalClientUrl}/${partitionGlobalId}/secrets/${secretId}`);
}
