import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { portalTelemetry } from '@experiences/telemetry';
import { dialogCoordinator } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Tokens from '@uipath/apollo-core';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useIsAdminRevampEnabled } from '../common/hooks/useIsAdminRevampEnabled';
import { history } from '../History.default';

const EnableLogoutOn401Errors = getFeatureFlagValue(Features.EnableLogoutOn401Errors.name);

const AxiosInterceptorContext = React.createContext<null>(null);

export const AxiosInterceptorProvider: React.FC = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    useEffect(() => {
        axios.interceptors.response.use(
            undefined,
            EnableLogoutOn401Errors
                ? async error => {
                    if (error.response.status === 440) {
                        portalTelemetry.trackTrace({
                            message: `Logging out on 401 error service call: ${error}`,
                            severityLevel: SeverityLevel.Error,
                        }, { revampEnabled: isAdminRevampEnabled });

                        const timeoutPromise = new Promise(resolve => setTimeout(resolve, 10000));

                        const showDialogPromise = new Promise(resolve =>
                            dialogCoordinator.showDialog({
                                title: translate({ id: 'CLIENT_SESSION_EXP_HEADER' }),
                                body: (() => {
                                    return (
                                        <span>
                                            {translate({ id: 'CLIENT_SESSION_EXP_401_MSG' })}
                                            <span
                                                onClick={resolve}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: Tokens.Colors.ColorBlue500,
                                                    marginLeft: '3px',
                                                }}
                                            >
                                                {translate({ id: 'CLIENT_CLICK_TO_SIGN_OUT_MANUALLY' })}
                                            </span>
                                        </span>
                                    );
                                })(),
                                icon: 'error',
                                hideActions: true,
                                unclosable: true,
                            }),
                        );

                        await Promise.any([ timeoutPromise, showDialogPromise ]);
                        dialogCoordinator.showDialog({});

                        // Calling auth.logout() directly is not as smooth
                        history.push('/portal_/logout');
                    }
                    return Promise.reject(error);
                }
                : undefined,
        );
    }, [ translate ]);

    return <AxiosInterceptorContext.Provider value={null}>
        {children}
    </AxiosInterceptorContext.Provider>;
};
