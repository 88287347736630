import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { AdvantagesOfSamlIntegration } from '../../../common/constants/documentation/SAMLDocumentationLinks';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { ReactComponent as AutomaticOnboardingIcon } from '../../../images/automaticOnboarding.svg';
import { ReactComponent as ScaleGovernanceIcon } from '../../../images/scaleGovernance.svg';
import { ReactComponent as SimplifiedSigninIcon } from '../../../images/simplifiedSignin.svg';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        subtitle: {
            fontWeight: 600,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        learnMoreLink: { marginLeft: '5px' },
        svgIcon: {
            margin: '16px 0px',
            '& .iconFillPrimary': { fill: theme.palette.semantic.colorPrimary },
            '& .iconFillSecondary': { fill: theme.palette.semantic.colorForegroundHigh },
            '& .iconStrokePrimary': { stroke: theme.palette.semantic.colorPrimary },
            '& .iconStrokeSecondary': { stroke: theme.palette.semantic.colorForegroundHigh },
        },
        advantagesList: {
            display: 'flex',
            marginBottom: '24px',
        },
        advantage: {
            maxWidth: '200px',
            marginRight: '60px',
        },
        advantageText: { color: theme.palette.semantic.colorForeground },
        prereqTitle: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundEmp,
            lineHeight: '20px',
        },
        bullet: {
            fontSize: '20px',
            fontWeight: 800,
            marginRight: '8px',
            lineHeight: '20px',
        },
        prereq: {
            marginTop: '12px',
            color: theme.palette.semantic.colorForeground,
        },
        prereqLink: { fontWeight: 600 },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
        },
    }),
}));

const SsoPrerequirementsComponent: React.FC<{ type: 'aad' | 'saml' }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();

    const getLocalizedLink = useDocumentationLinks({
        product: process.buildConfigs.documentationProduct,
        version: process.buildConfigs.documentationVersion,
    });

    const cancel = useCallback(() => {
        history.push(getRoute((process.buildConfigs.isOnPrem && !isSecuritySettingsRevampEnabled)
            ? RouteNames.AuthSettings : RouteNames.SecuritySettings));
    }, [ history, getRoute, isSecuritySettingsRevampEnabled ]);

    const goToForm = useCallback(() => {
        history.replace({
            ...history.location,
            state: {
                ...(history.location.state as any),
                showPreReqs: false,
            },
        });
    }, [ history ]);

    return (
        <UiForm
            onSubmit={() => {
                null;
            }}
            actions={
                <div className={classes.actions}>
                    <Button
                        onClick={cancel}
                        className={classes.footerButton}
                        data-cy="prereqs-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <Button
                        onClick={goToForm}
                        variant="outlined"
                        className={classes.footerButton}
                        data-cy="prereqs-next-button">
                        {translate({ id: 'CLIENT_NEXT' })}
                    </Button>
                </div>
            }>
            <Typography className={classes.subtitle}>
                {translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_ADVANTAGES_OF_${type.toUpperCase()}` })}
                <a
                    className={clsx(classes.learnMoreLink, classes.a)}
                    href={getLocalizedLink(AdvantagesOfSamlIntegration)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {translate({ id: 'CLIENT_LEARN_MORE' })}
                </a>
            </Typography>

            <div className={classes.advantagesList}>
                <div className={classes.advantage}>
                    <AutomaticOnboardingIcon className={classes.svgIcon} />
                    <Typography className={classes.advantageText}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AUTOMATIC_ONBOARDING' })}
                    </Typography>
                </div>
                <div className={classes.advantage}>
                    <SimplifiedSigninIcon className={classes.svgIcon} />
                    <Typography className={classes.advantageText}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_SIMPLIFIED_SIGN_IN' })}
                    </Typography>
                </div>
                <div className={classes.advantage}>
                    <ScaleGovernanceIcon className={classes.svgIcon} />
                    <Typography className={classes.advantageText}>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_SCALE_GOVERNANCE' })}
                    </Typography>
                </div>
            </div>

            <Typography className={classes.prereqTitle}>
                {translate({ id: 'CLIENT_PREREQ' })}
            </Typography>
            <Typography className={classes.prereq}>
                <span className={classes.bullet}>
•
                </span>
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_PREREQ_ADMIN_PERMISSIONS' })}
            </Typography>
            {type === 'aad' && (
                <Typography className={classes.prereq}>
                    <span className={classes.bullet}>
•
                    </span>
                    {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_PREREQ_AAD_ACCOUNT' })}
                </Typography>
            )}
            <Typography className={classes.prereq}>
                <span className={classes.bullet}>
•
                </span>

                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_PREREQ_STUDIO_VERSION' })}
            </Typography>
            {/* <Typography className={classes.prereq}>
        <span className={classes.bullet}>•</span>
        {translate(
          { id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_PREREQ_STUDIO_REC_DEPLOYMENT' },
          {
            recommendedDeploymentLink: (
              <a className={clsx(classes.prereqLink, classes.a)}>
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_PREREQ_STUDIO_DEPLOYMENT_LINK_TEXT' })}
              </a>
            ),
          },
        )}
      </Typography> */}

        </UiForm>
    );
};

export default SsoPrerequirementsComponent;
