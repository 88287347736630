import { ILrmProduct } from '@experiences/interfaces';

import {
    ILicenseOverallocation,
    IServiceLicense,
    IServiceLicenseAllocationRequest,
    ITenantServiceLicense,
} from '../../component/tenants/interfaces/service';
import {
    get,
    put,
} from '../utility/Requests.default';

export const licenseManagementUri = '/api/manageLicense';

export async function saveServiceLicenses(
    accountId: string,
    tenantId: string,
    serviceType: string,
    serviceLicense: IServiceLicenseAllocationRequest,
) {
    return put<IServiceLicense>(
        `${licenseManagementUri}/api/account/${accountId}/service-license/${tenantId}/${serviceType}`,
        { body: serviceLicense },
    );
}

export function getServiceLicenses(_url: string, partitionGlobalId: string) {
    return get<ILrmProduct[]>(`${licenseManagementUri}/api/account/${partitionGlobalId}/available-account-licenses`);
}

export function getTenantServiceLicenses(_url: string, accountId: string, tenantId: string, services: string[]) {
    return get<ITenantServiceLicense[]>(`${licenseManagementUri}/api/account/${accountId}/service-licenses/${tenantId}`, {
        urlParams: { services: services },
        shouldEnumerate: false,
    });
}

export async function checkIfLicenseIsOverallocated(accountGlobalId: string) {
    return get<ILicenseOverallocation>('/api/licensing/accountLicenseOverAllocation', { urlParams: { accountGlobalId } });
}
