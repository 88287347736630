/* eslint-disable no-unused-vars */

export interface IProductBase {
    code: string;
    quantity: number;
}

export interface IProduct extends IProductBase {
    minQuantity?: number;
    maxQuantity?: number;
}

export interface ICustomPlanForm {
    productQuantities: { [code: string]: number };
}

export interface IUpdateLicenseQuantityForm extends ICustomPlanForm {
    termsAndConditionsAccepted: boolean;
}

interface ISkuPackageBase {
    type: string;
    billingPeriod: string;
    products: IProductBase[];
}

export interface ISkuPackage extends ISkuPackageBase {
    price: number;
    currency: string;
}

export enum Currency {
    USD,
    EURO,
    JPY,
}

export interface IPackagesConfig {
    currency: Currency;
    packages: IPackage[];
    productPrices: IProductPrice[];
}

export interface IProductPrice {
    price: number;
    code: string;
}

export interface IPackage {
    type: string;
    billingPeriod: string;
    productQuantities: IProduct[];
    isCustomizable: boolean;
    productDiscounts: IDiscountedProductPrice[];
}

export interface INonUsTaxes {
    validVatTaxResponse: ITaxResponse;
    invalidVatTaxResponse: ITaxResponse;
}

export interface ITaxResponse {
    taxValue: number;
    taxRate: number;
    isValid: boolean;
}

export interface IErrorWithStatus extends Error {
    status: number;
    translationId?: string;
}

export interface IProductPriceOptions {
    code: string;
    prices: { [currency: string]: number };
}

export interface IProductsPrices {
    products: IProductPriceOptions[];
    defaultCurrency: string;
    stripePublishableKey?: string;
}

export interface IDiscountedProductsPrices {
    products: IDiscountedProductPriceOptions[];
    defaultCurrency: string;
    stripePublishableKey?: string;
}

export interface IDiscountedProductPriceOptions {
    code: string;
    prices: { [currency: string]: number };
    discount: IDiscount;
}

export interface IDiscountBase {
    couponId: string;
    durationInMonths: number;
    percentageOff: number;
}

export interface IDiscount extends IDiscountBase {
    discountedPrices: { [currency: string]: number };
}

export interface IInvoiceDiscount extends IDiscountBase {
    discountedPrice: number;
    end?: string;
}

export interface IDiscountedProductPrice extends IDiscountBase {
    discountedPrice: number;
    code: string;
}

export interface ISkuPackageDetails extends ISkuPackageBase {
    prices: { [currency: string]: number };
    discountedPrices: { [currency: string]: number };
    discountDetails?: IDiscountBase;
    defaultCurrency: string;
    stripePublishableKey?: string;
    tax?: ITaxResponse;
    taxError?: IErrorWithStatus;
    isEuCountry?: boolean;
}

export interface IHistoryStatus {
    hasHistoricalData: boolean;
}

export enum BillingEntityCode {
    RO = 'RO',
    US = 'US',
    JP = 'JP',
}

export interface IPlanDetails {
    planType: string;
    nextPayment: string;
    upcomingBill: number;
    startDate: string;
    endDate: string;
    currency: string;
    subscriptionId: string;
    billingEntityCode: BillingEntityCode;
    discount: IInvoiceDiscount;
}

export interface ISubscriptionDetails {
    packageType: string;
    products?: IProductBase[];
    currency: string;
    paymentMethod: string;
    countryCode: string;
    addressDetails: IAddressDetails;
    taxId?: string;
    leadDetails: IBusinessInfoPayload;
    organizationName?: string;
    googleAnalyticsId?: string;
}

export interface ISubscriptionUpdate {
    products: IProductBase[];
}

export interface IBusinessInfoForm {
    firstName: string;
    lastName: string;
    country: string;
    state?: string;
    businessEmail: string;
    companyName: string;
    jobTitle: string;
    logicalName?: string;
    marketingConsent?: boolean;
}

export interface IBusinessInfoPayload extends IBusinessInfoForm {
    userLanguage: string;
}

export interface IMarketoInfo {
    id?: number;
    cloudPlatformUserId?: string;
    email?: string;
    businessEmail?: string;
    firstName?: string;
    lastName?: string;
    formReceiveMarketingCommunications?: boolean;
    country?: string;
    cloudplatformLanguage?: string;
    Last_Interaction__c?: string;
    Last_Interaction_Details__c?: string;
    cloudplatformAccountID?: string;
    organizationName?: string;
    cloudplatformLicenseCode?: string;
    cloudplatformAccountLicenseExpiresAt?: Date;
    cloudplatformAccountLicenseSubscriptionCode?: string;
    accountURL?: string;
    state?: string;
    jobTitle?: string;
    status?: string;
    reasons?: [
        {
            code?: string;
            message?: string;
        },
    ];
}

export interface ISubscriptionForm {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2?: string;
    country: string;
    currency: string;
    city: string;
    state?: string;
    zipcode?: string;
    taxId?: string;
    nameOnCard: string;
    termsAndConditionsAccepted: boolean;
}

export interface IAddressDetails {
    line1: string;
    line2?: string;
    country: string;
    city: string;
    state?: string;
    postalcode?: string;
}

export interface IOrderedProductDetails {
    code: string;
    quantity: number;
    totalPrice: number;
    currency: string;
}

export interface IOrderConfirmationInvoice {
    subtotal: number;
    taxValue: number;
    taxRate: number;
    total: number;
    currency: string;
    created: Date;
    email: string;
    cardBrand: 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';
    cardLast4: string;
    proration?: boolean;
    discount?: IInvoiceDiscount;
}

export interface IOrderConfirmation {
    status: 'PAYMENT_PROCESSED' | 'PAYMENT_INITIALIZED' | 'PAYMENT_FAILED';
    invoiceData?: IOrderConfirmationInvoice;
    accountName?: string;
    packageType: string;
    products?: IOrderedProductDetails[];
    organizationId?: string;
}

export interface ICreateSubscriptionDto {
    stripeSubscriptionId: string;
    subscriptionStatus: string;
    paymentIntentStatus: string;
    paymentIntentClientSecret: string;
    organizationId?: string;
}

export interface IUpdateSubscriptionDto {
    stripeSubscriptionId: string;
    paymentIntentStatus: string;
    paymentIntentClientSecret: string;
    defaultPaymentMethodId: string;
    orderConfirmation: IOrderConfirmation;
}

export interface IAddressValidationResult {
    isValid: boolean;
}

export interface IVatValidation {
    vatCode: string;
    validationStatus: string;
    country: string;
}

export interface ICheckOrganizationResponse {
    ready: boolean;
    organizationName?: string;
}

export interface IEcommerceCheckoutComponentState {
    skuPackage?: ISkuPackage;
    businessInfo?: IBusinessInfoForm;
    showPaymentInfo?: boolean;
    billingInfo?: ISubscriptionForm;
}

export interface IProductQuantityDifference {
    value: number;
    sign: '+' | '-';
}

export interface IUpcomingInvoiceRequest {
    products: IProductBase[];
}

export interface IUpcomingInvoiceResponse {
    productPrices: IOrderedProductDetails[];
}

export interface IProductQuantityPrice extends IProductBase {
    quantity: number;
    proratedPrice?: number;
}

export interface IEcommerceSuccess {
    title: string;
    line1: string;
    line2?: string;
    button: 'OK' | 'DISMISS';
}
