import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    GridSize,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import {
    INotificationTopic,
    INotificationTopicGroup,
} from './interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        topicTitle: { fontWeight: 600 },
        notificationTopicItem: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            borderRadius: '3px',
            marginBottom: '8px',
            padding: '18px 30px',
        },
        notificationSubtopicContainer: { paddingLeft: '20px' },
        loaderContainer: {
            minHeight: '42px',
            minWidth: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

const NotificationTopicComponent: React.FC<{
    notificationTopic: INotificationTopicGroup;
    allNotificationChannelsDisabled: boolean;
    changeTopicSubscription(topicIndex: number, isSubscribed: boolean): void;
    toggleAllTopicSubscription(isSubscribed: boolean): void;
}> = ({
    notificationTopic, allNotificationChannelsDisabled, changeTopicSubscription, toggleAllTopicSubscription,
}) => {
    const classes = useStyles();

    const {
        isTopicGrouped,
        isEveryTopicChecked,
        isAnyTopicChecked,
        isAllTopicItemsUpdating,
        gridItemColumnSize,
    } = useMemo(
        () => ({
            isTopicGrouped: notificationTopic.groupName !== 'un-grouped',
            isEveryTopicChecked: notificationTopic.topics.every((topic: INotificationTopic) => topic.isSubscribed),
            isAnyTopicChecked: notificationTopic.topics.some((topic: INotificationTopic) => topic.isSubscribed),
            isAllTopicItemsUpdating: notificationTopic.isUpdating,
            gridItemColumnSize: (notificationTopic.topics?.length > 4 ? 6 : 12),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ JSON.stringify(notificationTopic.topics), notificationTopic.groupName, notificationTopic.isUpdating ],
    );

    return notificationTopic.topics.length !== 0 ? (
        <div className={classes.notificationTopicItem}>
            {isTopicGrouped ? (
                <FormControlLabel
                    control={
                        !isAllTopicItemsUpdating ? (
                            <Checkbox
                                indeterminate={isAnyTopicChecked && !isEveryTopicChecked}
                                checked={isAnyTopicChecked}
                                disabled={allNotificationChannelsDisabled}
                                onChange={() => toggleAllTopicSubscription(!isEveryTopicChecked)}
                            />
                        ) : (
                            <div className={classes.loaderContainer}>
                                <CircularProgress
                                    size={16}
                                    thickness={4} />
                            </div>
                        )
                    }
                    label={<span className={classes.topicTitle}>
                        {notificationTopic.groupName}
                    </span>}
                />
            ) : null}
            <div className={clsx({ [classes.notificationSubtopicContainer]: isTopicGrouped })}>
                <Grid
                    container
                    spacing={0}>
                    {notificationTopic.topics.map((topicItem: INotificationTopic, topicItemIndex: number) => (
                        <Grid
                            item
                            xs={gridItemColumnSize as GridSize}
                            key={topicItemIndex}>
                            <FormControlLabel
                                control={
                                    !topicItem.isUpdating ? (
                                        <Checkbox
                                            checked={topicItem.isSubscribed}
                                            disabled={allNotificationChannelsDisabled || isAllTopicItemsUpdating}
                                            onChange={e => changeTopicSubscription(topicItem.topicIndex, e.target.checked)}
                                        />
                                    ) : (
                                        <div className={classes.loaderContainer}>
                                            <CircularProgress
                                                size={16}
                                                thickness={4} />
                                        </div>
                                    )
                                }
                                label={topicItem.displayName}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    ) : null;
};

export default NotificationTopicComponent;
