import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    Paper,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useHistory,
    useLocation,
} from 'react-router';
import xss from 'xss';

import { ERROR_MAX_STRING_LENGTH } from '../common/constants/Constant';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 380,
            overflow: 'auto',
            marginTop: '100px',
            padding: '0px 24px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        content: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
            paddingBottom: '24px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            textAlign: 'center',
            marginTop: '8px',
            overflowWrap: 'break-word',
            paddingBottom: '20px',
        },
        showMore: {
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
        },
        button: {
            width: '280px',
            marginTop: '16px',
        },
    }),
);

export const AuthError: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const location = useLocation<{ error: Error }>();

    const [ showMore, setShowMore ] = useState(false);

    const [ errorMessage, fullErrorMessage ] = useMemo(() => {
        const error = location.state?.error;

        if (error) {
            if (error.message.length >= ERROR_MAX_STRING_LENGTH) {
                setShowMore(true);
            }

            return [ error.message.substring(0, ERROR_MAX_STRING_LENGTH), error.message ];
        }

        return [ undefined, undefined ];
    }, [ location.state ]);

    const handleShowMore = useCallback(() => {
        setShowMore(false);
    }, []);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div
                    className={classes.content}
                    data-cy="unhandled-error-content">
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <Typography className={classes.headerText}>
                        {translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG' })}
                    </Typography>
                    {showMore ? (
                        <>
                            <Typography
                                className={classes.headerSubText}
                                data-cy="error-message">
                                {xss(errorMessage || '')}
                            </Typography>
                            <Typography
                                role="button"
                                className={classes.showMore}
                                onClick={handleShowMore}
                                data-cy="show-more-button">
                                {translate({ id: 'CLIENT_SHOW_MORE' })}
                            </Typography>
                        </>
                    ) : (
                        <Typography
                            className={classes.headerSubText}
                            data-cy="full-error-message">
                            {xss(fullErrorMessage || errorMessage || translate({ id: 'CLIENT_OOPS_ERROR_DESCRIPTION' }))}
.
                        </Typography>
                    )}
                    <Typography>
                        {translate({ id: 'CLIENT_SIGNIN_AGAIN' })}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => history.push('/')}
                        data-cy="unhandled-error-login-button"
                    >
                        {translate({ id: 'CLIENT_SIGN_IN' })}
                    </Button>
                </div>
            </Paper>
        </UiBackground>
    );
};

export default AuthError;
