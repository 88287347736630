import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { User } from 'oidc-client-ts';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import setLastUsedOrganizationId from '../callback/LastUsedOrganizationId';
import setSignInCookie from '../callback/SignInCookie';
import useGetAuthProvider from './GetAuthProvider';

interface ICallbackState {
    redirect_uri: string;
}

export default function useAuthCallback() {
    const history = useHistory();

    const getAuthType = useGetAuthProvider();

    const EnableAccessTokenLocalStorage = useFeatureFlagValue(Features.EnableAccessTokenLocalStorage.name);

    return useCallback(async (user: User | void) => {
        let redirect_uri = '/portal_/cloudrpa';

        if (user && user instanceof User) {
            const loginMethod = await getAuthType(user.access_token);

            if (EnableAccessTokenLocalStorage) {
                localStorage.setItem('accessToken', user.access_token);
            }

            if (process.buildConfigs.enableAuthCallbackOps) {
                loginMethod && setSignInCookie(user, loginMethod);
                setLastUsedOrganizationId(user as User);
            }

            redirect_uri = (<ICallbackState>user.state)?.redirect_uri;
        }

        history.push(redirect_uri);
    }, []);
}
