import { Grid } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        grid: {
            maxWidth: '1100px',
            justifyContent: 'center',
        },
    }),
);

const UiCardGrid: React.FC<{ maxCardWidth: string; style?: React.CSSProperties }> = ({
    children, maxCardWidth, style,
}) => {
    const classes = useStyles();

    return <Grid
        className={classes.grid}
        sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(250px, ${maxCardWidth}))`,
            gridAutoFlow: 'row',
            gap: '24px',
        }}
        style={style}>
        {children}
    </Grid>;
};

export default UiCardGrid;
