import {
    FormControlLabel,
    Switch,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useCallback,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { IUserCIS } from '../../../common/interfaces/cis/user';

const useStyles = makeStyles(theme =>
    createStyles({
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        input: { marginTop: '30px' },
    }),
);

export interface IEditUserAuthSettingsData {
    bypassBasicAuthRestriction: boolean;
}

export const getDefaultEditUserAuthSettingsData = (user?: IUserCIS): IEditUserAuthSettingsData => ({ bypassBasicAuthRestriction: user?.bypassBasicAuthRestriction ?? false });

const EditUserAuthSettingsFormComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, setValue,
    } = useFormContext<IEditUserAuthSettingsData>();

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <div className={classes.input}>
            <Controller
                name="bypassBasicAuthRestriction"
                control={control}
                render={({
                    value, onChange,
                }) => (
                    <FormControlLabel
                        className={classes.switchFormControl}
                        control={
                            <Switch
                                checked={value}
                                onChange={e => handleSwitch('bypassBasicAuthRestriction', e.target.checked, onChange)}
                                color="primary"
                                data-cy="edit-user-auth-settings-bypass-basic-auth-restriction"
                            />
                        }
                        label={
                            <div className={classes.switchLabel}>
                                {translate({ id: 'CLIENT_ALLOW_BYPASSING_LOGIN_RESTRICTION' })}
                            </div>
                        }
                    />
                )}
            />
        </div>
    );
};

export default EditUserAuthSettingsFormComponent;
