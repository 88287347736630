import {
    ENVIRONMENT,
    LocalGlobalAppOrigin,
    LocalPortalOrigin,
    SubscriptionType,
} from '@experiences/constants';
import { match } from '@uipath/path-to-regexp';
import { RouteConfig } from 'react-router-config';

import UiStorage from '../global/UiStorage';
import { getEnvironment } from './EnvironmentUtil';

function getOrganizationAppOrigin() {
    if (getEnvironment() === ENVIRONMENT.LOCAL) {
        return LocalPortalOrigin;
    }

    return window.location.origin;
}

function getGlobalAppOrigin() {
    if (getEnvironment() === ENVIRONMENT.LOCAL) {
        return LocalGlobalAppOrigin;
    }

    return window.location.origin;
}

export function shouldLoadApplicationWithAPath(pathName: string, routes: RouteConfig[]) {
    if (pathName === '/') {
        return true;
    }

    // filter out '/' because it matches all paths
    return routes
        .map(route => route.path)
        .filter(path => !!path && path !== '/')
        .some(path => match(path!, { decode: decodeURIComponent })(pathName));
}

export function loadOrganizationApp(organizationName: string, organizationId: string, extraParams?: any) {
    const ecommerceRedirect = UiStorage.getItem('ecommerceRedirect') || 'false';
    if (ecommerceRedirect) {
        UiStorage.removeItem('ecommerceRedirect');
    }

    const params: any = {
        organizationId,
        ecommerceRedirect,
        ...extraParams,
    };
    const search = new URLSearchParams(params);

    window.location.assign(`${getOrganizationAppOrigin()}/${organizationName}/portal_/cloudrpa?${search.toString()}`);
}

export function loadOrganizationAppByAccount(organizationName: string) {
    window.location.assign(`${getOrganizationAppOrigin()}/${organizationName}/portal_/cloudrpa`);
}

export function loadRetryUrl(retryUrl: string) {
    window.location.assign(`${getOrganizationAppOrigin()}${retryUrl}`);
}

export function loadGlobalApp() {
    window.location.assign(getGlobalAppOrigin());
}

export function isFreeSkuEnabled() {
    return UiStorage.getItem('subscriptionPlan') === SubscriptionType.FREE_SKU;
}

export function navigateToAutomationHub(logicalName: string, search: URLSearchParams) {
    window.location.assign(`${window.location.origin}/${logicalName}/DefaultTenant/automationhub_?${search.toString()}`);
}
