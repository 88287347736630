import {
    useLocalization,
    useSupportedLanguagesMap,
} from '@experiences/locales';
import {
    themeMap,
    useApolloTheme,
} from '@experiences/theme';
import { UiSelect } from '@experiences/ui-common';
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import {
    defaultLanguage,
    setTheme,
    ThemeType,
} from '@uipath/portal-shell-util';
import clsx from 'clsx';
import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import useSetUserLanguage from '../../../common/hooks/useSetUserLanguage.default';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        formContainer: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        mainHeading: { paddingBottom: '20px' },
        radio: {
            marginTop: '14px',
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        rowGroup: {
            marginLeft: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        section: { paddingBottom: '20px' },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
    }),
);

interface IProfilePreferencesData {
    language: string;
    theme: ThemeType;
}

const ProfilePreferencesComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const setUserLanguage = useSetUserLanguage();
    const selectedLanguage = useLocalization(false);
    const { selectedThemeId } = useApolloTheme();
    const languageOptions = useSupportedLanguagesMap();
    const {
        control, errors, setValue,
    } = useForm<IProfilePreferencesData>({
        mode: 'onChange',
        defaultValues: {
            language: (selectedLanguage ?? defaultLanguage).toLowerCase(),
            theme: selectedThemeId ?? 'autoTheme',
        },
    });
    const prevLangRef = useRef<string>();
    const prevThemeRef = useRef<ThemeType>();
    const { logEvent } = useTelemetryHelper();

    const handleThemeSelect = useCallback((newTheme: ThemeType) => {
        logEvent('Theme.ChangeTheme', {
            ProductThemeOld: prevThemeRef.current,
            ProductThemeNew: newTheme,
        });
        setTheme(newTheme);
        prevThemeRef.current = newTheme;
    }, [ logEvent ]);

    const handleLanguageSelect = useCallback((newLanguage) => {
        logEvent('Localization.ChangeLanguage', {
            ProductLanguageOld: prevLangRef.current,
            ProductLanguageNew: newLanguage,
        });
        setUserLanguage(newLanguage);
        prevLangRef.current = newLanguage;
    }, [ logEvent, setUserLanguage ]);

    useEffect(() => {
        if (selectedThemeId) {
            setValue('theme', selectedThemeId);
        }
    }, [ selectedThemeId, setValue ]);

    useEffect(() => {
        if (selectedLanguage) {
            setValue('language', selectedLanguage.toLowerCase());
        }
    }, [ selectedLanguage, setValue ]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.section}>
                <Typography
                    className={clsx(classes.heading, classes.mainHeading)}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_PREFERENCES' })}
                </Typography>
                <UiSelect
                    control={control}
                    dataCy="profile-preferences-language-select"
                    inputLabel={translate({ id: 'CLIENT_LANGUAGE' })}
                    name="language"
                    options={languageOptions}
                    error={!!errors.language}
                    fullWidth
                    isTranslated
                    required={true}
                    onChange={event => handleLanguageSelect(event.target.value)}
                />
            </div>
            <div className={classes.section}>
                <Typography className={classes.subHeading}>
                    {translate({ id: 'CLIENT_THEME' })}
                </Typography>
                <Typography>
                    {translate({ id: 'CLIENT_THEME_CHANGE_DESCRIPTION' })}
                </Typography>
                <Controller
                    control={control}
                    name="theme"
                    rules={{ required: true }}
                    render={({
                        onChange, value,
                    }) => (
                        <RadioGroup
                            data-cy="profile-preferences-theme-radio-buttons"
                            className={classes.rowGroup}
                            value={value}
                            onChange={event => {
                                onChange(event.target.value);
                                handleThemeSelect(event.target.value as ThemeType);
                            }}
                        >
                            {Object.entries(themeMap).map(([ reasonId, reasonValue ], i) => (
                                <FormControlLabel
                                    key={i}
                                    className={classes.radio}
                                    control={<Radio
                                        value={reasonId}
                                        checked={value === reasonId}
                                        color="primary" />}
                                    label={translate({ id: reasonValue })}
                                />
                            ))}
                        </RadioGroup>
                    )}
                />
            </div>
        </div>
    );
};

export default ProfilePreferencesComponent;
