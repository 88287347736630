import {
    get,
    post,
    put,
} from '@experiences/util';
import axios, { AxiosError } from 'axios';

import {
    IAddressDetails,
    IAddressValidationResult,
    IBusinessInfoPayload,
    ICheckOrganizationResponse,
    ICreateSubscriptionDto,
    IDiscountedProductsPrices,
    IErrorWithStatus,
    IMarketoInfo,
    INonUsTaxes,
    IOrderConfirmation,
    IPackagesConfig,
    IPlanDetails,
    IProductBase,
    IProductsPrices,
    ISkuPackage,
    ISubscriptionDetails,
    ISubscriptionUpdate,
    ITaxResponse,
    IUpcomingInvoiceRequest,
    IUpcomingInvoiceResponse,
    IUpdateSubscriptionDto,
    IVatValidation,
} from '../interfaces/ecommerce';

export const billingUrl = '/api/billing';
export const billingSubscriptionUri = '/api/billing/subscription';

export function validateEmailBlacklist(email: string) {
    return get('/api/auth/Email/validate', {
        urlParams: { email },
        accessToken: '',
    });
}

export async function getPublicSkuPackages() {
    const publicHost = window.env.ECOMMERCE_HOST.replace('{organizationGuid}/', '');
    return await axios.get<ISkuPackage[]>(`${publicHost}sku-package-prices?countryCode=US`);
}

export async function getProductsPricesByCountry(countryCode: string, accessToken: string, accountName?: string) {
    const urlParams = {
        accountName,
        countryCode,
    };
    return await get<IProductsPrices>(`${billingUrl}/productsPrices`, {
        urlParams,
        accessToken,
    });
}

export async function getProductsPricesWithDiscountsByCountry(countryCode: string, productAndQuantities: IProductBase[],
    accessToken: string, accountName?: string) {
    const urlParams = {
        accountName,
        countryCode,
    };
    return await post<IDiscountedProductsPrices>(`${billingUrl}/productsPrices`, {
        urlParams,
        accessToken,
        body: productAndQuantities,
    });
}

export async function getUsTaxValue(countryCode: string, zipcode: string, amount: number, accountName: string, accessToken: string) {
    const urlParams = {
        countryCode,
        zipcode,
        amount,
        accountName,
    };
    if (amount) {
        urlParams['amount'] = amount;
    }
    try {
        return await get<ITaxResponse>(`${billingUrl}/getUsTaxes`, {
            urlParams,
            accessToken,
        });
    } catch (error) {
        const errorWithStatus = new Error('An error occurred while fetching US taxes.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_US_TAXES';
        errorWithStatus.status = error.response.status;
        errorWithStatus.stack = error.stack;
        throw errorWithStatus;
    }
}

export async function getNonUsTaxValue(countryCode: string, amount: number, accountName: string, accessToken: string) {
    const urlParams = {
        countryCode,
        amount,
        accountName,
    };
    if (amount) {
        urlParams['amount'] = amount;
    }
    try {
        return await get<INonUsTaxes>(`${billingUrl}/getNonUsTaxes`, {
            urlParams,
            accessToken,
        });
    } catch (error) {
        const errorWithStatus = new Error('An error occurred while fetching NON-US taxes.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_NON_US_TAXES';
        errorWithStatus.status = error.response.status;
        errorWithStatus.stack = error.stack;
        throw errorWithStatus;
    }
}

export async function validateUsTaxes(addressDetails: IAddressDetails, accessToken: string, accountName?: string) {
    try {
        return await post<IAddressValidationResult>(`${billingUrl}/validateUsAddress`, {
            urlParams: { accountName },
            accessToken,
            body: addressDetails,
        });
    } catch (error) {
        const errorWithStatus = new Error('An error occurred while Validating Address.') as IErrorWithStatus;
        errorWithStatus.translationId = 'CLIENT_ERROR_VALIDATING_ADDRESS';
        errorWithStatus.status = error.response.status;
        errorWithStatus.stack = error.stack;
        throw errorWithStatus;
    }
}

export async function validateEuVat(
    countryCode: string,
    vatId: string,
    accountName: string,
    accessToken: string,
) {
    const urlParams = {
        countryCode,
        vatId,
        accountName,
    };
    return await get<IVatValidation>(`${billingUrl}/validateEuVat`, {
        urlParams,
        accessToken,
    });
}

export async function createSubscription(subscriptionDetails: ISubscriptionDetails, accountName: string, accessToken: string) {
    try {
        return await post<ICreateSubscriptionDto>(`${billingUrl}/createSubscription`, {
            urlParams: { accountName },
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while creating subscription.', error);
    }
}

export async function updateSubscription(subscriptionDetails: ISubscriptionUpdate, accountName: string, accessToken: string) {
    try {
        return await put<IUpdateSubscriptionDto>(`${billingUrl}/updateSubscription`, {
            urlParams: { accountName },
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while updating subscription.', error);
    }
}

export async function createDirectBuySubscription(subscriptionDetails: ISubscriptionDetails, accessToken: string) {
    try {
        return await post<ICreateSubscriptionDto>(`${billingUrl}/createDirectBuySubscription`, {
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while creating subscription.', error);
    }
}

export async function isOrganizationReady(organizationGuid: string, accessToken: string) {
    return await get<ICheckOrganizationResponse>(`${billingUrl}/isOrgReady`, {
        urlParams: { organizationGuid },
        accessToken,
    });
}

export async function getExistingUserMarketoData(accessToken: string, accountName?: string) {
    const marketoInfo = await get<IMarketoInfo>(`${billingUrl}/marketo`, {
        urlParams: { accountName },
        accessToken,
    });

    return {
        ...marketoInfo,
        businessEmail: marketoInfo.businessEmail,
        companyName: marketoInfo.organizationName,
        userLanguage: marketoInfo.cloudplatformLanguage,
    } as IBusinessInfoPayload;
}

export async function sendUserMarketoData(marketoData: IBusinessInfoPayload, accessToken: string, accountName?: string) {
    await post(`${billingUrl}/marketo`, {
        urlParams: { accountName },
        body: marketoData,
        accessToken,
    });
}

export function getOrderConfirmationDetails(stripeSubscriptionId: string, accountName: string, accessToken: string) {
    return get<IOrderConfirmation>(`${billingUrl}/subscription`, {
        urlParams: {
            stripeSubscriptionId,
            accountName,
        },
        accessToken,
    });
}

export function getDirectBuyOrderConfirmationDetails(organizationId: string, countryCode: string,
    stripeSubscriptionId: string, accessToken: string) {
    return get<IOrderConfirmation>(`${billingUrl}/directBuyOrderConfirmation`, {
        urlParams: {
            organizationId,
            countryCode,
            stripeSubscriptionId,
        },
        accessToken,
    });
}

export function getFixedCurrencyIfPresent(countryCode: string, accountName: string, accessToken: string) {
    return get<string>(`${billingUrl}/customerCurrency`, {
        urlParams: {
            countryCode,
            accountName,
        },
        accessToken,
    });
}

export async function getPackagesConfig(accountName: string, countryCode: string, accessToken: string) {
    const urlParams: any = { accountName };
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return get<IPackagesConfig>(`${billingUrl}/packagesConfig`, {
        urlParams,
        accessToken,
    });
}

export async function fetchPlanDetails(accountName: string, accessToken: string) {
    const urlParams = { accountName };
    return get<IPlanDetails>(`${billingUrl}/planDetails`, {
        urlParams,
        accessToken,
    });
}

export async function getUpcomingInvoice(upcomingInvoiceRequest: IUpcomingInvoiceRequest, accountName: string, accessToken: string) {
    try {
        return await put<IUpcomingInvoiceResponse>(`${billingUrl}/upcomingInvoice`, {
            urlParams: { accountName },
            body: upcomingInvoiceRequest,
            accessToken,
        });
    } catch (error) {
        throw buildError('An error occurred while fetching the upcoming invoice.', error);
    }
}

function buildError(message: string, error: AxiosError) {
    const errorWithStatus = new Error(message) as IErrorWithStatus;
    errorWithStatus.translationId = error.response?.data?.ErrorCode
        ? `CLIENT_ERROR_${error.response.data.ErrorCode.toUpperCase()}`
        : 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY';
    if (error.response?.status) {
        errorWithStatus.status = error.response?.status;
    }
    errorWithStatus.stack = error.stack;
    errorWithStatus.message = error.response?.data?.Message;
    return errorWithStatus;
}
