import {
    IAddIPRestrictionPayload,
    IEditIPRestrictionPayload,
    IIPRestriction,
} from '../../common/interfaces/iprestriction';
import {
    axiosDelete,
    get,
    patch,
    post,
} from '../utility/Requests.default';

export const ipRangeUrl = '/api/accessPolicy';

export async function getCurrentIp() {
    return await get<string>(`${ipRangeUrl}/ip`);
}

export async function getIpRangeByOrganizationId(key: string, organizationId: string) {
    return await get<IIPRestriction[]>(`${ipRangeUrl}/organization/${organizationId}/ipRanges`);
}

export async function getIpRange(organizationId: string, ipRangeId: string) {
    return await get<IIPRestriction>(`${ipRangeUrl}/organization/${organizationId}/ipRange/${ipRangeId}`);
}

export async function addIpRange(organizationId: string, payload: IAddIPRestrictionPayload) {
    return await post<IIPRestriction>(`${ipRangeUrl}/organization/${organizationId}/ipRange`, { body: payload });
}

export async function updateIpRange(organizationId: string, ipRangeId: string, payload: IEditIPRestrictionPayload) {
    return await patch<IIPRestriction>(`${ipRangeUrl}/organization/${organizationId}/ipRange/${ipRangeId}`, { body: payload });
}

export async function deleteIpRange(organizationId: string, ipRangeId: string) {
    return await axiosDelete(`${ipRangeUrl}/organization/${organizationId}/ipRange/${ipRangeId}`);
}
