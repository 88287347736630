import { useEcommerceTelemetry } from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useApolloTheme } from '@experiences/theme';
import {
    Chip,
    CircularProgress,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, {
    useEffect,
    useMemo,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { ILicenseAndKey } from '../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import {
    countrySelector,
    isAdminSelector,
    isUnlicensedSelector,
    profile,
} from '../../store/selectors';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import EcommerceLicenseInfo from '../ecommerce/subcomponents/EcommerceLicenseInfo';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import LicenseHeaderActionComponent from './LicenseHeaderActionComponent.default';
import Unlicensed from './Unlicensed';
import { UserLicensingTabs } from './UserLicensingTabs';
import { useShouldShowManageButton } from './useShouldShowManageButton';

const useStyles = makeStyles(theme =>
    createStyles({
        expiry: {
            marginTop: '8px',
            display: 'flex',
            minHeight: '28px',
            alignItems: 'center',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
        },
        chip: {
            background: `${theme.palette.semantic.colorBackgroundHigh} !important`,
            height: '24px',
            marginLeft: '4px',
        },
        chipLabel: { color: theme.palette.semantic.colorForegroundHigh },
    }),
);

export function Licenses() {
    const classes = useStyles();
    const { themeId } = useApolloTheme();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const logEcommerceEvent = useEcommerceTelemetry();

    const isAdmin = useSelector(isAdminSelector);
    const accountCountry = useSelector(countrySelector);
    const profileState = useSelector(profile);
    const organizationName = useOrganizationName();

    const EnableEcommerceIncremental = useFeatureFlagValue(Features.EnableEcommerceIncremental.name);

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);

    const ExpiryDate = useMemo(() => (license?.endDate ? new Date(license.endDate * 1000) : undefined), [ license ]);

    const shouldShowManageButton = useShouldShowManageButton(
        profileState.accountUserDto.accountLogicalName,
        license?.accountType,
    );

    const AccountPlan = useMemo(() => {
        switch (license?.accountType) {
            case 'PRO':
            case 'ENTERPRISE':
                return 'ENTERPRISE_PLAN';
            case 'TRIAL':
                return 'TRIAL_PLAN';
            case 'UNLICENSED':
                return 'UNLICENSED_PLAN';
            default:
                return 'COMMUNITY_PLAN';
        }
    }, [ license ]);

    const [ LicenseStatusOrPlan, ExpiryMessage ] = useMemo(() => {
        switch (license?.licenseStatus) {
            case 'EXPIRED':
                return [ 'LICENSE_EXPIRED', 'LICENSE_EXPIRED_DATE' ];
            default:
                return [ AccountPlan, 'SUBSCRIPTION_EXPIRY' ];
        }
    }, [ license, AccountPlan ]);

    useEffect(() => {
        if (license?.accountType && license.accountType !== profileState.accountUserDto.accountType) {
            mutate(licenseManagementAccountUrl);
        }
    }, [ license?.accountType, profileState ]);

    useEffect(() => {
        logEcommerceEvent('Licenses.Navigate', {
            IsAdmin: isAdmin,
            CloudOrganizationCountry: accountCountry,
        });
    }, [ logEcommerceEvent, isAdmin, accountCountry ]);

    const ecommerceAndExpiryComponent = useMemo(() => <>
        {EnableEcommerceIncremental ? (<>
            {shouldShowManageButton && license && (<EcommerceLicenseInfo />)}
        </>) : (
            <>
                {!shouldShowManageButton && (
                    <div className={classes.expiry}>
                        {isAdminRevampEnabled && <Chip
                            style={{ marginRight: '8px' }}
                            label={
                                <Typography
                                    data-cy="licenses-account-plan"
                                    className={classes.chipLabel}
                                    style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange300 : Tokens.Colors.ColorOrange700 }}
                                >
                                    {translate({ id: `CLIENT_${LicenseStatusOrPlan}` })}
                                </Typography>
                            }
                            className={classes.chip}
                        />}
                        <Typography>
                            {translate({ id: `CLIENT_${ExpiryMessage}` })}
                        </Typography>
                        <div style={{ marginLeft: '8px' }}>
                            {ExpiryDate ? (
                                <Typography data-cy="licenses-expiry-date">
                                    <FormattedDate
                                        value={Date.UTC(ExpiryDate.getUTCFullYear(), ExpiryDate.getUTCMonth(), ExpiryDate.getUTCDate())}
                                        year="numeric"
                                        month="short"
                                        day="numeric"
                                        timeZone="UTC"
                                    />
                                </Typography>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                        </div>
                    </div>
                )}
            </>)}
        {!EnableEcommerceIncremental || !shouldShowManageButton && (
            <div className={classes.expiry}>
                {isAdminRevampEnabled && <Chip
                    style={{ marginRight: '8px' }}
                    label={
                        <Typography
                            data-cy="licenses-account-plan"
                            className={classes.chipLabel}
                            style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange300 : Tokens.Colors.ColorOrange700 }}
                        >
                            {translate({ id: `CLIENT_${LicenseStatusOrPlan}` })}
                        </Typography>
                    }
                    className={classes.chip}
                />}
                <Typography>
                    {translate({ id: `CLIENT_${ExpiryMessage}` })}
                </Typography>
                <div style={{ marginLeft: '8px' }}>
                    {ExpiryDate ? (
                        <Typography data-cy="licenses-expiry-date">
                            <FormattedDate
                                value={Date.UTC(ExpiryDate.getUTCFullYear(), ExpiryDate.getUTCMonth(), ExpiryDate.getUTCDate())}
                                year="numeric"
                                month="short"
                                day="numeric"
                                timeZone="UTC"
                            />
                        </Typography>
                    ) : (
                        <CircularProgress size={20} />
                    )}
                </div>
            </div>
        )}
        <UserLicensingTabs />
    </>, [ ExpiryDate, ExpiryMessage, classes.expiry, shouldShowManageButton, translate, EnableEcommerceIncremental, license ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.Licensing,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                position='left'
                primaryActions={isAdmin && <LicenseHeaderActionComponent />}>
                {ecommerceAndExpiryComponent}
            </UiPageContainer> :
            <UiPanel
                licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={<UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={<UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={<UiOverAllocationBanner />}
                header={{
                    title: (
                        <div className={classes.header}>
                            <Typography
                                className={classes.headerText}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: 'CLIENT_LICENSES' })}
                            </Typography>
                            <Chip
                                label={
                                    <Typography
                                        data-cy="licenses-account-plan"
                                        className={classes.chipLabel}
                                        style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange300 : Tokens.Colors.ColorOrange700 }}
                                    >
                                        {translate({ id: `CLIENT_${LicenseStatusOrPlan}` })}
                                    </Typography>
                                }
                                className={classes.chip}
                            />
                        </div>
                    ),
                    actions: isAdmin && <LicenseHeaderActionComponent />,
                    disableGutter: true,
                }}
            >
                {ecommerceAndExpiryComponent}
            </UiPanel>
    );
}

export default ({ route }: { route?: any }) => {
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    return (
        <>
            {isUnlicensedMode ? <Unlicensed showSubscription /> : <Licenses />}
            {renderRoutes(route.routes)}
        </>
    );
};
