import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    UiStorage,
    useRouteResolver,
} from '@experiences/util';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Link,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { AzureADIntegrationLink } from '../../../common/constants/documentation/AzureADIntegrationDocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useLoginProvider } from '../../../common/hooks/useLoginProvider';
import { useStorageListener } from '../../../common/hooks/useStorageListener';
import {
    AuthenticationSettingType,
    authenticationSettingUrl,
    createAuthenticationSetting,
    createDirectoryIntegrationSetting,
    IAuthenticationSettingPayload,
    IAuthenticationSettingResponse,
    updateAuthenticationSetting,
    updateDirectoryIntegrationSetting,
} from '../../../services/identity/AuthenticationSettingService';
import {
    ITestConnectionResult,
    testConnection,
} from '../../../services/identity/TestConnectionService';
import {
    createUser,
    inviteUsers,
} from '../../../services/identity/UserService';
import {
    accountGlobalId,
    companyName,
    emailSelector,
    organizationLanguage,
} from '../../../store/selectors';
import { getNameFromEmail } from '../../../util/EmailUtil';
import {
    mapDirectoryUserDto,
    mapInviteUserDto,
} from '../../../util/UserGroupUtil';
import UiAlertBanner from '../../common/UiAlertBanner';
import UiForm from '../../common/UiForm';
import { UserGroup } from '../../common/UserGroups';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        button: { marginTop: '24px' },
        errorIcon: {
            color: theme.palette.semantic.colorErrorIcon,
            marginRight: '4px',
        },
        errorMessage: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            fontSize: '14px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        errorAlert: {
            width: 'fit-content',
            marginTop: '16px',
        },
        linkEmailsCheckbox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        footer: {
            position: 'fixed',
            bottom: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            height: '64px',
            width: 'calc(100% - max(min(312px, 20vw), 175px) - 68px)',
            marginLeft: '-24px',
            padding: '0px 24px',
            backgroundColor: theme.palette.semantic.colorBackground,
            zIndex: 1,
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
        deleteButton: {
            marginTop: 20,
            color: theme.palette.semantic.colorErrorText,
            width: 'fit-content',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important` },
            '&:focus': {
                color: `${theme.palette.semantic.colorErrorText} !important`,
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            },
        },
        learnMore: {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorPrimaryLighter,
        },
        lauchIcon: {
            fontSize: '14px',
            marginLeft: '4px',
            color: theme.palette.semantic.colorPrimaryLighter,
        },
        groupInfoIcon: {
            width: 18,
            height: 18,
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        centerLoader: {
            display: 'flex',
            justifyContent: 'center',
        },
    }),
}));

interface IAzureAADFormData {
    tenantID?: string;
    applicationID?: string;
    applicationSecret?: string;
    applicationCertificate?: any;
}

const defaultAzureAADFormData: IAzureAADFormData = {
    tenantID: '',
    applicationID: '',
    applicationSecret: '',
    applicationCertificate: '',
};

type TestConnectionStatus = 'undefined' | 'clicked' | 'success' | 'error' | 'loading' | 'retry';

const ExternalProvidersAADForm: React.FC<{
    authenticationSetting: IAuthenticationSettingResponse | undefined;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onError: (error: any) => Promise<void> | void;
    onClose?: () => void;
    enableProgressSymbol?: boolean;
    enableDeleteConfigurationButton?: boolean;
    showButtonFooter?: boolean;
}> = ({
    authenticationSetting,
    loading,
    setLoading,
    onError,
    onClose,
    enableProgressSymbol = false,
    showButtonFooter = false,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });
    const history = useHistory();
    const getRoute = useRouteResolver();

    const loginProvider = useLoginProvider();

    const cancel = useCallback(() => {
        history.push(getRoute(RouteNames.SecuritySettings));
    }, [ history, getRoute ]);

    const currentEmail = useSelector(emailSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgLanguage = useSelector(organizationLanguage);
    const orgName = useSelector(companyName);

    const EnableEnforceTestConnectionforAAD = useFeatureFlagValue(Features.EnableEnforceTestConnectionforAAD.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);

    const [ testConnectionState, setTestConnectionState ] = useState<TestConnectionStatus>(
        process.buildConfigs.disableTestConnection ? 'success' : 'undefined',
    );
    const [ testConnectionError, setTestConnectionError ] = useState('');
    const [ emailLinkingChecked, setEmailLinkingChecked ] = useState(false);
    const [ retryCount, setRetryCount ] = useState(() => 0);

    useEffect(() => {
        if (authenticationSetting) {
            setEmailLinkingChecked(
                authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AAD,
            );
        }
    }, [ authenticationSetting ]);

    const {
        control,
        handleSubmit,
        getValues,
        errors,
        formState,
        watch,
        reset,
        trigger,
    } = useForm<IAzureAADFormData>({
        mode: 'onChange',
        defaultValues: defaultAzureAADFormData,
    });

    const {
        isValid, isDirty,
    } = formState;

    useEffect(() => {
        const authority = authenticationSetting?.externalIdentityProviderDto?.authority;
        if (
            authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AAD
            && authority
        ) {
            const matchedValues = authority.match(/.com\/(.*)\/v/);
            reset({
                tenantID: (matchedValues && matchedValues.length > 1 && matchedValues[1]) || '',
                applicationID: authenticationSetting?.externalIdentityProviderDto.clientId,
                applicationSecret: authenticationSetting?.externalIdentityProviderDto.clientSecret,
            });
        }
    }, [ authenticationSetting, reset ]);

    const cleanupLocalStorage = useCallback(() => {
        UiStorage.removeItem('applicationID');
        UiStorage.removeItem('applicationSecret');
        UiStorage.removeItem('tenantID');
        UiStorage.removeItem('AAD_code');
    }, []);

    useEffect(() => {
        return () => cleanupLocalStorage();
    }, [ cleanupLocalStorage ]);

    const onSubmit = useCallback(
        async (data: IAzureAADFormData) => {
            setLoading(true);

            const payload: IAuthenticationSettingPayload = {
                type: AuthenticationSettingType.AAD,
                displayName: AuthenticationSettingType.AAD,
                partitionGlobalId,
                clientId: data.applicationID?.trim(),
                clientSecret: data.applicationSecret!.trim(),
                tenantId: data.tenantID?.trim(),
            };

            try {
                if (authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AAD) {
                    if (EnableSecuritySettingsRevamp) {
                        await updateDirectoryIntegrationSetting(payload);
                    } else {
                        await updateAuthenticationSetting(payload);
                    }
                } else {
                    if (EnableSecuritySettingsRevamp) {
                        await createDirectoryIntegrationSetting(payload);
                    } else {
                        await createAuthenticationSetting(payload);
                    }
                }
                enqueueSnackbar(
                    translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_SUCCESSFULLY_CHANGED' }),
                    { variant: notificationType.SUCCESS } as any,
                );
                mutate([ authenticationSettingUrl, partitionGlobalId ]);
                onClose?.();
                if (EnableSecuritySettingsRevamp) {
                    history.push(getRoute(RouteNames.SecuritySettings));
                }
            } catch (error) {
                await onError(error);
            } finally {
                setLoading(false);
            }
        },
        [
            setLoading,
            partitionGlobalId,
            authenticationSetting?.authenticationSettingType,
            enqueueSnackbar,
            translate,
            onClose,
            EnableSecuritySettingsRevamp,
            history,
            getRoute,
            onError,
        ],
    );

    const updateTestConnectionState = useCallback(async () => {
        // Limit the number of requests to 1.
        if (testConnectionState !== 'clicked' && testConnectionState !== 'retry') {
            return;
        }

        const code = UiStorage.getItem('AAD_code') ?? '';

        // Not combined with above if to prevent showing error messages when the request is pending.
        if (!code) {
            const errorIfOverRetry = retryCount >= 5;
            setTestConnectionState(errorIfOverRetry ? 'error' : 'retry');
            if (errorIfOverRetry) {
                setRetryCount(0);
            } else {
                setRetryCount(count => count + 1);
            }
            setTestConnectionError(translate({ id: 'CLIENT_TEST_CONNECTION_ERROR_TOKEN' }));
            return;
        }

        let response: ITestConnectionResult | undefined = undefined;

        try {
            setTestConnectionState('loading');
            response = await testConnection({
                client_id: getValues('applicationID') ?? '',
                code,
                redirect_uri: encodeURI(`${window.location.origin}/portal_/testconnection`),
                client_secret: getValues('applicationSecret')!,
                tenant_id: getValues('tenantID') ?? '',
            });

            if (!response) {
                throw new Error();
            }
        } catch (error) {
            const errorDescription = (error as any)?.response?.data?.data?.error_description
                || translate({ id: 'CLIENT_TEST_CONNECTION_ERROR_TOKEN' });
            setTestConnectionState('error');
            setTestConnectionError(errorDescription);
            return;
        }

        const emailAddress = response?.email?.toLowerCase();
        const userAction = !DisableFeatureFedRamp && process.buildConfigs.inviteUserOnTestConnection ? 'invite' : 'create';
        const userShouldBeAdded = emailAddress != null && emailAddress !== currentEmail.toLowerCase();

        if (!userShouldBeAdded) {
            setTestConnectionState('success');
            return;
        }

        try {
            if (!DisableFeatureFedRamp && process.buildConfigs.inviteUserOnTestConnection) {
                await inviteUsers(
                    mapInviteUserDto(
                        {
                            userName: getNameFromEmail(emailAddress),
                            email: [ emailAddress ],
                            groupIds: { [UserGroup.Administrators]: true },
                        },
                    ),
                    loginProvider,
                    orgLanguage,
                    orgName,
                );
            } else {
                await createUser(
                    mapDirectoryUserDto(
                        {
                            email: emailAddress,
                            groupIds: { [UserGroup.Administrators]: true },
                        },
                        partitionGlobalId,
                    ),
                );
            }

            setTestConnectionState('success');
            await trigger(); // Forces the form to revalidate (so that the submit button becomes enabled)

            // Used for the new security settings page, when the Test Connection button and Save button are one
            if (showButtonFooter && EnableEnforceTestConnectionforAAD) {
                await onSubmit(watch());
            }

            enqueueSnackbar(
                translate({ id: userShouldBeAdded ? 'CLIENT_TEST_CONNECTION_ADD_USER_SUCCESS' : 'CLIENT_TEST_CONNECTION_SUCCESS' }),
                { variant: notificationType.SUCCESS } as any,
            );
        } catch (error) {
            setTestConnectionState('error');
            setTestConnectionError(translate(
                { id: 'CLIENT_AAD_INVITE_FAILURE' },
                {
                    userAction,
                    emailAddress,
                }));
        }
    }, [
        testConnectionState,
        DisableFeatureFedRamp,
        currentEmail,
        retryCount,
        translate,
        getValues,
        trigger,
        showButtonFooter,
        EnableEnforceTestConnectionforAAD,
        enqueueSnackbar,
        loginProvider,
        orgLanguage,
        orgName,
        partitionGlobalId,
        onSubmit,
        watch,
    ]);

    useStorageListener(updateTestConnectionState);

    const disableSubmit = useMemo(
        () =>
            !emailLinkingChecked ||
            !isDirty ||
            !isValid ||
            (EnableEnforceTestConnectionforAAD && testConnectionState !== 'success'),
        [ EnableEnforceTestConnectionforAAD, emailLinkingChecked, isDirty, isValid, testConnectionState ],
    );

    const loginWithAAD = useMemo(() => {
        const applicationID = watch('applicationID')?.trim();
        const tenantID = watch('tenantID')?.trim();
        const returnUrl = encodeURI(`${window.location.origin}/portal_/testconnection`);

        const params = {
            prompt: 'select_account',
            response_type: 'code',
            redirect_uri: returnUrl,
            state: 'h0QLkxNM6fMx4sfY8LYOFYSdaXtoab0v',
            client_id: applicationID ?? '',
        };

        const search = new URLSearchParams(params);

        const scopes = [ 'email', 'GroupMember.Read.All', 'openid', 'profile', 'User.Read', 'User.ReadBasic.All' ];

        return `https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize?scope=${scopes.join(
            '+',
        )}+&${search.toString()}`;
    }, [ watch ]);

    return (
        <>
            {enableProgressSymbol ? (
                <div className={classes.centerLoader}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {showButtonFooter && !process.buildConfigs.disableTestConnection && testConnectionState === 'error' && (
                        <div className={classes.errorAlert}>
                            <UiAlertBanner
                                closeable={true}
                                type="error">
                                {`${translate({ id: 'CLIENT_AZURE_TEST_CONNECTION_FAILED' })}: ${testConnectionError}`}
                            </UiAlertBanner>
                        </div>
                    )}
                    {DisableFeatureFedRamp && (
                        <portal-alert-bar cancelable={false}>
                            <Typography>
                                {translate({ id: 'CLIENT_AZURE_AD_PUBLIC_INFO' })}
                            </Typography>
                            &nbsp;
                            <a
                                className={classes.a}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://docs.microsoft.com/en-us/azure/azure-government/documentation-government-plan-identity#choosing-your-identity-authority"
                            >
                                {translate({ id: 'CLIENT_MORE_INFO' })}
                            </a>
                        </portal-alert-bar>
                    )}
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className={clsx(classes.input)}>
                            <Controller
                                as={TextField}
                                control={control}
                                rules={{ required: true }}
                                error={!!errors.tenantID}
                                label={translate({ id: 'CLIENT_AZURE_TENANT_ID' })}
                                placeholder={translate({ id: 'CLIENT_AZURE_TENANT_ID_PLACEHOLDER' })}
                                helperText={
                                    (
                                        errors.tenantID?.type === 'required'
                                        && translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_AZURE_TENANT_ID' }) },
                                        )
                                    )
                                    || (
                                        errors.tenantID?.type === 'invalid'
                                        && translate({ id: 'CLIENT_WARN_NAME' }, { 0: 32 })
                                    )
                                }
                                name="tenantID"
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{ 'data-cy': 'azure-tenant-id-field' }}
                                required
                            />
                        </div>
                        <div className={clsx(classes.input)}>
                            <Controller
                                as={TextField}
                                control={control}
                                rules={{ required: true }}
                                error={!!errors.applicationID}
                                label={translate({ id: 'CLIENT_AZURE_APPLICATION_ID' })}
                                placeholder={translate({ id: 'CLIENT_AZURE_APPLICATION_ID_PLACEHOLDER' })}
                                helperText={
                                    (
                                        errors.tenantID?.type === 'required'
                                    && translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_AZURE_APPLICATION_ID' }) },
                                    )
                                    )
                                    || (
                                        errors.tenantID?.type === 'invalid'
                                        && translate({ id: 'CLIENT_WARN_NAME' }, { 0: 32 })
                                    )
                                }
                                name="applicationID"
                                variant="outlined"
                                fullWidth
                                size="small"
                                inputProps={{ 'data-cy': 'azure-application-id-field' }}
                                required
                            />
                        </div>
                        <div className={clsx(classes.input)}>
                            <Controller
                                as={TextField}
                                control={control}
                                rules={{ required: true }}
                                error={!!errors.applicationSecret}
                                label={translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET' })}
                                placeholder={translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET_PLACEHOLDER' })}
                                helperText={
                                    (
                                        errors.tenantID?.type === 'required'
                                        && translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET' }) },
                                        )
                                    )
                                    || (
                                        errors.tenantID?.type === 'invalid'
                                        && translate({ id: 'CLIENT_WARN_NAME' }, { 0: 32 })
                                    )
                                }
                                name="applicationSecret"
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                size="small"
                                inputProps={{ 'data-cy': 'azure-application-secret-field' }}
                                style={{ marginBottom: '20px' }}
                                required
                            />
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={emailLinkingChecked}
                                    onChange={async e => {
                                        setEmailLinkingChecked(e.target.checked);
                                        await trigger();
                                    }}
                                    data-cy="link-email-checkbox"
                                />
                            }
                            label={
                                <div className={classes.linkEmailsCheckbox}>
                                    <Typography>
                                        {translate({
                                            id: process.buildConfigs.disableUserInvite
                                                ? 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_ADD'
                                                : 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_INVITE',
                                        })}
                                    </Typography>
                                    {!EnableSecuritySettingsRevamp && <Tooltip
                                        style={{ marginLeft: '4px' }}
                                        title={
                                            <Typography color="inherit">
                                                {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP' })}
                                                <a
                                                    className={clsx(classes.learnMore, classes.a)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={getLocalizedLink(AzureADIntegrationLink)}
                                                >
                                                    {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                                    <LaunchIcon className={classes.lauchIcon} />
                                                </a>
                                            </Typography>
                                        }
                                    >
                                        <a
                                            className={classes.a}
                                            href={getLocalizedLink(AzureADIntegrationLink)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label={translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                        >
                                            <IconButton>
                                                <HelpOutlineIcon color="primary" />
                                            </IconButton>
                                        </a>
                                    </Tooltip>}
                                </div>
                            }
                        />
                        {!showButtonFooter
                            ? (
                                <div>
                                    {!process.buildConfigs.disableTestConnection && (
                                        <UiProgressButton
                                            className={clsx(classes.button)}
                                            component={Link}
                                            loading={testConnectionState === 'loading'}
                                            disabled={!watch('applicationID')
                                            || !watch('tenantID')
                                            || !watch('applicationSecret')
                                            || watch('applicationSecret')?.includes('*****')}
                                            target="popup"
                                            onClick={() => {
                                                setTestConnectionState('clicked');
                                                window.open(loginWithAAD, 'popup', 'width=600, height=600');
                                            }}
                                            data-cy="azure-test-connection-button"
                                        >
                                            {translate({ id: 'CLIENT_AZURE_TEST_CONNECTION' })}
                                            {testConnectionState === 'success' && <CheckIcon />}
                                        </UiProgressButton>
                                    )}
                                    {!process.buildConfigs.disableTestConnection && testConnectionState === 'error' && (
                                        <div className={classes.errorMessage}>
                                            <ErrorIcon className={classes.errorIcon} />
                                            {`${translate({ id: 'CLIENT_AZURE_TEST_CONNECTION_FAILED' })}: ${testConnectionError}`}
                                        </div>
                                    )}
                                    <UiProgressButton
                                        type="submit"
                                        loading={loading}
                                        disabled={disableSubmit}
                                        variant="contained"
                                        className={clsx(classes.button)}
                                        data-cy="azure-submit-button"
                                    >
                                        {translate({ id: 'CLIENT_SAVE' })}
                                    </UiProgressButton>
                                </div>
                            )
                            : (
                                <div className={classes.footer}>
                                    <Button
                                        className={classes.footerButton}
                                        onClick={cancel}>
                                        {translate({ id: 'CLIENT_CANCEL' })}
                                    </Button>
                                    <UiProgressButton
                                        className={classes.footerButton}
                                        component={Link}
                                        loading={testConnectionState === 'loading' || loading}
                                        disabled={!watch('applicationID') || !watch('tenantID') || !watch('applicationSecret')}
                                        target="popup"
                                        variant="contained"
                                        onClick={() => {
                                            setTestConnectionState('clicked');
                                            window.open(loginWithAAD, 'popup', 'width=600, height=600');
                                        }}
                                        data-cy="azure-test-and-save-button"
                                    >
                                        {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                                    </UiProgressButton>
                                </div>
                            )}
                    </UiForm>
                </>
            )}
        </>
    );
};

export default ExternalProvidersAADForm;
