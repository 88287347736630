import { Theme } from '@mui/material';
import MuiLinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import {
    createStyles,
    withStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const commonStyles = (theme: Theme) => ({
    root: {
        height: 4,
        borderRadius: 4,
        marginTop: '10px',
    },
    colorPrimary: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
    bar: {
        borderRadius: 4,
        backgroundColor: theme.palette.semantic.colorPrimary,
    },
});
const RegularLinearProgress = withStyles(theme => createStyles(commonStyles(theme)))(MuiLinearProgress);

const OverAllocatedLinearProgress = withStyles(theme =>
    createStyles({
        ...commonStyles(theme),
        colorPrimary: { backgroundColor: theme.palette.semantic.colorErrorIcon },
        bar: { backgroundColor: theme.palette.semantic.colorErrorIcon },
    }),
)(MuiLinearProgress);

export const BorderLinearProgress: React.FC<{
    consumed: number;
    total: number;
}> = ({
    consumed, total,
}) => {
    const {
        formatMessage: translate, formatNumber,
    } = useIntl();

    const tooltipText = useMemo(() => {
        return translate(
            { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
            {
                0: formatNumber(consumed),
                1: formatNumber(total),
            },
        );
    }, [ translate, formatNumber, consumed, total ]);

    const percentage = useMemo(() => (consumed > total ? 101 : Math.round((consumed / total) * 100)), [ consumed, total ]);

    return (
        <Tooltip
            title={tooltipText}
            aria-label={tooltipText}>
            {percentage > 100 ? (
                <OverAllocatedLinearProgress
                    variant="determinate"
                    value={percentage} />
            ) : (
                <RegularLinearProgress
                    variant="determinate"
                    value={percentage} />
            )}
        </Tooltip>
    );
};
