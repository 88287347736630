import { UiDialog } from '@experiences/ui-common';
import React, {
    useCallback,
    useContext,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

export const CentralErrorHandlingContext = React.createContext<{
    showError: (error: any) => void;
}>({ showError: () => {} });

export const useCentralErrorSetter = () => {
    const { showError } = useContext(CentralErrorHandlingContext);
    return showError;
};

export const CentralErrorHandlingProvider: React.FC = props => {
    const { formatMessage: translate } = useIntl();

    const [ error, setError ] = useState(null);
    const showError = useCallback(
        (error: any) => {
            setError(error);
        },
        [ setError ],
    );

    return (
        <>
            {error && (
                <UiDialog
                    close={() => {
                        setError(null);
                    }}
                    title={translate({ id: 'CLIENT_ERROR' })}
                >
                    {error}
                </UiDialog>
            )}
            <CentralErrorHandlingContext.Provider
                value={{ showError: showError }}
            >
                {props.children}
            </CentralErrorHandlingContext.Provider>
        </>
    );
};
