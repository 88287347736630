import { ENVIRONMENT } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { getEnvironment } from '@experiences/util';
import {
    InMemoryWebStorage,
    UserManagerSettings,
    WebStorageStateStore,
} from 'oidc-client-ts';
import { useMemo } from 'react';

import {
    CISAuthority,
    CISHostConnect,
    CISOpenIdConfiguration,
} from './AuthDomainResolver';

const EnableInMemoryStorage = getFeatureFlagValue(Features.EnableInMemoryStorage.name);

export const CISClientId = '6e80a74c-0878-4517-9b94-5283859df42f';

const localMetadata = {
    issuer: CISAuthority,
    jwks_uri: `${CISAuthority}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${CISHostConnect}/authorize`,
    token_endpoint: `${CISHostConnect}/token`,
    userinfo_endpoint: `${CISHostConnect}/userinfo`,
    end_session_endpoint: `${CISHostConnect}/endsession`,
    check_session_iframe: `${CISHostConnect}/checksession`,
    revocation_endpoint: `${CISHostConnect}/revocation`,
    introspection_endpoint: `${CISHostConnect}/introspect`,
};

export const useAuthConfiguration: () => UserManagerSettings = () => {
    const metadataConfig = useMemo(() => {
        const env = getEnvironment();
        return env === ENVIRONMENT.LOCAL ? { metadata: localMetadata } : { metadataUrl: CISOpenIdConfiguration };
    }, []);

    const redirectUri = useMemo(() => {
        const accountName = window.location.pathname.split('/')[1];
        const isValidAccountName = accountName && !accountName.includes('portal_');
        return `${window.location.origin}/${isValidAccountName ? accountName + '/' : ''}portal_/loginsuccess`;
    }, []);

    return {
        authority: CISAuthority,
        client_id: CISClientId,
        post_logout_redirect_uri: `${window.location.origin}/portal_/cloudrpa`,
        response_type: 'code',
        redirect_uri: redirectUri,
        silent_redirect_uri: `${window.location.origin}/portal_/loginsuccess`,
        scope:
      'openid profile email IdentityServerApi OrchestratorApiUserAccess JamJamApi ReferenceToken offline_access',
        loadUserInfo: false,
        automaticSilentRenew: true,
        stopCheckSessionOnError: true,
        monitorSession: true,
        // To test PKCE, set accessTokenExpiringNotificationTime: 300
        userStore: new WebStorageStateStore({ store: EnableInMemoryStorage ? new InMemoryWebStorage() : sessionStorage }),
        ...metadataConfig,
    };
};
