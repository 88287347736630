import { CircularProgress } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';

import {
    INotificationMode,
    INotificationTopicGroup,
} from './interfaces/notificationSettings';
import NotificationTopicComponent from './NotificationTopicComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        topicsContainer: {
            overflowY: 'auto',
            height: 'calc(100vh - 277px)',
            '&::-webkit-scrollbar': { width: '18px' },
            '&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                borderRadius: '10px',
                border: '6px solid transparent',
                backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        },
        topicsContainerLoader: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 255px)',
        },
    }),
);

const NotificationTopicsContainerComponent: React.FC<{
    notificationChannelModes: INotificationMode[];
    notificationTopics: INotificationTopicGroup[];
    changeTopicSubscription(groupIndex: number, topicIndex: number, isSubscribed: boolean): void;
    toggleAllTopicSubscription(groupIndex: number, isSubscribed: boolean): void;
    isNotificationTopicsContainerUpdating: boolean | undefined;
}> = ({
    notificationChannelModes,
    notificationTopics,
    changeTopicSubscription,
    toggleAllTopicSubscription,
    isNotificationTopicsContainerUpdating,
}) => {
    const classes = useStyles();

    const allNotificationChannelsDisabled = useMemo(
        () => !notificationChannelModes.some((mode: INotificationMode) => mode.isActive),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ JSON.stringify(notificationChannelModes) ],
    );

    return (
        <>
            {!isNotificationTopicsContainerUpdating ? (
                <div className={classes.topicsContainer}>
                    {notificationTopics?.map((topic: INotificationTopicGroup, index: number) => (
                        <NotificationTopicComponent
                            key={index}
                            notificationTopic={topic}
                            allNotificationChannelsDisabled={allNotificationChannelsDisabled}
                            changeTopicSubscription={(topicIndex: number, isSubscribed: boolean) =>
                                changeTopicSubscription(topic.groupIndex, topicIndex, isSubscribed)}
                            toggleAllTopicSubscription={(isSubscribed: boolean) =>
                                toggleAllTopicSubscription(topic.groupIndex, isSubscribed)}
                        />
                    ))}
                </div>
            ) : (
                <div className={classes.topicsContainerLoader}>
                    <CircularProgress
                        thickness={2}
                        size={36} />
                </div>
            )}
        </>
    );
};

export default NotificationTopicsContainerComponent;
