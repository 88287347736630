import { useRouteResolver } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

export type SecondaryNavPage = {
    selected: boolean;
    text: string;
    route: string;
    isLink?: boolean;
    subRoutes?: string[];
};

export interface ISecondaryNavItem {
    id: string;
    text: string;
    selected?: boolean;
}

export const SecondaryNav: React.FC<{ pages: SecondaryNavPage[] }> = ({ pages }) => {
    const { formatMessage: translate } = useIntl();

    const getRoute = useRouteResolver();
    const history = useHistory();

    const secondaryNavElement = useRef<HTMLPortalSecondaryNavElement>(null);
    const [ active, setActive ] = useState('');

    const items = useMemo(() => {
        const itemArray: ISecondaryNavItem[] = [];

        pages.forEach((page, index) => {
            if (page.selected) {
                setActive(`${index}`);
            }
            itemArray.push({
                id: `${index}`,
                text: translate({
                    id: page.text,
                    defaultMessage: page.text,
                }),
            });
        });
        return itemArray;
    }, [ pages, translate ]);

    const handleNavMenuItemClick = useCallback(
        (event: any) => {
            const page = pages[Number.parseInt(event.detail.id)];

            if (page.isLink) {
                window.location.assign(getRoute(page.route));
            } else {
                history.push(getRoute(page.route));
            }
        },
        [ getRoute, history, pages ],
    );

    useEffect(() => {
        const { current } = secondaryNavElement;
        current?.addEventListener('secondaryNavMenuItemClick', handleNavMenuItemClick);
        return () => {
            current?.removeEventListener('secondaryNavMenuItemClick', handleNavMenuItemClick);
        };
    }, [ handleNavMenuItemClick ]);

    return (
        <portal-secondary-nav
            ref={secondaryNavElement}
            items={JSON.stringify(items)}
            active={active}
            footer={
                window.env?.CLOUDRPA_VERSION ? `${translate({ id: 'CLIENT_VERSION' })} ${window.env?.CLOUDRPA_VERSION}` : ''
            }
        />
    );
};
