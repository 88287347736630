import { UiLoader } from '@experiences/ui-common';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Redirect } from 'react-router';

export default function AuthCallback() {
    const { error } = useAuth();

    if (error) {
        return (
            <Redirect to={{
                pathname: '/portal_/autherror',
                state: { error },
            }} />
        );
    }

    return <UiLoader type="full" />;
}
