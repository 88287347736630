import { SubscriptionType } from '@experiences/constants';
import { useIsDirectBuyInProgressFlow } from '@experiences/ecommerce';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router';

import {
    accountLogicalName,
    accountType,
    isAdminSelector,
} from '../../store/selectors';
import CannotUpgradeOrganizationDialog from './CannotUpgradeOrganizationDialog';
import OrganizationMismatchDialog from './OrganizationMismatchDialog';

const OrganizationSwitcherDialogComponent: React.FC = () => {
    const location = useLocation();
    const accountNameInStore = useSelector(accountLogicalName);
    const { accountName } = useParams<{ accountName: string }>();
    const isAdmin = useSelector(isAdminSelector);
    const subscriptionType = useSelector(accountType);
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();

    const isOrganizationMismatch = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const isOrganizationSettingsUpdate = params.get('isOrgNameUpdate');
        return accountName.toLowerCase() !== accountNameInStore?.toLowerCase() && !isOrganizationSettingsUpdate;
    }, [ accountNameInStore, accountName, location.search ]);

    const isDirectBuyUpgradeError = useMemo(() => {
        const sub = subscriptionType.toLowerCase();
        return isDirectBuyInProgressFlow && (!isAdmin || (sub !== SubscriptionType.COMMUNITY && sub !== SubscriptionType.TRIAL));
    }, [ isAdmin, subscriptionType, isDirectBuyInProgressFlow ]);

    return isOrganizationMismatch ? (
        <OrganizationMismatchDialog />
    ) : isDirectBuyUpgradeError ? (
        <CannotUpgradeOrganizationDialog isAdmin={isAdmin} />
    ) : null;
};
export default OrganizationSwitcherDialogComponent;
