import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export function useRouteResolver() {
    const { accountName } = useParams<{ accountName: string }>();

    return useCallback(
        (route: string, customAccountName?: string) => route.replace(':accountName', customAccountName || accountName),
        [ accountName ],
    );
}
