import {
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../../tenants/TenantConstants';

const useStyles = makeStyles<Theme>(theme =>
    createStyles({
        card: { position: 'relative' },
        cardContent: {
            padding: '12px 24px 16px 24px',
            '&:last-child': { paddingBottom: '36px' },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '8px 0 16px 0',
        },
        titleText: {
            marginLeft: '8px',
            fontWeight: 600,
            fontSize: '16px',
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '12px',
        },
        chip: {
            marginLeft: '12px',
            fontSize: '12px',
            fontWeight: 600,
        },
        disabledChip: {
            backgroundColor: `${theme.palette.semantic.colorBackgroundDisabled} !important`,
            color: `${theme.palette.semantic.colorForeground} !important`,
        },
        errorChip: {
            backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            color: `${theme.palette.semantic.colorErrorText} !important`,
        },
        errorText: { color: theme.palette.semantic.colorErrorText },
        errorBorder: { border: `1px solid ${theme.palette.semantic.colorErrorText} !important` },
        loadingText: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 600,
            marginLeft: '12px',
        },
        cardAction: {
            display: 'flex',
            justifyItems: 'space-between',
            padding: '4px 0px',
        },
        actions: {
            position: 'absolute',
            bottom: '12px',
            right: '5px',
        },
        statusChip: {
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            minHeight: '36px',
            verticalAlign: 'middle',
        },
        disabled: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
        },
        disabledText: { color: theme.palette.semantic.colorForegroundDisable },
    }),
);

export const UiServiceCard: React.FC<{
    title: string;
    titleIcon: ReactElement<any, any>;
    description: string;
    status: string;
    region?: string;
    dataCy: string;
    actions: ReactElement<any, any>;
    retry?: React.ReactNode;
    sx?: SxProps;
    style?: React.CSSProperties;
}> = ({
    title, titleIcon, description, status, region, dataCy, actions, retry, sx, style,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <Card
            className={clsx({
                [classes.card]: true,
                [classes.errorBorder]: status === TenantStatusConstants.FAILED,
                [classes.disabled]: status === TenantStatusConstants.DISABLED,
            })}
            sx={sx}
            style={style}
            data-cy={dataCy}
            aria-label="ui-service-card">
            <CardContent classes={{ root: classes.cardContent }}>
                <div className={classes.titleSection}>
                    {titleIcon}
                    <Typography
                        className={clsx({
                            [classes.titleText]: true,
                            [classes.errorText]: status === TenantStatusConstants.FAILED,
                            [classes.disabledText]: status === TenantStatusConstants.DISABLED,
                        })}
                        component="div">
                        {title}
                    </Typography>
                    {region && typeof region === 'string' && <Chip
                        size='small'
                        clickable={false}
                        className={clsx(classes.chip, 'info-mini')}
                        label={translate({ id: `CLIENT_${region.toUpperCase()}` })}
                        data-cy='service-card-region-chip' />}
                </div>
                <Typography
                    className={clsx({
                        [classes.descriptionText]: true,
                        [classes.disabledText]: status === TenantStatusConstants.DISABLED,
                    })}>
                    {description}
                </Typography>
            </CardContent>
            <CardActions className={classes.cardAction}>
                <div className={classes.statusChip}>
                    {(status === TenantStatusConstants.DISABLED || status === TenantStatusConstants.FAILED)
                        && <Chip
                            size='small'
                            clickable={false}
                            className={clsx({
                                [classes.chip]: true,
                                [classes.disabledChip]: status === TenantStatusConstants.DISABLED,
                                [classes.errorChip]: status === TenantStatusConstants.FAILED,
                            })}
                            label={translate({ id: `CLIENT_${status}` })}
                            data-cy='service-card-status-chip' />}
                    {status === TenantStatusConstants.FAILED && retry}
                    {(status === TenantStatusConstants.ENABLE_STATUS_INPROGRESS
                        || status === TenantStatusConstants.DISABLE_STATUS_INPROGRESS
                        || status === TenantStatusConstants.UPDATING)
                        && <Typography className={classes.loadingText}>
                            <CircularProgress
                                size={20}
                                style={{ marginRight: '8px' }} />
                            {translate({ id: `CLIENT_${status}` })}
                        </Typography>}
                </div>
                <div className={classes.actions}>
                    {actions}
                </div>
            </CardActions>
        </Card>
    );
};
