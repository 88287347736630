import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { ConcurrentUserLicenses } from '../../../common/constants/Constant';
import {
    getSortedUserBundleConfigurations,
    IProductConfiguration,
} from '../../../common/constants/LicensingConfig';
import * as RouteNames from '../../../common/constants/RouteNames';
import {
    IGroupInheritance,
    IUserLicenseAllocation,
} from '../../users/interfaces/userLicense';
import {
    IUserLicenseExplicitAllocation,
    LicenseAllocationMethod,
} from './EditUserAllocationComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        checkboxLabel: {
            marginTop: '2px',
            marginBottom: 0,
            alignItems: 'flex-start',
        },
        checkBox: { paddingTop: '4px' },
        checkboxText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '2px',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tooltipIcon: {
            padding: '3px',
            marginLeft: '4px',
        },
        explicitAllocationSection: { marginLeft: '28px' },
        radioButtonLabel: { marginBottom: 0 },
        viewDetails: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontSize: '12px',
            '&:hover': { textDecoration: 'underline' },
        },
        inheritedSublabel: {
            marginLeft: '32px',
            lineHeight: '12px',
        },
        overallocatedInfo: {
            color: theme.palette.semantic.colorForegroundDisable,
            lineHeight: '16px',
            fontSize: '12px',
        },
        overallocatedWarning: {
            color: theme.palette.semantic.colorErrorText,
            lineHeight: '16px',
            fontSize: '12px',
        },
        overallocatedIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        overallocatedContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        userBundleLicensesError: {
            color: theme.palette.semantic.colorErrorText,
            marginTop: '4px',
            fontSize: '12px',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '6px',
        },
    }),
}));

type ILicenseGenericWithCode = { code: string } & Partial<IUserLicenseAllocation>;

const EnableExternalUserLicense = getFeatureFlagValue(Features.EnableExternalUserLicense.name);

export const LicensingAllocationFormComponent: React.FC<{
    availableUserBundles?: ILicenseGenericWithCode[];
    allocatedUserBundles?: string[];
    groupLicenseInheritance?: IGroupInheritance[];
    userId?: string;
    withRadioGroup?: boolean;
    isAllocationForAGroup?: boolean;
}> = ({
    availableUserBundles,
    allocatedUserBundles,
    groupLicenseInheritance,
    userId,
    withRadioGroup,
    isAllocationForAGroup,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const getRoute = useRouteResolver();

    const sortedAndValidUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);

    const sortingFunction = useCallback(
        (a, b) => {
            return sortedAndValidUserBundleCodes.indexOf(a.code) - sortedAndValidUserBundleCodes.indexOf(b.code);
        },
        [ sortedAndValidUserBundleCodes ],
    );

    const { formatMessage: translate } = useIntl();
    const {
        getValues, setValue, watch, control, errors,
    } = useFormContext<IUserLicenseExplicitAllocation>();
    const allUserBundles = useMemo(() => {
        const availableUserBundleCodes = availableUserBundles?.map(u => u.code);
        const unavailableUserBundleCodes = allocatedUserBundles?.filter(
            x => availableUserBundleCodes?.indexOf(x) === -1,
        );
        const unavailableLicenses = unavailableUserBundleCodes?.map(
            l => ({
                code: l,
                allocated: 0,
                total: 0,
            } as ILicenseGenericWithCode),
        );

        if (availableUserBundles && unavailableLicenses) {
            const unsortedBundles = [ ...availableUserBundles, ...unavailableLicenses ];
            return unsortedBundles.sort(sortingFunction);
        }
        return availableUserBundles?.sort(sortingFunction);
    }, [ availableUserBundles, allocatedUserBundles, sortingFunction ]);
    const handleCheck = useCallback(
        (checkedId: string) => {
            const { userBundleLicenses: ids } = getValues();
            return ids.indexOf(checkedId) > -1 ? ids.filter(id => id !== checkedId) : [ ...(ids ?? []), checkedId ];
        },
        [ getValues ],
    );

    const userProducts = useMemo(() => getSortedUserBundleConfigurations(), []) as IProductConfiguration[];

    const handleSwitch = useCallback(
        (checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue('userBundleLicenses', [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const [ renderExplicitAllocations, renderGroupInheritanceText ] = useMemo(() => {
        const method = watch('licenseAllocationMethod');
        return [
            method === LicenseAllocationMethod.EXPLICIT || !withRadioGroup,
            method === LicenseAllocationMethod.GROUP,
        ];
    }, [ watch, withRadioGroup ]);

    const disableCheckbox = useCallback(
        (userBundle: ILicenseGenericWithCode) =>
            (EnableExternalUserLicense && watch('useExternalLicense')) ||
            (!isAllocationForAGroup &&
                !(ConcurrentUserLicenses.indexOf(userBundle.code) > -1) &&
                userBundle.allocated !== undefined &&
                userBundle.total !== undefined &&
                userBundle.allocated >= userBundle.total &&
                allocatedUserBundles!.indexOf(userBundle.code) === -1),
        [ allocatedUserBundles, watch, isAllocationForAGroup ],
    );

    const licenseInheritanceText = useMemo(
        () =>
            groupLicenseInheritance?.length
                ? groupLicenseInheritance
                    .map(license => {
                        const names = license.useExternalLicense
                            ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                            : license.userBundleCodes.map(code => translate({ id: `CLIENT_${code}` })).join(', ');
                        return translate({ id: 'CLIENT_LICENSE_INHERITED_FROM' }, {
                            0: names,
                            1: license.group,
                        });
                    })
                    .join('; ')
                : undefined,
        [ groupLicenseInheritance, translate ],
    );

    const orgHasUserLicenses = useMemo(
        () => (allUserBundles?.length ?? 0) + (allocatedUserBundles?.length ?? 0) > 0,
        [ allUserBundles, allocatedUserBundles ],
    );

    return (
        <div>
            {withRadioGroup && (
                <Controller
                    as={
                        <RadioGroup
                            aria-labelledby="licenseAllocationMethodLabel"
                            style={{ color: theme.palette.semantic.colorForeground }}
                        >
                            <FormControlLabel
                                value={LicenseAllocationMethod.GROUP}
                                control={<Radio color="primary" />}
                                label={
                                    <div
                                        className={classes.switchLabel}
                                        id="licenseAllocationMethodLabel">
                                        {translate({ id: 'CLIENT_GROUP_ALLOCATION' })}
                                        <Tooltip title={translate({ id: 'CLIENT_USE_GROUP_ALLOCATION_RULE' })}>
                                            <InfoOutlined className={clsx(classes.tooltipIcon, classes.icon)} />
                                        </Tooltip>
                                    </div>
                                }
                                data-cy="invite-edit-group-allocation"
                                className={classes.radioButtonLabel}
                            />
                            {renderGroupInheritanceText && groupLicenseInheritance && (
                                <div className={classes.inheritedSublabel}>
                                    <Typography
                                        display="inline"
                                        style={{ fontSize: '12px' }}>
                                        {groupLicenseInheritance.length > 0
                                            ? licenseInheritanceText
                                            : translate({ id: 'CLIENT_NO_LICENSE_INHERITANCE' })}
                                    </Typography>
                                    &nbsp;
                                    <Typography
                                        display="inline"
                                        className={classes.viewDetails}
                                        onClick={() =>
                                            history.push({
                                                pathname: getRoute(
                                                    history.location.pathname ===
                                                        getRoute(RouteNames.UsersUserEditLicenseAllocation)
                                                        ? RouteNames.UsersUserLicenseInheritance
                                                        : RouteNames.LicensingUserLicenseInheritance,
                                                ),
                                                state: { userId },
                                            })}
                                        data-cy="view-details"
                                    >
                                        {translate({ id: 'CLIENT_VIEW_DETAILS' })}
                                    </Typography>
                                </div>
                            )}
                            <FormControlLabel
                                value={LicenseAllocationMethod.EXPLICIT}
                                control={<Radio color="primary" />}
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_ALLOCATE_EXPLICITLY' })}
                                        <Tooltip title={translate({ id: 'CLIENT_USE_EXPLICIT_ALLOCATION_RULE' })}>
                                            <InfoOutlined className={classes.tooltipIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                data-cy="invite-edit-explicit-allocation"
                                className={classes.radioButtonLabel}
                            />
                        </RadioGroup>
                    }
                    name="licenseAllocationMethod"
                    control={control}
                />
            )}
            {renderExplicitAllocations && (
                <>
                    {orgHasUserLicenses || EnableExternalUserLicense ? (
                        <div className={withRadioGroup ? classes.explicitAllocationSection : ''}>
                            <FormGroup>
                                {orgHasUserLicenses && (
                                    <>
                                        {errors.userBundleLicenses && (
                                            <Typography
                                                data-cy="userBundleLicenses-error"
                                                className={classes.userBundleLicensesError}
                                            >
                                                {translate({ id: `CLIENT_USER_BUNDLE_LICENSES_REQUIRED_ERROR` })}
                                            </Typography>
                                        )}
                                        <Controller
                                            name="userBundleLicenses"
                                            control={control}
                                            render={props =>
                                                allUserBundles?.map(userBundle => (
                                                    <div
                                                        key={userBundle.code}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            key={`label-${userBundle.code}`}
                                                            className={classes.checkboxLabel}
                                                            control={
                                                                <Checkbox
                                                                    className={classes.checkBox}
                                                                    checked={props.value?.indexOf(userBundle.code) > -1}
                                                                    onChange={() =>
                                                                        props.onChange(handleCheck(userBundle.code))}
                                                                    size="small"
                                                                    data-cy="group-allocation-ubl"
                                                                    disabled={disableCheckbox(userBundle)}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <Typography className={classes.checkboxText}>
                                                                        {translate({ id: `CLIENT_${userBundle.code}` })}
                                                                    </Typography>

                                                                    {userBundle.allocated !== undefined &&
                                                                        userBundle.total !== undefined &&
                                                                        (userBundle.allocated > userBundle.total ? (
                                                                            <div
                                                                                className={
                                                                                    classes.overallocatedContainer
                                                                                }
                                                                            >
                                                                                <Typography
                                                                                    data-cy={`warning-${userBundle.code}`}
                                                                                    className={
                                                                                        classes.overallocatedWarning
                                                                                    }
                                                                                >
                                                                                    {translate(
                                                                                        { id: `CLIENT_PRODUCTS_OVER_ALLOCATED` },
                                                                                        {
                                                                                            0:
                                                                                                userBundle.allocated -
                                                                                                userBundle.total,
                                                                                        },
                                                                                    )}
                                                                                </Typography>
                                                                                <Tooltip
                                                                                    title={translate({ id: 'CLIENT_PRODUCTS_OVER_ALLOCATED_TOOLTIP' })}
                                                                                    arrow
                                                                                    data-cy="overallocation-tooltip"
                                                                                >
                                                                                    <InfoIcon
                                                                                        className={
                                                                                            classes.overallocatedIcon
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        ) : (
                                                                            userProducts.find(p => p.code === userBundle.code)?.hideQuantity
                                                                                ? null :
                                                                                (<Typography
                                                                                    data-cy={`info-${userBundle.code}`}
                                                                                    className={classes.overallocatedInfo}
                                                                                >
                                                                                    {userBundle.total -
                                                                                            userBundle.allocated > 1 ?
                                                                                        translate(
                                                                                            { id: `CLIENT_PRODUCTS_AVAILABLE_PLURAL` },
                                                                                            {
                                                                                                0:
                                                                                                    userBundle.total -
                                                                                                    userBundle.allocated,
                                                                                            },
                                                                                        ) : translate(
                                                                                            { id: `CLIENT_PRODUCTS_AVAILABLE_SINGLE` },
                                                                                            {
                                                                                                0:
                                                                                            userBundle.total -
                                                                                            userBundle.allocated,
                                                                                            },
                                                                                        )}
                                                                                </Typography>)
                                                                        ))}
                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                )) as any}
                                        />
                                    </>
                                )}
                            </FormGroup>
                            {EnableExternalUserLicense && (
                                <Controller
                                    name="useExternalLicense"
                                    control={control}
                                    render={props => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={props.value}
                                                    onChange={e => handleSwitch(e.target.checked, props.onChange)}
                                                    color="primary"
                                                    data-cy="external-license-switch"
                                                />
                                            }
                                            label={
                                                <div className={classes.switchLabel}>
                                                    {translate({ id: 'CLIENT_USE_EXTERNAL_LICENSE' })}
                                                    <Tooltip
                                                        data-cy="external-license-tooltip"
                                                        title={translate({ id: 'CLIENT_ALLOW_USERS_EXTERNAL_LICENSE' })}
                                                    >
                                                        <InfoOutlined className={classes.tooltipIcon} />
                                                    </Tooltip>
                                                </div>
                                            }
                                        />
                                    )}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={classes.groupColumn}>
                            <Typography
                                className={classes.noLicensesAvailable}
                                data-cy="case-no-license-available">
                                {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                            </Typography>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
