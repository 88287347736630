export const AboutAccountsLink = 'https://docs.uipath.com/automation-suite/{language}/docs/about-accounts';

export const GroupsAndRolesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/managing-access#groups-and-roles';

export const TenantsAndServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/managing-tenants-and-services';

export const BrowserMigrationLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/using-the-migration-tool';

export const UserManagementLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/about-accounts#user-accounts';

export const LicensingPlansLink = 'https://docs.uipath.com/automation-suite/{language}/docs/about-licensing';

export const LicensingOverallocationLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/license-overallocation';

export const UserLicenseManagementLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/user-license-management';

export const RegionInstancesLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/regions-and-instances';

export const LicensingRobotServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/about-licensing#types-of-robot-licenses';

export const ManagingUsersBulkAddingLink =
  'https://docs.uipath.com/automation-cloud/{language}/docs/managing-users#bulk-adding-users';

export const ReleaseNotesLink = 'https://docs.uipath.com/releasenotes/{language}/';

export const OrchestratorLink = 'https://docs.uipath.com/orchestrator/{language}/';

export const RobotLink = 'https://docs.uipath.com/robot/{language}/';

export const StudioLink = 'https://docs.uipath.com/studio/{language}/';

export const ActivitiesLink = 'https://docs.uipath.com/activities/{language}/';

export const AIFabricLink = 'https://docs.uipath.com/ai-fabric/{language}//v0/docs/about-ai-fabric';

export const IntegrationGuideLink = 'https://docs.uipath.com/marketplace/{language}/';

export const TenantRegionDocsLinks = 'https://docs.uipath.com/{language}/overview-guide/docs/data-residency-cloud';

export const AuthenticationAndSecuritySettingsLinks =
  'https://docs.uipath.com/automation-suite/{language}/docs/global-authentication-settings';

export const LicensingManagementOptionsLink = 'https://docs.uipath.com/overview-guide/{language}/docs/license-management-options';

export const EnablingDisablingServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/managing-tenants-and-services#enabling-or-disabling-services';
