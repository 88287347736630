import { UiLoader } from '@experiences/ui-common';
import {
    base64URLEncode,
    sha256,
    useRouteResolver,
} from '@experiences/util';
import crypto from 'crypto';
import React, { useCallback } from 'react';

import { AuthCode } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';

export function RequestUserToken() {
    const getRoute = useRouteResolver();

    const hiddenFrame = useCallback(() => {
        const codeVerifier = base64URLEncode(crypto.randomBytes(32));
        const codeChallenge = base64URLEncode(sha256(codeVerifier));
        sessionStorage.setItem(AuthCode.CHALLENGE, codeChallenge);
        sessionStorage.setItem(AuthCode.VERIFIER, codeVerifier);
        return (
        // Hidden iframe to perform the authentication
            <div style={{ display: 'none' }}>
                <iframe
                    src={`${window.location.origin}${getRoute(RouteNames.RequestUserTokenAuth)}`}
                    title={AuthCode.IFRAME_TITLE}
                />
            </div>
        );
    }, [ getRoute ]);

    return (
        <div>
            <UiLoader type="backdrop" />
            {hiddenFrame()}
        </div>
    );
}
