import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';

const useStyles = makeStyles((theme) =>
    createStyles({
        colorLabelContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        colorSelect: {
            height: '40px',
            width: '30%',
            minWidth: '75px',
        },
        colorList: {
            display: 'grid',
            'grid-template-columns': 'repeat(6, 1fr)',
            padding: '8px 2px',
            justifyItems: 'center',
            alignItems: 'center',
            rowGap: '12px',
        },
        colorIcon: {
            width: '40px',
            height: '40px',
            minHeight: '40px',
            borderRadius: '2px',
            boxSizing: 'border-box',
            '&:hover': { boxShadow: '0 0 2px currentColor' },
        },
        colorSelected: {
            boxShadow: 'none !important',
            border: `2px solid ${theme.palette.semantic.colorForegroundEmp}`,
            borderRadius: '3px',
        },
        colorSelectedIcon: {
            width: '22px',
            height: '22px',
            borderRadius: '2px',
        },
        emptyColor: {
            background: `linear-gradient(to bottom right, ${theme.palette.semantic.colorBackgroundRaised}, ${theme.palette.semantic.colorBackgroundRaised} 48%, ${theme.palette.semantic.colorSelection} 48%, ${theme.palette.semantic.colorSelection} 52%, ${theme.palette.semantic.colorBackgroundRaised} 52%, ${theme.palette.semantic.colorBackgroundRaised})`,
            border: `2px solid ${theme.palette.semantic.colorBorderDisabled}`,
        },
        emptyColorTooltip: {
            position: 'absolute',
            padding: '15px 20px',
            left: -1,
        },
        emptyColorSelectedIcon: {
            width: '20px',
            height: '20px',
            borderRadius: '2px',
        },
        selectNoLabel: { marginTop: '36px' },
        selectWithLabel: { '& .MuiOutlinedInput-notchedOutline': { top: '-5px' } },
    }),
);

const defaultTenantColors = [
    Tokens.Colors.ColorPink300,
    Tokens.Colors.ColorOrange300,
    Tokens.Colors.ColorBlue300,
    Tokens.Colors.ColorGreen300,
    Tokens.Colors.ColorPurple300,
    Tokens.Colors.ColorYellow300,
    Tokens.Colors.ColorLightBlue300,
    Tokens.Colors.ColorPink600,
    Tokens.Colors.ColorOrange500,
    Tokens.Colors.ColorBlue600,
    Tokens.Colors.ColorGreen500,
    Tokens.Colors.ColorPurple500,
    Tokens.Colors.ColorYellow600,
    Tokens.Colors.ColorLightBlue700,
];

const UiColorPicker: React.FC<{ value: string; onChange: (...args: any[]) => void; disabled?: boolean }> = ({
    value, onChange, disabled = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const adminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <FormControl>
            {adminRevampEnabled && <div className={classes.colorLabelContainer}>
                <InputLabel id="tenant-color-label">
                    {translate({ id: 'CLIENT_COLOR' })}
                </InputLabel>
                <Tooltip
                    title={translate({ id: 'CLIENT_TENANT_SETTINGS_COLOR_TOOLTIP' })}
                    describeChild={true}
                >
                    <InfoOutlinedIcon style={{
                        marginLeft: '2px',
                        width: '14px',
                    }} />
                </Tooltip>
            </div>}
            <Select
                id="tenant-color-select"
                value={value}
                onChange={onChange}
                variant="outlined"
                disabled={disabled}
                labelId={adminRevampEnabled ? 'tenant-color-label' : undefined}
                label={adminRevampEnabled ? translate({ id: 'CLIENT_COLOR' }) : undefined}
                IconComponent={KeyboardArrowDownIcon}
                className={clsx({
                    [classes.colorSelect]: true,
                    [classes.selectNoLabel]: !adminRevampEnabled,
                    [classes.selectWithLabel]: adminRevampEnabled,
                })}
                displayEmpty
                renderValue={(value: any) =>
                    value ? (
                        <div
                            className={classes.colorSelectedIcon}
                            style={{ backgroundColor: value }} />
                    ) : (
                        <div className={clsx(classes.emptyColorSelectedIcon, classes.emptyColor)} />
                    )}
                MenuProps={{
                    PaperProps: { style: { width: '360px' } },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    classes: { list: classes.colorList },
                }}
                role="combobox"
                SelectDisplayProps={{ 'aria-labelledby': 'tenantNameLabel' }}
                aria-expanded="false"
                data-cy="create-edit-tenant-color"
            >
                <MenuItem
                    key="empty-default-color"
                    value=""
                    className={clsx(classes.colorIcon, classes.emptyColor)}
                    classes={{ selected: classes.colorSelected }}
                    role="menuitem"
                    aria-label={translate({ id: 'CLIENT_NO_COLOR' })}
                >
                    <Tooltip title={translate({ id: 'CLIENT_NO_COLOR' })}>
                        <div className={classes.emptyColorTooltip} />
                    </Tooltip>
                </MenuItem>

                {defaultTenantColors.map((color, i) => (
                    <MenuItem
                        key={i}
                        value={color}
                        style={{
                            backgroundColor: color,
                            color,
                        }}
                        className={classes.colorIcon}
                        classes={{ selected: classes.colorSelected }}
                        aria-label={translate({ id: 'CLIENT_ITEM' }, { 0: value })}
                    />
                ))}
            </Select>
        </FormControl>
    );
};

export default UiColorPicker;
