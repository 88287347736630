import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import { hyperLink } from '../../common/constants/Constant';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import {
    accountGlobalId,
    accountLogicalName,
    groupIdsForUser,
    userNameSelector,
} from '../../store/selectors';
import Carousel from '../carousel/CarouselComponent';
import UiAlertBanner from '../common/UiAlertBanner';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { UiWidgetContainer } from '../common/UiWidgetContainer';
import { FREBannerHelper } from './helper/FREBannerHelper';
import { HomeWidgets } from './helper/HomeWidgets';

const useStyles = makeStyles((theme) => GlobalStyles(theme));

export function HomePageComponent() {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const userName = useSelector(userNameSelector);
    const organizationName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const currentGroupIds = useSelector(groupIdsForUser);
    const { checkTokenTypeIsAuth0: isAuth0 } = useCheckAuthTypes();

    const EnableHomePageCarousel = useFeatureFlagValue(
        Features.EnableHomePageCarousel.name,
    );

    const greeting = useMemo((): string => {
        const currentHour = new Date().getHours();

        if (currentHour >= 3 && currentHour < 12) {
            return translate(
                { id: 'CLIENT_GOOD_MORNING_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        } else if (currentHour >= 12 && currentHour < 18) {
            return translate(
                { id: 'CLIENT_GOOD_AFTERNOON_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        }
        return translate(
            { id: 'CLIENT_GOOD_EVENING_USER' },
            { 0: userName || translate({ id: 'CLIENT_USER' }) },
        );
    }, [ translate, userName ]);

    const isSSOConnectionEnabled = useCheckSSOEnabled();

    const [ hideSidebar, setHideSidebar ] = useState(false);

    return (
        <UiPanel
            header={{
                title: greeting,
                fontSize: '18px',
                fontWeight: 700,
            }}
            sideBar={
                !hideSidebar && (
                    <UiWidgetContainer
                        widgets={HomeWidgets}
                        userGroups={currentGroupIds}
                        position="right"
                    />
                )
            }
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={
                <UiTrialPerSkuLicenseInGracePeriodBanners />
            }
            licenseOverAllocationBanner={<UiOverAllocationBanner />}
            banner={
                <>
                    {EnableHomePageCarousel && <Carousel />}
                    {isSSOConnectionEnabled && isAuth0 && (
                        <UiAlertBanner
                            type="info"
                            closeable={false}>
                            {translate(
                                { id: 'CLIENT_AZURE_AUTH0_LOGIN' },
                                {
                                    0: (
                                        <a
                                            className={classes.a}
                                            href={`${window.location.origin}/${organizationName}`}
                                        >
                                            {`${window.location.origin}/${organizationName}`}
                                        </a>
                                    ),
                                },
                            )}
                            <a
                                className={classes.a}
                                target="_blank"
                                rel="noreferrer"
                                href={hyperLink.USER_MANAGEMENT}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    marginLeft: '8px',
                                }}
                            >
                                {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                <LaunchIcon
                                    style={{
                                        fontSize: '14px',
                                        marginLeft: '4px',
                                    }}
                                />
                            </a>
                        </UiAlertBanner>
                    )}
                </>
            }
            freBanner={
                <FREBannerHelper
                    partitionGlobalId={partitionGlobalId}
                    userGroupIds={currentGroupIds}
                    setHideSidebar={setHideSidebar}
                />
            }
        >
            <UiWidgetContainer
                widgets={HomeWidgets}
                userGroups={currentGroupIds}
                position="center"
            />
        </UiPanel>
    );
}

export default HomePageComponent;
