import { TableCell } from '@mui/material';
import React, { ReactNode } from 'react';

import {
    CheckboxCellSize,
    RowHeight,
} from './constants';

interface ITableCellProps {
    checkbox?: boolean;
    rowIndex?: number;
    children?: ReactNode;
    columnWidth: number;
    justifyContent?: any;
    wrap?: boolean;
    dataCy?: string;
    tabIndex?: number;
    ariaHidden?: boolean;
}

export const UiTableCell = ({
    checkbox,
    rowIndex,
    children,
    columnWidth,
    justifyContent = 'flex-start',
    wrap = false,
    dataCy,
    tabIndex = 0,
    ariaHidden = false,
}: ITableCellProps) => {
    const firstRowWithCheckbox = checkbox && rowIndex === 0;
    return (
        <TableCell
            tabIndex={tabIndex}
            style={{
                justifyContent,
                textAlign: 'center',
                paddingRight: justifyContent === 'flex-end' ? '16px' : '0',
                height: RowHeight + 'px',
                ...(wrap && { whiteSpace: 'unset' }),
                flex: firstRowWithCheckbox ? `0 0 ${CheckboxCellSize}px` : `1 1 ${columnWidth}px`,
            }}
            key={rowIndex}
            component={'div' as any}
            padding={firstRowWithCheckbox ? 'checkbox' : 'normal'}
            data-cy={dataCy}
            role={ariaHidden ? 'none' : 'cell'}
            aria-hidden={checkbox ? undefined : ariaHidden}
        >
            {children}
        </TableCell>
    );
};
