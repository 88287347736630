import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Breadcrumbs,
    Button,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR from 'swr';

import { LicensingRobotServicesLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import { ITenant } from '../../../../common/interfaces/tenant/tenant';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import { isAdminSelector } from '../../../../store/selectors';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../../../common/UiPanel/UiPanel';
import { UiTenantStatusBanner } from '../../../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import UsageChartsComponent from '../../../usage/UsageChartsComponent';
import { TenantStatusConstants } from '../../TenantConstants';

const useStyles = makeStyles(theme =>
    createStyles({
        breadcrumbText: {
            fontSize: '18px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        chartsContainer: {
            marginTop: '16px',
            maxWidth: '90%',
        },
    }),
);

const ServicesUsagePageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();

    const history = useHistory();
    const getRoute = useRouteResolver();

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'pt', 'pt-BR', 'zh-CN', 'zh-TW' ] });

    const isAdmin = useSelector(isAdminSelector);
    const { tenantId } = useParams<{ tenantId: string }>();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const {
        data: tenant, isValidating, error,
    } = useSWR<ITenant, Error>(
        isAdmin && tenantId ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null,
        getTenantById,
    );

    const goToTenantsPage = useCallback(() => history.push(getRoute(RouteNames.Services)), [ getRoute, history ]);

    const { getErrorMessage } = useGetErrorInfo();

    useEffect(() => {
        if (!isAdmin) {
            (async () => {
                await createDialog({
                    title: translate({ id: 'CLIENT_ERROR' }),
                    body: translate({ id: 'CLIENT_FORBIDDEN_ERROR_MESSAGE' }),
                    icon: 'error',
                });

                goToTenantsPage();
            })();
        }
    }, [ isAdmin, createDialog, goToTenantsPage, translate ]);

    useEffect(() => {
        if (!isValidating && error) {
            (async () => setErrorMessage(await getErrorMessage(error)))();
        }
    }, [ error, isValidating, setErrorMessage, getErrorMessage ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.TenantHome.replace(':tenantId', tenantId),
            name: tenant?.name ?? translate({ id: 'CLIENT_HOME_TITLE' }),
        },
        {
            link: RouteNames.ServicesUsage.replace(':tenantId', tenantId),
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ];

    const usageCharts = useMemo(() => (
        <div className={classes.chartsContainer}>
            <UsageChartsComponent
                tenantIds={[ tenantId ]}
                isServiceMode={true} />
        </div>
    ), [ classes.chartsContainer, tenantId ]);

    return (
        isAdminRevampEnabled
            ? <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                banner={<UiTenantStatusBanner
                    status={tenant?.status}
                    tenantName={tenant?.name} />}
                primaryActions={
                    <div>
                        <Button
                            variant="text"
                            style={{ marginRight: '8px' }}
                            href={getLocalizedLink(LicensingRobotServicesLink)}
                            target="_blank"
                            rel="noreferrer">
                            {translate({ id: 'CLIENT_NEED_MORE_LICENSES' })}
                        </Button>
                        <Button
                            style={{ marginRight: '20px' }}
                            size="small"
                            onClick={() => history.push(
                                getRoute(
                                    RouteNames.TenantServicesUsageAllocation
                                        .replace(':tenantId', tenantId)
                                        .replace(':type(configure)', 'configure'),
                                ),
                                { previousLocation: history.location.pathname },
                            )}
                            variant="contained"
                            disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}
                            data-cy="service-usage-edit-allocation">
                            {translate({ id: 'CLIENT_EDIT_ALLOCATION' })}
                        </Button>
                    </div>
                }
                position="left">
                {usageCharts}
            </UiPageContainer>
            : <UiPanel
                header={{
                    title: (
                        <Breadcrumbs separator=">">
                            <span
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                onClick={goToTenantsPage}>
                                {translate({ id: 'CLIENT_PAGE_TENANTS' })}
                            </span>
                            <span
                                className={classes.breadcrumbText}
                                role="heading"
                                aria-level={1}>
                                {tenant?.name || ''}
                                {' '}
-
                                {translate({ id: 'CLIENT_USAGE' })}
                            </span>
                        </Breadcrumbs>
                    ),
                }}
                data-cy="services-usage-panel"
            >
                {usageCharts}
            </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <ServicesUsagePageComponent />
        {renderRoutes(route.routes)}
    </>
);
