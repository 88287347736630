import { useModalState } from '@experiences/util';
import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../common/constants/RouteNames';
import { UiDrawer } from '../../common/UiDrawer';
import EditSSOWithAADComponent from './EditSSOWithAADComponent';

const ConfigureAADOrgModeComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const {
        open, close,
    } = useModalState(RouteNames.AuthSettings);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const handleError = useCallback(() => {
        setShowDrawerError(true);
    }, []);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CONFIGURE_AAD_INTEGRATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: translate({ id: 'CLIENT_CONFIGURE_AAD_GENERIC_ERROR' }),
            }}
        >
            <EditSSOWithAADComponent
                onError={handleError}
                onClose={close} />
        </UiDrawer>
    );
};

export default ConfigureAADOrgModeComponent;
