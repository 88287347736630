import orderBy from 'lodash/orderBy';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { groupUrl } from '../../component/users/GroupsPageComponent';
import { getGroups } from '../../services/identity/GroupService';
import { accountGlobalId } from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { GroupTypes } from '../constants/GroupTypes';
import { IGroup } from '../interfaces/cis/group';

export default function useSimpleGroup(fetchGroups = false) {
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data, isValidating,
    } = useSWR<IGroup[], Error>(fetchGroups ? [ groupUrl, partitionGlobalId ] : null, getGroups);

    const groups = useMemo(() => {
        return orderBy(data, [ 'type', d => (d.type === GroupTypes.CUSTOM ? d.name : 0) ], [ 'asc', 'asc' ]).map(group => {
            return {
                ...group,
                name: decodeSanitizedHtml(group.name),
            };
        });
    }, [ data ]);

    const getGroupsForUser = useCallback(
        (userGroupIds: string[] = [], useId?: boolean) =>
            groups.filter(group => userGroupIds.indexOf(group.id) > -1).map(group => (useId ? group.id : group.name)),
        [ groups ],
    );

    return {
        loading: isValidating,
        groups,
        getGroupsForUser,
    };
}
