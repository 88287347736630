import { AccountLicense } from '@experiences/constants';
import { ITabProductAllocation } from '@experiences/interfaces';
import { useShowDialog } from '@experiences/util';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { generateMLServiceKey } from '../../services/licensing/LicenseService';
import { licenseManagementAccountUrl } from '../../services/licensing/management/AccountService';
import { isAdminSelector } from '../../store/selectors';
import { getShortNumber } from '../../util/TypeUtil';
import { BorderLinearProgress } from './BorderLinearProgress';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            height: '100%',
            justifyContent: 'space-between',
        },
        textContent: {
            display: 'flex',
            minHeight: '60px',
            justifyContent: 'space-between',
        },
        entry: {
            flex: 1,
            minWidth: 0,
        },
        header: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        description: {
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        quantity: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        iconButton: { color: theme.palette.semantic.colorPrimary },
        apiKeyWithAction: {
            display: 'flex',
            marginTop: '8px',
        },
        apiKey: {
            display: 'flex',
            marginTop: '8px',
            overflow: 'hidden',
        },
        apiKeyText: { whiteSpace: 'nowrap' },
        apiKeyLine: {
            textOverflow: 'ellipsis',
            maxWidth: '480px',
        },
        productQuantityAndApiKeys: {
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
        },
        apiKeyActions: {
            marginLeft: '5px',
            display: 'flex',

            alignSelf: 'flex-end',
        },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginRight: '4px',
            height: '20px',
        },
    }),
);

export const LicensingProductAllocations: React.FC<{
    products: ITabProductAllocation[];
    loading: boolean;
}> = ({
    products, loading,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const createDialog = useShowDialog();
    const isAdmin = useSelector(isAdminSelector);

    const [ keyToGenerate, setKeyToGenerate ] = useState<string | undefined>(undefined);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const generateNewMlKey = useCallback(async (keyType: string) => {
        setKeyToGenerate(keyType);
        await generateMLServiceKey(keyType);
        mutate(`${licenseManagementAccountUrl}/available`);
    }, []);

    const showConfirmationDialog = useCallback(
        async (keyType?: string) => {
            if (!keyType) {
                return;
            }

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_CONFIRM_GENERATION_REQUEST' }),
                body: translate({ id: 'CLIENT_CONFIRM_GENERATION_TEXT' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_GENERATE' }),
            });
            if (proceed) {
                await generateNewMlKey(keyType);
            }
        },
        [ createDialog, generateNewMlKey, translate ],
    );

    return (
        <Grid
            container
            spacing={3}>
            {products.map(product => (
                <Grid
                    key={product.code}
                    item
                    xs={6}
                    data-cy="licenses-grid-item">
                    <div
                        tabIndex={0}
                        className={classes.content}
                        data-cy={`product-allocation-${product.code.toLowerCase()}`}>
                        <div className={classes.textContent}>
                            <div className={classes.entry}>
                                <Typography
                                    className={classes.header}
                                    data-cy="licenseType">
                                    {translate({ id: `CLIENT_USAGE_HEADER_${product.code}` })}
                                </Typography>
                                <Typography
                                    className={classes.description}
                                    data-cy={`license-description-${product.code.toLowerCase()}`}
                                >
                                    {translate({
                                        id:
                                        product.subscriptionPlan && AccountLicense[product.subscriptionPlan] === AccountLicense.COMMUNITY
                                            ? `CLIENT_USAGE_DESCRIPTION_COMMUNITY_${product.code}`
                                            : `CLIENT_USAGE_DESCRIPTION_${product.code}`,
                                    })}
                                </Typography>
                                {product.licenseType && (
                                    <div className={classes.apiKeyWithAction}>
                                        <div className={classes.apiKey}>
                                            <Typography
                                                className={classes.apiKeyText}
                                                data-cy={`licenses-api-key-label-${product.code.toLowerCase()}`}
                                            >
                                                {translate({ id: 'CLIENT_API_KEY' })}
                                                &nbsp;
                                            </Typography>
                                            {loading && product.licenseType === keyToGenerate ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{ marginLeft: '8px' }} />
                                            ) : (
                                                <Tooltip
                                                    arrow
                                                    title={product.mlServiceKey ?? ''}>
                                                    <Typography
                                                        noWrap
                                                        className={classes.apiKeyLine}
                                                        data-cy={`licenses-other-api-key-${product.code.toLowerCase()}`}
                                                    >
                                                        {product.mlServiceKey ?? translate({ id: 'CLIENT_NOT_GENERATED_YET' })}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className={classes.apiKeyActions}>
                                            {product.mlServiceKey && (
                                                <CopyToClipboard text={product.mlServiceKey}>
                                                    <Tooltip
                                                        title={translate({ id: 'CLIENT_COPY_API_KEY' })}
                                                        placement="top">
                                                        <IconButton>
                                                            <FileCopyOutlinedIcon
                                                                onClick={() => createNotification(translate({ id: 'CLIENT_COPIED_TO_CLIPBOARD' }))}
                                                                data-cy={`licenses-copy-key-${product.code.toLowerCase()}`}
                                                                color="primary"
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            )}
                                            {isAdmin && (
                                                <>
                                                    <Tooltip
                                                        title={translate({ id: 'CLIENT_GENERATE_NEW' })}
                                                        placement="top">
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            style={{ marginLeft: product.mlServiceKey ? '12px' : '0' }}
                                                            onClick={() => showConfirmationDialog(product.licenseType)}
                                                            data-cy={`licenses-generate-key-${product.code.toLowerCase()}`}
                                                        >
                                                            <AutorenewIcon
                                                                color="primary"
                                                                fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                data-cy="licenseNumber"
                                className={classes.productQuantityAndApiKeys}>
                                {!product.hideQuantity && (
                                    <Typography
                                        className={classes.quantity}
                                        data-cy={`product-allocation-${product.code.toLowerCase()}-total`}
                                    >
                                        {product.allocated > product.total && (
                                            <Tooltip
                                                title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                                aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                            >
                                                <WarningIcon
                                                    className={classes.yellowIcon}
                                                    data-cy="usage-chart-over-allocation" />
                                            </Tooltip>
                                        )}
                                        <span>
                                            {getShortNumber(product.total)}
                                        </span>
                                    </Typography>
                                )}
                            </div>
                        </div>
                        {!product.hideProgress && <BorderLinearProgress
                            consumed={product.allocated}
                            total={product.total} />}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
