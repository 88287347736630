import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../../../common/constants/RouteNames';
import {
    getKmsConfig,
    IKmsConfig,
    kmsUrl,
} from '../../../../services/identity/KeyManagementService';
import { accountGlobalId } from '../../../../store/selectors';
import EncryptionWarningDialogBody from './EncryptionWarningDialogBody';

const useStyles = makeStyles(theme =>
    createStyles({
        centerLoader: { margin: 'auto' },
        default: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
            marginLeft: '4px',
        },
        description: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '28px',
        },
        editButton: { marginLeft: '28px' },
        labelDescription: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            marginBottom: '4px',
        },
        labelTitle: {
            color: theme.palette.semantic.colorForegroundEmp,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
        },
        labelTitleContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        mainContainer: {
            margin: '24px auto',
            maxWidth: '480px',
        },
        radio: { marginBottom: '18px' },
        title: {
            color: theme.palette.semantic.colorForegroundEmp,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
        },
    }),
);

export enum EncryptionType {
    UIPATH = 'uipath',
    CUSTOMER = 'customer',
}

const EncryptionComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();

    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: kmsConfig, mutate: mutateKmsConfig,
    } = useSWR<IKmsConfig, Error>(
        [ kmsUrl, partitionGlobalId ],
        getKmsConfig,
    );

    const [ encryptionType, setEncryptionType ] = useState<EncryptionType>(EncryptionType.UIPATH);

    useEffect(() => {
        if (kmsConfig) {
            setEncryptionType(kmsConfig.keyType === 1 ? EncryptionType.CUSTOMER : EncryptionType.UIPATH);
        }
    }, [ kmsConfig ]);

    const refreshRadioGroup = useCallback(() => {
        mutate([ kmsUrl, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const addConnection = useCallback(() => {
        history.push({ pathname: getRoute(`${RouteNames.Encryption}/configure/add`) });
    }, [ getRoute, history ]);

    const editConnection = useCallback(() => {
        history.push({ pathname: getRoute(`${RouteNames.Encryption}/configure/edit`) });
    }, [ getRoute, history ]);

    const handleChange = useCallback(async (e, value) => {
        if (value === EncryptionType.CUSTOMER) {
            addConnection();
        } else {
            await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_WARNING_TITLE' }),
                customDialogContent: EncryptionWarningDialogBody,
                customDialogContentProps: {
                    refreshCallback: () => {
                        refreshRadioGroup();
                    },
                },
                icon: 'info',
            });
        }
    }, [ createDialog, addConnection, refreshRadioGroup, translate ]);

    const options = useMemo(() => {
        return [
            {
                title: (
                    <div className={classes.labelTitleContainer}>
                        <Typography className={classes.labelTitle}>
                            {translate({ id: 'CLIENT_ENCRYPTION_UIPATH_MANAGED_KEY' })}
                        </Typography>
                        <Typography className={classes.default}>
                            (
                            {translate({ id: 'CLIENT_DEFAULT' })}
)
                        </Typography>
                    </div>
                ),
                description: translate({ id: 'CLIENT_ENCRYPTION_UIPATH_MANAGED_KEY_DESCRIPTION' }),
                value: EncryptionType.UIPATH,
                dataCy: 'uipath-managed-key',
            },
            {
                title: (
                    <Typography className={classes.labelTitle}>
                        {translate({ id: 'CLIENT_ENCRYPTION_CUSTOMER_MANAGED_KEY' })}
                    </Typography>
                ),
                description: translate({ id: 'CLIENT_ENCRYPTION_CUSTOMER_MANAGED_KEY_DESCRIPTION' }),
                value: EncryptionType.CUSTOMER,
                dataCy: 'customer-managed-key',
            },
        ];
    }, [ classes, translate ]);

    return (
        kmsConfig ?
            <div className={classes.mainContainer}>
                <Typography
                    id='encryptionTitle'
                    className={classes.title}
                    data-cy='encryption-title'
                >
                    {translate({ id: 'CLIENT_ENCRYPTION_TYPE' })}
                </Typography>
                <Typography
                    className={classes.description}
                    data-cy='encryption-description'
                >
                    {translate({ id: 'CLIENT_ENCRYPTION_DESCRIPTION' })}
                </Typography>
                <RadioGroup
                    aria-labelledby='encryptionTitle'
                    value={encryptionType}
                    data-cy='encryption-radio-group'
                    onChange={handleChange}
                >
                    {options.map((option, i) => (
                        <FormControlLabel
                            className={classes.radio}
                            key={i}
                            value={option.value}
                            control={<Radio />}
                            label={<>
                                {option.title}
                                <Typography className={classes.labelDescription}>
                                    {option.description}
                                </Typography>
                            </>}
                            data-cy={`encryption-radio-${option.dataCy}`}
                        />
                    ))}
                </RadioGroup>
                {encryptionType === EncryptionType.CUSTOMER && <Button
                    className={classes.editButton}
                    data-cy='encryption-edit-connection-button'
                    onClick={editConnection}
                    variant='outlined'>
                    {translate({ id: 'CLIENT_EDIT_CONNECTION' })}
                </Button>}
            </div>
            :
            <div
                className={classes.centerLoader}
                data-cy='encryption-circular-progress'>
                <CircularProgress />
            </div>
    );
};

export default EncryptionComponent;
