import { TextField } from '@mui/material';
import React, { FC } from 'react';

import CustomEditor, { ICustomEditorProps } from './subcomponents/CustomEditor';

export const UiCodeEditor: FC<ICustomEditorProps> = ({
    value, onValueChange, dataCy,
}) => {
    return (
        <TextField
            variant="outlined"
            fullWidth
            InputProps={{
                inputComponent: CustomEditor,
                inputProps: {
                    dataCy,
                    value,
                    onValueChange,
                },
            }}
        />
    );
};
