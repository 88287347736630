import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import { updateUserNotificationTopicSubscription } from '../../../services/notification-preferences';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import {
    INotificationSettingsReducer,
    INotificationTopic,
    INotificationTopicGroup,
    NotificationMode,
} from '../interfaces/notificationSettings';
import { ActionTypes } from '../reducers/actionTypes';

const useStyles = makeStyles(theme =>
    createStyles({
        header: { fontWeight: '600' },
        tableCell: {
            height: '50px',
            minWidth: '200px',
            width: '20%',
            border: 'none',
        },
        overflowDiv: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        tableMainCell: { width: '50%' },
        tableHeaderCell: {
            backgroundColor: 'transparent',
            color: theme.palette.semantic.colorForegroundDeEmp,
            paddingLeft: '27px',
            borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        tableBodyRow: { '&:hover': { backgroundColor: `${theme.palette.semantic.colorHover}` } },
        tableHeaderMainCell: { paddingLeft: '0' },
        tableSubHeaderRow: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        tableSubHeaderCell: { borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
        tableSubHeaderMainCell: {
            paddingLeft: '16px',
            fontWeight: '600',
        },
        tableBodyMainCell: { paddingLeft: '32px' },
        loaderContainer: {
            minHeight: '42px',
            minWidth: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        infoIcon: {
            margin: '6px',
            height: '16px',
            width: '16px',
        },
    }),
);

const NotificationModePreferencesComponent: React.FC<{
    notificationSettings: INotificationSettingsReducer;
    dispatch: any;
    handleError(error: Error): void;
}> = ({
    notificationSettings, dispatch, handleError,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const {
        currentPublisher,
        notificationTopics,
        publisherIndex,
    } = useMemo(
        () => {
            const publisher = notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex];
            return {
                currentPublisher: publisher,
                notificationTopics: publisher.topicGroups,
                publisherIndex: publisher.publisherIndex,
            };
        },
        [ JSON.stringify(notificationSettings.publishersWithGroupedTopics), notificationSettings.tabIndex ],
    );

    const modeResxMap = new Map<NotificationMode, string>([
        [ NotificationMode.Email, 'CLIENT_EMAIL' ],
        [ NotificationMode.InApp, 'CLIENT_CLOUDRPA_TITLE' ],
    ]);

    const isPublisherModeVisible = (modeName: NotificationMode) => !!currentPublisher.modes.find(mode => mode.name === modeName);

    const changeTopicSubscription = useCallback(
        async (groupIndex: number, topicIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                    mode,
                },
            });
            const topicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const topicState = {
                topicId,
                isSubscribed,
                notificationMode: mode,
            };
            try {
                await updateUserNotificationTopicSubscription([ topicState ], tenantId);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, tenantId, handleError ],
    );

    const changeTopicGroupSubscription = useCallback(
        async (groupIndex: number, mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    mode,
                },
            });
            const topicStatesList = currentPublisher.topicGroups[groupIndex].topics.map((topic: INotificationTopic) => {
                return {
                    topicId: topic.topicId,
                    isSubscribed,
                    notificationMode: mode,
                };
            });
            try {
                await updateUserNotificationTopicSubscription(topicStatesList, tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, tenantId, handleError ],
    );

    const changePublisherSubscription = useCallback(
        async (mode: NotificationMode, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    mode,
                },
            });
            const topicStatesList = currentPublisher.topicGroups.flatMap(topicgroup => topicgroup.topics).map((topic) => {
                return {
                    topicId: topic.topicId,
                    isSubscribed,
                    notificationMode: mode,
                };
            });
            try {
                await updateUserNotificationTopicSubscription(topicStatesList, tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        isSubscribed,
                        mode,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        mode,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, tenantId, handleError ],
    );

    const getTopicContent = (topicGroup: INotificationTopicGroup) => topicGroup.topics.map((topic, index) => (
        <TableRow
            key={index}
            className={classes.tableBodyRow}>
            <TableCell className={clsx(
                classes.tableCell,
                classes.tableMainCell,
                classes.tableBodyMainCell)}>
                <div className={classes.overflowDiv}>
                    {topic.displayName}
                </div>
                { topic.description && topic.description.toLowerCase() !== topic.displayName.toLowerCase() && <Tooltip
                    arrow
                    title={topic.description}>
                    <InfoOutlined
                        className={classes.infoIcon}
                        tabIndex={0}
                        aria-label={topic.description}
                    />
                </Tooltip>}
            </TableCell>
            {
                Object.values(NotificationMode)
                    .filter(modeName => isPublisherModeVisible(modeName))
                    .map(modeName => topic.modes.find(mode => mode.name === modeName))
                    .map((mode, cellIndex) => (
                        <TableCell
                            key={cellIndex}
                            className={clsx(
                                classes.tableCell)}
                            align='left'>
                            { !!mode && !mode.isUpdating ?
                                <Checkbox
                                    data-testid={`topic_${topic.name}_${mode.name}`}
                                    aria-label={translate({ id: modeResxMap.get(mode.name) })}
                                    checked={mode.isSubscribed}
                                    disabled={!!topicGroup.modes.find(m => m.name === mode.name)?.isUpdating ||
                                            !!currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating}
                                    onChange={(e) =>
                                        changeTopicSubscription(topicGroup.groupIndex, topic.topicIndex, mode.name, e.target.checked)}
                                />
                                : (<div className={classes.loaderContainer}>
                                    <CircularProgress
                                        size={16}
                                        thickness={4} />
                                </div>)}
                        </TableCell>
                    ))
            }
        </TableRow>
    ));

    return (
        <>
            <TableContainer>
                <Table aria-label={translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_EVENTS' })}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableMainCell,
                                    classes.tableHeaderCell,
                                    classes.tableHeaderMainCell)}
                                align='left'>
                                <div className={classes.overflowDiv}>
                                    {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOTIFICATION_EVENTS' })}
                                </div>
                            </TableCell>
                            {
                                Object.values(NotificationMode)
                                    .map(modeName => currentPublisher.modes.find(mode => mode.name === modeName))
                                    .map((mode, cellIndex) => (!!mode &&
                                    <TableCell
                                        key={cellIndex}
                                        className={clsx(classes.tableCell, classes.tableHeaderCell)}
                                        align='left'>
                                        <FormControlLabel
                                            control={!mode.isUpdating ?
                                                <Checkbox
                                                    checked={mode.isSubscribed}
                                                    indeterminate={mode.isIndeterminate}
                                                    onChange={(e) => changePublisherSubscription(mode.name, e.target.checked)} />
                                                : (<div className={classes.loaderContainer}>
                                                    <CircularProgress
                                                        size={16}
                                                        thickness={4} />
                                                </div>)}
                                            label={
                                                <Typography
                                                    className={clsx(classes.header)}>
                                                    {translate({ id: modeResxMap.get(mode.name) })}
                                                </Typography>
                                            } />
                                    </TableCell>
                                    ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            notificationTopics[0].topics
                                .map((topic, index) => (
                                    <TableRow
                                        key={index}
                                        className={classes.tableBodyRow}>
                                        <TableCell className={clsx(
                                            classes.tableCell,
                                            classes.tableMainCell,
                                            classes.tableBodyMainCell)}>
                                            <div className={classes.overflowDiv}>
                                                {topic.displayName}
                                            </div>
                                        </TableCell>
                                        {
                                            Object.values(NotificationMode)
                                                .filter(mode => isPublisherModeVisible(mode))
                                                .map(modeName => topic.modes.find(mode => mode.name === modeName))
                                                .map((mode, cellIndex) => (
                                                    <TableCell
                                                        key={cellIndex}
                                                        className={clsx(
                                                            classes.tableCell)}
                                                        align='left'>
                                                        { !!mode && !mode.isUpdating ?
                                                            <Checkbox
                                                                aria-label={translate({ id: modeResxMap.get(mode.name) })}
                                                                checked={mode.isSubscribed}
                                                                disabled={currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating}
                                                                onChange={(e) =>
                                                                    changeTopicSubscription(0, topic.topicIndex, mode.name, e.target.checked)}
                                                            /> :
                                                            (<div className={classes.loaderContainer}>
                                                                <CircularProgress
                                                                    size={16}
                                                                    thickness={4} />
                                                            </div>)}
                                                    </TableCell>
                                                ))
                                        }
                                    </TableRow>
                                ))
                        }
                        {notificationTopics?.filter(topicGroup => topicGroup.groupName !== 'un-grouped')
                            .map((topicGroup: INotificationTopicGroup, index: number) => (
                                <>
                                    <TableRow
                                        key={index}
                                        className={clsx(classes.tableBodyRow, classes.tableSubHeaderRow)}>
                                        <TableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableMainCell,
                                                classes.tableSubHeaderCell,
                                                classes.tableSubHeaderMainCell)}>
                                            <div className={classes.overflowDiv}>
                                                {topicGroup.groupName}
                                            </div>
                                        </TableCell>
                                        {
                                            Object.values(NotificationMode)
                                                .filter(mode => isPublisherModeVisible(mode))
                                                .map(modeName => topicGroup.modes.find(mode => mode.name === modeName))
                                                .map((mode, cellIndex) => (
                                                    <TableCell
                                                        key={cellIndex}
                                                        className={clsx(
                                                            classes.tableCell,
                                                            classes.tableSubHeaderCell)}
                                                        align='left'>
                                                        { !!mode && !mode.isUpdating ?
                                                            <Checkbox
                                                                aria-label={translate({ id: modeResxMap.get(mode.name) })}
                                                                checked={mode.isSubscribed}
                                                                indeterminate={mode.isIndeterminate}
                                                                disabled={currentPublisher.modes.find(m => m.name === mode.name)?.isUpdating}
                                                                onChange={(e) => changeTopicGroupSubscription(topicGroup.groupIndex, mode.name, e.target.checked)}
                                                            />
                                                            : (<div className={classes.loaderContainer}>
                                                                <CircularProgress
                                                                    size={16}
                                                                    thickness={4} />
                                                            </div>)}
                                                    </TableCell>
                                                ))
                                        }
                                    </TableRow>
                                    {getTopicContent(topicGroup)}
                                </>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default NotificationModePreferencesComponent;
