import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useSupportedLanguagesMap } from '@experiences/locales';
import {
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    InputAdornment,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { UnregisterCallback } from 'history';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import {
    accountSetting,
    notificationType,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { useTriggerPortalShellRefresh } from '../../../common/hooks/useTriggerPortalShellRefresh';
import { ServiceErrorCode } from '../../../common/interfaces/error';
import { IOrganizationError } from '../../../common/interfaces/organization';
import {
    cobrandingUrl,
    deleteLogo,
    getLogos,
    uploadLogo,
} from '../../../services/organization/CobrandingService';
import { updateOrganization } from '../../../services/organization/OrganizationService';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import { profile } from '../../../store/selectors';
import { getMiddleTruncatedString } from '../../../util/TypeUtil';
import validateAccountLogicalName from '../../../util/validators/AccountLogicalNameValidator';
import UiCopyButton from '../../common/UiCopyButton';
import UiForm from '../../common/UiForm';
import CobrandingComponent from '../subcomponents/CobrandingComponent';
import loadOrganizationName from '../subcomponents/OrganizationUtils';

interface GeneralSettingsThemeProps {
    width: string | undefined;
}

const useStyles = makeStyles<Theme, GeneralSettingsThemeProps>(theme =>
    createStyles({
        root: props => ({
            paddingTop: '20px',
            width: props.width,
        }),
        input: { marginTop: '24px' },
        select: { marginTop: '16px' },
        generalSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        warningText: { fontSize: '12px !important' },
        flex: { display: 'flex' },
        logicalNameAdornment: {
            width: 'unset',
            marginRight: '2px',
        },
        supportIdContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        supportIdLabel: {
            fontWeight: 600,
            lineHeight: '15px',
            marginBottom: '2px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        supportId: { color: theme.palette.semantic.colorForegroundDeEmp },
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '28px',
            marginBottom: '24px',
        },
        rightAlignedActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

export interface IAccountSettingsDto {
    companyName: string;
    logicalName: string;
    language: string;
}

interface ILogoUrls {
    lightLogo?: string;
    darkLogo?: string;
}

const EnablePortalLogoCobrandingAdminUX = !process.buildConfigs.disablePortalLogoCobrandingAdminUX;

export const GeneralSettingsComponent: React.FC = () => {
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const classes = useStyles({ width: isAdminRevampEnabled ? '100%' : '518px' });
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const setErrorMessage = useCentralErrorSetter();
    const { parseErrorObject } = useGetErrorInfo();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const { triggerPortalShellRefresh } = useTriggerPortalShellRefresh();

    const [ loading, setLoading ] = useState(false);
    const [ savedPayload, setSavedPayload ] = useState<IAccountSettingsDto>();

    const accountProfile = useSelector(profile);

    const proceedListener = useRef<UnregisterCallback>();

    const {
        companyName,
        accountLogicalName,
        licenseCode: supportId,
        accountLanguage: language,
    } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const {
        data: logos,
        error: logoError,
    } = useSWR<any, Error>(EnablePortalLogoCobrandingAdminUX ? [ cobrandingUrl ] : null, getLogos);

    const mutateLogos = useCallback((tempLogos: ILogoUrls) => mutate([ cobrandingUrl ], tempLogos, false), []);
    const triggerLogos = useCallback(() => mutate([ cobrandingUrl ]), []);

    const [ lightLogoFile, setLightLogoFile ] = useState<File | null>(null);
    const [ darkLogoFile, setDarkLogoFile ] = useState<File | null>(null);
    const [ deleteLightLogo, setDeleteLightLogoFile ] = useState(false);
    const [ deleteDarkLogo, setDeleteDarkLogoFile ] = useState(false);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const {
        control, handleSubmit, reset, setError, formState, setValue, watch,
    } = useForm<IAccountSettingsDto>({
        mode: 'onSubmit',
        defaultValues: {
            companyName: companyName ?? '',
            logicalName: accountLogicalName ?? '',
            language: !language || language?.toLowerCase() === 'keys' ? 'en' : language,
        },
    });

    const {
        errors, isDirty,
    } = formState;

    const {
        logicalName: currentLogicalName, companyName: currentCompanyName,
    } = useMemo(
        () => watch([ 'logicalName', 'companyName' ]),
        [ watch ],
    );

    const orgNameIsLogicalName = useMemo(() => process.buildConfigs.orgNameIsOrgLogicalName, []);

    const languageOptions = useSupportedLanguagesMap();

    const onReset = useCallback(() => {
        triggerLogos();
        reset();
        setLightLogoFile(null);
        setDeleteLightLogoFile(false);
        setDarkLogoFile(null);
        setDeleteDarkLogoFile(false);
    }, [ reset, triggerLogos ]);

    const logosSave = useCallback(async () => {
        if (EnablePortalLogoCobrandingAdminUX) {
            const cobrandingPromises = [];
            const tempLogosData = { ...logos };

            if (lightLogoFile) {
                cobrandingPromises.push(uploadLogo('light', lightLogoFile));
                tempLogosData.lightLogo = URL.createObjectURL(lightLogoFile);
            }
            if (deleteLightLogo) {
                cobrandingPromises.push(deleteLogo('light'));
                tempLogosData.lightLogo = undefined;
            }

            if (darkLogoFile) {
                cobrandingPromises.push(uploadLogo('dark', darkLogoFile));
                tempLogosData.darkLogo = URL.createObjectURL(darkLogoFile);
            }
            if (deleteDarkLogo) {
                cobrandingPromises.push(await deleteLogo('dark'));
                tempLogosData.darkLogo = undefined;
            }

            if (lightLogoFile || deleteLightLogo || darkLogoFile || deleteDarkLogo) {
                await Promise.all(cobrandingPromises);

                mutateLogos(tempLogosData);

                setLightLogoFile(null);
                setDeleteLightLogoFile(false);
                setDarkLogoFile(null);
                setDeleteDarkLogoFile(false);
                triggerPortalShellRefresh();
            }
        }
    }, [ darkLogoFile, deleteDarkLogo, deleteLightLogo, lightLogoFile, logos, mutateLogos, triggerPortalShellRefresh ]);

    const onSaveProceed = useCallback(
        async (data: IAccountSettingsDto) => {
            const {
                companyName, logicalName, language: languageFormData,
            } = data;

            // language should never be undefined
            const language = languageFormData ?? 'en';

            try {
                const logicalNameChanged = accountLogicalName !== logicalName;

                if (isDirty) {
                    await updateOrganization({
                        companyName,
                        logicalName,
                        language,
                    });
                }

                await logosSave();
                createNotification(translate({ id: 'CLIENT_ACCOUNT_SETTINGS_CHANGED' }));
                const updateProfile = Object.assign({}, accountProfile);
                updateProfile.accountUserDto.companyName = companyName;
                updateProfile.accountUserDto.accountLogicalName = logicalName;
                updateProfile.accountUserDto.accountLanguage = language;

                // set param in URL to avoid rendering Organization Switching warning dialog
                if (logicalNameChanged) {
                    history.push({ search: '?isOrgNameUpdate=true' });
                }

                setUserProfile(updateProfile);

                reset(data);

                if (logicalNameChanged) {
                    loadOrganizationName(data.logicalName);
                }
            } catch (error) {
                const parsedError: IOrganizationError = await parseErrorObject(error);
                if (parsedError.ErrorCode === ServiceErrorCode.Conflict) {
                    setError('logicalName', { type: 'duplicate' });
                    setErrorMessage(translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }));
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' }));
                }
            } finally {
                setLoading(false);
            }
        },
        [
            accountProfile,
            accountLogicalName,
            history,
            createNotification,
            translate,
            reset,
            setError,
            setErrorMessage,
            logosSave,
            isDirty,
            parseErrorObject,
        ],
    );

    const onSave = useCallback(
        async (data: IAccountSettingsDto) => {
            setLoading(true);

            data.logicalName = data.logicalName.trim();
            data.companyName = data.companyName.trim();

            if (process.buildConfigs.orgNameIsOrgLogicalName && !validateAccountLogicalName(data.companyName)) {
                setError('companyName', { type: 'invalidLogical' });
                setLoading(false);
                return;
            }

            if (!validateAccountLogicalName(data.logicalName)) {
                setError('logicalName', { type: 'invalid' });
                setLoading(false);
                return;
            }

            if (accountLogicalName.toLowerCase() !== data.logicalName.toLowerCase()) {
                history.push(getRoute(RouteNames.OrganizationSettingsWarning));
            } else {
                await onSaveProceed(data);
            }

            setSavedPayload(data);
        },
        [ accountLogicalName, getRoute, history, onSaveProceed, setError ],
    );

    useEffect(() => {
        proceedListener.current = history.listen(async history => {
            if (history.state && (history.state as any)['proceed'] && savedPayload) {
                onSaveProceed(savedPayload);
            } else {
                setLoading(false);
            }
        });

        return () => {
            if (proceedListener.current) {
                proceedListener.current();
            }
        };
    }, [ history, loading, onSaveProceed, savedPayload ]);

    useEffect(() => {
        if (orgNameIsLogicalName) {
            setValue('logicalName', currentCompanyName.toLowerCase());
        }
    }, [ setValue, currentCompanyName, orgNameIsLogicalName ]);

    const formContent = useMemo(() => (
        <>
            {!isAdminRevampEnabled && <Typography
                className={classes.generalSettingsTitle}
                role="heading"
                aria-level={2}>
                {translate({ id: 'CLIENT_GENERAL_SETTINGS' })}
            </Typography>}
            <Controller
                as={TextField}
                control={control}
                rules={{
                    required: true,
                    maxLength: accountSetting.companyNameLength,
                }}
                error={!!errors.companyName}
                name="companyName"
                variant="outlined"
                label={`${translate({ id: 'CLIENT_ORGANIZATION_NAME' })} *`}
                InputLabelProps={{ id: 'organizationNameLabel' }}
                helperText={
                    (errors.companyName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                    || (errors.companyName?.type === 'invalidLogical'
                    && translate(
                        { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME_ORG' },
                        { 0: accountSetting.accountLogicalNameLength },
                    ))
                    || (errors.companyName
                        && translate({ id: 'CLIENT_VALID_COMPANY_NAME' }, { 0: accountSetting.companyNameLength }))
                }
                InputProps={{ className: 'Tall' }}
                fullWidth
                data-cy="organization-settings-form-companyName"
                inputProps={{ 'aria-labelledby': 'organizationNameLabel' }}
            />
            <div className={classes.input}>
                <Typography
                    className={classes.inputLabel}
                    id="logicalNameLabel">
                    {translate({ id: 'CLIENT_URL' })}
                </Typography>
                <div className={classes.flex}>
                    <Controller
                        as={TextField}
                        control={control}
                        rules={{
                            required: true,
                            maxLength: accountSetting.accountLogicalNameLength,
                        }}
                        error={!!errors.logicalName}
                        name="logicalName"
                        variant="outlined"
                        helperText={
                            (errors.logicalName?.type === 'required'
                                && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                            || (errors.logicalName?.type === 'duplicate'
                                && translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }))
                            || (errors.logicalName
                                && translate(
                                    { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME' },
                                    { 0: accountSetting.accountLogicalNameLength },
                                ))
                            || translate({
                                id: process.buildConfigs.disableUserInvite
                                    ? 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT_NO_INVITE'
                                    : 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT',
                            })
                        }
                        FormHelperTextProps={{ className: classes.warningText }}
                        InputProps={{
                            readOnly: orgNameIsLogicalName,
                            className: `Tall ${orgNameIsLogicalName ? 'Mui-disabled' : ''}`,
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.logicalNameAdornment}>
                                    <Typography>
                                        {getMiddleTruncatedString(`${window.location.hostname}/`)}
                                    </Typography>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <UiCopyButton
                                        textToCopy={`${window.location.hostname}/${currentLogicalName}`}
                                        aria-label={translate({ id: 'CLIENT_COPY_LOGICAL_NAME' })}
                                        data-cy="organization-settings-form-logicalName-copy-button"
                                    />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            'aria-labelledby': 'logicalNameLabel',
                            className: `${orgNameIsLogicalName ? 'Mui-disabled' : ''}`,
                        }}
                        fullWidth
                        data-cy="organization-settings-form-logicalName"
                    />
                </div>
            </div>
            {!DisableFeatureFedRamp && (
                <div className={clsx(classes.select)}>
                    <UiSelect
                        control={control}
                        dataCy="organization-settings-form-language"
                        inputLabel={translate({ id: 'CLIENT_LANGUAGE' })}
                        name="language"
                        options={languageOptions}
                        error={!!errors.language}
                        fullWidth
                        isTranslated
                        required={true}
                        helperText={translate({ id: 'CLIENT_LANGUAGE_CHANGE_NOTE_HELPER_TEXT' })}
                    />
                </div>
            )}
            {EnablePortalLogoCobrandingAdminUX && (
                <div className={clsx(classes.input)}>
                    <CobrandingComponent
                        lightLogo={logos?.lightLogo}
                        darkLogo={logos?.darkLogo}
                        lightFile={lightLogoFile}
                        darkFile={darkLogoFile}
                        setLightFile={setLightLogoFile}
                        setDarkFile={setDarkLogoFile}
                        deleteLightLogo={deleteLightLogo}
                        deleteDarkLogo={deleteDarkLogo}
                        setDeleteLightLogo={setDeleteLightLogoFile}
                        setDeleteDarkLogo={setDeleteDarkLogoFile}
                    />
                </div>
            )}
            {!isAdminRevampEnabled && <div className={classes.input}>
                <Typography className={classes.supportIdLabel}>
                    {translate({ id: 'CLIENT_SUPPORT_ID' })}
                </Typography>
                <div className={classes.supportIdContainer}>
                    <Typography
                        className={classes.supportId}
                        data-cy="organization-settings-form-supportId">
                        {supportId}
                    </Typography>
                    <UiCopyButton
                        textToCopy={supportId}
                        aria-label={translate({ id: 'CLIENT_COPY_SUPPORT_ID' })}
                        data-cy="organization-settings-form-supportId-copy-button"
                    />
                </div>
            </div>}
        </>
    ), [
        DisableFeatureFedRamp,
        classes.flex,
        classes.generalSettingsTitle,
        classes.input,
        classes.inputLabel,
        classes.logicalNameAdornment,
        classes.select,
        classes.supportId,
        classes.supportIdContainer,
        classes.supportIdLabel,
        classes.warningText,
        control,
        currentLogicalName,
        darkLogoFile,
        deleteDarkLogo,
        deleteLightLogo,
        errors.companyName,
        errors.language,
        errors.logicalName,
        isAdminRevampEnabled,
        languageOptions,
        lightLogoFile,
        logos?.darkLogo,
        logos?.lightLogo,
        orgNameIsLogicalName,
        supportId,
        translate,
    ]);

    const formActions = useMemo(() => (
        <div className={clsx(isAdminRevampEnabled ? classes.rightAlignedActions : classes.actions)}>
            {(isDirty || darkLogoFile || lightLogoFile || deleteLightLogo || deleteDarkLogo) && (
                <Button
                    className={classes.cancelButton}
                    onClick={onReset}
                    color="primary"
                    data-cy="organization-settings-form-reset-button"
                >
                    {translate({ id: 'CLIENT_RESET' })}
                </Button>
            )}
            <UiProgressButton
                loading={loading}
                disabled={
                    !(
                        isDirty
                        || lightLogoFile
                        || darkLogoFile
                        || deleteDarkLogo
                        || deleteLightLogo
                    )
                    || !!logoError
                }
                type="submit"
                variant="contained"
                data-cy="organization-settings-form-submit-button"
            >
                {translate({ id: !isAdminRevampEnabled ? 'CLIENT_SAVE' : 'CLIENT_SAVE_CHANGES' })}
            </UiProgressButton>
        </div>
    ), [
        classes.actions,
        classes.cancelButton,
        classes.rightAlignedActions,
        darkLogoFile,
        deleteDarkLogo,
        deleteLightLogo,
        isAdminRevampEnabled,
        isDirty,
        lightLogoFile,
        loading,
        logoError,
        onReset,
        translate,
    ]);

    return !isAdminRevampEnabled
        ? <form
            onSubmit={handleSubmit(onSave)}
            className={clsx(classes.root)}>
            {formContent}
            {formActions}
        </form>
        : <UiForm
            className={classes.root}
            onSubmit={handleSubmit(onSave)}
            actions={isDirty || darkLogoFile || lightLogoFile || deleteLightLogo || deleteDarkLogo ? formActions : undefined}
            centerChild={true}
            heightOffset="0px">
            {formContent}
        </UiForm>
    ;
};

export default GeneralSettingsComponent;
