import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ContextTagKeys from 'applicationinsights/out/Declarations/Contracts/Generated/ContextTagKeys';
import { createBrowserHistory } from 'history';

const emailPiiRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/g;
const emailPiiMask = '<PII_EMAIL>';

// For pageview events that has token, we don't want to log them as they have the token.
// calls to /unregistered are cluttering up app insights instances, so we don't log those either
const AI_FILTER_URI_LIST = [ 'token', 'unregistered' ];

let appInsights: ApplicationInsights;

function removeAuthHeader(data: any) {
    delete data?.error?.config?.headers?.Authorization;
}

export function initializeTelemetryPortal() {
    const reactPlugin = new ReactPlugin();

    const noKeyOrString =
        !window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING && !window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;

    const browserHistory = createBrowserHistory({ basename: '' });

    const diagonsticTelemtryEnabled = process.buildConfigs.diagonsticTelemtry;

    appInsights = new ApplicationInsights({
        config: {
            disableTelemetry: process.buildConfigs.telemetry === false || window.env?.APPLICATION_INSIGHTS_ENABLED === 'false',
            instrumentationKey: noKeyOrString ? 'fakeKey' : window.env?.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
            connectionString: noKeyOrString ? 'fakeString' : window.env?.APPLICATION_INSIGHTS_CONNECTION_STRING,
            extensions: [ reactPlugin ],
            enableAutoRouteTracking: true,
            extensionConfig: { [reactPlugin.identifier]: { history: browserHistory } },
            disableExceptionTracking: !diagonsticTelemtryEnabled,
            disableCorrelationHeaders: !diagonsticTelemtryEnabled,
        },
    });

    appInsights.loadAppInsights();

    const tagKeys = new ContextTagKeys();

    appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
        try {
            if (item?.name?.endsWith('Pageview')) {
                const refUri = item?.baseData && item.baseData['refUri'];
                if (refUri && AI_FILTER_URI_LIST.some(uri => refUri.toString().indexOf(uri) > -1)) {
                    return false;
                }
            }

            item.baseData = item.baseData ?? {};
            item.baseData.properties = item.baseData.properties || {};
            item.baseData.properties['Canary'] = window.env?.CANARY;
            item.baseData.properties['ScaleUnitIndex'] = window.env?.SCALE_UNIT_INDEX;
            item.baseData.properties['ScaleUnitId'] = window.env?.SCALE_UNIT_ID;
            item.baseData.properties['Region'] = window.env?.REGION;

            item.tags = item.tags ?? [];
            item.tags[tagKeys.cloudRole] = 'portal-frontend';

            removeAuthHeader(item.data);
            return;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return false;
        }
    });

    appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
        const data = item.baseData;

        if (window.env?.APPLICATION_INSIGHTS_CLUSTER_ID && data) {
            data.properties = data.properties || {};
            data.properties['ClusterId'] = window.env?.APPLICATION_INSIGHTS_CLUSTER_ID;
            data.properties['ApplicationName'] = window.env?.APPLICATION_INSIGHTS_APPLICATION_NAME;
            data.properties['ProductVersion'] = window.env?.APPLICATION_INSIGHTS_PRODUCT_VERSION;
        }
    });

    appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
        try {
            const itemJson = JSON.stringify(item);
            const isEmailPiiFound = itemJson.search(emailPiiRegex) > -1;
            if (isEmailPiiFound) {
                const sanitizedItemString = itemJson.replace(emailPiiRegex, emailPiiMask);
                const itemObj = JSON.parse(sanitizedItemString);
                Object.assign(item, itemObj);
            }
            return;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return false;
        }
    });

    appInsights.context.application = {
        build: window.env?.ENVIRONMENT,
        ver: window.env?.CLOUDRPA_VERSION,
    };
}

export { appInsights as portalTelemetry };

