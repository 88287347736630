import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import licensesAdmin from '../../images/licensesAdmin.svg';
import servicesAdmin from '../../images/servicesAdmin.svg';
import settingsAdmin from '../../images/settingsAdmin.svg';
import { accountType } from '../../store/selectors';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiTenantStatusBanner } from '../common/UiTenantStatusBanner';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

interface ICard {
    title: string;
    titleIcon: string;
    description: string;
    onClickLink: string;
    dataCy: string;
    alt: string;
    invisible?: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        grid: { maxWidth: '1100px' },
        cardIcon: { width: '40px' },
    }),
);

const TenantAdminHome: FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();

    const accountPlan = useSelector(accountType);

    const match = useRouteMatch<{ tenantId: string }>();

    const { id } = useMemo<{ id: string }>(
        () => ({ id: match.params.tenantId }),
        [ match ],
    );
    const { getTenantData } = useTenantsContext();

    const tenant = useMemo(() => getTenantData(id), [ getTenantData, id ]);
    const enableVpnGateway = useFeatureFlagValue(Features.EnableVpnGateway.name);
    const { formatMessage: translate } = useIntl();

    const cardList: ICard[] = [
        {
            title: 'CLIENT_SERVICES',
            titleIcon: servicesAdmin,
            description: 'CLIENT_TENANT_HOME_SERVICES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantServices.replace(':tenantId', id)),
            dataCy: 'tenant-home-services-card',
            alt: 'Services',
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: licensesAdmin,
            description: 'CLIENT_TENANT_HOME_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ServicesUsage.replace(':tenantId', id)),
            dataCy: 'tenant-home-licenses-card',
            alt: 'Licenses',
            invisible: AccountLicense[accountPlan] >= AccountLicense.FREEKIT,
        },
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: settingsAdmin,
            description: 'CLIENT_TENANT_HOME_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantSettings.replace(':tenantId', id)),
            dataCy: 'tenant-home-settings-card',
            alt: 'Settings',
        },
        {
            title: 'CLIENT_VPN_GATEWAY',
            titleIcon: servicesAdmin,
            description: 'CLIENT_TENANT_HOME_VPN_GATEWAY_DESCRIPTION',
            onClickLink: getRoute(RouteNames.VpnGateway.replace(':tenantId', id)),
            dataCy: 'tenant-home-vpn-gateway-card',
            alt: translate({ id: 'CLIENT_VPN_GATEWAY' }),
            invisible: !enableVpnGateway,
        },
    ];

    return (
        <UiPageContainer
            banner={<UiTenantStatusBanner
                status={tenant?.status}
                tenantName={tenant?.name}
            />}
        >
            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible && <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={<img
                            className={classes.cardIcon}
                            src={card.titleIcon}
                            alt='accountsAndGroups' />}
                        description={card.description}
                        onClickLink={() => history.push(card.onClickLink)}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default TenantAdminHome;
