import {
    ILocation,
    IPortalUser,
} from '@experiences/interfaces';
import {
    en,
    IntlWrapperContext,
    LanguageCulture,
} from '@experiences/locales';
import { createBrowserHistory } from 'history';
import React, { PropsWithChildren } from 'react';
import {
    createIntl,
    RawIntlProvider,
} from 'react-intl';
import { Router } from 'react-router';
import { SWRConfig } from 'swr';

export function HookTestWrapper({
    children,
    customLocation,
    customLanguage = 'en',
}: PropsWithChildren<{
    customState?: Partial<IPortalUser & { isHostMode: boolean }>;
    customLocation?: ILocation;
    customLanguage?: LanguageCulture;
}>) {
    const history = createBrowserHistory();

    return (
        <IntlWrapperContext.Provider value={{
            locale: customLanguage,
            setLocale: () => {},
        }}>
            <RawIntlProvider value={createIntl({
                messages: en,
                locale: 'en',
            })}>
                <SWRConfig value={{ provider: () => new Map() }}>
                    <Router history={customLocation ? {
                        ...history,
                        location: customLocation,
                    } : history}>
                        {children}
                    </Router>
                </SWRConfig>
            </RawIntlProvider>
        </IntlWrapperContext.Provider>
    );
}

export default HookTestWrapper;
