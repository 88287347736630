import { TextField } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AuthSettingPasswordKey } from '../../common/constants/AuthSettingConstant';
import {
    getSetting,
    ISetting,
    settingUrl,
} from '../../services/identity/SettingService';
import { accountGlobalId } from '../../store/selectors';
import {
    hasDigit,
    hasLowercase,
    hasSpecialCharacter,
    hasUppercase,
} from '../../util/PasswordUtil';
import { mapSettingArrayToSecurityAuthSettingsData } from '../../util/setting/AuthSettingUtil';

export interface IEditPasswordData {
    password: string;
    passwordRepeat: string;
}

export const defaultEditPasswordData: IEditPasswordData = {
    password: '',
    passwordRepeat: '',
};

export const useEditPasswordStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
    }),
);

const EditPasswordFormComponent: FC<{
    passwordLabel?: string;
    required?: boolean;
    respectPasswordRequirements?: boolean;
}> = ({
    passwordLabel, required = false, respectPasswordRequirements = false,
}) => {
    const {
        control, errors, watch,
    } = useFormContext<IEditPasswordData>();
    const classes = useEditPasswordStyles();
    const {
        formatMessage: translate, formatList,
    } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const keys = useMemo(() => [ AuthSettingPasswordKey.PasswordComplexity ], []);
    const { data: settingArray } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        respectPasswordRequirements ? getSetting : null,
        { shouldRetryOnError: false },
    );
    const passwordComplexity = useMemo(
        () => settingArray && mapSettingArrayToSecurityAuthSettingsData(settingArray)?.password?.passwordComplexity,
        [ settingArray ],
    );

    const defaultPasswordHelperText = useMemo(() => {
        const length = passwordComplexity?.Length;
        const requirements = formatList(
            [
                ...(passwordComplexity?.hasDigit ? [ translate({ id: 'CLIENT_DIGIT_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasLowercase ? [ translate({ id: 'CLIENT_LOWERCASE_CHARACTER_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasSpecialCharacter ? [ translate({ id: 'CLIENT_SPECIAL_CHARACTER_REQUIREMENT' }) ] : []),
                ...(passwordComplexity?.hasUppercase ? [ translate({ id: 'CLIENT_UPPERCASE_CHARACTER_REQUIREMENT' }) ] : []),
                ...(length ? [ translate({ id: 'CLIENT_MIN_CHARACTER_LENGTH_REQUIREMENT' }, { length }) ] : []),
            ],
            {
                style: 'long',
                type: 'conjunction',
            },
        );
        return requirements.length ? translate({ id: 'CLIENT_PASSWORD_REQUIREMENTS' }, { requirements }) : '';
    }, [
        formatList,
        passwordComplexity?.Length,
        passwordComplexity?.hasDigit,
        passwordComplexity?.hasLowercase,
        passwordComplexity?.hasSpecialCharacter,
        passwordComplexity?.hasUppercase,
        translate,
    ]);

    return (
        <>
            <div className={classes.input}>
                <Controller
                    label={passwordLabel ?? translate({ id: 'CLIENT_PASSWORD' })}
                    as={TextField}
                    control={control}
                    name="password"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    required={required}
                    rules={{
                        required: required,
                        minLength: passwordComplexity?.Length,
                        validate: {
                            hasDigit: value => !value || !passwordComplexity?.hasDigit || hasDigit(value),
                            hasLowercase: value => !value || !passwordComplexity?.hasLowercase || hasLowercase(value),
                            hasSpecialCharacter: value =>
                                !value || !passwordComplexity?.hasSpecialCharacter || hasSpecialCharacter(value),
                            hasUppercase: value => !value || !passwordComplexity?.hasUppercase || hasUppercase(value),
                        },
                    }}
                    helperText={
                        errors.password
                            ? (errors.password?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                (errors.password?.type === 'hasDigit' && translate({ id: 'CLIENT_PASSWORD_HAS_DIGIT_ERROR' })) ||
                (errors.password?.type === 'hasLowercase' &&
                  translate({ id: 'CLIENT_PASSWORD_HAS_LOWERCASE_ERROR' })) ||
                (errors.password?.type === 'hasSpecialCharacter' &&
                  translate({ id: 'CLIENT_PASSWORD_HAS_SPECIAL_CHARACTER_ERROR' })) ||
                (errors.password?.type === 'hasUppercase' &&
                  translate({ id: 'CLIENT_PASSWORD_HAS_UPPERCASE_ERROR' })) ||
                (errors.password?.type === 'minLength' &&
                  translate({ id: 'CLIENT_PASSWORD_MIN_LENGTH_ERROR' }, { 0: passwordComplexity?.Length }))
                            : defaultPasswordHelperText
                    }
                    error={!!errors.password}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    InputLabelProps={{ id: 'passwordLabel' }}
                    inputProps={{ 'aria-labelledby': 'passwordLabel' }}
                    data-cy="add-edit-password"
                />
            </div>
            <div className={classes.input}>
                <Controller
                    label={translate({ id: 'CLIENT_CONFIRM_PASSWORD' })}
                    as={TextField}
                    control={control}
                    name="passwordRepeat"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    required={required}
                    rules={{
                        required: required,
                        validate: { match: value => value === watch('password') },
                    }}
                    error={!!errors.passwordRepeat}
                    helperText={
                        errors.password
                            ? (errors.passwordRepeat?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                (errors.passwordRepeat?.type === 'match' && translate({ id: 'CLIENT_PASSWORD_MATCH_ERROR' }))
                            : translate({ id: 'CLIENT_PASSWORD_MATCH_ERROR' })
                    }
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    InputLabelProps={{ id: 'confirmPasswordLabel' }}
                    inputProps={{ 'aria-labelledby': 'confirmPasswordLabel' }}
                    data-cy="add-edit-password-repeat"
                />
            </div>
        </>
    );
};

export default EditPasswordFormComponent;
