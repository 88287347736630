import { MessageTemplateName } from '../common/constants/MessageTemplateConstant';
import {
    ICreateMessageTemplateDto,
    IMessageTemplate,
} from '../common/interfaces/cis/message-template';
import { ILoginCustomizationData } from '../component/organizationsettings/subcomponents/LoginCustomizationComponent';
import { decodeXmlCharacters } from './DecodeSanitizedHtml';

export const mapToLoginCustomizationData = (
    loginLayoutMessageTemplate: IMessageTemplate | undefined,
): ILoginCustomizationData => {
    return { loginLayout: loginLayoutMessageTemplate?.value ? decodeXmlCharacters(loginLayoutMessageTemplate.value) : '' };
};

export const mapLoginCustomizationDataToMessageTemplate = (
    loginCustomizationData: ILoginCustomizationData,
    loginLayoutMessageTemplate: IMessageTemplate,
): IMessageTemplate => {
    return {
        ...loginLayoutMessageTemplate,
        value: loginCustomizationData.loginLayout,
    };
};

export const mapLoginCustomizationDataToCreateMessageTemplateDto = (
    loginCustomizationData: ILoginCustomizationData,
): ICreateMessageTemplateDto => {
    return {
        name: MessageTemplateName.LoginLayout,
        value: loginCustomizationData.loginLayout,
    };
};
