import { IFeatures } from './feature';

export const Features: IFeatures = {
    /**
   * Service feature flags
   */
    EnableServiceAifabric: {
        name: 'enable-service-aifabric',
        defaultValue: true,
        frontend: true,
    },

    EnableMultiregionOrgSignUp: {
        name: 'IsMultiregionOrgCreationEnabledForPortal',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceOrchestrator: {
        name: 'enable-service-orchestrator',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceChatbots: {
        name: 'enable-service-chatbots',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceTestmanager: {
        name: 'enable-service-testmanager',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceInsights: {
        name: 'enable-service-insights',
        defaultValue: false,
        frontend: true,
    },

    EnableServiceFpst: {
        name: 'enable-service-fpst',
        defaultValue: false,
        frontend: true,
    },

    /**
   * Other feature flags
   */
    EnableCORsHeaderForLocalhost: {
        name: 'enableCORsHeaderForLocalhost',
        defaultValue: true,
    },

    EnableClearingOrchestratorSSOCookie: {
        name: 'enableClearingOrchestratorSSOCookie',
        defaultValue: true,
    },

    /** License Stats Aggregation for robot usage */
    EnableSourceBasedRobotLicenseStatsAggregation: {
        name: 'enableSourceBasedRobotLicenseStatsAggregation',
        defaultValue: false,
        frontend: true,
    },

    /** Enables external user licensing */
    EnableExternalUserLicense: {
        name: 'EnableExternalUserLicense',
        defaultValue: false,
        frontend: true,
    },

    /** Enables showing the displayName field in the UI for robots */
    EnableUnattRobotDisplayName: {
        name: 'enableUnattRobotDisplayName',
        defaultValue: false,
        frontend: true,
    },

    EnableEnforceTestConnectionforAAD: {
        name: 'enableEnforceTestConnectionforAAD',
        defaultValue: true,
        frontend: true,
    },

    EnableSmartlingIntegration: {
        name: 'EnableSmartlingIntegration',
        defaultValue: false,
        frontend: true,
    },

    /** Support for Toggling Feature Flags in LocalStorage */
    EnableLocalStorageFeatureFlags: {
        name: 'enableLocalStorageFeatureFlags',
        defaultValue: false,
        frontend: true,
    },

    EnableRecreateDefaultTenant: {
        name: 'enableRecreateDefaultTenant',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Task Capture */
    EnableDuLinks: {
        name: 'enableDuLinks',
        defaultValue: false,
        frontend: true,
    },

    /** Enable auto logout on 401 errors */
    EnableLogoutOn401Errors: {
        name: 'enableLogoutOn401Errors',
        defaultValue: false,
        frontend: true,
    },

    /** Enable show need more service component in add tenant drawer */
    EnableShowNeedMoreServicesComponent: {
        name: 'enableShowNeedMoreServicesComponent',
        defaultValue: false,
        frontend: true,
    },

    /** Enable show benefits panel in request enterprise form */
    EnableShowEnterpriseBenefitsPanel: {
        name: 'enableShowEnterpriseBenefitsPanel',
        defaultValue: false,
        frontend: true,
    },

    /** Enable show need more tenants button */
    EnableNeedMoreTenantsButton: {
        name: 'enableNeedMoreTenantsButton',
        defaultValue: false,
        frontend: true,
    },

    /** Enable idle session check */
    EnableIdleSessionCheck: {
        name: 'enableIdleSessionCheck',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce integration */
    EnableEcommerceIntegration: {
        name: 'enableEcommerceIntegration',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce in US */
    EnableEcommerceInUs: {
        name: 'enableEcommerceInUs',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce in Europe */
    EnableEcommerceInEurope: {
        name: 'enableEcommerceInEurope',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce in World */
    EnableEcommerceInWorld: {
        name: 'enableEcommerceInWorld',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce in Japan */
    EnableEcommerceInJapan: {
        name: 'enableEcommerceInJapan',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce - Incremental license add-ons PLT-9665 */
    EnableEcommerceIncremental: {
        name: 'enableEcommerceIncremental',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Ecommerce - Promotional discounts */
    EnableEcommercePromotionalDiscounts: {
        name: 'enableEcommercePromotionalDiscounts',
        defaultValue: false,
        frontend: true,
    },

    /** Enabled Ecommerce - Robot Units support PLT-14703 */
    EnableEcommerceRobotUnits: {
        name: 'enableEcommerceRobotUnits',
        defaultValue: false,
        frontend: true,
    },

    /** Enable D&B */
    EnableDnBForms: {
        name: 'enableDnBForms',
        defaultValue: false,
        frontend: true,
    },

    /** Enables the copyright text in the app bar on the help page */
    EnableHelpCopyright: {
        name: 'enableHelpCopyright',
        defaultValue: false,
    },

    /** Enables the new Tenant API */
    EnableGetTenantsRedesign: {
        name: 'EnableGetTenantsRedesign',
        defaultValue: false,
        frontend: true,
    },

    /** Enable using packages from CDN */
    EnableCdnPackage: {
        name: 'enable-cdn-package',
        defaultValue: false,
    },

    /** Enable per cloud env CDN for apollo-packages */
    EnableApolloPerEnvCdn: {
        name: 'enable-apollo-per-env-cdn',
        defaultValue: false,
    },

    /** Portal-shell package version */
    PortalShellVersion: {
        name: 'portal-shell-version',
        defaultValue: '2.2.1-hf.1',
    },

    /** Apollo-fonts package version */
    ApolloFontsVersion: {
        name: 'apollo-fonts-version',
        defaultValue: '1.0.7',
    },

    /** Enable Resource Center CMS page */
    EnableResourceCenterCMS: {
        name: 'enableResourceCenterCMS',
        defaultValue: false,
        frontend: true,
    },

    /** Enable Resource Center CMS search bar */
    EnableResourceCenterCMSSearchBar: {
        name: 'enableResourceCenterCMSSearchBar',
        defaultValue: false,
        frontend: true,
    },

    /** Enables the new Resource Center CMS preview option */
    EnableResourceCenterCMSPreview: {
        name: 'enableResourceCenterCMSPreview',
        defaultValue: false,
        frontend: true,
    },

    /** Enable the new Resource Center CMS upgrade box */
    EnableResourceCenterCMSUpgradeBox: {
        name: 'enableResourceCenterCMSUpgradeBox',
        defaultValue: false,
        frontend: true,
    },

    /* Enable Global Login Redirect as opposed to Gov Login Redirect */
    EnableDefaultGlobalLoginRedirect: {
        name: 'enableDefaultGlobalLoginRedirect',
        defaultValue: true,
        frontend: true,
    },

    /* Enable GovCloud routes */
    EnableGovCloudRoutes: {
        name: 'enableGovCloudRoutes',
        defaultValue: false,
        frontend: true,
    },

    /* Enable Global App routes */
    EnableGlobalAppRoutes: {
        name: 'enableGlobalAppRoutes',
        defaultValue: true,
        frontend: true,
    },

    /* Enable Airgapped license activation for gov */
    EnableAirgappedLicenseActivation: {
        name: 'GovCloudOperation.UseAirgappedLicenseActivation',
        defaultValue: false,
        frontend: true,
    },

    /* Enable enterprise license activation for cloud */
    EnableCloudEnterpriseLicenseActivation: {
        name: 'EnableCloudEnterpriseLicenseActivation',
        defaultValue: false,
        frontend: true,
    },

    /** Enable updated stable download link for Community cards */
    EnableUpdatedCommunityStableDownloadLink: {
        name: 'enableUpdatedCommunityStableDownloadLink',
        defaultValue: false,
        frontend: true,
    },

    EnableXHRWithOrgName: {
        name: 'enable-xhr-with-org-name',
        defaultValue: false,
        frontend: true,
    },

    ShowServerllessControlPlane: {
        name: 'show-serverlesscontrolplane',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings page */
    EnableSecuritySettingsPage: {
        name: 'enableSecuritySettingsPage',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings new AAD */
    EnableNewSecuritySettingsAAD: {
        name: 'enableNewSecuritySettingsAAD',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings session policy control */
    EnableSecuritySettingsSessionPolicy: {
        name: 'enableSecuritySettingsSessionPolicy',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings IP restriction control */
    EnableSecuritySettingsIPRestriction: {
        name: 'enableSecuritySettingsIPRestriction',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings IP restriction Audit option */
    EnableSecuritySettingsIPRestrictionAudit: {
        name: 'enableSecuritySettingsIPRestrictionAudit',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings for idle timeout */
    EnableIdleTimeout: {
        name: 'enableIdleTimeout',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings for concurrent browser sessions */
    EnableConcurrentSessions: {
        name: 'enableConcurrentSessions',
        defaultValue: false,
        frontend: true,
    },

    /* Enable security settings encryption control */
    EnableSecuritySettingsEncryption: {
        name: 'enableSecuritySettingsEncryption',
        defaultValue: false,
        frontend: true,
    },

    /** Enable SAML HTTP Post  */
    EnableSamlHttpPost: {
        name: 'enableSamlHttpPost',
        defaultValue: false,
        frontend: true,
    },

    ShowInviteUserButton: {
        name: 'show-invite-user-button',
        defaultValue: true,
        frontend: true,
    },

    /** Enable adding directory users */
    EnableAddDirectoryUsers: {
        name: 'enableAddDirectoryUsers',
        defaultValue: false,
        frontend: true,
    },

    /** Enable PM users management link */
    EnablePMUsersLink: {
        name: 'enable-pm-users-link',
        defaultValue: false,
        frontend: true,
    },

    EnableInMemoryStorage: {
        name: 'enable-in-memory-storage',
        defaultValue: false,
        frontend: true,
    },

    /** Disable features for gov cloud */
    DisableFeatureFedRamp: {
        name: 'disable-feature-fed-ramp',
        defaultValue: false,
        frontend: true,
    },

    /** Disable the Google, Microsoft, and invite auth settings */
    DisableGoogleMicrosoftAndInviteAuthSettings: {
        name: 'disableGoogleMicrosoftAndInviteAuthSettings',
        defaultValue: false,
        frontend: true,
    },

    /** Disable the users tab */
    DisableUsersTab: {
        name: 'disableUsersTab',
        defaultValue: false,
        frontend: true,
    },

    /** Enable new sign up flow for gov cloud */
    EnableNewSignUpGovCloud: {
        name: 'enableNewSignUpGovCloud',
        defaultValue: false,
        frontend: true,
    },

    /** Enable notification settings page */
    EnableNotificationSettings: {
        name: 'enableNotificationSettings',
        defaultValue: false,
        frontend: true,
    },

    /** Enable notification settings page */
    EnableNotificationSettingsUiV2: {
        name: 'enableNotificationSettingsUiV2',
        defaultValue: false,
        frontend: true,
    },

    /** Enable notification settings page */
    EnableNotificationProxy: {
        name: 'apollo-enableNotificationProxy',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable notification settings page title with Preview Suffix */
    DisableNotificationPreview: {
        name: 'disableNotificationPreview',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    /** Enable enterprise trial in the app bar */
    EnableAppBarEnterpriseTrial: {
        name: 'enable-appbar-enterprise-trial',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    /** Enable Insights Trial for user */
    EnableInsightsTrial: {
        name: 'enable-insights-trial-for-enterprise',
        defaultValue: false,
        frontend: true,
    },

    /** Enables account type in the left nav API */
    EnablePortalShellApiAccountType: {
        name: 'enable-portal-shell-api-account-type',
        defaultValue: true,
        frontend: false,
    },

    /** Enable the access token to be stored in local storage */
    EnableAccessTokenLocalStorage: {
        name: 'enableAccessTokenLocalStorage',
        defaultValue: false,
        frontend: true,
    },

    /** Enable the privacy and security settings page */
    EnablePrivacyAndSecuritySettings: {
        name: 'enable-privacy-and-security-settings',
        defaultValue: false,
        frontend: true,
    },

    /** Enables showing the usage charts and allocation details for Robot Units */
    EnableRobotUnits: {
        name: 'EnableRobotUnits',
        defaultValue: false,
        frontend: true,
    },

    /** Enable the fake language to show localization keys */
    EnableLocKeysLanguage: {
        name: 'enable-loc-keys-language',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },

    EnableAcceptInviteOrgPicker: {
        name: 'enable-accept-invite-org-picker',
        defaultValue: false,
        frontend: true,
    },

    EnableExtAppClientMultiSecrets: {
        name: 'EnableExtAppClientMultiSecrets',
        defaultValue: false,
        frontend: true,
    },

    EnableOrgMigration: {
        name: 'enable-org-migration',
        defaultValue: false,
        frontend: true,
    },

    IncludeRoboticsOpsToSelfServeOrgMigration: {
        name: 'roboticsops.EnableSelfServeOrgMigration',
        defaultValue: false,
        frontend: true,
    },

    /** Enable the toggle switch for the new Admin revamp */
    EnableAdminRevampToggle: {
        name: 'enableAdminRevampToggle',
        defaultValue: false,
        frontend: true,
    },

    EnableTenantCreateLicenseAllocation: {
        name: 'EnableTenantCreateLicenseAllocation',
        defaultValue: false,
        frontend: true,
    },

    EnableDisplayName: {
        name: 'EnableDisplayName',
        defaultValue: false,
        frontend: true,
    },

    EnableRetryFailedService: {
        name: 'enableRetryFailedService',
        defaultValue: false,
        frontend: true,
    },

    EnableReferenceTokens: {
        name: 'enableReferenceTokens',
        defaultValue: false,
        frontend: true,
    },

    EnableAsyncOrganizationCreationAH: {
        name: 'EnableAsyncOrganizationCreationAH',
        defaultValue: false,
        frontend: true,
    },

    EnableSecuritySettingsRevamp: {
        name: 'EnableSecuritySettingsRevamp',
        defaultValue: false,
        frontend: true,
    },

    EnableDisableOrchestrator: {
        name: 'EnableDisableOrchestrator',
        defaultValue: false,
        frontend: true,
    },

    EnableOffersRevamp: {
        name: 'enableOffersRevamp',
        defaultValue: false,
        frontend: true,
    },

    EnableOffersRevampAdmin: {
        name: 'EnableOffersRevampAdmin',
        defaultValue: false,
        frontend: true,
    },

    EnableHomePageCarousel:
    {
        name: 'EnableHomePageCarousel',
        defaultValue: true,
        frontend: true,
    },

    EnableHomePageRevamp:
    {
        name: 'EnableHomePageCarousel',
        defaultValue: true,
        frontend: true,
    },

    EnableAdminRevampDefaultExperience: {
        name: 'enableAdminRevampDefaultExperience',
        defaultValue: false,
        frontend: true,
    },

    EnableCanaryTenant: {
        name: 'EnableCanaryTenant',
        defaultValue: false,
        frontend: true,
    },

    EnableVpnGateway: {
        name: 'enableVpnGateway',
        defaultValue: false,
        frontend: true,
    },

    AsyncEditTenantEnabled: {
        name: 'AsyncEditTenantEnabled',
        defaultValue: false,
        frontend: true,
    },

    IsAsyncTenantDeletionEnabled: {
        name: 'IsAsyncTenantDeletionEnabled',
        defaultValue: false,
        frontend: true,
    },

    EnableAsyncChangeTenantStatus: {
        name: 'EnableAsyncChangeTenantStatus',
        defaultValue: false,
        frontend: true,
    },

    AsyncTenantCreationByDTFEnabled: {
        name: 'AsyncTenantCreationByDTFEnabled',
        defaultValue: false,
        frontend: true,
    },

    EnableAsyncOrganizationCreation: {
        name: 'EnableAsyncOrganizationCreation',
        defaultValue: false,
        frontend: true,
    },

    /** Shows the help icon in the app bar if it is on */
    EnableAppBarHelpIcon: {
        name: 'enable-appbar-help-icon',
        defaultValue: false,
        portalShell: true,
    },

    /** Shows push notification */
    EnableNotification: {
        name: 'enableNotificationSettings',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable Single Session Enforcement */
    EnablePortalShellSingleSession: {
        name: 'enable-portal-shell-single-session',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable Local storage overrides for env config fields */
    EnablePortalShellLocalStorageOverrides: {
        name: 'enable-portal-shell-localstorage-overrides',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable using self auth in people picker */
    EnablePortalShellSelfAuthPeoplePicker: {
        name: 'enable-portal-shell-self-auth-people-picker',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable Idle Timeout */
    EnablePortalShellIdleTimeout: {
        name: 'enable-portal-shell-idle-timeout',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable Global Focus */
    EnableGlobalFocus: {
        name: 'enable-global-focus',
        defaultValue: false,
        portalShell: true,
    },

    /* Enables using parameters parsed from the URL */
    EnablePortalShellUrlParams: {
        name: 'enable-portal-shell-url-params',
        defaultValue: true,
        portalShell: true,
    },

    /* Enables a 48px app-bar */
    EnableAppBarThinner: {
        name: 'enable-appbar-thinner',
        defaultValue: true,
        portalShell: true,
    },

    EnablePeoplePickerIdentityBaseUrlOverride: {
        name: 'apollo-enablePeoplePickerIdentityBaseUrlOverride',
        defaultValue: false,
        portalShell: true,
    },

    /** Enable insights log export from admin */
    EnableAdminInsightsExport: {
        name: 'enableAdminInsightsExport',
        defaultValue: false,
        frontend: true,
    },

    /** Enables task mining as an unlicensed service */
    EnableTaskminingIsNoLongerALicensedService: {
        name: 'taskminingIsNoLongerALicensedService',
        defaultValue: false,
        frontend: true,
    },

    /** Enables the zh-TW language */
    EnableLanguageZhTw: {
        name: 'enable-language-zh-tw',
        defaultValue: false,
        frontend: true,
        portalShell: true,
    },
};

export const DefaultValuesMap = Object.keys(Features).reduce((accumulator, current) => {
    const value = Features[current];
    accumulator[value.name] = value.defaultValue;
    return accumulator;
}, {} as { [key: string]: boolean | string });

export const FrontendFeatureFlags = Object.values(Features).filter(featureInformation => featureInformation.frontend);

export const PortalShellFeatureFlags = Object.values(Features).filter(featureInformation => featureInformation.portalShell);

export const CookieEnabledFeatures = Object.values(Features)
    .filter(value => value.cookieOptIn)
    .map(value => value.name);
