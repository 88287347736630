import { getUserLanguageFromLocalStorage } from '@experiences/locales';
import { AxiosRequestConfig } from 'axios';

import { isBrowserIE } from '../static/CheckBrowser';

export async function getHeaders(token: string, headers?: HeadersInit | AxiosRequestConfig) {
    const reqHeaders: Record<string, string> = {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'x-uipath-localization': getUserLanguageFromLocalStorage(),
    };

    if (isBrowserIE()) {
        reqHeaders['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        reqHeaders['Pragma'] = 'no-cache';
    }

    if ((headers as Record<string, string>)?.extendRequestHeaders === 'true') {
        delete (headers as Record<string, string>)['extendRequestHeaders'];
        headers = {
            ...reqHeaders,
            ...headers,
        };
    }

    return headers ?? reqHeaders;
}
