/* eslint-disable react/no-unstable-nested-components */
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiDataContextProvider,
    UiProgressButton,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    renderRoutes,
    RouteConfigComponentProps,
} from 'react-router-config';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import useSWR from 'swr';

import {
    CharacterLimitForNames,
    notificationType,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import {
    ExternalApiResourceType,
    IExternalClient,
    IGridResource,
    IResource,
    ISecret,
} from '../../../common/interfaces/cis/client';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import {
    createClientSecret,
    createExternalClient,
    deleteClientSecret,
    editExternalClient,
    externalClientUrl,
    getExternalClientById,
} from '../../../services/identity/ExternalClientService';
import { accountGlobalId } from '../../../store/selectors';
import { timestampToDate } from '../../../util/DateUtil';
import UiCopyButton from '../../common/UiCopyButton';
import UiForm from '../../common/UiForm';
import { UiGrid } from '../../common/UiGrid';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../../common/UiPanel/UiPanel';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';

const useStyles = makeStyles(theme =>
    createStyles({
        loader: { margin: 'auto' },
        headerLayout: {
            display: 'flex',
            flexDirection: 'column',
        },
        breadcrumbText: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
            marginTop: '8px',
        },
        input: { marginTop: '24px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputHeader: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        secretsHeader: {
            display: 'flex',
            marginBottom: '12px',
        },
        secretsLabel: { marginTop: '12px' },
        rowGroup: {
            display: 'flex',
            flexDirection: 'row',
        },
        body: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        copyText: {
            fontWeight: 'bold',
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
        cancelButton: { marginRight: '10px' },
        scopeCell: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
        },
        tooltipCell: {
            flex: 1,
            minWidth: 0,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        secretCell: {
            flex: 'auto',
            minWidth: 0,
            textAlign: 'left',
        },
    }),
);

interface IResourceForm {
    name: string;
    isConfidential: boolean;
    redirectUri: string;
    scopes: IGridResource[];
}

export interface IExternalAppDataContext {
    resources: IGridResource[] | undefined;
    confidential: boolean;
}

const SECRETS_TABLE_HEIGHT_PX = '330px';

const CreateEditExternalApplicationsFormComponent: React.FC<{
    type: 'add' | 'edit';
    id: string;
    clientData: IExternalClient | undefined;
    externalResources: IResource[] | undefined;
}> = ({
    type, id, clientData, externalResources,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();

    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();

    const partitionGlobalId = useSelector(accountGlobalId);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const enableExtAppClientMultiSecrets = useFeatureFlagValue(Features.EnableExtAppClientMultiSecrets.name);

    const { enqueueSnackbar } = useSnackbar();
    const createNotification = useCallback(
        (message: string | React.ReactNode, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const secretNotification = useCallback((label: React.ReactNode) => {
        createNotification(label, notificationType.SUCCESS);
    }, [ createNotification ]);

    const [ loading, setLoading ] = useState(false);
    const [ generatedSecrets, setGeneratedSecrets ] = useState<ISecret[]>([]);

    const secretsData = useMemo(() =>
        clientData?.secrets?.map(data => generatedSecrets.find(s => s.id === data.id) ?? data) ?? [], [
        clientData?.secrets,
        generatedSecrets,
    ]);

    const {
        error,
        mutate,
    } = useSWR<IExternalClient, Error>(
        type === 'edit' && id ? [ `${externalClientUrl}/${id}`, partitionGlobalId, id ] : null,
        getExternalClientById,
    );

    const {
        register, control, handleSubmit, reset, errors, formState: {
            isDirty, isValid,
        }, setValue, setError, clearErrors, watch, trigger,
    } =
    useForm<IResourceForm>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            isConfidential: true,
            redirectUri: '',
            scopes: [],
        },
        shouldUnregister: false,
    });

    const {
        scopes, isConfidential: confidential, redirectUri,
    } = useMemo(() => watch(), [ watch ]);

    const {
        data: gridResourceData, setData,
    } = useUiDataContext<IExternalAppDataContext>();

    useEffect(() => {
        if (type === 'edit' && clientData) {
            const gridScopes = clientData.resources?.map(resource => {
                return {
                    name: resource.name,
                    userScopes: resource.scopes
                        ?.filter(
                            scope => scope.type === ExternalApiResourceType.User
                                || scope.type === ExternalApiResourceType.UserAndApplication,
                        )
                        .map(scope => scope.name),
                    applicationScopes: resource.scopes
                        ?.filter(
                            scope => scope.type === ExternalApiResourceType.Application
                            || scope.type === ExternalApiResourceType.UserAndApplication,
                        )
                        .map(scope => scope.name),
                };
            });

            reset({
                name: clientData.name,
                isConfidential: clientData.isConfidential,
                redirectUri: clientData.redirectUri ?? '',
                scopes: gridScopes,
            });

            setData({
                resources: gridScopes,
                confidential: clientData.isConfidential,
            });
        }
    }, [ clientData, reset, setData, type ]);

    useEffect(() => {
        if (gridResourceData.resources) {
            setValue('scopes', gridResourceData.resources, { shouldDirty: true });
            if (gridResourceData.resources.filter(scopeIterator => scopeIterator.userScopes.length).length <= 0) {
                clearErrors('redirectUri');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ gridResourceData.resources ]);

    const openScopesDrawer = useCallback(
        (resource?: IGridResource) => {
            const ScopeDrawerRoute = RouteNames.ExternalApplicationScopes
                .replace(':type(add|edit)', type)
                .replace('/:id?', id ? `/${id}` : '')
                .replace('/:name?', resource ? `/${resource.name}` : '');
            setData({
                resources: scopes,
                confidential,
            });
            history.push(getRoute(ScopeDrawerRoute));
        },
        [ confidential, getRoute, history, id, scopes, setData, type ],
    );

    const onGenerateSecret = useCallback(
        async () => {
            const payload = {
                clientId: id,
                partitionGlobalId: partitionGlobalId,
            };
            const returnPayload = await createClientSecret(payload);
            const creationTime = (new Date()).toUTCString();
            setGeneratedSecrets(data => [
                {
                    ...returnPayload,
                    creationTime,
                }, ...data,
            ]);
            secretNotification(<div>
                {translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_GENERATED_NOTIFICATION' })}
                <CopyToClipboard text={returnPayload.secret}>
                    <Typography className={classes.copyText}>
                        {translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_COPY' })}
                    </Typography>
                </CopyToClipboard>
            </div>);
            mutate();
        }, [ classes.copyText, id, mutate, partitionGlobalId, secretNotification, translate ],
    );

    const onDeleteSecret = useCallback(
        async (data) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SECRET_TITLE' }),
                body: <Typography>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SECRET' }, { 0: data.id })}
                </Typography>,
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                await deleteClientSecret(data.id, partitionGlobalId);
                setGeneratedSecrets(
                    generatedSecrets.filter(secrets => secrets.secret !== data.secret),
                );
                secretNotification(<div>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_REMOVED_NOTIFICATION' })}
                </div>);
                mutate();
            }
        }, [ createDialog, generatedSecrets, mutate, partitionGlobalId, secretNotification, translate ],
    );

    const onSubmit = useCallback(
        async (data: IResourceForm) => {
            const payload = {
                name: data.name.trim(),
                redirectUri: data.redirectUri.trim() || '',
                isConfidential: data.isConfidential,
                partitionGlobalId,
                scopes: scopes.flatMap(scope =>
                    scope.applicationScopes
                        .map(val => ({
                            name: val,
                            type: ExternalApiResourceType.Application,
                        }))
                        .concat(
                            scope.userScopes.map(val => ({
                                name: val,
                                type: ExternalApiResourceType.User,
                            })),
                        ),
                ),
            };

            let returnPayload: IExternalClient | undefined = undefined;
            try {
                setLoading(true);

                if (type === 'add') {
                    returnPayload = await createExternalClient(payload);
                } else if (clientData) {
                    await editExternalClient(clientData.id!, payload);
                }
            } catch (error) {
                if ((error as any)?.response?.status !== 409) {
                    let errorMessage = await getErrorMessage(error);
                    if (errorMessage.includes('RedirectUri=')) {
                        errorMessage = translate({ id: 'CLIENT_REDIRECT_URI_INVALID' });
                    }
                    setErrorMessage(errorMessage);
                } else {
                    setError('name', { type: 'conflict' });
                }
                setLoading(false);
                return;
            }

            setLoading(false);
            history.push({
                pathname: getRoute(RouteNames.ExternalApplications),
                state: returnPayload ? { externalClient: returnPayload } : undefined,
            });
        },
        [
            partitionGlobalId,
            scopes,
            history,
            getRoute,
            type,
            clientData,
            getErrorMessage,
            setErrorMessage,
            translate,
            setError,
        ],
    );

    return (error || (type === 'edit' && !id)) ? (
        <div className={classes.body}>
            <Typography>
                {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
            </Typography>
            <Button
                variant="outlined"
                size="small"
                onClick={() => {
                    mutate();
                }}
                style={{ marginTop: '12px' }}
            >
                {translate({ id: 'CLIENT_RETRY' })}
            </Button>
        </div>
    ) : (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            id="editResource"
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.cancelButton}
                        color="primary"
                        onClick={() => history.push(getRoute(RouteNames.ExternalApplications))}
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        disabled={
                            !isDirty
                            || !isValid
                            || (!process.buildConfigs.enableScopelessApp && scopes.length <= 0)
                            || (scopes?.filter(scope => scope.userScopes.length).length > 0 && !redirectUri?.trim())
                        }
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        data-cy="add-edit-submit-button"
                    >
                        {type === 'add' ? translate({ id: 'CLIENT_ADD' }) : translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            centerChild={isAdminRevampEnabled}
        >
            <div className={classes.input}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="applicationNameLabel">
                    {translate({ id: 'CLIENT_EXTERNAL_APPLICATION_NAME' })}
                </Typography>
                <TextField
                    inputRef={register({
                        required: true,
                        validate: value => !!value.trim(),
                    })}
                    name="name"
                    required
                    error={!!errors.name}
                    helperText={(errors.name?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                        || (errors.name?.type === 'invalid'
                            && translate({ id: 'CLIENT_WARN_NAME' }, { 0: CharacterLimitForNames.maxNameLength }))
                        || (errors.name?.type === 'conflict' && translate({ id: 'CLIENT_EXTERNAL_APP_EXISTS' }))}
                    variant="outlined"
                    style={{ width: isAdminRevampEnabled ? '75%' : '50%' }}
                    size="small"
                    data-cy="external-applications-name-field"
                    inputProps={{ 'aria-labelledby': 'applicationNameLabel' }}
                />
            </div>
            {type === 'edit' && clientData && (
                <div>
                    {enableExtAppClientMultiSecrets
                         && (
                             <div>
                                 <div className={classes.input}>
                                     <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                                         {translate({ id: 'CLIENT_EXTERNAL_APP_APP_ID' })}
                                     </Typography>
                                     <Typography variant="body1">
                                         {clientData.id}
                                     </Typography>
                                 </div>
                                 <div className={classes.secretsHeader}>
                                     <Typography className={clsx(classes.inputLabel, classes.secretsLabel)}>
                                         {translate({ id: 'CLIENT_EXTERNAL_APP_APP_SECRETS' })}
                                     </Typography>
                                     <Button
                                         style={{ marginLeft: 'auto' }}
                                         variant="outlined"
                                         data-cy="generate-secrets-button"
                                         onClick={() => onGenerateSecret()}>
                                         {translate({ id: 'CLIENT_EXTERNAL_APP_GENERATE_SECRET' })}
                                     </Button>
                                 </div>
                                 <UiGrid<ISecret>
                                     dataCy="secrets-ui-grid"
                                     tableHeight={
                                         secretsData.length === 0 ? ('120px') : ((secretsData.length <= 4) ? `${60 * secretsData.length + 60}px` : SECRETS_TABLE_HEIGHT_PX)
                                     }
                                     columns={[
                                         {
                                             accessor: 'id',
                                             Header: translate({ id: 'CLIENT_EXTERNAL_APP_SECRET_ID' }),
                                             disableSortBy: true,
                                             width: 25,
                                         },
                                         {
                                             accessor: 'secret',
                                             Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_VALUE' }),
                                             width: 30,
                                             disableSortBy: true,
                                             Cell: ({ row }) => {
                                                 return (
                                                     <div>
                                                         {
                                                             !row.original.secret ? (
                                                                 <Typography>
                                                                    ******
                                                                 </Typography>
                                                             ) : (
                                                                 <div className={classes.scopeCell}>
                                                                     <Typography className={classes.secretCell}>
                                                                         {row.original.secret}
                                                                     </Typography>
                                                                     <div>
                                                                         <UiCopyButton
                                                                             textToCopy={row.original.secret}
                                                                             data-cy="secret-copy"
                                                                         />
                                                                     </div>
                                                                 </div>
                                                             )
                                                         }
                                                     </div>
                                                 );
                                             },
                                         },
                                         {
                                             accessor: 'creationTime',
                                             Header: translate({ id: 'CLIENT_CREATION_DATE' }),
                                             width: 30,
                                             disableSortBy: true,
                                             Cell: ({ row }) => {
                                                 return (
                                                     <div>
                                                         {timestampToDate(row.original.creationTime)}
                                                     </div>
                                                 );
                                             },
                                         },
                                     ]}
                                     data={secretsData}
                                     rowActions={[
                                         {
                                             type: ButtonType.Icon,
                                             label: translate({ id: 'CLIENT_DELETE' }),
                                             tooltip: translate({ id: 'CLIENT_DELETE' }),
                                             actionType: GridActionType.Row,
                                             icon: <DeleteForeverOutlinedIcon />,
                                             click: row => {
                                                 onDeleteSecret(row.original);
                                             },
                                             dataCy: 'action-delete-secrets',
                                         },
                                     ]}
                                 />
                             </div>
                         )}
                    {!enableExtAppClientMultiSecrets && (
                        <div className={clsx(classes.input, classes.rowGroup)}>
                            <div style={{ marginRight: '120px' }}>
                                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                                    {translate({ id: 'CLIENT_EXTERNAL_APP_APP_ID' })}
                                </Typography>
                                <Typography variant="body1">
                                    {clientData.id}
                                </Typography>
                            </div>
                            {clientData.isConfidential && (
                                <div>
                                    <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                                        {translate({ id: 'CLIENT_EXTERNAL_APP_APP_SECRET' })}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{ marginBottom: '8px' }}>
                                        {generatedSecrets[0]?.secret}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        onClick={() => onGenerateSecret()}
                                    >
                                        {translate({ id: 'CLIENT_EXTERNAL_APP_GENERATE_SECRET' })}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className={classes.input}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_TYPE' })}
                </Typography>
                <Controller
                    render={({
                        onChange, value,
                    }) => (
                        <RadioGroup
                            className={classes.rowGroup}
                            value={value}
                            onChange={event => {
                                onChange(event.target.value === 'true');
                            }}
                        >
                            <FormControlLabel
                                style={{ marginRight: '44px' }}
                                control={<Radio
                                    value={true}
                                    disabled={type === 'edit'}
                                    checked={value === true}
                                    color="primary" />}
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_CONFIDENTIAL_APPLICATION' })}
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        value={false}
                                        disabled={type === 'edit'}
                                        checked={value === false}
                                        color="primary" />
                                }
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_NON_CONFIDENTIAL_APPLICATION' })}
                            />
                        </RadioGroup>
                    )}
                    control={control}
                    name="isConfidential"
                    defaultValue={true}
                />
            </div>
            <div className={classes.input}>
                <Typography className={clsx(classes.inputHeader, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_EXTERNAL_APP_RESOURCES' })}
                </Typography>
                <Controller
                    control={control}
                    name="scopes"
                    render={() => (
                        <UiGrid<IGridResource>
                            dataCy="scopes-ui-grid"
                            tableHeight={`${60 * scopes?.length + 60}px`}
                            columns={[
                                {
                                    accessor: 'name',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_NAME' }),
                                    disableSortBy: true,
                                    width: 30,
                                },
                                {
                                    accessor: 'userScopes',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE' }),
                                    width: 35,
                                    disableSortBy: true,
                                    Cell: ({ row }) => {
                                        return (
                                            <div className={classes.scopeCell}>
                                                <Tooltip
                                                    className={classes.tooltipCell}
                                                    arrow
                                                    placement="bottom-start"
                                                    title={row.original.userScopes.join(', ')}
                                                >
                                                    <Typography>
                                                        {row.original.userScopes.join(', ')}
                                                    </Typography>
                                                </Tooltip>
                                                {!!row.original.userScopes.length && (
                                                    <CopyToClipboard text={row.original.userScopes.join(' ')}>
                                                        <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                                                            <IconButton>
                                                                <FileCopyOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    accessor: 'applicationScopes',
                                    Header: translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_SCOPE' }),
                                    width: 35,
                                    disableSortBy: true,
                                    Cell: ({ row }) => {
                                        return (
                                            <div className={classes.scopeCell}>
                                                <Tooltip
                                                    className={classes.tooltipCell}
                                                    arrow
                                                    placement="bottom-start"
                                                    title={row.original.applicationScopes.join(', ')}
                                                >
                                                    <Typography>
                                                        {row.original.applicationScopes.join(', ')}
                                                    </Typography>
                                                </Tooltip>
                                                {!!row.original.applicationScopes.length && (
                                                    <CopyToClipboard text={row.original.applicationScopes.join(' ')}>
                                                        <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                                                            <IconButton>
                                                                <FileCopyOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={scopes}
                            tableActions={(isAdminRevampEnabled || undefined) &&
                             [
                                 {
                                     type: ButtonType.ButtonWithIcon,
                                     label: translate({ id: 'CLIENT_EXTERNAL_APP_ADD_SCOPES' }),
                                     tooltip: translate({ id: 'CLIENT_EXTERNAL_APP_ADD_SCOPES' }),
                                     actionType: GridActionType.Main,
                                     icon: <AddIcon />,
                                     click: () => openScopesDrawer(),
                                     dataCy: 'add-scope-button',
                                 },
                             ]}
                            rowActions={[
                                {
                                    type: ButtonType.Icon,
                                    label: translate({ id: 'CLIENT_EDIT' }),
                                    tooltip: translate({ id: 'CLIENT_EDIT' }),
                                    actionType: GridActionType.Row,
                                    icon: <EditOutlinedIcon />,
                                    click: row => openScopesDrawer(row.original),
                                    dataCy: 'action-edit-scopes',
                                },
                                {
                                    type: ButtonType.Icon,
                                    label: translate({ id: 'CLIENT_DELETE' }),
                                    tooltip: translate({ id: 'CLIENT_DELETE' }),
                                    actionType: GridActionType.Row,
                                    icon: <DeleteForeverOutlinedIcon />,
                                    click: async row => {
                                        const proceed = await createDialog({
                                            title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SCOPE_TITLE' }),
                                            body: <Typography>
                                                {translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_SCOPE' })}
                                            </Typography>,
                                            icon: 'warning',
                                            primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
                                            showCancel: true,
                                        });

                                        if (proceed) {
                                            const filteredScopes = scopes.filter(scope => scope.name !== row.original.name);
                                            setValue('scopes', filteredScopes, { shouldDirty: true });
                                            await trigger('redirectUri');
                                        }
                                    },
                                    dataCy: 'action-delete-scopes',
                                },
                            ]}
                        />
                    )}
                />
                {!isAdminRevampEnabled && <Button
                    variant="text"
                    color="primary"
                    onClick={() => openScopesDrawer()}
                    disabled={!externalResources?.filter(
                        resource => !scopes.length || !scopes?.find(scope => scope.name === resource.name),
                    ).length}
                    data-cy="add-scope-button"
                >
                    {translate({ id: 'CLIENT_EXTERNAL_APP_ADD_SCOPES' })}
                </Button>}
            </div>
            <div className={classes.input}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="redirectUrlLabel">
                    {translate({ id: 'CLIENT_EXTERNAL_APP_REDIRECT_URL' })}
                </Typography>
                <TextField
                    inputRef={register({
                        validate: value => !scopes?.filter(scope => scope.userScopes.length).length
                            || !!value?.trim()
                            || translate({ id: 'CLIENT_REQUIRED_REDIRECT_URL_FIELD' }),
                    })}
                    name="redirectUri"
                    error={!!errors.redirectUri}
                    helperText={
                        errors.redirectUri?.message ?? translate({ id: 'CLIENT_EXTERNAL_APP_REDIRECT_URI_HELPER' })
                    }
                    variant="outlined"
                    style={{ width: isAdminRevampEnabled ? '75%' : '50%' }}
                    size="small"
                    data-cy="external-applications-redirectUri-field"
                    inputProps={{ 'aria-labelledby': 'redirectUrlLabel' }}
                />
            </div>
            {type === 'add' && (
                <div className={classes.input}>
                    <Typography>
                        {translate({ id: 'CLIENT_EXTERNAL_APP_REGISTER_NEW' })}
                    </Typography>
                </div>
            )}
        </UiForm>
    );
};

export const CreateEditExternalApplicationsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const match = useRouteMatch<{ type: 'add' | 'edit'; id: string }>();
    const getRoute = useRouteResolver();

    const partitionGlobalId = useSelector(accountGlobalId);
    const organizationName = useOrganizationName();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const {
        type, id,
    } = useMemo(() => ({
        type: match.params.type,
        id: match.params.id,
    }), [ match ]);

    const { data: clientData } = useSWR<IExternalClient, Error>(
        type === 'edit' && id ? [ `${externalClientUrl}/${id}`, partitionGlobalId, id ] : null,
        getExternalClientById,
    );

    const { data: externalResources } = useSWR<IResource[], Error>(
        externalApiResourceUrl,
        getExternalApiResources,
    );

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.ExternalApplications,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
        {
            link: RouteNames.ExternalApplicationAddEdit,
            name: translate({ id: type === 'add' ? 'CLIENT_ADD_APPLICATION' : 'CLIENT_PAGE_EDIT_EXTERNAL_APPLICATION' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                loading={(type === 'edit' && !clientData) || !externalResources}
                maxWidth="900px"
                data-cy="external-apps-panel"
            >
                <CreateEditExternalApplicationsFormComponent
                    type={type}
                    id={id}
                    clientData={clientData}
                    externalResources={externalResources} />
            </UiPageContainer> :
            <UiPanel
                header={{
                    title: (
                        <div className={classes.headerLayout}>
                            <Breadcrumbs separator=">">
                                <span
                                    className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                    onClick={() => history.push(getRoute(RouteNames.ExternalApplications))}
                                >
                                    {translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' })}
                                </span>
                            </Breadcrumbs>
                            <Typography
                                className={classes.headerText}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: type === 'add' ? 'CLIENT_ADD_APPLICATION' : 'CLIENT_PAGE_EDIT_EXTERNAL_APPLICATION' })}
                            </Typography>
                        </div>
                    ),
                }}
                data-cy="external-apps-panel"
            >
                {(type === 'edit' && !clientData) || !externalResources
                    ? (
                        <CircularProgress className={classes.loader} />
                    ) : <CreateEditExternalApplicationsFormComponent
                        type={type}
                        id={id}
                        clientData={clientData}
                        externalResources={externalResources} />}
            </UiPanel>
    );
};

export default ({ route }: RouteConfigComponentProps<any>) => (
    <UiDataContextProvider<IExternalAppDataContext> initialState={{
        resources: undefined,
        confidential: true,
    }}>
        <CreateEditExternalApplicationsPageComponent />
        {renderRoutes(route?.routes)}
    </UiDataContextProvider>
);
