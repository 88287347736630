import {
    IAllocateOrganizationLicenseRequest,
    IOrganizationLicenseWithAvailableProducts,
} from '../../../component/licensing/interfaces/license';
import {
    axiosDelete,
    get,
    put,
} from '../../utility/Requests.default';

export const licenseManagementHostUrl = '/api/license/management/host';

export function getOrganizationLicenseWithAvailableProducts(organizationId: string) {
    return get<IOrganizationLicenseWithAvailableProducts>(
        `${licenseManagementHostUrl}/getOrganizationLicenseWithAvailableProducts`,
        { urlParams: { organizationId } },
    );
}

export function allocateOrganizationLicense(organizationId: string, request: IAllocateOrganizationLicenseRequest) {
    return put<IAllocateOrganizationLicenseRequest>(`${licenseManagementHostUrl}/allocateOrganizationLicense`, {
        body: {
            organizationId,
            request,
        },
    });
}

export function removeHostedLicense(organizationId: string) {
    return axiosDelete(`${licenseManagementHostUrl}/license/remove`, { urlParams: { organizationId } });
}
