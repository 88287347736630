import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    createIntl,
    createIntlCache,
    RawIntlProvider,
} from 'react-intl';

import { LanguageCulture } from './language';
import {
    getUserLanguageFromLocalStorage,
    isValidLanguageCode,
    setUserLanguageInLocalStorage,
} from './LocaleAvailability';
import en from './translation-en.json';

interface IIntlContext {
    locale: string;
    setLocale: (_: LanguageCulture) => void;
}

export const IntlWrapperContext = React.createContext<IIntlContext>({
    locale: 'en',
    setLocale: () => {},
});

const cache = createIntlCache();
const keys = Object.keys(en).reduce(
    (accum, current) => ({
        ...accum,
        [current]: current,
    }),
    {},
);

const EnableLocKeysLanguage = getFeatureFlagValue(Features.EnableLocKeysLanguage.name);

export function useUpdateLanguage() {
    const { setLocale } = useContext(IntlWrapperContext);

    const updateLanguage = useCallback((language: string) => {
        const languageCode = isValidLanguageCode(language) ?? 'en';
        setUserLanguageInLocalStorage(languageCode);
        setLocale(languageCode);
    }, [ setLocale ]);

    return updateLanguage;
}

export const useLocalization = (useStrict = true) => {
    const { locale } = useContext(IntlWrapperContext);
    return (
        (!EnableLocKeysLanguage || useStrict) && locale?.toLowerCase() === 'keys' ? 'en' : locale
    ) as LanguageCulture;
};

export const IntlProvider: React.FC = ({ children }) => {
    const [ locale, setLocale ] = useState(getUserLanguageFromLocalStorage());

    const [ messages, setMessages ] = useState<Record<string, string>>(en);

    useEffect(() => {
        (async () => {
            if (locale?.toLowerCase() === 'keys') {
                EnableLocKeysLanguage && setMessages(keys);
                return;
            }
            const localeData = await import(/* webpackChunkName: "[request]" */ `./translation-${locale}.json`);
            setMessages({
                ...en,
                ...localeData,
            });
        })();
    }, [ locale ]);

    const intlShape = useMemo(() => {
        if (!messages) {
            return null;
        }

        return createIntl(
            {
                locale: locale?.toLowerCase() === 'keys' ? 'en' : locale,
                defaultLocale: 'en',
                messages,
            },
            cache,
        );
    }, [ locale, messages ]);

    return intlShape ?
        <IntlWrapperContext.Provider value={{
            locale,
            setLocale,
        }}>
            <RawIntlProvider value={intlShape}>
                {children}
            </RawIntlProvider>
        </IntlWrapperContext.Provider> : null;
};

export function mapAutomationCloudLanguageToStripe(automationCloudLanguageCode: LanguageCulture) {
    switch (automationCloudLanguageCode) {
        case 'ja':
        case 'en':
        case 'fr':
        case 'de':
        case 'es':
        case 'pt-BR':
        case 'pt':
        case 'ru':
        case 'zh-TW':
            return automationCloudLanguageCode;
        case 'es-MX':
            return 'es';
        case 'zh-CN':
            return 'zh';
        default:
            return 'en';
    }
}

