/**
 * TEMPORARY FILE
 * Required while ecommerce components are tightly coupled with redux
 */

import { AccountType } from '@experiences/interfaces';

const UserRole = {
    DEFAULT_USER: 'DEFAULT_USER',
    TENANT_ADMIN: 'TENANT_ADMIN',
    ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
    ACCOUNT_OWNER: 'ACCOUNT_OWNER',
};

interface IAccountUserDto {
    id: number;
    firstName: string;
    lastName: string;
    country: string;
    companyName: string;
    accountGuid: string;
    contactNumber: string;
    provisioningStatus: string;
    userCount: number;
    accountType: string;
    accountExpirationDate: string;
    licenseActivated: boolean | null;
    accountLogicalName: string;
    accountLanguage: string;
    concurrentLicenseOpted: boolean;
    accountGlobalId: string;
    licenseCode: string;
    licenseType: string;
}

interface IUser {
    id: number;
    userName: string;
    emailId: string;
    accountUserDto: IAccountUserDto;
    accountRoleType: string;
    userStatus: string;
    provisioningStatus: string;
    isUserActive: boolean;
    lastLoginTime: string;
    userGlobalId: string;
    serviceRoles?: string[];
    accountRoleTypeDisplayName: any;
    isAccountOwner: boolean;
    serviceList: string[];
    isHostMode?: boolean;
    orchestratorUrl?: string;
}

export const accountLogicalName = (state: any) => state.profile?.accountUserDto?.accountLogicalName as string;
export const accountGlobalId = (state: any) => state.profile?.accountUserDto?.accountGlobalId.toLowerCase() as string;
export const countrySelector = (state: any) => state.profile?.accountUserDto?.country as string;
export const emailSelector = (state: any) => (state.profile as IUser).emailId;
export const profile = (state: any) => state.profile as IUser;
export const isAdminSelector = (state: any) => state.profile?.accountRoleType !== UserRole.DEFAULT_USER;
export const isAdmin = (state: any) => state.profile?.accountRoleType !== UserRole.DEFAULT_USER;
export const userGlobalId = (state: any) => state.profile.userGlobalId as string;
export const accountType = (state: any) => state.profile?.accountUserDto?.accountType as AccountType;
