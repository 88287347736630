import { AuthContext } from '@experiences/util';
import React, { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

import useUserInfo from '../hooks/UserInfo';

const IdentityAuthProvider: React.FC = ({ children }) => {
    const { token } = useUserInfo();
    const { signoutRedirect: logoutIdentity } = useAuth();

    const logout = useCallback(
        (returnUrl?: string) => {
            logoutIdentity({ post_logout_redirect_uri: returnUrl });
        },
        [ logoutIdentity ],
    );

    return (
        <AuthContext.Provider
            value={{
                token: token,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default IdentityAuthProvider;
