import { ContactUsLinks } from '@experiences/constants';
import {
    ECOMMERCE_PRODUCT_CODES,
    ECOMMERCE_PRODUCT_EXTENDED_CODES,
    EcommerceProvider,
    getPriceString,
    getUniqueDiscountPackagesConfig,
    ICustomPlanForm,
    IDiscountedProductsPrices,
    IPackage,
    IPackagesConfig,
    IProductPriceOptions,
    ISkuPackage,
    JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH,
    useEcommerceEnabledCountries,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import GlobalStyles from '@experiences/theme';
import {
    useLocalizedLinks,
    useRouteResolver,
} from '@experiences/util';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    CircularProgress,
    IconButton,
    Link,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    billingUrl,
    getPackagesConfig,
    getPackagesConfigByCurrency,
    getProductsPricesInAllCurrencies,
    getProductsPricesWithDiscountsByCurrency,
} from '../../services/licensing/BillingService';
import { accountLogicalName } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import EcommerceBackToLicense from './subcomponents/EcommerceBackToLicense';
import EcommerceDiscountBanner from './subcomponents/EcommerceDiscountBanner';
import EcommerceProductInfo from './subcomponents/EcommerceProductInfo';
import EcommerceProductQuantityInput from './subcomponents/EcommerceProductQuantityInput';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        pageContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        backToLicensesText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            cursor: 'pointer',
        },
        titleContainer: {
            flexGrow: 1,
            marginTop: '10px',
        },
        title: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            marginBottom: '10px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        createCustomOfferAction: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginRight: '11px',
        },
        createCustomOfferActionStrong: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        salesLink: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        openInNewIcon: {
            width: '16px',
            height: '16px',
            marginLeft: '2px',
        },
        planOverallDetailsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '24px',
            paddingBottom: '24px',
            paddingRight: '30px',
            height: '100%',
        },
        planOverallDetailsCard: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        planOverallDetailsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '12px',
        },
        planOverallDetailsRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: '8px',
        },
        planOverallDetailsRowText: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        planOverallDetailsCheckmarkIcon: {
            color: theme.palette.semantic.colorSelection,
            marginRight: '11px',
        },
        packageCard: { padding: '24px 4px' },
        planOverallDetails: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '24px',
            height: '100%',
        },
        packageCardTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
            textAlign: 'center',
        },
        packageCardSubtitle: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            textAlign: 'center',
            marginBottom: '32px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        packageCardPrice: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
        },
        packageCardPriceAndBuyButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 'auto',
        },
        packageCardNumber: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        packageCardInterval: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        packageFullPrice: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundLight,
            textDecorationLine: 'line-through',
        },
        circularLoading: { borderRadius: '30px' },
        buyNowButton: {
            marginTop: '16px',
            minWidth: '91px',
            fontSize: '14px',
        },
        productCell: {
            height: '100%',
            padding: '24px 0px',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        productInfoCell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
        },
        productInfoCellText: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        detailsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '18px',
        },
        detailsSubtitle: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginBottom: '12px',
            color: theme.palette.semantic.colorForeground,
        },
        detailsLastSubtitle: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        checkmarkIcon: {
            color: theme.palette.semantic.colorSelection,
            marginTop: '12px',
        },
        supportCell: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        innerCellBorder: {
            height: '100%',
            borderLeft: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        customPlanBackground: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        quantityInput: {
            backgroundColor: theme.palette.semantic.colorBackgroundEdit,
            maxWidth: '84px',
        },
        quantityInputContainer: { height: '32px' },
        errorText: { display: 'inline' },
        productBottomCell: {
            borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            padding: '24px 0px',
            textAlign: 'left',
            verticalAlign: 'bottom',
        },
        japanPurchaseLegalText: {
            alignSelf: 'center',
            fontWeight: 400,
            lineHeight: '16px',
            fontSize: '12px',
            marginRight: 'auto',
        },
        footerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '24px',
        },
        currencySelector: {
            minWidth: '120px',
            alignSelf: 'start',
            marginRight: 'auto',
        },
        productForm: {
            display: 'flex',
            flexDirection: 'column',
        },
        borderlessTable: { borderCollapse: 'collapse' },
        headerBorder: { borderLeft: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
    }),
}));

const EcommerceComponent: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const language = useLocalization();
    const currentAccountName = useSelector(accountLogicalName);
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const logEcommerceEvent = useEcommerceTelemetry();
    const getLocalizedLink = useLocalizedLinks();

    const [ selectedCurrency, setSelectedCurrency ] = useState<string | undefined>();
    const [ customPlanPackage, setCustomPlanPackage ] = useState<IPackage>();
    const [ customPlanConfigUpdated, setCustomPlanConfigUpdated ] = useState<boolean>(false);

    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);
    const EnableEcommerceRobotUnits = useFeatureFlagValue(Features.EnableEcommerceRobotUnits.name);

    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const {
        data: packagesData,
        isValidating: loading,
        error,
    } = useSWR<IPackagesConfig, Error>(
        [ currentAccountName, selectedCurrency, accountCountryCode, packagesConfigUrl ],
        () => {
            if (!selectedCurrency) {
                return getPackagesConfig(currentAccountName, accountCountryCode);
            }
            return getPackagesConfigByCurrency(currentAccountName, selectedCurrency);
        },
    );

    const productsPricesInAllCurrenciesUrl = `${billingUrl}/productsPricesInAllCurrencies`;
    const {
        data: productsPricesInAllCurrencies,
        isValidating: loadingProductPrices,
        error: errorLoadingProductPrices,
    } = useSWR<IProductPriceOptions[], Error>([ currentAccountName, productsPricesInAllCurrenciesUrl ], getProductsPricesInAllCurrencies);

    useEffect(() => {
        if (packagesData) {
            setSelectedCurrency(packagesData!.currency.toString());
        }
    }, [ packagesData ]);

    const isJapanCountry = useMemo<boolean>(
        () => {
            return accountCountryCode === 'JP';
        },
        [ accountCountryCode ],
    );

    const ecommerceProductCodes = useMemo<string[]>(
        () => EnableEcommerceRobotUnits ? ECOMMERCE_PRODUCT_EXTENDED_CODES : ECOMMERCE_PRODUCT_CODES,
        [ EnableEcommerceRobotUnits ],
    );

    const allCurrencies = useMemo<string[]>(
        () => {
            const currenciesSet = new Set(productsPricesInAllCurrencies?.flatMap(p => Object.keys(p.prices)));
            return Array.from(currenciesSet.values()).sort();
        },
        [ productsPricesInAllCurrencies ],
    );

    const productQuantitiesForCustomPlan = useMemo(() => {
        const productQuantities: { [code: string]: number } = {};

        if (customPlanPackage && customPlanConfigUpdated) {
            customPlanPackage?.productQuantities?.forEach((pq) => productQuantities[pq.code] = pq.quantity);
        } else {
            packagesData?.packages
                ?.find(p => p.isCustomizable)?.productQuantities
                ?.forEach((pq) => productQuantities[pq.code] = pq.quantity);
        }

        return productQuantities;
    }, [ customPlanPackage, packagesData, customPlanConfigUpdated ]);

    const useFormMethods = useForm<ICustomPlanForm>({
        mode: 'onChange',
        defaultValues: { productQuantities: productQuantitiesForCustomPlan },
    });
    const {
        handleSubmit,
        errors,
        reset,
    } = useFormMethods;

    const onCurrencyChange = useCallback((event: any) => {
        if (event.target) {
            setSelectedCurrency(event.target.value);
        }
    },
    [ setSelectedCurrency ],
    );

    const customPlanProductPricesWithDiscountsSWRDependencies = useMemo(() =>
        [ currentAccountName, selectedCurrency, customPlanPackage, productsPricesInAllCurrenciesUrl ],
    [ currentAccountName, selectedCurrency, customPlanPackage, productsPricesInAllCurrenciesUrl ]);
    const {
        data: customPlanProductPricesWithDiscounts,
        isValidating: customPlanProductPricesWithDiscountsLoading,
    } = useSWR<IDiscountedProductsPrices>(
        EnableEcommercePromotionalDiscounts && customPlanPackage && selectedCurrency
            ? customPlanProductPricesWithDiscountsSWRDependencies
            : null,
        () => {
            return getProductsPricesWithDiscountsByCurrency(currentAccountName, selectedCurrency!, customPlanPackage?.productQuantities!);
        });

    useEffect(() => {
        if (!customPlanPackage) {
            setCustomPlanPackage(packagesData?.packages.find(p => p.isCustomizable));
            setCustomPlanConfigUpdated(true);
        }
        reset({ productQuantities: productQuantitiesForCustomPlan });
    }, [ productQuantitiesForCustomPlan, reset, packagesData?.packages, customPlanPackage ]);

    const getPrice = useCallback(
        (sku: IPackage) => {
            let price = 0;
            const skuPackage = sku.isCustomizable ? customPlanPackage! : sku;
            skuPackage.productQuantities?.forEach(productQuantity => {
                if (selectedCurrency) {
                    const foundProductPrice = productsPricesInAllCurrencies?.find(pp => pp.code === productQuantity.code);
                    if (foundProductPrice) {
                        price += productQuantity.quantity * foundProductPrice.prices[selectedCurrency];
                    }
                }
            });
            return price;
        },
        [ productsPricesInAllCurrencies, selectedCurrency, customPlanPackage ],
    );

    const getDiscountedPrice = useCallback((skuPackage: IPackage) => {
        let price = 0;
        if (EnableEcommercePromotionalDiscounts && skuPackage.isCustomizable) {
            customPlanPackage!.productQuantities?.forEach(productQuantity => {
                const foundProductPrice = customPlanProductPricesWithDiscounts!.products?.find(pp => pp.code === productQuantity.code);
                if (foundProductPrice && selectedCurrency) {
                    price += productQuantity.quantity * foundProductPrice.discount?.discountedPrices[selectedCurrency];
                }
            });
        } else {
            skuPackage.productQuantities?.forEach(productQuantity => {
                const foundProductPrice = skuPackage.productDiscounts?.find(pp => pp.code === productQuantity.code);
                if (foundProductPrice) {
                    price += productQuantity.quantity * foundProductPrice.discountedPrice;
                }
            });
        }
        return price;
    },
    [ selectedCurrency, EnableEcommercePromotionalDiscounts, customPlanPackage, customPlanProductPricesWithDiscounts ],
    );

    const onBuySkuPackage = useCallback(
        async (packageType: string) => {
            const selectedPackage = packagesData?.packages?.find(p => p.type === packageType);
            const selectedPackageConfig = selectedPackage?.isCustomizable ? customPlanPackage : selectedPackage;

            const productQuantities: { [code: string]: number } = {};
            selectedPackageConfig?.productQuantities?.forEach((pq) => productQuantities[pq.code] = pq.quantity);
            logEcommerceEvent('Licenses.SelectPlan', {
                SelectedPlan: packageType,
                SelectedProducts: productQuantities,
            });

            const skuPackage = {
                currency: packagesData?.currency ?? '',
                products: selectedPackageConfig?.productQuantities,
                type: packageType,
                billingPeriod: selectedPackageConfig?.billingPeriod,
            } as ISkuPackage;

            history.push({
                pathname: getRoute(RouteNames.BuyEnterpriseCheckout),
                state: {
                    skuPackage,
                    selectedCurrency,
                },
            });
        },
        [ packagesData, getRoute, history, logEcommerceEvent, selectedCurrency, customPlanPackage ],
    );

    const getPackagePrice = useCallback(
        (skuPackage: IPackage, currency: string | undefined) => {
            const packageCurrency = currency ?? '';
            const isLoading = customPlanProductPricesWithDiscountsLoading || !customPlanProductPricesWithDiscounts;

            if (EnableEcommercePromotionalDiscounts && skuPackage.isCustomizable && isLoading) {
                return (
                    <CircularProgress
                        size={16}
                        className={classes.circularLoading} />
                );
            }

            const price = getPrice(skuPackage);
            const discountedPrice = EnableEcommercePromotionalDiscounts ? getDiscountedPrice(skuPackage) : 0;
            const shouldDisplayDiscount = EnableEcommercePromotionalDiscounts && price !== discountedPrice && discountedPrice > 0;

            return (
                <>
                    {shouldDisplayDiscount && (<div
                        className={classes.packageCardPrice}
                        data-cy={`${skuPackage.type}-full-price`}>
                        <Typography className={classes.packageFullPrice}>
                            {getPriceString(price, packageCurrency, language, undefined, false)}
                        </Typography>
                    </div>
                    )}
                    <div
                        className={classes.packageCardPrice}
                        data-cy={`${skuPackage.type}-price`}>
                        <Typography className={classes.packageCardNumber}>
                            {getPriceString(shouldDisplayDiscount ? discountedPrice : price, packageCurrency, language, undefined, false)}
                        </Typography>
                        <Typography className={classes.packageCardInterval}>
                            &nbsp;
                            {'/ ' + translate({ id: `CLIENT_PER_${skuPackage.billingPeriod.toUpperCase()}_LONG` })}
                        </Typography>
                    </div>
                </>
            );
        },
        [
            EnableEcommercePromotionalDiscounts,
            getPrice,
            getDiscountedPrice,
            classes,
            language,
            translate,
            customPlanProductPricesWithDiscountsLoading,
            customPlanProductPricesWithDiscounts,
        ],
    );

    const getPackageCard = useCallback(
        (skuPackage: IPackage, currency: string | undefined) => {
            const packageCurrency = currency ?? '';
            return (
                <div
                    className={classes.packageCard}
                    data-cy="package-card">
                    <Typography className={classes.packageCardTitle}>
                        {translate({ id: `CLIENT_SKU_PACKAGE_TITLE_${skuPackage.type}` })}
                    </Typography>
                    <Typography className={classes.packageCardSubtitle}>
                        {translate({ id: `CLIENT_SKU_PACKAGE_SUBTITLE_${skuPackage.type}` })}
                    </Typography>
                    <div className={classes.packageCardPriceAndBuyButtonContainer}>
                        {getPackagePrice(skuPackage, packageCurrency)}
                        <Button
                            className={classes.buyNowButton}
                            variant="contained"
                            onClick={() => onBuySkuPackage(skuPackage.type)}
                            data-cy='buy-now-button'
                            disabled={skuPackage.isCustomizable && errors.productQuantities !== undefined}
                        >
                            {translate({ id: 'CLIENT_BUY_NOW' })}
                        </Button>
                    </div>
                </div>
            );
        },
        [ classes, translate, errors, getPackagePrice, onBuySkuPackage ],
    );

    const getPlanOverallDetails = useCallback(() => {
        return (
            <div
                className={classes.planOverallDetailsCard}
                data-cy="plan-overall-details">
                <Typography className={classes.planOverallDetailsTitle}>
                    {translate({ id: 'CLIENT_PLAN_OVERALL_DETAILS_TITLE' })}
                </Typography>
                <div className={classes.planOverallDetailsRow}>
                    <CheckIcon
                        fontSize="small"
                        className={classes.planOverallDetailsCheckmarkIcon} />
                    <Typography className={classes.planOverallDetailsRowText}>
                        {translate({ id: 'CLIENT_PLAN_OVERALL_DETAILS_MONTHLY_AUTO_RENEWS' })}
                    </Typography>
                </div>
                <div className={classes.planOverallDetailsRow}>
                    <CheckIcon
                        fontSize="small"
                        className={classes.planOverallDetailsCheckmarkIcon} />
                    <Typography className={classes.planOverallDetailsRowText}>
                        {translate({ id: 'CLIENT_PLAN_OVERALL_DETAILS_CANCEL_ANYTIME' })}
                    </Typography>
                </div>
            </div>
        );
    }, [ classes, translate ]);

    const getCheckmarkBox = useCallback(
        (type: string) => {
            return (
                <div
                    className={classes.supportCell}
                    key={type}>
                    <CheckIcon
                        fontSize="small"
                        className={classes.checkmarkIcon}
                        titleAccess={`${translate({ id: 'CLIENT_PRODUCT_SUPPORT_FROM_UIPATH' })} included in ${translate({ id: `CLIENT_SKU_PACKAGE_TITLE_${type}` })}`}
                        role="img"
                        tabIndex={0} />
                    <CheckIcon
                        fontSize="small"
                        className={classes.checkmarkIcon}
                        titleAccess={`${translate({ id: 'CLIENT_PRODUCT_UPTIME_GUARANTEE' })} included in ${translate({ id: `CLIENT_SKU_PACKAGE_TITLE_${type}` })}`}
                        role="img"
                        tabIndex={0} />
                    <CheckIcon
                        fontSize="small"
                        className={classes.checkmarkIcon}
                        titleAccess={`${translate({ id: 'CLIENT_PRODUCT_MULTIPLE_TENANTS_P1' })} included in ${translate({ id: `CLIENT_SKU_PACKAGE_TITLE_${type}` })}`}
                        role="img"
                        tabIndex={0} />
                </div>
            );
        },
        [ classes, translate ],
    );

    const onChangeCustomSkuPackage = useCallback(
        async (data: ICustomPlanForm) => {
            packagesData?.packages
                .find(p => p.isCustomizable)
                ?.productQuantities
                .forEach(pq => (pq.quantity = data.productQuantities[pq.code]));
            const localCustomPackage = packagesData?.packages.find(p => p.isCustomizable);
            setCustomPlanConfigUpdated(true);
            setCustomPlanPackage(localCustomPackage);
            mutate(customPlanProductPricesWithDiscountsSWRDependencies);
        },
        [ packagesData, customPlanProductPricesWithDiscountsSWRDependencies ],
    );

    const getInfoCell = useCallback(
        (skuPackage: IPackage, productCode) => {
            const count = skuPackage.productQuantities.find(product => product.code === productCode)?.quantity ?? '─';
            return (
                <div
                    key={skuPackage.type}
                    className={classes.productInfoCell}>
                    {!skuPackage.isCustomizable && (
                        <Typography className={classes.productInfoCellText}>
                            {count.toString()}
                        </Typography>
                    )}
                    {skuPackage.isCustomizable && (
                        <EcommerceProductQuantityInput
                            productCode={productCode}
                            customSkuPackage={customPlanPackage!}
                            loading={loading}
                            useFormMethods={useFormMethods}
                        />
                    )}
                </div>
            );
        },
        [ classes, loading, useFormMethods, customPlanPackage ],
    );

    const isDataLoading = useMemo(() => {
        return loading
            || loadingProductPrices
            || customPlanPackage === undefined
            || (customPlanProductPricesWithDiscounts === undefined && !customPlanConfigUpdated)
            || (customPlanProductPricesWithDiscountsLoading && !customPlanConfigUpdated);
    }, [
        loading,
        loadingProductPrices,
        customPlanPackage,
        customPlanProductPricesWithDiscounts,
        customPlanProductPricesWithDiscountsLoading,
        customPlanConfigUpdated,
    ]);

    const uniqueApplicableDiscount = useMemo(
        () => {
            if (packagesData) {
                return getUniqueDiscountPackagesConfig(packagesData);
            }
        },
        [ packagesData ],
    );

    const existAnyDiscounts = useMemo(() => {
        return !loading && uniqueApplicableDiscount && EnableEcommercePromotionalDiscounts;
    }, [ loading, uniqueApplicableDiscount, EnableEcommercePromotionalDiscounts ]);

    const planColumnWidthPercentage = useMemo(() => {
        if (EnableEcommerceRobotUnits) {
            return isJapanCountry ? '33.33%' : '22.21%';
        }
        return isJapanCountry ? '22.21%' : '16.66%';
    }, [ isJapanCountry, EnableEcommerceRobotUnits ]);

    const shouldDisplayPackage = useCallback((skuPackage) => {
        const shouldHidePackage = (isJapanCountry && skuPackage.isCustomizable)
            || (EnableEcommerceRobotUnits && skuPackage.type === 'CRPAPRO');
        return !shouldHidePackage;
    }, [ isJapanCountry, EnableEcommerceRobotUnits ]);

    return (
        <div className={classes.pageContainer}>
            <EcommerceDiscountBanner />
            <UiPanel
                navigationElement={
                    <EcommerceBackToLicense />
                }
                ecommerceDiscountsExists={existAnyDiscounts}
                header={{
                    title: (
                        <div className={classes.titleContainer}>
                            <Typography
                                className={classes.title}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: 'CLIENT_ECOMMERCE_PLANS_AND_PRICING' })}
                            </Typography>
                        </div>
                    ),
                    disableRightPadding: true,
                    actions: (
                        <>
                            <Typography className={classes.createCustomOfferAction}>
                                <FormattedMessage
                                    id="CLIENT_CREATE_CUSTOM_OFFER_ACTION"
                                    values={{
                                        strong: (str: string) => <strong className={classes.createCustomOfferActionStrong}>
                                            {str}
                                        </strong>,
                                    }}
                                />
                            </Typography>

                            <Link
                                className={classes.salesLink}
                                data-cy="ecommerce-select-plan-contact-sales-link"
                                onClick={() => {
                                    logEcommerceEvent('SelectPlan.ContactSales');
                                    window.open(getLocalizedLink(ContactUsLinks), '_blank');
                                }}
                            >
                                {translate({ id: 'CLIENT_CONTACT_SALES' })}
                                <OpenInNewIcon className={classes.openInNewIcon} />
                            </Link>
                        </>
                    ),
                }}
                data-cy="products-dialog"
            >
                {isDataLoading && (
                    <CircularProgress
                        color="primary"
                        size="60px"
                        style={{
                            marginTop: '20%',
                            marginLeft: 'calc(50% - 30px)',
                        }} />
                )}
                {error && !loading && (
                    <div>
                        <Typography className={classes.errorText}>
                            {translate({ id: 'CLIENT_ERROR_RETREIVING_PRODUCTS' })}
                        </Typography>
                        <IconButton
                            color="primary"
                            onClick={() => mutate([ currentAccountName, accountCountryCode, packagesConfigUrl ], {}, true)}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </div>
                )}
                {errorLoadingProductPrices && !loadingProductPrices && (
                    <div>
                        <Typography className={classes.errorText}>
                            {translate({ id: 'CLIENT_ERROR_RETREIVING_PRODUCTS' })}
                        </Typography>
                        <IconButton
                            color="primary"
                            onClick={() => mutate([ currentAccountName, productsPricesInAllCurrenciesUrl ], {}, true)}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </div>
                )}
                {!isDataLoading && !error && !errorLoadingProductPrices && selectedCurrency && (
                    <form
                        className={classes.productForm}
                        onChange={handleSubmit(onChangeCustomSkuPackage)}>
                        <table className={classes.borderlessTable}>
                            <colgroup>
                                <col
                                    span={1}
                                    width="33.36%"
                                />
                                <col
                                    span={1}
                                    width={planColumnWidthPercentage}
                                />
                                <col
                                    span={1}
                                    width={planColumnWidthPercentage}
                                />
                                <col
                                    span={1}
                                    width={planColumnWidthPercentage}
                                />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th
                                        id="header_plan_overall_details"
                                        className={classes.planOverallDetails}
                                        scope="col">
                                        {getPlanOverallDetails()}
                                    </th>
                                    {packagesData?.packages?.map((skuPackage, index) =>
                                        shouldDisplayPackage(skuPackage) && (
                                            <th
                                                key={index}
                                                id={`header_package_${skuPackage.type}`}
                                                scope="col"
                                                className={clsx(skuPackage.isCustomizable && classes.customPlanBackground,
                                                    classes.headerBorder)}>
                                                {getPackageCard(skuPackage, selectedCurrency)}
                                            </th>
                                        ))}
                                </tr>

                                {ecommerceProductCodes.map(productCode => (
                                    <tr
                                        key={productCode}>
                                        <th
                                            scope="row"
                                            id={`header_product_${productCode}`}
                                            data-cy={`${productCode}-title`}
                                            className={classes.productCell}>
                                            <EcommerceProductInfo productCode={productCode} />
                                        </th>

                                        {packagesData?.packages?.map((skuPackage, index) =>
                                            shouldDisplayPackage(skuPackage) && (
                                                <td
                                                    key={index}
                                                    headers={`header_product_${productCode} header_package_${skuPackage.type} header_plan_overall_details`}
                                                    className={clsx(
                                                        classes.innerCellBorder,
                                                        skuPackage.isCustomizable && classes.customPlanBackground,
                                                    )}>
                                                    {getInfoCell(skuPackage, productCode)}
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                                <tr>
                                    <th
                                        scope="row"
                                        id="header_support"
                                        className={clsx(classes.productCell, classes.productBottomCell)}
                                    >
                                        <Typography className={classes.detailsTitle}>
                                            {translate({ id: 'CLIENT_ECOMMERCE_SUPPORT' })}
                                        </Typography>
                                        <Typography className={classes.detailsSubtitle}>
                                            {translate({ id: 'CLIENT_PRODUCT_SUPPORT_FROM_UIPATH' })}
                                        </Typography>
                                        <Typography className={classes.detailsSubtitle}>
                                            {translate({ id: 'CLIENT_PRODUCT_UPTIME_GUARANTEE' })}
                                        </Typography>
                                        <Typography className={classes.detailsLastSubtitle}>
                                            {translate({ id: 'CLIENT_PRODUCT_MULTIPLE_TENANTS_P1' })}
                                        &nbsp;
                                            {translate({ id: 'CLIENT_PRODUCT_MULTIPLE_TENANTS_P2' })}
                                        </Typography>
                                    </th>
                                    {packagesData?.packages?.map((skuPackage, index) =>
                                        shouldDisplayPackage(skuPackage) && (
                                            <td
                                                key={index}
                                                headers={`header_package_${skuPackage.type} header_support`}
                                                className={clsx(
                                                    classes.innerCellBorder,
                                                    classes.productBottomCell,
                                                    skuPackage.isCustomizable && classes.customPlanBackground,
                                                )}>
                                                {getCheckmarkBox(skuPackage.type)}
                                            </td>
                                        ))}
                                </tr>
                            </tbody>
                        </table>

                        <div className={classes.footerContainer}>
                            <Select
                                value={selectedCurrency}
                                disabled={allCurrencies.length === 1}
                                IconComponent={ExpandMoreIcon}
                                onChange={onCurrencyChange}
                                className={classes.currencySelector}
                                variant='outlined'
                                inputProps={{
                                    id: 'currency',
                                    name: 'currency',
                                    'data-cy': 'ecommerce-select-currency',
                                    style: {
                                        height: '100%',
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                    },
                                }}
                            >
                                {allCurrencies.map((currency, i) => (
                                    <MenuItem
                                        key={i}
                                        value={currency}
                                        aria-label={currency}
                                    >
                                        {currency}
                                    </MenuItem>
                                ))}
                            </Select>

                            {accountCountryCode === 'JP' && (
                                <Link
                                    className={clsx(classes.japanPurchaseLegalText)}
                                    href={JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH}
                                    data-cy="japan-purchase-legal-text"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {translate({ id: 'CLIENT_JP_COMMERCIAL_TRANSACTION_ACT' })}
                                </Link>
                            )}
                        </div>
                    </form>
                )}
            </UiPanel>
        </div>
    );
};

export default ({ route }: { route?: any }) => (
    <EcommerceProvider>
        <EcommerceComponent />
        {renderRoutes(route.routes)}
    </EcommerceProvider>
);
