import {
    ICustomPlanForm,
    IPackage,
    IUpdateLicenseQuantityForm,
} from '@experiences/ecommerce';
import GlobalStyles from '@experiences/theme';
import { TextField } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    UseFormMethods,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        quantityInputContainer: { height: '32px' },
        quantityInput: {
            backgroundColor: theme.palette.semantic.colorBackgroundEdit,
            maxWidth: '84px',
            '&[type=number]': { '-moz-appearance': 'textfield' },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
    }),
}));

const EcommerceProductQuantityInput:
React.FC<{
    productCode: string;
    customSkuPackage: IPackage;
    loading: boolean;
    useFormMethods: UseFormMethods<ICustomPlanForm> | UseFormMethods<IUpdateLicenseQuantityForm>;
    onChangeCallback?: (...event: any[]) => void;
    // TODO//remove minQuantity as part of PLT-11099
    minQuantity?: number;
}> = ({
    productCode, customSkuPackage, loading, useFormMethods, onChangeCallback, minQuantity,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        control,
        handleSubmit,
        errors,
        setValue,
    } = useFormMethods as UseFormMethods<ICustomPlanForm>;

    const productQuantity = useMemo(() => {
        return customSkuPackage.productQuantities.find(pq => pq.code === productCode);
    }, [ customSkuPackage, productCode ]);

    const preventOutOfBoundValues = useCallback(
        (event) => {
            const { value } = event.target;
            const minimumValue = minQuantity ?? 0;

            if (!value) {
                return;
            }
            if (value < 0) {
                event.target.value = 0;
            } else if (value > productQuantity!.maxQuantity!) {
                event.target.value = productQuantity!.maxQuantity;
            } else if (value < minimumValue) {
                event.target.value = minimumValue;
            } else {
                event.target.value = Number(value);
            }
        },
        [ productQuantity, minQuantity ],
    );
    const preventAlphaNumericValues = useCallback(
        (evt) => {
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            }
        },
        [ ],
    );
    const onChangeCustomSkuPackage = useCallback(
        async (data: ICustomPlanForm) => {
            customSkuPackage?.productQuantities
                .forEach(pq => (pq.quantity = data.productQuantities[pq.code]));
        },
        [ customSkuPackage ],
    );

    const preventEmptyValues = useCallback(
        (name, event) => {
            if (!event.target.value) {
                setValue(name, productQuantity!.minQuantity, { shouldValidate: true });
                handleSubmit(onChangeCustomSkuPackage)();
            }
        },
        [ setValue, handleSubmit, onChangeCustomSkuPackage, productQuantity ],
    );

    return (<Controller
        className={classes.quantityInputContainer}
        render={({
            value, onChange, name,
        }) => (
            <TextField
                data-cy={`${productCode}-quantity`}
                InputProps={{
                    type: 'number',
                    className: classes.quantityInput,
                    inputProps: {
                        min: productQuantity!.minQuantity,
                        max: productQuantity!.maxQuantity,
                        'aria-label': `${productCode}-quantity`,
                    },
                }}
                onInput={e => preventOutOfBoundValues(e)}
                onKeyPress={e => preventAlphaNumericValues(e)}
                onBlur={e => preventEmptyValues(name, e)}
                onChange={(event) => {
                    onChange(event);
                    if (onChangeCallback) {
                        onChangeCallback(event);
                    }
                }}
                value={value}
                error={errors.productQuantities && !!errors.productQuantities[`${productCode}`]}
                helperText={
                    (errors.productQuantities &&
                errors.productQuantities![`${productCode}`]?.type === 'required' &&
                translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
              (errors.productQuantities &&
                errors.productQuantities![`${productCode}`]?.type === 'min' &&
                translate(
                    { id: 'CLIENT_ECOMMERCE_MIN_ALLOWED_PRODUCT_QUANTITY' },
                    { minQuantity: productQuantity!.minQuantity },
                )) ||
              (errors.productQuantities &&
                errors.productQuantities![`${productCode}`]?.type === 'max' &&
                translate(
                    { id: 'CLIENT_ECOMMERCE_MAX_ALLOWED_PRODUCT_QUANTITY' },
                    { maxQuantity: productQuantity!.maxQuantity },
                ))
                }
            />
        )}
        control={control}
        rules={{
            required: true,
            min: productQuantity!.minQuantity,
            max: productQuantity!.maxQuantity,
        }}
        name={`productQuantities[${productCode}]`}
        defaultValue="0"
        variant="outlined"
        size="small"
        style={{ maxWidth: '50%' }}
        disabled={loading}
    />
    );
};

export default EcommerceProductQuantityInput;
