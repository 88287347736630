import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import OrganizationGridComponent from './subcomponents/OrganizationGridComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        headerTitle: {
            display: 'flex',
            alignItems: 'center',
        },
        azureChip: {
            marginLeft: '8px',
            color: theme.palette.getContrastText(theme.palette.semantic.colorForegroundDeEmp),
            backgroundColor: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const OrganizationPageComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();

    const handleAddOrganization = useCallback(() => {
        history.push(`${getRoute(RouteNames.Organizations)}/add`);
    }, [ getRoute, history ]);

    return (
        <UiPanel
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseOverAllocationBanner={<UiOverAllocationBanner />}
            header={{
                title: (
                    <div className={classes.headerTitle}>
                        <Typography
                            style={{
                                fontWeight: 600,
                                fontSize: '18px',
                                lineHeight: '35px',
                                padding: '3px 0',
                            }}
                            role="heading"
                            aria-level={1}
                        >
                            {translate({ id: 'CLIENT_ORGANIZATION' })}
                        </Typography>
                    </div>
                ),
                actions: (
                    <Button
                        variant="contained"
                        onClick={handleAddOrganization}
                        data-cy="ui-grid-primary-action-button">
                        {translate({ id: 'CLIENT_ADD_ORGANIZATION' })}
                    </Button>
                ),
            }}
            data-cy="organization-panel"
        >
            <OrganizationGridComponent />
        </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <OrganizationPageComponent />
        {renderRoutes(route.routes)}
    </>
);
