import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
    Chip,
    CircularProgress,
    IconButton,
    MenuItem,
    MenuList,
    Skeleton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useEnabledTenantOperations } from '../../common/hooks/useEnabledTenantOperations';
import { ITenant } from '../../common/interfaces/tenant/tenant';
import { ReactComponent as HostTenantShellIcon } from '../../images/hostTenantShell.svg';
import { ReactComponent as OrgTenantShellIcon } from '../../images/orgTenantShell.svg';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../services/organization/OnPremOrganizationService';
import {
    isAdminSelector,
    isHostModeSelector,
    profile,
} from '../../store/selectors';
import { IOnPremOrganizationResponse } from '../organization/subcomponents/OrganizationGridComponent';
import { TenantStatusConstants } from '../tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        tenantShellContainer: {
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            borderRight: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            width: ' 20vw',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxWidth: '312px',
            minWidth: '175px',
            justifyContent: 'space-between',
        },
        tenantActionBar: {
            display: 'flex',
            margin: '18px 10px 0 20px',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tenantActionHeader: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        list: {
            background: theme.palette.semantic.colorBackground,
            boxShadow: 'none',
        },
        listRoot: {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        tenantList: {
            overflowY: 'auto',
            height: '100%',
        },
        menuItem: {
            padding: '6px 20px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        orgMenuItem: { height: '48px' },
        tenantMenuItem: { height: '32px' },
        tenantSpacing: { justifyContent: 'space-between' },
        tenantIcon: { marginRight: '8px' },
        filler: { height: '100%' },
        footer: {
            fontSize: '14px',
            marginBottom: '4px',
            textAlign: 'center',
        },
        chip: {
            fontSize: '12px',
            fontWeight: 600,
            backgroundColor: `${theme.palette.semantic.colorInfoBackground} !important`,
        },
        addTenant: { '&.Mui-disabled': { pointerEvents: 'auto' } },
        orgIcon: {
            color: theme.palette.semantic.colorForeground,
            margin: '0 8px 2px 0',
        },
        tenantShellHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        upgradeChip: { display: 'visible' },
        orgName: {
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '@media all and (max-width: 1830px)': { upgradeChip: { display: 'none' } },
    }),
);

const TenantShell: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const theme = useTheme();
    const { includesLicense } = useCheckLicense();

    const [ searching, setSearching ] = useState(false);
    const [ query, setQuery ] = useState('');
    const [ active, setActive ] = useState('org');

    const isAdmin = useSelector(isAdminSelector);
    const isHost = useSelector(isHostModeSelector);
    const accountProfile = useSelector(profile);

    const EnableOffersRevampAdmin = useFeatureFlagValue(Features.EnableOffersRevampAdmin.name);

    const {
        data, tenantsWithoutServices, isValidating,
    } = useTenantsContext();

    const { canAddTenant } = useEnabledTenantOperations(data, isAdmin);
    const { checkOperationList } = useTenantOperationTrackerContext();

    const { data: organizations } = useSWR<IOnPremOrganizationResponse>(
        isHost && !process.buildConfigs.hideForMSI ? [ `${onPremOrganizationManagementUri}/getAllOrganizations` ] : null,
        getAllOnPremOrganizations,
    );

    const { companyName } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const tenantShellUpgradeMessage = useMemo(() => {
        if (EnableOffersRevampAdmin) {
            return '';
        }
        if (includesLicense([ AccountLicense.FREEKIT ])) {
            return translate({ id: 'CLIENT_UPGRADE_TO_PRO' });
        } else if (includesLicense([ AccountLicense.PRO, AccountLicense['PRO-TRIAL'] ])) {
            return translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_REVAMP' });
        }
    }, [ EnableOffersRevampAdmin, includesLicense, translate ]);

    useEffect(() => {
        const tenantUrlRegex = isHost ? /\/(?:organization|service)\/([\w-]+)?(?:\/|$)/ : /\/(?:tenant|service)\/([\w-]+)?(?:\/|$)/;
        const tenantId = window.location.pathname.match(tenantUrlRegex);
        if (tenantId?.length) {
            setActive(tenantId[1]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ window.location.pathname ]);

    const filteredTenants = useMemo(() => {
        return query
            ? tenantsWithoutServices.filter(tenant => tenant.name.toLowerCase().includes(query))
            : tenantsWithoutServices;
    }, [ query, tenantsWithoutServices ]);

    const filteredOrganizations = useMemo(() => {
        return query
            ? organizations?.results.filter(tenant => tenant.name.toLowerCase().includes(query))
            : organizations?.results;
    }, [ organizations?.results, query ]);

    const tenantChipMessage = useCallback((tenant: ITenant) => {
        if (tenant.status.toLowerCase() === TenantStatusConstants.DISABLED.toLowerCase()) {
            return 'CLIENT_DISABLED';
        }
        if (tenant.isCanaryTenant) {
            return 'CLIENT_CANARY_TENANT';
        }
        return '';
    }
    , []);

    const noResults = useMemo(() =>
        <Typography className={clsx(classes.tenantSpacing, classes.menuItem)}>
            {translate({ id: 'CLIENT_PEOPLE_PICKER_NO_OPTIONS' }, { query })}
        </Typography>
    , [ classes.menuItem, classes.tenantSpacing, query, translate ]);

    const shellList = isHost
        ? filteredOrganizations && filteredOrganizations.length > 0
            ? filteredOrganizations.sort((objA, objB) => new Date(objA.createdOn).getTime() - new Date(objB.createdOn).getTime())
                .map(organization => (
                    <MenuItem
                        key={`organization-shell-item-${organization.name}`}
                        className={clsx(classes.menuItem, classes.tenantSpacing)}
                        selected={active === organization.id}
                        style={{ fontWeight: active === organization.id ? 600 : 400 }}
                        onClick={() => handleMenuItemClick(organization.id)}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <OrgTenantShellIcon
                                className={clsx(classes.orgIcon, classes.svgIcon)}
                                width='24px'
                            />
                            {organization.name}
                        </div>
                    </MenuItem>),
                ) : noResults
        : filteredTenants.length > 0
            ? filteredTenants.sort((objA, objB) => new Date(objA.createdOn).getTime() - new Date(objB.createdOn).getTime())
                .map(tenant => (
                    <MenuItem
                        key={`tenant-shell-item-${tenant.name}`}
                        className={clsx(classes.menuItem, classes.tenantSpacing)}
                        selected={active === tenant.id}
                        style={{ fontWeight: active === tenant.id ? 600 : 400 }}
                        onClick={() => handleMenuItemClick(tenant.id)}
                        data-cy={`tenant-shell-item-${tenant.name}`}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <LanguageIcon
                                className={classes.tenantIcon}
                                style={{
                                    color: tenant.color ?? theme.palette.grey,
                                    width: '16px',
                                }}
                            />
                            {tenant.name}
                        </div>
                        {checkOperationList(tenant.id) ?
                            <CircularProgress size={15} /> :
                            tenantChipMessage(tenant) &&
                        <Chip
                            className={classes.chip}
                            size="small"
                            clickable={false}
                            label={translate({ id: tenantChipMessage(tenant) })} />}
                    </MenuItem>),
                ) : noResults;

    const addTenantTooltip = useMemo(() => translate({
        id: !canAddTenant
            ? 'CLIENT_MAX_SERVICE_INSTANCE_COUNT_REACHED'
            : 'CLIENT_TENANT_CREATE',
    }), [ canAddTenant, translate ]);

    const addOrganizationTooltip = useMemo(() => translate({ id: 'CLIENT_CREATE_NEW_ORG_TENANT_SHELL' }), [ translate ]);

    const resetSearch = useCallback(() => {
        setQuery('');
        setSearching(false);
    }, []);

    const handleMenuItemClick = useCallback((option: string) => {
        setActive(option);
        resetSearch();

        if (option === 'org') {
            history.push(getRoute(RouteNames.OrganizationAdminHome));
        } else {
            isHost
                ? history.push(getRoute(RouteNames.OrganizationHome.replace(':organizationId', option)))
                : history.push(getRoute(RouteNames.TenantHome.replace(':tenantId', option)));
        }
    }, [ getRoute, history, isHost, resetSearch ]);

    return <div className={classes.tenantShellContainer}>
        <MenuList
            className={classes.list}
            classes={{ root: classes.listRoot }}>
            <MenuItem
                data-cy="tenant-shell-admin-home"
                className={clsx(classes.menuItem, classes.orgMenuItem)}
                selected={active === 'org'}
                onClick={() => handleMenuItemClick('org')}>
                {isHost ?
                    <HostTenantShellIcon
                        className={clsx(classes.orgIcon, classes.svgIcon)}
                        width='24px'
                    />
                    :
                    <OrgTenantShellIcon
                        className={clsx(classes.orgIcon, classes.svgIcon)}
                        width='24px'
                    />}
                <div
                    className={classes.orgName}
                    style={{ fontWeight: active === 'org' ? 600 : 400 }}>
                    {isHost ? translate({ id: 'CLIENT_HOST' }) : companyName}
                </div>
            </MenuItem>
        </MenuList>
        {!process.buildConfigs.hideForMSI
        && <>
            <div className={classes.tenantActionBar}>
                {!searching
                    ?
                    <div className={classes.tenantShellHeader}>
                        <Typography className={classes.tenantActionHeader}>
                            {translate({ id: isHost ? 'CLIENT_ORGANIZATIONS' : 'CLIENT_PAGE_TENANTS' })}
                        </Typography>
                        {tenantShellUpgradeMessage &&
                            <Chip
                                style={{ marginLeft: '16px' }}
                                size="small"
                                className={clsx(classes.upgradeChip, 'info-mini')}
                                label={tenantShellUpgradeMessage}
                                data-cy="sso-enabled-chip" />}
                    </div>
                    : <TextField
                        data-cy="tenant-shell-search"
                        placeholder={translate({ id: isHost ? 'CLIENT_SEARCH_ORGANIZATION' : 'CLIENT_SEARCH_TENANT' })}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <CloseIcon onClick={() => {
                                        resetSearch();
                                    }} />
                                </IconButton>
                            ),
                        }}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={searching}
                        value={query}
                        onChange={event => setQuery(event?.target.value)}
                        fullWidth />}
                <div>
                    {!searching && <IconButton
                        data-cy="tenant-shell-search-button"
                        style={{ marginRight: isHost ? '-8px' : '' }}
                        aria-label={translate({ id: isHost ? 'CLIENT_SEARCH_ORGANIZATION' : 'CLIENT_SEARCH_TENANT' })}
                        onClick={() => setSearching(true)}>
                        <SearchOutlinedIcon />
                    </IconButton>}
                    <IconButton
                        aria-label={isHost ? addOrganizationTooltip : addTenantTooltip}
                        className={classes.addTenant}
                        onClick={() => history.push(getRoute(isHost ? RouteNames.HostOrganizationAdd : RouteNames.TenantCreate))}
                        disabled={!canAddTenant}
                        data-cy="tenant-shell-add-button">
                        <Tooltip title={isHost ? addOrganizationTooltip : addTenantTooltip}>
                            <AddIcon />
                        </Tooltip>
                    </IconButton>
                </div>
            </div>
            <MenuList
                data-cy="tenant-shell-list"
                className={clsx(classes.tenantList, classes.list)}>
                {!isHost && isValidating
                    ? Array.from(Array(5)).map((_x, i) =>
                        <MenuItem
                            key={`tenant-shell-item-${i}`}
                            className={clsx(classes.menuItem, classes.tenantMenuItem)}
                            disabled={true}
                        >
                            <Skeleton
                                style={{ marginRight: '8px' }}
                                variant="rectangular"
                                width="16px"
                                height="16px"
                            />
                            <Skeleton
                                data-cy="tenant-shell-loading-skeleton"
                                variant="rectangular"
                                height="16px"
                                width={i % 2 === 0 ? '100%' : '50%'}
                            />
                        </MenuItem>,
                    )
                    : shellList}
            </MenuList>
        </>}
        {process.buildConfigs.showVersion && <div className={classes.footer}>
            {window.env?.CLOUDRPA_VERSION ? `${translate({ id: 'CLIENT_VERSION' })} ${window.env?.CLOUDRPA_VERSION}` : ''}
        </div>}
    </div>;
};

export default TenantShell;
