import { IServiceMetadata } from '../../interfaces/service';

export const getDefaultRegionForTenant = (availableServices: IServiceMetadata[] | undefined) => {
    const serviceWithRegion = availableServices?.find(service => service.id === 'orchestrator' || !!service.defaultRegion);
    return serviceWithRegion?.defaultRegion;
};

export const getSupportedRegionForTenant = (availableServices: IServiceMetadata[] | undefined) => {
    const serviceWithRegion = availableServices?.find(service => service.id === 'orchestrator' || !!service.supportedRegions?.length);
    return serviceWithRegion?.supportedRegions;
};
