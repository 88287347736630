import { IDirectoryEntry } from './directory';
import { IGroup } from './group';

export enum SAMLConditionType {
    Is = 'Is',
    IsNot = 'IsNot',
    Contains = 'Contains',
    NotContains = 'NotContains',
    IsCaseInsensitive = 'IsCaseInsensitive',
    ContainsCaseInsensitive = 'ContainsCaseInsensitive',
}

export interface ISAMLProvisioningCondition {
    ClaimName: string;
    ConditionType: SAMLConditionType;
    Value: string;
}

export interface ISAMLProvisioningRule {
    Id?: number;
    Name: string;
    Description: string;
    GroupsToAssign: IDirectoryEntry[] | string[];
    Conditions: ISAMLProvisioningCondition[];
}

export interface ISAMLRule {
    id?: number;
    partitionGlobalId: string;
    name: string;
    description: string;
    enabled: boolean;
    definition: ISAMLProvisioningCondition[] | string;
    assignedGroups?: IGroup[] | string[];
}

export interface ISAMLProvisioningSetting {
    AccountLinkConfirmation: boolean;
    AllowedDomains: string;
    ProvisioningRules?: ISAMLProvisioningRule[];
    AttributeMapper?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        CompanyName?: string;
        JobTitle?: string;
        Department?: string;
        City?: string;
        DisplayName?: string;
    };
}

export interface ISamlFormData {
    SingleSignOnServiceUrl: string;
    IdentityProviderEntityId: string;
    SigningCertificateLocation: {
        CertificateText: string;
    };
    AllowUnsolicitedAuthnResponse?: boolean;
    ExternalAuthUserMappingStrategy?: number;
    Saml2BindingType?: string;
    ServiceCertificateUsage?: string;
    IdentityProviderMetadataUrl?: string;
    ProvisioningSetting?: ISAMLProvisioningSetting;
}
