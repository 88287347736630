import { useShowDialog } from '@experiences/util';
import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

const useShowDeleteAADDialog = () => {
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();

    return () =>
        createDialog({
            title: translate({ id: 'CLIENT_LOGS_DELETE' }),
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            icon: 'error',
            body: (
                <>
                    <Typography>
                        {translate(
                            { id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_1' },
                            { titleProductName: process.buildConfigs.titleProductName },
                        )}
                    </Typography>
                    <br />
                    <Typography>
                        {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_2' })}
                    </Typography>
                </>
            ),
        });
};

export default useShowDeleteAADDialog;
