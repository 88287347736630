import {
    Checkbox,
    FormControlLabel,
    TextField,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    FC,
    useCallback,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';

const useStyles = makeStyles(theme =>
    createStyles({
        root: { width: '100%' },
        section: { marginTop: '20px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

export interface IEditIdentityProviderData {
    isActive?: boolean;
    isExclusive?: boolean;
    displayName?: string;
}

export const defaultEditIdentityProviderData: IEditIdentityProviderData = {
    isActive: false,
    isExclusive: false,
    displayName: '',
};

const EditIdentityProviderFormComponent: FC<{ disableIsActive?: boolean; switches?: JSX.Element }> = ({
    disableIsActive = false,
    switches,
}) => {
    const {
        control, setValue, errors,
    } = useFormContext<IEditIdentityProviderData>();
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <>
            <div className={clsx(classes.root, classes.section)}>
                {!isSecuritySettingsRevampEnabled && <Controller
                    name="isActive"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disableIsActive}
                                    checked={props.value}
                                    onChange={e => handleSwitch('isActive', e.target.checked, props.onChange)}
                                    color="primary"
                                    data-cy="edit-identity-provider-is-active-switch"
                                />
                            }
                            label={<div className={classes.switchLabel}>
                                {translate({ id: 'CLIENT_ENABLED' })}
                            </div>}
                        />
                    )}
                />}
                <Controller
                    name="isExclusive"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.value}
                                    onChange={e => handleSwitch('isExclusive', e.target.checked, props.onChange)}
                                    color="primary"
                                    data-cy="edit-identity-provider-is-exclusive-switch"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_FORCE_AUTOMATIC_LOGIN' })}
                                </div>
                            }
                        />
                    )}
                />
                {switches}
            </div>
            <div className={classes.section}>
                <Controller
                    label={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                    as={TextField}
                    control={control}
                    required
                    rules={{ required: true }}
                    error={!!errors.displayName}
                    helperText={
                        errors.displayName?.type === 'required' &&
                        translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_DISPLAY_NAME' }) },
                        )
                    }
                    name="displayName"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    InputLabelProps={{ id: 'displayNameLabel' }}
                    inputProps={{ 'aria-labelledby': 'displayNameLabel' }}
                    data-cy="edit-identity-provider-display-name"
                />
            </div>
        </>
    );
};

export default EditIdentityProviderFormComponent;
