import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { emailSelector } from '../../store/selectors';
import {
    INotificationMode,
    NotificationMode,
} from './interfaces/notificationSettings';

const useStyles = makeStyles(() =>
    createStyles({
        loaderContainer: {
            minHeight: '42px',
            minWidth: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        restoreDefaultSubscriptionBlock: { marginTop: '12px' },
        restoreDefaultSubscriptionLink: {
            marginTop: '5px',
            fontWeight: 600,
            display: 'block',
            cursor: 'pointer',
        },
    }),
);

const NotificationChannelsComponent: React.FC<{
    notificationChannelModes: INotificationMode[];
    onChangeChannelMode(modeIndex: number, newModeStatus: boolean): void;
    restoreDefaultSubscriptions(): void;
}> = ({
    notificationChannelModes, onChangeChannelMode, restoreDefaultSubscriptions,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const currentEmail = useSelector(emailSelector);

    const getChannelLabel = useCallback(
        (modeName: NotificationMode) => {
            if (modeName === NotificationMode.InApp) {
                return translate({ id: 'CLIENT_CLOUDRPA_TITLE' });
            } else if (modeName === NotificationMode.Email) {
                return translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_CHANNEL_EMAIL_TO' }, {
                    EMAIL: <b>
                        {currentEmail}
                    </b>,
                });
            }
            return '';
        },
        [ currentEmail, translate ],
    );

    return (
        <div>
            <Typography
                variant="subtitle1"
                gutterBottom
                component="div">
                {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_CHANNEL' })}
            </Typography>
            {notificationChannelModes.map((mode: INotificationMode, index: number) => {
                return (
                    <FormControlLabel
                        key={index}
                        control={
                            !mode.isUpdating ? (
                                <Checkbox
                                    checked={mode.isActive}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeChannelMode(index, e.target.checked)} />
                            ) : (
                                <div className={classes.loaderContainer}>
                                    <CircularProgress
                                        size={16}
                                        thickness={4} />
                                </div>
                            )
                        }
                        label={getChannelLabel(mode.name) as any}
                    />
                );
            })}
            {!notificationChannelModes.some((mode: INotificationMode) => mode.isActive) && (
                <div className={classes.restoreDefaultSubscriptionBlock}>
                    <portal-alert-bar
                        status="warning"
                        cancelable={false}>
                        <div>
                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_NOT_SUBSCRIBED_TO_ANY_TEXT' })}
                            <Link
                                className={classes.restoreDefaultSubscriptionLink}
                                onClick={restoreDefaultSubscriptions}>
                                {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_RESTORE_DEFAULT_SUBSCRIPTIONS' })}
                            </Link>
                        </div>
                    </portal-alert-bar>
                </div>
            )}
        </div>
    );
};

export default NotificationChannelsComponent;
