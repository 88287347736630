import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
    ButtonTypeMap,
    ExtendButtonBase,
    Fade,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        successIcon: {
            transform: 'scale(.8)',
            padding: 0,
            color: theme.palette.semantic.colorSuccessIcon,
        },
        copyButton: {
            transform: 'scale(.8)',
            padding: 0,
            '&:hover': { color: theme.palette.semantic.colorPrimary },
        },
    }),
);

const CHECKED_ICON_ANIMATION_TIMEOUT = 2 * 1000; // 2 seconds

const UiCopyButton: ExtendButtonBase<ButtonTypeMap<{ textToCopy: string }>> = (props: any) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ showCopied, setShowCopied ] = useState(false);

    const {
        textToCopy, ...rest
    } = props;

    const onCopy = useCallback(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), CHECKED_ICON_ANIMATION_TIMEOUT);
    }, []);

    return (
        <CopyToClipboard text={textToCopy}>
            <IconButton
                {...rest}
                className={showCopied ? classes.successIcon : classes.copyButton}
                onClick={onCopy}>
                {showCopied ? (
                    <Fade in={true}>
                        <Tooltip
                            title={translate({ id: 'CLIENT_COPIED' })}
                            open>
                            <CheckIcon data-cy="ui-copy-button-success-icon" />
                        </Tooltip>
                    </Fade>
                ) : (
                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                        <FileCopyOutlinedIcon data-cy="ui-copy-button-copy-icon" />
                    </Tooltip>
                )}
            </IconButton>
        </CopyToClipboard>
    );
};

export default UiCopyButton;
