import { useMemo } from 'react';

import { DirectoryConnectionType } from '../../services/identity/DirectoryConnectionService';
import { useCheckAuthenticationSetting } from './useCheckAuthenticationSetting';

const useDirectoryConnectionType = (): DirectoryConnectionType | undefined => {
    const authenticationSetting = useCheckAuthenticationSetting();
    return useMemo(() => {
        const hostConnectionType = authenticationSetting?.hostConnectionType?.toLowerCase();
        if (hostConnectionType === DirectoryConnectionType.AD
      || hostConnectionType === DirectoryConnectionType.LDAPAD) {
            return authenticationSetting?.hostConnectionType;
        }

        return authenticationSetting?.directoryConnectionDto.type;
    }, [ authenticationSetting ]);
};

export default useDirectoryConnectionType;
