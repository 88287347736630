import {
    ILicenseActivationResponse,
    IOfflineActivationRequestResponse,
} from '../../../common/interfaces/license.activation';
import {
    axiosDelete,
    get,
    put,
} from '../../utility/Requests.default';

export const licenseManagementActivateUri = '/api/license/management/activate';

export function activateLicenseOnline(licenseCode: string, isHostMode: boolean, isTrialPerSkuActivation: boolean) {
    if (isHostMode) {
        return put<ILicenseActivationResponse>(`${licenseManagementActivateUri}/host/online/license/${licenseCode}`);
    }
    return put<ILicenseActivationResponse>(`${licenseManagementActivateUri}/online/license/${licenseCode}`, { body: { isTrialPerSkuActivation } });
}

export function getActivationRequest(licenseCode: string, isHostMode: boolean, isTrialPerSkuActivation: boolean) {
    if (isHostMode) {
        return get<IOfflineActivationRequestResponse>(`${licenseManagementActivateUri}/host/activation-request`, { urlParams: { licenseCode } });
    }
    return get<IOfflineActivationRequestResponse>(`${licenseManagementActivateUri}/activation-request`, {
        urlParams: {
            licenseCode,
            isTrialPerSkuActivation,
        },
    });
}

export function activateOffline(licenseActivationData: string, isHostMode: boolean, isTrialPerSkuActivation: boolean) {
    if (isHostMode) {
        return put<ILicenseActivationResponse>(`${licenseManagementActivateUri}/host/offline`, { body: { activationData: licenseActivationData } });
    }
    return put<ILicenseActivationResponse>(`${licenseManagementActivateUri}/offline`, {
        body: {
            activationData: licenseActivationData,
            isTrialPerSkuActivation,
        },
    });
}

export function deactivateLicenseOnline(licenseCode: string, isHostMode: boolean) {
    if (isHostMode) {
        return axiosDelete(`${licenseManagementActivateUri}/host/online/license/${licenseCode}`);
    }
    return axiosDelete(`${licenseManagementActivateUri}/online/license/${licenseCode}`);
}

export function deactivateOffline(licenseCode: string, isHostMode: boolean) {
    if (isHostMode) {
        return axiosDelete(`${licenseManagementActivateUri}/host/offline/license/${licenseCode}`);
    }
    return axiosDelete(`${licenseManagementActivateUri}/offline/license/${licenseCode}`);
}
