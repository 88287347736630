import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import { deleteIpRange } from '../../../../services/access-policy/IPRangeService';
import { accountGlobalId } from '../../../../store/selectors';
import {
    checkIPv4InRange,
    checkIPv6InRange,
} from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteTenantConfirmation: { maxWidth: '417px' },
        deleteText: { color: theme.palette.semantic.colorErrorText },
        deleteButton: {
            color: theme.palette.semantic.colorForegroundInverse,
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const IPRestrictionDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, currentIp, ipRange, ipStatus, refreshCallback,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ loading, setLoading ] = useState(false);

    const inIpRange = useMemo(() => {
        return checkIPv4InRange(currentIp, ipRange.startIp, ipRange.endIp) || checkIPv6InRange(currentIp, ipRange.startIp, ipRange.endIp);
    }, [ currentIp, ipRange ]);

    const enforcementEnabled = useMemo(() => ipStatus === EnforcementType.ENABLED, [ ipStatus ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.INPROGRESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const deleteAndClose = useCallback(async () => {
        setLoading(true);
        try {
            await deleteIpRange(partitionGlobalId, ipRange.id);
            createNotification(
                translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_SUCCESS_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.SUCCESS,
            );
            refreshCallback();
            closeDialog(true);
        } catch (error) {
            createNotification(
                translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_FAILURE_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.ERROR,
            );
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        }
    }, [
        partitionGlobalId,
        ipRange,
        createNotification,
        translate,
        refreshCallback,
        closeDialog,
        getErrorObject,
        getErrorMessage,
        setErrorMessage,
    ]);

    const deleteBlocked = useMemo(() =>
        <Typography data-cy="delete-blocked-message">
            {translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BLOCKED' })}
        </Typography>
    , [ translate ]);

    const deleteMessage = useMemo(() =>
        <Typography data-cy="delete-confirmation-ask">
            <FormattedMessage
                id="CLIENT_IP_RESTRICTION_DELETE_ASK"
                values={{ 0: ipRange.name }}
            />
        </Typography>
    , [ ipRange.name ]);

    const deleteWarning = useMemo(() =>
        <Typography data-cy="delete-confirmation-type-warning">
            <FormattedMessage
                id="CLIENT_IP_RESTRICTION_DELETE_WARNING"
                values={{ 0: ipRange.name }}
            />
        </Typography>
    , [ ipRange.name ]);

    const deleteContent = useMemo(() => {
        if (enforcementEnabled && inIpRange) {
            return deleteBlocked;
        } else if (enforcementEnabled && !inIpRange) {
            return (
                <>
                    {deleteWarning}
                    <br />
                    {deleteMessage}
                </>
            );
        }
        return deleteMessage;

    }, [ deleteBlocked, deleteMessage, deleteWarning, enforcementEnabled, inIpRange ]);

    const buttons = useMemo(() => {
        return (
            <div className={classes.buttonContainer}>
                <Button
                    key="cancelButton"
                    className={classes.cancelButton}
                    onClick={() => closeDialog()}
                    color="primary"
                    data-cy="close-button-confirmation"
                >
                    {translate({ id: enforcementEnabled && inIpRange ? 'CLIENT_OK' : 'CLIENT_CANCEL' })}
                </Button>

                {!(enforcementEnabled && inIpRange) && <UiProgressButton
                    loading={loading}
                    key="primaryButton"
                    variant="contained"
                    color="inherit"
                    data-cy="delete-button-confirmation"
                    onClick={() => deleteAndClose()}
                    classes={{ colorInherit: classes.deleteButton }}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiProgressButton>}
            </div>
        );
    }, [
        classes.buttonContainer,
        classes.cancelButton,
        classes.deleteButton,
        closeDialog,
        deleteAndClose,
        enforcementEnabled,
        inIpRange,
        loading,
        translate,
    ]);

    return (
        <>
            <div className={classes.confirmDeleteSection}>
                {deleteContent}
            </div>
            {buttons}
        </>
    );
};

export default IPRestrictionDeleteDialogBody;
