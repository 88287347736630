import { AccountLicense } from '@experiences/constants';
import { ITabProductAllocation } from '@experiences/interfaces';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { getShortNumber } from '../../util/TypeUtil';
import { BorderLinearProgress } from './BorderLinearProgress';

const useStyles = makeStyles(theme =>
    createStyles({
        container: { margin: '-12px' },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            height: '100%',
        },
        titleForBar: { fontSize: '16px' },
        progressBar: { flex: '1 0 75%' },
        textForBar: {
            fontSize: '16px',
            verticalAlign: 'bottom',
            display: 'flex',
            alignItems: 'center',
        },
        barAndText: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        description: { color: theme.palette.semantic.colorForegroundDeEmp },
        item: { padding: '12px 0 !important' },
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginLeft: '8px',
            fontSize: '24px',
        },
        licenseContent: {
            textAlign: 'center',
            whiteSpace: 'nowrap',
            flex: '1 0 25%',
            marginLeft: '17px',
        },
    }),
);

export const HomePageLicenseAllocations: React.FC<{
    products: ITabProductAllocation[];
}> = ({ products }) => {
    const classes = useStyles();
    const {
        formatNumber, formatMessage: translate,
    } = useIntl();

    const isOverallocated = useCallback((product: ITabProductAllocation): boolean => {
        return product.allocated > product.total;
    }, []);

    return (
        <Grid
            container
            spacing={3}
            className={classes.container}>
            {products.map(product => (
                <Grid
                    key={product.code}
                    item
                    lg={6}
                    xs={12}
                    classes={{ item: classes.item }}
                    data-cy="licenses-grid-item">
                    <div className={classes.content}>
                        <Typography
                            className={classes.titleForBar}
                            data-cy="usage-chart-headers">
                            {translate({ id: `CLIENT_USAGE_HEADER_${product.code}` })}
                        </Typography>
                        <div className={classes.barAndText}>
                            <div
                                className={classes.progressBar}
                                data-cy="usage-chart-linear-progress-bar">
                                {!product.hideProgress && <BorderLinearProgress
                                    consumed={product.allocated}
                                    total={product.total} />}
                                <Typography className={classes.description}>
                                    {product.code === 'CV' &&
                    translate({
                        id:
                        product.subscriptionPlan && AccountLicense[product.subscriptionPlan] === AccountLicense.COMMUNITY
                            ? `CLIENT_USAGE_DESCRIPTION_COMMUNITY_${product.code}`
                            : `CLIENT_USAGE_DESCRIPTION_${product.code}`,
                    })}
                                </Typography>
                            </div>
                            <div className={classes.licenseContent}>
                                <Typography
                                    className={classes.textForBar}
                                    data-cy="usage-chart-allocated-of-total">
                                    <span>
                                        {product.showOnlyTotal
                                            ? getShortNumber(product.total)
                                            : translate(
                                                { id: 'CLIENT_ALLOCATED_OF_TOTAL' },
                                                {
                                                    0: formatNumber(product.allocated),
                                                    1: getShortNumber(product.total),
                                                },
                                            )}
                                    </span>
                                    {isOverallocated(product) && (
                                        <Tooltip
                                            title={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                            aria-label={translate({ id: 'CLIENT_LICENSE_HOMEPAGE_OVERALLOCATION_TOOLTIP' })}
                                        >
                                            <WarningIcon
                                                className={classes.yellowIcon}
                                                data-cy="usage-chart-over-allocation" />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
