import { Region } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { ICreateEditTenantPayload } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Divider,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { isString } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useEnabledTenantOperations } from '../../common/hooks/useEnabledTenantOperations';
import { useTenantOperations } from '../../common/hooks/useTenantOperations';
import { ITenant } from '../../common/interfaces/tenant/tenant';
import { leaseUrl } from '../../services/licensing/accountant/LeaseService';
import {
    getAvailableServices,
    getTenantById,
    organizationManagementTenantUri,
    tenantAvailableServicesUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../store/selectors';
import validateName from '../../util/validators/NameValidator';
import UiAlertBanner from '../common/UiAlertBanner';
import { UiDeleteButton } from '../common/UiDeleteButton/UiDeleteButton';
import UiForm from '../common/UiForm';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiTenantStatusBanner } from '../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import { IServiceMetadata } from '../tenants/interfaces/service';
import TenantDeleteDialogBody from '../tenants/subcomponents/TenantDeleteDialogBody';
import TenantStatusDialogBody from '../tenants/subcomponents/TenantStatusDialogBody';
import { TenantStatusConstants } from '../tenants/TenantConstants';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';
import {
    serviceInstanceUrl,
    useTenantsContext,
} from '../tenants/TenantsContextProvider';
import TenantGeneralForm from './TenantGeneralForm';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        centerLoader: { margin: 'auto' },
        deleteButton: {
            color: `${theme.palette.semantic.colorErrorText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
        deleteTenant: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '60px',
        },
        divider: { margin: '24px 0px' },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '20px',
        },
        sectionButton: {
            marginRight: '10px',
            marginTop: '20px',
            width: '120px',
        },
        sectionHeader: {
            display: 'flex',
            flexDirection: 'column',
        },
        statusButton: { '&.Mui-disabled': { pointerEvents: 'auto' } },
        tenantSettingsContainer: {
            display: 'flex',
            width: '100%',
        },
    }),
);

const TenantSettingsComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const tenantOperations = useTenantOperations();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const isAdmin = useSelector(isAdminSelector);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const { data: tenantContextData } = useTenantsContext();
    const {
        refreshAfterComplete, checkOperationList,
    } = useTenantOperationTrackerContext();
    const { canEnableTenant } = useEnabledTenantOperations(tenantContextData, isAdmin);
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const [ loading, setLoading ] = useState(false);

    const match = useRouteMatch<{ tenantId: string }>();
    const { tenantId } = useMemo<{ tenantId: string }>(
        () => ({ tenantId: match.params.tenantId }),
        [ match ],
    );

    const { data: tenant } = useSWR<ITenant>(tenantId ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null, getTenantById);

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ tenantAvailableServicesUri, partitionGlobalId, accountName ],
        getAvailableServices,
    );

    const methods = useForm<ICreateEditTenantPayload>({
        mode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            name: tenant?.name ?? '',
            color: tenant?.color ?? '',
            region: (tenant?.region as Region) ?? '',
            services: [],
        },
    });

    const initialValuesRef = useMemo<ICreateEditTenantPayload>(() => ({
        name: tenant?.name ?? '',
        color: tenant?.color ?? '',
        region: (tenant?.region as Region) ?? '',
        services: tenant?.tenantServiceInstances.map(service => service.serviceType) ?? [],
    }), [ tenant ]);

    const {
        formState, handleSubmit, setError, reset,
    } = useMemo(() => methods, [ methods ]);
    const { isDirty } = formState;

    const isStatusButtonDisabled = useMemo(() => {
        if (tenant?.id) {
            return checkOperationList(tenant.id) ||
            (!canEnableTenant && tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
            || tenant?.status.toUpperCase() === TenantStatusConstants.UPDATING);
        }
        return false;
    }, [ canEnableTenant, checkOperationList, tenant ]);

    const clickedDisableTenant = useCallback(
        async (data) => {
            return await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER' }),
                icon: 'warning',
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    status: TenantStatusConstants.DISABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                    refreshAfterComplete,
                },
            });
        },
        [ createDialog, translate, tenantOperations.tenantStatusModify, refreshAfterComplete ],
    );

    const clickedEnableTenant = useCallback(
        async (data) => {
            return await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
                icon: 'warning',
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    status: TenantStatusConstants.ENABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                    refreshAfterComplete,
                },
            });
        },
        [ createDialog, translate, tenantOperations.tenantStatusModify, refreshAfterComplete ],
    );

    const clickedDeleteTenant = useCallback(
        async (data) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_TENANT' }),
                icon: 'error',
                customDialogContent: TenantDeleteDialogBody,
                customDialogContentProps: {
                    tenant: data,
                    tenantDelete: tenantOperations.tenantDelete,
                },
            });
            if (proceed) {
                history.push(getRoute(RouteNames.OrganizationSettings));
            }
        },
        [ createDialog, getRoute, history, tenantOperations.tenantDelete, translate ],
    );

    const breadCrumbLinks = useMemo(() =>
        [
            {
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? '',
            },
            {
                link: RouteNames.TenantSettings.replace(':tenantId', tenantId),
                name: translate({ id: 'CLIENT_SETTINGS' }),
            },
        ],
    [ tenant, tenantId, translate ]);

    useEffect(() => {
        if (tenant && availableServices) {
            reset(initialValuesRef);
        }
    }, [ availableServices, initialValuesRef, reset, tenant ]);

    const updateTenantStatus = useCallback(
        async (data) => {
            const proceed = data.status.toUpperCase() === TenantStatusConstants.DISABLED
                ? await clickedEnableTenant(data)
                : await clickedDisableTenant(data);
            if (proceed) {
                try {
                    setLoading(true);
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                } finally {
                    setLoading(false);
                }
            }
        },
        [ clickedDisableTenant, clickedEnableTenant, getErrorMessage ],
    );

    const discardChanges = useCallback(() => {
        reset(initialValuesRef);
    },
    [ initialValuesRef, reset ]);

    const onSubmit = useCallback(
        async (data: ICreateEditTenantPayload) => {
            setLoading(true);

            data.name = data.name.trim();

            if (!validateName(data.name)) {
                setError('name', { type: 'invalid' });
                setLoading(false);
                return;
            }

            try {
                const proceed = data.name !== tenant?.name
                    ? await createDialog({
                        title: translate({ id: 'CLIENT_CHANGE_SERVICE_NAME' }),
                        body: (
                            <div>
                                <Typography>
                                    <FormattedMessage
                                        id={
                                            process.buildConfigs.disableUserInvite || DisableFeatureFedRamp
                                                ? 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME_NO_INVITE'
                                                : 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME'
                                        }
                                        values={{
                                            strong: (chunk: string) =>
                                                <strong>
                                                    {chunk}
                                                </strong>,
                                        }}
                                    />
                                </Typography>
                                <Typography style={{ marginTop: '8px' }}>
                                    {translate({ id: 'CLIENT_PROCEED_MESSAGE' })}
                                </Typography>
                            </div>
                        ),
                        icon: 'warning',
                        showCancel: true,
                        unclosable: true,
                        primaryButtonText: translate({ id: 'CLIENT_PROCEED' }),
                        dataCy: 'create-edit-tenant-confirmation-dialog',
                    })
                    : true;
                if (proceed) {
                    await tenantOperations.tenantEdit(data, tenant, {});
                } else {
                    setLoading(false);
                    return;
                }

                setLoading(false);

                mutate(
                    !EnableGetTenantsRedesign
                        ? [ serviceInstanceUrl, partitionGlobalId, accountName, true ]
                        : [ serviceInstanceUrl, partitionGlobalId, accountName, 'All', undefined, undefined, true ],
                );
                mutate(leaseUrl);
            } catch (error) {
                const errorObject = await getErrorObject(error);

                if (errorObject.response?.status === 504) {
                    setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
                } else {
                    const errorData = errorObject.response?.data;
                    const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                    setErrorMessage(errorResponse);
                }
                setLoading(false);
            }
        },
        [
            setError,
            EnableGetTenantsRedesign,
            partitionGlobalId,
            accountName,
            tenantOperations,
            tenant,
            createDialog,
            translate,
            DisableFeatureFedRamp,
            getErrorObject,
            getErrorMessage,
        ],
    );

    return (
        <UiPageContainer
            position='center'
            banner={
                <div>
                    {!canEnableTenant && tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED && <UiAlertBanner
                        dataCy="tenant-settings-max-tenants-banner"
                        type="info"
                        closeable={false}>
                        {translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED_REVAMP' })}
                    </UiAlertBanner>}
                    <UiTenantStatusBanner
                        dataCy="tenant-settings-disabled-tenant-banner"
                        status={tenant?.status}
                        tenantName={tenant?.name} />
                </div>
            }
            header={AdminBreadCrumbs(breadCrumbLinks)}
            loading={!tenant || !availableServices}
        >
            <div style={{
                maxWidth: '900px',
                height: '100%',
            }}>

                {!!errorMessage && (
                    <div
                        className={classes.error}
                        data-cy="add-edit-tenant-error">
                        <Typography>
                            {errorMessage}
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setErrorMessage('');
                            }}
                            style={{ marginTop: '12px' }}
                            data-cy="add-edit-tenant-retry"
                        >
                            {translate({ id: 'CLIENT_RETRY' })}
                        </Button>
                    </div>
                )}
                {!errorMessage && (<UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        isDirty
                            ? <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => discardChanges()}
                                    color="primary"
                                    data-cy="add-edit-tenant-discard"
                                >
                                    {translate({ id: 'CLIENT_DISCARD' })}
                                </Button>
                                <UiProgressButton
                                    type="submit"
                                    loading={loading}
                                    variant="contained"
                                    data-cy="tenant-settings-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE_CHANGES' })}
                                </UiProgressButton>
                            </div>
                            : undefined
                    }
                    centerChild={true}
                    dataCy="tenant-settings-component"
                >
                    {tenant && <FormProvider {...methods}>
                        <TenantGeneralForm
                            disableRegion={true}
                            disableForm={checkOperationList(tenant.id)}
                            tenant={tenant}
                            type="edit" />
                    </FormProvider>}

                    <Divider className={classes.divider} />

                    <div className={classes.sectionHeader}>
                        <Typography className={classes.inputLabel}>
                            {translate({
                                id: tenant?.status.toUpperCase() === TenantStatusConstants.ENABLED
                                    ? 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER'
                                    : 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER',
                            })}
                        </Typography>
                        <Typography>
                            {translate({
                                id: tenant?.status.toUpperCase() === TenantStatusConstants.ENABLED
                                    ? 'CLIENT_TENANT_SETTINGS_DISABLE_WARNING'
                                    : 'CLIENT_TENANT_SETTINGS_ENABLE_WARNING',
                            }, { 0: tenant?.name })}
                        </Typography>
                        <Tooltip
                            title={!canEnableTenant && tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
                                ? translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED_REVAMP' }) : ''}
                            placement='bottom-start'>
                            <div style={{
                                display: 'inline-block',
                                width: 'fit-content',
                            }}>
                                <Button
                                    className={clsx(classes.sectionButton, classes.statusButton)}
                                    onClick={() => updateTenantStatus(tenant)}
                                    variant="outlined"
                                    data-cy="tenant-settings-toggle-status-button"
                                    disabled={isStatusButtonDisabled}
                                >
                                    {tenant?.status === 'Enabled'
                                        ? translate({ id: 'CLIENT_DISABLE' })
                                        : translate({ id: 'CLIENT_ENABLE' })}
                                </Button>
                            </div>
                        </Tooltip>
                    </div>

                    <Divider className={classes.divider} />

                    <div className={classes.sectionHeader}>
                        <Typography className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DELETE_HEADER' })}
                        </Typography>
                        <Typography>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DELETE_WARNING' }, { 0: tenant?.name })}
                        </Typography>
                        <UiDeleteButton
                            innerButtonClass={classes.sectionButton}
                            onClick={() => clickedDeleteTenant(tenant)}
                            variant="outlined"
                            data-cy="tenant-settings-delete-button"
                        >
                            {translate({ id: 'CLIENT_DELETE' })}
                        </UiDeleteButton>
                    </div>
                </UiForm>)}
            </div>
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantSettingsComponent />
        {renderRoutes(route.routes)}
    </>
);
