import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../common/constants/Constant';
import {
    ExternalAuthenticationScheme,
    ExternalUserMappingStrategy,
    externalUserMappingStrategyOptions,
    Saml2BindingType,
    samlBindingTypeOptions,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import {
    IEditCertificateLocationsData,
    ISaml2ProviderSettings,
} from '../../../common/interfaces/externalIdentity';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import {
    mapExternalIdentityPayloadToErrorCode,
    mapExternalIdentityPayloadToSamlConfigData,
    mapSamlConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditCertificateLocationsFormComponent, { defaultEditCertificateLocationsData } from './EditCertificateLocationsFormComponent.default';
import EditIdentityProviderFormComponent, {
    defaultEditIdentityProviderData,
    IEditIdentityProviderData,
} from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        section: { marginTop: '20px' },
        selectInput: { marginTop: '12px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

export interface ISamlConfigData extends IEditIdentityProviderData, IEditCertificateLocationsData {
    serviceProviderEntityId?: string;
    identityProviderEntityId?: string;
    singleSignOnServiceUrl?: string;
    allowUnsolicitedAuthnResponse?: boolean;
    returnUrl?: string;
    externalAuthUserMappingStrategy?: ExternalUserMappingStrategy;
    saml2BindingType?: Saml2BindingType;
    externalAuthUserIdentifierClaim?: string;
}

const ConfigureSamlComponent: FC = () => {
    const { formatMessage: translate } = useIntl();

    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();
    const organizationName = useOrganizationName();

    const {
        open, close,
    } = useModalState(isSecuritySettingsRevampEnabled
        ? RouteNames.SecuritySettings : RouteNames.AuthSettings);

    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorDialog = useCentralErrorSetter();
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_CONFIGURE_SAML_GENERIC_ERROR' }));
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const methods = useForm<ISamlConfigData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditIdentityProviderData,
            ...defaultEditCertificateLocationsData,
            serviceProviderEntityId: `${window.location.origin}${process.buildConfigs.identityBaseRoute}`,
            identityProviderEntityId: '',
            singleSignOnServiceUrl: '',
            allowUnsolicitedAuthnResponse: false,
            returnUrl: '',
            externalAuthUserMappingStrategy: ExternalUserMappingStrategy.ByUserEmail,
            saml2BindingType: Saml2BindingType.HttpRedirect,
            externalAuthUserIdentifierClaim: '',
        },
    });
    const {
        handleSubmit, formState, reset, control, errors, setValue, watch,
    } = useMemo(() => methods, [ methods ]);
    const {
        isDirty, isSubmitting,
    } = formState;

    const fetchedExternalIdentity = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const externalAuthUserMappingStrategy = watch('externalAuthUserMappingStrategy');

    const { errorCode } = useMemo(() => {
        if (!fetchedExternalIdentity) {
            return { errorCode: undefined };
        }

        const { settings } = fetchedExternalIdentity;
        const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
        return { errorCode: parsedSettings?.ErrorCode };
    }, [ fetchedExternalIdentity ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const onSubmit = useCallback(
        async (data: ISamlConfigData) => {
            const payload = mapSamlConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            try {
                if (isSecuritySettingsRevampEnabled) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'SAML2' }),
                        body: <Typography>
                            {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'SAML2' })}
                        </Typography>,
                        icon: 'info',
                        showCancel: true,
                        primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                        cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                    });
                    payload.isActive = proceed;
                }

                const newExternalIdentityConfig = fetchedExternalIdentity
                    ? await updateExternalIdentityProvider(payload)
                    : await createExternalIdentityProvider(payload);

                const errorCode = mapExternalIdentityPayloadToErrorCode(newExternalIdentityConfig);
                if (errorCode) {
                    if (isSecuritySettingsRevampEnabled) {
                        setErrorDialog(errorCode);
                    } else {
                        setErrorMessage(errorCode);
                        setShowDrawerError(true);
                    }
                    return;
                }

                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }));
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                const parsedErrorMessage = await getErrorMessage(error);
                if (isSecuritySettingsRevampEnabled) {
                    setErrorDialog(parsedErrorMessage || translate({ id: 'CLIENT_CONFIGURE_SAML_GENERIC_ERROR' }));
                } else {
                    setErrorMessage(parsedErrorMessage || translate({ id: 'CLIENT_CONFIGURE_SAML_GENERIC_ERROR' }));
                    setShowDrawerError(true);
                }
            }
        },
        [
            fetchedExternalIdentity,
            isSecuritySettingsRevampEnabled,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            createDialog,
            setErrorDialog,
            getErrorMessage,
        ],
    );

    useEffect(() => {
        if (fetchedExternalIdentity) {
            reset(mapExternalIdentityPayloadToSamlConfigData(fetchedExternalIdentity));
        }
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: isSecuritySettingsRevampEnabled ? RouteNames.SecuritySettings : RouteNames.AuthSettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_SAML_SSO_CONFIGURATION' }),
        },
    ], [ isSecuritySettingsRevampEnabled, organizationName, translate ]);

    const form = useMemo(() =>
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton)}
                        onClick={() => close()}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        disabled={!isDirty}
                        variant="contained"
                        data-cy="configure-saml-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            isDrawer={!isSecuritySettingsRevampEnabled}
            addScrollPadding
        >
            {errorCode && (
                <portal-alert-bar
                    status="error"
                    cancelable={false}>
                    <Typography style={{ paddingRight: '8px' }}>
                        {errorCode}
                    </Typography>
                </portal-alert-bar>
            )}
            <FormProvider {...methods}>
                <EditIdentityProviderFormComponent disableIsActive={!!errorCode && !isDirty} />
            </FormProvider>
            <div className={classes.section}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="serviceProviderEntityIdLabel">
                    {translate({ id: 'CLIENT_SERVICE_PROVIDER_ENTITY_ID' })}
                </Typography>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{ required: true }}
                    error={!!errors.serviceProviderEntityId}
                    helperText={
                        errors.serviceProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="serviceProviderEntityId"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    inputProps={{ 'aria-labelledby': 'serviceProviderEntityIdLabel' }}
                    data-cy="configure-saml-service-provider-entity-id"
                />
            </div>
            <div className={classes.section}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="identityProviderEntityIdLabel">
                    {translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                </Typography>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{ required: true }}
                    error={!!errors.identityProviderEntityId}
                    helperText={
                        errors.identityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="identityProviderEntityId"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    inputProps={{ 'aria-labelledby': 'identityProviderEntityIdLabel' }}
                    data-cy="configure-saml-identity-provider-entity-id"
                />
            </div>
            <div className={classes.section}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="ssoServiceUrlLabel">
                    {translate({ id: 'CLIENT_SINGLE_SIGN_ON_SERVICE_URL' })}
                </Typography>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{ required: true }}
                    type="url"
                    error={!!errors.singleSignOnServiceUrl}
                    helperText={
                        errors.singleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="singleSignOnServiceUrl"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    inputProps={{ 'aria-labelledby': 'ssoServiceUrlLabel' }}
                    data-cy="configure-saml-single-sign-on-service-url"
                />
            </div>
            <div className={classes.section}>
                <Controller
                    name="allowUnsolicitedAuthnResponse"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.value}
                                    onChange={e => handleSwitch('allowUnsolicitedAuthnResponse', e.target.checked, props.onChange)}
                                    color="primary"
                                    data-cy="configure-saml-allow-unsolicited-authn-response"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ALLOW_UNSOLICITED_AUTHENTICATION_RESPONSE' })}
                                </div>
                            }
                        />
                    )}
                />
            </div>
            <div className={classes.section}>
                <Typography
                    className={clsx(classes.inputLabel, classes.inputMargin)}
                    id="returnUrlLabel">
                    {translate({ id: 'CLIENT_RETURN_URL' })}
                </Typography>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{ required: true }}
                    type="url"
                    error={!!errors.returnUrl}
                    helperText={errors.returnUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    name="returnUrl"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    inputProps={{ 'aria-labelledby': 'returnUrlLabel' }}
                    data-cy="configure-saml-return-url"
                />
            </div>
            <UiSelect
                control={control}
                className={classes.selectInput}
                name="externalAuthUserMappingStrategy"
                inputLabel={translate({ id: 'CLIENT_EXTERNAL_USER_MAPPING_STRATEGY' })}
                error={!!errors.externalAuthUserMappingStrategy}
                options={externalUserMappingStrategyOptions}
                required
                helperText={
                    errors.externalAuthUserMappingStrategy?.type === 'required' &&
            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
                fullWidth
                dataCy="configure-saml-external-auth-user-mapping-strategy"
            />
            {externalAuthUserMappingStrategy?.toString() === ExternalUserMappingStrategy.ByUserName.toString() && (
                <div className={classes.section}>
                    <Typography
                        className={clsx(classes.inputLabel, classes.inputMargin)}
                        id="externalAuthUserIdentifierClaimLabel"
                    >
                        {translate({ id: 'CLIENT_EXTERNAL_AUTH_USER_IDENTIFIER_CLAIM' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        rules={{ required: true }}
                        error={!!errors.externalAuthUserIdentifierClaim}
                        helperText={
                            errors.externalAuthUserIdentifierClaim?.type === 'required' &&
                translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        name="externalAuthUserIdentifierClaim"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-labelledby': 'externalAuthUserIdentifierClaimLabel' }}
                        data-cy="configure-saml-external-auth-user-identifier-claim"
                    />
                </div>
            )}
            <UiSelect
                control={control}
                className={classes.selectInput}
                name="saml2BindingType"
                inputLabel={translate({ id: 'CLIENT_SAML_BINDING_TYPE' })}
                error={!!errors.saml2BindingType}
                options={samlBindingTypeOptions}
                required
                helperText={errors.saml2BindingType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                fullWidth
                dataCy="configure-saml2-binding-type"
            />
            <FormProvider {...methods}>
                <EditCertificateLocationsFormComponent />
            </FormProvider>
        </UiForm>
    , [
        isSecuritySettingsRevampEnabled,
        classes,
        close,
        control,
        errorCode,
        errors,
        externalAuthUserMappingStrategy,
        handleSubmit,
        handleSwitch,
        isDirty,
        isSubmitting,
        methods,
        onSubmit,
        translate,
    ]);

    return (
        isSecuritySettingsRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                maxWidth="900px"
                position='center'>
                {form}
            </UiPageContainer>
            :
            <UiDrawer
                title={translate({ id: 'CLIENT_CONFIGURE_SAML_INTEGRATION' })}
                drawerProps={{
                    anchor: 'right',
                    open,
                    onClose: () => close(),
                }}
                error={{
                    showError: showDrawerError,
                    message: errorMessage,
                }}
                themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            >
                {form}
            </UiDrawer>
    );
};

export default ConfigureSamlComponent;
