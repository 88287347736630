import Cookies from 'js-cookie';

class UiStorage {
    setItem = (name: string, value: any) => {
        localStorage.setItem(name, value);
        Cookies.set(name, value);
    };

    getItem = (name: string) => {
        return localStorage.getItem(name) || Cookies.get(name) || null;
    };

    removeItem = (name: string) => {
        localStorage.removeItem(name);
        Cookies.remove(name);
    };
}

const UiStorageSingleton = new UiStorage();

export default UiStorageSingleton;
