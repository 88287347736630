import { useUpdateLanguage } from '@experiences/locales';
import { useApolloTheme } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router';

import useUserInfo from '../../auth/hooks/UserInfo';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useTriggerPortalShellRefresh } from '../../common/hooks/useTriggerPortalShellRefresh';
import {
    accountLogicalName,
    isHostModeSelector,
} from '../../store/selectors';
import { useIsSettingsRouteMatched } from '../../util/RouteUtil';
import { useTenantsContext } from '../tenants/TenantsContextProvider';

export const LeftNav: React.FC = () => {
    const [ portalNavElement, setPortalNavElement ] = useState<HTMLPortalNavElement | null>(null);
    const { token } = useUserInfo();

    const {
        updateTheme,
        updateSelectedTheme,
    } = useApolloTheme();
    const accountName = useSelector(accountLogicalName);
    const isHostMode = useSelector(isHostModeSelector);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const getRoute = useRouteResolver();
    const location = useLocation();
    const history = useHistory();
    const settingsRoute = useIsSettingsRouteMatched();

    const updateLanguage = useUpdateLanguage();

    const {
        shouldRefreshPortalShell, triggerPortalShellRefresh,
    } = useTriggerPortalShellRefresh();
    const { changeSelectedTenant } = useTenantsContext();

    const adminNavigationRoute = useMemo(() => {
        const routeIfAdminRevamp = isAdminRevampEnabled
            ? RouteNames.OrganizationAdminHome
            : RouteNames.Services;
        return getRoute(isHostMode
            ? RouteNames.Organizations
            : routeIfAdminRevamp);
    }, [ getRoute, isAdminRevampEnabled, isHostMode ]);

    const onLeftNavItemChangeCallback = useCallback(
        (e: any) => {
            const requestedRoute = e.detail.serviceid as 'admin' | 'home' | 'help';
            if (requestedRoute === 'admin') {
                history.push(adminNavigationRoute);
            } else if (requestedRoute === 'home') {
                history.push(getRoute(RouteNames.Home));
            } else if (requestedRoute === 'help') {
                history.push(getRoute(RouteNames.ResourceCenter));
            }
        },
        [ history, adminNavigationRoute, getRoute ],
    );

    const active = useMemo(() => {
        if (location.pathname?.toLowerCase() === getRoute(RouteNames.Home).toLowerCase()) {
            return 'home';
        } else if (location.pathname?.toLowerCase() === getRoute(RouteNames.ResourceCenter).toLowerCase()) {
            return 'help';
        } else if (settingsRoute) {
            return 'admin';
        }
        return '';
    }, [ location, settingsRoute, getRoute ]);

    // Updates the logo and tenant color in the app-bar immediately after changed
    useEffect(() => {
        if (shouldRefreshPortalShell && typeof portalNavElement?.RefreshAccountInfo === 'function') {
            triggerPortalShellRefresh();
            portalNavElement?.RefreshAccountInfo();
        }
    }, [ shouldRefreshPortalShell, triggerPortalShellRefresh, portalNavElement ]);

    const portalNavElementCallback = useCallback(
        (ref: HTMLPortalNavElement | null) => {
            ref?.addEventListener('logoutClicked', () => {
                history.push('/portal_/logout');
            });

            ref?.addEventListener('languageChanged', (event: any) => {
                updateLanguage(event.detail.selectedLanguageId);
            });

            ref?.addEventListener('settingsItemClick', onLeftNavItemChangeCallback);

            ref?.addEventListener('themeChanged', (event: any) => {
                updateTheme(event.detail.selectedThemeId === 'dark' ? 'dark' : 'light');
            });

            ref?.addEventListener('themeSelectChanged', (event: any) => {
                updateSelectedTheme(event.detail.selectedThemeId);
            });

            ref?.addEventListener('tenantChanged', (event: any) => {
                changeSelectedTenant(event.detail);
            });

            setPortalNavElement(ref);
        },
        [ onLeftNavItemChangeCallback, history, updateLanguage, updateTheme, updateSelectedTheme, changeSelectedTenant ],
    );

    return (
        <portal-nav
            ref={portalNavElementCallback}
            url={window.location.origin}
            token={token || undefined}
            active={active as any}
            account={accountName}
            overrides="admin,help,home"
        />
    );
};

export default LeftNav;
