import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../common/constants/RouteNames';
import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { ServiceErrorCode } from '../../../common/interfaces/error';
import { IServiceError } from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import { TenantStatusConstants } from '../TenantConstants';
import { navigateToLicenseConfigure } from './helpers/ManageLicensesHelper';

const useStyles = makeStyles(() =>
    createStyles({
        body2: { marginTop: '20px' },
        buttonContainer: {
            display: 'flex',
            marginTop: '32px',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const TenantStatusDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog,
    createDialog,
    tenant,
    status,
    service,
    tenantStatusModify,
    refreshAfterComplete,
}) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();
    const getRoute = useRouteResolver();

    const {
        parseError, parseErrorObject, safeParse,
    } = useGetErrorInfo();

    const accountGUID = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);

    const adminRevampEnabled = useIsAdminRevampEnabled();

    const [ loading, setLoading ] = useState(false);

    const close = useCallback(
        (proceed?: boolean) => {
            if (proceed) {
                refreshAfterComplete();
            }
            closeDialog(proceed);
        },
        [ closeDialog, refreshAfterComplete ],
    );

    const changeStatusAndClose = useCallback(async () => {
        setLoading(true);

        try {
            await tenantStatusModify(service, tenant, accountGUID, status);
            close(true);
        } catch (error) {
            refreshAfterComplete();

            const parsedError = await parseErrorObject(error);
            const message = safeParse(parsedError.Message);

            const failedServices: IServiceError[] = message;
            if (failedServices?.length) {
                const licenseFailures = failedServices.filter(
                    service => service.ErrorCode === ServiceErrorCode.InsufficientLicense,
                );
                if (licenseFailures.length) {
                    const licenseFailureServiceString = licenseFailures
                        .map(service => getFriendlyName(service.ServiceType))
                        .join(', ');

                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_HEADER' }),
                        body: translate(
                            { id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_BODY' },
                            { services: licenseFailureServiceString },
                        ),
                        icon: 'info',
                        primaryButtonText: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                        showCancel: true,
                    });

                    if (proceed) {
                        navigateToLicenseConfigure(
                            `${getRoute(RouteNames.Services, accountName)}/configure/${tenant.id.toLowerCase()}`,
                            getRoute(RouteNames.Services, accountName),
                        );
                    }

                    return;
                }

                const genericFailures = failedServices.filter(
                    service => service.ErrorCode !== ServiceErrorCode.InsufficientLicense,
                );
                if (genericFailures.length) {
                    const genericFailureServiceString = genericFailures
                        .map(service => getFriendlyName(service.ServiceType))
                        .join(', ');

                    await createDialog({
                        title: translate({ id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_HEADER' }),
                        body: translate(
                            { id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_BODY' },
                            {
                                status,
                                services: genericFailureServiceString,
                            },
                        ),
                        icon: 'info',
                        showCancel: true,
                    });

                    return;
                }
            }

            setErrorMessage(await parseError(error));
        } finally {
            setLoading(false);
            close();
        }
    }, [
        tenantStatusModify,
        service,
        tenant,
        accountGUID,
        status,
        close,
        refreshAfterComplete,
        parseErrorObject,
        safeParse,
        setErrorMessage,
        parseError,
        createDialog,
        translate,
        getRoute,
        accountName,
    ]);

    return (
        <div data-cy='tenant-status-dialog-body'>
            {status === TenantStatusConstants.DISABLE ? (
                adminRevampEnabled ?
                    <>
                        <Typography>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DISABLE_DIALOG_REMINDER_1' })}
                        </Typography>
                        <br />
                        <Typography>
                            {translate({ id: 'CLIENT_TENANT_SETTINGS_DISABLE_DIALOG_REMINDER_2' })}
                        </Typography>
                    </>
                    :
                    <Typography>
                        {translate({ id: 'CLIENT_DISABLE_TENANT' }, { 0: tenant.name })}
                    </Typography>
            ) : (
                <div>
                    <Typography>
                        {translate({ id: 'CLIENT_ALLOCATE_LICENSES_AUTOMATICALLY_ON_ENABLE_TENANT' })}
                    </Typography>
                    <Typography className={classes.body2}>
                        {translate({ id: 'CLIENT_ENABLE_TENANT' }, { 0: tenant.name })}
                    </Typography>
                </div>
            )}
            <div className={classes.buttonContainer}>
                <Button
                    key="cancel"
                    className={classes.cancelButton}
                    onClick={() => close()}
                    color="primary"
                    data-cy="secondary-button-confirmation"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    loading={loading}
                    key="skip"
                    variant="contained"
                    data-cy="primary-button-confirmation"
                    onClick={changeStatusAndClose}
                >
                    {translate({ id: `CLIENT_${status.toUpperCase()}` })}
                </UiProgressButton>
            </div>
        </div>
    );
};

export default TenantStatusDialogBody;
