import {
    useCallback,
    useState,
} from 'react';
import { useAuth } from 'react-oidc-context';

import { clearAuthStorage } from '../utils/ClearStorage';

interface ILogoutParams {
    returnUrl: string;
    shouldClearAuthStorage: boolean;
}

export default function useAuthentication() {
    const {
        isLoading, clearStaleState, signoutRedirect,
    } = useAuth();

    const [ isLogoutInProgress, setIsLogoutInProgress ] = useState(false);

    const logout = useCallback(
        async ({
            returnUrl = undefined, shouldClearAuthStorage = true,
        }: Partial<ILogoutParams> = {}) => {
            setIsLogoutInProgress(true);

            returnUrl = returnUrl ?? `${window.location.origin}${process.buildConfigs.postLogoutReturnPathname}`;

            clearStaleState();

            if (shouldClearAuthStorage) {
                clearAuthStorage();
            }

            signoutRedirect({ post_logout_redirect_uri: returnUrl });
        },
        [ signoutRedirect ],
    );

    return {
        logout,
        isLoggingOut: isLoading || isLogoutInProgress,
    };
}
