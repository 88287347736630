import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    mapAutomationCloudLanguageToStripe,
    useLocalization,
} from '@experiences/locales';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useEcommerce } from './helpers/useEcommerce';
import { ISkuPackage } from './interfaces/ecommerce';

const EnableEcommerceIntegration = getFeatureFlagValue(Features.EnableEcommerceIntegration.name);
const { loadStripe } = EnableEcommerceIntegration ? require('@stripe/stripe-js') : require('@stripe/stripe-js/pure');

export const EcommerceElementsWrapperComponent: React.FC<{
    skuPackage?: ISkuPackage;
    selectedCurrency?: string;
}> = ({
    skuPackage, selectedCurrency, children,
}) => {
    const [ stripePromise, setStripePromise ] = useState<Promise<Stripe | null> | null>(null);
    const { publicKey } = useEcommerce(skuPackage, selectedCurrency);
    const locale = useLocalization();
    const stripeLocale = useMemo(() => mapAutomationCloudLanguageToStripe(locale), [ locale ]);

    useEffect(() => {
        if (publicKey) {
            setStripePromise(
                loadStripe(publicKey, { locale: stripeLocale }),
            );
        }
    }, [ publicKey, stripeLocale ]);

    return (
        <Elements
            stripe={stripePromise}
            options={{ locale: stripeLocale }}
            key={locale} >
            {children}
        </Elements>
    );
};
