import GlobalStyles from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    isAdminSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { DirectoryIntegrationComponent } from './DirectoryIntegrationComponent.default';
import {
    ExternalIdentityProvidersFormComponent,
    initialData,
    useExternalIdentityProvidersForm,
} from './ExternalIdentityProvidersFormComponent.default';
import InstallationKeyComponent from './InstallationKeyComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        centerLoader: { margin: 'auto' },
        buttonWidth: { width: '160px' },
        cancelButton: { marginRight: '10px' },
    }),
}));

const ExternalProvidersComponentRevamp: React.FC = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);

    const methods = useForm({
        mode: 'onSubmit',
        defaultValues: initialData,
    });

    const {
        handleSubmit, reset, formState: { isDirty },
    } = methods;

    const {
        loading, onSubmit,
    } = useExternalIdentityProvidersForm(reset);

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    if (loading) {
        return <div className={classes.centerLoader}>
            <CircularProgress />
        </div>;
    }

    return (
        <UiForm
            centerChild
            onSubmit={handleSubmit(onSubmit)}
            actions={
                isDirty && <div className={classes.actions}>
                    <Button
                        className={classes.cancelButton}
                        onClick={handleCancel}
                        color="primary"
                        data-cy="azure-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        innerButtonClass={classes.buttonWidth}
                        loading={loading}
                        disabled={!isAdmin}
                        variant="contained"
                        type="submit"
                        data-cy="azure-save-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }>
            {process.buildConfigs.enableInstallationKey && isHostMode && <InstallationKeyComponent />}
            <FormProvider {...methods}>
                <ExternalIdentityProvidersFormComponent />
            </FormProvider>
            {!isHostMode && <DirectoryIntegrationComponent />}
        </UiForm>
    );
};

export default ExternalProvidersComponentRevamp;
