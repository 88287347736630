import {
    IReferenceToken,
    IReferenceTokenPayload,
} from '../../common/interfaces/cis/profile';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const referenceTokenUrl = `${process.buildConfigs.identityApiBaseRoute}/ReferenceToken`;

export function getReferenceToken(url: string) {
    return get<IReferenceToken[]>(`${referenceTokenUrl}`);
}

export function generateReferenceToken(payload: IReferenceTokenPayload) {
    return post<string>(`${referenceTokenUrl}`, { body: payload });
}

export function deleteReferenceToken(referenceTokenId: string) {
    return axiosDelete(`${referenceTokenUrl}/${referenceTokenId}`);
}

export function regenerateReferenceToken(referenceTokenId: string, payload: { expiration: string }) {
    return put<string>(`${referenceTokenUrl}/${referenceTokenId}`, { body: payload });
}
