import { UiStorage } from '@experiences/util';
import {
    CircularProgress,
    Divider,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import {
    getProcessesRecentRuns,
    processesUrl,
} from '../../../../services/orchestrator/ProcessesService';
import { getCurrentOrchestratorUser } from '../../../../services/orchestrator/UsersService';
import { setUserProfile } from '../../../../store/action/UserProfileAction';
import {
    accountLogicalName,
    profile,
} from '../../../../store/selectors';
import { useUserReadableTime } from '../../../../util/DateUtil';
import { isHtml } from '../../../../util/TypeUtil';
import HomePageHeader from '../../../home/helper/HomePageHeader';
import { IOrchestratorUser } from '../../../users/interfaces/user';
import useTenantChangeListener from '../../TenantChangeListener';
import { IRecentRun } from '../interfaces/processes';

const useStyles = makeStyles(theme => ({
    recentRunsContainer: {
        paddingBottom: '20px',
        paddingTop: '20px',
        '& .MuiButton-textPrimary': {
            color: theme.palette.semantic.colorPrimary,
            outline: 'none !important',
        },
    },
    divider: { margin: '8px 0' },
    listItem: { padding: '0' },
    recentRunsList: {
        paddingTop: '20px',
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    inline: {
        maxWidth: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '5px',
        color: theme.palette.semantic.colorForeground,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        height: '16px',
        lineHeight: '16px',
        border: 'solid 1px',
        fontSize: '10px',
        fontWeight: 600,
        borderRadius: '8px',
        padding: '0 8px',
        '&.Pending, &.New, &.Resumed, &.Retried': {
            color: theme.palette.semantic.colorWarningText,
            backgroundColor: theme.palette.semantic.colorChipWarningBackground,
            borderColor: theme.palette.semantic.colorWarningText,
        },
        '&.Stopping, &.Stopped, &.Terminating, &.Suspended, &.Abandoned, &.Deleted': {
            color: theme.palette.semantic.colorForeground,
            backgroundColor: theme.palette.semantic.colorBackgroundGray,
            borderColor: theme.palette.semantic.colorForeground,
        },
        '&.Running, &.InProgress': {
            color: theme.palette.semantic.colorInfoForeground,
            backgroundColor: theme.palette.semantic.colorChipInfoBackground,
            borderColor: theme.palette.semantic.colorInfoForeground,
        },
        '&.Successful': {
            color: theme.palette.semantic.colorSuccessText,
            backgroundColor: theme.palette.semantic.colorChipSuccessBackground,
            borderColor: theme.palette.semantic.colorSuccessText,
        },
        '&.Faulted, &.Failed': {
            color: theme.palette.semantic.colorErrorText,
            backgroundColor: theme.palette.semantic.colorChipErrorBackground,
            borderColor: theme.palette.semantic.colorErrorText,
        },
    },
}));

export const RecentRunsComponent: React.FC = () => {
    const classes = useStyles();
    const initialTenant = UiStorage.getItem('PORTAL_CURRENT_TENANT');
    const [ currentTenant, setCurrentTenant ] = useState(initialTenant);
    const logicalName = useSelector(accountLogicalName);
    const currentProfile = useSelector(profile);

    const { userReadableTime } = useUserReadableTime();

    const tenantChangeListener = useTenantChangeListener(setCurrentTenant);

    const {
        data: recentRuns, isValidating: isValidating, error: recentRunError,
    } = useSWR<IRecentRun[], Error>(
        `${processesUrl}/recentRuns/${currentTenant}`,
        currentTenant
            ? ((async () => {
                if (currentProfile.currentOrchestratorUser) {
                    return getProcessesRecentRuns(currentTenant, logicalName, currentProfile.currentOrchestratorUser.Id);
                }
                const user: IOrchestratorUser = await getCurrentOrchestratorUser(currentTenant, logicalName);
                if (isHtml(user)) {
                    throw new Error();
                }
                setUserProfile({
                    ...currentProfile,
                    currentOrchestratorUser: user,
                });
                return getProcessesRecentRuns(currentTenant, logicalName, user.Id);

            }) as () => Promise<IRecentRun[]>)
            : () => {
                throw new Error();
            },
    );

    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        window.addEventListener('tenantChanged', tenantChangeListener);
        mutate(`${processesUrl}/recentRuns/${currentTenant}`);
        return function cleanup() {
            window.removeEventListener('tenantChanged', tenantChangeListener);
        };
    // eslint-disable-next-line
  }, [currentTenant]);

    return (
        <div
            className={clsx(classes.recentRunsContainer)}
            data-cy="home-page-recent-runs">
            <HomePageHeader
                label={translate({ id: 'CLIENT_RECENT_RUNS' })}
                fontSize="20px" />
            <div className={classes.recentRunsList}>
                {!isValidating && (
                    <div>
                        {recentRuns &&
              recentRuns.map((recentRunInfo, id) => (
                  <div key={id}>
                      <div
                          key={id}
                          className={classes.listItem}
                          data-cy="home-page-recent-runs-list">
                          <div>
                              <>
                                  <Typography component="div">
                                      <div className={classes.flex}>
                                          <Tooltip title={recentRunInfo.Name}>
                                              <div className={classes.inline}>
                                                  {recentRunInfo.Name}
                                              </div>
                                          </Tooltip>
                                          <div className={clsx(classes.chip, recentRunInfo.Status)}>
                                              {translate({ id: 'JOBSTATUS_' + recentRunInfo.Status.toUpperCase() })}
                                          </div>
                                      </div>
                                  </Typography>
                              </>
                              <>
                                  <Typography
                                      component="span"
                                      variant="caption">
                                      <Tooltip title={recentRunInfo.StartDate}>
                                          <span>
                                              {userReadableTime(moment(recentRunInfo.StartDate))}
                                          </span>
                                      </Tooltip>
                                  </Typography>
                              </>
                          </div>
                      </div>
                      <Divider className={classes.divider} />
                  </div>
              ))}
                    </div>
                )}
                {isValidating && <CircularProgress
                    size={36}
                    thickness={4} />}
                {!isValidating && recentRuns && recentRuns.length === 0 && (
                    <div data-cy="home-page-recent-runs-empty">
                        {translate({ id: 'CLIENT_NO_PROCESSES' })}
                    </div>
                )}
                {!isValidating && recentRunError && <div>
                    {translate({ id: 'CLIENT_NO_PROCESSES' })}
                </div>}
            </div>
        </div>
    );
};
export default RecentRunsComponent;
