import { AccountLicense } from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useApolloTheme } from '@experiences/theme';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Button,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { accountType } from '../../../store/selectors';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
        },
        infoText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '20px',
        },
        imagePreview: {
            borderRadius: '2px',
            boxSizing: 'border-box',
            marginBottom: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70px',
            width: '102px',
            padding: '10px',
        },
        lightImagePreview: { backgroundColor: Tokens.Colors.ColorBackgroundLight },
        darkImagePreview: { backgroundColor: Tokens.Colors.ColorBackgroundDark },
        flex: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        button: { marginRight: '16px' },
        removeButton: {
            marginRight: '16px',
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            color: theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon),
            maxHeight: '20px',
            maxWidth: '20px',
            marginLeft: '-12px',
            marginTop: '-8px',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '16px 0px 8px 0',
        },
    }),
);

const FILE_SIZE_LIMIT = 1024 * 1024;

const THEMES = [ 'light', 'dark' ];

const CobrandingComponent: React.FC<{
    lightLogo?: string;
    darkLogo?: string;
    lightFile: File | null;
    darkFile: File | null;
    setLightFile: any;
    setDarkFile: any;
    deleteLightLogo: boolean;
    deleteDarkLogo: boolean;
    setDeleteLightLogo: any;
    setDeleteDarkLogo: any;
}> = ({
    lightLogo,
    darkLogo,
    lightFile,
    darkFile,
    setLightFile,
    setDarkFile,
    deleteLightLogo,
    deleteDarkLogo,
    setDeleteLightLogo,
    setDeleteDarkLogo,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        theme: apolloTheme, themeId,
    } = useApolloTheme();

    const [ lightLogoUrl, setLightLogoUrl ] = useState<string | undefined>();
    const [ darkLogoUrl, setDarkLogoUrl ] = useState<string | undefined>();
    const [ error, setError ] = useState<string | undefined>();

    const lightInputRef = createRef<HTMLInputElement>();
    const darkInputRef = createRef<HTMLInputElement>();
    const { includesLicense } = useCheckLicense();
    const EnableAirgappedLicenseActivation = getFeatureFlagValue(Features.EnableOffersRevampAdmin.name);
    const showUpgrade = useMemo(() =>
        EnableAirgappedLicenseActivation && includesLicense([ AccountLicense.FREEKIT, AccountLicense.COMMUNITY ]),
    [ EnableAirgappedLicenseActivation, includesLicense ]);

    const licensePlan = useSelector(accountType);

    const setUrls = useCallback((logo, deleteLogo, file, logoUrl, setLogoUrl) => {
        if (logo && !deleteLogo && !file) {
            setLogoUrl(logo);
        } else if (!logo && logoUrl && !file) {
            setLogoUrl(undefined);
        } else if (!logoUrl && !deleteLogo && file) {
            setLogoUrl(URL.createObjectURL(file));
        }
    }, []);

    useEffect(() => {
        setUrls(lightLogo, deleteLightLogo, lightFile, lightLogoUrl, setLightLogoUrl);
    }, [ setUrls, lightLogo, deleteLightLogo, lightFile, lightLogoUrl, setLightLogoUrl ]);

    useEffect(() => {
        setUrls(darkLogo, deleteDarkLogo, darkFile, darkLogoUrl, setDarkLogoUrl);
    }, [ setUrls, darkLogo, deleteDarkLogo, darkFile, darkLogoUrl, setDarkLogoUrl ]);

    const onFileSelected = useCallback(
        (event, theme) => {
            let uploadedFile: File;
            if (event.target.files && (uploadedFile = event.target.files[0])) {
                if (uploadedFile.size > FILE_SIZE_LIMIT) {
                    setError('CLIENT_LOGO_FILE_SIZE_ERROR');
                } else if (theme === 'light') {
                    setLightFile(uploadedFile);
                    setLightLogoUrl(URL.createObjectURL(uploadedFile));
                    setError(undefined);
                } else {
                    setDarkFile(uploadedFile);
                    setDarkLogoUrl(URL.createObjectURL(uploadedFile));
                    setError(undefined);
                }
                if (theme === 'light' && lightInputRef.current) {
                    lightInputRef.current.value = '';
                } else if (darkInputRef.current) {
                    darkInputRef.current.value = '';
                }
            }
        },
        [ darkInputRef, lightInputRef, setDarkFile, setLightFile ],
    );

    const onFileRemove = useCallback(
        (theme) => {
            if (theme === 'light') {
                if (lightLogo) {
                    setDeleteLightLogo(true);
                }
                setLightFile(null);
                setLightLogoUrl(undefined);
            } else {
                if (darkLogo) {
                    setDeleteDarkLogo(true);
                }
                setDarkFile(null);
                setDarkLogoUrl(undefined);
            }
        },
        [ darkLogo, lightLogo, setDarkFile, setDeleteDarkLogo, setDeleteLightLogo, setLightFile ],
    );

    const onUploadClick = useCallback((inputRef) => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    }, []);

    const hiddenInputs = useCallback(
        (theme) => (
            <input
                key={`file-input-${theme}`}
                ref={theme === 'light' ? lightInputRef : darkInputRef}
                type="file"
                name={`${theme}Logo`}
                style={{ display: 'none' }}
                accept=".png,.jpeg,.jpg,.svg"
                onChange={(event) => onFileSelected(event, theme)}
                data-cy={`file-input-${theme}`}
            />
        ),
        [ darkInputRef, lightInputRef, onFileSelected ],
    );

    const imagePreview = useCallback(
        (theme) =>
            (theme === 'light' && lightLogoUrl) || (theme === 'dark' && darkLogoUrl) ? (
                <div
                    key={`image-preview-${theme}`}
                    className={classes.flex}>
                    <div
                        className={clsx(
                            classes.imagePreview,
                            theme === 'light' ? classes.lightImagePreview : classes.darkImagePreview,
                        )}
                        style={
                            theme === themeId
                                ? { border: `1px solid ${apolloTheme.palette.semantic.colorBorderDeEmp}` }
                                : undefined
                        }
                        data-cy={`image-preview-${theme}`}
                    >
                        <img
                            src={theme === 'light' ? lightLogoUrl : darkLogoUrl}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            alt={`${theme} logo`}
                        />
                    </div>
                    <Tooltip
                        arrow
                        title={translate({ id: `CLIENT_LOGO_REMOVE_${theme.toUpperCase()}` })}>
                        <IconButton
                            size="small"
                            className={classes.removeButton}
                            data-cy={`remove-button-${theme}`}
                            onClick={() => onFileRemove(theme)}
                        >
                            <RemoveIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </div>
            ) : undefined,
        [ classes, darkLogoUrl, lightLogoUrl, themeId, apolloTheme, translate, onFileRemove ],
    );

    const buttons = useCallback(
        (theme) => (
            <Button
                key={`upload-button-${theme}`}
                className={classes.button}
                variant="outlined"
                disabled={showUpgrade}
                data-cy={`upload-update-button-${theme}`}
                onClick={() => onUploadClick(theme === 'light' ? lightInputRef : darkInputRef)}
            >
                {translate({
                    id: `CLIENT_LOGO_${
                        (theme === 'light' ? lightLogoUrl : darkLogoUrl) ? 'UPDATE' : 'UPLOAD'
                    }_${theme.toUpperCase()}`,
                })}
            </Button>
        ),
        [ classes.button, showUpgrade, translate, lightLogoUrl, darkLogoUrl, onUploadClick, lightInputRef, darkInputRef ],
    );

    return (
        <>
            <div className={classes.titleSection}>
                <Typography className={classes.title}>
                    {translate({ id: 'CLIENT_LOGO_TITLE' })}
                </Typography>
                {showUpgrade &&
                    <UiUpgradeChip
                        licenseType={AccountLicense.PRO}
                        style={{ marginLeft: '8px' }}
                        toolTipText={translate({ id: 'CLIENT_UPGRADE_TO_PRO_FEATURE' })} />}
            </div>
            <Typography className={classes.infoText}>
                {translate({ id: 'CLIENT_LOGO_INFO' })}
            </Typography>
            {error ? <Typography color="error">
                {translate({ id: error })}
            </Typography> : undefined}
            {THEMES.map((theme) => hiddenInputs(theme))}
            <div className={classes.flex}>
                {THEMES.map((theme) => imagePreview(theme))}
            </div>
            <div className={classes.flex}>
                {THEMES.map((theme) => buttons(theme))}
            </div>
        </>
    );
};

export default CobrandingComponent;
