import {
    useEffect,
    useState,
} from 'react';

import { TenantStatusConstants } from '../../component/tenants/TenantConstants';
import { ITenantService } from '../interfaces/tenant/tenant';
import { useLicensingLimitation } from './useLicensingLimitation';

export const useEnabledTenantOperations = (tenantData: ITenantService[], isAdmin: boolean) => {
    const [ canAddTenant, setCanAddTenant ] = useState(false);
    const [ canEnableTenant, setCanEnableTenant ] = useState(false);
    const { availableTenantsCount } = useLicensingLimitation();

    useEffect(() => {
        if (!isAdmin) {
            return;
        }
        if (process.buildConfigs.enableAddEnableWithoutLicenseCheck) {
            setCanEnableTenant(true);
            setCanAddTenant(true);
            return;
        }

        const disabledCount =
            tenantData.filter(
                service =>
                    service.serviceType === 'orchestrator' &&
                service.tenant?.status?.toUpperCase() === TenantStatusConstants.DISABLED,
            ).length ?? 0;
        setCanEnableTenant(availableTenantsCount ? availableTenantsCount > 0 : false);
        setCanAddTenant(availableTenantsCount ? availableTenantsCount - disabledCount > 0 : false);
    }, [ availableTenantsCount, tenantData, isAdmin ]);

    return {
        canAddTenant,
        canEnableTenant,
    };
};
