import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { useAuthContext } from '@experiences/util';
import LocalOfferOutlined from '@mui/icons-material/LocalOfferOutlined';
import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getPriceString } from '../helpers/EcommerceHelpers';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import {
    IDiscountBase,
    IProduct,
    IProductBase,
    IProductPrice,
    ITaxResponse,
    IUpcomingInvoiceResponse,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getUpcomingInvoice,
} from '../services/BillingService';
import EcommerceAmountDueToday from './EcommerceAmountDueToday';
import { EcommerceDiscountedPrice } from './EcommerceDiscountedPrice';
import EcommerceOrderSummaryFooter from './EcommerceOrderSummaryFooter';
import { EcommercePrice } from './EcommercePrice';

const useStyles = makeStyles(theme =>
    createStyles({
        summaryContainer: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.semantic.colorBackgroundLeftRail,
        },
        topContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            padding: '20px',
        },
        bottomContainer: {
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.semantic.colorBorderDeEmp,
            borderTopWidth: '1px',
            minHeight: '85px',
            padding: '15px 20px',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        title: {
            marginTop: '2px',
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        packRow: {
            marginBottom: '10px',
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        productRow: {
            display: 'flex',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        alignRight: { marginLeft: 'auto' },
        planType: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '20px',
            marginBottom: '3px',
            color: theme.palette.semantic.colorForeground,
        },
        infoText: { color: theme.palette.semantic.colorForegroundDeEmp },
        orderSummarySubtitle: {
            marginTop: '42px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        orderSummaryText: {
            color: theme.palette.semantic.colorForeground,
            marginBottom: '70px',
            marginLeft: '18px',
            marginRight: '18px',
        },
        centered: { alignSelf: 'center' },
        percentageOff: {
            color: theme.palette.semantic.colorSuccessText,
            whiteSpace: 'pre',
        },
        discountRow: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '9px',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'pre-wrap',
        },
        discountIcon: {
            fontSize: '16px',
            marginRight: '4px',
        },
    }),
);

export const EcommerceCheckoutOrderSummary: React.FC<{
    type: string;
    products: IProduct[];
    currency: string;
    price?: number;
    billingPeriod: string;
    tax?: ITaxResponse;
    taxLoading?: boolean;
    productPricesLoading?: boolean;
    isFirstSubscription: boolean;
    productPrices?: IProductPrice[];
    changes?: { productsToAdd: IProduct[]; productsToDecrease: IProduct[] };
    width?: string;
    discountedPrice?: number;
    discountDetails?: IDiscountBase;
}> = ({
    type,
    products,
    currency,
    price,
    billingPeriod,
    tax,
    taxLoading,
    productPricesLoading,
    isFirstSubscription,
    productPrices,
    changes,
    width,
    discountedPrice,
    discountDetails,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const { token } = useAuthContext();
    const currentAccountName = useSelector(accountLogicalName);
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);

    const emptyData = useMemo(() => {
        if (changes) {
            return changes.productsToAdd.length === 0 && changes.productsToDecrease.length === 0;
        }
        return false;
    }, [ changes ]);

    const upcomingInvoiceProducts = useMemo(() => {
        if (changes?.productsToAdd.length && products?.length) {
            return products.map(productToAdd => {
                return {
                    code: productToAdd.code,
                    quantity: productToAdd.quantity,
                } as IProductBase;
            });
        }
        return [];
    }, [ changes?.productsToAdd, products ]);

    const { data: upcomingInvoice } = useSWR<IUpcomingInvoiceResponse, Error>(
        upcomingInvoiceProducts.length ? [ { products: upcomingInvoiceProducts }, currentAccountName, token, `${billingUrl}/upcomingInvoice` ] : undefined,
        getUpcomingInvoice,
    );

    const getProductPrice = useCallback((product, allProductPrices: IProductPrice[]) => {
        const productPrice = allProductPrices.find(p => p.code === product.code)?.price ?? 0;
        return (product.quantity * productPrice);
    }, []);

    const subscriptionPrice = useMemo(() => {
        if (price) {
            return price;
        }
        let priceTotal = 0;
        if (productPrices) {
            products.forEach(product => {
                priceTotal += getProductPrice(product, productPrices);
            });
        }
        return priceTotal;
    }, [ price, products, productPrices, getProductPrice ]);

    const shouldDisplayDiscount = useMemo(() => {
        return !!(EnableEcommercePromotionalDiscounts && discountedPrice && discountDetails);
    }, [ EnableEcommercePromotionalDiscounts, discountedPrice, discountDetails ]);

    const subscriptionPriceToDisplay = useMemo(() => {
        return shouldDisplayDiscount ? discountedPrice! : subscriptionPrice;
    }, [ shouldDisplayDiscount, discountedPrice, subscriptionPrice ]);

    const getPlanPrice = useCallback(() => {
        return shouldDisplayDiscount ? (
            <EcommerceDiscountedPrice
                fullPrice={subscriptionPrice}
                discountedPrice={discountedPrice}
                discountDetails={discountDetails}
                billingPeriod={billingPeriod}
                loading={productPricesLoading}
                currency={currency}
                dataCy="subscription-full-price"
            />
        ) : (
            <EcommercePrice
                value={subscriptionPrice}
                loading={productPricesLoading}
                gap={true}
                billingPeriod={billingPeriod}
                currency={currency}
                dataCy="subscription-price"
            />
        );
    }, [ shouldDisplayDiscount, subscriptionPrice, discountedPrice, discountDetails, billingPeriod, productPricesLoading, currency ]);

    return (
        <div
            className={classes.summaryContainer}
            style={{ width: width ?? '430px' }}
            data-cy="summary-container">
            <div className={classes.topContainer}>
                <Typography className={classes.title}>
                    {translate({ id: 'CLIENT_ORDER_SUMMARY' })}
                </Typography>
                {emptyData ? (
                    <>
                        <Typography className={clsx(classes.orderSummarySubtitle, classes.centered)}>
                            {translate({ id: `CLIENT_THERES_NO_CHANGES_IN_YOUR_PLAN` })}
                        </Typography>
                        <Typography className={clsx(classes.orderSummaryText, classes.centered)}>
                            {translate({ id: `CLIENT_CHANGE_THE_LICENSE_QUANTITY` })}
                        </Typography>
                    </>
                ) : (<div>
                    <div className={classes.row}>
                        <Typography className={classes.packRow}>
                            {translate({ id: `CLIENT_SKU_PACKAGE_PACK_${type}` })}
                        </Typography>
                        {getPlanPrice()}
                    </div>
                    {products.filter(p => p.quantity > 0).map((product, index) => (
                        <div
                            className={classes.productRow}
                            key={product.code}>
                            <Typography
                                key={index}
                                data-cy={`monthly-product-name-${product.code}`}
                            >
                                {`${
                                    product.quantity > 0 ? `${product.quantity} ` : ''
                                }${translate({ id: `CLIENT_PRODUCT_${product.code}` })}`}
                            </Typography>
                            {productPrices && (
                                <Typography
                                    className={classes.alignRight}
                                    data-cy={`monthly-product-price-${product.code}`}
                                >
                                    {getPriceString(getProductPrice(product, productPrices), currency, language)}
                                    {' '}
                          /
                                    {' '}
                                    {translate({ id: `CLIENT_PER_${billingPeriod.toUpperCase()}` })}
                                </Typography>
                            )}
                        </div>
                    ))}
                    {isFirstSubscription && (
                        <>
                            <Typography className={classes.planType}>
                                {translate({ id: 'CLIENT_PLAN_TYPE' })}
                            </Typography>
                            <Typography className={classes.productRow}>
                                {translate({ id: 'CLIENT_MONTHLY_PLAN' })}
                            </Typography>
                        </>
                    )}
                    {shouldDisplayDiscount && (
                        <Typography
                            className={classes.discountRow}
                            data-cy="discount-message">
                            <LocalOfferOutlined className={classes.discountIcon} />
                            <FormattedMessage
                                id="CLIENT_ORDER_SUMMARY_DISCOUNT"
                                values={{
                                    green: (chunk: string) => <span className={classes.percentageOff}>
                                        {chunk}
                                    </span>,
                                    percentageOff: discountDetails?.percentageOff,
                                    durationInMonths: discountDetails?.durationInMonths,
                                }}
                            />
                        </Typography>
                    )}
                </div>)}
            </div>
            { changes && changes.productsToAdd.length > 0 && (
                <div className={classes.bottomContainer} >
                    <EcommerceAmountDueToday
                        productPrices={productPrices}
                        currency={currency}
                        productsToAdd={changes?.productsToAdd}
                        upcomingInvoiceProductPrices={upcomingInvoice?.productPrices}
                        price={price}
                    />
                </div>
            )}
            {!emptyData && isFirstSubscription && (
                <div className={classes.bottomContainer}>
                    <EcommerceOrderSummaryFooter
                        tax={tax}
                        subscriptionPriceToDisplay={subscriptionPriceToDisplay}
                        productPricesLoading={productPricesLoading}
                        taxLoading={taxLoading}
                        billingPeriod={billingPeriod}
                        currency={currency}
                    />
                </div>
            )}
        </div>
    );
};
