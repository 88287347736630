import { IUiLinkMap } from '@experiences/interfaces';

export const DataRegionLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/overview-guide/docs/data-residency-cloud' ],
    [ 'zh-CN', 'https://docs.uipath.com/overview-guide/lang-zh_CN/docs/data-residency-cloud' ],
    [ 'zh-TW', 'https://docs.uipath.com/overview-guide/lang-zh_CN/docs/data-residency-cloud' ],
    [ 'fr', 'https://docs.uipath.com/overview-guide/lang-fr/docs/data-residency-cloud' ],
    [ 'de', 'https://docs.uipath.com/overview-guide/lang-de/docs/data-residency-cloud' ],
    [ 'ja', 'https://docs.uipath.com/overview-guide/lang-ja/docs/data-residency-cloud' ],
    [ 'es', 'https://docs.uipath.com/overview-guide/lang-es/docs/data-residency-cloud' ],
    [ 'pt-BR', 'https://docs.uipath.com/overview-guide/lang-pt_BR/docs/data-residency-cloud' ],
]);
