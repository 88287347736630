import { IUiDialogHookParams } from '@experiences/interfaces';
import React from 'react';

export const UiDialogContext = React.createContext<{
    createDialog: (parameters: IUiDialogHookParams) => Promise<boolean>;
}>({ createDialog: () => Promise.resolve(false) });

export const useShowDialog = () => {
    const { createDialog } = React.useContext(UiDialogContext);
    return createDialog;
};

