import {
    getPublishersWithGroupedTopics,
    isPublisherModeIndeterminate,
    isPublisherModeSubscribed,
    isTopicGroupModeIndeterminate,
    isTopicGroupModeSubscribed,
} from '../helpers/topicsGrouper';
import {
    INotificationMode,
    INotificationPublisherDto,
    INotificationSettingsAction,
    INotificationSettingsActionRestoreDefaultData,
    INotificationSettingsActionUpdateModeData,
    INotificationSettingsActionUpdateTabIndexData,
    INotificationSettingsActionUpdateTopicData,
    INotificationSettingsReducer,
    INotificationTopic,
} from '../interfaces/notificationSettings';
import { ActionTypes as ACTION_TYPE } from './actionTypes';

export const initialNotificationSettingsReducerState = {
    tabIndex: 0,
    publishers: [],
    publishersWithGroupedTopics: [],
} as INotificationSettingsReducer;

export const notificationSettingsReducer = (state: INotificationSettingsReducer, action: INotificationSettingsAction): INotificationSettingsReducer => {
    switch (action.type) {
        case ACTION_TYPE.NS_INITIALIZE_NOTIFICATION_SETTINGS: {
            const publisherData = action.data as INotificationPublisherDto[];
            return {
                ...state,
                publishers: publisherData,
                publishersWithGroupedTopics: getPublishersWithGroupedTopics(publisherData),
                tabIndex: state.tabIndex >= publisherData.length ? 0 : state.tabIndex,
            };
        }
        case ACTION_TYPE.NS_SET_TAB_INDEX: {
            const { tabIndex } = action.data as INotificationSettingsActionUpdateTabIndexData;
            return {
                ...state,
                tabIndex,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION: {
            return toggleTopicSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;

            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];
            const topic = topicGroup.topics[topicIndex!];
            topic.isSubscribed = isSubscribed!;
            topic.isUpdating = false;

            const topicMode = topic.modes?.find((m: INotificationMode) => m.name === mode);
            if (topicMode) {
                topicMode.isSubscribed = isSubscribed!;
                topicMode.isUpdating = false;
            }

            const topicGroupMode = topicGroup.modes?.find((m: INotificationMode) => m.name === mode);
            if (topicGroupMode) {
                topicGroupMode.isSubscribed = isTopicGroupModeSubscribed(mode!, topicGroup);
                topicGroupMode.isIndeterminate = isTopicGroupModeIndeterminate(mode!, topicGroup);
            }

            const publisherMode = publisher.modes?.find((m: INotificationMode) => m.name === mode);
            if (publisherMode) {
                publisherMode.isSubscribed = isPublisherModeSubscribed(mode!, publisher);
                publisherMode.isIndeterminate = isPublisherModeIndeterminate(mode!, publisher);
            }

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];

            topicGroup.topics.forEach((topic: INotificationTopic) => {
                topic.isSubscribed = isSubscribed!;
                const topicMode = topic.modes?.find(m => m.name === mode);
                if (topicMode) {
                    topicMode.isSubscribed = isSubscribed!;
                }
            });

            topicGroup.isUpdating = false;
            const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
            if (topicGroupMode) {
                topicGroupMode.isUpdating = false;
                topicGroupMode.isSubscribed = isSubscribed!;
                topicGroupMode.isIndeterminate = false;
            }

            const publisherMode = publisher.modes?.find((m: INotificationMode) => m.name === mode);
            if (publisherMode) {
                publisherMode.isSubscribed = isPublisherModeSubscribed(mode!, publisher);
                publisherMode.isIndeterminate = isPublisherModeIndeterminate(mode!, publisher);
            }

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION: {
            return togglePublisherSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, mode, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            publisher.isUpdating = true;
            const publisherMode = publisher.modes.find(m => m.name === mode);
            publisherMode!.isUpdating = false;
            publisherMode!.isSubscribed = isSubscribed!;
            publisherMode!.isIndeterminate = false;

            publisher.topicGroups.forEach(topicGroup => {
                const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
                if (topicGroupMode) {
                    topicGroupMode.isSubscribed = isSubscribed!;
                    topicGroupMode.isIndeterminate = false;
                }

                topicGroup.topics.forEach((topic: INotificationTopic) => {
                    topic.isSubscribed = isSubscribed!;
                    const topicMode = topic.modes?.find(m => m.name === mode);
                    if (topicMode) {
                        topicMode.isSubscribed = isSubscribed!;
                    }
                });
            });

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED: {
            return togglePublisherSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE: {
            return toggleChannelModeUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATED: {
            const {
                publisherIndex, modeIndex, newModeStatus,
            } = action.data as INotificationSettingsActionUpdateModeData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

            updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isActive = newModeStatus!;
            updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isUpdating = false;

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED: {
            return toggleChannelModeUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS: {
            return toggleRestoreSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED: {
            const {
                publisherIndex, resetData,
            } = action.data as INotificationSettingsActionRestoreDefaultData;
            const updatedRawPublishers = state.publishers;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

            updatedRawPublishers[publisherIndex] = resetData![0];
            const newGroupedData = getPublishersWithGroupedTopics(updatedRawPublishers);
            updatedPublishersWithGroupedTopics[publisherIndex] = newGroupedData[publisherIndex];

            return {
                ...state,
                publishers: updatedRawPublishers,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED: {
            return toggleRestoreSubscriptionUpdate(action, state, false);
        }
        default: {
            return state;
        }
    }
};

function toggleTopicSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, topicIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;

    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const topic = updatedPublishersWithGroupedTopics[publisherIndex].topicGroups[groupIndex!].topics[topicIndex!];
    topic.isUpdating = isUpdating;
    const topicMode = topic.modes?.find((m: INotificationMode) => m.name === mode);

    if (topicMode) {
        topicMode.isUpdating = isUpdating;
    }
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleTopicGroupSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const topicGroup = updatedPublishersWithGroupedTopics[publisherIndex].topicGroups[groupIndex!];
    topicGroup.isUpdating = isUpdating;
    const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
    if (topicGroupMode) {
        topicGroupMode.isUpdating = isUpdating;
    }
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function togglePublisherSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
    publisher.isUpdating = isUpdating;
    publisher.modes.find(m => m.name === mode)!.isUpdating = isUpdating;
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleChannelModeUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, modeIndex,
    } = action.data as INotificationSettingsActionUpdateModeData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

    updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isUpdating = isUpdating;

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleRestoreSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const { publisherIndex } = action.data as INotificationSettingsActionRestoreDefaultData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

    updatedPublishersWithGroupedTopics[publisherIndex].modes.forEach((mode) => {
        mode.isUpdating = isUpdating;
    });
    updatedPublishersWithGroupedTopics[publisherIndex].isUpdating = isUpdating;
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

