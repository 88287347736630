import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isHostModeSelector } from '../../store/selectors';
import { useIsSettingsRouteMatched } from '../../util/RouteUtil';
import useIsProfileRoute from './useIsProfileRoute';

const useProductName = () => {
    const isProfileRoute = useIsProfileRoute();
    const isHostMode = useSelector(isHostModeSelector);
    const isSettingsRoute = useIsSettingsRouteMatched();
    const showTrademark = useMemo(
        () => !isProfileRoute && !isSettingsRoute && !process.buildConfigs.forceLogoTrademark,
        [ isProfileRoute, isSettingsRoute ],
    );
    const productName = useMemo<string>(
        () => {
            if (isProfileRoute) {
                return '';
            }
            return (isHostMode && `${process.buildConfigs.productName} | Host`) ||
                (isSettingsRoute && 'Administration') ||
                process.buildConfigs.productName;
        },
        [ isHostMode, isProfileRoute, isSettingsRoute ],
    );

    return {
        productName,
        showTrademark,
    };
};

export default useProductName;
