import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';

export const servicesToHide = [
    'bupproxyservice',
    'hypervisor',
    'elements',
    'provisioning',
    'connectorbuilder',
    'resourcecatalog',
    'messagebus',
    'insightslogex',
    'aimetering',
];

export const useServiceDependency = () => {
    const ShowServerllessControlPlane = useFeatureFlagValue(Features.ShowServerllessControlPlane.name);

    if (!ShowServerllessControlPlane) {
        servicesToHide.push('serverlesscontrolplane');
    }

    if (process.buildConfigs.hideServiceDU) {
        servicesToHide.push('du');
    }
    return { servicesToHide: servicesToHide };
};
