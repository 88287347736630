import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useEcommerce } from '../helpers/useEcommerce';
import { ISubscriptionForm } from '../interfaces/ecommerce';
import EcommerceAddressLine1 from './EcommerceAddressLine1';
import EcommerceAddressLine2 from './EcommerceAddressLine2';
import EcommerceCountryAndCurrency from './EcommerceCountryAndCurrency';
import EcommerceStateAndCity from './EcommerceStateAndCity';
import EcommerceTaxId from './EcommerceTaxId';
import EcommerceZipCode from './EcommerceZipCode';

const useStyles = makeStyles((theme) =>
    createStyles({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '32px 24px',
            width: '482px',
            marginBottom: '24px',
        },
        sectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
        spacing: { marginBottom: '16px' },
        label: {
            fontSize: '15px',
            fontWeight: 600,
            marginBottom: '8px',
        },
    }),
);

const EcommerceCheckoutBillingAddress: React.FC<{
    currencies: string[];
    disabled: boolean;
    countryChangeHandler: any;
}> = ({
    currencies, disabled, countryChangeHandler,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        setEcommerceCurrencyValue,
        setEcommerceTaxIdValue,
        countryCode,
    } = useEcommerce();

    const {
        watch, errors, trigger,
    } =
    useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        taxId: currentTaxId,
        currency: currentCurrency,
    } = watch();

    useEffect(() => {
        if (countryCode && currentCountry !== countryCode && !disabled) {
            countryChangeHandler(currentCountry);
        }
    }, [ countryCode, currentCountry, disabled, countryChangeHandler ]);

    useEffect(() => {
        if (currentCurrency) {
            setEcommerceCurrencyValue(currentCurrency);
        }
    }, [ setEcommerceCurrencyValue, currentCurrency, errors.currency ]);

    const [ debouncedTaxId ] = useDebounce(currentTaxId, 1000);
    useEffect(() => {
        if (debouncedTaxId) {
            setEcommerceTaxIdValue(debouncedTaxId);
        }
    }, [ setEcommerceTaxIdValue, debouncedTaxId, errors.taxId ]);

    const {
        addressLine1, city, state, zipcode,
    } = watch([
        'addressLine1',
        'city',
        'state',
        'zipcode',
    ]);
    useEffect(() => {
        if (
            (addressLine1 && errors.addressLine1?.type === 'addressError') ||
      (city && errors.city?.type === 'addressError') ||
      (state && errors.state?.type === 'addressError') ||
      (zipcode && errors.zipcode?.type === 'addressError')
        ) {
            trigger([ 'addressLine1', 'city', 'state', 'zipcode' ]);
        }
    }, [ addressLine1, city, state, zipcode, errors, trigger ]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.row}>
                <Typography className={classes.sectionTitle}>
                    {translate({ id: 'CLIENT_BILLING_ADDRESS' })}
                </Typography>
            </div>
            <EcommerceCountryAndCurrency
                disabled={disabled}
                currencies={currencies}
            />
            {currentCountry === 'JP' ? (
                <>
                    <div className={classes.row}>
                        <EcommerceZipCode
                            disabled={disabled}
                        />
                    </div>

                    <EcommerceStateAndCity
                        showCityFirst={false}
                        disabled={disabled}
                    />
                    <EcommerceAddressLine1
                        disabled={disabled}
                    />
                    <EcommerceAddressLine2
                        disabled={disabled}
                    />
                </>
            ) : (
                <>
                    <EcommerceAddressLine1
                        disabled={disabled}
                    />
                    <EcommerceAddressLine2
                        disabled={disabled}
                    />
                    <EcommerceStateAndCity
                        showCityFirst={true}
                        disabled={disabled}
                    />
                    {(currentCountry === 'US' || currentCountry === 'CA' || currentCountry === 'JP') ? (
                        <EcommerceZipCode
                            disabled={disabled}
                        />
                    ) : (
                        <EcommerceTaxId
                            disabled={disabled}
                        />

                    )}
                </>
            )}
        </div>
    );
};

export default EcommerceCheckoutBillingAddress;
