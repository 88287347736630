import { UiSelect } from '@experiences/ui-common';
import { useAuthContext } from '@experiences/util';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    useEcommerceEnabledCountries,
    useIsSignupDirectBuyFlow,
} from '../helpers/EcommerceHelpers';
import {
    accountLogicalName,
    profile,
} from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import { ISubscriptionForm } from '../interfaces/ecommerce';
import { getFixedCurrencyIfPresent } from '../services/BillingService';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
    }),
);

const EcommerceCountryAndCurrency: React.FC<{
    currencies: string[];
    disabled: boolean;
}> = ({
    currencies, disabled,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { filteredCountryCodeCountries: countryCodeCountries } = useEcommerceEnabledCountries();
    const {
        control, errors,
    } =
    useFormContext<ISubscriptionForm>();

    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const profileState = useSelector(profile);
    const { countryCode } = useEcommerce();

    const defaultCountryCode = useMemo(() => {
        if (isSignupDirectBuyFlow) {
            return countryCode;
        }
        return profileState?.accountUserDto?.country;
    }, [
        profileState?.accountUserDto?.country,
        isSignupDirectBuyFlow,
        countryCode,
    ]);
    const { token } = useAuthContext();
    const { setValue } = useFormContext<ISubscriptionForm>();

    const currentAccountName = useSelector(accountLogicalName);
    const [ fixedCurrency, setFixedCurrency ] = useState(false);
    useEffect(() => {
        (async () => {
            if (countryCode && countryCode !== '' && token) {
                if (isSignupDirectBuyFlow) {
                    setFixedCurrency(false);
                    setValue('currency', 'USD');
                } else {
                    const currency = await getFixedCurrencyIfPresent(
                        countryCode,
                        currentAccountName,
                        token,
                    );
                    if (currency) {
                        setFixedCurrency(true);
                        setValue('currency', currency);
                    }
                }
            }
        })();
    }, [
        countryCode,
        currentAccountName,
        setValue,
        token,
        setFixedCurrency,
        isSignupDirectBuyFlow,
    ]);

    return (
        <div className={classes.row}>
            <UiSelect
                style={{ marginRight: '12px' }}
                control={control}
                name="country"
                isTranslated
                inputLabel={translate({ id: 'CLIENT_FORM_COUNTRY' })}
                options={countryCodeCountries}
                defaultValue={defaultCountryCode}
                required
                fullWidth
                dataCy="ecommerce-form-country"
                error={!!errors.country}
                disabled={disabled}
                helperText={
                    errors.country?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
            />
            <UiSelect
                control={control}
                name="currency"
                inputLabel={translate({ id: 'CLIENT_CURRENCY' })}
                options={currencies}
                disabled={currencies.length === 1 || disabled || fixedCurrency}
                required
                fullWidth
                dataCy="ecommerce-form-currency"
                error={!!errors.country}
                helperText={
                    errors.country?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
            />
        </div>
    );
};

export default EcommerceCountryAndCurrency;
