import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
    Button,
    Tooltip,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Row } from 'react-table';

import { notificationType } from '../../../common/constants/Constant';
import { IGroup } from '../../../common/interfaces/cis/group';
import {
    getUserAllocationsForGroup,
    releaseUserLicensesFromGroup,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import { EnableUserLicensingSelector } from '../../../store/selectors';
import { isStringEmpty } from '../../../util/TypeUtil';
import { UiDrawer } from '../../common/UiDrawer';
import { UiPanelWithActions } from '../../common/UiForm';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import { UiPaginatedGrid } from '../../common/UiPaginatedGrid';
import {
    IUserAllocationsInGroup,
    IUserAllocationsInGroupResponse,
} from '../../users/interfaces/userLicense';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {},
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        secondaryButton: { marginLeft: '8px' },
        cellText: {
            fontSize: '14px',
            fontWeight: 400,
            color: theme.palette.semantic.colorForeground,
            lineHeight: '20px',
            style: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

const ViewUserAllocationsInGroupComponent: React.FC<{
    location?: { state?: { group: IGroup; previousLocation: string } };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();

    const history = useHistory();
    const [ group, previousLocation ] = useMemo(() => [ location?.state?.group!, location?.state?.previousLocation! ], [ location ]);

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const [ refresh, setRefresh ] = useState(false);

    const {
        open, close,
    } = useModalState(previousLocation);

    const userAllocationsInGroupUrl = useMemo(() => `${userLicenseUrl}/group/${group.id}/allocations`, [ group ]);
    useEffect(() => {
        if (!EnableUserLicensing || !group) {
            console.warn('Feature not enabled or missing data. Closing...');
            history.push('/invalidurl');
        }
    }, [ EnableUserLicensing, group, history ]);

    const getUserLicenseNames = useCallback(
        (data: string[]) => {
            return data.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');
        },
        [ translate ],
    );

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const releaseUserLicensesAsync = useCallback(
        async (userAllocation: IUserAllocationsInGroup) => {
            try {
                await releaseUserLicensesFromGroup(group.id, userAllocation.id);
                createNotification(translate({ id: 'CLIENT_USER_LICENSE_RELEASED' }));
                setRefresh(true);
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        },
        [ createNotification, translate, setErrorMessage, group, getErrorMessage ],
    );

    const openReleaseDialog = useCallback(
        async (row: Row<IUserAllocationsInGroup>) => {
            const userAllocation = row.original;

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_RELEASE_USER_LICENSE' }),
                body: (
                    <>
                        <p>
                            {translate(
                                { id: 'CLIENT_RELEASE_USER_LICENSE_DETAILS_P1' },
                                { 0: `${userAllocation.name} ${userAllocation.surname ?? ''}` },
                            )}
                        </p>
                        <p>
                            {translate({ id: 'CLIENT_RELEASE_USER_LICENSE_DETAILS_P2' })}
                        </p>
                        <p>
                            {translate({ id: 'CLIENT_RELEASE_USER_LICENSE_DETAILS_P3' })}
                        </p>
                    </>
                ),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_YES' }),
            });
            if (proceed) {
                releaseUserLicensesAsync(userAllocation);
            }
        },
        [ translate, createDialog, releaseUserLicensesAsync ],
    );
    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_GROUPS_VIEW_GROUP_ALLOCATION_RULE' }, { 0: group?.name })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="large"
        >
            <UiPanelWithActions
                actions={
                    <div className={classes.actions}>
                        <Button
                            variant="outlined"
                            className={clsx(classes.secondaryButton)}
                            onClick={() => close()}
                            data-cy="view-user-allocations-close-button"
                        >
                            {translate({ id: 'CLIENT_CLOSE' })}
                        </Button>
                    </div>
                }
            >
                <UiPaginatedGrid<IUserAllocationsInGroup, IUserAllocationsInGroupResponse>
                    url={userAllocationsInGroupUrl}
                    dataCy="user-allocations-in-group-ui-grid"
                    pagination={true}
                    fetcher={getUserAllocationsForGroup}
                    fetcherArgs={[ group.id ]}
                    search={false}
                    hideRefresh={true}
                    refreshData={{
                        current: refresh,
                        set: setRefresh,
                    }}
                    tableHeight="100%"
                    columns={[
                        {
                            accessor: 'email',
                            Header: translate({ id: 'CLIENT_EMAIL' }),
                            Cell: ({ row }) => (
                                <Tooltip title={row.original.orphan || isStringEmpty(row.original.email) ? translate({ id: 'CLIENT_NOT_APPLICABLE' }) : row.original.email}>
                                    <span className={classes.cellText}>
                                        {row.original.orphan || isStringEmpty(row.original.email) ? translate({ id: 'CLIENT_NOT_APPLICABLE' }) : row.original.email}
                                    </span>
                                </Tooltip>
                            ),
                            width: 35,
                            disableSortBy: true,
                        },
                        {
                            accessor: 'allocatedUserBundles',
                            Header: translate({ id: 'CLIENT_LICENSES' }),
                            Cell: ({ row }) => (
                                <Tooltip title={getUserLicenseNames(row.original.userBundleLicenses)}>
                                    <span className={classes.cellText}>
                                        {getUserLicenseNames(row.original.userBundleLicenses)}
                                    </span>
                                </Tooltip>
                            ),
                            width: 40,
                            disableSortBy: true,
                        },
                        {
                            accessor: 'lastInUse',
                            sortName: 'UpdatedAt',
                            Header: <Tooltip title={translate({ id: 'CLIENT_LAST_IN_USE' })}>
                                <span>
                                    {translate({ id: 'CLIENT_LAST_IN_USE' })}
                                </span>
                            </Tooltip>,
                            Cell: ({ row }) => (
                                <FormattedDate
                                    value={row.original.lastInUse}
                                    year="numeric"
                                    month="short"
                                    day="numeric" />
                            ),
                            width: 25,
                        },
                    ]}
                    hiddenColumns={[ 'id' ]}
                    initialSort={[
                        {
                            id: 'UpdatedAt',
                            desc: false,
                        },
                    ]}
                    rowActions={[
                        {
                            type: ButtonType.Icon,
                            label: translate({ id: 'CLIENT_DELETE' }),
                            tooltip: translate({ id: 'CLIENT_RELEASE_LICENSE' }),
                            actionType: GridActionType.Row,
                            icon: <DeleteOutlineOutlinedIcon data-cy="ui-grid-release-user-licenses" />,
                            click: openReleaseDialog,
                        },
                    ]}
                />
            </UiPanelWithActions>
        </UiDrawer>
    );
};

export default ViewUserAllocationsInGroupComponent;
