import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    RouteConfig,
    RouteConfigComponentProps,
} from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import HomePageComponent from '../../component/home/HomePageComponent';
import ProfilePageComponent from '../../component/profile/ProfilePageComponent';
import CreatePersonalAccessTokenDrawerComponent from '../../component/profile/subcomponents/CreatePersonalAccessTokenDrawerComponent';
import ResourceCenter from '../../component/resourceCenter/ResourceCenter.k8s';
import ResetPasswordComponent from '../../component/users/subcomponents/ResetPasswordComponent';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';
import useHostPrivateShellRoutes from './useHostPrivateShellRoutes.default';
import useOrgAdminPrivateShellRoutes from './useOrgAdminPrivateShellRoutes.default';

const usePrivateShellRoutes = () => {
    const EnableNotificationSettings = useFeatureFlagValue(Features.EnableNotificationSettings.name);
    const EnablePrivacyAndSecuritySettings = useFeatureFlagValue(Features.EnablePrivacyAndSecuritySettings.name);
    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);

    const isAdmin = useSelector(isAdminSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const orgAdminPrivateShellRoutes = useOrgAdminPrivateShellRoutes();
    const hostPrivateShellRoutes = useHostPrivateShellRoutes();

    return useMemo<RouteConfig[]>(() => [
        ...(isAdmin && !isHostMode ? orgAdminPrivateShellRoutes : []),
        ...(isHostMode
            ? hostPrivateShellRoutes
            : [
                {
                    path: RouteNames.Home,
                    exact: true,
                    component: HomePageComponent,
                },
                {
                    path: RouteNames.ResourceCenter,
                    exact: true,
                    component: ResourceCenter,
                },
            ]),
        {
            path: RouteNames.Profile,
            component: ProfilePageComponent,
        },
        {
            path: RouteNames.PrivacyAndSecuritySettings,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnablePrivacyAndSecuritySettings, <ProfilePageComponent {...props} />),
            routes: [
                {
                    path: RouteNames.ChangePassword,
                    component: ResetPasswordComponent,
                },
            ],
        },
        {
            path: RouteNames.NotificationSettings,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnableNotificationSettings && !isHostMode, <ProfilePageComponent {...props} />),
        },
        {
            path: RouteNames.PersonalAccessToken,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnableReferenceTokens && !isHostMode, <ProfilePageComponent {...props} />),
            routes: [
                {
                    path: RouteNames.AddPersonalAccessToken,
                    component: CreatePersonalAccessTokenDrawerComponent,
                },
            ],
        },
        {
            path: '*',
            component: InvalidUrl,
        },
    ], [
        EnableNotificationSettings,
        EnablePrivacyAndSecuritySettings,
        EnableReferenceTokens,
        hostPrivateShellRoutes,
        isAdmin,
        isHostMode,
        orgAdminPrivateShellRoutes,
    ]);
};

export default usePrivateShellRoutes;
