import { CircularProgress } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
        },
    }),
);

export const TestConnection: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');

            if (code) {
                localStorage.setItem('AAD_code', code);

                window.close();
            }
        })();
    }, [ location.search ]);

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};
