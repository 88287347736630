import { ErrorOption } from 'react-hook-form';

import {
    IIPRestriction,
    IPRestrictionFormData,
} from '../../../../common/interfaces/iprestriction';

const Address6 = require('ip-address').Address6;
const Address4 = require('ip-address').Address4;

export const validateIPv4Range = (rangeStart: string, rangeEnd: string) => {
    if (!Address4.isValid(rangeStart) || !Address4.isValid(rangeEnd)) {
        return false;
    }
    const start = rangeStart.split('.');
    const end = rangeEnd.split('.');
    const [ s1, s2, s3, s4 ] = start.map(i => parseInt(i));
    const [ e1, e2, e3, e4 ] = end.map(i => parseInt(i));
    return (
        ([ s1, s2, s3, s4 ].some(o => o > 255 || o < 0) ||
        [ e1, e2, e3, e4 ].some(o => o > 255 || o < 0)) ?
            false : // range contains invalid IP
            (s1 > e1 || s2 > e2 || s3 > e3 || s4 > e4) ?
                false : // range start is greater than range end
                true // range is valid
    );
};

export const validateIPv6Range = (rangeStart: string, rangeEnd: string) => {
    if (!Address6.isValid(rangeStart) || !Address6.isValid(rangeEnd)) {
        return false;
    }
    const startv6 = new Address6(rangeStart);
    const endv6 = new Address6(rangeEnd);
    let startIp = startv6.canonicalForm();
    let endIp = endv6.canonicalForm();
    startIp = startIp.replace(/:/g, '').toLowerCase();
    endIp = endIp.replace(/:/g, '').toLowerCase();
    return startIp <= endIp;
};

export const checkIPv4InRange = (currentIp: string, rangeStart: string, rangeEnd: string) => {
    if (!Address4.isValid(currentIp) || !Address4.isValid(rangeStart) || !Address4.isValid(rangeEnd)) {
        return false;
    }
    const current = currentIp.split('.');
    const start = rangeStart.split('.');
    const end = rangeEnd.split('.');
    const [ c1, c2, c3, c4 ] = current.map(i => parseInt(i));
    const [ s1, s2, s3, s4 ] = start.map(i => parseInt(i));
    const [ e1, e2, e3, e4 ] = end.map(i => parseInt(i));
    return (
        s1 <= c1 && c1 <= e1 &&
        s2 <= c2 && c2 <= e2 &&
        s3 <= c3 && c3 <= e3 &&
        s4 <= c4 && c4 <= e4
    );
};

export const checkIPv6InRange = (currentIp: string, rangeStart: string, rangeEnd: string) => {
    if (!Address6.isValid(currentIp) || !Address6.isValid(rangeStart) || !Address6.isValid(rangeEnd)) {
        return false;
    }
    const currentv6 = new Address6(currentIp);
    const startv6 = new Address6(rangeStart);
    const endv6 = new Address6(rangeEnd);
    let current = currentv6.canonicalForm();
    let startIp = startv6.canonicalForm();
    let endIp = endv6.canonicalForm();
    current = current.replace(/:/g, '').toLowerCase();
    startIp = startIp.replace(/:/g, '').toLowerCase();
    endIp = endIp.replace(/:/g, '').toLowerCase();
    return startIp <= current && current <= endIp;
};

export const checkInIpRangeList = (currentIp: string, excludeIpRange: IIPRestriction, ipRanges: IIPRestriction[]) => {
    for (const obj of ipRanges) {
        if (excludeIpRange.id === obj.id) {
            continue;
        }
        if (checkIPv4InRange(currentIp, obj.startIp, obj.endIp) || checkIPv6InRange(currentIp, obj.startIp, obj.endIp)) {

            return true;
        }
    }
    return false;
};

export const validateIP = (
    rangeStart: string,
    rangeEnd: string,
    setError: (name: keyof IPRestrictionFormData, error: ErrorOption
    ) => void) => {

    if (Address4.isValid(rangeStart) && Address4.isValid(rangeEnd)) {
        if (validateIPv4Range(rangeStart, rangeEnd)) {
            return true;
        }
        setError('EndIp', { type: 'invalidRange' });
    }
    if (Address6.isValid(rangeStart) && Address6.isValid(rangeEnd)) {
        if (validateIPv6Range(rangeStart, rangeEnd)) {
            return true;
        }
        setError('EndIp', { type: 'invalidRange' });
    }
    if (!Address4.isValid(rangeStart) && !Address6.isValid(rangeStart)) {
        setError('StartIp', { type: 'invalid' });
    }
    if (!Address4.isValid(rangeEnd) && !Address6.isValid(rangeEnd)) {
        setError('EndIp', { type: 'invalid' });
    }
    if ((Address4.isValid(rangeStart) && Address6.isValid(rangeEnd)) || (Address6.isValid(rangeStart) && Address4.isValid(rangeEnd))) {
        setError('EndIp', { type: 'invalidRange' });
    }
    return false;
};
