import { ILAUsage } from '@experiences/interfaces';
import { CancelTokenSource } from 'axios';

import store from '../../store/index';
import { post } from '../utility/Requests.default';

export const licenseUsageUrl = '/api/usage';

export function getLicenseUsageTimeline(
    clientIds: string[],
    timePeriod: number,
    usageAggregationType: string,
    source?: CancelTokenSource,
) {
    const accountGlobalId = store.getState().profile.accountUserDto.accountGlobalId.toLowerCase();
    return post<ILAUsage[]>(`${licenseUsageUrl}/getTimelineForAccount`, {
        body: {
            accountGlobalId,
            clientIds,
            timePeriod,
            usageAggregationType,
        },
        cancelTokenSource: source,
    });
}
