import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        divider: {
            width: '40%',
            background: '#ddd',
        },
        centerText: { width: '20%' },
        or: {
            display: 'flex',
            justifyContent: 'space-around',
        },
    }),
);

export const UiDividerWithText: React.FC<{
    text: string;
}> = ({ text }) => {
    const classes = useStyles();

    return (
        <div className={classes.dividerContainer}>
            <Divider className={classes.divider} />
            <div className={classes.centerText}>
                <Typography className={classes.or}>
                    {text}
                </Typography>
            </div>
            <Divider className={classes.divider} />
        </div>
    );
};
