import { UiProgressButton } from '@experiences/ui-common';
import {
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import {
    accountUrl,
    getClientAccessToken,
} from '../../../services/identity/AccountService';
import UiCopyButton from '../../common/UiCopyButton';

const useStyles = makeStyles(theme =>
    createStyles({
        root: { marginBottom: '24px' },
        installationKeyContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        installationKeyInput: { marginLeft: '16px' },
        generateNewButton: { color: theme.palette.semantic.colorPrimary },
    }),
);

const InstallationKeyComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const [ loading, setLoading ] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const {
        data: installationKey, error,
    } = useSWR<string, Error>(
        `${accountUrl}/ClientAccessToken`,
        getClientAccessToken,
    );

    const handleGenerateInstallationKey = useCallback(async () => {
        try {
            setLoading(true);
            await mutate(`${accountUrl}/ClientAccessToken`);
            enqueueSnackbar(translate({ id: 'CLIENT_GENERATE_INSTALLATION_KEY_SUCCESS' }), { variant: notificationType.SUCCESS } as any);
        } catch {
            enqueueSnackbar(translate({ id: 'CLIENT_GENERATE_INSTALLATION_KEY_FAILURE' }), { variant: notificationType.ERROR } as any);
        } finally {
            setLoading(false);
        }
    }, [ enqueueSnackbar, translate ]);

    return (
        <div className={classes.root}>
            <div className={classes.installationKeyContainer}>
                <Typography className={classes.label}>
                    {translate({ id: 'CLIENT_INSTALLATION_KEY_LABEL' })}
                </Typography>
                <TextField
                    data-cy="installation-key-field"
                    className={classes.installationKeyInput}
                    value={installationKey}
                    error={!!error}
                    helperText={error && translate({ id: 'CLIENT_GET_INSTALLATION_KEY_FAILURE' })}
                    InputProps={{
                        readOnly: true,
                        className: 'Tall',
                        endAdornment: (
                            <InputAdornment position="end">
                                <UiCopyButton
                                    disabled={!installationKey}
                                    textToCopy={installationKey ?? ''}
                                    aria-label={translate({ id: 'CLIENT_COPY_INSTALLATION_KEY' })}
                                    data-cy="installation-key-copy-button"
                                />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <UiProgressButton
                    data-cy="installation-key-generate-new-button"
                    loading={loading}
                    onClick={handleGenerateInstallationKey}
                    color="inherit"
                    classes={{ colorInherit: classes.generateNewButton }}
                >
                    {translate({ id: 'CLIENT_GENERATE_NEW' })}
                </UiProgressButton>
            </div>
            <Typography>
                {translate(
                    { id: 'CLIENT_INSTALLATION_KEY_DESCRIPTION' },
                    { titleProductName: process.buildConfigs.titleProductName },
                )}
            </Typography>
        </div>
    );
};

export default InstallationKeyComponent;
