import { Token } from '@experiences/interfaces';

export const hasSurname = (fullName?: string): boolean => {
    if (!fullName) {
        return false;
    }
    return fullName.split(' ').length > 1;
};

export const getFirstNameFromFullName = (fullName?: string): string | undefined => {
    if (fullName?.includes('@')) {
        return '';
    }
    return (hasSurname(fullName) ? fullName?.split(' ')[0] : fullName) || undefined;
};

export const getSurnameFromFullName = (fullName?: string): string | undefined => {
    if (fullName?.includes('@')) {
        return '';
    }
    return hasSurname(fullName) ? fullName?.split(' ').splice(1)
        .join(' ') : '';
};

export const fixName = (name: string): string => {
    return name.replace(/\s\s+/g, ' ').trim();
};

export const validateUsername = (username: string): boolean => {
    return !!username && !!username.trim();
};

export const getFullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
        return '';
    }
    if (!firstName) {
        return lastName;
    }
    if (!lastName) {
        return firstName;
    }
    return `${firstName} ${lastName}`;
};

export const getFirstNameFromToken = (claims: Token | undefined): string => {
    return (
        claims?.given_name ??
    claims?.['https://cloudrpa/userinfo']?.firstName ??
    getFirstNameFromFullName(claims?.name) ??
    ''
    );
};

export const getLastNameFromToken = (claims: Token | undefined): string => {
    return (
        claims?.family_name ?? claims?.['https://cloudrpa/userinfo']?.lastName ?? getSurnameFromFullName(claims?.name) ?? ''
    );
};
