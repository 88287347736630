import { useApolloTheme } from '@experiences/theme';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React from 'react';

import { BottomPageOptions } from './subcomponents/BottomPageOptions';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundPosition: '0 0',
            backgroundSize: '20px 20px',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            overflow: 'auto',
            flex: 1,
        },
        center: {
            alignItems: 'center',
            flexDirection: 'column',
        },
    }),
);

export const UiBackground: React.FC<{
    children?: React.ReactNode;
    center?: boolean;
    withBottomOptions?: boolean;
    isRedesign?: boolean;
    onlyLanguageBottomPage?: boolean;
}> = ({
    children, center, withBottomOptions = true, isRedesign = false, onlyLanguageBottomPage = false,
}) => {
    const classes = useStyles();
    const { themeId } = useApolloTheme();

    return (
        <div
            className={clsx(classes.root, center ? classes.center : '')}
            style={{
                backgroundImage: !isRedesign
                    ? themeId === 'light'
                        ? `radial-gradient(${Tokens.Colors.ColorGray200} 15%, ${Tokens.Colors.ColorGray100} 15%)`
                        : `radial-gradient(${Tokens.Colors.ColorInk650} 15%, ${Tokens.Colors.ColorInk700} 15%)`
                    : '',
                backgroundColor: isRedesign
                    ? themeId === 'light'
                        ? Tokens.Colors.ColorBlueSecondary100
                        : Tokens.Colors.ColorBackgroundDark
                    : '',
            }}
        >
            {children}
            {withBottomOptions && <BottomPageOptions hideLinksBottomPage={onlyLanguageBottomPage} />}
        </div>
    );
};
