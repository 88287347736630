import { UiProgressButton } from '@experiences/ui-common';
import { Button } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { NavCustomizationSetting } from '../../../../common/constants/NavCustomizationConstant';
import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import {
    getSetting,
    ISetting,
    saveSetting,
    settingUrl,
} from '../../../../services/identity/SettingService';
import { accountGlobalId } from '../../../../store/selectors';
import {
    mapNavCustomizationDataToKeyValuePairs,
    mapSettingArrayToNavCustomizationData,
} from '../../../../util/setting/NavCustomizationSettingUtil';
import UiForm from '../../../common/UiForm';
import NavCustomizationForm from './NavCustomizationForm';

const useStyles = makeStyles(() =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        cancelButton: { marginLeft: '10px' },
        form: {
            width: '518px',
            marginBottom: '16px',
        },
    }),
);

export interface INavCustomizationData {
    appsHidden: boolean;
    resourceCenterHidden: boolean;
}

export const defaultNavCustomizationData: INavCustomizationData = {
    appsHidden: false,
    resourceCenterHidden: false,
};

const NavCustomizationComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const methods = useForm<INavCustomizationData>({
        mode: 'onSubmit',
        defaultValues: defaultNavCustomizationData,
    });

    const {
        reset, handleSubmit, formState,
    } = methods;
    const {
        isDirty, isSubmitting,
    } = formState;

    const partitionGlobalId = useSelector(accountGlobalId);
    const keys = useMemo(() => [ NavCustomizationSetting.AppsHidden, NavCustomizationSetting.ResourceCenterHidden ], []);
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: INavCustomizationData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapNavCustomizationDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ createNotification, mutate, partitionGlobalId, translate ],
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToNavCustomizationData(fetchedSettings));
        }
    }, [ reset, fetchedSettings ]);

    return !isAdminRevampEnabled
        ? (
            <UiForm
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    !isAdminRevampEnabled && <div className={classes.actions}>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="nav-customization-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                        {isDirty && (
                            <Button
                                className={classes.cancelButton}
                                onClick={() => {
                                    handleCancel();
                                }}
                                color="primary"
                                data-cy="nav-customization-cancel-button"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                        )}
                    </div>
                }
                isDrawer
            >
                <FormProvider {...methods}>
                    <NavCustomizationForm />
                </FormProvider>
            </UiForm>
        )
        : (
            <NavCustomizationForm />
        )
    ;
};

export default NavCustomizationComponent;
