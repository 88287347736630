import {
    User,
    UserLoadedCallback,
} from 'oidc-client-ts';
import {
    useEffect,
    useState,
} from 'react';
import { useAuth } from 'react-oidc-context';

export type UserInfo = {
    idToken: string;
    token: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    username?: string;
    identifier: string;
};

function mapUserInfo(user?: User | null): UserInfo {
    if (!user) {
        return {} as UserInfo;
    }

    const {
        access_token,
        id_token,
        profile: {
            email, first_name, last_name, name, preferred_username, sub,
        },
    } = user;

    return {
        idToken: id_token!, // once tokens are removed from localstorage, remove this
        token: access_token,
        email,
        firstName: first_name as string,
        lastName: last_name as string,
        name,
        username: preferred_username,
        identifier: sub,
    };
}

export default function useUserInfo() {
    const {
        user: oidcUser, events,
    } = useAuth();

    const [ user, setUser ] = useState<UserInfo>({
        identifier: '',
        token: '',
        idToken: '',
    });

    useEffect(() => {
        const onUserLoad = (u: User | null | undefined) => setUser(mapUserInfo(u));
        onUserLoad(oidcUser);
        events.addUserLoaded(onUserLoad);

        return () => {
            events.removeUserLoaded(onUserLoad as UserLoadedCallback);
        };
    }, [ oidcUser ]);

    return user;
}
