import { useRouteResolver } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-table';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { ITenantAllocation } from '../../common/interfaces/tenant/tenant';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import { getTenantAllocations } from '../../services/licensing/LicenseService';
import { accountGlobalId } from '../../store/selectors';
import { UiGrid } from '../common/UiGrid';
import {
    ButtonType,
    GridActionType,
    UiColumn,
} from '../common/UiGrid/grid';

export const AllocationToTenants: React.FC = () => {
    const accountId = useSelector(accountGlobalId);
    const getRoute = useRouteResolver();
    const history = useHistory();

    const {
        data: tenantAllocations,
        isValidating,
        error,
    } = useSWR<ITenantAllocation[], Error>(`/api/licensing/tenantAllocations/${accountId}`, () =>
        getTenantAllocations(accountId),
    );

    useEffect(() => {
        history.listen(history => {
            if (history.state && (history.state as any)['refresh']) {
                mutate(`/api/licensing/tenantAllocations/${accountId}`);
            }
        });
    }, [ history, accountId ]);

    const isLicenseExpired = useLicenseExpired();
    const { formatMessage: translate } = useIntl();
    const columnsData: Array<UiColumn<ITenantAllocation>> = useMemo(() => {
        const columnsDataList: Array<UiColumn<ITenantAllocation>> = [];

        columnsDataList.push({
            accessor: 'tenant.name',
            sortName: 'Name',
            Header: translate({ id: 'CLIENT_TENANT' }),
            width: 20,
        });
        if (tenantAllocations != null && tenantAllocations.length > 0) {
            const productTypesAvailable = Object.keys(tenantAllocations[0]).filter(
                productKey =>
                    productKey !== 'services' &&
                    productKey !== 'tenant' &&
                    (!process.buildConfigs.unavailableProductNames?.includes(productKey) ?? true) &&
                    tenantAllocations.find(tenantAllocation => tenantAllocation[productKey] != null) != null,
            );
            productTypesAvailable.map(productType => {
                columnsDataList.push({
                    accessor: `${productType}`,
                    Header: translate({ id: `CLIENT_TENANT_ALLOCATION_${productType.toUpperCase()}` }),
                    Cell: ({ row }) => row.original[productType] ?? 0,
                    width: 20,
                    justifyContent: 'center',
                });
            });
        }

        return columnsDataList;
    }, [ tenantAllocations, translate ]);

    const clickedConfigureLicense = useCallback(
        (row: Row<ITenantAllocation>) => {
            history.push({
                pathname: `${getRoute(RouteNames.Licensing)}/configure/${row.original.tenant.id.toLowerCase()}`,
                state: {
                    tenant: row.original.tenant,
                    services: row.original.services,
                    previousLocation: getRoute(RouteNames.Licensing),
                },
            });
        },
        [ getRoute, history ],
    );

    return (
        <UiGrid<ITenantAllocation>
            dataCy="tenant-allocations-table"
            loading={isValidating}
            pagination={true}
            columns={columnsData}
            data={tenantAllocations ?? []}
            error={error}
            wrapHeader={true}
            rowActions={[
                {
                    type: ButtonType.Icon,
                    label: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    tooltip: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                    actionType: GridActionType.Row,
                    icon: <AllocateLicenseIcon />,
                    click: clickedConfigureLicense,
                    dataCy: 'data-allocate-licenses',
                    disable: () => isLicenseExpired,
                },
            ]}
        />
    );
};
