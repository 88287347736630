import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    FormHelperText,
    InputProps,
    MenuItem,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Control,
    Controller,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        select: { height: '40px' },
        icon: { fill: theme.palette.semantic.colorIconDefault },
        disabled: { color: theme.palette.semantic.colorForegroundDisable },
        noneMenuItem: { fontStyle: 'italic' },
    }),
);

interface IUiSelectProps extends InputProps {
    control: Control;
    name: string;
    inputLabel: string;
    options: { [k: string]: string } | any[];
    dataCy: string;
    helperText?: string | React.ReactNode;
    isTranslated?: boolean;
}

export const UiSelect: React.FC<IUiSelectProps> = ({
    control,
    name,
    inputLabel,
    options,
    dataCy,
    defaultValue,
    error,
    disabled,
    helperText,
    className,
    style,
    fullWidth,
    required,
    isTranslated,
    onChange,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const mapEnglishToTranslated = useCallback(
        (englishToI18nCode: { [k: string]: string }) => {
            const englishToTranslationMap: { [k: string]: string } = {};
            Object.entries(englishToI18nCode).forEach(([ key, value ]) => {
                englishToTranslationMap[key] = translate({ id: value });
            });
            return englishToTranslationMap;
        },
        [ translate ],
    );

    const optionsArray = useMemo(
        () =>
            Array.isArray(options)
                ? options.map(value => ({
                    key: value,
                    value,
                }))
                : isTranslated
                    ? Object.entries(options).map(([ key, value ]) => ({
                        key,
                        value,
                    }))
                    : Object.entries(mapEnglishToTranslated(options)).map(([ key, value ]) => ({
                        key,
                        value,
                    })),
        [ options, mapEnglishToTranslated, isTranslated ],
    );

    return (
        <FormControl
            error={error}
            disabled={disabled}
            className={className}
            style={style}
            fullWidth={fullWidth}
            variant="standard">
            <InputLabel
                htmlFor={name}
                id={`${name}-label`}
                className="select-label"
                style={{ position: 'relative' }}>
                {inputLabel}
            </InputLabel>
            <Controller
                as={
                    <Select
                        variant="outlined"
                        labelId={`${name}-label`}
                        inputProps={{
                            id: name,
                            name,
                            'data-cy': dataCy,
                            classes: {
                                icon: classes.icon,
                                disabled: classes.disabled,
                            },
                            style: {
                                height: '100%',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            },
                            onChange,
                        }}
                        className={classes.select}
                        IconComponent={ExpandMoreIcon}
                    >
                        {!required && (
                            <MenuItem
                                aria-label={translate({ id: 'CLIENT_NONE' })}
                                value=""
                                className={classes.noneMenuItem}>
                                {translate({ id: 'CLIENT_NONE' })}
                            </MenuItem>
                        )}
                        {optionsArray.map((option, i) => (
                            <MenuItem
                                key={i}
                                value={option.key}
                                lang={name === 'language' ? option.key : undefined}
                                aria-label={name === 'language' ? `${option.value} ${option.key.toUpperCase()}` : undefined}
                            >
                                {option.value}
                            </MenuItem>
                        ))}
                    </Select>
                }
                defaultValue={defaultValue ?? ''}
                name={name}
                rules={{ required }}
                control={control}
            />
            <FormHelperText>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

