import {
    ICreateSubscriptionDto,
    IDiscountedProductsPrices,
    IErrorWithStatus,
    IHistoryStatus,
    IPackagesConfig,
    IPlanDetails,
    IProductBase,
    IProductPriceOptions,
    ISkuPackage,
    ISubscriptionDetails,
} from '@experiences/ecommerce';

import {
    get,
    post,
} from '../utility/Requests.default';

export const billingUrl = '/api/billing';

// TODO//NovaTeam: once we ship PLT-9664 delete me
export async function getSkuPackages(accountName: string, countryCode?: string) {
    const urlParams: any = { accountName };
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return await get<ISkuPackage[]>(`${billingUrl}/skuPackages`, { urlParams });
}

export async function getPackagesConfig(accountName: string, countryCode?: string) {
    const urlParams: any = { accountName };
    if (countryCode) {
        urlParams['countryCode'] = countryCode;
    }
    return await get<IPackagesConfig>(`${billingUrl}/packagesConfig`, { urlParams });
}

export async function getPackagesConfigByCurrency(accountName: string, currency?: string) {
    const urlParams: any = { accountName };
    if (currency) {
        urlParams['currency'] = currency;
    }
    return await get<IPackagesConfig>(`${billingUrl}/packagesConfigByCurrency`, { urlParams });
}

export async function getProductsPricesInAllCurrencies(accountName?: string) {
    const urlParams = { accountName };
    return await get<IProductPriceOptions[]>(`${billingUrl}/productsPricesInAllCurrencies`, { urlParams });
}

export async function getProductsPricesWithDiscountsByCurrency(accountName: string, currency: string, productQuantities: IProductBase[]) {
    const urlParams = {
        accountName,
        currency,
    };
    return await post<IDiscountedProductsPrices>(`${billingUrl}/productsPricesByCurrency`, {
        urlParams,
        body: productQuantities,
    });
}

export async function fetchPlanDetails(accountName: string) {
    const urlParams = { accountName };
    return await get<IPlanDetails>(`${billingUrl}/planDetails`, { urlParams });
}

export async function fetchHistoryStatus(accountName: string) {
    const urlParams = { accountName };
    return await get<IHistoryStatus>(`${billingUrl}/historyStatus`, { urlParams });
}

export async function goToStripeCustomerPortalSession(accountName: string) {
    const urlParams = { accountName };
    const stripeUrl = await post<string>(`${billingUrl}/createStripeCustomerSession`, {
        urlParams: urlParams,
        body: { returnUrl: window.location.href },
    });
    window.location.assign(stripeUrl);
}

export async function createSubscription(subscriptionDetails: ISubscriptionDetails, accountName: string) {
    try {
        return await post<ICreateSubscriptionDto>(`${billingUrl}/createSubscription`, {
            urlParams: { accountName },
            body: subscriptionDetails,
        });
    } catch (error) {
        const errorWithStatus = new Error('An error occurred while creating subscription.') as IErrorWithStatus;
        errorWithStatus.translationId = error.response?.data?.ErrorCode
            ? `CLIENT_ERROR_${error.response.data.ErrorCode.toUpperCase()}`
            : 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY';
        errorWithStatus.status = error.response.status;
        errorWithStatus.stack = error.stack;
        errorWithStatus.message = error.response?.data?.Message;
        throw errorWithStatus;
    }
}

export async function createDirectBuySubscription(subscriptionDetails: ISubscriptionDetails, accessToken: string) {
    try {
        return await post<ICreateSubscriptionDto>(`${billingUrl}/createDirectBuySubscription`, {
            body: subscriptionDetails,
            accessToken,
        });
    } catch (error) {
        const errorWithStatus = new Error('An error occurred while creating subscription.') as IErrorWithStatus;
        errorWithStatus.translationId = error.response?.data?.ErrorCode
            ? `CLIENT_ERROR_${error.response.data.ErrorCode.toUpperCase()}`
            : 'CLIENT_PAYMENT_TROUBLE_PROCESSING_BODY';
        errorWithStatus.status = error.response.status;
        errorWithStatus.stack = error.stack;
        errorWithStatus.message = error.response?.data?.Message;
        throw errorWithStatus;
    }
}
