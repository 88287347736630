import {
    INotificationPublisherDto,
    INotificationTopic,
    INotificationTopicDto,
    INotificationTopicGroup,
    INotificationTopicPublisher,
    NotificationMode,
} from '../interfaces/notificationSettings';

const getGroupedTopics = (topics: INotificationTopicDto[]) => {
    const groupedTopics: INotificationTopicGroup[] = [
        {
            groupName: 'un-grouped',
            topics: [],
            groupIndex: 0,
            modes: [],
        },
    ];

    topics.forEach((topic: INotificationTopicDto) => {
        if (topic.group == null) {
            groupedTopics[0].topics.push(getTopicListObject(topic, groupedTopics[0].topics.length));
        } else {
            const groupName = topic.group;
            const groupIndex = groupedTopics.findIndex(tg => tg.groupName === groupName);
            if (groupIndex === -1) {
                const groupTopicItem = {
                    groupName,
                    topics: [ getTopicListObject(topic, 0) ],
                    groupIndex: groupedTopics.length,
                    modes: [],
                } as INotificationTopicGroup;
                groupedTopics.push(groupTopicItem);
            } else {
                groupedTopics[groupIndex].topics.push(getTopicListObject(topic, groupedTopics[groupIndex].topics.length));
            }
        }
    });

    groupedTopics.forEach(topicGroup => {
        topicGroup.modes = Object.values(NotificationMode)
            .filter(modeName => isTopicGroupModeVisible(modeName, topicGroup))
            .map(modeName => ({
                name: modeName,
                isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
            }));
    });

    return groupedTopics;
};

const isTopicModeVisible = (modeName: NotificationMode, topic: INotificationTopic) =>
    !!topic.modes.find(mode => mode.name === modeName);

const isTopicGroupModeVisible = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) =>
    topicGroup.topics.some(topic => isTopicModeVisible(modeName, topic));

const isTopicModeSubscribed = (modeName: NotificationMode, topic: INotificationTopic) =>
    topic.modes.find(mode => modeName === mode.name)?.isSubscribed;

export const isTopicGroupModeSubscribed = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) =>
    topicGroup.topics.every(topic => isTopicModeSubscribed(modeName, topic));

export const isPublisherModeSubscribed = (modeName: NotificationMode, publisher: INotificationTopicPublisher) =>
    publisher.topicGroups.flatMap(topicGroup => topicGroup.topics).every(topic => topic.modes.find(mode => modeName === mode.name)?.isSubscribed);

export const isTopicGroupModeIndeterminate = (modeName: NotificationMode, topicGroup: INotificationTopicGroup) => {
    const subscriptions = topicGroup.topics.map(topic => topic.modes.find(mode => mode.name === modeName)?.isSubscribed);
    return subscriptions.some(subscription => subscription !== subscriptions[0]);
};
export const isPublisherModeIndeterminate = (modeName: NotificationMode, publisher: INotificationTopicPublisher) => {
    const topics = publisher.topicGroups.flatMap(topicGroup => topicGroup.topics);
    const subscriptions = topics.filter(topic => topic.modes.find(mode => mode.name === modeName))
        .map(topic => topic.modes.find(mode => mode.name === modeName)?.isSubscribed);
    return subscriptions.some(subscription => subscription !== subscriptions[0]);
};

const getTopicListObject = (topic: INotificationTopicDto, index: number): INotificationTopic => ({
    displayName: topic.displayName,
    topicId: topic.id,
    name: topic.name,
    isSubscribed: topic.isSubscribed,
    topicFiltersUrl: topic.topicFilters,
    topicIndex: index,
    modes: topic.modes,
    description: topic.description,
});

export const getPublishersWithGroupedTopics = (publishers: INotificationPublisherDto[]): INotificationTopicPublisher[] => {
    const groupedPublisher: INotificationTopicPublisher[] = publishers
        .map((publisher: INotificationPublisherDto, index: number) => {
            const topicGroups = getGroupedTopics(publisher.topics);
            return {
                name: publisher.name,
                modes: publisher.modes,
                publisherIndex: index,
                publisherId: publisher.id,
                topicGroups,
            };
        });

    groupedPublisher.forEach(publisher => {
        publisher.modes.forEach(mode => {
            mode.isSubscribed = isPublisherModeSubscribed(mode.name, publisher);
            mode.isIndeterminate = isPublisherModeIndeterminate(mode.name, publisher);
        });
    });

    return groupedPublisher;
};
