import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import useShowDeleteAADDialog from '../../../common/hooks/useShowDeleteAADDialog';
import {
    AuthenticationSettingType,
    authenticationSettingUrl,
    deleteAuthenticationSetting,
} from '../../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        externalProvidersGrid: {
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            width: '100%',
            gap: '16px',
        },
        section: {
            flex: 1,
            marginBottom: '14px',
            minWidth: '200px',
            flexBasis: 'calc(50% - 8px)',
        },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        description: {
            fontSize: '14px',
            lineHeight: '20px',
            maxWidth: '451px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        button: {
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '12px',
        },
        deleteButton: {
            color: `${theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon)}`,
            backgroundColor: `${theme.palette.semantic.colorErrorIcon} !important`,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        buttonRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        buttonMargin: { marginLeft: '8px' },
    }),
);

const ExternalProvidersButtonsComponent: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage: translate } = useIntl();

    const [ loading, setLoading ] = useState(false);

    const isHostMode = useSelector(isHostModeSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const authenticationSetting = useCheckAuthenticationSetting();
    const showDeleteAADDialog = useShowDeleteAADDialog();

    const isNoSetting = useMemo(() => {
        return authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.NoSetting;
    }, [ authenticationSetting ]);

    const isGoogle = useMemo(() => {
        return authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.Google;
    }, [ authenticationSetting ]);

    const isAD = useMemo(() => {
        return (
            authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AD ||
      authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.LDPAD
        );
    }, [ authenticationSetting ]);

    const isAAD = useMemo(() => {
        return authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.AAD;
    }, [ authenticationSetting ]);

    const isSaml = useMemo(() => {
        return authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.SAML;
    }, [ authenticationSetting ]);

    const handleConfigureAD = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureAD));
    }, [ getRoute, history ]);

    const handleConfigureAAD = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureAAD));
    }, [ getRoute, history ]);

    const handleConfigureSaml = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureSaml));
    }, [ getRoute, history ]);

    const handleConfigureSamlOrg = useCallback(() => {
        history.push({
            pathname: getRoute(`${RouteNames.SecuritySettings}/configure/saml2`),
            state: {
                authenticationSetting,
                showPreReqs: authenticationSetting?.authenticationSettingType?.toLowerCase() !== AuthenticationSettingType.SAML,
            },
        });
    }, [ authenticationSetting, getRoute, history ]);

    const handleConfigureGoogle = useCallback(() => {
        history.push(getRoute(RouteNames.AuthSettingsConfigureGoogle));
    }, [ getRoute, history ]);

    const handleDelete = useCallback(async () => {
        const proceed = await showDeleteAADDialog();
        if (proceed) {
            setLoading(true);
            try {
                await deleteAuthenticationSetting(partitionGlobalId);
                enqueueSnackbar(translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_SUCCESSFULLY_DELETED' }), { variant: notificationType.SUCCESS } as any);
                mutate([ authenticationSettingUrl, partitionGlobalId ]);
            } catch (error) {
                enqueueSnackbar(translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_DELETE_FAILED' }), { variant: notificationType.ERROR } as any);
            } finally {
                setLoading(false);
            }
        }
    }, [ showDeleteAADDialog, partitionGlobalId, enqueueSnackbar, translate ]);

    return (
        <div className={classes.externalProvidersGrid}>
            {isHostMode && (
                <div className={classes.section}>
                    <Typography className={classes.subHeading}>
                        {translate({ id: 'CLIENT_GOOGLE_INTEGRATION' })}
                    </Typography>
                    <Typography className={classes.description}>
                        {translate({ id: 'CLIENT_GOOGLE_INTEGRATION_DESCRIPTION' })}
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        disabled={!isHostMode && !isNoSetting && !isGoogle}
                        onClick={handleConfigureGoogle}
                        data-cy="google-configure-button"
                    >
                        {translate({ id: isGoogle ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </div>
            )}
            <div className={classes.section}>
                <Typography className={classes.subHeading}>
                    {translate({ id: 'CLIENT_AAD_INTEGRATION' })}
                </Typography>
                <Typography className={classes.description}>
                    {translate({ id: 'CLIENT_AAD_INTEGRATION_DESCRIPTION' })}
                </Typography>
                <div className={classes.buttonRow}>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        disabled={!isHostMode && !isNoSetting && !isAAD}
                        onClick={handleConfigureAAD}
                        data-cy="aad-configure-button"
                    >
                        {translate({ id: isAAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                    {!isHostMode && isAAD && (
                        <UiProgressButton
                            innerButtonClass={clsx(classes.button, classes.deleteButton, classes.buttonMargin)}
                            variant="outlined"
                            data-cy="saml-delete-button"
                            color="error"
                            loading={loading}
                            onClick={handleDelete}
                            startIcon={<DeleteIcon />}
                        >
                            {translate({ id: 'CLIENT_LOGS_DELETE' })}
                        </UiProgressButton>
                    )}
                </div>
            </div>
            {isHostMode && (
                <div className={classes.section}>
                    <Typography className={classes.subHeading}>
                        {translate({ id: 'CLIENT_AD_INTEGRATION' })}
                    </Typography>
                    <Typography className={classes.description}>
                        {translate({ id: 'CLIENT_AD_INTEGRATION_DESCRIPTION' })}
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        disabled={!isHostMode && !isNoSetting && !isAD}
                        onClick={handleConfigureAD}
                        data-cy="ad-configure-button"
                    >
                        {translate({ id: isAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </div>
            )}
            {isHostMode && (
                <div className={classes.section}>
                    <Typography className={classes.subHeading}>
                        {translate({ id: 'CLIENT_SAML_INTEGRATION' })}
                    </Typography>
                    <Typography className={classes.description}>
                        {translate({ id: 'CLIENT_SAML_INTEGRATION_DESCRIPTION' })}
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        disabled={!isHostMode && !isNoSetting && !isSaml}
                        onClick={handleConfigureSaml}
                        data-cy="saml-configure-button"
                    >
                        {translate({ id: isAD ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                    </Button>
                </div>
            )}
            {!isHostMode && (
                <div className={classes.section}>
                    <Typography className={classes.subHeading}>
                        {translate({ id: 'CLIENT_SAML_INTEGRATION' })}
                    </Typography>
                    <Typography className={classes.description}>
                        {translate({ id: 'CLIENT_SAML_ORG_INTEGRATION_DESCRIPTION' })}
                    </Typography>
                    <div className={classes.buttonRow}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            disabled={!isHostMode && !isNoSetting && !isSaml}
                            onClick={handleConfigureSamlOrg}
                            data-cy="saml-org-configure-button"
                        >
                            {translate({ id: isSaml ? 'CLIENT_EDIT' : 'CLIENT_CONFIGURE' })}
                        </Button>
                        {isSaml && (
                            <UiProgressButton
                                innerButtonClass={clsx(classes.button, classes.deleteButton, classes.buttonMargin)}
                                variant="outlined"
                                data-cy="saml-delete-button"
                                color="error"
                                loading={loading}
                                onClick={handleDelete}
                                startIcon={<DeleteIcon />}
                            >
                                {translate({ id: 'CLIENT_LOGS_DELETE' })}
                            </UiProgressButton>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExternalProvidersButtonsComponent;
