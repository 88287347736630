import { ContactUsLinks } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import {
    useFilteredParams,
    useLocalizedLinks,
} from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    Paper,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import useStaticLogout from '../../auth/hooks/StaticLogout';
import { getFriendlyName } from '../../common/constants/ServicesMapping';
import useNavigateToHome from '../../common/hooks/useNavigateToHome';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        paperDefault: {
            minWidth: 425,
            maxWidth: 520,
            height: 'fit-content',
            marginTop: '100px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        content: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
        },
        errorIcon: { color: theme.palette.semantic.colorErrorIcon },
        warningIcon: { color: theme.palette.semantic.colorWarningIcon },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
            marginBottom: '-24px',
        },
        headerSubText: {
            marginTop: '32px',
            margin: '0px 28px',
            textAlign: 'center',
            overflowWrap: 'break-word',
        },
        signUpLine: {
            marginTop: '8px',
            display: 'flex',
            marginBottom: '16px',
        },
        button: {
            width: '280px',
            marginTop: '32px',
        },
        bottomLinks: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: '12px',
            padding: '8px 24px',
        },
        link: {
            marginLeft: '4px',
            cursor: 'pointer',
            color: theme.palette.semantic.colorPrimary,
            '&:hover': { textDecoration: 'underline' },
        },
    }),
}));

/**
 * Display page when user tries to login but account does not exist
 */
export const Unregistered: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const logout = useStaticLogout();
    const getLocalizedLink = useLocalizedLinks();
    const [ serviceType, tenantName, organizationName ] = useFilteredParams([
        'serviceType',
        'tenantName',
        'organizationName',
    ]);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const navigateToHome = useNavigateToHome(organizationName);

    const handleLogin = useCallback(
        (e: any) => {
            e.preventDefault();
            logout();
        },
        [ logout ],
    );

    const getErrorMessage = useCallback(() => {
        if (serviceType === 'portal' && organizationName) {
            return (<Typography>
                {translate(
                    { id: 'CLIENT_UNREGISTERED_ORGANIZATION_DESCRIPTION' },
                    { serviceName: getFriendlyName(serviceType, false) },
                )}
            </Typography>);
        } else if (serviceType && tenantName) {
            return (
                <>
                    <Typography>
                        {translate(
                            { id: 'CLIENT_UNREGISTERED_SERVICE_DESCRIPTION' },
                            {
                                serviceName: getFriendlyName(serviceType, false),
                                tenantName,
                            },
                        )}
                    </Typography>
                    <Typography style={{ marginTop: '8px' }}>
                        {process.buildConfigs.simpleUnregisteredMessage
                            ? translate({ id: 'CLIENT_UNREGISTERED_SERVICE_SIMPLE' })
                            : translate(
                                { id: 'CLIENT_UNREGISTERED_SERVICE_DESCRIPTION2' },
                                {
                                    serviceName: getFriendlyName(serviceType, false),
                                    titleProductName: process.buildConfigs.titleProductName,
                                    tenantName,
                                },
                            )}
                    </Typography>
                </>
            );
        }
        return translate({ id: 'CLIENT_UNREGISTERED_DESCRIPTION' });

    }, [ organizationName, serviceType, tenantName, translate ]);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.content}>
                    <UiLogo />
                    {!serviceType && <CancelIcon className={clsx(classes.headerIcon, classes.errorIcon)} />}
                    {!serviceType && (
                        <Typography
                            className={classes.headerText}
                            data-cy="unregistered-header-text">
                            {translate({ id: 'CLIENT_OOPS_SOMETHING_WENT_WRONG_ELLIPSES' })}
                        </Typography>
                    )}
                    <div
                        className={classes.headerSubText}
                        data-cy="unregistered-header-subtext">
                        {getErrorMessage()}
                    </div>
                    {serviceType && tenantName ? (
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={navigateToHome}
                            data-cy="unregistered-back-to-home"
                        >
                            {translate({ id: 'CLIENT_GO_TO_HOME_PAGE' })}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleLogin}
                            data-cy="unregistered-sign-in-button"
                        >
                            {translate({ id: 'CLIENT_BACK_TO_SIGN_IN' })}
                        </Button>
                    )}
                    {!process.buildConfigs.hideSignUpLink && (!serviceType || (serviceType === 'portal' && organizationName)) && (
                        <div className={classes.signUpLine}>
                            <Typography display="inline">
                                {translate({ id: 'CLIENT_DONT_HAVE_ACCOUNT' })}
                            </Typography>
                            <Typography
                                display="inline"
                                className={classes.link}
                                onClick={handleLogin}>
                                {translate({ id: 'CLIENT_SIGNUP' })}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.bottomLinks}>
                        {!process.buildConfigs.disableServiceStatusLink && !DisableFeatureFedRamp && (
                            <a
                                className={classes.a}
                                href="https://status.uipath.com"
                                data-cy="unregistered-status-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translate({ id: 'CLIENT_SERVICE_STATUS' })}
                            </a>
                        )}
                        <a
                            className={classes.a}
                            href={getLocalizedLink(ContactUsLinks)}
                            data-cy="unregistered-contact-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translate({ id: 'CLIENT_CONTACT_US' })}
                        </a>
                    </div>
                </div>
            </Paper>
        </UiBackground>
    );
};

export default Unregistered;
