import { useLocalization } from '@experiences/locales';
import Info from '@mui/icons-material/Info';
import {
    CircularProgress,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';
import {
    IOrderedProductDetails,
    IProduct,
    IProductPrice,
    IProductQuantityPrice,
} from '../interfaces/ecommerce';

const useStyles = makeStyles(theme =>
    createStyles({
        productRow: {
            display: 'flex',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        circularLoading: { borderRadius: '30px' },
        alignRight: {
            marginLeft: 'auto',
            textAlign: 'end',
        },
        tableCellTop: { verticalAlign: 'top' },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        subtotalRow: { marginBottom: '12px' },
        subtotalText: { color: theme.palette.semantic.colorForeground },
        fontSizeLBold: {
            height: '24px',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        fontSizeM: {
            height: '24px',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        amountDueTodayPrice: { marginLeft: 'auto' },
        amountDueTodayTax: { alignSelf: 'end' },
        infoIcon: {
            width: '15px',
            height: '20px',
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '11px',
            verticalAlign: 'middle',
        },
        infoTooltipText: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundInverse,
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.2)',
            borderRadius: '3px',
        },
    }),
);

const EcommerceAmountDueToday: React.FC<{
    productPrices?: IProductPrice[];
    currency: string;
    productsToAdd: IProduct[];
    upcomingInvoiceProductPrices?: IOrderedProductDetails[];
    price?: number;
}> = ({
    productPrices,
    currency,
    productsToAdd,
    upcomingInvoiceProductPrices,
    price,
}) => {
    const classes = useStyles();
    const language = useLocalization();
    const getProratedValue = useCallback((productCode: string) => {
        if (upcomingInvoiceProductPrices) {
            const foundProduct = upcomingInvoiceProductPrices.find(invoiceProduct => invoiceProduct.code === productCode);
            if (foundProduct) {
                return foundProduct.totalPrice;
            }
        }
        return undefined;
    }, [ upcomingInvoiceProductPrices ]);

    const [ hiddenBreakdown, setHiddenBreakdown ] = useState(false);

    const productPricesToAdd = useMemo(() => {
        return productsToAdd
            .filter(p => p.quantity > 0)
            .map((product) => {
                const productQuantityPrice = {
                    code: product.code,
                    quantity: product.quantity,
                } as IProductQuantityPrice;
                if (productPrices?.length) {
                    productQuantityPrice.proratedPrice = getProratedValue(product.code);
                }
                return productQuantityPrice;
            });
    }, [ productsToAdd, productPrices, getProratedValue ]);

    const { formatMessage: translate } = useIntl();

    const newProductsPrice = useMemo(() => {
        if (price) {
            return price;
        }
        let priceTotal = 0;
        if (productPrices && productPricesToAdd) {
            productPricesToAdd.forEach(product => {
                const proratedValue = getProratedValue(product.code);
                if (proratedValue) {
                    priceTotal += proratedValue;
                }
            });
        }
        return priceTotal;
    }, [ price, productPricesToAdd, getProratedValue, productPrices ]);

    return (
        <>
            <div className={clsx(classes.row, classes.subtotalRow, classes.subtotalText)}>
                <Typography className={classes.fontSizeLBold}>
                    {translate({ id: `CLIENT_AMOUNT_DUE_TODAY` })}
                </Typography>
                {newProductsPrice ? (
                    <>
                        <Typography
                            className={clsx(classes.fontSizeM, classes.amountDueTodayPrice)}
                            data-cy="new-product-price">
                            {getPriceString(newProductsPrice, currency, language)}
                        </Typography>
                        <Typography className={classes.amountDueTodayTax}>
                            {translate({ id: `CLIENT_PLUS_TAX` })}
                        </Typography>
                    </>
                ) : (<CircularProgress
                    size={24}
                    className={clsx(classes.circularLoading, classes.alignRight)} />)}
            </div>
            <Link
                onClick={() => setHiddenBreakdown(!hiddenBreakdown)}
                underline="none"
                data-cy='link-toggle-breakdown'
            >
                {hiddenBreakdown ? translate({ id: 'CLIENT_SHOW_BREAKDOWN' }) : translate({ id: 'CLIENT_HIDE_BREAKDOWN' })}
            </Link>
            {!hiddenBreakdown && productPricesToAdd && (
                <table style={{ width: '100%' }}>
                    {productPricesToAdd.filter(p => p.quantity > 0).map(product =>
                        (<tr key={product.code + product.quantity}>
                            <td style={{
                                display: 'flex',
                                maxWidth: '320px',
                            }}>
                                <Typography
                                    data-cy={`product-to-add-name-${product.code}`}
                                >
                                    {`${
                                        product.quantity > 0 ? `${product.quantity} ` : ''
                                    }${translate({ id: `CLIENT_PRODUCT_${product.code}` })}`}
                                    {' ' + translate({ id: 'CLIENT_PRORATED' })}
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltipBox }}
                                        title={
                                            <Typography data-cy="product-info-tooltip">
                                                <span className={classes.infoTooltipText}>
                                                    <FormattedMessage
                                                        id="CLIENT_PRORATED_INFO_TOOLTIP"
                                                        values={{
                                                            title: (chunk: string) => <span>
                                                                {chunk}
                                                            </span>,
                                                        }}
                                                    />
                                                </span>
                                            </Typography>
                                        }
                                    >
                                        <Info className={classes.infoIcon} />
                                    </Tooltip>
                                </Typography>
                            </td>
                            <td className={classes.tableCellTop}>
                                {(productPrices && product.proratedPrice) ? (
                                    <Typography
                                        className={classes.alignRight}
                                        key={`${product.code}-${product.quantity}-${product.proratedPrice}`}
                                        data-cy={`product-to-add-price-${product.code}`}
                                    >
                                        {getPriceString(product.proratedPrice, currency, language)}
                                    </Typography>) : (<CircularProgress
                                    size={16}
                                    className={clsx(classes.circularLoading, classes.alignRight)} />
                                )}
                            </td>
                        </tr>))}
                </table>
            )}
        </>
    );
};

export default EcommerceAmountDueToday;

