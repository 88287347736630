import { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import {
    kmsUrl,
    saveKmsConfig,
} from '../../../../services/identity/KeyManagementService';
import { accountGlobalId } from '../../../../store/selectors';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
    }),
);

const EncryptionWarningDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, refreshCallback,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const [ loading, setLoading ] = useState(false);

    const handleClick = useCallback(async () => {
        setLoading(true);
        await saveKmsConfig(kmsUrl, {
            partitionGlobalId: partitionGlobalId,
            keyType: 0,
        });
        closeDialog();
        setLoading(false);
        refreshCallback();
    }, [ closeDialog, partitionGlobalId, refreshCallback ]);

    const formatStrong = useCallback((chunk: string) => {
        return (
            <strong>
                {chunk}
            </strong>
        );
    }, []);

    return (
        <>
            <Typography data-cy="encryption-warning-message">
                <FormattedMessage
                    id='CLIENT_ENCRYPTION_WARNING_DESCRIPTION'
                    values={{ strong: formatStrong }}
                />
            </Typography>
            <br />
            <Typography data-cy="encryption-warning-ask">
                {translate({ id: 'CLIENT_ASK_CONFIRMATION' })}
            </Typography>

            <div className={classes.buttonContainer}>
                <Button
                    className={clsx(classes.button, classes.buttonInner)}
                    color="primary"
                    data-cy="encryption-warning-cancel-button"
                    onClick={() => closeDialog()}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    className={classes.button}
                    data-cy="encryption-warning-confirmation-button"
                    innerButtonClass={classes.buttonInner}
                    loading={loading}
                    onClick={() => handleClick()}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_YES' })}
                </UiProgressButton>
            </div>
        </>
    );
};

export default EncryptionWarningDialogBody;
