import { IServiceMetadata } from '../../interfaces/service';
import { ServiceLicenseStatus } from '../CreateEditTenantComponent';
import { getListOfHiddenDependencies } from './ServiceDependencyGraph';

export const addHiddenDependenciesHelper = (
    services: string[],
    availableServices: IServiceMetadata[] | undefined) => {
    services.forEach(service => {
        const hiddenService = getListOfHiddenDependencies(service);
        hiddenService.forEach(service => {
            const implicitService = availableServices?.find(s => s.id === service);
            if (implicitService?.serviceLicenseStatus === ServiceLicenseStatus.Available) {
                services.push(service);
            }
        });
    });

    return [ ...new Set(services) ];
};
