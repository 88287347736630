import { IUiLinkMap } from '@experiences/interfaces';

export const PrivacyPolicyLinks: IUiLinkMap = new Map([
    [ 'en', 'https://www.uipath.com/legal/privacy-policy' ],
    [ 'ja', 'https://www.uipath.com/ja/legal/privacy-policy' ],
    [ 'fr', 'https://www.uipath.com/fr/legal/privacy-policy' ],
    [ 'zh-CN', 'https://www.uipath.com.cn/legal/privacy-policy' ],
    [ 'zh-TW', 'https://www.uipath.com.cn/legal/privacy-policy' ],
    [ 'es', 'https://www.uipath.com/es/legal/privacy-policy' ],
    [ 'ko', 'https://www.uipath.com/ko/legal/privacy-policy' ],
]);

export const TermsOfUseLinks: IUiLinkMap = new Map([
    [ 'en', 'https://www.uipath.com/legal/terms-of-use' ],
    [ 'ja', 'https://www.uipath.com/ja/legal/terms-of-use' ],
    [ 'ko', 'https://www.uipath.com/ko/legal/terms-of-use' ],
]);
