import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    FormHelperText,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { MessageTemplateName } from '../../../common/constants/MessageTemplateConstant';
import { IMessageTemplate } from '../../../common/interfaces/cis/message-template';
import {
    createMessageTemplate,
    getMessageTemplate,
    updateMessageTemplate,
} from '../../../services/identity/MessageTemplateService';
import {
    mapLoginCustomizationDataToCreateMessageTemplateDto,
    mapLoginCustomizationDataToMessageTemplate,
    mapToLoginCustomizationData,
} from '../../../util/MessageTemplateUtil';
import { UiCodeEditor } from '../../common/UiCodeEditor';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(theme =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        section: {
            flex: 1,
            minWidth: '200px',
            maxWidth: '50%',
        },
        sectionHeading: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        sectionDescription: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        sectionText: { marginBottom: '16px' },
        dividedContent: {
            padding: '21px 16px 0px 0px',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '16px',
        },
        preview: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            padding: '24px',
        },
    }),
);

const MAX_LOGIN_CUSTOMIZATION = 20000;

export interface ILoginCustomizationData {
    loginLayout: string;
}

const LoginCustomizationComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const {
        handleSubmit, reset, formState, control, errors, watch,
    } = useForm<ILoginCustomizationData>({
        mode: 'onChange',
        defaultValues: { loginLayout: '' },
    });
    const {
        isDirty, isValid, isSubmitting,
    } = formState;

    const {
        data: fetchedLoginLayoutMessageTemplate, mutate,
    } = useSWR<IMessageTemplate | undefined, Error>(
        MessageTemplateName.LoginLayout,
        getMessageTemplate,
        { shouldRetryOnError: false },
    );
    const originalData = useMemo(
        () => mapToLoginCustomizationData(fetchedLoginLayoutMessageTemplate),
        [ fetchedLoginLayoutMessageTemplate ],
    );
    const loginLayout = watch('loginLayout');

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: ILoginCustomizationData) => {
            try {
                const dataPromise = fetchedLoginLayoutMessageTemplate
                    ? updateMessageTemplate(
                        mapLoginCustomizationDataToMessageTemplate(data, fetchedLoginLayoutMessageTemplate),
                    )
                    : createMessageTemplate(mapLoginCustomizationDataToCreateMessageTemplateDto(data));
                const savedData = await dataPromise;
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_LOGIN_CUSTOMIZATION_UPDATED' }));
            } catch (error) {
                createNotification(
                    translate({ id: 'CLIENT_LOGIN_CUSTOMIZATION_UPDATE_ERROR' }),
                    notificationType.ERROR,
                );
            }
        },
        [ createNotification, fetchedLoginLayoutMessageTemplate, mutate, translate ],
    );

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    useEffect(() => {
        if (fetchedLoginLayoutMessageTemplate) {
            reset(originalData);
        }
    }, [ reset, fetchedLoginLayoutMessageTemplate, originalData ]);

    return (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    {isDirty && (
                        <Button
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}
                            color="primary"
                            data-cy="login-customization-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                    )}
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        disabled={!isDirty || !isValid}
                        variant="contained"
                        data-cy="login-customization-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            heightOffset="94px"
        >
            <div className={classes.dividedContent}>
                <div className={classes.section}>
                    <div className={classes.sectionText}>
                        <Typography className={clsx(classes.sectionHeading, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_EDIT' })}
                        </Typography>
                        <Typography className={classes.sectionDescription}>
                            {translate({ id: 'CLIENT_LOGIN_CUSTOMIZATION_EDIT_DESCRIPTION' })}
                        </Typography>
                    </div>
                    <Controller
                        control={control}
                        rules={{ maxLength: MAX_LOGIN_CUSTOMIZATION }}
                        name="loginLayout"
                        render={({
                            value, onChange,
                        }) => (
                            <>
                                <UiCodeEditor
                                    dataCy="login-customization-login-layout-input"
                                    value={value}
                                    onValueChange={onChange}
                                />
                                <FormHelperText error={!!errors.loginLayout}>
                                    {(errors.loginLayout?.type === 'required' &&
                                        translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                        (errors.loginLayout?.type === 'maxLength' &&
                                            translate({ id: 'CLIENT_LOGIN_CUSTOMIZATION_MAX_LENGTH_ERROR' })) ||
                                        `${value?.length ?? 0}/${MAX_LOGIN_CUSTOMIZATION}`}
                                </FormHelperText>
                            </>
                        )}
                    />
                </div>
                <div className={classes.section}>
                    <div className={classes.sectionText}>
                        <Typography className={clsx(classes.sectionHeading, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_PREVIEW' })}
                        </Typography>
                        <Typography className={classes.sectionDescription}>
                            {translate({ id: 'CLIENT_LOGIN_CUSTOMIZATION_PREVIEW_DESCRIPTION' })}
                        </Typography>
                    </div>
                    <div
                        data-cy="login-customization-login-layout-preview"
                        className={classes.preview}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: sanitize(loginLayout, { FORCE_BODY: true }) }}
                    />
                </div>
            </div>
        </UiForm>
    );
};

export default LoginCustomizationComponent;
