import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { accountLogicalName } from '../../store/selectors';

const useNavigateToHome = (accountNameOverride?: string) => {
    const history = useHistory();
    const currentAccountName = useSelector(accountLogicalName);

    return useCallback(() => {
        const accountName = accountNameOverride ?? currentAccountName;
        history.push(generatePath(RouteNames.Home, { accountName }));
    }, [ accountNameOverride, currentAccountName, history ]);
};

export default useNavigateToHome;
