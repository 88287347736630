import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import React from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

import store from '../store';
import App from './App.default';
import { AxiosInterceptorProvider } from './AxiosInterceptorProvider';

export function AppWrapper() {
    return (
        <Provider store={store}>
            <IntlProvider>
                <AxiosInterceptorProvider>
                    <ApolloThemeProvider>
                        <SWRConfig
                            value={{
                                revalidateOnFocus: false,
                                dedupingInterval: (window as any).Cypress ? 0 : process.buildConfigs.swrDedupeInterval,
                            }}
                        >
                            <App />
                        </SWRConfig>
                    </ApolloThemeProvider>
                </AxiosInterceptorProvider>
            </IntlProvider>
        </Provider>
    );
}

export default AppWrapper;
