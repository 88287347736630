import { useApolloTheme } from '@experiences/theme';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import React, {
    useCallback,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';

const notificationDuration = {
    NOTIFICATION_DURATION: 3000,
    TRANSITION_DURATION: 500,
};

const useStyles = makeStyles(theme =>
    createStyles({
        iconWrapper: {
            paddingRight: '5px',
            paddingTop: '2px',
        },
        infoIcon: { color: theme.palette.semantic.colorInfoForegroundInverse },
        successIcon: { color: theme.palette.semantic.colorSuccessForegroundInverse },
        errorIcon: { color: theme.palette.semantic.colorErrorForegroundInverse },
        warningIcon: { color: theme.palette.semantic.colorWarningForegroundInverse },
        closeIcon: {
            color: theme.palette.semantic.colorIconInvertedDefault,
            '&:hover': { color: theme.palette.semantic.colorIconInvertedHover },
        },
    }),
);

const Toast = (props: any) => {
    const { theme } = useApolloTheme();
    const classes = useStyles();
    const notistackRef = useRef<any>();
    const { formatMessage: translate } = useIntl();

    const onClickDismiss = useCallback(
        key => () => {
            notistackRef.current.closeSnackbar(key);
        },
        [ notistackRef ],
    );

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={5}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoHideDuration={notificationDuration.NOTIFICATION_DURATION}
            // Work around for notistack bug with MUI5 compatibility where custom classes are not working
            // @ts-ignore
            sx={{
                '& .SnackbarContent-root': {
                    color: theme.palette.semantic.colorForegroundInverse,
                    backgroundColor: theme.palette.semantic.colorBackgroundInverse,
                },
                maxWidth: '600px',
            }}
            iconVariant={{
                default: (
                    <div className={classes.iconWrapper}>
                        <InfoIcon className={classes.infoIcon} />
                    </div>
                ),
                success: (
                    <div className={classes.iconWrapper}>
                        <SuccessIcon className={classes.successIcon} />
                    </div>
                ),
                error: (
                    <div className={classes.iconWrapper}>
                        <ErrorIcon className={classes.errorIcon} />
                    </div>
                ),
                warning: (
                    <div className={classes.iconWrapper}>
                        <WarningIcon className={classes.warningIcon} />
                    </div>
                ),
            }}
            action={key => (
                <IconButton
                    onClick={onClickDismiss(key)}
                    aria-label={translate({ id: 'CLIENT_CLOSE' })}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            )}
            transitionDuration={{ appear: notificationDuration.TRANSITION_DURATION }}
        >
            {props.children}
        </SnackbarProvider>
    );
};

export default Toast;
