import {
    UiErrorBoundary,
    UiLoader,
} from '@experiences/ui-common';
import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    renderRoutes,
    RouteConfigComponentProps,
} from 'react-router-config';

import PrivateShell from '../component/container/PrivateShell.default';
import { accountRoleType } from '../store/selectors';
import usePrivateShellRoutes from './routeConfigs/usePrivateShellRoutes.default';

const useStyles = makeStyles(theme =>
    createStyles({
        errorBoundary: {
            backgroundColor: theme.palette.semantic.colorBackground,
            width: '100%',
            height: '100%',
            margin: 'auto',
            padding: '24px',
        },
    }),
);

const ShellRouteWrapper: FC<{ routeConfig: RouteConfigComponentProps }> = ({ routeConfig }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const accountRole = useSelector(accountRoleType);
    const privateShellRoutes = usePrivateShellRoutes();

    const privateShellRoutesComponent = useMemo(() => renderRoutes(privateShellRoutes), [ privateShellRoutes ]);

    const errorComponent = useMemo(() => <Typography className={classes.errorBoundary}>
        {translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' })}
    </Typography>, [ classes.errorBoundary, translate ]);

    return (
        <PrivateShell>
            <UiErrorBoundary
                route={routeConfig}
                errorComponent={errorComponent}>
                {accountRole ? privateShellRoutesComponent : <UiLoader type="full" />}
            </UiErrorBoundary>
        </PrivateShell>
    );
};

export default ShellRouteWrapper;
