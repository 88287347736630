import { UiDialog } from '@experiences/ui-common';
import { useInterval } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme =>
    createStyles({
        yellowIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            fontSize: '32px',
        },
        DeleteFailCountDown: { marginTop: '5px' },
    }),
);

export const OrgDeleteAlertDialog: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const [ countDownTime, setCountDownTime ] = useState(5);

    const close = useCallback(() => {
        history.push('/portal_/logout');
    }, [ history ]);

    useInterval(() => {
        if (countDownTime <= 0) {
            history.push('/portal_/logout');
        } else {
            setCountDownTime(countDownTime - 1);
        }
    }, 1000);

    return (
        <>
            <UiDialog
                close={close}
                title={translate({ id: 'CLIENT_ALERT' })}
                icon={<WarningIcon className={classes.yellowIcon} />}
            >
                <div>
                    <Typography data-cy="delete-org-fail-message">
                        {translate({ id: 'CLIENT_ORGANIZATION_DELETE_FAILURE_MESSAGE' })}
                    </Typography>
                    <Typography
                        data-cy="delete-org-fail-countdown"
                        className={classes.DeleteFailCountDown}>
                        {translate({ id: 'CLIENT_DELETE_ORG_FAIL_COUNTDOWN' }, { 0: countDownTime })}
                    </Typography>
                </div>
            </UiDialog>
        </>
    );
};
