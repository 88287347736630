import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import { verifySmtpSettings } from '../../../services/identity/SettingService';
import { mapEmailSettingsDataToVerifySmtpSettingsData } from '../../../util/setting/EmailSettingUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';

export interface IVerifyEmailSettingsData {
    recipient: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const VerifyEmailSettingsComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        open, close,
    } = useModalState(RouteNames.EmailSettings);
    const { state: { emailSettingsData } } = useLocation<{ emailSettingsData: IEmailSettingsData }>();
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const methods = useForm<IVerifyEmailSettingsData>({
        mode: 'onSubmit',
        defaultValues: { recipient: '' },
    });

    const {
        control, handleSubmit, errors, formState,
    } = useMemo(() => methods, [ methods ]);
    const { isDirty } = formState;

    const { enqueueSnackbar } = useSnackbar();

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async ({ recipient }: IVerifyEmailSettingsData) => {
            try {
                setLoading(true);
                await verifySmtpSettings(recipient, mapEmailSettingsDataToVerifySmtpSettingsData(emailSettingsData));
                createNotification(translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS_SUCCESS' }, { 0: recipient }));
                close(true, { verifiedSettings: emailSettingsData });
            } catch (error) {
                setShowDrawerError(true);
            } finally {
                setLoading(false);
            }
        },
        [ close, createNotification, emailSettingsData, translate ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS_GENERIC_ERROR' }),
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="verify-email-settings-send-button"
                        >
                            {translate({ id: 'CLIENT_SEND' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={classes.input}>
                    <Typography
                        id="sendToLabel"
                        className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_SEND_TO' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        name="recipient"
                        variant="outlined"
                        type="email"
                        rules={{ validate: { valid: value => !value || validateEmail(value) } }}
                        error={!!errors.recipient}
                        helperText={errors.recipient?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })}
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="verify-email-settings-recipient"
                        inputProps={{ 'aria-labelledby': 'sendToLabel' }}
                    />
                </div>
            </UiForm>
        </UiDrawer>
    );
};

export default VerifyEmailSettingsComponent;
