import { Backdrop } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        fullPage: { height: '100vh' },
        backdrop: { zIndex: 999999 },
        container: {
            margin: '20px',
            width: '200px',
            maxWidth: '100%',
        },
    }),
);

export const UiLoader = ({
    size, type, ...props
}: { size?: string; type?: 'backdrop' | 'full' }) => {
    const classes = useStyles();

    const loadingElement = useMemo(
        () => (
            <div
                {...props}
                className={classes.container}>
                <portal-loader size={size} />
            </div>
        ),
        [ classes.container, props, size ],
    );

    return type === 'backdrop' ? (
        <Backdrop
            data-cy="ui-loader-backdrop"
            open={true}
            className={classes.backdrop}>
            {loadingElement}
        </Backdrop>
    ) : (
        <div
            data-cy="ui-loader"
            className={clsx(classes.root, type === 'full' ? classes.fullPage : '')}>
            {loadingElement}
        </div>
    );
};
