import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { portalTelemetry } from '@experiences/telemetry';
import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Paper } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Cookies from 'js-cookie';
import React, { PropsWithChildren } from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';

import * as RouteNames from '../common/constants/RouteNames';
import useAuthentication from './hooks/Authentication';
import useAuthorization from './hooks/Authorization';
import useInit from './hooks/Init';
import useUserInfo from './hooks/UserInfo';
import useValidateStore from './hooks/ValidateStore.default';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 440,
            height: 'fit-content',
            marginTop: '40px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        content: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
        },
    }),
);

export default function PortalInitializationProtector({ children }: PropsWithChildren<any>) {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { isLoggingOut } = useAuthentication();
    const {
        isAuthorizedOnCurrentRoute, isFreeSkuAuthorization,
    } = useAuthorization();
    const {
        token, identifier,
    } = useUserInfo();
    const isStoreInitialized = useValidateStore();
    const {
        init, ErrorComponent, renderError,
    } = useInit();
    const getRoute = useRouteResolver();
    const EnableAccessTokenLocalStorage = useFeatureFlagValue(Features.EnableAccessTokenLocalStorage.name);
    const EnableOffersRevamp = useFeatureFlagValue(Features.EnableOffersRevamp.name);

    if (!token || isLoggingOut) {
        return <></>;
    }

    // TODO - Deprecate after all teams have modified testing to not use localstorage
    if (EnableAccessTokenLocalStorage) {
        localStorage.setItem('accessToken', token);
    }

    if (renderError) {
        return (
            <UiBackground center>
                <Paper className={classes.paperDefault}>
                    <div className={classes.content}>
                        <UiLogo />
                        {ErrorComponent}
                    </div>
                </Paper>
            </UiBackground>
        );
    }

    // initialize redux
    if (!isStoreInitialized) {
        init();
        return <></>;
    }

    // for FreeSKU accounts, navigate to specific landing page
    if (!EnableOffersRevamp && isFreeSkuAuthorization() && !location.pathname.includes('automationkit')) {
        history.push(getRoute(RouteNames.FreeSkuLandingPage));
        return <></>;
    }

    // handle authorization
    if (!isAuthorizedOnCurrentRoute()) {
        history.push(`/portal_/genericerror?errorCode=9017`);
        return <></>;
    }

    const _ga = Cookies.get('_ga');
    portalTelemetry.trackTrace(
        {
            message: 'Internal Redirect',
            severityLevel: SeverityLevel.Information,
        },
        {
            _ga,
            sub: identifier,
            pathname: location.pathname,
        },
    );

    return children;
}
