import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

// This session Id is returned from Identity.
// This session Id is same for the user accross the applications

export default function useSessionId() {
    const { user } = useAuth();

    const sessionId = useMemo(() => {
        return user?.profile?.sid ?? '';
    }, [ user ]);

    return sessionId;
}
