import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AzureADIntegrationLink } from '../../../common/constants/documentation/AzureADIntegrationDocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import {
    authenticationSettingUrl,
    getAuthenticationSetting,
    IAuthenticationSettingResponse,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import ExternalProvidersAADForm from './ExternalProvidersAADForm';

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
            marginBottom: '4px',
        },
        description: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
            marginBottom: '24px',
        },
        container: { maxWidth: '480px' },
        subtitle: {
            fontWeight: 600,
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
    }),
);

export const ExternalProvidersAADPage: React.FC = () => {
    const classes = useStyles();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const [ loading, setLoading ] = useState(false);

    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: authenticationSetting,
        isValidating: authenticationSettingLoading,
    } = useSWR<IAuthenticationSettingResponse, Error>(
        [ authenticationSettingUrl, partitionGlobalId ],
        getAuthenticationSetting,
    );

    const handleError = useCallback(
        async error => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    return (
        <div className={classes.container}>
            <Typography className={classes.subtitle}>
                {translate({ id: 'CLIENT_AZURE_CONFIGURATION_DETAILS' })}
            </Typography>
            <Typography className={classes.description}>
                {translate(
                    { id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DESCRIPTION' },
                    {
                        guideLink: (
                            <Link
                                href={getLocalizedLink(AzureADIntegrationLink)}
                                rel="noreferrer"
                                target="_blank"
                                underline='none'
                            >
                                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_STEP_BY_STEP_GUIDE' })}
                            </Link>
                        ),
                        powershellScriptLink: (
                            <Link
                                href="https://github.com/UiPath/platform-quickstart/tree/master/azure-ad-integration"
                                rel="noreferrer"
                                target="_blank"
                                underline='none'
                            >
                                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_POWERSHELL_SCRIPT' })}
                            </Link>
                        ),
                    },
                )}
            </Typography>
            <ExternalProvidersAADForm
                loading={loading}
                setLoading={setLoading}
                authenticationSetting={authenticationSetting}
                enableProgressSymbol={authenticationSettingLoading}
                onError={handleError}
            />
        </div>
    );
};
