import { Typography } from '@mui/material';
import React from 'react';

export const Header: React.FC<{
    label: string;
    variant?: any;
    fontSize?: string;
    fontWeight?: number;
    icon?: any;
    lineHeight?: string;
    color?: string;
}> = ({
    label, variant, fontSize, fontWeight = 600, icon, lineHeight, color,
}) => {
    return (
        <Typography
            variant={variant}
            style={{
                fontSize,
                lineHeight,
                color,
                fontWeight,
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {icon && <span style={{ marginRight: '8px' }}>
                {icon}
            </span>}
            <span>
                {label}
            </span>
        </Typography>
    );
};

export default Header;
