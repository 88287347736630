import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { LicenseType } from '../../../common/constants/Constant';

const useStyles = makeStyles(theme =>
    createStyles({
        robotUsageChart: {
            marginLeft: '-24px',
            marginRight: '-24px',
            width: 'calc(100% + 24px)',
            '& .recharts-wrapper': {
                '& .recharts-surface': {
                    '& .recharts-layer, & .recharts-cartesian-axis': {
                        fontSize: '10px',
                        color: theme.palette.semantic.colorForegroundDeEmp,
                        '& .recharts-cartesian-axis-line': { display: 'none' },
                        '& .recharts-cartesian-axis-tick-line': { display: 'none' },
                        '& .recharts-text': { fill: theme.palette.semantic.colorForegroundDeEmp },
                        '& .recharts-line-dots circle': { fill: theme.palette.semantic.colorBackground },
                    },
                },
            },

            '& .robot-usage-tooltip': {
                color: theme.palette.semantic.colorForeground,
                background: theme.palette.semantic.colorBackground,
                borderRadius: '3px',
                boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.5)',
                padding: '12px 24px 12px 12px',
                fontSize: '16px',
                '& .label': {
                    margin: 0,
                    marginBottom: '8px',
                    fontWeight: 'bold',
                },
                '& .legend': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .legend-color': {
                        width: '10px',
                        height: '10px',
                        borderRadius: '5px',
                        marginRight: '8px',
                    },
                    '& .legend-text': { fontSize: '14px' },
                },
            },
        },
    }),
);

const UsageChart: React.FC<{ usage: any[]; isVisible: any }> = ({
    usage, isVisible,
}) => {
    const classes = useStyles();
    const {
        formatMessage: translate, formatNumber,
    } = useIntl();

    const getLegends = useCallback(
        payload => {
            const legends = payload.map(
                (data: { color: any; name: any; value: number | bigint }, index: string | number | undefined) => {
                    return (
                        <div
                            key={index}
                            className="legend">
                            <div
                                className="legend-color"
                                style={{ backgroundColor: data.color }} />
                            <div className="legend-text">
                                {`${translate({ id: `CLIENT_${data.name}` })}: ${formatNumber(data.value, {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                    useGrouping: false,
                                })}`}
                            </div>
                        </div>
                    );
                },
            );
            legends.reverse();
            return legends;
        },
        [ translate, formatNumber ],
    );

    const getCustomTooltip = useCallback(
        ({
            active, payload, label,
        }) => {
            if (active) {
                return (
                    <div className="robot-usage-tooltip">
                        <p className="label">
                            {translate({ id: 'CLIENT_CHART_USAGE_TRENDS' }, { 0: label })}
                        </p>
                        {getLegends(payload)}
                    </div>
                );
            }

            return null;
        },
        [ getLegends, translate ],
    );

    return (
        <div
            className={classes.robotUsageChart}
            data-cy="robot-usage-chart">
            <div style={{
                width: '100%',
                height: 250,
            }}>
                <ResponsiveContainer>
                    <LineChart
                        data={usage}
                        margin={{
                            top: 5,
                            right: 0,
                            left: 0,
                            bottom: 5,
                        }}>
                        <XAxis dataKey="date" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={getCustomTooltip} />
                        <Line
                            dataKey="AI_DOCUMENT_UNDERSTANDING"
                            stroke={LicenseType.AI_DOCUMENT_UNDERSTANDING_LEGEND_COLOR}
                            hide={!isVisible['AI_DOCUMENT_UNDERSTANDING'].selected || !isVisible['AI_DOCUMENT_UNDERSTANDING'].display}
                            activeDot={{ r: 6 }}
                        />
                        <Line
                            dataKey="AI_UNITS"
                            stroke={LicenseType.AI_UNITS_LEGEND_COLOR}
                            hide={!isVisible['AI_UNITS'].selected || !isVisible['AI_UNITS'].display}
                            activeDot={{ r: 6 }}
                        />
                        <Line
                            dataKey="ROBOT_UNITS"
                            stroke={LicenseType.ROBOT_UNITS_LEGEND_COLOR}
                            hide={!isVisible['ROBOT_UNITS'].selected || !isVisible['ROBOT_UNITS'].display}
                            activeDot={{ r: 6 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default UsageChart;
