import { IRobotAndServicesLicenses } from '../../common/interfaces/licenses';
import { ITenantAllocation } from '../../common/interfaces/tenant/tenant';
import { IEnterpriseUpgradeDto } from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import {
    get,
    post,
    put,
} from '../utility/Requests.default';

export function generateMLServiceKey(licenseType: string) {
    return post('/api/licensing/generateMLServiceKey', { body: { licenseType } });
}

export function upgradeToTrial(accountUserId: string, accountGlobalId: string, contactInfo: IEnterpriseUpgradeDto) {
    return put('/api/licensing/trial', {
        body: {
            accountUserId,
            accountGlobalId,
            contactInfo,
        },
    });
}

export function getTenantProductAllocations(accountGlobalId: string, tenantGlobalId: string) {
    return get<IRobotAndServicesLicenses>('/api/licensing/tenantProductAllocation', {
        urlParams: {
            accountGlobalId,
            tenantGlobalId,
        },
    });
}

export function getTenantAllocations(accountGlobalId: string) {
    return get<ITenantAllocation[]>('/api/licensing/tenantAllocations', { urlParams: { accountGlobalId } });
}
