import { ErrorCodeMapping } from '../../../error/src/lib/ErrorCodeMapping';

export class UiError {
    private code: string;

    constructor(code: number) {
        this.code = code.toString();
    }

    // use for static functions
    getMessage = () => {
        return ErrorCodeMapping[this.code]?.message;
    };
}
