import { CentralErrorHandlingProvider } from '@experiences/error';
import {
    ToastNotification,
    UiDialogProvider,
    UiErrorBoundary,
} from '@experiences/ui-common';
import React from 'react';
import { Router } from 'react-router-dom';

import { PortalAuthenticationProvider } from '../auth/PortalAuthenticationProvider';
import { PortalShellRefreshProvider } from '../common/hooks/useTriggerPortalShellRefresh';
import { history } from '../History.default';
import Routes from './Routes';

export default function App() {
    return (
        <CentralErrorHandlingProvider>
            <ToastNotification>
                <PortalShellRefreshProvider>
                    <Router history={history}>
                        <UiDialogProvider>
                            <PortalAuthenticationProvider>
                                <UiErrorBoundary>
                                    <Routes />
                                </UiErrorBoundary>
                            </PortalAuthenticationProvider>
                        </UiDialogProvider>
                    </Router>
                </PortalShellRefreshProvider>
            </ToastNotification>
        </CentralErrorHandlingProvider>
    );
}
