import { createStyles } from '@mui/styles';

export default createStyles({
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
    },
});
