import GlobalStyles from '@experiences/theme';
import {
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ReactComponent as MasterCardIcon } from '../images/mastercard.svg';
import { ReactComponent as VisaIcon } from '../images/visa.svg';
import { ISubscriptionForm } from '../interfaces/ecommerce';
import { StripeFormState } from '../interfaces/StripeElementClass';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '32px 24px',
            width: '482px',
        },
        sectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
        spacing: { marginBottom: '16px' },
        stripeElement: {
            height: '40px',
            padding: '10px 14px 11px 14px',
        },
        stripeElementDisabled: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            color: theme.palette.semantic.colorForegroundDisable,
        },
        stripeBox: {
            width: '100%',
            height: '100%',
        },
    }),
}));

const StripeInput = (props: any) => {
    const theme = useTheme();
    const classes = useStyles();

    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize: '16px',
                    fontFamily:
            'noto-sans, \'Noto Sans\', -apple-system, system-ui, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, sans-serif',
                    color: theme.palette.semantic.colorForeground,
                    '::placeholder': { color: theme.palette.semantic.colorForegroundDisable },
                },
                invalid: { color: theme.palette.semantic.colorErrorText },
            },
            disabled: props.disabled,
        }),
        [ theme, props.disabled ],
    );

    const onChange = useCallback(
        (event: any) => {
            if (!props.stripeElementState || !props.trigger) {
                return;
            }

            if (event.complete) {
                props.stripeElementState.setComplete(true);
                props.stripeElementState.setError(undefined);
            } else if (event.error) {
                props.stripeElementState.setError(event.error.message);
            } else if (event.empty) {
                props.stripeElementState.setError('required');
            } else if (props.setCardBrand && event.brand && (event.brand === 'visa' || event.brand === 'mastercard')) {
                props.setCardBrand(event.brand);
            }
            props.trigger();
        },
        [ props ],
    );

    return <props.component
        className={classes.stripeBox}
        options={options}
        onChange={onChange} />;
};

const EcommerceCheckoutPaymentInfo: React.FC<{
    stripeFormState: React.MutableRefObject<StripeFormState>;
    disabled: boolean;
}> = ({
    stripeFormState, disabled,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, errors, trigger,
    } = useFormContext<ISubscriptionForm>();

    const [ cardBrand, setCardBrand ] = useState('');

    return (
        <div className={classes.formContainer}>
            <div className={classes.row}>
                <Typography className={classes.sectionTitle}>
                    {translate({ id: 'CLIENT_PAYMENT_INFO' })}
                </Typography>
            </div>
            <Controller
                className={classes.spacing}
                as={TextField}
                control={control}
                variant="outlined"
                rules={{ required: true }}
                id="nameOnCard"
                label={translate({ id: 'CLIENT_NAME_ON_CARD' })}
                name="nameOnCard"
                error={!!errors.nameOnCard}
                helperText={errors.nameOnCard?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                InputProps={{ className: 'Tall' }}
                fullWidth
                disabled={disabled}
                data-cy="ecommerce-form-name-on-card"
            />

            <TextField
                className={classes.spacing}
                variant="outlined"
                label={translate({ id: 'CLIENT_CARD_NUMBER' })}
                InputProps={{
                    className: clsx(classes.stripeElement, disabled && classes.stripeElementDisabled),
                    inputComponent: StripeInput,
                    inputProps: {
                        component: CardNumberElement,
                        stripeElementState: stripeFormState.current.stripeCard,
                        setCardBrand,
                        trigger,
                        disabled,
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            {cardBrand === 'visa' && <VisaIcon />}
                            {cardBrand === 'mastercard' && <MasterCardIcon />}
                        </InputAdornment>
                    ),
                }}
                fullWidth
                disabled={disabled}
                error={!!stripeFormState.current.stripeCard.error}
                data-cy="ecommerce-form-card-number"
                helperText={
                    stripeFormState.current.stripeCard.error === 'required'
                        ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        : stripeFormState.current.stripeCard.error
                }
            />

            <div className={classes.row}>
                <TextField
                    style={{ marginRight: '12px' }}
                    variant="outlined"
                    label={translate({ id: 'CLIENT_EXPIRATION_DATE' })}
                    InputProps={{
                        className: clsx(classes.stripeElement, disabled && classes.stripeElementDisabled),
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardExpiryElement,
                            stripeElementState: stripeFormState.current.stripeExpiration,
                            trigger,
                            disabled,
                        },
                    }}
                    fullWidth
                    disabled={disabled}
                    error={!!stripeFormState.current.stripeExpiration.error}
                    data-cy="ecommerce-form-expiration-date"
                    helperText={
                        stripeFormState.current.stripeExpiration.error === 'required'
                            ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            : stripeFormState.current.stripeExpiration.error
                    }
                />
                <TextField
                    variant="outlined"
                    label={translate({ id: 'CLIENT_CVC' })}
                    InputProps={{
                        className: clsx(classes.stripeElement, disabled && classes.stripeElementDisabled),
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardCvcElement,
                            stripeElementState: stripeFormState.current.stripeCvc,
                            trigger,
                            disabled,
                        },
                    }}
                    fullWidth
                    disabled={disabled}
                    error={!!stripeFormState.current.stripeCvc.error}
                    data-cy="ecommerce-form-cvc"
                    helperText={
                        stripeFormState.current.stripeCvc.error ? (
                            stripeFormState.current.stripeCvc.error === 'required' ? (
                                translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            ) : (
                                stripeFormState.current.stripeCvc.error
                            )
                        ) : (
                            <a
                                className={classes.a}
                                href="https://www.creditcards.com/credit-card-news/credit-card-verification-numbers-security-code-1282/"
                                style={{ marginLeft: '2px' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translate({ id: 'CLIENT_WHERE_CAN_FIND' })}
                            </a>
                        )
                    }
                />
            </div>
        </div>
    );
};

export default EcommerceCheckoutPaymentInfo;
