import { merge } from 'lodash';
import { DeepPartial } from 'redux';
import urljoin from 'url-join';

import {
    AuthSettingKey,
    AuthSettingPasswordKey,
    AuthSettingUserLockOutKey,
    ShouldChangePassword,
} from '../../common/constants/AuthSettingConstant';
import {
    IAuthSettingsRestrictionsData,
    ISecurityAuthenticationSettingsData,
} from '../../common/interfaces/authSetting';
import { ISamlFormData } from '../../common/interfaces/cis/saml';
import { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import { defaultAuthSettingsRestrictionsData } from '../../component/authsettings/subcomponents/AuthSettingsRestrictionsFormComponent';
import { defaultSecurityAuthenticationSettingsData } from '../../component/authsettings/subcomponents/SecuritySettingsComponent';
import { IAuthenticationSettingPayload } from '../../services/identity/AuthenticationSettingService';
import { ISetting } from '../../services/identity/SettingService';

export function mapSettingArrayToSecurityAuthSettingsData(
    fetchedSettings: ISetting[],
): DeepPartial<ISecurityAuthenticationSettingsData> {
    const defaultExpirationDays = fetchedSettings.find(
        setting => setting.key === AuthSettingPasswordKey.DefaultExpirationDays,
    );
    const previousUseLimit = fetchedSettings.find(setting => setting.key === AuthSettingPasswordKey.PreviousUseLimit);
    const shouldChangePasswordAfterFirstLogin = fetchedSettings.find(
        setting => setting.key === AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
    );
    const userLockOut = fetchedSettings.find(setting => setting.key === AuthSettingUserLockOutKey.IsEnabled);
    const maxFailedAccessAttemptsBeforeLockout = fetchedSettings.find(
        setting => setting.key === AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
    );
    const defaultAccountLockoutSeconds = fetchedSettings.find(
        setting => setting.key === AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
    );
    const passwordComplexity = fetchedSettings.find(setting => setting.key === AuthSettingPasswordKey.PasswordComplexity);
    const parsedPasswordComplexity = passwordComplexity && JSON.parse(passwordComplexity.value);

    return merge({}, defaultSecurityAuthenticationSettingsData, {
        password: {
            defaultExpirationDays: defaultExpirationDays && parseInt(defaultExpirationDays.value),
            previousUseLimit: previousUseLimit && parseInt(previousUseLimit.value),
            shouldChangePasswordAfterFirstLogin:
        shouldChangePasswordAfterFirstLogin === undefined
            ? undefined
            : shouldChangePasswordAfterFirstLogin.value === 'true'
                ? ShouldChangePassword.Required
                : ShouldChangePassword.NotRequired,
            passwordComplexity: {
                hasSpecialCharacter: parsedPasswordComplexity?.HasSpecialCharacter,
                hasLowercase: parsedPasswordComplexity?.HasLowercase,
                hasUppercase: parsedPasswordComplexity?.HasUppercase,
                hasDigit: parsedPasswordComplexity?.HasDigit,
                Length: parsedPasswordComplexity?.Length,
            },
        },
        userLockOut: {
            isEnabled: userLockOut?.value === undefined ? undefined : userLockOut.value === 'true',
            defaultAccountLockoutSeconds: defaultAccountLockoutSeconds && parseInt(defaultAccountLockoutSeconds.value),
            maxFailedAccessAttemptsBeforeLockout:
        maxFailedAccessAttemptsBeforeLockout && parseInt(maxFailedAccessAttemptsBeforeLockout.value),
        },
    });
}

export function mapSettingArrayToAuthSettingsRestrictionsData(
    fetchedSettings: ISetting[],
): DeepPartial<IAuthSettingsRestrictionsData> {
    const restrictBasicAuthentication = fetchedSettings.find(
        setting => setting.key === AuthSettingKey.RestrictBasicAuthentication,
    );
    const enableBasicAuthenticationForHostTenant = fetchedSettings.find(
        setting => setting.key === AuthSettingKey.EnableBasicAuthenticationForHostTenant,
    );
    return merge({}, defaultAuthSettingsRestrictionsData, {
        restrictBasicAuthentication:
      restrictBasicAuthentication?.value === undefined ? undefined : restrictBasicAuthentication.value === 'true',
        enableBasicAuthenticationForHostTenant:
      enableBasicAuthenticationForHostTenant?.value === undefined
          ? undefined
          : enableBasicAuthenticationForHostTenant.value === 'true',
    });
}

export function mapSecurityAuthSettingDataToKeyValuePairs(data: ISecurityAuthenticationSettingsData): IKeyValuePair[] {
    return [
        {
            key: AuthSettingPasswordKey.DefaultExpirationDays,
            value: data.password?.defaultExpirationDays?.toString(),
        },
        {
            key: AuthSettingPasswordKey.PasswordComplexity,
            value: JSON.stringify(data.password?.passwordComplexity),
        },
        {
            key: AuthSettingPasswordKey.PreviousUseLimit,
            value: data.password?.previousUseLimit?.toString(),
        },
        {
            key: AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
            value: data.password?.shouldChangePasswordAfterFirstLogin.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
            value: data.userLockOut?.defaultAccountLockoutSeconds?.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.IsEnabled,
            value: data.userLockOut?.isEnabled?.toString(),
        },
        {
            key: AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
            value: data.userLockOut?.maxFailedAccessAttemptsBeforeLockout?.toString(),
        },
    ];
}

export function mapAuthSettingsRestrictionsDataToKeyValuePairs(data: IAuthSettingsRestrictionsData): IKeyValuePair[] {
    const { enableBasicAuthenticationForHostTenant } = data;
    const keyValuePairs = [
        {
            key: AuthSettingKey.RestrictBasicAuthentication,
            value: data.restrictBasicAuthentication?.toString(),
        },
    ];
    if (enableBasicAuthenticationForHostTenant !== undefined) {
        keyValuePairs.push({
            key: AuthSettingKey.EnableBasicAuthenticationForHostTenant,
            value: enableBasicAuthenticationForHostTenant.toString(),
        });
    }
    return keyValuePairs;
}

export function mapSamlFormDataToAuthSettingPayload(
    data: ISamlFormData,
    partitionGlobalId: string,
    externalIdentityProviderId?: number,
): IAuthenticationSettingPayload {
    const settings = {
        ...data,
        ServiceProviderEntityId: urljoin(window.location.origin, process.buildConfigs.identityBaseRoute),
    } as any;
    settings.ProvisioningSetting.AllowedDomains =
    settings.ProvisioningSetting.AllowedDomains?.split(',')
        .map((domain: string) => domain.trim())
        .filter((domain: string) => !!domain) || null;

    return {
        externalIdenptyProviderId: externalIdentityProviderId,
        clientId: data.IdentityProviderEntityId,
        type: 'Saml2',
        displayName: 'Saml2',
        partitionGlobalId,
        settings: JSON.stringify(settings),
    };
}

export function mapSettingArrayToRestrictBasicAuthentication(fetchedSettings: ISetting[] | undefined): boolean {
    const restrictBasicAuthentication = fetchedSettings?.find(
        setting => setting.key === AuthSettingKey.RestrictBasicAuthentication,
    );
    return restrictBasicAuthentication?.value === 'true';
}
