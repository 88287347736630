import { IPortalUser } from '@experiences/interfaces';
import {
    CircularProgress,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getProcessesCount } from '../../../../services/orchestrator/ProcessesService';
import { getCurrentOrchestratorUser } from '../../../../services/orchestrator/UsersService';
import { setUserProfile } from '../../../../store/action/UserProfileAction';
import {
    accountLogicalName,
    profile,
} from '../../../../store/selectors';
import { getAgoDateFromNow } from '../../../../util/DateUtil';
import { isHtml } from '../../../../util/TypeUtil';
import HomePageHeader from '../../../home/helper/HomePageHeader';
import useTenantChangeListener from '../../TenantChangeListener';

const useStyles = makeStyles(theme => ({
    widgetContainer: {
        paddingTop: '16px',
        '& .MuiButton-textPrimary': {
            color: theme.palette.semantic.colorPrimary,
            outline: 'none !important',
        },
    },
    header: {
        width: '100%',
        display: 'flex',
        padding: '15px 0 0 0;',
        '& button': { outline: 'none !important' },
    },
    selected: {
        color: theme.palette.semantic.colorPrimary,
        borderBottom: 'solid 3px',
        borderColor: theme.palette.semantic.colorPrimary,
    },
    tabLabel: { fontWeight: 'bold' },
    tabScrollButton: {
        width: '15px',
        transition: 'width 0.2s',
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    tabScrollButtonDisabled: { width: '0px' },
    totalCount: {
        padding: '10px 5px',
        fontSize: '36px',
        fontWeight: 600,
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
    noData: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: theme.palette.semantic.colorForegroundDeEmp,
    },
}));

enum TabType {
    Today,
    LastWeek,
    LastMonth,
}

enum Duration {
    Today = 1,
    LastWeek = 7,
}

export const TotalRunsComponent: React.FC = () => {
    const classes = useStyles();
    const [ countTillDate, setCountTillDate ] = React.useState(
        moment(getAgoDateFromNow(Duration.LastWeek))
            .utc()
            .format(),
    ); // Show Last week count by default
    const [ tabIndex, setIndex ] = React.useState(1);
    const [ currentTenant, setCurrentTenant ] = useState(localStorage.getItem('PORTAL_CURRENT_TENANT'));
    const logicalName = useSelector(accountLogicalName);
    const currentProfile: IPortalUser = useSelector(profile);

    const tenantChangeListener = useTenantChangeListener(setCurrentTenant);

    const processCountUrl = '/api/processes';
    let showTillDate = moment()
        .utc()
        .format();

    const {
        data: processesCount, isValidating: isValidating, error: totalRunError, mutate,
    } = useSWR<Number, Error>(
        processCountUrl + '/' + countTillDate + '/' + currentTenant,
        currentTenant
            ? ((async () => {
                if (currentProfile.currentOrchestratorUser) {
                    return getProcessesCount(
                        countTillDate,
                        currentTenant,
                        logicalName,
                        currentProfile.currentOrchestratorUser.Id,
                    );
                }
                const user = await getCurrentOrchestratorUser(currentTenant, logicalName);
                if (isHtml(user)) {
                    throw new Error();
                }
                setUserProfile({
                    ...currentProfile,
                    currentOrchestratorUser: user,
                });
                return getProcessesCount(countTillDate, currentTenant, logicalName, user.Id);

            }) as () => Promise<number>)
            : () => {
                throw new Error();
            },
    );

    const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndex(newValue);

        switch (newValue) {
            case TabType.Today:
                showTillDate = moment()
                    .subtract(Duration.Today, 'day')
                    .minute(0)
                    .format();
                break;
            case TabType.LastWeek:
                showTillDate = moment()
                    .subtract(Duration.LastWeek, 'day')
                    .minute(0)
                    .format();
                break;
            case TabType.LastMonth:
                showTillDate = moment()
                    .subtract(1, 'month')
                    .minute(0)
                    .format();
                break;
        }

        setCountTillDate(showTillDate);
        mutate();
    };

    const TabPanel: React.FC<{
        selectedTab: number | undefined;
    }> = ({ selectedTab }) => {
        switch (selectedTab) {
            case TabType.Today:
                return (
                    <div className={classes.totalCount}>
                        {isValidating && (
                            <div>
                                <CircularProgress
                                    size={36}
                                    thickness={4} />
                            </div>
                        )}
                        {!isValidating && <div data-cy="total-runs-data-today">
                            {' '}
                            {processesCount}
                        </div>}
                        {!isValidating && totalRunError && (
                            <div className={classes.noData}>
                                {translate({ id: 'CLIENT_NO_DATA' })}
                                {' '}
                            </div>
                        )}
                    </div>
                );
            case TabType.LastWeek:
                return (
                    <div className={classes.totalCount}>
                        {isValidating && (
                            <div>
                                <CircularProgress
                                    size={36}
                                    thickness={4} />
                            </div>
                        )}
                        {!isValidating && <div>
                            {' '}
                            {processesCount}
                        </div>}
                        {!isValidating && totalRunError && (
                            <div className={classes.noData}>
                                {translate({ id: 'CLIENT_NO_DATA' })}
                                {' '}
                            </div>
                        )}
                    </div>
                );
            case TabType.LastMonth:
                return (
                    <div className={classes.totalCount}>
                        {isValidating && (
                            <div>
                                <CircularProgress
                                    size={36}
                                    thickness={4} />
                            </div>
                        )}
                        {!isValidating && <div>
                            {processesCount}
                        </div>}
                        {!isValidating && totalRunError && (
                            <div className={classes.noData}>
                                {translate({ id: 'CLIENT_NO_DATA' })}
                                {' '}
                            </div>
                        )}
                    </div>
                );
            default:
                return <div />;
        }
    };
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        window.addEventListener('tenantChanged', tenantChangeListener);
        mutate();
        return function cleanup() {
            window.removeEventListener('tenantChanged', tenantChangeListener);
        };
    // eslint-disable-next-line
  }, [currentTenant]);

    return (
        <div
            className={clsx(classes.widgetContainer)}
            data-cy="home-page-total-runs">
            <HomePageHeader
                label={translate({ id: 'CLIENT_TOTAL_RUNS' })}
                fontSize="20px" />
            <div className={clsx(classes.header)}>
                <Tabs
                    value={tabIndex}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label={translate({ id: 'CLIENT_TOTAL_RUNS' })}
                    className="tiny"
                    variant="scrollable"
                    TabScrollButtonProps={{
                        classes: {
                            root: classes.tabScrollButton,
                            disabled: classes.tabScrollButtonDisabled,
                        },
                    }}
                >
                    <Tab
                        label={<Typography className={classes.tabLabel}>
                            {translate({ id: 'CLIENT_TOTAL_RUN_TODAY' })}
                        </Typography>}
                        className="tiny"
                        data-cy="home-page-total-today-tab"
                        disableRipple
                    />
                    <Tab
                        label={
                            <Typography className={classes.tabLabel}>
                                {translate({ id: 'CLIENT_TOTAL_RUN_LAST_WEEK' })}
                            </Typography>
                        }
                        className="tiny"
                        data-cy="home-page-total-lastweek-tab"
                        disableRipple
                    />
                    <Tab
                        label={
                            <Typography className={classes.tabLabel}>
                                {translate({ id: 'CLIENT_TOTAL_RUN_LAST_MONTH' })}
                            </Typography>
                        }
                        className="tiny"
                        data-cy="home-page-total-lastmonth-tab"
                        disableRipple
                    />
                </Tabs>
            </div>
            <TabPanel selectedTab={tabIndex} />
        </div>
    );
};
export default TotalRunsComponent;
