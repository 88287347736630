import { CircularProgress } from '@mui/material';
import React, {
    FC,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    authenticationSettingUrl,
    getAuthenticationSetting,
    IAuthenticationSettingResponse,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import ExternalProvidersAADForm from './ExternalProvidersAADForm';

const EditSSOWithAADComponent: FC<{
    enableProgressSymbol?: boolean;
    onError: (error: any) => void;
    onClose: () => void;
}> = ({
    enableProgressSymbol = false, onError, onClose,
}) => {
    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);
    const {
        data: authenticationSetting, isValidating: authenticationSettingLoading,
    } = useSWR<
    IAuthenticationSettingResponse,
    Error
    >([ authenticationSettingUrl, partitionGlobalId ], getAuthenticationSetting);

    return enableProgressSymbol && authenticationSettingLoading ? (
        <CircularProgress />
    ) : (
        <ExternalProvidersAADForm
            authenticationSetting={authenticationSetting}
            loading={loading}
            setLoading={setLoading}
            onError={onError}
            onClose={onClose}
            enableProgressSymbol={enableProgressSymbol || authenticationSettingLoading}
        />
    );
};

export default EditSSOWithAADComponent;
