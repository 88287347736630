import { Grid } from '@mui/material';
import React, {
    useCallback,
    useMemo,
} from 'react';

import {
    updateUserNotificationSubscriptionMode,
    updateUserNotificationTopicSubscription,
} from '../../services/notification-preferences';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import {
    INotificationSettingsReducer,
    INotificationTopic,
} from './interfaces/notificationSettings';
import NotificationChannelsComponent from './NotificationChannelsComponent';
import NotificationTopicsContainerComponent from './NotificationTopicsContainerComponent';
import { ActionTypes } from './reducers/actionTypes';

const NotificationPreferencesContainerComponent: React.FC<{
    notificationSettings: INotificationSettingsReducer;
    dispatch: any;
    restoreDefaultSubscriptions(): void;
    handleError(error: Error): void;
}> = ({
    notificationSettings, dispatch, restoreDefaultSubscriptions, handleError,
}) => {
    const { selectedTenant: { id: tenantId } } = useTenantsContext();

    const {
        currentPublisher,
        notificationTopics,
        isNotificationTopicsContainerUpdating,
        notificationChannelModes,
        publisherIndex,
    } = useMemo(
        () => {
            const currentPublisher = notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex];
            return {
                currentPublisher,
                notificationTopics: currentPublisher.topicGroups,
                isNotificationTopicsContainerUpdating: currentPublisher.isUpdating,
                notificationChannelModes: currentPublisher.modes,
                publisherIndex: currentPublisher.publisherIndex,
            };
        },
        [ notificationSettings.publishersWithGroupedTopics, notificationSettings.tabIndex ],
    );

    const changeTopicSubscription = useCallback(
        async (groupIndex: number, topicIndex: number, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                    topicIndex,
                },
            });
            const topicId = currentPublisher.topicGroups[groupIndex].topics[topicIndex].topicId;
            const topicState = {
                topicId,
                isSubscribed,
                notificationMode: currentPublisher.topicGroups[groupIndex].topics[topicIndex].modes[0].name,
            };
            try {
                await updateUserNotificationTopicSubscription([ topicState ], tenantId);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                        isSubscribed,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        topicIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, tenantId, handleError ],
    );

    const toggleAllTopicSubscription = useCallback(
        async (groupIndex: number, isSubscribed: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION,
                data: {
                    publisherIndex,
                    groupIndex,
                },
            });
            const topicStatesList = currentPublisher.topicGroups[groupIndex].topics.map((topic: INotificationTopic) => {
                return {
                    topicId: topic.topicId,
                    isSubscribed,
                    notificationMode: topic.modes[0].name,
                };
            });
            try {
                await updateUserNotificationTopicSubscription(topicStatesList, tenantId);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED,
                    data: {
                        publisherIndex,
                        groupIndex,
                        isSubscribed,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        groupIndex,
                    },
                });
            }
        },
        [ dispatch, publisherIndex, currentPublisher.topicGroups, tenantId, handleError ],
    );

    const onChangeChannelMode = useCallback(
        async (modeIndex: number, newModeStatus: boolean) => {
            dispatch({
                type: ActionTypes.NS_TOGGLE_CHANNEL_MODE,
                data: {
                    publisherIndex,
                    modeIndex,
                },
            });
            try {
                await updateUserNotificationSubscriptionMode(
                    {
                        publisherId: currentPublisher.publisherId,
                        publisherMode: {
                            name: currentPublisher.modes[modeIndex].name,
                            isActive: newModeStatus,
                        },
                    },
                    tenantId,
                );
                dispatch({
                    type: ActionTypes.NS_TOGGLE_CHANNEL_MODE_UPDATED,
                    data: {
                        publisherIndex,
                        modeIndex,
                        newModeStatus,
                    },
                });
            } catch (error) {
                handleError(error as Error);
                dispatch({
                    type: ActionTypes.NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED,
                    data: {
                        publisherIndex,
                        modeIndex,
                    },
                });
            }
        },
        [
            dispatch,
            publisherIndex,
            currentPublisher.publisherId,
            currentPublisher.modes,
            tenantId,
            handleError,
        ],
    );

    return (
        <Grid
            container
            spacing={2}>
            <Grid
                item
                xs={9}>
                <NotificationTopicsContainerComponent
                    {...{
                        notificationChannelModes,
                        notificationTopics,
                        isNotificationTopicsContainerUpdating,
                        changeTopicSubscription,
                        toggleAllTopicSubscription,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={3}>
                <NotificationChannelsComponent
                    {...{
                        notificationChannelModes,
                        onChangeChannelMode,
                        restoreDefaultSubscriptions,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default NotificationPreferencesContainerComponent;
