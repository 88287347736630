import { ISort } from '@experiences/interfaces';
import GlobalStyles, { useApolloTheme } from '@experiences/theme';
import {
    isBrowserSafari,
    useElementWidth,
} from '@experiences/util';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    Tooltip,
    useTheme,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import produce from 'immer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';
import {
    Column,
    Row,
    SortingRule,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import { AutoSizer } from 'react-virtualized';
import {
    FixedSizeList as List,
    ListChildComponentProps,
} from 'react-window';

import {
    ActionCellPaddingSize,
    ActionIconSize,
    CheckboxCellSize,
    RowHeight,
} from './constants';
import {
    ButtonType,
    GridActionType,
    IAction,
    IActionHeader,
    UiColumn,
} from './grid';
import { IndeterminateCheckbox } from './IndeterminateCheckboxComponent';
import { UiGridHeaderComponent } from './UiGridHeaderComponent';
import { UiMoreRowActionsComponent } from './UiMoreRowActionsComponent';
import { UiPaginationActionsComponent } from './UiPaginationActionsComponent';
import { UiSkeletonGridComponent } from './UiSkeletonGridComponent';
import { UiTableCell } from './UiTableCell';

declare module 'react-table' {
    interface TableInstance<D extends object = {}>
        extends UsePaginationInstanceProps<D>,
        UseRowSelectInstanceProps<D>,
        UseFiltersInstanceProps<D>,
        UseGlobalFiltersInstanceProps<D>,
        UseGroupByInstanceProps<D>,
        UseExpandedInstanceProps<D> {}
    interface TableOptions<D extends object = {}>
        extends UseFiltersOptions<D>,
        UseTableColumnOptions<D>,
        UseSortByColumnOptions<D>,
        UseGroupByOptions<D>,
        UseExpandedOptions<D>,
        UseSortByOptions<D> {}
    interface TableState<D extends object = {}>
        extends UsePaginationState<D>,
        UseGlobalFiltersState<D>,
        UseFiltersState<D>,
        UseGroupByState<D>,
        UseExpandedState<D>,
        UseSortByState<D> {}
    interface Column<D extends object = {}>
        extends Partial<UseFiltersColumnOptions<D>>,
        Partial<UseFiltersColumnProps<D>>,
        Partial<UseSortByColumnOptions<D>>,
        Partial<UseSortByColumnProps<D>>,
        Partial<UseGroupByColumnOptions<D>> {}
    interface ColumnInterface<D extends object = {}>
        extends Partial<UseFiltersColumnOptions<D>>,
        Partial<UseSortByColumnOptions<D>>,
        Partial<UseGroupByColumnOptions<D>> {}
    interface Row<D extends object = {}> extends UseRowSelectRowProps<D>, UseExpandedRowProps<D> {
        hideExpand?: boolean;
    }
    interface Cell<D extends object = {}> extends UseGroupByCellProps<D> {}
}

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        gridPaper: {
            flex: 1,
            boxShadow: 'none',
            backgroundImage: 'none',
        },
        retryActionButton: {
            marginTop: '16px',
            fontSize: '14px',
        },
        actionCell: {
            paddingRight: '16px',
            justifyContent: 'flex-end',
        },
        actionBar: { display: 'flex' },
        actionIconButton: {
            width: ActionIconSize + 'px',
            height: ActionIconSize + 'px',
            padding: '8px',
        },
        noDataRow: {
            display: 'inline-flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        noResultsRow: {
            display: 'inline-flex',
            flexDirection: 'row',
            flex: 1,
            margin: '16px',
        },
        footerRow: { borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
        scrollBarIE: { '-ms-overflow-style': 'scrollbar' },
        scrollBar: {
            scrollbarColor: `unset ${theme.palette.semantic.colorForegroundDeEmp}`,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': { width: '0.5em' },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:vertical:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        },
        selectLabel: { display: 'none' },
        sortLabel: { '&:focus': { border: `2px solid ${theme.palette.semantic.colorForegroundDeEmp}` } },
        rowToggle: {
            fontSize: '14px',
            borderRadius: 0,
            '&:focus': {
                borderRadius: '1px',
                outline: '2px solid black',
            },
        },
    }),
}));

function calculateNumberOfIconsWeCanShow(width: number, userRequested: number) {
    const maxWidthForIconsWithPadding = width * 0.2;
    const maxWidthForIcons = maxWidthForIconsWithPadding - ActionCellPaddingSize;
    const maxNumberOfIconsWeCanShow = Math.floor(maxWidthForIcons / ActionIconSize);

    const numberOfIconsToShow = Math.min(maxNumberOfIconsWeCanShow, userRequested);

    // We always want to show at least 1 icon
    return Math.max(1, numberOfIconsToShow);
}

function getPropertyByString(o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

interface IPaginationProps {
    onPageChange: (pageIndex: number, pageSize: number) => void;
    onSearch: (query?: string) => void;
    onSort: (by?: string) => void;
    total: number;
    sort: ISort;
    query: { current?: string; set: (query: string) => void };
    page: { current: number; set: (page: number) => void };
}

export interface IGridProps<T extends object> {
    tableHeight?: string;
    columns: Array<UiColumn<T>>;
    hiddenColumns?: string[];
    data: T[];
    error?: Error;
    search?: boolean;
    extraActionButtons?: IActionHeader[];
    searchPlaceholder?: string;
    SearchComponent?: JSX.Element;
    initialSort?: Array<SortingRule<T>>;
    filters?: boolean;
    groupBy?: string[];
    expandedDefault?: boolean;
    autoResetExpanded?: boolean;
    tableActions?: Array<IAction<T>>;
    rowActions?: Array<IAction<T>>;
    checkbox?: boolean;
    disableCheckboxPerRow?: (row: Row<T>) => boolean;
    pagination?: boolean;
    paginationProps?: IPaginationProps;
    refresh?: () => any;
    loading?: boolean;
    dataCy?: string;
    emptyRowsCallback?: (globalFilter: string, columnWidths: number[], actionWidth: number) => JSX.Element;
    wrapHeader?: boolean;
    zebraStyle?: boolean;
    defaultPageSize?: number;
    tableId?: string;
    getRowProps?: (row: Row<T>) => any;
}

export function UiGrid<T extends object>({
    tableHeight,
    search,
    extraActionButtons,
    searchPlaceholder,
    SearchComponent,
    initialSort,
    filters,
    groupBy,
    expandedDefault,
    autoResetExpanded,
    columns,
    hiddenColumns,
    data,
    error,
    tableActions,
    rowActions,
    checkbox,
    disableCheckboxPerRow,
    pagination,
    paginationProps,
    refresh,
    loading,
    dataCy,
    emptyRowsCallback,
    zebraStyle,
    wrapHeader,
    defaultPageSize = 25,
    tableId = 'table',
    getRowProps,
}: IGridProps<T>) {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const { themeId } = useApolloTheme();
    const { formatMessage: translate } = useIntl();
    const [ tableWidth, tableRef ] = useElementWidth();

    const requestedIconCount = useMemo(() => {
        const actionFunction = (action: IAction<T>) =>
            (action.featureFlag === undefined || action.featureFlag)
            && (action.actionType === GridActionType.Row || action.actionType === GridActionType.MainRow)
            && (action.type === ButtonType.Icon || action.type === ButtonType.LinkIcon);
        return rowActions
            ? rowActions.filter(actionFunction).length + (rowActions.filter(actionFunction).length ? 1 : 0)
            : 0;
    }, [ rowActions ]);

    const numberOfIconsToRender = useMemo(() => {
        return calculateNumberOfIconsWeCanShow(tableWidth, requestedIconCount);
    }, [ requestedIconCount, tableWidth ]);

    const actualActions = useMemo(() => {
        return produce(rowActions, (draftState: any) => {
            if (!rowActions) {
                return null;
            }

            // if the number we don't need to hide anything, return the original list
            if (numberOfIconsToRender >= requestedIconCount) {
                return draftState;
            }

            const actionsUserWantedToShow = draftState!.filter(
                (actionButton: { type: ButtonType }) =>
                    actionButton.type === ButtonType.Icon || actionButton.type === ButtonType.LinkIcon,
            );
            const numberOfIconsToHide = actionsUserWantedToShow.length - numberOfIconsToRender + 1;

            for (let index = 0; index < numberOfIconsToHide; index++) {
                actionsUserWantedToShow[actionsUserWantedToShow.length - index - 1].type = ButtonType.ButtonWithIcon;
            }
        });
    }, [ rowActions, numberOfIconsToRender, requestedIconCount ]);

    const actionWidth = useMemo(() => {
        return numberOfIconsToRender * ActionIconSize + ActionCellPaddingSize;
    }, [ numberOfIconsToRender ]);

    const columnWidths = useMemo(() => {
        return columns
            .filter(column => !hiddenColumns?.find(hc => hc === column.accessor))
            .map(
                column =>
                    ((column.width as number) / 100) * tableWidth -
          ((checkbox ? CheckboxCellSize : 0) + actionWidth) / columns.length,
            );
    }, [ columns, hiddenColumns, tableWidth, checkbox, actionWidth ]);

    const skipPageResetRef = useRef(false);
    useEffect(() => {
        skipPageResetRef.current = true;
    }, [ data ]);

    const initialState = useMemo(() => {
        const draftInitialState: { [key: string]: any } = { pageSize: defaultPageSize };

        if (hiddenColumns?.length) {
            draftInitialState.hiddenColumns = hiddenColumns;
        }

        if (initialSort?.length) {
            draftInitialState.sortBy = initialSort;
        }

        if (groupBy) {
            draftInitialState.groupBy = groupBy;

            const initExpanded: { [key: string]: boolean } = {};
            if (expandedDefault) {
                data.forEach(val => {
                    if (getPropertyByString(val, groupBy[0]) !== undefined) {
                        initExpanded[`${groupBy[0]}:${getPropertyByString(val, groupBy[0])}`] = true;
                    }
                });
                draftInitialState.expanded = initExpanded;
            }
        }

        return draftInitialState;
    }, [ defaultPageSize, hiddenColumns, initialSort, groupBy, expandedDefault, data ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        gotoPage,
        setPageSize,
        prepareRow,
        selectedFlatRows,
        state,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState,
            autoResetFilters: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetExpanded,
            disableSortBy: !!paginationProps?.sort,
            paginateExpandedRows: false,
        },
        useFilters,
        useGlobalFilter,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useFlexLayout,
        useRowSelect,
        hooks =>
            checkbox
                ? hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        groupByBoundary: true,
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox
                                data-cy="ui-grid-checkbox-toggle-all"
                                aria-label={translate({ id: 'CLIENT_TOGGLE_ALL_ROWS_SELECTED' })}
                                {...getToggleAllRowsSelectedProps()}
                                title={translate({ id: 'CLIENT_TOGGLE_ALL_ROWS_SELECTED' })}
                            />
                        ),
                        Cell: ({ row }) => (
                            <IndeterminateCheckbox
                                disabled={disableCheckboxPerRow ? disableCheckboxPerRow(row) : undefined}
                                aria-label={translate({ id: 'CLIENT_TOGGLE_ROW_SELECTED' })}
                                data-cy="ui-grid-checkbox-toggle-one"
                                {...row.getToggleRowSelectedProps()}
                                title={translate({ id: 'CLIENT_TOGGLE_ROW_SELECTED' })}
                            />
                        ),
                    },
                    ...columns,
                ])
                : undefined,
    );

    const EmptyRowsElement = useCallback(() => {
        return emptyRowsCallback != null ? (
            emptyRowsCallback(state.globalFilter, columnWidths, actionWidth)
        ) : (
            <div
                data-cy="ui-grid-no-results-row"
                className={clsx(classes.noResultsRow)}>
                <span>
                    <b>
                        {translate({ id: 'CLIENT_NO_RESULTS' })}
&nbsp;
                    </b>
                </span>
                <span>
                    {state.globalFilter}
                </span>
            </div>
        );
    }, [ columnWidths, actionWidth, emptyRowsCallback, state, translate, classes.noResultsRow ]);

    const sortLabel = useCallback((column: Column<T>, index: number) => {
        const calcIndex = checkbox ? index - 1 : index;
        return columns[calcIndex]?.sortType ? (
            <TableSortLabel
                className={classes.sortLabel}
                hideSortIcon={false}
                active={true}
                direction={column.isSortedDesc ? 'desc' : 'asc'}
                IconComponent={column.isSorted ? ArrowDropDownIcon : ArrowLeftIcon}
                data-cy="ui-grid-column-sort"
                aria-label={translate(
                    { id: 'CLIENT_TOGGLE_COLUMN_SORT' },
                    {
                        0: translate({
                            id: `CLIENT_CURRENT_SORT_${
                                !column.isSorted ? 'UNSORTED' : column.isSortedDesc ? 'DESC' : 'ASC'
                            }`,
                        }),
                    },
                )}
                onClick={() => column.toggleSortBy?.(!column.isSortedDesc, false)}
            />
        ) : columns[calcIndex]?.sortName && paginationProps?.sort ? (
            <TableSortLabel
                className={classes.sortLabel}
                hideSortIcon={false}
                active={true}
                direction={
                    columns[calcIndex]?.sortName === paginationProps.sort?.by
                        && paginationProps.sort?.order === 'desc'
                        ? 'desc'
                        : 'asc'
                }
                IconComponent={
                    columns[calcIndex]?.sortName === paginationProps.sort?.by
                        ? ArrowDropDownIcon
                        : ArrowLeftIcon
                }
                data-cy="ui-grid-column-sort"
                aria-label={translate(
                    { id: 'CLIENT_TOGGLE_COLUMN_SORT' },
                    {
                        0: translate({
                            id: `CLIENT_CURRENT_SORT_${
                                columns[calcIndex]?.sortName !== paginationProps.sort?.by
                                    ? 'UNSORTED'
                                    : columns[calcIndex]?.sortName
                                        === paginationProps.sort?.by
                                        && paginationProps.sort?.order === 'desc'
                                        ? 'DESC'
                                        : 'ASC'
                            }`,
                        }),
                    },
                )}
                onClick={() => {
                    paginationProps.onSort(columns[calcIndex].sortName);
                }}
            />
        ) : null;
    }, [ checkbox, classes.sortLabel, columns, paginationProps, translate ]);

    const generateHeaderRow = useCallback(() => {
        return headerGroups.map((headerGroup, i) => (
            <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={i}
                component="div"
                data-cy="ui-grid-header-row">
                {headerGroup.headers.map((column, j) => {
                    const headerResult = column.render('Header');

                    return (
                        <UiTableCell
                            {...column.getHeaderProps(column.getSortByToggleProps?.())}
                            rowIndex={j}
                            key={j}
                            columnWidth={columnWidths[!checkbox ? j : j - 1]}
                            checkbox={checkbox}
                            wrap={wrapHeader}
                            justifyContent={columns[j]?.justifyContent === 'center' ? 'center' : undefined}
                            ariaHidden={typeof headerResult !== 'string'}
                            tabIndex={typeof headerResult !== 'string' ? -1 : 0}
                        >
                            <div>
                                {headerResult}
                                {sortLabel(column, j)}
                            </div>
                        </UiTableCell>
                    );
                })}
                {!!rowActions && actionWidth && <UiTableCell
                    ariaHidden={true}
                    justifyContent="flex-end"
                    columnWidth={actionWidth}
                    tabIndex={-1} />}
            </TableRow>
        ));
    }, [ actionWidth, checkbox, columnWidths, columns, headerGroups, rowActions, sortLabel, wrapHeader ]);

    const generateActions = useCallback((row: Row<T>, index: number) => {
        return !!rowActions && actionWidth ? (
            <UiTableCell
                justifyContent="flex-end"
                columnWidth={actionWidth}
                key={`action-${index}`}>
                <div className={clsx(classes.actionBar)}>
                    {actualActions?.map((action, k) =>
                        (action.type === ButtonType.Icon || action.type === ButtonType.LinkIcon)
                            && (action.invisible !== undefined && typeof action.invisible === 'function'
                                ? !action.invisible(row)
                                : !action.invisible) ? (
                                <Tooltip
                                    key={k}
                                    title={
                                        action.tooltip
                                            ? typeof action.tooltip === 'function'
                                                ? action.tooltip(row)
                                                : action.tooltip
                                            : ''
                                    }
                                >
                                    <span>
                                        {action.type === ButtonType.Icon && (
                                            <IconButton
                                                className={clsx(classes.actionIconButton, classes.icon, classes.svgIcon)}
                                                disabled={
                                                    action.disable && typeof action.disable === 'function'
                                                        ? action.disable(row)
                                                        : false
                                                }
                                                onClick={() => action.click?.(row)}
                                                data-cy={
                                                    (typeof action.dataCy === 'function'
                                                        ? action.dataCy(row)
                                                        : action.dataCy)
                                                    || 'ui-grid-action-row-button'
                                                }
                                                aria-label={
                                                    action.ariaLabel
                                                    || (typeof action.label === 'string' ? action.label : 'row action')
                                                }
                                            >
                                                {action.icon}
                                            </IconButton>
                                        )}
                                        {action.type === ButtonType.LinkIcon && (
                                            <>
                                                {typeof action.icon === 'function'
                                                    ? action.icon(row, {
                                                        className: clsx(
                                                            classes.actionIconButton,
                                                            classes.icon,
                                                            classes.svgIcon,
                                                        ),
                                                        disabled:
                                                            action.disable && typeof action.disable === 'function'
                                                                ? action.disable(row)
                                                                : false,
                                                    })
                                                    : action.icon}
                                            </>
                                        )}
                                    </span>
                                </Tooltip>
                            ) : null,
                    )}
                    {actualActions?.filter(
                        action =>
                            action.type !== ButtonType.Icon
                                && action.type !== ButtonType.LinkIcon
                                && (action.invisible !== undefined && typeof action.invisible === 'function'
                                    ? !action.invisible(row)
                                    : !action.invisible),
                    ).length ? (
                            <UiMoreRowActionsComponent<T>
                                row={row}
                                actions={actualActions.filter(
                                    actions =>
                                        actions.type !== ButtonType.Icon
                                            && actions.type !== ButtonType.LinkIcon,
                                )}
                            />
                        ) : null}
                </div>
            </UiTableCell>
        ) : null;
    }, [
        actionWidth,
        actualActions,
        classes.actionBar,
        classes.actionIconButton,
        classes.icon,
        classes.svgIcon,
        rowActions,
    ]);

    const generateBodyRow = useCallback(() => {
        return ({
            index, style,
        }: ListChildComponentProps) => {
            const row = page[index];
            prepareRow(row);
            if (groupBy) {
                style.backgroundColor = row?.subRows?.length
                    ? theme.palette.semantic.colorBackground
                    : themeId === 'light'
                        ? Tokens.Colors.ColorGray100
                        : Tokens.Colors.ColorBackgroundDisabledDark;
            } else if (zebraStyle) {
                style.backgroundColor = index % 2 === 1
                    ? theme.palette.semantic.colorForegroundInverse
                    : theme.palette.semantic.colorBackground;
            }

            const rowProps = getRowProps?.(row);

            return (
                <TableRow
                    data-cy="ui-grid-data-row"
                    component="div"
                    {...row.getRowProps({ style })}>
                    {row.cells.map((cell, j) => (
                        <UiTableCell
                            rowIndex={j}
                            key={j}
                            columnWidth={columnWidths[!checkbox ? j : j - 1]}
                            checkbox={checkbox}
                            justifyContent={columns[checkbox ? j - 1 : j]?.justifyContent}
                        >
                            {cell.isGrouped ? (
                                rowProps?.hideExpand ? (
                                    <span
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            marginLeft: '25px',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </span>
                                ) : (
                                    <>
                                        <IconButton
                                            classes={{ root: classes.rowToggle }}
                                            disableRipple
                                            {...row.getToggleRowExpandedProps({
                                                style: {
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                },
                                            })}
                                        >
                                            {row.isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                                            {cell.render('Cell')}
                                        </IconButton>
                                    </>
                                )
                            ) : cell.isAggregated ? (
                                cell.render('Aggregated')
                            ) : cell.isPlaceholder ? null : (
                                cell.render('Cell')
                            )}
                        </UiTableCell>
                    ))}
                    {generateActions(row, index)}
                </TableRow>
            );
        };
    }, [
        checkbox,
        classes.rowToggle,
        columnWidths,
        columns,
        generateActions,
        getRowProps,
        groupBy,
        page,
        prepareRow,
        theme.palette.semantic.colorBackground,
        theme.palette.semantic.colorForegroundInverse,
        themeId,
        zebraStyle,
    ]);

    return (
        <TableContainer
            component={Paper}
            className={clsx(classes.gridPaper)}
            data-cy={dataCy}>
            <UiGridHeaderComponent
                search={search}
                extraActionButtons={extraActionButtons}
                searchPlaceholder={searchPlaceholder}
                SearchComponent={SearchComponent}
                filters={filters}
                filterQueries={state.filters}
                tableActions={tableActions}
                refresh={refresh}
                loading={loading}
                headerGroups={headerGroups}
                rows={rows}
                selectedFlatRows={selectedFlatRows.filter(row => (disableCheckboxPerRow ? !disableCheckboxPerRow(row) : true))}
                setGlobalFilter={setGlobalFilter}
                paginationProps={{
                    query: paginationProps?.query,
                    onSearch: paginationProps?.onSearch,
                }}
                state={state}
            />
            <Table
                component="div"
                ref={tableRef}
                {...getTableProps({ style: { height: tableHeight ?? '85%' } })}
                data-cy-loading={loading}
            >
                <TableHead component="div">
                    {generateHeaderRow()}
                </TableHead>
                <TableBody
                    component="div"
                    {...getTableBodyProps({
                        style: {
                            flex: 1,
                            overflow: 'hidden',
                        },
                    })}>
                    <AutoSizer
                        style={
                            isBrowserSafari()
                                ? {
                                    position: 'relative',
                                    height: RowHeight * 9 + 'px',
                                    width: 'auto',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }
                                : undefined
                        }
                    >
                        {({
                            height, width,
                        }) =>
                            !page.length && loading ? (
                                [ ...Array(state.pageSize) ].map((_, emptyIndex) => (
                                    <UiSkeletonGridComponent
                                        key={emptyIndex}
                                        columnWidths={columnWidths}
                                        rowHeight={RowHeight}
                                        position={emptyIndex}
                                        actionWidth={actionWidth}
                                        checkbox={checkbox}
                                    />
                                ))
                            ) : page.length || emptyRowsCallback ? (
                                <div
                                    className={classes.scrollBar}
                                    style={{
                                        overflow: 'hidden auto',
                                        height,
                                        width: !isBrowserSafari() ? width : 'auto',
                                    }}
                                >
                                    {state.globalFilter && !page.length && emptyRowsCallback != null && <EmptyRowsElement />}
                                    <List
                                        height={RowHeight * page.length}
                                        width={!isBrowserSafari() ? width : 'auto'}
                                        itemCount={page.length}
                                        itemSize={RowHeight}
                                        itemData={{ columns: columns }}
                                        style={{
                                            ...{
                                                overflowX: 'hidden',
                                                overflowY: 'visible',
                                            },
                                            ...(isBrowserSafari() && { willChange: 'unset !important' }),
                                        }}
                                    >
                                        {generateBodyRow()}
                                    </List>
                                </div>
                            ) : error ? (
                                <div
                                    data-cy="ui-grid-no-data-row"
                                    className={clsx(classes.noDataRow)}
                                    style={{
                                        width,
                                        height,
                                    }}
                                >
                                    <ErrorIcon />
                                    <span>
                                        {translate({ id: 'CLIENT_TABLE_ERROR' })}
                                    </span>
                                    {refresh ? (
                                        <Button
                                            className={clsx(classes.retryActionButton)}
                                            variant="contained"
                                            startIcon={<RefreshIcon />}
                                            disabled={loading}
                                            onClick={refresh}
                                            data-cy="ui-grid-error-refresh-button"
                                        >
                                            {translate({ id: 'CLIENT_RETRY' })}
                                        </Button>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    data-cy="ui-grid-no-data-row"
                                    className={clsx(classes.noDataRow)}
                                    style={{
                                        width,
                                        height,
                                    }}
                                    role="row"
                                >
                                    <VisibilityOffIcon />
                                    <span role="cell">
                                        {translate({ id: 'CLIENT_NO_DATA' })}
                                    </span>
                                </div>
                            )}
                    </AutoSizer>
                </TableBody>
                {pagination || paginationProps ? (
                    <TableFooter
                        component="div"
                        style={{ justifyContent: 'flex-end' }}>
                        <TableRow
                            className={clsx(classes.footerRow)}
                            component="div">
                            <TablePagination
                                role="cell"
                                tabIndex={0}
                                aria-label={translate({ id: 'CLIENT_TABLE_FOOTER' })}
                                aria-flowto={`${tableId}PaginationButtons`}
                                component="div"
                                style={{ flex: 1 }}
                                classes={{ selectLabel: classes.selectLabel }}
                                rowsPerPageOptions={[ 5, 10, 25, 50 ]}
                                count={paginationProps ? paginationProps.total : rows.length}
                                rowsPerPage={state.pageSize}
                                page={paginationProps ? paginationProps.page.current : state.pageIndex}
                                onPageChange={(_, newPage) => {
                                    if (paginationProps) {
                                        paginationProps.page.set(newPage);
                                        paginationProps.onPageChange(newPage, state.pageSize);
                                    } else {
                                        gotoPage(newPage);
                                    }
                                }}
                                onRowsPerPageChange={event => {
                                    setPageSize(parseInt(event.target.value));
                                    if (paginationProps) {
                                        paginationProps.page.set(0);
                                        paginationProps.onPageChange(0, parseInt(event.target.value));
                                    } else {
                                        gotoPage(0);
                                    }
                                }}
                                ActionsComponent={(paginationProps: TablePaginationActionsProps) => (
                                    <UiPaginationActionsComponent
                                        {...paginationProps}
                                        tableId={tableId} />
                                )}
                                SelectProps={{
                                    'aria-label': translate({ id: 'CLIENT_PAGE_SIZE_MENU_WITH_VALUE' }, { 0: state.pageSize }),
                                    tabIndex: 0,
                                    id: `${tableId}PageSizeSelect`,
                                }}
                                data-cy="ui-grid-pagination-bar"
                                labelDisplayedRows={({
                                    from, to, count,
                                }) =>
                                    translate({ id: 'CLIENT_TABLE_ITEMS_FROM_TO_OF' }, {
                                        fromItem: from,
                                        toItem: to,
                                        totalItems: count,
                                    })}
                            />
                        </TableRow>
                    </TableFooter>
                ) : null}
            </Table>
        </TableContainer>
    );
}
