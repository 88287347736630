import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import {
    Breadcrumbs,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { ISAMLRule } from '../../../common/interfaces/cis/saml';
import {
    deleteRule,
    getRules,
    ruleUrl,
    updateRule,
} from '../../../services/identity/RuleService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { decodeSanitizedHtml } from '../../../util/DecodeSanitizedHtml';
import { UiGrid } from '../../common/UiGrid';
import {
    ButtonType,
    GridActionType,
    IActionHeader,
} from '../../common/UiGrid/grid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../../common/UiPanel/UiPanel';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';

const useStyles = makeStyles((theme) =>
    createStyles({
        breadcrumbText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        header: {
            paddingTop: '24px',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    }),
);

const MAXIMUM_PROVISIONING_RULES = 1000;

const SAMLProvisioningRulesComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const history = useHistory();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const organizationName = useOrganizationName();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const createDialog = useShowDialog();

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const {
        data: rules, isValidating: loading, error, mutate,
    } = useSWR<ISAMLRule[], Error>(
        [ ruleUrl, partitionGlobalId ],
        getRules,
    );

    const updateRuleStatus = useCallback(async (row: Row<ISAMLRule>, status: boolean) => {
        const payload = {
            ...row.original,
            ruleId: row.original.id,
            partitionGlobalId,
            enabled: status,
        };

        try {
            await updateRule(payload);
            createNotification(
                translate({
                    id: status
                        ? 'CLIENT_SAML_PROVISIONING_RULES_ENALBED_SUCCESSFULLY'
                        : 'CLIENT_SAML_PROVISIONING_RULES_DISABLED_SUCCESSFULLY',
                }),
            );
            mutate();
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ createNotification, getErrorMessage, mutate, partitionGlobalId, setErrorMessage, translate ]);

    const openDeleteDialog = useCallback(
        async (rows: Array<Row<ISAMLRule>> | Row<ISAMLRule>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DELETE_DIALOG_TITLE' }),
                body: (
                    <Typography>
                        {translate(
                            { id: 'CLIENT_SAML_PROVISIONING_RULES_DELETE_DIALOG_BODY' },
                            {
                                ruleName: <b>
                                    {Array.isArray(rows)
                                        ? rows.map(row => row.original.name).join(', ')
                                        : rows.original.name}
                                </b>,
                            },
                        )}
                    </Typography>
                ),
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });

            if (proceed) {
                try {
                    if (!Array.isArray(rows)) {
                        await deleteRule(partitionGlobalId, rows.original.id!);
                    }
                    createNotification(
                        translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DELETED_SUCCESSFULLY' }),
                    );
                    mutate();
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                }
            }
        }, [ createDialog, createNotification, getErrorMessage, mutate, partitionGlobalId, setErrorMessage, translate ]);

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_ADD_RULE' }),
            icon: <AddIcon />,
            click: () => history.push(`${getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules)}/add`),
            disable: (rules?.length ?? 0) >= MAXIMUM_PROVISIONING_RULES,
            toolTip: (rules?.length ?? 0) >= MAXIMUM_PROVISIONING_RULES
                ? translate({ id: 'CLIENT_SAML_JIT_PROVISIONING_RULES_MAXIMUM' }, { max: MAXIMUM_PROVISIONING_RULES })
                : '',
            invisible: !isAdmin,
            variant: 'contained',
            dataCy: 'ui-grid-add-provisioning-rule-button',
        },
    ], [ getRoute, history, isAdmin, rules, translate ]);

    const mainContent = useMemo(() => {
        return (
            <UiGrid<ISAMLRule>
                dataCy="saml-provisioning-rules-ui-grid"
                search={true}
                loading={loading}
                error={error}
                searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                extraActionButtons={actions}
                pagination={true}
                columns={[
                    {
                        accessor: 'name',
                        sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_NAME' }),
                        Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_NAME' }),
                        width: 40,
                        sortType: 'alphanumeric',
                        Cell: ({ row }) => decodeSanitizedHtml(row.original.name),
                    },
                    {
                        accessor: 'description',
                        sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DESCRIPTION' }),
                        Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_DESCRIPTION' }),
                        width: 40,
                        sortType: 'alphanumeric',
                        Cell: ({ row }) => decodeSanitizedHtml(row.original.description),
                    },
                    {
                        accessor: 'enabled',
                        sortName: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_STATUS' }),
                        Header: translate({ id: 'CLIENT_SAML_PROVISIONING_RULES_STATUS' }),
                        width: 20,
                        sortType: 'alphanumeric',
                        Cell: ({ row }) => translate({ id: row.original.enabled ? 'CLIENT_ENABLED' : 'CLIENT_DISABLED' }),
                    },
                ]}
                data={rules ?? []}
                rowActions={
                    isAdmin
                        ? [
                            {
                                type: ButtonType.Button,
                                label: translate({ id: 'CLIENT_EDIT' }),
                                tooltip: translate({ id: 'CLIENT_EDIT' }),
                                actionType: GridActionType.Row,
                                click: row => history.push(`${getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules)}/edit/${row.original.id}`),
                                dataCy: 'jit-rule-edit',
                            },
                            {
                                type: ButtonType.Button,
                                label: translate({ id: 'CLIENT_ENABLE' }),
                                tooltip: translate({ id: 'CLIENT_ENABLE' }),
                                actionType: GridActionType.Row,
                                invisible: row => row!.original.enabled,
                                click: row => updateRuleStatus(row, !row!.original.enabled),
                                dataCy: 'jit-rule-enable',
                            },
                            {
                                type: ButtonType.Button,
                                label: translate({ id: 'CLIENT_DISABLE' }),
                                tooltip: translate({ id: 'CLIENT_DISABLE' }),
                                actionType: GridActionType.Row,
                                invisible: row => !row!.original.enabled,
                                click: row => updateRuleStatus(row, !row!.original.enabled),
                                dataCy: 'jit-rule-disable',
                            },
                            {
                                type: ButtonType.Button,
                                label: translate({ id: 'CLIENT_DELETE' }),
                                tooltip: translate({ id: 'CLIENT_DELETE' }),
                                actionType: GridActionType.Row,
                                click: openDeleteDialog,
                                dataCy: 'jit-rule-delete',
                            },
                        ]
                        : []
                }
            />
        );
    }, [ actions, error, getRoute, history, isAdmin, loading, openDeleteDialog, rules, translate, updateRuleStatus ]);

    const breadCrumbLinks = useMemo(() =>
        [
            {
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                link: RouteNames.SecuritySettings,
                name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
            },
            {
                link: RouteNames.SecuritySettingsSAMLProvisioningRules,
                name: translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' }),
            },
        ],
    [ organizationName, translate ]);

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
            >
                {mainContent}
            </UiPageContainer>
            :
            <UiPanel
                header={{
                    title: (
                        <Breadcrumbs separator=">">
                            <Link
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                to={getRoute(RouteNames.SecuritySettings)}
                            >
                                {translate({ id: 'CLIENT_AUTHENTICATION' })}
                            </Link>
                            <span
                                className={classes.breadcrumbText}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: 'CLIENT_PAGE_SAML_PROVISIONING_RULES' })}
                            </span>
                        </Breadcrumbs>
                    ),
                }}
            >
                {mainContent}
            </UiPanel>
    );
};

export default SAMLProvisioningRulesComponent;
