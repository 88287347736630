import './index.css';

import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { initializeTelemetryPortal } from '@experiences/telemetry';
import { UiErrorBoundary } from '@experiences/ui-common';
import React from 'react';
import ReactDOM from 'react-dom';

import checkHtmlJSMismatch from './common/htmlJSMismatchCheck';
import AppWrapper from './container/AppWrapper.default';
import { registerSmartling } from './register-smartling';
// @ts-ignore
import * as serviceWorker from './serviceWorker';

console.log('CLOUDRPA_VERSION', window.env.CLOUDRPA_VERSION);

checkHtmlJSMismatch();
document.title = 'Sign Up';

initializeTelemetryPortal();
registerSmartling();

if (
    getFeatureFlagValue(Features.EnableEcommerceIntegration.name) ||
  getFeatureFlagValue(Features.EnableDnBForms.name)
) {
    if ((window as any).ActiveXObject) {
        (window as any).ActiveXObject = null;
    }

    const script = document.createElement('script');
    script.src = '//cdn-0.d41.co/tags/ff-2.min.js';
    script.type = 'text/javascript';
    script.onload = function() {
        (window as any).DnBLeadForm = (window as any).Fill.LeadFormApp;
    };
    document.getElementsByTagName('body')[0].appendChild(script);
}

ReactDOM.render(
    <UiErrorBoundary>
        <AppWrapper />
    </UiErrorBoundary>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
