import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiPathLogo } from '@experiences/images';
import { useLocalization } from '@experiences/locales';
import GlobalStyles from '@experiences/theme';
import {
    loadOrganizationApp,
    UiStorage,
    useAuthContext,
    useInterval,
    useModalState,
    useRouteResolver,
} from '@experiences/util';
import {
    Button,
    Dialog,
    Slide,
    Typography,
    useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useHistory } from 'react-router';

import {
    DIRECT_BUY_ORDER_CONFIRMATION,
    getPriceString,
    getTaxPercentageString,
    useIsSignupDirectBuyFlow,
} from './helpers/EcommerceHelpers';
import {
    BuyEnterprise,
    BuyEnterpriseSuccess,
} from './helpers/EcommerceRoutes';
import {
    ICheckOrganizationResponse,
    IEcommerceSuccess,
    IOrderConfirmation,
} from './interfaces/ecommerce';
import { isOrganizationReady } from './services/BillingService';
import { EcommerceDiscountedPrice } from './subcomponents/EcommerceDiscountedPrice';
import { EcommercePrice } from './subcomponents/EcommercePrice';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        dialog: { padding: '0px' },
        backBar: {
            height: '76px',
            width: '100%',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: theme.palette.semantic.colorBackground,
            justifyContent: 'space-between',
            padding: '22px',
        },
        backLink: {
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
        },
        title: {
            width: '530px',
            height: '24px',
            marginTop: '29px',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        directBuyTitle: {
            width: '530px',
            height: '72px',
            marginTop: '29px',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
            whiteSpace: 'pre-wrap',
        },
        receipt: {
            marginTop: '8px',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        orderConfirmationBody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        goToAutomationCloud: { marginTop: '16px' },
        orderDetails: {
            marginTop: '36px',
            width: '529px',
            background: theme.palette.semantic.colorBackground,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxSizing: 'border-box',
            borderRadius: '3px',
            padding: '24px 36px 28px 36px',
        },
        detailsTitle: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            color: theme.palette.semantic.colorForeground,
        },
        paymentDetails: {
            display: 'flex',
            marginTop: '32px',
            justifyContent: 'space-between',
        },
        paymentColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        paymentColumnTitle: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        paymentColumnValue: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        paymentSummary: {
            margin: '22px 0 8px',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        productsTable: {
            background: theme.palette.semantic.colorBackground,
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxSizing: 'border-box',
            borderRadius: '3px',
            padding: '24px 26px 20px 24px',
        },
        contact: {
            marginTop: '16px',
            textAlign: 'center',
        },
        contactText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        lineItem: {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        productListContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        productListColumnAlignedToLeft: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        productListColumnAlignedToRight: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        productListColumnTitle: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '12px',
        },
        productListColumnValue: { marginBottom: '8px' },
        textValue: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        subtotal: {
            display: 'flex',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            justifyContent: 'space-between',
            marginTop: '8px',
            paddingTop: '16px',
        },
        tax: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        amountPaid: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
        amountPaidValues: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
        },
        logo: { '& .iconOrange': { fill: theme.palette.semantic.colorForegroundHigh } },
    }),
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide
        direction="up"
        ref={ref}
        {...props} />;
});

export const EcommerceOrderConfirmationComponent: React.FC<{
    location?: { state?: { orderConfirmation: IOrderConfirmation } };
}> = ({ location }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);

    const { formatMessage: translate } = useIntl();
    const {
        open, close: modalStateClose,
    } = useModalState(BuyEnterprise);
    const orderConfirmation = useMemo(() => {
        const localStorageValue = UiStorage.getItem(DIRECT_BUY_ORDER_CONFIRMATION);
        if (localStorageValue) {
            return JSON.parse(localStorageValue) as IOrderConfirmation;
        }
        return location?.state?.orderConfirmation!;
    }, [ location ]);
    const {
        invoiceData, products,
    } = orderConfirmation;
    const language = useLocalization();

    const close = () => {
        UiStorage.removeItem(DIRECT_BUY_ORDER_CONFIRMATION);
        modalStateClose();
    };

    useEffect(() => {
        localStorage.removeItem('marketoData');
    }, []);

    const { token } = useAuthContext();
    const [ isOrganizationReadyResult, setIsOrganizationReadyResult ] = useState<ICheckOrganizationResponse | null>(null);

    const checkIfOrganizationIsReady = useCallback(async () => {
        const result = await isOrganizationReady(orderConfirmation.organizationId!, token);
        setIsOrganizationReadyResult(result);
    }, [ token, orderConfirmation ]);

    useInterval(
        () => checkIfOrganizationIsReady(),
        isSignupDirectBuyFlow && !isOrganizationReadyResult?.ready ? 1000 : null,
    );

    const shouldDisplayDiscount = useMemo(() => {
        return !!(EnableEcommercePromotionalDiscounts && invoiceData?.discount);
    }, [ EnableEcommercePromotionalDiscounts, invoiceData ]);

    const getInvoiceSubtotal = useCallback(() => {
        if (invoiceData) {
            return shouldDisplayDiscount ? (
                <EcommerceDiscountedPrice
                    fullPrice={invoiceData.subtotal}
                    discountedPrice={invoiceData.discount?.discountedPrice}
                    discountDetails={invoiceData.discount}
                    loading={false}
                    currency={invoiceData.currency}
                    dataCy="order-confirmation-full-price"
                />
            ) : (
                <EcommercePrice
                    value={invoiceData.subtotal}
                    loading={false}
                    gap={true}
                    currency={invoiceData.currency}
                    dataCy="order-confirmation-price"
                />
            );
        }
    }, [ invoiceData, shouldDisplayDiscount ]);

    return (
        <Dialog
            className={classes.dialog}
            fullScreen
            open={open}
            onClose={() => close()}
            TransitionComponent={Transition}
            data-cy="online-confirmation-dialog"
            PaperProps={{
                style: {
                    padding: '0px',
                    backgroundColor: theme.palette.semantic.colorBackground,
                },
            }}
        >
            <div className={classes.backBar}>
                <UiPathLogo className={classes.logo} />
            </div>
            <div className={classes.orderConfirmationBody}>
                {isSignupDirectBuyFlow ? (
                    <Typography className={classes.directBuyTitle}>
                        {translate({ id: 'CLIENT_DIRECT_BUY_PURCHASE_SUCCESS' })}
                    </Typography>
                ) : (
                    <Typography className={classes.title}>
                        {translate({ id: 'CLIENT_PURCHASE_SUCCESS' })}
                    </Typography>
                )}
                <Typography className={classes.receipt}>
                    {translate({ id: 'CLIENT_A_COPY_OF_THE_RECEIPT' }, { 0: invoiceData?.email })}
                </Typography>
                <Button
                    className={classes.goToAutomationCloud}
                    variant="contained"
                    disabled={isSignupDirectBuyFlow && !isOrganizationReadyResult?.ready}
                    onClick={() => {
                        if (isSignupDirectBuyFlow) {
                            loadOrganizationApp(isOrganizationReadyResult!.organizationName!, orderConfirmation.organizationId!,
                                { firstLogin: true });
                        } else {
                            if (orderConfirmation.invoiceData?.proration) {
                                const successState = {
                                    ecommerceSuccess:
                                  {
                                      title: translate({ id: 'CLIENT_PRORATED_PURCHASE_SUCCESS' }),
                                      line1: translate({ id: 'CLIENT_PRORATED_NEW_LICENSES_RELEASED' }),
                                      button: 'OK',
                                  } as IEcommerceSuccess,
                                };
                                history.push(getRoute(BuyEnterpriseSuccess, orderConfirmation.accountName), successState);
                            } else {
                                history.push(getRoute(BuyEnterpriseSuccess, orderConfirmation.accountName));
                            }
                        }
                    }}
                    data-cy="go-to-automation-cloud"
                >
                    {translate({ id: 'CLIENT_GO_TO_AUTOMATION_CLOUD' })}
                </Button>
                <div className={classes.orderDetails}>
                    <Typography className={classes.detailsTitle}>
                        {translate({ id: 'CLIENT_ORDER_CONFIRMATION' })}
                    </Typography>
                    <div className={classes.paymentDetails}>
                        <div
                            data-cy="order-confirmation-total"
                            className={classes.paymentColumn}>
                            <Typography className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_AMOUNT_PAID' })}
                            </Typography>
                            <Typography className={classes.paymentColumnValue}>
                                {invoiceData && getPriceString(invoiceData.total, invoiceData.currency, language)}
                            </Typography>
                        </div>
                        <div className={classes.paymentColumn}>
                            <Typography className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_DATE_PAID' })}
                            </Typography>
                            <Typography className={classes.paymentColumnValue}>
                                {invoiceData && (
                                    <FormattedDate
                                        value={invoiceData.created}
                                        year="numeric"
                                        month="short"
                                        day="numeric" />
                                )}
                            </Typography>
                        </div>
                        <div
                            data-cy="order-confirmation-card-details"
                            className={classes.paymentColumn}>
                            <Typography className={classes.paymentColumnTitle}>
                                {translate({ id: 'CLIENT_PAYMENT_METHOD' })}
                            </Typography>
                            <Typography className={classes.paymentColumnValue}>
                                {translate(
                                    { id: 'CLIENT_CREDIT_CARD_ENDING_IN' },
                                    {
                                        0: translate({ id: `CLIENT_CREDIT_CARD_${invoiceData?.cardBrand.toUpperCase()}` }),
                                        1: invoiceData?.cardLast4,
                                    },
                                )}
                            </Typography>
                        </div>
                    </div>
                    <Typography className={classes.paymentSummary}>
                        {translate({ id: 'CLIENT_SUMMARY' })}
                    </Typography>
                    <div className={classes.productsTable}>
                        <div
                            data-cy="order-confirmation-products-and-subtotal"
                            className={classes.productListContainer}>

                            <div
                                className={classes.productListColumnAlignedToLeft}
                                data-cy="order-confirmation-products-code-column">
                                <Typography className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_NAME' })}
                                </Typography>
                                {products?.map((product, index) => (
                                    <Typography
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        key={index}
                                        data-cy={`order-confirmation-product-name-${product.code}`}
                                    >
                                        {translate({ id: `CLIENT_PRODUCT_${product.code}` })}
                                        {orderConfirmation?.invoiceData?.proration && (' ' + translate({ id: 'CLIENT_PRORATED' }))}
                                    </Typography>
                                ))}
                            </div>

                            <div
                                className={classes.productListColumnAlignedToRight}
                                data-cy="order-confirmation-products-quantity-column">
                                <Typography className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_QUANTITY' })}
                                </Typography>
                                {products?.map((product, index) => (
                                    <Typography
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        data-cy={`order-confirmation-product-quantity-${product.code}`}
                                        key={index}>
                                        {product.quantity}
                                    </Typography>
                                ))}
                            </div>

                            <div
                                className={classes.productListColumnAlignedToRight}
                                data-cy="order-confirmation-products-total-price-column">
                                <Typography className={classes.productListColumnTitle}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_ORDER_CONFIRMATION_PRODUCT_TOTAL_PRICE' })}
                                </Typography>
                                {products?.map((product, index) => (
                                    <Typography
                                        className={clsx(classes.productListColumnValue, classes.textValue)}
                                        key={index}
                                        data-cy={`order-confirmation-product-price-${product.code}`}
                                    >
                                        {getPriceString(product.totalPrice, product.currency, language)}
                                    </Typography>
                                ))}
                            </div>
                        </div>
                        <div
                            data-cy="order-confirmation-subtotal"
                            className={classes.subtotal}>
                            <Typography className={classes.textValue}>
                                {translate({ id: 'CLIENT_SUBTOTAL' })}
                            </Typography>
                            {invoiceData && getInvoiceSubtotal()}
                        </div>
                        <div
                            data-cy="order-confirmation-tax"
                            className={classes.tax}>
                            <Typography className={classes.textValue}>
                                {invoiceData &&
                  `${translate({ id: 'CLIENT_TAX_TITLE' })}  ${getTaxPercentageString(invoiceData.taxRate, language)}`}
                            </Typography>
                            <Typography className={classes.textValue}>
                                {invoiceData && getPriceString(invoiceData.taxValue, invoiceData.currency, language)}
                            </Typography>
                        </div>
                        <div
                            data-cy="order-confirmation-amount-paid"
                            className={classes.amountPaid}>
                            <Typography className={classes.amountPaidValues}>
                                {translate({ id: 'CLIENT_AMOUNT_PAID' })}
                            </Typography>
                            <Typography className={classes.amountPaidValues}>
                                {invoiceData && getPriceString(invoiceData.total, invoiceData.currency, language)}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <Typography className={classes.contactText}>
                            {translate({ id: 'CLIENT_IF_YOU_HAVE_ANY_QUESTIONS' })}
                        </Typography>
                        <Typography className={classes.contactText}>
                            <FormattedMessage
                                id="CLIENT_PLEASE_CONTACT_UI_PATH_AT"
                                values={{
                                    a: (chunk: string) => (
                                        <a
                                            className={classes.a}
                                            href="mailto:cloud.billing@uipath.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {chunk}
                                        </a>
                                    ),
                                }}
                            />
                        </Typography>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

