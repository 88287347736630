import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useAuthContext } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import {
    IDiscountedProductsPrices,
    IErrorWithStatus,
    INonUsTaxes,
    IProductsPrices,
    ISkuPackage,
    ISkuPackageDetails,
    ITaxResponse,
    IVatValidation,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getNonUsTaxValue,
    getProductsPricesByCountry,
    getProductsPricesWithDiscountsByCountry,
    getUsTaxValue,
    validateEuVat,
} from '../services/BillingService';
import {
    getStoredCountryCode,
    useEcommerceEnabledCountries,
} from './EcommerceHelpers';
import { accountLogicalName } from './EcommerceSelectors';

const europeanUnionCountryCodes = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];

export const EcommerceContext = React.createContext<{
    countryCode: string;
    setEcommerceCountry: (countryCode: string) => void;
    setEcommerceCurrencyValue: (currencyValue: string) => void;
    setEcommerceTaxIdValue: (taxIdValue?: string) => void;
    setEcommerceZipCodeValue: (zipCodeValue?: string) => void;
    setSkuPackage: (skuPackage?: ISkuPackage) => void;
    setSelectedCurrency: (selectedCurrency?: string) => void;
    error?: Error;
    currentSkuPackageDetails: ISkuPackageDetails;
    isValidating?: boolean;
    isTaxLoading?: boolean;
    arePricesLoading?: boolean;
    isEuropeanUnionCountry: boolean;
    publicKey?: string;
}>({
            countryCode: '',
            setEcommerceCountry: () => {},
            setEcommerceCurrencyValue: () => {},
            setEcommerceTaxIdValue: () => {},
            setEcommerceZipCodeValue: () => {},
            setSkuPackage: () => {},
            setSelectedCurrency: () => {},
            error: undefined,
            currentSkuPackageDetails: {
                type: '',
                billingPeriod: '',
                products: [],
                defaultCurrency: '',
                prices: {},
                discountedPrices: {},
                discountDetails: undefined,
                taxError: undefined,
                isEuCountry: false,
            },
            isValidating: true,
            isTaxLoading: false,
            arePricesLoading: false,
            isEuropeanUnionCountry: false,
            publicKey: '',
        });

export const useEcommerce = (skuPackage?: ISkuPackage, selectedCurrency?: string) => {
    const {
        setEcommerceCountry,
        setEcommerceTaxIdValue,
        setEcommerceCurrencyValue,
        setSkuPackage,
        setSelectedCurrency,
        setEcommerceZipCodeValue,
        error,
        currentSkuPackageDetails,
        isValidating,
        isTaxLoading,
        arePricesLoading,
        countryCode,
        isEuropeanUnionCountry,
        publicKey,
    } = React.useContext(EcommerceContext);
    if (skuPackage) {
        setSkuPackage(skuPackage);
    }
    if (selectedCurrency) {
        setSelectedCurrency(selectedCurrency);
    }
    return {
        countryCode,
        setEcommerceCountry,
        setEcommerceTaxIdValue,
        setEcommerceCurrencyValue,
        setEcommerceZipCodeValue,
        error,
        currentSkuPackageDetails,
        isValidating,
        isTaxLoading,
        arePricesLoading,
        isEuropeanUnionCountry,
        publicKey,
    };
};

export const EcommerceProvider: React.FC = ({ children }) => {
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);

    const currentAccountName = useSelector(accountLogicalName);
    const { accountCountryCode } = useEcommerceEnabledCountries();

    const [ countryCode, setCountryCode ] = useState<string>(getStoredCountryCode() || accountCountryCode || 'US');
    const [ currencyValue, setCurrencyValue ] = useState<string>('');
    const [ zipCodeValue, setZipCodeValue ] = useState<string>('');
    const [ taxIdValue, setTaxIdValue ] = useState<string>('');
    const [ isTaxLoading, setIsTaxLoading ] = useState<boolean>(false);
    const [ arePricesLoading, setArePricesLoading ] = useState<boolean>(false);
    const [ isEuropeanUnionCountry, setIsEuropeanUnionCountry ] = useState<boolean>(false);
    const [ publicKey, setPublicKey ] = useState<string | undefined>();
    const { token } = useAuthContext();

    const [ currentSkuPackage, setCurrentSkuPackage ] = useState<ISkuPackage | null>(null);
    const [ currentSelectedCurrency, setCurrentSelectedCurrency ] = useState<string>('');

    const productsPricesUrl = `${billingUrl}/productsPrices`;
    const {
        data: productsPrices,
        isValidating,
        error,
    } = useSWR<IProductsPrices, Error>(
        countryCode ? [ countryCode, currentAccountName, productsPricesUrl ] : null,
        () => getProductsPricesByCountry(countryCode, token, currentAccountName),
        { revalidateOnFocus: false },
    );

    const {
        data: productsPricesWithDiscounts,
        isValidating: productsPricesWithDiscountsLoading,
    } = useSWR<IDiscountedProductsPrices>(
        EnableEcommercePromotionalDiscounts && currentSkuPackage?.products && countryCode
            ? [ countryCode, currentAccountName, currentSkuPackage?.products, productsPricesUrl ]
            : null,
        () => getProductsPricesWithDiscountsByCountry(countryCode, currentSkuPackage?.products!, token, currentAccountName),
        { revalidateOnFocus: false },
    );

    const currentSkuPackageCurrencies = useMemo(
        () => {
            return Array.from(new Set(productsPrices?.products.flatMap(p => Object.keys(p.prices))).values());
        },
        [ productsPrices ],
    );

    const currentSkuPackagePrice = useMemo(
        () => {
            const packagePrices: Record<string, number> = {};

            currentSkuPackageCurrencies?.forEach(currency => {
                let price = 0;
                currentSkuPackage?.products.forEach(productQuantity => {
                    if (productsPrices) {
                        const foundProductPrice = productsPrices.products.find(pp => pp.code === productQuantity.code)?.prices[currency];
                        if (foundProductPrice) {
                            price += productQuantity.quantity * foundProductPrice;
                        }
                    }
                });
                packagePrices[currency] = price;
            });

            return packagePrices;
        },
        [
            currentSkuPackage?.products,
            productsPrices,
            currentSkuPackageCurrencies,
        ],
    );

    const currentSkuPackageDiscountedPrice = useMemo(
        () => {
            const packagePrices: Record<string, number> = {};

            currentSkuPackageCurrencies?.forEach(currency => {
                let price = 0;
                currentSkuPackage?.products.forEach(productQuantity => {
                    if (productsPricesWithDiscounts) {
                        const foundProductPrice = productsPricesWithDiscounts.products
                            .find(pp => pp.code === productQuantity.code)?.discount?.discountedPrices[currency];
                        if (foundProductPrice) {
                            price += productQuantity.quantity * foundProductPrice;
                        }
                    }
                });
                packagePrices[currency] = price;
            });

            return packagePrices;
        },
        [
            currentSkuPackage?.products,
            productsPricesWithDiscounts,
            currentSkuPackageCurrencies,
        ],
    );

    const discountDetails = useMemo(
        () => {
            if (productsPricesWithDiscounts) {
                const discounts = productsPricesWithDiscounts.products.map(pp => pp.discount);
                const couponIds = discounts.map(d => d?.couponId);
                const uniqueCouponIds = [ ...new Set(couponIds) ];
                if (uniqueCouponIds.length === 1) {
                    return discounts[0];
                }
            }
        },
        [ productsPricesWithDiscounts ],
    );

    const shouldDisplayDiscount = useMemo(() => {
        const uniqueDiscounts = [ ...new Set(Object.values(currentSkuPackageDiscountedPrice)) ];
        const hasDiscounts = !(uniqueDiscounts.length === 1 && uniqueDiscounts[0] === 0);
        return EnableEcommercePromotionalDiscounts && currentSkuPackagePrice !== currentSkuPackageDiscountedPrice && hasDiscounts;
    }, [ EnableEcommercePromotionalDiscounts, currentSkuPackagePrice, currentSkuPackageDiscountedPrice ]);

    const mainPriceToDisplay = useMemo(() => {
        return shouldDisplayDiscount
            ? currentSkuPackageDiscountedPrice
            : currentSkuPackagePrice;
    }, [ shouldDisplayDiscount, currentSkuPackagePrice, currentSkuPackageDiscountedPrice ]);

    const nonUsTaxesParams = useMemo(() => {
        let parameters = null;
        if (countryCode && countryCode !== 'US' && mainPriceToDisplay[currencyValue] && currencyValue) {
            const amount = mainPriceToDisplay[currencyValue] / 100;
            parameters = [ countryCode, amount, currentAccountName, token ];
        }
        return parameters;
    }, [ countryCode, mainPriceToDisplay, currencyValue, token, currentAccountName ]);
    const {
        data: taxResponseNonUs,
        isValidating: isLoadingNonUsTax,
        error: taxErrorNonUs,
    } = useSWR<INonUsTaxes, IErrorWithStatus>(nonUsTaxesParams, getNonUsTaxValue, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    const usTaxesParams = useMemo(() => {
        let parameters = null;
        if (currentSkuPackage && productsPrices && countryCode && countryCode === 'US' && zipCodeValue) {
            const amount = mainPriceToDisplay[productsPrices.defaultCurrency] / 100;
            parameters = [ countryCode, zipCodeValue, amount, currentAccountName, token ];
        }

        return parameters;
    }, [ countryCode, currentSkuPackage, productsPrices, mainPriceToDisplay, token, currentAccountName, zipCodeValue ]);

    const {
        data: taxResponseUs,
        isValidating: isLoadingUsTax,
        error: taxErrorUs,
    } = useSWR<ITaxResponse, IErrorWithStatus>(usTaxesParams, getUsTaxValue, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });

    const {
        data: vatValidation,
        isValidating: isLoadingVat,
        error: vatValidationError,
    } = useSWR<IVatValidation, IErrorWithStatus>(
        countryCode && countryCode !== 'US' && taxIdValue && europeanUnionCountryCodes.includes(countryCode)
            ? [ countryCode, taxIdValue, currentAccountName, token ]
            : null,
        validateEuVat,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        },
    );

    const getTaxValueAndRate = useCallback(
        (country: string, vatValid: IVatValidation | undefined): ITaxResponse | undefined => {
            if (country === 'US') {
                return taxResponseUs;
            }
            return vatValid?.validationStatus?.toLowerCase() === 'valid'
                ? taxResponseNonUs?.validVatTaxResponse
                : taxResponseNonUs?.invalidVatTaxResponse;

        },
        [ taxResponseNonUs, taxResponseUs ],
    );

    useEffect(() => {
        setIsEuropeanUnionCountry(europeanUnionCountryCodes.includes(countryCode));
    }, [ countryCode ]);

    const allowedCurrentSelectedCurrency = useMemo(() => {
        return currentSkuPackageCurrencies?.includes(currentSelectedCurrency) ? currentSelectedCurrency : null;
    }, [ currentSkuPackageCurrencies, currentSelectedCurrency ]);

    const currentSkuPackageDetails = useMemo(
        () => ({
            type: currentSkuPackage?.type ?? '',
            billingPeriod: currentSkuPackage?.billingPeriod ?? '',
            products: currentSkuPackage?.products ?? [],
            defaultCurrency: allowedCurrentSelectedCurrency ?? productsPrices?.defaultCurrency ?? currentSkuPackage?.currency ?? 'EUR',
            prices: currentSkuPackage?.products ? currentSkuPackagePrice : {},
            discountedPrices: shouldDisplayDiscount && currentSkuPackage?.products ? currentSkuPackageDiscountedPrice : {},
            discountDetails: shouldDisplayDiscount ? discountDetails : undefined,
            tax: getTaxValueAndRate(countryCode, vatValidation),
            taxError: countryCode === 'US' ? taxErrorUs : taxErrorNonUs ?? vatValidationError,
            isEuCountry: europeanUnionCountryCodes.includes(countryCode),
            stripePublishableKey: productsPrices?.stripePublishableKey,
        }),
        [
            currentSkuPackage,
            currentSkuPackagePrice,
            currentSkuPackageDiscountedPrice,
            shouldDisplayDiscount,
            discountDetails,
            productsPrices,
            taxErrorUs,
            taxErrorNonUs,
            vatValidation,
            vatValidationError,
            countryCode,
            getTaxValueAndRate,
            allowedCurrentSelectedCurrency,
        ],
    );

    useEffect(() => {
        setIsTaxLoading(isLoadingUsTax || isLoadingNonUsTax || isLoadingVat);
    }, [ isLoadingUsTax, isLoadingNonUsTax, isLoadingVat ]);

    useEffect(() => {
        setArePricesLoading(isValidating || productsPricesWithDiscountsLoading);
    }, [ isValidating, productsPricesWithDiscountsLoading ]);

    useEffect(() => {
        if (countryCode && currentSkuPackage) {
            mutate([ productsPricesUrl, currentAccountName, countryCode ]);
        }
    }, [ countryCode, currentSkuPackage, currentAccountName, productsPricesUrl ]);

    useEffect(() => {
        if (productsPrices?.stripePublishableKey && publicKey !== productsPrices?.stripePublishableKey) {
            setPublicKey(productsPrices.stripePublishableKey);
        }
    }, [ productsPrices, publicKey ]);

    // Callback to change the Stripe public key based on the selected country
    const setEcommerceCountry = useCallback((country?: string) => {
        if (country) {
            setCountryCode(country);
        }
    }, []);

    const setEcommerceCurrencyValue = useCallback((currency?: string) => {
        if (currency) {
            setCurrencyValue(currency);
        }
    }, []);

    const setEcommerceZipCodeValue = useCallback((zipCode?: string) => {
        setZipCodeValue(zipCode ? zipCode : '');
    }, []);

    const setEcommerceTaxIdValue = useCallback((taxId?: string) => {
        setTaxIdValue(taxId ? taxId : '');
    }, []);

    const setSkuPackage = useCallback((skuPackage?: ISkuPackage) => {
        if (skuPackage) {
            setCurrentSkuPackage(skuPackage);
        }
    }, []);

    const setSelectedCurrency = useCallback((selectedCurrency?: string) => {
        if (selectedCurrency) {
            setCurrentSelectedCurrency(selectedCurrency);
        }
    }, []);

    return (
        <EcommerceContext.Provider
            value={{
                countryCode,
                setEcommerceCountry,
                setEcommerceCurrencyValue,
                setEcommerceTaxIdValue,
                setEcommerceZipCodeValue,
                setSkuPackage,
                setSelectedCurrency,
                error,
                currentSkuPackageDetails,
                isValidating,
                isTaxLoading,
                arePricesLoading,
                isEuropeanUnionCountry,
                publicKey,
            }}
        >
            {children}
        </EcommerceContext.Provider>
    );
};
