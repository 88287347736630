import {
    IPagination,
    ISort,
} from '@experiences/interfaces';

/**
 * if sortOrder is asc, the alert should be rendered on the first page bc thats the oldest data
 * else, the alert should be rendered on the last page
 */
export function shouldRenderAlertForOldestPage(pagination: IPagination, sort: ISort, totalCount: number) {
    if (totalCount === 0) {
        return false;
    }

    return sort.order && sort.order !== 'desc' ? pagination.skip === 0 : pagination.skip + pagination.top >= totalCount;
}
