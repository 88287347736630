import React from 'react';

export const StudioXIcon: React.FC<{ id: string; className?: string }> = ({
    id, className,
}) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M10 10L0 20H20L10 10Z"
                fill={`url(#paint0_linear_${id})`} />
            <path
                d="M10 10L0 0H20L10 10Z"
                fill={`url(#paint1_linear_${id})`} />
            <path
                d="M10 10L0 0V20L10 10Z"
                fill={`url(#paint2_linear_${id})`} />
            <path
                d="M10 10L20 20V0L10 10Z"
                fill={`url(#paint3_linear_${id})`} />
            <path
                d="M13.3328 3.75C13.3328 4.46429 12.797 5 12.0828 5C11.3685 5 10.8328 4.46429 10.8328 3.75C10.8328 3.03571 11.3685 2.5 12.0828 2.5C12.797 2.5 13.3328 3.03571 13.3328 3.75Z"
                fill="white"
            />
            <path
                d="M9.16638 16.25C9.16638 16.9643 8.63067 17.5 7.91638 17.5C7.2021 17.5 6.66638 16.9643 6.66638 16.25C6.66638 15.5357 7.2021 15 7.91638 15C8.63067 15 9.16638 15.5357 9.16638 16.25Z"
                fill="white"
            />
            <path
                d="M13.3328 12.5C13.3328 11.131 12.2018 10 10.8328 10V7.5C13.5709 7.5 15.8328 9.7619 15.8328 12.5C15.8328 15.2381 13.5709 17.5 10.8328 17.5V15C12.2018 15 13.3328 13.869 13.3328 12.5Z"
                fill="white"
            />
            <path
                d="M6.66638 7.5C6.66638 8.86905 7.79733 10 9.16638 10V12.5C6.42829 12.5 4.16638 10.2381 4.16638 7.5C4.16638 4.76191 6.42829 2.5 9.16638 2.5V5C7.79733 5 6.66638 6.13095 6.66638 7.5Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_${id}`}
                    x1="3.20887"
                    y1="20.9804"
                    x2="16.8079"
                    y2="14.0729"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#38C6F4" />
                    <stop
                        offset="1"
                        stopColor="#3FA9F5" />
                </linearGradient>
                <linearGradient
                    id={`paint1_linear_${id}`}
                    x1="18.1546"
                    y1="7.73911"
                    x2="5.95863"
                    y2="-2.83792"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#38C6F4" />
                    <stop
                        offset="1"
                        stopColor="#3FA9F5" />
                </linearGradient>
                <linearGradient
                    id={`paint2_linear_${id}`}
                    x1="5.48208"
                    y1="3.62214"
                    x2="0.773809"
                    y2="17.0146"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#38C6F4" />
                    <stop
                        offset="1"
                        stopColor="#3FA9F5" />
                </linearGradient>
                <linearGradient
                    id={`paint3_linear_${id}`}
                    x1="24.8599"
                    y1="12.579"
                    x2="14.2829"
                    y2="6.96667"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#38C6F4" />
                    <stop
                        offset="1"
                        stopColor="#3FA9F5" />
                </linearGradient>
            </defs>
        </svg>
    );
};

