import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import {
    isEqual,
    merge,
    omit,
} from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import useSWR, { mutate } from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import { getSortedUserBundleConfigurations } from '../../../common/constants/LicensingConfig';
import {
    IGroupAllocations,
    IGroupPickerAllocations,
} from '../../../common/interfaces/licenses';
import { SourceFilters } from '../../../services/identity/DirectoryService';
import {
    deleteGroupLicenseAllocation,
    getGroupLicenseAllocation,
    ISubmitGroupAllocations,
    putGroupLicenseAllocation,
    userLicenseUrl,
} from '../../../services/licensing/accountant/UserLicenseService';
import {
    getUserLicenseAllocation,
    userLicenseUri,
} from '../../../services/licensing/UserLicenseService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
} from '../../../store/selectors';
import { decodeSanitizedHtml } from '../../../util/DecodeSanitizedHtml';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { groupUrl } from '../../users/GroupsPageComponent';
import {
    IGroupAllocationRules,
    IUserLicenseAllocation,
} from '../../users/interfaces/userLicense';
import { LicensingAllocationFormComponent } from './LicensingAllocationFormComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        body: { margin: '0px 24px' },
        cancelButton: { marginRight: '10px' },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '20px',
            style: 'normal',
        },
        groupAllocationMessage: { marginTop: '24px' },
        groupPicker: { marginTop: '15px' },
        heading: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '15px',
            marginBottom: '2px',
        },
    }),
);

const AddEditGroupAllocationComponent: React.FC<{
    location?: { state?: { group: IGroupAllocations; previousLocation: string } };
}> = ({ location }) => {
    const EnableExternalUserLicense = useFeatureFlagValue(Features.EnableExternalUserLicense.name);

    const classes = useStyles();
    const match = useRouteMatch<{ type: 'add' | 'edit' }>();
    const type = useMemo(() => (match.params.type === 'add' ? 'add' : 'edit'), [ match ]);
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const setErrorMessage = useCentralErrorSetter();
    const accountGlobalGuid = useSelector(accountGlobalId);
    const { token } = useUserInfo();

    const history = useHistory();
    const group = useMemo(() => location?.state?.group, [ location ]);
    const previousLocation = useMemo(() => location?.state?.previousLocation!, [ location ]);

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);

    const { getErrorMessage } = useGetErrorInfo();

    const {
        open, close,
    } = useModalState(previousLocation);

    const [ loading, setLoading ] = useState(false);

    const defaultPeoplePickerGroup: IGroupPickerAllocations = useMemo(() => {
        return {
            id: '',
            name: '',
            group: undefined,
            userBundleLicenses: [],
            useExternalLicense: false,
        };
    }, []);

    const methods = useForm<IGroupPickerAllocations>({
        mode: 'onChange',
        defaultValues: defaultPeoplePickerGroup,
    });

    const isEdit = useMemo(() => type === 'edit', [ type ]);
    const showGroupPicker = useMemo(() => {
        return !isEdit && group == null;
    }, [ isEdit, group ]);

    const {
        handleSubmit, getValues, setValue, formState, reset, control, clearErrors, setError,
    } = useMemo(
        () => methods,
        [ methods ],
    );

    const { isDirty } = formState;

    const title = useMemo(
        () =>
            isEdit
                ? 'CLIENT_GROUPS_EDIT_GROUP_ALLOCATION_RULE'
                : showGroupPicker
                    ? 'CLIENT_CREATE_GROUP_ALLOCATION_RULE'
                    : 'CLIENT_GROUPS_CREATE_GROUP_ALLOCATION_RULE',
        [ isEdit, showGroupPicker ],
    );

    const {
        data: editGroupData, isValidating: editGroupLoading,
    } = useSWR<IGroupAllocationRules, Error>(
        isEdit ? [ `${userLicenseUrl}/group`, group ] : null,
        () => getGroupLicenseAllocation(group!.id),
    );

    const {
        data: addGroupData, isValidating: addGroupLoading,
    } = useSWR<IUserLicenseAllocation[], Error>(
        !isEdit ? [ `${userLicenseUri}/api/account/${accountGlobalGuid}/user-license`, accountGlobalGuid ] : null,
        () => getUserLicenseAllocation(accountGlobalGuid),
    );

    const validUserBundleCodes = useMemo(() => getSortedUserBundleConfigurations().map(s => s.code), []);

    const [ allocatedUserBundles, availableUserBundles, useExternalLicense ] = useMemo(
        () =>
            isEdit
                ? [
                    editGroupData?.allocatedUserBundles,
                    editGroupData?.availableUserBundles.filter(p => validUserBundleCodes.includes(p.code)),
                    editGroupData?.useExternalLicense,
                ]
                : [
                    [],
                    addGroupData?.filter(ubl => ubl.total > 0).filter(p => validUserBundleCodes.includes(p.code)),
                    false,
                ],
        [ editGroupData, addGroupData, isEdit, validUserBundleCodes ],
    );

    useEffect(() => {
        if (!EnableUserLicensing || (!group && isEdit)) {
            console.warn('Feature not enabled or missing data. Closing...');
            history.push('/invalidurl');
        }
    }, [ isEdit, EnableUserLicensing, group, history ]);

    useEffect(() => {
        if (allocatedUserBundles) {
            reset(
                merge(defaultPeoplePickerGroup, {
                    userBundleLicenses: allocatedUserBundles,
                    useExternalLicense: useExternalLicense,
                }),
            );
        }
    }, [ allocatedUserBundles, useExternalLicense, reset, defaultPeoplePickerGroup ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const confirmAllocationOverrideDialog = useCallback(
        async (groupName: string, licenseSelectionIsEmpty: boolean) => {
            return await createDialog({
                title: `${translate({ id: 'CLIENT_CREATE_GROUP_ALLOCATION_RULE' })}`,
                body: (
                    <div>
                        <p>
                            {translate({ id: 'CLIENT_OVERRIDE_GROUP_ALLOCATION' }, { 0: groupName })}
                        </p>
                        <p>
                            {translate({ id: 'CLIENT_OVERRIDE_GROUP_ALLOCATION_CONTINUE' })}
                        </p>
                        {licenseSelectionIsEmpty && (
                            <p data-cy="delete-warning">
                                {translate({ id: 'CLIENT_DELETE_EXISTING_GROUP_ALLOCATION_RULE_DETAILS' })}
                            </p>
                        )}
                    </div>
                ),
                icon: 'warning',
                primaryButtonText: `${translate({ id: 'CLIENT_SAVE' })}`,
                showCancel: true,
            });
        },
        [ translate, createDialog ],
    );

    const shouldShowWarningDialog = useCallback(
        (payload: ISubmitGroupAllocations) => {
            const licenseAllocationIsDowngraded = allocatedUserBundles?.some(
                userBundle => payload.licenses.indexOf(userBundle) === -1,
            );
            const allocationRuleAlreadyExists = allocatedUserBundles?.length !== 0 || useExternalLicense;
            return (
                isEdit &&
                allocationRuleAlreadyExists &&
                (licenseAllocationIsDowngraded || useExternalLicense !== payload.useExternalLicense)
            );
        },
        [ allocatedUserBundles, isEdit, useExternalLicense ],
    );

    const onSubmit = useCallback(
        async (data: IGroupPickerAllocations) => {
            setLoading(true);
            try {
                const licenses = data.useExternalLicense ? [] : data.userBundleLicenses!;
                const externalLicense = data.useExternalLicense ?? false;
                const emptyLicenseSelection = licenses.length === 0 && !externalLicense;
                const peoplePickerGroup = data.group;

                let allocationRuleAlreadyExists;
                if (showGroupPicker) {
                    if (peoplePickerGroup?.identifier == null) {
                        setError('group', { type: 'invalid' });
                        setLoading(false);
                        return;
                    }
                    const groupLicense = await getGroupLicenseAllocation(peoplePickerGroup.identifier);
                    allocationRuleAlreadyExists =
                        groupLicense.allocatedUserBundles.length !== 0 || groupLicense.useExternalLicense;
                    if (allocationRuleAlreadyExists) {
                        const proceed = await confirmAllocationOverrideDialog(
                            peoplePickerGroup.name,
                            emptyLicenseSelection,
                        );
                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                }
                if (emptyLicenseSelection && !isEdit && !allocationRuleAlreadyExists) {
                    setError('userBundleLicenses', { type: 'invalid' });
                    setLoading(false);
                    return;
                }
                const groupId = showGroupPicker ? peoplePickerGroup.identifier : group!.id;
                const payload: ISubmitGroupAllocations = {
                    groupId,
                    licenses,
                    useExternalLicense: externalLicense,
                };

                if (!emptyLicenseSelection) {
                    if (shouldShowWarningDialog(payload)) {
                        const proceed = await createDialog({
                            title: translate({ id: 'CLIENT_SAVE_CHANGES' }),
                            body: `${translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE_WARNING' })}`,
                            icon: 'warning',
                            showCancel: true,
                            primaryButtonText: translate({ id: 'CLIENT_SAVE' }),
                        });

                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                    await putGroupLicenseAllocation(payload);
                    createNotification(
                        translate(
                            { id: 'CLIENT_GROUP_UPDATED' },
                            { 0: decodeSanitizedHtml(showGroupPicker ? peoplePickerGroup!.name : group!.name) },
                        ),
                    );
                    await mutate(
                        [ `${groupUrl}/licenses?partitionGlobalId=${accountGlobalGuid}`, accountGlobalGuid ],
                    );
                } else {
                    if (isEdit) {
                        const proceed = await createDialog({
                            title: translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE' }),
                            body: `${translate({ id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE_DETAILS' })}`,
                            icon: 'warning',
                            showCancel: true,
                            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                        });
                        if (!proceed) {
                            setLoading(false);
                            return;
                        }
                    }
                    await deleteGroupLicenseAllocation(payload.groupId);
                    createNotification(
                        translate(
                            { id: 'CLIENT_GROUPS_DELETE_GROUP_ALLOCATION_RULE_SUCCESS' },
                            { 0: showGroupPicker ? peoplePickerGroup!.name : group!.name },
                        ),
                    );
                    await mutate(`${userLicenseUrl}/users`);
                    await mutate(
                        [ `${groupUrl}/licenses?partitionGlobalId=${accountGlobalGuid}`, accountGlobalGuid ],
                    );
                }
            } catch (error) {
                setLoading(false);
                setErrorMessage(await getErrorMessage(error));
                close();
                return;
            }
            setLoading(false);
            close(true);
        },
        [
            close,
            createNotification,
            group,
            setErrorMessage,
            translate,
            createDialog,
            confirmAllocationOverrideDialog,
            isEdit,
            setError,
            showGroupPicker,
            shouldShowWarningDialog,
            getErrorMessage,
            accountGlobalGuid,
        ],
    );

    const orgHasUserLicenses = useMemo(
        () =>
            EnableExternalUserLicense || (availableUserBundles?.length ?? 0) + (allocatedUserBundles?.length ?? 0) > 0,
        [ EnableExternalUserLicense, availableUserBundles?.length, allocatedUserBundles?.length ],
    );

    const peoplePickerElementCallback = useCallback(
        (ref: HTMLPortalPeoplePickerElement | null) => {
            ref?.addEventListener('peoplePickerChanged', (event: any) => {
                const prevWithoutChip = omit(getValues('group'), [ 'optionId', 'chipType' ]);
                const curWithoutChip = omit(event.detail.data, [ 'optionId', 'chipType' ]);
                const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
                setValue('group', event.detail.data, {
                    shouldDirty: valuesChanged,
                    shouldValidate: valuesChanged,
                });
            });

            ref?.addEventListener('peoplePickerError', (event: any) => {
                if (event.detail) {
                    setError('group', { type: 'invalid' });
                } else {
                    clearErrors('group');
                }
            });

            ref?.addEventListener('peoplePickerLoading', (event: any) => setLoading(event.detail));
        },
        [ clearErrors, getValues, setError, setValue ],
    );

    return (
        <UiDrawer
            title={translate({ id: title }, { 0: group?.name })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={editGroupLoading || addGroupLoading}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    {orgHasUserLicenses && (
                        <>
                            {showGroupPicker && (
                                <div
                                    className={classes.groupPicker}
                                    data-cy="group-picker">
                                    <Controller
                                        control={control}
                                        name="group"
                                        render={props => (
                                            <portal-people-picker
                                                ref={peoplePickerElementCallback}
                                                token={token || undefined}
                                                source-filters={JSON.stringify([
                                                    'localGroups',
                                                    'directoryGroups',
                                                ] as SourceFilters[])}
                                                value={JSON.stringify(props.value ? [ props.value ] : undefined)}
                                                single-mode="true"
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className={showGroupPicker ? '' : classes.groupAllocationMessage}>
                                <Typography className={classes.heading}>
                                    {translate({ id: 'CLIENT_SELECT_LICENSES' })}
                                </Typography>
                                <Typography className={classes.groupText}>
                                    {translate({ id: 'CLIENT_GROUPS_ALLOCATION_RULE_MESSAGE' })}
                                </Typography>
                            </div>
                        </>
                    )}
                    <LicensingAllocationFormComponent
                        availableUserBundles={availableUserBundles}
                        allocatedUserBundles={allocatedUserBundles}
                        isAllocationForAGroup={true}
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default AddEditGroupAllocationComponent;
