import { AccountLicense } from '@experiences/constants';
import { ICreateEditTenantPayload } from '@experiences/interfaces';
import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, {
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { IService } from '../../../../common/interfaces/tenant/tenant';
import { getAvailableServices } from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../../store/selectors';
import { IServiceMetadata } from '../../interfaces/service';
import { serviceOrder } from '../../TenantConstants';
import { IServiceRegionMap } from '../../TenantsPageComponent';
import CreateEditTenantFormComponent from '../forms/CreateEditTenantFormComponent';
import { getDefaultRegionForTenant } from '../helpers/TenantRegionHelper';
import { ServiceErrorType } from '../helpers/TenantServiceErrorMessage';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        licenseInfo: {
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        serviceIconColor: { color: Tokens.Colors.ColorBlue600 },
        errorText: {
            fontSize: '11px',
            color: theme.palette.semantic.colorErrorText,
        },
    }),
);

const TenantCreateServicesComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ],
    );

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const methods = useFormContext<ICreateEditTenantPayload>();

    const {
        watch, formState,
    } = methods;

    const { errors } = formState;

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ '/api/tenant/availableservices', partitionGlobalId, accountName, watch('customProperties.isCanaryTenant') ],
        getAvailableServices,
    );

    const defaultRegion = useMemo(() => getDefaultRegionForTenant(availableServices), [ availableServices ]);

    const {
        services, servicesAltRegion,
    } = useMemo(() => {
        const services = [] as IService[];
        const servicesAltRegion = services
            .reduce((map, service) => {
                map[service.serviceType] = service.region;
                return map;
            }, {} as IServiceRegionMap);
        return {
            services,
            servicesAltRegion,
        };
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div style={{ marginTop: '16px' }}>
                <CreateEditTenantFormComponent
                    type="add"
                    availableServices={
                        availableServices?.sort(
                            (serviceA, serviceB) => serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
                        ) ?? []
                    }
                    services={services}
                    servicesAltRegion={servicesAltRegion}
                    isRegionEnabled={isRegionEnabled}
                    validationErrorHandler={validationErrorHandlers}
                    defaultRegion={defaultRegion}
                />
                {!!errors.services && (
                    <Typography className={classes.errorText}>
                        {translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default TenantCreateServicesComponent ;
