import { getFeatureFlagValue } from '@experiences/feature-flags';
import { UiErrorBoundary } from '@experiences/ui-common';
import _ from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { IWidgetMetadata } from '../../../common/interfaces/widget';

export const UiWidgetContainer: React.FC<{
    widgets: IWidgetMetadata[];
    userGroups: string[];
    position: 'center' | 'right';
}> = ({
    widgets, userGroups, position,
}) => {
    const { formatMessage: translate } = useIntl();
    return userGroups ? (
        <>
            {widgets
                .filter(
                    widget =>
                        _.intersection(widget.groups, userGroups).length > 0 && // current user group must intersect widget groups to render
                        widget.position === position && // position of widget should match position of container
                        (widget.featureFlag
                            ? !widget.invertFeatureFlag === getFeatureFlagValue(widget.featureFlag) // feature flag, if it is provided
                            : true),
                )
                .sort((a, b) => a.priority - b.priority)
                .map((widget, i) => {
                    return (
                        <UiErrorBoundary
                            key={i}
                            errorComponent={translate({ id: 'CLIENT_WIDGET_FAILED_LOADING' }, { 0: widget.name })}
                        >
                            <widget.component />
                        </UiErrorBoundary>
                    );
                })}
        </>
    ) : null;
};
