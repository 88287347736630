import {
    Button,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '25vh',
        },
        infoContainer: {
            display: 'flex',
            flex: 1.5,
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 5vw 0 5vw',
        },
        imageContainer: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            padding: '0 5vw 0 5vw',
        },
        headerText: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '32px',
            alignSelf: 'start',
        },
        bodyText: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            alignSelf: 'start',
        },
        button: { alignSelf: 'start' },
        spacer: { marginBottom: '20px' },
    }),
);

export const UiNotificationPage = ({
    headerText, descriptionText, errorImage, buttonName, buttonMethod,
}: { headerText: string; descriptionText: string; errorImage: string; buttonName?: string; buttonMethod?: any }) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.mainContainer}>
            <div
                className={classes.infoContainer}
                data-cy="notification-page-text">
                <Typography
                    align='left'
                    className={clsx(classes.headerText, classes.spacer)}>
                    {translate({ id: headerText })}
                </Typography>
                <Typography
                    align='left'
                    className={clsx(classes.bodyText, classes.spacer)}>
                    {translate({ id: descriptionText })}
                </Typography>
                {buttonName &&
                    <Button
                        variant='contained'
                        onClick={buttonMethod}
                        className={classes.button}
                        data-cy='notification-page-button'>
                        {translate({ id: buttonName })}
                    </Button>}
            </div>
            <div className={classes.imageContainer}>
                <img
                    src={errorImage}
                    alt="uipath error"
                    data-cy="notification-page-image" />
            </div>
        </div>
    );
};
