import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { UiLogo } from './UiLogo';

const useStyles = makeStyles(theme =>
    createStyles({
        flex: { display: 'flex' },
        titleContainer: {
            marginTop: '12px',
            marginLeft: '4px',
        },
        primaryTitle: {
            fontSize: '32px',
            letterSpacing: '-1px',
        },
        secondaryTitle: {
            fontSize: '12px',
            letterSpacing: '1px',
            marginTop: '1px',
        },
        trademark: {
            marginTop: '-4px',
            fontSize: '6px',
        },
        standardFont: {
            fontFamily: 'poppins',
            fontWeight: 600,
        },
        standardColor: { color: theme.palette.text.primary },
    }),
);

interface IUiSignupLogoProps {
    productName?: string;
    className?: string;
}

export function UiSignupLogo({
    productName, className,
}: IUiSignupLogoProps) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.flex, className)}>
            <UiLogo />
            <div className={classes.titleContainer}>
                <div className={classes.flex}>
                    <Typography className={clsx(classes.primaryTitle, classes.standardFont, classes.standardColor)}>
            Automation Cloud
                    </Typography>
                    <sup className={clsx(classes.trademark, classes.standardColor)}>
TM
                    </sup>
                </div>
                <Typography className={clsx(classes.secondaryTitle, classes.standardFont, classes.standardColor)}>
                    {productName ? productName.toUpperCase() : ''}
                </Typography>
            </div>
        </div>
    );
}
