import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

const EnableSmartlingIntegration = getFeatureFlagValue(Features.EnableSmartlingIntegration.name);

export function registerSmartling() {
    if (window.env.REACT_APP_CONTEXT_CAPTURE_LIB_KEY && EnableSmartlingIntegration) {
        const script = document.createElement('script');
        script.src = '//d2c7xlmseob604.cloudfront.net/tracker.min.js';
        script.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = function() {
            (window as any).SmartlingContextTracker.init({
                orgId: window.env.REACT_APP_CONTEXT_CAPTURE_LIB_KEY,
                host: 'api.smartling.com',
            });
        };
    }
}
