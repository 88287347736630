import { IUiLinkMap } from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import { useCallback } from 'react';

export const useLocalizedLinks = () => {
    const language = useLocalization();

    const getLocalizedLink = useCallback((linkMap: IUiLinkMap) => linkMap.get(language) ?? linkMap.get('en'), [ language ]);

    return getLocalizedLink;
};
