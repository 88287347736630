import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useRouteResolver } from './useRouteResolver';

export function useProtectRouteFromMissingParams(params: any[], rerouteName: string) {
    const history = useHistory();
    const getRoute = useRouteResolver();

    useEffect(() => {
        if (params.some(param => !!param)) {
            history.push(getRoute(rerouteName));
        }
    }, [ history, getRoute, params, rerouteName ]);
}
