import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export default (theme: Theme) =>
    createStyles({
        a: {
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': {
                cursor: 'pointer',
                color: theme.palette.semantic.colorForegroundLinkPressed,
            },
            '&:focus': { color: theme.palette.semantic.colorForegroundLinkPressed },
        },

        /* todo: replace with correct icon color from DSGN-2071 */
        icon: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            '&:disabled': { color: theme.palette.semantic.colorBorder },
        },
        svgIcon: {
            '& .iconStroke': { stroke: theme.palette.semantic.colorForegroundDeEmp },
            '& .iconFill': { fill: theme.palette.semantic.colorForegroundDeEmp },
            '& .iconStrokeAndFill': {
                stroke: theme.palette.semantic.colorForegroundDeEmp,
                fill: theme.palette.semantic.colorForegroundDeEmp,
            },

            '&:disabled': {
                '& .iconStroke': { stroke: theme.palette.semantic.colorBorder },
                '& .iconFill': { fill: theme.palette.semantic.colorBorder },
                '& .iconStrokeAndFill': {
                    stroke: theme.palette.semantic.colorBorder,
                    fill: theme.palette.semantic.colorBorder,
                },
            },
        },
    });
