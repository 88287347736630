import {
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { UiLogo } from '../UiLogo';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        leftContainer: {
            background: theme.palette.semantic.colorPrimary,
            display: 'flex',
            width: '400px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'left',
            padding: '50px 50px',
        },
        leftContainerText: {
            color: theme.palette.semantic.colorBackground,
            fontFamily: 'Poppins',
            fontWeight: 800,
            fontSize: '56px',
            lineHeight: '64px',
            width: '250px',
            wordBreak: 'break-word',
            minHeight: '250px',
        },
        '@media all and (max-width: 1100px)': { leftContainer: { display: 'none' } },
    }),
);

interface IErrorObject {
    ErrorComponent: React.ReactNode;
    renderError: boolean;
}

export function UiSignupLayout({
    children, error,
}: PropsWithChildren<{ error?: IErrorObject }>) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const theme = useTheme();

    return <div className={classes.root}>
        <div className={classes.leftContainer}>
            <UiLogo
                color={theme.palette.semantic.colorBackground}
                data-cy="uipath-logo" />
            <Typography
                className={classes.leftContainerText}
                data-cy='ui-signup-title'
            >
                {translate({ id: 'CLIENT_OFFERS_REVAMP_SIDEBAR_TEXT' })}
            </Typography>
        </div>
        <div style={{
            flex: 1,
            overflow: 'auto',
        }} >
            {error?.renderError ? error.ErrorComponent : children}
        </div>
    </div>;
}
