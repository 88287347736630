import {
    PrivacyPolicyLinks,
    TermsOfUseLinks,
} from '@experiences/constants';
import {
    isValidLanguageCode,
    useLocalization,
    useSupportedLanguages,
    useUpdateLanguage,
} from '@experiences/locales';
import { useLocalizedLinks } from '@experiences/util';
import LanguageIcon from '@mui/icons-material/Language';
import {
    Link,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 'auto',
            paddingBottom: '8px',
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '4px',
        },
        languageIcon: {
            color: theme.palette.semantic.colorIconHover,
            marginRight: '5px',
        },
        links: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '23px',
        },
        privacyLink: { marginLeft: '23px' },
        languageSelect: { backgroundColor: 'transparent' },
    }),
);

export const BottomPageOptions: React.FC<{
    hideLanguageBottomPage?: boolean;
    hideLinksBottomPage?: boolean;
}> = ({
    hideLanguageBottomPage = false, hideLinksBottomPage = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const updateLanguage = useUpdateLanguage();

    const language = useLocalization(false);
    const getLocalizedLinks = useLocalizedLinks();

    const supportedLanguages = useSupportedLanguages();

    const onLanguageChange = useCallback(
        (event: any) => {
            if (event.target && isValidLanguageCode(event.target.value)) {
                const languageCode = event.target.value;
                updateLanguage(languageCode);
            }
        },
        [ updateLanguage ],
    );

    return (
        <div className={classes.root}>
            {!hideLanguageBottomPage && (
                <div className={classes.content}>
                    <LanguageIcon
                        fontSize="small"
                        className={classes.languageIcon} />
                    <TextField
                        select
                        id="languageOptions"
                        onChange={onLanguageChange}
                        defaultValue="en"
                        SelectProps={{
                            disableUnderline: true,
                            className: classes.languageSelect,
                            inputProps: { 'aria-label': translate({ id: 'CLIENT_LANGUAGE_SELECTED' }, { 0: language.toUpperCase() }) },
                        }}
                        value={language?.toLowerCase()}
                        data-cy="bottom-page-options-language-select"
                        variant="standard"
                    >
                        {supportedLanguages.map((option: { id: string; text: string }, i: number) => (
                            <MenuItem
                                id={option.id}
                                value={option.id}
                                key={i}
                                aria-label={`${option.text} ${option.id.toUpperCase()}`}
                            >
                                {option.text}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            )}
            {!hideLinksBottomPage && (
                <div className={classes.links}>
                    <Typography>
                        <Link
                            href={getLocalizedLinks(TermsOfUseLinks)}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-cy="bottom-page-options-terms-link"
                            underline="none"
                        >
                            {translate({ id: 'CLIENT_TERMS' })}
                        </Link>
                    </Typography>
                    <Typography className={classes.privacyLink}>
                        <Link
                            href={getLocalizedLinks(PrivacyPolicyLinks)}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-cy="bottom-page-options-privacy-link"
                            underline="none"
                        >
                            {translate({ id: 'CLIENT_PRIVACY' })}
                        </Link>
                    </Typography>
                </div>
            )}
        </div>
    );
};

