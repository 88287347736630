import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import {
    parseFile,
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { IActivateOfflineData } from '../../../common/interfaces/license.activation';
import { licenseManagementAccountUrl } from '../../../services/licensing/management/AccountService';
import { activateOffline } from '../../../services/licensing/management/ActivationService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import { getUserOrganizationInfo } from '../../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import {
    accountGlobalId,
    isHostModeSelector,
    profile,
} from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { useTenantOperationTrackerContext } from '../../tenants/TenantOperationTrackerContextProvider';
import LicenseActivationErrorDialogBody from './LicenseActivationErrorDialogBody';
import LicenseActivationStepsComponent from './LicenseActivationStepsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

enum haveLicenseFile {
    YES = 'yes',
    NO = 'no',
}

const ActivateLicenseOfflineComponent: React.FC<{
    location?: { state?: { previousLocation: string; isUpdateLicense: boolean;
        isTrialPerSkuActivation?: boolean; customLicenseCode?: string; }; };
}> = ({ location }) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const accountGlobalGuid = useSelector(accountGlobalId);
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);

    const [ isUpdateLicense, previousLocation, isTrialPerSkuActivation, customLicenseCode ] = useMemo(
        () => [
            location?.state?.isUpdateLicense,
            location?.state?.previousLocation,
            location?.state?.isTrialPerSkuActivation,
            location?.state?.customLicenseCode,
        ],
        [ location ],
    );
    const licenseCode = useMemo(
        () => customLicenseCode ? customLicenseCode : accountProfile.accountUserDto.licenseCode,
        [ accountProfile.accountUserDto.licenseCode, customLicenseCode ],
    );

    const [ loading, setLoading ] = useState(false);

    const methods = useForm<IActivateOfflineData>({
        mode: 'onChange',
        defaultValues: {
            accountGlobalId: '',
            licenseCode: isUpdateLicense ? licenseCode : '',
            haveFile: haveLicenseFile.YES,
            file: undefined,
        },
    });

    const {
        handleSubmit, watch, control, errors, register,
    } = useMemo(() => methods, [ methods ]);

    const {
        open, close,
    } = useModalState(getRoute(previousLocation ?? RouteNames.Licensing));

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: any) => {
            setLoading(true);
            try {
                if (data.file?.[0]) {
                    const licenseActivationData = await parseFile(data.file[0]);
                    await activateOffline(licenseActivationData, isHostMode, !!isTrialPerSkuActivation);
                    createNotification(
                        translate({
                            id: isUpdateLicense
                                ? 'CLIENT_LICENSE_UPDATED_SUCCESSFULLY'
                                : 'CLIENT_ACTIVATION_PLATFORM_LICENSED',
                        }),
                        notificationType.SUCCESS,
                    );
                    const userOrganizationInfo = await getUserOrganizationInfo();
                    if (userOrganizationInfo) {
                        await setUserProfile(userOrganizationInfo);
                    }
                    await mutate(licenseManagementAccountUrl);
                    await mutate(`${licenseManagementAccountUrl}/available`);
                    await mutate(trialServicesSwrKey);
                    refreshAfterComplete('', true);
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                if (errorObject.response?.status === 422) {
                    setLoading(false);
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                    });
                    return;
                }
                setErrorMessage(await getErrorMessage(error));
            }
            close();
            setLoading(false);
            return;
        },
        [
            close,
            createNotification,
            translate,
            createDialog,
            setErrorMessage,
            isUpdateLicense,
            isHostMode,
            getErrorMessage,
            getErrorObject,
            isTrialPerSkuActivation,
            refreshAfterComplete,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: isUpdateLicense ? 'CLIENT_UPDATE_LICENSE_OFFLINE' : 'CLIENT_ACTIVATE_OFFLINE' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={loading}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={clsx(classes.cancelButton)}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                    data-cy="save-submit-button"
                                >
                                    {translate({ id: isUpdateLicense ? 'CLIENT_UPDATE' : 'CLIENT_ACTIVATE_BUTTON' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    <div className={classes.input}>
                        <Typography className={clsx(classes.inputLabel)}>
                            {translate({ id: 'CLIENT_ACCOUNT_ID' })}
                        </Typography>
                        <TextField
                            id="accountId"
                            disabled
                            fullWidth
                            value={accountGlobalGuid}
                            variant="outlined"
                            data-cy="activate-online-account-id"
                        />
                    </div>
                    {watch('haveFile') === haveLicenseFile.NO && (
                        <div className={classes.input}>
                            <Typography className={clsx(classes.inputLabel)}>
                                {translate({ id: 'CLIENT_LICENSE_CODE' })}
                            </Typography>
                            <Controller
                                as={TextField}
                                control={control}
                                name="licenseCode"
                                variant="outlined"
                                error={!!errors.licenseCode}
                                helperText={
                                    errors.licenseCode?.type === 'license-format' &&
                                    translate({ id: 'CLIENT_ACTIVATION_INVALID_LICENSE_CODE' })
                                }
                                disabled={isUpdateLicense}
                                fullWidth
                                data-cy="licenseCode"
                            />
                        </div>
                    )}

                    <div className={classes.input}>
                        <Typography className={clsx(classes.inputLabel)}>
                            {translate({ id: 'CLIENT_LICENSE_FILE_QUESTION' })}
                        </Typography>
                        <Controller
                            as={
                                <RadioGroup row>
                                    <FormControlLabel
                                        value={haveLicenseFile.YES}
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_YES' })}
                                        data-cy="have-file"
                                    />
                                    <FormControlLabel
                                        value={haveLicenseFile.NO}
                                        control={<Radio color="primary" />}
                                        label={translate({ id: 'CLIENT_NO' })}
                                        data-cy="not-have-file"
                                    />
                                </RadioGroup>
                            }
                            name="haveFile"
                            control={control}
                        />
                    </div>
                    {watch('haveFile') === haveLicenseFile.NO &&
                        <LicenseActivationStepsComponent isTrialPerSkuActivation={!!isTrialPerSkuActivation} />}
                    {watch('haveFile') === haveLicenseFile.YES && (
                        <div className={classes.input}>
                            <Typography className={clsx(classes.inputLabel)}>
                                {translate({ id: 'CLIENT_UPLOAD_LICENSE' })}
                            </Typography>
                            <input
                                ref={register}
                                type="file"
                                name="file"
                                required={true}
                                data-cy="file-input" />
                        </div>
                    )}
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default ActivateLicenseOfflineComponent;
