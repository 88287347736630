import { ITrialServices } from '@experiences/interfaces';

import {
    get,
    post,
} from '../utility/Requests.default';
import { licenseManagementAccountUrl } from './management/AccountService';

export const trialServicesSwrKey = 'trial-services';

export const subscriptionCodeToServiceName: { [id: string]: string } = {
    'TRIAL-AHUB': 'automationhub',
    'TRIAL-ANL': 'insights',
};

export function getTrialServices() {
    return get<ITrialServices>(`${licenseManagementAccountUrl}/trial-services`);
}

export function getTrialPerSkuLicensesInGrace() {
    return get<string[]>(`${licenseManagementAccountUrl}/trial-per-sku-licenses-in-grace`);
}

export function requestTrialLicense(subscriptionCode: string) {
    return post(`${licenseManagementAccountUrl}/request-trial-license`, { body: { serviceSubscription: subscriptionCode } });
}
