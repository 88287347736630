export interface IProductConfiguration {
    code: string;
    hasAPIKey?: boolean;
    renderWithSubscriptionPlan?: boolean;
    hideProgress?: boolean;
    hideProgressForAccountType?: 'ENTERPRISE' | 'TRIAL' | 'COMMUNITY';
    hideQuantity?: boolean;
    hideQuantityForAccountType?: 'ENTERPRISE' | 'TRIAL' | 'COMMUNITY';
    hideInHomepage?: boolean;
    hideInService?: boolean;
    type?: 'USER' | 'STANDARD' | 'SERVICE' | 'CONSUMPTION' | 'UNLIMITED';
    flavor?: 'NAMED' | 'MULTIUSER';
    showOnlyTotal?: boolean;
    /**
     * When set, the API Key displayed for this product is identified using this code rather than the actual product code.
     * E.g. for AI units the API Key code will be MLSERVICE_TIEMODEL, because if borrows the API key from Document Understanding
     */
    customAPIKeyCode?: string;
}

const SortedRobotsAndServicesProductConfigurations: IProductConfiguration[] = [
    { code: 'UNATT' },
    { code: 'NONPR' },
    { code: 'TAUNATT' },
    { code: 'AIW' },
    { code: 'AIG' },
    {
        code: 'DSU',
        renderWithSubscriptionPlan: true,
    },
    { code: 'AHUB' },
    { code: 'TMNG' },
    { code: 'PM' },
    {
        code: 'ANL',
        hideQuantity: true,
        hideProgress: true,
        hideInHomepage: true,
        hideInService: true,
        type: 'UNLIMITED',
    },
    {
        code: 'TIE',
        hasAPIKey: true,
        hideProgressForAccountType: 'COMMUNITY',
        renderWithSubscriptionPlan: true,
        hideQuantityForAccountType: 'COMMUNITY',
    },
    {
        code: 'CV',
        hasAPIKey: true,
        hideProgress: true,
        showOnlyTotal: true,
    },
    { code: 'ACR' },
    { code: 'TMH' },
    { code: 'RU' },
    {
        code: 'AIU',
        hasAPIKey: true,
        customAPIKeyCode: 'TIE',
        hideInHomepage: false,
    },
];

export const getSortedRobotsAndServicesProductConfigurations = () =>
    SortedRobotsAndServicesProductConfigurations.map(config => {
        return { ...config };
    });

const SortedUserBundleConfigurations: IProductConfiguration[] = [
    { code: 'ATTUNU' },
    {
        code: 'ATTUCU',
        flavor: 'MULTIUSER',
    },
    { code: 'CTZDEVNU' },
    {
        code: 'CTZDEVCU',
        flavor: 'MULTIUSER',
    },
    { code: 'RPADEVNU' },
    {
        code: 'RPADEVCU',
        flavor: 'MULTIUSER',
    },
    { code: 'RPADEVPRONU' },
    {
        code: 'RPADEVPROCU',
        flavor: 'MULTIUSER',
    },
    { code: 'TSTDEVPRONU' },
    { code: 'TSTNU' },
    { code: 'ACNU' },
    {
        code: 'ACCU',
        flavor: 'MULTIUSER',
    },
    { code: 'IDU' },
    { code: 'PMD' },
    { code: 'PMBU' },
    {
        code: 'AKIT',
        hideQuantity: true,
        hideProgress: true,
        hideInHomepage: true,
    },
];
SortedUserBundleConfigurations.forEach(pc => (pc.type = 'USER'));

export const getSortedUserBundleConfigurations = () =>
    SortedUserBundleConfigurations.map(config => {
        return { ...config };
    });

export const getAllProductsConfigurations = (includeUserProducts: boolean) => {
    const robotsAndServices = getSortedRobotsAndServicesProductConfigurations();
    if (!includeUserProducts) {
        return robotsAndServices;
    }
    const userProducts = getSortedUserBundleConfigurations();
    return userProducts.concat(robotsAndServices);
};

const LicensedProductsConfiguration = new Map<string, IProductConfiguration>(
    SortedUserBundleConfigurations.concat(SortedRobotsAndServicesProductConfigurations).map(pc => [ pc.code, pc ]),
);

export const getLicensedProductsConfiguration = () => new Map(LicensedProductsConfiguration);
