import { createStyles } from '@mui/material';

export default createStyles({
    styleOverrides: {
        root: {
            maxWidth: 'unset',
            minWidth: 'min-content',
        },
    },
});
