import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useEffect } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useEcommerce } from '../helpers/useEcommerce';
import { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({
        taxIdController: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        linkEmailsCheckbox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        smallIcon: {
            fontSize: '1.2em',
            marginLeft: '4px',
        },
    }),
);

const EcommerceTaxId: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        watch, control, errors,
    } =
    useFormContext<ISubscriptionForm>();

    const {
        country: currentCountry,
        zipcode: currentZipCode,
    } = watch();

    const { setEcommerceZipCodeValue } = useEcommerce();

    const [ debouncedZipCode ] = useDebounce(currentZipCode, 1000);
    useEffect(() => {
        if (debouncedZipCode) {
            setEcommerceZipCodeValue(debouncedZipCode);
        }
    }, [ setEcommerceZipCodeValue, errors.zipcode, debouncedZipCode ]);

    return (
        <div className={classes.taxIdController}>
            <div className={classes.linkEmailsCheckbox}>
                <Typography className="MuiInputLabel-root">
                    {translate({ id: 'CLIENT_TAX_ID' })}
                </Typography>
                <Tooltip
                    title={translate({ id: 'CLIENT_TAX_ID_TOOLTIP' })}
                    data-cy="ecommerce-form-tax-id-tooltip">
                    <InfoOutlined className={classes.smallIcon} />
                </Tooltip>
            </div>
            <Controller
                as={TextField}
                control={control}
                variant="outlined"
                rules={{ required: false }}
                id="taxId"
                name="taxId"
                error={!!errors.taxId}
                InputProps={{ className: 'Tall' }}
                placeholder={translate({ id: 'CLIENT_TAX_ID_PLACEHOLDER' })}
                data-cy="ecommerce-form-tax-id"
                disabled={disabled}
                fullWidth={currentCountry !== 'US' && currentCountry !== 'CA'}
            />
        </div>
    );
};

export default EcommerceTaxId;
