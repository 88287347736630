export enum ActionTypes {
    NS_INITIALIZE_NOTIFICATION_SETTINGS,
    NS_SET_TAB_INDEX,
    NS_CHANGE_TOPIC_SUBSCRIPTION,
    NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED,
    NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED,
    NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION,
    NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED,
    NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED,
    NS_TOGGLE_CHANNEL_MODE,
    NS_TOGGLE_CHANNEL_MODE_UPDATED,
    NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED,
    NS_RESTORE_DEFAULT_SUBSCRIPTIONS,
    NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED,
    NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED,
    NS_TOGGLE_PUBLISHER_SUBSCRIPTION,
    NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED,
    NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED,
}
