import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import GlobalStyles from '@experiences/theme';
import { Typography } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../../store/selectors';
import UiLicenseExpiredBanner from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiAccordion } from '../licensing/UiAccordion';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SessionPolicyComponent from '../securitySettings/subcomponents/SessionPolicyComponent';
import ExternalProvidersComponent from './subcomponents/ExternalProvidersComponent.default';
import InstallationKeyComponent from './subcomponents/InstallationKeyComponent';
import SecuritySettingsComponent from './subcomponents/SecuritySettingsComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        authSettingsPage: { marginTop: '14px' },
        header: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
}));

const AuthSettingsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);
    const organizationName = useOrganizationName();

    const EnableSecuritySettingsSessionPolicy = useFeatureFlagValue(Features.EnableSecuritySettingsSessionPolicy.name);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.AuthSettings,
            name: translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer header={AdminBreadCrumbs(breadCrumbLinks)}>
                <div
                    data-cy="auth-settings-page"
                    className={classes.authSettingsPage}>
                    {isAdmin && (
                        <Typography
                            className={classes.header}
                            role="heading"
                            aria-level={2}>
                            {translate({ id: 'CLIENT_IDENTITY_MANAGEMENT' })}
                        </Typography>
                    )}
                    {process.buildConfigs.enableInstallationKey && isHostMode && <InstallationKeyComponent />}
                    {isAdmin && (
                        <UiAccordion titleTranslationCode="CLIENT_EXTERNAL_PROVIDERS">
                            <ExternalProvidersComponent />
                        </UiAccordion>
                    )}
                    <UiAccordion titleTranslationCode="CLIENT_SECURITY">
                        <SecuritySettingsComponent />
                    </UiAccordion>
                    {EnableSecuritySettingsSessionPolicy && process.buildConfigs.showSessionPolicy && (
                        <UiAccordion titleTranslationCode="CLIENT_SESSION_POLICY">
                            <SessionPolicyComponent />
                        </UiAccordion>
                    )}
                </div>
            </UiPageContainer> :

            <UiPanel
                licenseInGracePeriodBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseExpiredBanner />}
                licenseOverAllocationBanner={!process.buildConfigs.disableUserLicensing && <UiOverAllocationBanner />}
                header={{ title: translate({ id: 'CLIENT_SECURITY_SETTINGS' }) }}
                data-cy="security-settings-panel"
            >
                <div
                    data-cy="auth-settings-page"
                    className={classes.authSettingsPage}>
                    {isAdmin && (
                        <Typography
                            className={classes.header}
                            role="heading"
                            aria-level={2}>
                            {translate({ id: 'CLIENT_IDENTITY_MANAGEMENT' })}
                        </Typography>
                    )}
                    {process.buildConfigs.enableInstallationKey && isHostMode && <InstallationKeyComponent />}
                    {isAdmin && (
                        <UiAccordion titleTranslationCode="CLIENT_EXTERNAL_PROVIDERS">
                            <ExternalProvidersComponent />
                        </UiAccordion>
                    )}
                    <UiAccordion titleTranslationCode="CLIENT_SECURITY">
                        <SecuritySettingsComponent />
                    </UiAccordion>
                    {EnableSecuritySettingsSessionPolicy && process.buildConfigs.showSessionPolicy && (
                        <UiAccordion titleTranslationCode="CLIENT_SESSION_POLICY">
                            <SessionPolicyComponent />
                        </UiAccordion>
                    )}
                </div>
            </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <AuthSettingsPageComponent />
        {route && renderRoutes(route.routes)}
    </>
);

