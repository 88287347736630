import { IChangePasswordDto } from '../common/interfaces/cis/account';
import { IResetPasswordData } from '../component/users/subcomponents/ResetPasswordComponent';

export function mapResetPasswordDataToChangePasswordDto(data: IResetPasswordData, userId: string): IChangePasswordDto {
    return {
        newPassword: data.password,
        oldPassword: data.currentPassword,
        userId,
    };
}
