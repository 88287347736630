import { AccountLicense } from '@experiences/constants';
import { useCentralErrorSetter } from '@experiences/error';
import GlobalStyles from '@experiences/theme';
import {
    useLocalizedLinks,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { DirectorySSOLink } from '../../../common/constants/DirectorySSO';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import IExternalIdentity, { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    authenticationSettingUrl,
    getAllAuthenticationSettings,
    IBulkAuthenticationSettingResponse,
} from '../../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';
import DeleteSSOConfigurationDialogBody from './DeleteSSOConfigurationDialogBody';
import DirectoryIntegration from './DirectoryIntegration';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        centerLoader: { margin: 'auto' },
        ssoConfigureButton: { marginRight: '8px' },
        radioPrimary: {
            fontWeight: 600,
            fontSize: '14px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px',
        },
        configureSSO: { marginTop: '24px' },
        spacer: { marginTop: '8px' },
        buttonWidth: { width: '160px' },
        link: {
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        configuredSSO: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        ssoText: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '16px',
            marginBottom: '4px',
            marginTop: '8px',
        },
    }),
}));

export const DirectoryIntegrationComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();
    const getLocalizedLink = useLocalizedLinks();

    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);
    const { isOffersRevampAndCommunity } = useCheckLicense();

    const {
        data: authenticationSetting,
        mutate,
    } = useSWR<IBulkAuthenticationSettingResponse, Error>(
        [ `${authenticationSettingUrl}/getAll`, partitionGlobalId ],
        getAllAuthenticationSettings,
    );

    const {
        isAAD, isSaml,
    } = useMemo(
        () => ({
            isAAD: authenticationSetting?.['aad'],
            isSaml: authenticationSetting?.['Saml2'],
        }),
        [ authenticationSetting ],
    );

    const { isSSO } = useMemo(
        () => ({ isSSO: isAAD || isSaml }),
        [ isAAD, isSaml ],
    );

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!authenticationSetting?.['Saml2']?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(authenticationSetting?.['Saml2']?.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            const errorMessage = (error as Error).message;
            setErrorMessage(errorMessage);
        }
    }, [ authenticationSetting, setErrorMessage ]);

    const ssoOtherOptions = useCallback((str: string) =>
        <Typography
            display="inline"
            className={classes.link}
            onClick={() => history.push(getRoute(RouteNames.ConfigureSSO))}>
            {str}
        </Typography>
    , [ classes.link, getRoute, history ]);

    const refreshConfig = useCallback(() => {
        mutate();
    }, [ mutate ]);

    const handleDelete = useCallback(async () => {
        await createDialog({
            title: translate({ id: isSaml ? 'CLIENT_DELETE_SAML_SSO_CONFIGURATION' : 'CLIENT_DELETE_AZURE_AD_SSO_CONFIGURATION' }),
            customDialogContent: DeleteSSOConfigurationDialogBody,
            customDialogContentProps: {
                refreshCallback: () => {
                    refreshConfig();
                },
            },
            icon: 'error',
        });
    }, [ createDialog, isSaml, refreshConfig, translate ]);

    const directoryIntegrationDescription = useMemo(() => {
        if (isAAD) {
            return (
                <>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-application-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_AZURE_APPLICATION_ID' })}: `}
                        </strong>
                        {(authenticationSetting?.['aad']?.externalIdentityProviderDto as IExternalIdentity)?.clientId}
                    </Typography>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-tenant-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_AZURE_TENANT_ID' })}: `}
                        </strong>
                        {
                            (authenticationSetting?.['aad']?.externalIdentityProviderDto as IExternalIdentity)?.authority?.match(
                                /.com\/(.*)\/v/,
                            )?.[1]
                        }
                    </Typography>
                </>
            );
        } else if (isSaml) {
            return (
                <>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-sign-on-url">
                        <strong>
                            {`${translate({ id: 'CLIENT_SIGN_ON_URL' })}: `}
                        </strong>
                        {externalIdentityProviderDtoSettings?.SingleSignOnServiceUrl}
                    </Typography>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-identity-provider-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}: `}
                        </strong>
                        {externalIdentityProviderDtoSettings?.IdentityProviderEntityId}
                    </Typography>
                </>
            );
        }
    }, [ authenticationSetting, classes, externalIdentityProviderDtoSettings, isAAD, isSaml, translate ]);

    const directoryIntegrationType = useMemo(() => {
        return (authenticationSetting?.['Saml2'] ?? authenticationSetting?.['aad'])?.authenticationSettingType?.toUpperCase()
        ?? '';
    }, [ authenticationSetting ]);

    return <div className={classes.configureSSO}>
        <div className={classes.configuredSSO}>
            <Typography className={classes.headerText}>
                {translate({ id: 'CLIENT_SSO_CONFIGURATION_HEADER' })}
            </Typography>
            {isOffersRevampAndCommunity &&
                <Tooltip title={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_ACCESS' })}>
                    <UiUpgradeChip
                        style={{ marginLeft: '8px' }}
                        licenseType={AccountLicense.ENTERPRISE} />
                </Tooltip>}
        </div>
        {isSSO ? (
            <>
                <DirectoryIntegration
                    title={translate({ id: `CLIENT_${directoryIntegrationType}_INTEGRATION` })}
                    enableChip={true}
                    description={directoryIntegrationDescription}
                    actions={
                        <>
                            {isSaml && !isHostMode && <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                data-cy="saml-provisioning-rules-button"
                                onClick={() => history.push(getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules))}
                            >
                                {translate({ id: 'CLIENT_SAML_VIEW_PROVISIONING_RULES' })}
                            </Button>}
                            <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                data-cy="sso-edit-button"
                                onClick={() =>
                                    history.push({
                                        pathname: getRoute(`${RouteNames.SecuritySettings}/configure/${directoryIntegrationType.toLowerCase()}`),
                                        state: {
                                            authenticationSetting: authenticationSetting?.['Saml2']
                                                ?? authenticationSetting?.['aad']
                                                ?? authenticationSetting?.['No Setting'],
                                            showPreReqs: false,
                                        },
                                    })}
                            >
                                {translate({ id: 'CLIENT_EDIT' })}
                            </Button>
                            <UiDeleteButton
                                variant="outlined"
                                data-cy="sso-delete-button"
                                onClick={handleDelete}
                            >
                                {translate({ id: 'CLIENT_DELETE' })}
                            </UiDeleteButton>
                        </>
                    }
                />
                <Typography className={classes.spacer}>
                    <FormattedMessage
                        id="CLIENT_SSO_OTHER_OPTIONS"
                        values={{ a: (str: string) => ssoOtherOptions(str) }}
                    />
                </Typography>
            </>
        ) :
            <>
                <div className={classes.spacer}>
                    <FormattedMessage
                        id="CLIENT_SSO_CONFIGURATION_DESCRIPTION"
                        values={{
                            a: (msg: any) =>
                                (
                                    <a
                                        className={classes.a}
                                        href={getLocalizedLink(DirectorySSOLink)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {msg}
                                    </a>
                                ) as any,
                        }}
                    />
                </div>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(getRoute(RouteNames.ConfigureSSO))}
                    data-cy="configure-sso-button"
                    className={classes.spacer}
                    style={{ display: 'block' }}
                >
                    {translate({ id: isOffersRevampAndCommunity ? 'CLIENT_LEARN_MORE' : 'CLIENT_CONFIGURE_SSO' })}
                </Button>
            </>}
    </div>;
};
