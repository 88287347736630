import {
    useEffect,
    useState,
} from 'react';

import {
    getFeatureFlagValue,
    isLocalFeatureFlagsEnabled,
} from './FeatureFlagUtil.default';

const useFeatureFlagValue = (featureFlagKey: string) => {
    const [ value, setValue ] = useState<boolean>(getFeatureFlagValue(featureFlagKey));

    useEffect(() => {
        function updateFeatureFlagValue() {
            const flagValueChanged = localStorage.getItem(featureFlagKey)?.toLowerCase() !== value.toString().toLowerCase();

            if (isLocalFeatureFlagsEnabled && localStorage.getItem(featureFlagKey) != null && flagValueChanged) {
                setValue(localStorage.getItem(featureFlagKey) === 'true');
            }
        }

        window.addEventListener('storage', updateFeatureFlagValue);

        return () => window.removeEventListener('storage', updateFeatureFlagValue);
    }, [ featureFlagKey, value ]);

    return value;
};

export default useFeatureFlagValue;
