import { useRouteMatch } from 'react-router';

import * as RouteNames from '../constants/RouteNames';

const useIsNoShowLeftNav = () => {
    return !!useRouteMatch([
        RouteNames.Profile,
        RouteNames.NotificationSettings,
        RouteNames.PrivacyAndSecuritySettings,
        RouteNames.OrganizationMigration,
        RouteNames.PersonalAccessToken,
    ]);
};

export default useIsNoShowLeftNav;
