import {
    getTaxPercentageString,
    ITaxResponse,
} from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';
import { EcommercePrice } from './EcommercePrice';

const useStyles = makeStyles(theme =>
    createStyles({
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        subtotalRow: { marginBottom: '12px' },
        subtotalText: { color: theme.palette.semantic.colorForeground },
        dueNow: {
            fontSize: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        tax: {
            marginTop: '2px',
            marginLeft: '3px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        circularLoading: { borderRadius: '30px' },
    }),
);

const EcommerceOrderSummaryFooter: React.FC<{
    tax?: ITaxResponse;
    subscriptionPriceToDisplay: number;
    productPricesLoading?: boolean;
    taxLoading?: boolean;
    billingPeriod: string;
    currency: string;
}> = ({
    tax,
    subscriptionPriceToDisplay,
    productPricesLoading,
    taxLoading,
    billingPeriod,
    currency,
}) => {
    const classes = useStyles();
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();

    const taxPercentageString = useMemo(() => {
        return tax !== undefined ? getTaxPercentageString(tax.taxRate, language) : undefined;
    }, [ tax, language ]);

    return (
        <>
            <div className={clsx(classes.row, classes.subtotalRow, classes.subtotalText)}>
                <Typography className={classes.subtotalText}>
                    {translate({ id: 'CLIENT_SUBTOTAL' })}
                </Typography>
                <EcommercePrice
                    value={subscriptionPriceToDisplay}
                    loading={productPricesLoading}
                    billingPeriod={billingPeriod}
                    currency={currency}
                    dataCy="subtotal-value"
                />
            </div>
            {tax !== undefined || taxLoading ? (
                <div
                    className={clsx(classes.row, classes.subtotalRow)}
                    data-cy="tax-value">
                    <Typography>
                        {translate({ id: 'CLIENT_TAX_TITLE' }) +
                        ' ' +
                        translate({ id: 'CLIENT_ESTIMATED' }) +
                        ': ' +
                        taxPercentageString}
                    </Typography>
                    <EcommercePrice
                        value={tax?.taxValue}
                        loading={taxLoading}
                        billingPeriod={billingPeriod}
                        currency={currency}
                    />

                </div>
            ) : undefined}
            <div className={classes.row}>
                <div className={classes.row}>
                    <Typography className={classes.dueNow}>
                        {translate({ id: 'CLIENT_DUE_NOW' })}
                    </Typography>
                    {tax !== undefined ? (
                        <Typography className={classes.tax}>
                            {translate({ id: 'CLIENT_ESTIMATED' })}
                        </Typography>
                    ) : undefined}
                </div>
                <div className={classes.row}>
                    {(taxLoading || productPricesLoading) ? (
                        <CircularProgress
                            size={16}
                            className={classes.circularLoading} />
                    ) : (
                        <Typography
                            className={classes.dueNow}
                            data-cy="due-now-value">
                            {getPriceString(subscriptionPriceToDisplay, currency, language, tax?.taxValue)}
                        </Typography>
                    )}
                    {tax !== undefined ? null : (
                        <Typography className={classes.tax}>
                            {translate({ id: 'CLIENT_TAX' })}
                        </Typography>
                    )}
                </div>
            </div>
        </>
    );
};

export default EcommerceOrderSummaryFooter;
