import { AccountLicense } from '@experiences/constants';
import {
    useModalState,
    useRouteResolver,
} from '@experiences/util';
import {
    Card,
    CardContent,
    Chip,
    Theme,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import configureSSOIcon1 from '../../../images/configureSSOIcon1.svg';
import configureSSOIcon2 from '../../../images/configureSSOIcon2.svg';
import configureSSOIcon3 from '../../../images/configureSSOIcon3.svg';
import {
    AuthenticationSettingType,
    authenticationSettingUrl,
    getAllAuthenticationSettings,
    IBulkAuthenticationSettingResponse,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import { UiDrawer } from '../../common/UiDrawer';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';

interface IUiCardThemeProps {
    clickable: boolean;
}

const useStyles = makeStyles<Theme, IUiCardThemeProps>(theme =>
    createStyles({
        card: props => ({
            '&:hover': props.clickable ?
                {
                    boxShadow: `0px 1px 10px ${theme.palette.semantic.colorBackgroundGray}, 0px 4px 5px ${theme.palette.semantic.colorBackgroundGray}, 0px 2px 4px -1px ${theme.palette.semantic.colorBackgroundGray}`,
                    cursor: 'pointer',
                } : undefined,
            boxShadow: `0px 2px 1px -1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 3px ${theme.palette.semantic.colorBackgroundGray}`,
            border: `1px ${theme.palette.semantic.colorBorderDisabled}`,
            maxWidth: '300px',
        }),
        mainContent: {
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            margin: '24px 24px 24px 0',
        },
        headerText: { fontWeight: 600 },
        spacer: { marginTop: '24px' },
        cardSection: {
            display: 'flex',
            flexDirection: 'row',
        },
        cardTitleText: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        cardDescriptionText: {
            marginTop: '8px',
            fontWeight: 400,
            fontSize: '14px',
        },
        ssoAdvantage: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        chooseSSOTitle: {
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'row',
        },
    }),
);

export const ConfigureSSOComponent: React.FC = () => {

    const history = useHistory();
    const getRoute = useRouteResolver();

    const { formatMessage: translate } = useIntl();
    const { isOffersRevampAndCommunity } = useCheckLicense();

    const partitionGlobalId = useSelector(accountGlobalId);

    const classes = useStyles({ clickable: !isOffersRevampAndCommunity });

    const { data: authenticationSetting } = useSWR<IBulkAuthenticationSettingResponse, Error>(
        [ `${authenticationSettingUrl}/getAll`, partitionGlobalId ],
        getAllAuthenticationSettings,
    );

    const {
        open, close,
    } = useModalState(RouteNames.SecuritySettings);

    const ssoAdvantageDisplay = useCallback((label: string, image: any) => {
        return (
            <span className={classes.ssoAdvantage}>
                <img
                    style={{ marginRight: '8px' }}
                    src={image}
                    alt='' />
                <Typography>
                    {translate({ id: label })}
                </Typography>
            </span>
        );
    }, [ classes.ssoAdvantage, translate ]);

    const ssoAdvantageList = useMemo(() =>
        [
            {
                label: 'CLIENT_SSO_ADVANTAGE_1',
                icon: configureSSOIcon1,
            },
            {
                label: 'CLIENT_SSO_ADVANTAGE_2',
                icon: configureSSOIcon2,
            },
            {
                label: 'CLIENT_SSO_ADVANTAGE_3',
                icon: configureSSOIcon3,
            },
        ], [],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_SSO_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="large"
        >
            <div className={classes.mainContent}>
                <Typography className={classes.headerText}>
                    {translate({ id: 'CLIENT_SSO_ADVANTAGES' })}
                </Typography>
                <div>
                    {ssoAdvantageList.map((item, i) => <div key={`sso-advantage-${i}`}>
                        {ssoAdvantageDisplay(item.label, item.icon)}
                    </div>)}
                </div>
                <Typography className={clsx(classes.headerText, classes.spacer)}>
                    {translate({ id: 'CLIENT_PREREQ' })}
                </Typography>

                <ul style={{ lineHeight: '24px' }}>
                    {[ 1, 2, 3, 4 ].map((number) => <li key={number}>
                        <Typography>
                            {translate({ id: 'CLIENT_SSO_PREREQ_' + String(number) })}
                        </Typography>
                    </li>)}
                </ul>
                <div className={classes.chooseSSOTitle}>
                    <Typography className={classes.headerText}>
                        {translate({ id: 'CLIENT_CHOOSE_SSO_OPTION' })}
                    </Typography>
                    {isOffersRevampAndCommunity &&
                        <UiUpgradeChip
                            style={{ marginLeft: '8px' }}
                            licenseType={AccountLicense.ENTERPRISE} />}
                </div>
                <div className={clsx(classes.cardSection, classes.spacer)}>
                    <Card
                        onClick={() => !isOffersRevampAndCommunity ?
                            history.push({
                                pathname: getRoute(`${RouteNames.SecuritySettings}/configure/${AuthenticationSettingType.SAML}`),
                                state: {
                                    authenticationSetting: authenticationSetting?.['Saml2']
                                        ?? authenticationSetting?.['aad']
                                        ?? authenticationSetting?.['No Setting'],
                                    showPreReqs: !authenticationSetting?.['Saml2'],
                                },
                            }) : undefined}
                        data-cy='saml-configure-card'
                        className={classes.card}
                        style={{ marginRight: '16px' }}
                        aria-label="saml-configure-card">
                        <CardContent>
                            <div
                                className={classes.cardSection}
                                style={{ alignItems: 'center' }}>
                                <Typography
                                    className={classes.cardTitleText}
                                    component="div">
                                    {translate({ id: 'CLIENT_SAML_INTEGRATION' })}
                                </Typography>
                                {authenticationSetting?.['Saml2']?.authenticationSettingType?.toLowerCase()
                                    === AuthenticationSettingType.SAML &&
                                <Chip
                                    style={{ marginLeft: '8px' }}
                                    variant="outlined"
                                    size="small"
                                    label={translate({ id: 'CLIENT_ENABLED' })}
                                    className="success-mini"
                                    data-cy="saml-configure-card-enabled" />}
                            </div>
                            <Typography
                                className={classes.cardDescriptionText}>
                                {translate({ id: 'CLIENT_SAML2_CARD_DESCRIPTION' })}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        onClick={() => !isOffersRevampAndCommunity ?
                            history.push({
                                pathname: getRoute(`${RouteNames.SecuritySettings}/configure/${AuthenticationSettingType.AAD}`),
                                state: {
                                    authenticationSetting: authenticationSetting?.['aad']
                                        ?? authenticationSetting?.['Saml2']
                                        ?? authenticationSetting?.['No Setting'],
                                    showPreReqs: false,
                                },
                            }) : undefined}
                        data-cy='aad-configure-card'
                        className={classes.card}
                        aria-label="aad-configure-card">
                        <CardContent>
                            <div
                                className={classes.cardSection}
                                style={{ alignItems: 'center' }}>
                                <Typography
                                    className={classes.cardTitleText}
                                    component="div">
                                    {translate({ id: 'CLIENT_AAD_INTEGRATION' })}
                                </Typography>
                                {authenticationSetting?.['aad']?.authenticationSettingType?.toLowerCase()
                                    === AuthenticationSettingType.AAD &&
                                <Chip
                                    style={{ marginLeft: '8px' }}
                                    variant="outlined"
                                    size="small"
                                    label={translate({ id: 'CLIENT_ENABLED' })}
                                    className="success-mini"
                                    data-cy="aad-configure-card-enabled" />}
                            </div>
                            <Typography
                                className={classes.cardDescriptionText}>
                                {translate({ id: 'CLIENT_AAD_CARD_DESCRIPTION' })}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </UiDrawer>
    );
};
