import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import { Button } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
    ExternalUserMappingStrategy,
    Saml2BindingType,
    Usage,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { ISamlFormData } from '../../../common/interfaces/cis/saml';
import { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    AuthenticationSettingType,
    createAuthenticationSettingSaml2,
    createDirectoryIntegrationSettingSaml2,
    IAuthenticationSettingResponse,
    updateAuthenticationSettingSaml2,
    updateDirectoryIntegrationSettingSaml2,
} from '../../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { mapSamlFormDataToAuthSettingPayload } from '../../../util/setting/AuthSettingUtil';
import { useShowSuccessConfirmationDialog } from '../../authsettings/subcomponents/useShowSuccessConfirmationDialog';
import UiForm from '../../common/UiForm';
import SecuritySettingsSAMLAdvancedDetailsForm from './SecuritySettingsSAMLAdvancedDetails';
import SecuritySettingsSAMLFormStepper from './SecuritySettingsSAMLFormStepper';
import SecuritySettingsSAMLGeneralForm from './SecuritySettingsSAMLGeneralForm';
import SecuritySettingsSAMLProvisioningSettings from './SecuritySettingsSAMLProvisioningSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        hiddenForm: {
            visibility: 'hidden',
            width: '0px',
            height: '0px',
            overflow: 'hidden',
            opacity: 0,
            transition: 'width 300ms 100ms ease, opacity 300ms 100ms ease',
        },
        visibleForm: {
            width: 'auto',
            paddingBottom: '64px',
            opacity: 1,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
    }),
);

const SecuritySettingsSAMLForm: React.FC<{
    authenticationSetting: IAuthenticationSettingResponse;
    activeStep: number;
    handleError: (error: any) => Promise<void>;
}> = ({
    authenticationSetting, activeStep, handleError,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const openShowSuccessConfirmationDialog = useShowSuccessConfirmationDialog(AuthenticationSettingType.SAML);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);

    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const disableForm = useMemo(() => !isAdmin, [ isAdmin ]);

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!authenticationSetting?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(authenticationSetting.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            handleError(error);
        }
    }, [ authenticationSetting, handleError ]);

    const formMethods = useForm<ISamlFormData>({
        mode: 'onChange',
        defaultValues: {
            SingleSignOnServiceUrl: externalIdentityProviderDtoSettings?.SingleSignOnServiceUrl ?? '',
            IdentityProviderEntityId: externalIdentityProviderDtoSettings?.IdentityProviderEntityId ?? '',
            SigningCertificateLocation: {
                CertificateText: externalIdentityProviderDtoSettings?.SigningCertificateLocation?.CertificateText
                ?? '',
            },
            IdentityProviderMetadataUrl: externalIdentityProviderDtoSettings?.IdentityProviderMetadataUrl ?? '',
            AllowUnsolicitedAuthnResponse:
                externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse != null
                    ? externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse
                    : true,
            ExternalAuthUserMappingStrategy:
                externalIdentityProviderDtoSettings?.ExternalAuthUserMappingStrategy ?? ExternalUserMappingStrategy.ByUserEmail,
            Saml2BindingType: externalIdentityProviderDtoSettings?.Saml2BindingType ?? Saml2BindingType.HttpRedirect,
            ServiceCertificateUsage:
                externalIdentityProviderDtoSettings?.ServiceCertificateUsage ?? Usage.SigningAndEncryption,
            ProvisioningSetting: {
                AccountLinkConfirmation:
                    externalIdentityProviderDtoSettings?.ProvisioningSetting?.AccountLinkConfirmation ?? false,
                AllowedDomains: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AllowedDomains?.join(', ') ?? '',
                AttributeMapper: {
                    FirstName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.FirstName ?? '',
                    LastName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.LastName ?? '',
                    Email: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Email ?? '',
                    JobTitle: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.JobTitle ?? '',
                    City: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.City ?? '',
                    Department: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Department ?? '',
                    DisplayName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.DisplayName ?? '',
                },
            },
        },
    });

    const {
        handleSubmit, watch, trigger, formState,
    } = formMethods;

    const {
        errors, isDirty,
    } = formState;

    const [ loading, setLoading ] = useState(false);

    const cancel = useCallback(() => {
        history.push(getRoute((process.buildConfigs.isOnPrem && !isAdminRevampEnabled && !EnableSecuritySettingsRevamp)
            ? RouteNames.AuthSettings : RouteNames.SecuritySettings));
    }, [ history, getRoute, isAdminRevampEnabled, EnableSecuritySettingsRevamp ]);

    const hasErrorOnStep = useCallback(() => {
        if (
            activeStep === 0 &&
            (
                errors.SingleSignOnServiceUrl ||
                errors.IdentityProviderEntityId ||
                errors.SigningCertificateLocation?.CertificateText
            )
        ) {
            return true;
        }

        if (activeStep === 1 && errors.ProvisioningSetting) {
            return true;
        }

        return false;
    }, [ activeStep, errors ]);

    const navigateToStep = useCallback(
        (step: number) => {
            trigger();

            if (hasErrorOnStep() && step > activeStep) {
                return;
            }

            history.replace({
                ...history.location,
                state: {
                    ...(history.location.state as any),
                    samlFormActiveStep: step,
                },
            });
        },
        [ activeStep, hasErrorOnStep, history, trigger ],
    );

    const onSubmit = useCallback(
        async (data: ISamlFormData) => {
            setLoading(true);
            const payload = mapSamlFormDataToAuthSettingPayload(
                data,
                partitionGlobalId,
                authenticationSetting?.externalIdentityProviderDto?.id,
            );
            try {
                if (authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.SAML) {
                    if (EnableSecuritySettingsRevamp) {
                        await updateDirectoryIntegrationSettingSaml2(payload);
                    } else {
                        await updateAuthenticationSettingSaml2(payload);
                    }
                } else {
                    if (EnableSecuritySettingsRevamp) {
                        await createDirectoryIntegrationSettingSaml2(payload);
                    } else {
                        await createAuthenticationSettingSaml2(payload);
                    }
                }

                await openShowSuccessConfirmationDialog();

                history.push({
                    pathname: getRoute(
                        (process.buildConfigs.isOnPrem && !EnableSecuritySettingsRevamp)
                            ? RouteNames.AuthSettings : RouteNames.SecuritySettings,
                    ),
                });
            } catch (error) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        },
        [
            partitionGlobalId,
            authenticationSetting?.externalIdentityProviderDto?.id,
            authenticationSetting?.authenticationSettingType,
            openShowSuccessConfirmationDialog,
            history,
            getRoute,
            EnableSecuritySettingsRevamp,
            handleError,
        ],
    );

    return (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.footerButton}
                        onClick={cancel}
                        data-cy="saml-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    {activeStep > 0 && (
                        <Button
                            className={classes.footerButton}
                            onClick={() => navigateToStep(activeStep - 1)}
                            variant="outlined"
                            data-cy="saml-back-button"
                        >
                            {translate({ id: 'CLIENT_BACK' })}
                        </Button>
                    )}
                    {(activeStep < 2 || activeStep === 0) && (
                        <Button
                            className={classes.footerButton}
                            disabled={hasErrorOnStep()}
                            onClick={() => navigateToStep(activeStep + 1)}
                            variant="outlined"
                            data-cy="saml-next-button"
                        >
                            {translate({ id: 'CLIENT_NEXT' })}
                        </Button>
                    )}
                    <UiProgressButton
                        type="submit"
                        className={classes.footerButton}
                        loading={loading}
                        disabled={
                            disableForm
                                || hasErrorOnStep()
                                || !isDirty
                                || !watch('SingleSignOnServiceUrl')?.trim()
                                || !watch('IdentityProviderEntityId')?.trim()
                                || !(watch('SigningCertificateLocation.CertificateText') as string)?.trim()
                                || !watch('ProvisioningSetting.AccountLinkConfirmation')
                                || !(watch('ProvisioningSetting.AllowedDomains') as string)?.trim()
                        }
                        variant="contained"
                        data-cy="saml-test-and-save-button"
                    >
                        {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                    </UiProgressButton>
                </div>
            }>
            <FormProvider {...formMethods}>
                <SecuritySettingsSAMLFormStepper activeStep={activeStep} />
                <div className={clsx(activeStep === 0 ? classes.visibleForm : classes.hiddenForm)}>
                    <SecuritySettingsSAMLGeneralForm />
                </div>
                <div className={clsx(activeStep === 1 ? classes.visibleForm : classes.hiddenForm)}>
                    <SecuritySettingsSAMLProvisioningSettings />
                </div>
                <div className={clsx(activeStep === 2 ? classes.visibleForm : classes.hiddenForm)}>
                    <SecuritySettingsSAMLAdvancedDetailsForm />
                </div>
            </FormProvider>
        </UiForm>
    );
};

export default SecuritySettingsSAMLForm;
