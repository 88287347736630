import { UiStorage } from '@experiences/util';
import { Dispatch } from 'react';
import { createAction } from 'redux-act';

export const ToggleAdminRevamp = createAction('ToggleAdminRevamp');

export const saveAdminToggleToLocalStorage = (newValue: boolean) => {
    UiStorage.setItem('adminRevamp', newValue.toString());
};

export const getAdminToggleFromLocalStorage = () => {
    return UiStorage.getItem('adminRevamp') === 'true';
};

export const toggleAdminRevamp = () => {
    return (dispatch: Dispatch<any>, getState: any) => {
        const curVal = getState().adminRevamp.adminRevampToggle;
        saveAdminToggleToLocalStorage(!curVal);
        dispatch(ToggleAdminRevamp());
    };
};
