import { useRouteResolver } from '@experiences/util';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
    Button,
    Divider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import {
    Link,
    useHistory,
} from 'react-router-dom';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';
import { useLicenseExpired } from '../../../common/hooks/useLicenseExpired';
import { IUserCIS } from '../../../common/interfaces/cis/user';
import SuccessMessageActionsComponent, { useSuccessMessageActionsStyles } from './SuccessMessageActionsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        body: {
            margin: '0px 24px',
            flex: '1 1 100%',
        },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: 'calc(100vh - 54px)',
        },
        header: {
            fontSize: '14px',
            fontWeight: 600,
            marginTop: '16px',
        },
        divider: {
            marginTop: '20px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorBorderDeEmp,
            backgroundColor: theme.palette.semantic.colorBorderDeEmp,
            width: '250',
        },
        subHeader: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '10px',
        },
        subHeaderText: {
            fontWeight: 600,
            marginLeft: '10px',
        },
    }),
);

export const CreateGroupSuccessComponent: React.FC = () => {
    return null;
};

export const ConfirmInviteSuccessComponent: React.FC<{ close: (_: boolean) => void }> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div className={classes.body}>
            <Typography>
                {translate({ id: 'CLIENT_NEW_USER_INVITED' })}
.
            </Typography>
            <br />
            <Typography>
                {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_ONE' })}
            </Typography>
            <br />
            <Typography>
                {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
            </Typography>
            <br />
            <Typography>
                {translate(
                    {
                        id: isAdminRevampEnabled
                            ? 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP'
                            : 'CLIENT_INVITE_SUCCESS_TEXT_THREE',
                    },
                    { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                )}
            </Typography>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};

export const ConfirmInviteSuccessLicensingComponent: React.FC<{
    close: (_: boolean) => void;
    users?: IUserCIS[];
}> = ({
    close, users,
}) => {
    const classes = useStyles();
    const successMessageActionsClasses = useSuccessMessageActionsStyles();
    const { formatMessage: translate } = useIntl();
    const isLicenseExpired = useLicenseExpired();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <Typography className={classes.header}>
                    {translate({ id: 'CLIENT_USERS_HAVE_BEEN_INVITED_SUCCESSFULLY' })}
                </Typography>
                <Divider className={classes.divider} />
                <div className={classes.subHeader}>
                    <ErrorOutlineIcon />
                    <Typography className={classes.subHeaderText}>
                        {translate({ id: 'CLIENT_PERMISSIONS' })}
                    </Typography>
                </div>
                <Typography>
                    {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_ONE' })}
                </Typography>
                <br />
                <Typography>
                    {
                        isAdminRevampEnabled
                            ? translate(
                                { id: 'CLIENT_INVITE_SUCCESS_LICENSING_TEXT_REVAMP' },
                                { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                            )
                            : translate(
                                { id: 'CLIENT_INVITE_SUCCESS_LICENSING_TEXT' },
                                {
                                    TENANTS: (
                                        <Link
                                            to={getRoute(RouteNames.Services)}
                                            style={{ fontWeight: 600 }}>
                                            {translate({ id: 'CLIENT_PAGE_TENANTS' })}
                                        </Link>
                                    ),
                                    ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} />,
                                },
                            )
                    }
                </Typography>
                <Divider className={classes.divider} />
                <div className={classes.subHeader}>
                    <ErrorOutlineIcon />
                    <Typography className={classes.subHeaderText}>
                        {translate({ id: 'CLIENT_LICENSES' })}
                    </Typography>
                </div>
                <Typography>
                    {translate({ id: 'CLIENT_LICENSE_USE_GROUP_ALLOCATION_RULE' })}
                </Typography>
                <br />
                <Typography>
                    {translate({ id: 'CLIENT_LICENSE_ALLOCATE_EXPLICITLY' })}
                </Typography>
            </div>
            <div className={successMessageActionsClasses.actionButtons}>
                <Button
                    color="primary"
                    onClick={() => close(true)}>
                    {translate({ id: 'CLIENT_CLOSE' })}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className={successMessageActionsClasses.secondaryButton}
                    onClick={() =>
                        history.push({
                            pathname: `${getRoute(RouteNames.UsersUserAddLicenseAllocation)}`,
                            state: {
                                users,
                                previousLocation: getRoute(RouteNames.Users),
                            },
                        })}
                    data-cy="add-edit-create-allocation-button"
                    disabled={isLicenseExpired}
                >
                    {translate({ id: 'CLIENT_ALLOCATE_LICENSES' })}
                </Button>
            </div>
        </div>
    );
};

export const ConfirmCreateEditGroupSuccessComponent: React.FC<{
    close: (_: boolean) => void;
}> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isServicesPageEnabled = useIsServicesPageEnabled();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <Typography className={classes.header}>
                    {translate({ id: 'CLIENT_GROUPS_NEW_GROUP' })}
                </Typography>
                {isServicesPageEnabled && (
                    <>
                        <br />
                        <Typography>
                            {translate({ id: 'CLIENT_GROUPS_NEW_DESCRIPTION' })}
                        </Typography>
                        <br />
                        <Typography display="inline">
                            <>
                                {translate({ id: 'CLIENT_GROUPS_MANAGE_USERS' }).split('{ICON}')[0]}
                                <PeopleAltOutlinedIcon className={classes.inlineIcon} />
                            </>
                        </Typography>
                        <Typography display="inline">
                            {translate({ id: 'CLIENT_GROUPS_MANAGE_USERS' }).split('{ICON}')[1]}
                        </Typography>
                    </>
                )}
            </div>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};

export const ConfirmAddEditRobotSuccessComponent: React.FC<{
    close: (_: boolean) => void;
}> = ({ close }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isServicesPageEnabled = useIsServicesPageEnabled();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div className={classes.content}>
            <div className={classes.body}>
                <Typography className={classes.header}>
                    {translate({ id: 'CLIENT_NEW_ROBOT_ADDED' })}
                </Typography>
                {isServicesPageEnabled && (
                    <>
                        <Divider className={classes.divider} />
                        <div className={classes.subHeader}>
                            <ErrorOutlineIcon />
                            <Typography className={classes.subHeaderText}>
                                {translate({ id: 'CLIENT_PERMISSIONS' })}
                            </Typography>
                        </div>
                        <Typography>
                            {translate({ id: 'CLIENT_CREATE_SUCCESS_TEXT' })}
                        </Typography>
                        <br />
                        <Typography>
                            {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
                        </Typography>
                        <br />
                        <Typography>
                            {translate(
                                {
                                    id: isAdminRevampEnabled
                                        ? 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP'
                                        : 'CLIENT_INVITE_SUCCESS_TEXT_THREE',
                                },
                                { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                            )}
                        </Typography>
                    </>
                )}
            </div>
            <SuccessMessageActionsComponent close={close} />
        </div>
    );
};
