// key is a string because Property Accessor always converts keys to strings
export const ErrorCodeMapping: Record<string, { message: string; action?: () => void }> = {
    '512': { message: 'CLIENT_AUTH_METHOD_NOT_SUPPORTED' },
    '9001': { message: 'CLIENT_SERVICE_UNAVAILABLE_ERROR' },
    '9002': { message: 'CLIENT_SERVICE_REGISTRY_ERROR' },
    '9003': { message: 'CLIENT_REQUEST_ROUTING_ERROR' },
    '9016': { message: 'CLIENT_SESSION_EXPIRED' },
    '9017': { message: 'CLIENT_UNAUTHORIZED' },
    '7777': { message: 'CLIENT_LOGIN_ERROR' },
    '7778': { message: 'CLIENT_ENTERPRISE_SSO_MISSING_ACR' },
};
