import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        backToLicensesText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            cursor: 'pointer',
        },
    }),
}));

const EcommerceBackToLicense: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    return (<div
        className={classes.backToLicensesText}
        onClick={() => history.push(getRoute(RouteNames.Licensing))}
        data-cy="ecommerce-go-to-licensing">
        <KeyboardArrowLeftIcon fontSize="small" />
        {translate({ id: 'CLIENT_BACK_TO_LICENSES' })}
    </div>)
    ;
};

export default EcommerceBackToLicense;
