import { useRouteResolver } from '@experiences/util';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

interface IBreadCrumb {
    link: string ;
    name: string;
}
function AdminBreadCrumbs(breadCrumbTrail: IBreadCrumb[]) {

    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const breadcrumbCallbackRef = useCallback((ref: HTMLPortalBreadcrumbElement | null) => {
        const navigateToBreadcrumb = (event: any) => {
            history.push(getRoute(event.detail));
        };
        ref?.addEventListener('breadcrumbClicked', navigateToBreadcrumb);
    }, [ getRoute, history ]);

    return (
        <portal-breadcrumb
            ref={breadcrumbCallbackRef}
            links={JSON.stringify(
                breadCrumbTrail.map((crumb) => (
                    {
                        id: crumb.link,
                        name: crumb.name === 'host' ? translate({ id: 'CLIENT_HOST_SETTINGS' }) : crumb.name,
                    }),
                ),
            )}
            active={breadCrumbTrail[breadCrumbTrail.length - 1].link}
            data-cy='admin-bread-crumb-navigation'
        />
    );
}

export default AdminBreadCrumbs ;
