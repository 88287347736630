import { UiLoader } from '@experiences/ui-common';
import React, {
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import {
    Redirect,
    useLocation,
} from 'react-router';

import { TenantOperationTrackerContextProvider } from '../component/tenants/TenantOperationTrackerContextProvider';
import { TenantsContextProvider } from '../component/tenants/TenantsContextProvider';
import useAuthentication from './hooks/Authentication';
import useAuthParams from './hooks/AuthParams.default';
import useCustomEvents from './hooks/CustomEvents';
import PortalInitializationProtector from './PortalInitializationProtector';
import IdentityAuthProvider from './token/IdentityAuthProvider';
import {
    getToken,
    setToken,
} from './utils/Token';

export default function ProtectedRoute({ children }: PropsWithChildren<any>) {
    useCustomEvents();
    const params = useAuthParams();

    const location = useLocation();

    const {
        isAuthenticated, isLoading, error, user, signinRedirect,
    } = useAuth();

    const { isLoggingOut } = useAuthentication();

    const [ isTokenLoaded, setIsTokenLoaded ] = useState(!!getToken());

    useEffect(() => {
        if (isLoggingOut) {
            return;
        }

        if (!isLoading && !isAuthenticated) {
            (async (): Promise<void> => {
                await signinRedirect({
                    ...params,
                    state: { redirect_uri: location.pathname + location.search },
                });
            })();

            return;
        }

        // TODO: deprecate once library supports async user manager access
        if (!isTokenLoaded && isAuthenticated && user) {
            setToken(user.access_token);
            setIsTokenLoaded(true);
        }
    }, [ user, isAuthenticated, isLoading, isLoggingOut, params, isTokenLoaded, signinRedirect, location.pathname, location.search ]);

    if (error) {
        return <Redirect to={{
            pathname: '/portal_/autherror',
            state: { error },
        }} />;
    }

    if (isAuthenticated && isTokenLoaded) {
        return (
            <IdentityAuthProvider>
                <PortalInitializationProtector>
                    {
                        process.buildConfigs.disableTenantsContext ?
                            <>
                                {children}
                            </>
                            :
                            <TenantOperationTrackerContextProvider>
                                <TenantsContextProvider>
                                    {children}
                                </TenantsContextProvider>
                            </TenantOperationTrackerContextProvider>
                    }
                </PortalInitializationProtector>
            </IdentityAuthProvider>
        );
    }

    return <UiLoader type='full' />;
}
