import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        dropdownBg: {
            minWidth: '30px',
            padding: '0px',
            color: 'inherit',
        },
    }),
);

interface IUiDropdownButtonProps {
    tooltip?: string;
    disabled?: boolean;
    showDropdownButton?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
    menuItems: IUiDropdownButtonMenuItemProps[];
    width?: number;
}

interface IUiDropdownButtonMenuItemProps {
    label: string;
    onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const UiDropdownButton: React.FC<IUiDropdownButtonProps> = ({
    tooltip,
    disabled,
    showDropdownButton,
    onClick,
    label,
    menuItems,
    width = 140,
}) => {
    const classes = useStyles();

    const [ menuAnchorEl, setMenuAnchorEl ] = React.useState<null | HTMLElement>(null);

    return (
        <>
            <Tooltip
                arrow
                title={tooltip ?? ''}>
                <span>
                    <Button
                        disabled={disabled}
                        onClick={
                            onClick == null && showDropdownButton
                                ? event => {
                                    event.stopPropagation();
                                    setMenuAnchorEl(event.currentTarget);
                                }
                                : onClick
                        }
                        variant="contained"
                        data-cy="dropdown-btn-primary-action-button"
                        style={showDropdownButton ? {
                            padding: '0px 3px 0px 16px',
                            minWidth: `${width}px`,
                        } : undefined}
                        endIcon={
                            showDropdownButton && (
                                <Button
                                    disabled={disabled}
                                    onClick={event => {
                                        event.stopPropagation();
                                        setMenuAnchorEl(event.currentTarget);
                                    }}
                                    data-cy="dropdown-btn-secondary-action-button"
                                    className={classes.dropdownBg}
                                    variant={disabled ? 'contained' : 'text'}
                                >
                                    <ExpandMoreIcon />
                                </Button>
                            )
                        }
                    >
                        {label}
                    </Button>
                </span>
            </Tooltip>
            {showDropdownButton && (
                <>
                    {/* translate prop is required for Popper */}
                    <Popper
                        open={!!menuAnchorEl}
                        anchorEl={menuAnchorEl}
                        placement="bottom-end"
                        translate={undefined}>
                        <ClickAwayListener onClickAway={() => setMenuAnchorEl(null)}>
                            <Paper>
                                <MenuList id="split-button-menu">
                                    {menuItems.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            data-cy="dropdown-btn-menu-item"
                                            onClick={event => {
                                                setMenuAnchorEl(null);
                                                item.onClick && item.onClick(event);
                                            }}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </>
            )}
        </>
    );
};
