import GlobalStyles from '@experiences/theme';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { GroupsAndRolesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { IGroup } from '../../../common/interfaces/cis/group';
import { SimpleGroup } from '../interfaces/user';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        groups: {
            marginTop: '10px',
            display: 'flex',
        },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupInfoIcon: {
            width: 18,
            height: 18,
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
            color: theme.palette.semantic.colorPrimary,
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tooltipIcon: {
            width: 16,
            height: 16,
            marginLeft: '4px',
        },
    }),
}));

export interface IEditGroupMembershipData {
    groupIds: SimpleGroup;
}

export const defaultEditGroupMembershipData: IEditGroupMembershipData = { groupIds: {} };

const EditGroupMembershipFormComponent: FC<{ groups: IGroup[]; defaultCheckedGroupIds: string[] }> = ({
    groups,
    defaultCheckedGroupIds,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { register } = useFormContext<IEditGroupMembershipData>();
    const getLocalizedLink = useDocumentationLinks();

    return (
        <div
            role="group"
            aria-labelledby="groupMembershipCheckboxListLabel"
            tabIndex={0}>
            <div className={classes.groups}>
                <Typography
                    className={classes.inputLabel}
                    id="groupMembershipCheckboxListLabel">
                    {translate({ id: 'CLIENT_GROUP_MEMBERSHIP' })}
                </Typography>
                <Tooltip
                    arrow
                    title={
                        <div style={{ display: 'flex' }}>
                            {translate({ id: 'CLIENT_GROUPS_GUIDE' }, { titleProductName: process.buildConfigs.titleProductName })}
                            <OpenInNewIcon className={classes.tooltipIcon} />
                        </div>
                    }
                >
                    <a
                        className={classes.a}
                        href={getLocalizedLink(GroupsAndRolesLink)}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={translate({ id: 'CLIENT_GROUPS_GUIDE_LINK_ARIA' })}
                    >
                        <HelpOutlineIcon className={classes.groupInfoIcon} />
                    </a>
                </Tooltip>
            </div>
            <FormGroup>
                {groups &&
          groups.map((group, i) => (
              <FormControlLabel
                  key={i}
                  control={<Checkbox
                      size="small"
                      defaultChecked={defaultCheckedGroupIds.indexOf(group.id) > -1} />}
                  label={<Typography className={classes.groupText}>
                      {group.name}
                  </Typography>}
                  disabled={group.name === 'Everyone'}
                  inputRef={register}
                  name={`groupIds[${group.id}]`}
              />
          ))}
            </FormGroup>
        </div>
    );
};

export default EditGroupMembershipFormComponent;
