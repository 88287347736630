import { Features } from './FeatureFlagConstants';

export function getFeatureFlagValue(featureFlagKey: string): boolean {
    if (isLocalFeatureFlagsEnabled && localStorage.getItem(featureFlagKey) != null) {
        return localStorage.getItem(featureFlagKey)?.toLowerCase() === 'true';
    }

    if (typeof window === 'undefined') {
        return false;
    }

    return (window as any).env?.FEATURE_FLAGS?.[featureFlagKey]?.value;
}

export const isLocalFeatureFlagsEnabled =
    typeof window !== 'undefined' &&
     ((window as any).Cypress || (window as any).env?.FEATURE_FLAGS?.[Features.EnableLocalStorageFeatureFlags.name]?.value);
