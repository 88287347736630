import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { ITenant } from '../../common/interfaces/tenant/tenant';
import {
    getTenants,
    getTenantsV2,
    organizationManagementTenantUri,
} from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../store/selectors';
import { useUserReadableTime } from '../../util/DateUtil';
import { ServiceInstanceUrlComponent } from '../tenants/subcomponents/ServiceInstanceUrlComponent';
import { TenantStatusComponent } from '../tenants/subcomponents/TenantStatusComponent';
import { TenantStatusConstants } from '../tenants/TenantConstants';
import HomePageHeader from './helper/HomePageHeader';

const useStyles = makeStyles(theme =>
    createStyles({
        fontBold: { fontWeight: 600 },
        services: { paddingBottom: '24px' },
        centered: {
            alignContent: 'center',
            display: 'flex',
            margin: 'auto',
            minHeight: '80px',
        },
        tileContent: {
            pointerEvents: 'none',
            float: 'left',
            width: '70%',
            flex: 1,
        },
        subText: {
            fontSize: '12px',
            float: 'left',
            textAlign: 'left',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        createNew: {
            marginTop: '15px',
            width: '250px',
            height: '40px',
        },
        tenantName: {
            fontWeight: 600,
            width: '270px',
            textAlign: 'left',
        },
        tenantStatus: {
            flex: 1,
            display: 'flex',
        },
        headerBar: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
            alignItems: 'center',
            height: '32px',
        },
    }),
);

export const HomePageServicesComponent: React.FC = () => {
    const classes = useStyles();

    const { userReadableTime } = useUserReadableTime();

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);

    const accountLogicalNameSelector = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const organizationGuid = useSelector(accountGlobalId);

    const getRoute = useRouteResolver();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const { formatMessage: translate } = useIntl();

    const retriesRef = useRef(0);

    const {
        data: tenants, isValidating,
    } = useSWR<ITenant[], Error>(
        !EnableGetTenantsRedesign
            ? [ organizationManagementTenantUri, organizationGuid, accountLogicalNameSelector, true ]
            : [ organizationManagementTenantUri, organizationGuid, accountLogicalNameSelector, 'Enabled' ],
        !EnableGetTenantsRedesign ? getTenants : getTenantsV2,
    );

    useEffect(() => {
        if (
            !isValidating &&
      tenants &&
      retriesRef.current < 5 &&
      (tenants.length === 0 || tenants.some(tenant => tenant.status?.toUpperCase() === TenantStatusConstants.UPDATING))
        ) {
            setTimeout(() => {
                retriesRef.current = retriesRef.current + 1;
                mutate([ organizationManagementTenantUri, organizationGuid, accountLogicalNameSelector ]);
            }, 5000);
        }
    }, [ tenants, isValidating, retriesRef, organizationGuid, accountLogicalNameSelector ]);

    const getTileContent = useCallback(
        (tenant: ITenant) => {
            return (
                <div className={clsx(classes.tileContent)}>
                    <div className={classes.tenantStatus}>
                        <TenantStatusComponent
                            data={tenant.status}
                            disableStatusIcon
                            label={
                                <Typography
                                    noWrap
                                    variant="body1"
                                    className={classes.tenantName}>
                                    {tenant.name}
                                </Typography>
                            }
                        />
                    </div>
                    <Typography className={clsx(classes.subText)}>
                        {`${translate({ id: 'CLIENT_LAST_UPDATED' })}: ${
                            tenant.status.toUpperCase() === TenantStatusConstants.ENABLED
                                ? userReadableTime(tenant.updatedOn)
                                : translate({ id: 'CLIENT_UPDATING' })
                        }`}
                    </Typography>
                </div>
            );
        },
        [ classes, translate, userReadableTime ],
    );

    const NoServiceComponent = useMemo(() => {
        return (
            <div>
                <Typography>
                    {translate({ id: 'CLIENT_NO_SERVICES' })}
                </Typography>
                <Button
                    variant="outlined"
                    className={clsx(classes.createNew)}
                    component={RouterLink}
                    to={getRoute(RouteNames.Services)}
                    data-cy="home-page-services-create-new-button"
                >
                    <HomePageHeader
                        label={translate({ id: 'CLIENT_CREATE_NEW' })}
                        variant="button" />
                </Button>
            </div>
        );
    }, [ classes, getRoute, translate ]);

    const ServiceTilesComponent = useMemo(() => {
        const serviceTiles: any[] = [];

        tenants?.forEach((tenant: ITenant, i: number) => {
            if (
                (tenant.status?.toUpperCase() === TenantStatusConstants.ENABLED
                || tenant.status?.toUpperCase() === TenantStatusConstants.UPDATING)
                && tenant.tenantServiceInstances.find(
                    service =>
                        service.serviceType === 'orchestrator' && service.status.toUpperCase() === TenantStatusConstants.ENABLED,
                )
                && i < 12
            ) {
                serviceTiles.push(
                    <Grid
                        key={i}
                        item>
                        <ServiceInstanceUrlComponent
                            data={tenant}
                            accountLogicalName={accountLogicalNameSelector}
                        />
                    </Grid>,
                );
            }
        });
        return serviceTiles.length > 0 ? (
            <Grid
                container
                spacing={2}>
                {serviceTiles}
            </Grid>
        ) : (
            NoServiceComponent
        );
    }, [ accountLogicalNameSelector, classes, getTileContent, tenants, NoServiceComponent ]);

    return (
        <div className={classes.services}>
            <div
                className={classes.headerBar}
                data-cy="home-page-services-header-bar">
                <HomePageHeader
                    label={translate({ id: 'CLIENT_ORCHESTRATOR_SERVICES' })}
                    fontSize="20px" />
                {isAdmin && tenants && tenants.length > 0 ? (
                    <Button
                        color="primary"
                        component={RouterLink}
                        to={getRoute(isAdminRevampEnabled ? RouteNames.OrganizationAdminHome : RouteNames.Services)}
                        data-cy="home-page-services-view-all-button"
                    >
                        {translate({ id: 'CLIENT_MANAGE' })}
                    </Button>
                ) : null}
            </div>
            {tenants ? (
                tenants.some(
                    tenant =>
                        tenant.status?.toUpperCase() === TenantStatusConstants.ENABLED ||
            tenant.status?.toUpperCase() === TenantStatusConstants.UPDATING,
                ) ? (
                        ServiceTilesComponent
                    ) : isAdmin ? (
                        NoServiceComponent
                    ) : (
                        <Typography>
                            {translate({ id: 'CLIENT_NO_SERVICES_USER' })}
                        </Typography>
                    )
            ) : (
                <div className={clsx(classes.centered)}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>
            )}
        </div>
    );
};
