import { useErrorMapper } from '@experiences/error';
import {
    UiBackground,
    UiLogo,
} from '@experiences/ui-common';
import { useFilteredParams } from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import {
    Button,
    Paper,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useHistory,
    useLocation,
} from 'react-router';

import forceLogout from '../../auth/utils/ForceLogout';
import useNavigateToHome from '../../common/hooks/useNavigateToHome';
import convertParamMapToTranslationKeys, { IParamToValidValues } from '../../util/ParamUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: 400,
            overflow: 'auto',
            marginTop: 104,
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        errorMessage: {
            padding: '8px 16px',
            textAlign: 'center',
            marginBottom: '12px',
        },
        button: {
            width: '280px',
            marginBottom: '24px',
        },
        errorIcon: { color: theme.palette.semantic.colorErrorIcon },
    }),
);

type IconType = 'error' | 'warning' | 'info';

// IParamToValidValues is a map of { param from url => array of valid values for that param }
interface IErrorProps {
    title: string;
    icon: IconType;
    backToLogin: boolean;
    isForce: boolean;
    backToHome: boolean;
    messageParams: IParamToValidValues[];
    titleParams: IParamToValidValues[];
}

const ErrorCodeToErrorPropsMap: Record<string, Partial<IErrorProps>> = {
    '512': {
        title: 'CLIENT_SIGN_OUT_CONTINUE_WITH',
        icon: 'info',
        backToLogin: true,
        isForce: true,
        messageParams: [ { userprovider: [ 'google-oauth2', 'UiPath-AADV2' ] } ],
        titleParams: [ { userprovider: [ 'google-oauth2', 'UiPath-AADV2' ] } ],
    },
    '7777': { backToLogin: true },
    '9017': { backToHome: true },
};

/**
 * Display page when user tries to login but account does not exist
 */
const GenericError: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { formatMessage: translate } = useIntl();
    const getErrorMapping = useErrorMapper();
    const navigateToHome = useNavigateToHome();
    const [ code ] = useFilteredParams([ 'errorCode' ]);

    const [ title, titleParams, message, iconType, isForce, backToLogin, backToHome ] = useMemo(() => {
        const params = new URLSearchParams(location.search);

        const props = ErrorCodeToErrorPropsMap[code];
        const messageParams = props && convertParamMapToTranslationKeys(params, props.messageParams);
        const titleParams = props && convertParamMapToTranslationKeys(params, props.titleParams);

        return [
            props?.title ?? 'CLIENT_ACCESS_DENIED_TEXT',
            _.mapValues(titleParams, v => translate({ id: v as any })),
            getErrorMapping(code, { params: messageParams }),
            props?.icon ?? 'error',
            props?.isForce ?? false,
            props?.backToLogin ?? false,
            props?.backToHome ?? false,
        ];
    }, [ location, code, getErrorMapping, translate ]);

    const Icon = useMemo(() => {
        switch (iconType) {
            case 'info':
                return <InfoIcon
                    color="primary"
                    className={classes.headerIcon} />;
            case 'warning':
            default:
                return <CancelIcon className={clsx(classes.headerIcon, classes.errorIcon)} />;
        }
    }, [ iconType, classes ]);

    const handleBackToSignIn = useCallback(() => {
        if (isForce) {
            forceLogout();
            return;
        }

        history.push('/portal_/logout');
    }, [ history, isForce ]);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.headerContainer}>
                    <UiLogo />
                    {Icon}
                    <Typography
                        className={classes.headerText}
                        data-cy="generic-error-header-text">
                        {location.pathname === '/portal_/genericerror'
                            ? translate({ id: title }, titleParams as any)
                            : translate({ id: 'CLIENT_PAGE_NOT_FOUND' })}
                    </Typography>
                    <Typography className={classes.errorMessage}>
                        {message}
                    </Typography>
                    {backToLogin && (
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => handleBackToSignIn()}
                            data-cy="generic-error-logout-button"
                        >
                            {translate({ id: 'CLIENT_BACK_TO_SIGN_IN' })}
                        </Button>
                    )}
                    {backToHome && (
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={navigateToHome}
                            data-cy="generic-error-logout-button"
                        >
                            {translate({ id: 'CLIENT_GO_TO_HOME_PAGE' })}
                        </Button>
                    )}
                </div>
            </Paper>
        </UiBackground>
    );
};

export default GenericError;
