import { createStyles } from '@mui/styles';

export default createStyles({
    styleOverrides: {
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            width: '100%',
        },
    },
});
