import {
    IHistoryStatus,
    useIsEcommerceEnabled,
} from '@experiences/ecommerce';
import { AccountType } from '@experiences/interfaces';
import {
    useEffect,
    useState,
} from 'react';
import useSWR from 'swr';

import useCheckLicense from '../../common/hooks/useCheckLicense';
import { fetchHistoryStatus } from '../../services/licensing/BillingService';

export function useShouldShowManageButton(
    currentAccountName: string,
    accountType: AccountType | undefined,
): boolean | undefined {

    const isEcommerceEnabled = useIsEcommerceEnabled();
    const [ historyStatus, setHistoryStatus ] = useState<boolean | undefined>(undefined);
    const { data: ecommerceHistoryStatus } = useSWR<IHistoryStatus, Error>(
        isEcommerceEnabled ? currentAccountName : null,
        fetchHistoryStatus,
    );
    const { isAccountLicenseEnterpriseOrPro } = useCheckLicense();

    useEffect(() => {
        if (ecommerceHistoryStatus?.hasHistoricalData != null) {
            setHistoryStatus(ecommerceHistoryStatus?.hasHistoricalData);
        }
    }, [ ecommerceHistoryStatus ]);

    return isEcommerceEnabled && accountType && isAccountLicenseEnterpriseOrPro(accountType) && historyStatus;
}
