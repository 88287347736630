import {
    IUpdateUserNotificationSubscriptionModeRequestData,
    IUpdateUserSubscriptionsRequestData,
} from '../../component/notificationSettings/interfaces/notificationSettings';
import {
    get,
    post,
} from '../utility/Requests.default';

const initialApiPath = '/api/notificationSettings/userSubscription';
export const userNotificationSubscriptionUri = initialApiPath + '/';
const updateUserNotificationSubscriptionModeUri = initialApiPath + '/updateMode';
const resetUserNotificationSubscriptionUri = initialApiPath + '/reset';

const getHeaders = (selectedTenantId: string) => {
    return {
        extendRequestHeaders: 'true',
        'X-UiPath-Internal-TenantId': selectedTenantId,
    };
};

export function getUserNotificationSubscription(requestUri: string, selectedTenantId: string) {
    return get<any>(requestUri, { headers: getHeaders(selectedTenantId) });
}

export function updateUserNotificationTopicSubscription(
    updateUserSubscriptions: IUpdateUserSubscriptionsRequestData[],
    selectedTenantId: string) {
    return post<any>(userNotificationSubscriptionUri, {
        body: { userSubscriptions: [ ...updateUserSubscriptions ] },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationSubscriptionMode(
    updateNotificationMode: IUpdateUserNotificationSubscriptionModeRequestData,
    selectedTenantId: string) {
    return post<any>(updateUserNotificationSubscriptionModeUri, {
        body: { ...updateNotificationMode },
        headers: getHeaders(selectedTenantId),
    });
}

export async function resetUserNotificationSubscription(publisherId: string, selectedTenantId: string) {
    return await post<any>(resetUserNotificationSubscriptionUri, {
        body: { publisherId },
        headers: getHeaders(selectedTenantId),
    });
}
