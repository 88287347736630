import React from 'react';

import { EcommerceCheckoutComponent } from './EcommerceCheckoutComponent';
import { EcommerceProvider } from './helpers/useEcommerce';
import { IEcommerceCheckoutComponentState } from './interfaces/ecommerce';

export const EcommerceCheckoutWrapperComponent: React.FC<{
    location?: {
        state?: IEcommerceCheckoutComponentState;
    };
}> = ({ location }) => {
    return (
        <EcommerceProvider>
            <EcommerceCheckoutComponent location={location} />
        </EcommerceProvider>
    );
};
