import { useShowDialog } from '@experiences/util';
import {
    FormControlLabel,
    Switch,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    FC,
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import { INavCustomizationData } from './NavCustomizationComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        formControlLabel: { marginRight: '0px' },
        form: {
            width: '518px',
            marginBottom: '16px',
        },
        icon: {
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
            color: theme.palette.semantic.colorForeground,
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tableBodyCell: {
            minWidth: '50%',
            height: '60px',
        },
        tableBodyCellRevamp: {
            width: '75%',
            height: '60px',
        },
        tableBodyCellActionRevamp: {
            width: '25%',
            height: '60px',
        },
        redText: { color: theme.palette.semantic.colorErrorText },
    }),
);

const EditNavBarIconFormComponent: FC<{ id: string; textId: string; icon: any }> = ({
    id, textId, icon,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { control } = useFormContext<INavCustomizationData>();
    const name = useMemo(() => `${id}Hidden` as keyof INavCustomizationData, [ id ]);
    const serviceName = useMemo(() => translate({ id: textId }), [ textId, translate ]);
    const createDialog = useShowDialog();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const handleSwitch = useCallback(
        async (checked: boolean, callback: (_: any) => void) => {
            const proceed =
        !checked ||
        (await createDialog({
            title: translate({ id: 'CLIENT_NAV_BAR_EDIT_CONFIRMATION_TITLE' }, { serviceName }),
            body: (
                <FormattedMessage
                    id="CLIENT_NAV_BAR_EDIT_CONFIRMATION_DESCRIPTION"
                    values={{
                        strong: (chunk: string) => <strong>
                            {chunk}
                        </strong>,
                        b: (chunk: string) => <b className={classes.redText}>
                            {chunk}
                        </b>,
                        serviceName,
                    }}
                />
            ),
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        }));
            if (proceed) {
                callback(checked);
            }
        },
        [ classes.redText, createDialog, serviceName, translate ],
    );

    return (
        <TableRow>
            <TableCell
                align="left"
                className={clsx({
                    [classes.tableBodyCell]: !isAdminRevampEnabled,
                    [classes.tableBodyCellRevamp]: isAdminRevampEnabled,
                })}>
                {icon}
                <Typography id={`serviceName-${id}`}>
                    {serviceName}
                </Typography>
            </TableCell>
            <TableCell
                align={isAdminRevampEnabled ? 'center' : 'right'}
                className={clsx({
                    [classes.tableBodyCell]: !isAdminRevampEnabled,
                    [classes.tableBodyCellActionRevamp]: isAdminRevampEnabled,
                })}>
                <Controller
                    name={name}
                    control={control}
                    render={props => (
                        <FormControlLabel
                            className={classes.formControlLabel}
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={!props.value}
                                    onChange={e => handleSwitch(!e.target.checked, props.onChange)}
                                    color="primary"
                                    aria-labelledby={`serviceName-${id}`}
                                    data-cy={`nav-customization-${name}-switch`}
                                />
                            }
                            label={
                                !isAdminRevampEnabled
                                    ? <div className={classes.switchLabel}>
                                        {translate({ id: props.value ? 'CLIENT_HIDDEN' : 'CLIENT_SHOWN' })}
                                    </div>
                                    : ''
                            }
                            aria-label={translate({ id: props.value ? 'CLIENT_HIDDEN' : 'CLIENT_SHOWN' })}
                        />
                    )}
                />
            </TableCell>
        </TableRow>
    );
};

export default EditNavBarIconFormComponent;
