import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { ICard } from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import accountsAndGroupIcon from '../../images/accountsAndGroupsAdmin.svg';
import auditLogsAdminon from '../../images/auditLogsAdmin.svg';
import externalAppsAdmin from '../../images/externalAppsAdmin.svg';
import licensesAdmin from '../../images/licensesAdmin.svg';
import securityAdmin from '../../images/securityAdmin.svg';
import settingsAdmin from '../../images/settingsAdmin.svg';
import {
    accountType,
    isHostModeSelector,
} from '../../store/selectors';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';

const useStyles = makeStyles(() =>
    createStyles({
        cardIcon: {
            width: '30px',
            marginRight: '4px',
        },
    }),
);

const OrganizationAdminHome: FC = () => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();

    const isHost = useSelector(isHostModeSelector);
    const AccountType = useSelector(accountType);

    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);

    const cardList: ICard[] = useMemo(() => [
        {
            title: isHost ? 'CLIENT_USERS' : 'CLIENT_ACCOUNTS_AND_GROUPS',
            titleIcon: accountsAndGroupIcon,
            description: isHost ? 'CLIENT_USERS_DESCRIPTION' : 'CLIENT_ACCOUNTS_AND_GROUPS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Users),
            dataCy: 'org-settings-users-card',
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: licensesAdmin,
            description: isHost ? 'CLIENT_HOST_LICENSES_DESCRIPTION' : 'CLIENT_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Licensing),
            chipText: !isHost ? `CLIENT_${AccountType}_PLAN` : '',
            dataCy: 'org-settings-licenses-card',
            invisible: process.buildConfigs.hideForMSI,
        },
        {
            title: 'CLIENT_SECURITY',
            titleIcon: securityAdmin,
            description: isHost ? 'CLIENT_HOST_SECURITY_DESCRIPTION' : 'CLIENT_SECURITY_DESCRIPTION',
            onClickLink: getRoute(EnableSecuritySettingsRevamp || !isHost
                ? RouteNames.SecuritySettings
                : RouteNames.AuthSettings),
            dataCy: 'org-settings-security-card',
        },
        {
            title: 'CLIENT_AUDIT_LOG',
            titleIcon: auditLogsAdminon,
            description: 'CLIENT_AUDIT_LOG_DESCRIPTION',
            onClickLink: getRoute(RouteNames.AuditLogs),
            dataCy: 'org-settings-audit-logs-card',
        },
        {
            title: 'CLIENT_EXTERNAL_APPLICATIONS',
            titleIcon: externalAppsAdmin,
            description: 'CLIENT_EXTERNAL_APPLICATIONS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ExternalApplications),
            dataCy: 'org-settings-external-apps-card',
            invisible: isHost,
        },
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: settingsAdmin,
            description: isHost ? 'CLIENT_HOST_SETTINGS_DESCRIPTION' : 'CLIENT_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(isHost || process.buildConfigs.showForMSI
                ? RouteNames.EmailSettings : RouteNames.OrganizationSettings),
            dataCy: 'org-settings-global-settings-card',
        },
    ], [ AccountType, EnableSecuritySettingsRevamp, getRoute, isHost ]);

    return (
        <UiPageContainer>
            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible &&
                    <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={<img
                            className={classes.cardIcon}
                            src={card.titleIcon}
                            alt='accountsAndGroups'
                            style={{ width: '40px' }} />}
                        description={card.description}
                        chipText={card.chipText}
                        onClickLink={() => history.push(card.onClickLink)}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default OrganizationAdminHome;
