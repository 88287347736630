import { useAuthContext } from '@experiences/util';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
    Button,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '80px',
        },
        headerText: {
            marginTop: '28px',
            fontSize: '20px',
            textAlign: 'center',
            lineHeight: '24px',
            fontWeight: 600,
        },
        successIcon: {
            transform: 'scale(.8)',
            padding: 0,
            color: theme.palette.semantic.colorSuccessIcon,
        },
        copyButton: {
            transform: 'scale(.65)',
            padding: 0,
            '&:hover': { color: theme.palette.semantic.colorPrimary },
        },
        errorMessage: {
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '8px',
        },
        errorGuid: {
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            height: '20px',
        },
        continue: {
            marginTop: '16px',
            maxWidth: '360px',
        },
        verifiedLine: {
            marginTop: '8px',
            display: 'flex',
        },
        clickHere: {
            fontWeight: 600,
            cursor: 'pointer',
            marginLeft: '4px',
            '&:hover': { textDecoration: 'underline' },
        },
    }),
);

export interface IErrorProps {
    title: string;
    message: string;
    guid: string;
    shouldLogout: boolean;
}

export const UiErrorSupportContent: React.FC<{
    retry: () => void;
    error: Partial<IErrorProps>;
}> = ({
    error, retry,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ showCopied, setShowCopied ] = useState(false);

    const { logout } = useAuthContext();

    const onCopy = useCallback(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 10000);
    }, []);

    return (
        <div
            data-cy="ui-error-support-content"
            className={classes.root}>
            <Typography className={classes.headerText}>
                {translate({ id: error.title ?? 'CLIENT_OOPS_SOMETHING_WENT_WRONG' })}
            </Typography>
            {error.message && (
                <Typography
                    className={classes.errorMessage}
                    data-cy="error-content-error-message">
                    {translate({ id: error.message })}
                </Typography>
            )}
            {error.guid && (
                <>
                    <Link
                        href="https://www.uipath.com/company/contact-us/cloud-platform-technical-support"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: '4px' }}
                    >
                        {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                    </Link>
                    <Typography style={{ fontSize: '12px' }}>
                        {translate({ id: 'CLIENT_SUPPORTING_DETAILS' })}
                    </Typography>
                    <span className={classes.errorGuid}>
                        <span data-cy="error-content-error-guid">
                            {translate({ id: 'CLIENT_ERROR_ID_WITH_GUID' }, { 0: error.guid })}
                        </span>
                        <CopyToClipboard text={`Error ID: ${error.guid}`}>
                            <IconButton
                                className={showCopied ? classes.successIcon : classes.copyButton}
                                onClick={onCopy}
                                data-cy="error-content-copy-error-button"
                            >
                                {showCopied ? <CheckIcon /> : <FileCopyIcon />}
                            </IconButton>
                        </CopyToClipboard>
                    </span>
                </>
            )}
            <Button
                variant="contained"
                onClick={error.shouldLogout ? () => logout() : retry}
                className={classes.continue}
                fullWidth
                data-cy="error-content-continue-button"
            >
                {translate({ id: `CLIENT_${error.shouldLogout ? 'SIGN_OUT' : 'CONTINUE'}` })}
            </Button>
            {!error.shouldLogout && (
                <div className={classes.verifiedLine}>
                    <Typography display="inline">
                        {translate({ id: 'CLIENT_ALREADY_HAVE_AN_ACCOUNT' })}
                    </Typography>
                    <Typography
                        display="inline"
                        color="primary"
                        className={classes.clickHere}
                        onClick={() => logout()}>
                        {translate({ id: 'CLIENT_SIGN_IN' })}
                    </Typography>
                </div>
            )}
        </div>
    );
};
