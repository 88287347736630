import {
    FormControlLabel,
    Switch,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IAuthSettingsRestrictionsData } from '../../../common/interfaces/authSetting';
import { isHostModeSelector } from '../../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        root: {
            marginBottom: '16px',
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

export const defaultAuthSettingsRestrictionsData: IAuthSettingsRestrictionsData = {
    restrictBasicAuthentication: false,
    enableBasicAuthenticationForHostTenant: true,
};

const AuthSettingsRestrictionsFormComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const isHostMode = useSelector(isHostModeSelector);
    const {
        control, setValue, watch,
    } = useFormContext<IAuthSettingsRestrictionsData>();
    const restrictBasicAuthentication = useMemo(() => watch('restrictBasicAuthentication'), [ watch ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <div className={classes.root}>
            <Controller
                name="restrictBasicAuthentication"
                control={control}
                render={({
                    value, onChange,
                }) => (
                    <FormControlLabel
                        className={classes.switchFormControl}
                        control={
                            <Switch
                                checked={value}
                                onChange={e => handleSwitch('restrictBasicAuthentication', e.target.checked, onChange)}
                                color="primary"
                                data-cy="auth-settings-restrictions-restrict-basic-authentication"
                            />
                        }
                        label={
                            <div className={classes.switchLabel}>
                                {translate({
                                    id: isHostMode
                                        ? 'CLIENT_RESTRICT_BASIC_AUTHENTICATION_FOR_ORGANIZATIONS'
                                        : 'CLIENT_RESTRICT_BASIC_AUTHENTICATION_FOR_THIS_ORGANIZATION',
                                })}
                            </div>
                        }
                    />
                )}
            />
            {isHostMode && restrictBasicAuthentication && (
                <Controller
                    name="enableBasicAuthenticationForHostTenant"
                    control={control}
                    render={({
                        value, onChange,
                    }) => (
                        <FormControlLabel
                            className={classes.switchFormControl}
                            control={
                                <Switch
                                    checked={value}
                                    onChange={e => handleSwitch('enableBasicAuthenticationForHostTenant', e.target.checked, onChange)}
                                    color="primary"
                                    data-cy="auth-settings-restrictions-enable-basic-authentication-for-host-tenant"
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_ENABLE_BASIC_AUTHENTICATION_FOR_HOST_TENANT' })}
                                </div>
                            }
                        />
                    )}
                />
            )}
        </div>
    );
};

export default AuthSettingsRestrictionsFormComponent;
