import { stateInfo } from '@experiences/constants';
import {
    useLocalization,
    useTranslatedCountries,
} from '@experiences/locales';
import {
    UiCheckBoxLabel,
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import {
    getDnBObject,
    sha256Hex,
    useAuthContext,
} from '@experiences/util';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import * as emailValidator from 'email-validator';
import Cookies from 'js-cookie';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import {
    DIRECT_BUY_FLOW,
    storeCountryCode,
    TRY_BUY_FLOW,
    useEcommerceEnabledCountries,
    useEcommerceTelemetry,
    useIsDirectBuyInProgressFlow,
    useIsSignupDirectBuyFlow,
} from '../helpers/EcommerceHelpers';
import {
    accountLogicalName,
    profile,
} from '../helpers/EcommerceSelectors';
import { useEcommerce } from '../helpers/useEcommerce';
import {
    IBusinessInfoForm,
    IBusinessInfoPayload,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getExistingUserMarketoData,
    sendUserMarketoData,
    validateEmailBlacklist,
} from '../services/BillingService';
import { EcommerceCheckoutOrderSummary } from './EcommerceCheckoutOrderSummary';
import EcommerceFormStepper from './EcommerceFormStepper';

const useStyles = makeStyles(theme =>
    createStyles({
        content: {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            margin: '32px 60px',
            justifyContent: 'space-between',
        },
        formContainer: {
            height: '100%',
            width: '482px',
        },
        summaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '60px',
            width: '500px',
        },
        sectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
        businessInfoSectionTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            marginTop: '20px',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '16px',
        },
        currencyPicker: {
            width: '100px',
            marginLeft: '12px',
        },
        spacing: { marginBottom: '16px' },
        nextButton: {
            marginTop: '16px',
            width: 'fit-content',
        },
        marketingConsent: { marginTop: '16px' },
        checkbox: {
            height: '42px',
            marginTop: '-9px',
        },
    }),
);

const EcommerceBusinessInfoForm: React.FC<{
    completedBusinessInfo?: IBusinessInfoForm;
}> = ({ completedBusinessInfo }) => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();
    const { translatedCountries: countries } = useTranslatedCountries();
    const {
        currentSkuPackageDetails, setEcommerceCountry, arePricesLoading: packageLoading,
    } = useEcommerce();
    const {
        token, claims,
    } = useAuthContext();
    const logEcommerceEvent = useEcommerceTelemetry();
    const isSignupDirectBuyFlow = useIsSignupDirectBuyFlow();
    const isDirectBuyInProgressFlow = useIsDirectBuyInProgressFlow();
    const { filteredCountryCodeCountries: countryCodeCountries } = useEcommerceEnabledCountries();

    const profileState = useSelector(profile);
    const currentAccountLanguage = useLocalization();
    const currentAccountName = useSelector(accountLogicalName);

    const [ loading, setLoading ] = useState(false);

    const companyNameRef = useRef<HTMLInputElement | null>(null);

    const {
        data: existingMarketoData, isValidating,
    } = useSWR<IBusinessInfoPayload>(
        [ currentAccountName, `${billingUrl}/marketo` ],
        () => getExistingUserMarketoData(token, currentAccountName),
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        },
    );

    const formDefaultValues = useMemo(
        () => ({
            firstName: profileState?.accountUserDto?.firstName ?? claims?.given_name ?? '',
            lastName: profileState?.accountUserDto?.lastName ?? claims?.family_name ?? '',
            country: profileState?.accountUserDto?.country ?? '',
            state: '',
            businessEmail: profileState?.emailId ?? claims?.email ?? '',
            jobTitle: '',
            companyName: profileState?.accountUserDto?.companyName ?? '',
            logicalName: '',
            marketingConsent: false,
        }),
        [ profileState, claims ],
    );

    const {
        control, handleSubmit, watch, formState, reset, setError, setValue,
    } = useForm<IBusinessInfoForm>({
        mode: 'onChange',
        defaultValues: completedBusinessInfo ?? formDefaultValues,
    });

    const {
        isValid, errors,
    } = formState;

    const {
        country: formCountry,
        businessEmail,
        state,
        companyName,
    } = useMemo(() => watch([ 'country', 'businessEmail', 'state', 'companyName' ]), [ watch ]);

    const states: string[] | undefined = useMemo(() => {
        const statesList = (stateInfo as Record<string, string[]>)[formCountry];
        if (statesList && state && !statesList.includes(state)) {
            setValue('state', '', { shouldValidate: true });
        }
        return statesList;
    }, [ formCountry, setValue, state ]);

    useEffect(() => {
        const DnB = getDnBObject('ecommerce-business-info-form', 'businessEmail', 'companyName');
        DnB?.attach();
    }, []);

    useEffect(() => {
        if (!completedBusinessInfo && existingMarketoData) {
            const removedNullAndUndefined = Object.fromEntries(
                Object.entries(existingMarketoData).filter(entry => !!entry[1]),
            );
            delete removedNullAndUndefined.companyName;
            reset({
                ...formDefaultValues,
                ...removedNullAndUndefined,
            });
        }
    }, [ completedBusinessInfo, existingMarketoData, reset, formDefaultValues ]);

    useEffect(() => {
        (async () => {
            if (businessEmail && !errors.businessEmail && !(await validateEmailBlacklist(businessEmail))) {
                setError('businessEmail', { type: 'invalidDomain' });
                setLoading(false);
                return;
            }
        })();
    }, [ businessEmail, setError, errors.businessEmail ]);

    useEffect(() => {
        if (isSignupDirectBuyFlow) {
            const countryCode =
        Object.entries(countryCodeCountries)
            .find(entry => entry[1]?.toLowerCase() === formCountry.toLowerCase())
            ?.shift() ?? '';
            storeCountryCode(countryCode);
            setEcommerceCountry(countryCode);
        }
    }, [ formCountry, isSignupDirectBuyFlow, countryCodeCountries, setEcommerceCountry ]);

    const submit = useCallback(
        async (data: IBusinessInfoForm) => {
            setLoading(true);

            logEcommerceEvent('Billing.AddBusinessInfo', {
                SelectedPlan: currentSkuPackageDetails.type,
                Country: data.country,
                Flow: isDirectBuyInProgressFlow ? DIRECT_BUY_FLOW : TRY_BUY_FLOW,
                GAId: Cookies.get('_ga'),
                ...(isSignupDirectBuyFlow &&
                    { HashedOrganizationName: data.logicalName ? sha256Hex(data.logicalName) : data.logicalName }),
            });

            try {
                const marketoData = {
                    ...data,
                    companyName: companyNameRef.current?.value ?? data.companyName,
                    userLanguage: currentAccountLanguage,
                };

                await sendUserMarketoData(marketoData, token, currentAccountName);
                localStorage.setItem('marketoData', JSON.stringify(marketoData));
            } catch (error) {
                console.error(JSON.stringify(error));
            } finally {
                setLoading(false);
                history.replace({
                    ...history.location,
                    state: {
                        ...(history.location.state as object),
                        businessInfo: data,
                        showPaymentInfo: true,
                    },
                });
            }
        },
        [
            setLoading,
            currentAccountLanguage,
            currentAccountName,
            history,
            logEcommerceEvent,
            currentSkuPackageDetails.type,
            token,
            isDirectBuyInProgressFlow,
            isSignupDirectBuyFlow,
        ],
    );

    const companyNameOnFocusCallback = useCallback(
        (event: any) => {
            const currentValue = event.target?.value;
            if (currentValue && currentValue !== companyName) {
                setValue('companyName', currentValue);
            }
        },
        [ companyName, setValue ],
    );

    return (
        <form
            id="ecommerce-business-info-form"
            onSubmit={handleSubmit(submit)}>
            <div className={classes.content}>
                <div />
                <div className={classes.formContainer}>
                    <EcommerceFormStepper activeStep={0} />

                    <div className={classes.row}>
                        <Typography className={classes.sectionTitle}>
                            {translate({ id: 'CLIENT_PERSONAL_INFORMATION' })}
                        </Typography>
                    </div>

                    <div className={classes.row}>
                        <Controller
                            as={TextField}
                            style={{ marginRight: '12px' }}
                            control={control}
                            variant="outlined"
                            rules={{ required: true }}
                            id="firstName"
                            label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_FIRSTNAME' })}
                            name="firstName"
                            error={!!errors.firstName}
                            helperText={errors.firstName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            InputProps={{ className: 'Tall' }}
                            disabled={loading || isValidating}
                            data-cy="ecommerce-business-first-name"
                            fullWidth
                        />
                        <Controller
                            as={TextField}
                            control={control}
                            variant="outlined"
                            rules={{ required: true }}
                            id="lastName"
                            label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_LASTNAME' })}
                            name="lastName"
                            error={!!errors.lastName}
                            helperText={errors.lastName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            InputProps={{ className: 'Tall' }}
                            disabled={loading || isValidating}
                            data-cy="ecommerce-business-last-name"
                            fullWidth
                        />
                    </div>

                    <Controller
                        as={TextField}
                        className={classes.spacing}
                        control={control}
                        variant="outlined"
                        rules={{ required: true }}
                        id="jobTitle"
                        label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_JOBTITLE' })}
                        name="jobTitle"
                        error={!!errors.jobTitle}
                        helperText={errors.jobTitle?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        disabled={loading || isValidating}
                        data-cy="ecommerce-business-job-title"
                    />

                    <div className={classes.row}>
                        <UiSelect
                            control={control}
                            name="country"
                            isTranslated
                            inputLabel={translate({ id: 'CLIENT_FORM_COUNTRY_REGION' })}
                            options={countries}
                            required
                            fullWidth
                            dataCy="ecommerce-business-country"
                            error={!!errors.country}
                            disabled={loading || isValidating}
                            helperText={errors.country?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                        />
                        {states && (
                            <UiSelect
                                style={{ marginLeft: '12px' }}
                                control={control}
                                name="state"
                                inputLabel={translate({ id: 'CLIENT_STATE' })}
                                options={states}
                                required
                                dataCy="ecommerce-business-state"
                                error={!!errors.state}
                                disabled={loading || isValidating}
                                fullWidth
                                helperText={errors.state?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            />
                        )}
                    </div>

                    <div className={classes.row}>
                        <Typography className={classes.businessInfoSectionTitle}>
                            {translate({ id: 'CLIENT_BUSINESS_INFO' })}
                        </Typography>
                    </div>

                    <div className={classes.row}>
                        <Controller
                            as={TextField}
                            className={classes.spacing}
                            control={control}
                            variant="outlined"
                            style={{ marginRight: '12px' }}
                            rules={{
                                required: true,
                                validate: { invalid: value => emailValidator.validate(value) },
                            }}
                            id="businessEmail"
                            label={translate({ id: 'CLIENT_BUSINESS_EMAIL' })}
                            name="businessEmail"
                            error={!!errors.businessEmail}
                            helperText={
                                (errors.businessEmail?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                (errors.businessEmail?.type === 'invalid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })) ||
                (errors.businessEmail?.type === 'invalidDomain' &&
                  translate({ id: 'CLIENT_INVALID_EMAIL_DOMAIN_ERROR' }))
                            }
                            InputProps={{ className: 'Tall' }}
                            fullWidth
                            disabled={loading || isValidating}
                            data-cy="ecommerce-business-email"
                        />

                        <Controller
                            as={TextField}
                            className={classes.spacing}
                            control={control}
                            variant="outlined"
                            rules={{ required: true }}
                            id="companyName"
                            label={translate({ id: 'CLIENT_BUSINESS_COMPANY_NAME' })}
                            name="companyName"
                            error={!!errors.companyName}
                            helperText={errors.companyName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            InputProps={{
                                className: 'Tall',
                                onFocus: companyNameOnFocusCallback,
                                inputProps: {
                                    ref: companyNameRef,
                                    autoComplete: 'off',
                                },
                            }}
                            fullWidth
                            disabled={loading || isValidating}
                            data-cy="ecommerce-business-company-name"
                        />
                    </div>

                    {isSignupDirectBuyFlow && (
                        <>
                            <Controller
                                as={TextField}
                                className={classes.spacing}
                                control={control}
                                variant="outlined"
                                rules={{
                                    required: true,
                                    validate: p => !!p.trim(),
                                }}
                                id="logicalName"
                                label={translate({ id: 'CLIENT_NAME_OF_ORGANIZATION' })}
                                name="logicalName"
                                error={!!errors.logicalName}
                                helperText={errors.logicalName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                InputProps={{ className: 'Tall' }}
                                fullWidth
                                disabled={loading || isValidating}
                                data-cy="ecommerce-organization-logical-name"
                            />

                            <FormControlLabel
                                control={
                                    <Controller
                                        name="marketingConsent"
                                        control={control}
                                        disabled={loading || isValidating}
                                        render={({
                                            value, onChange,
                                        }) => (
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                                data-cy="ecommerce-marketing-consent"
                                            />
                                        )}
                                    />
                                }
                                label={
                                    <UiCheckBoxLabel
                                        label="CLIENT_MARKETING_CONDITION"
                                        href={[ 'https://www.uipath.com/legal/privacy-policy' ]}
                                        hrefText={[ 'CLIENT_SOCIAL_PRIVACY_POLICY' ]}
                                    />
                                }
                                className={classes.marketingConsent}
                            />
                        </>
                    )}

                    <UiProgressButton
                        className={classes.nextButton}
                        loading={loading || isValidating}
                        disabled={!isValid}
                        type="submit"
                        variant="contained"
                        data-cy="ecommerce-business-next"
                    >
                        {translate({ id: 'CLIENT_NEXT' })}
                    </UiProgressButton>
                </div>
                <div className={classes.summaryContainer}>
                    {currentSkuPackageDetails && (
                        <EcommerceCheckoutOrderSummary
                            {...currentSkuPackageDetails}
                            currency={currentSkuPackageDetails.defaultCurrency}
                            price={currentSkuPackageDetails.prices[currentSkuPackageDetails.defaultCurrency]}
                            discountedPrice={currentSkuPackageDetails.discountedPrices[currentSkuPackageDetails.defaultCurrency]}
                            discountDetails={currentSkuPackageDetails.discountDetails}
                            productPricesLoading={packageLoading}
                            isFirstSubscription={true}
                        />
                    )}
                </div>
            </div>
        </form>
    );
};

export default EcommerceBusinessInfoForm;
