import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    authenticationSettingUrl,
    getAuthenticationSetting,
    IAuthenticationSettingResponse,
} from '../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../store/selectors';

export const useCheckAuthenticationSetting = () => {
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHost = useSelector(isHostModeSelector);
    const { data: authenticationSetting } = useSWR<IAuthenticationSettingResponse, Error>(
        !isHost ? [ authenticationSettingUrl, partitionGlobalId ] : null,
        getAuthenticationSetting,
    );
    return authenticationSetting;
};
