import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import { isUnlicensedSelector } from '../../store/selectors';
import { ILicenseAndKey } from '../interfaces/licenses';

export const useLicenseExpired = () => {
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const { data } = useSWR<ILicenseAndKey, Error>(
        !isUnlicensedMode && !process.buildConfigs.disableUserLicensing ? licenseManagementAccountUrl : null,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const isLicenseExpired = useMemo(() => {
        return !!data && data.accountLicense.licenseStatus === 'EXPIRED';
    }, [ data ]);

    return isLicenseExpired;
};
