import { useRouteResolver } from '@experiences/util';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../../tenants/TenantConstants';
import UiAlertBanner from '../UiAlertBanner';

const useStyles = makeStyles(() =>
    createStyles({
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        link: { marginLeft: '8px' },
    }),
);

export const UiTenantStatusBanner: React.FC<{
    status: string | undefined; tenantName: string | undefined; dataCy?: string;
}> = ({
    status, tenantName, dataCy,
}) => {
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const classes = useStyles();

    const bannerText = useMemo(() => {
        switch (status?.toLocaleLowerCase()) {
            case TenantStatusConstants.UPDATING.toLowerCase(): return translate({ id: 'CLIENT_TENANT_STATUS_UPDATING' });
            case TenantStatusConstants.DISABLED.toLowerCase():
                return translate({ id: 'CLIENT_TENANT_STATUS_DISABLED' }, { tenant: tenantName });
            default: return '';
        }
    }, [ status, tenantName, translate ]);

    return (
        <>
            {bannerText &&
                <UiAlertBanner
                    dataCy={dataCy}
                    type="warning"
                    closeable={false}
                    enterpriseTrialAlertBar={true}>
                    <div className={classes.banner}>
                        {bannerText}
                    </div>
                </UiAlertBanner>}
        </>
    );
};
