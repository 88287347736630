import React, { useMemo } from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import {
    accountGlobalId,
    isAdminSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';
import UiAlertBanner from '../UiAlertBanner';

export const UiLicenseInGracePeriodBanner: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const {
        data, isValidating,
    } = useSWR<ILicenseAndKey, Error>(
        isAdmin && !isUnlicensedMode ? licenseManagementAccountUrl : null,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const isLicenseInGracePeriod = useMemo(() => {
        return !isValidating && data && data.accountLicense.licenseStatus === 'GRACE_PERIOD';
    }, [ data, isValidating ]);

    const gracePeriodEndDate = useMemo(() => {
        if (data) {
            return new Date((data.accountLicense.endDate + data.accountLicense.gracePeriod * 60 * 60 * 24) * 1000);
        }
        return undefined;
    }, [ data ]);

    return (
        <>
            {isAdmin && isLicenseInGracePeriod && (
                <div data-cy="ui-license-in-grace-period-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        {translate(
                            { id: 'CLIENT_LICENSE_IN_GRACE_ONPREMISE_WARNING_BANNER' },
                            {
                                0: gracePeriodEndDate && (
                                    <FormattedDate
                                        value={Date.UTC(
                                            gracePeriodEndDate.getUTCFullYear(),
                                            gracePeriodEndDate.getUTCMonth(),
                                            gracePeriodEndDate.getUTCDate(),
                                        )}
                                        year="numeric"
                                        month="short"
                                        day="numeric"
                                        timeZone="UTC"
                                    />
                                ),
                            },
                        )}
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
export default () => <UiLicenseInGracePeriodBanner />;
