import { TextField } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({ spacing: { marginBottom: '16px' } }),
);

const EcommerceAddressLine1: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, errors,
    } =
    useFormContext<ISubscriptionForm>();

    return (
        <Controller
            as={TextField}
            className={classes.spacing}
            control={control}
            variant="outlined"
            rules={{ required: true }}
            id="addressLine1"
            label={translate({ id: 'CLIENT_BILLING_ADDRESS' })}
            name="addressLine1"
            error={!!errors.addressLine1}
            helperText={
                (errors.addressLine1?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
        (errors.addressLine1?.type === 'addressError' &&
          translate({ id: 'CLIENT_CHECK_YOUR_BILLING_ADDRESS' }))
            }
            InputProps={{ className: 'Tall' }}
            fullWidth
            disabled={disabled}
            data-cy="ecommerce-form-address-line-1"
        />
    );
};

export default EcommerceAddressLine1;
