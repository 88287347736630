import { AccountLicense } from '@experiences/constants';
import { useIsEcommerceEnabled } from '@experiences/ecommerce';
import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiStorage,
    useRouteResolver,
} from '@experiences/util';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {
    Badge,
    FormControlLabel,
    IconButton,
    Popover,
    Switch,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';

import useUserInfo from '../../auth/hooks/UserInfo';
import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import useIsNoShowLeftNav from '../../common/hooks/useIsNoShowLeftNav';
import useProductName from '../../common/hooks/useProductName.default';
import { ISettingRoute } from '../../common/interfaces/route';
import adminRevampIcon from '../../images/adminRevampIcon.svg';
import { toggleAdminRevamp } from '../../store/action/AdminRevampToggleAction';
import { markAnnouncementRead } from '../../store/action/AnnouncementAction';
import {
    accountType,
    adminRevampSelector,
    announcementsList,
    isHostModeSelector,
    unReadAnnouncementCount,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import {
    isSubRoute,
    useIsSettingsRouteMatched,
    useSettingsRoutes,
} from '../../util/RouteUtil';
import OrganizationSwitcherDialogComponent from '../authentication/OrganizationSwitcherDialogComponent';
import UiLicenseExpiredBanner from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiTrialPerSkuLicenseInGracePeriodBanners from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { EcommerceAlertBarComponent } from './EcommerceAlertBarComponent';
import { LeftNav } from './LeftNav';
import { Notifications } from './Notifications';
import { SecondaryNav } from './SecondaryNav';
import TenantShell from './TenantShell';

const EnableHelpCopyright = getFeatureFlagValue(Features.EnableHelpCopyright.name);

export const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
        },
        contentArea: {
            display: 'flex',
            overflow: 'auto',
            flex: 1,
        },
        leftRail: { height: '100%' },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        appContent: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            overflow: 'auto',
        },
        popoverDefaults: {
            width: '339px',
            height: 'auto',
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxShadow: '0px 9px 46px rgba(0, 0, 0, 0.12)',
        },
        version: {
            color: theme.palette.semantic.colorForegroundDisable,
            paddingBottom: '12px',
        },
        notificationsIcon: {
            height: '48px',
            display: 'flex',
            alignSelf: 'center',
        },
        copyRightSlot: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '-27px',
        },
        copyRightText: { color: theme.palette.semantic.colorForegroundDeEmp },
        hidden: { display: 'none' },
        adminToggle: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        adminToggleText: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const PrivateShell: React.FC = props => {
    const classes = useStyles();
    const history = useHistory();
    const {
        productName, showTrademark,
    } = useProductName();
    const { formatMessage: translate } = useIntl();
    const isNoLeftNavRoute = useIsNoShowLeftNav();
    const dispatch = useDispatch();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const { token } = useUserInfo();

    const { logEvent } = useTelemetryHelper();

    const [ notificationsAnchorEl, setNotificationsAnchorEl ] = useState<any>(null);

    const settingsRoutes = useSettingsRoutes();
    const isSettingsRoute = useIsSettingsRouteMatched();
    const isPortalHome = useRouteMatch(RouteNames.Home);
    const announcements = useSelector(announcementsList);
    const unreadAnnouncementsCount = useSelector(unReadAnnouncementCount);
    const currentAccountType = useSelector(accountType);
    const adminRevamp = useSelector(adminRevampSelector);
    const isHost = useSelector(isHostModeSelector);
    const isTenantsRoute = useRouteMatch(RouteNames.Services);
    const isOrgAdminRoute = useRouteMatch(RouteNames.OrganizationAdminHome);

    const isEcommerceEnabled = useIsEcommerceEnabled();

    const EnableAdminRevampToggle = useFeatureFlagValue(Features.EnableAdminRevampToggle.name);
    const EnableAppBarEnterpriseTrial = useFeatureFlagValue(Features.EnableAppBarEnterpriseTrial.name);
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);
    const EnableAdminRevampDefaultExperience = useFeatureFlagValue(Features.EnableAdminRevampDefaultExperience.name);

    const [ adminRevampToggleState, setAdminRevampToggleState ] = useState<
    'off' | 'on' | 'off-to-on' | 'on-to-off'
    >(adminRevamp ? 'on' : 'off');

    const portalAppBarElementCallback = useCallback(
        (ref: HTMLPortalAppBarElement | null) => {
            const handleIconClicked = () => {
                history.push('/');
            };

            ref?.addEventListener('iconClicked', handleIconClicked);
            return () => ref?.removeEventListener('iconClicked', handleIconClicked);
        },
        [ history ],
    );

    const getSelectedPage = useCallback(
        (page: ISettingRoute) => {
            const subRouteParent = isSubRoute(location.pathname, page);
            return subRouteParent ? page.route === subRouteParent : !!matchPath(location.pathname, page.route);
        },
        [ location.pathname ],
    );

    const isResourceCenter = useMemo(() => {
        return location.pathname?.indexOf('resourceCenter') > -1;
    }, [ location.pathname ]);

    const isHelpPage = useMemo(
        () => location.pathname?.toLowerCase() === getRoute(RouteNames.ResourceCenter).toLowerCase(),
        [ getRoute, location.pathname ],
    );

    const listOfPages = useMemo(
        () => settingsRoutes.filter(
            item => typeof item.hide === 'boolean' ?
                item.hide !== true :
                item.hide?.(currentAccountType) !== true)
            .map(item => ({
                ...item,
                selected: getSelectedPage(item),
            })),
        [ currentAccountType, getSelectedPage, settingsRoutes ],
    );

    const openNotificationsFlyout = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
    }, []);

    const closeNotificationsFlyout = useCallback(() => {
        if (announcements?.length > 0) {
            dispatch(markAnnouncementRead());
        }
        setNotificationsAnchorEl(null);
    }, [ announcements?.length, dispatch ]);

    const showNotificationsBell = useMemo(() => {
        return announcements.length > 0;
    }, [ announcements?.length ]);

    const adminRevampToggleChanged = useCallback(async (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAdminRevampToggleState(ev.target.checked ? 'off-to-on' : 'on-to-off');
        dispatch(toggleAdminRevamp());
        history.push(!adminRevamp ? getRoute(RouteNames.OrganizationAdminHome)
            : (isHost ?
                getRoute(RouteNames.Organizations)
                : getRoute(RouteNames.Services)
            ),
        );
        await logEvent('PortalAdminRevamp.Toggle', {
            OldRevampValue: !ev.target.checked,
            NewRevampValue: ev.target.checked,
        });
        (window as any).pendo?.updateOptions({ visitor: { newExperienceOn: ev.target.checked } });
        (window as any).pendo?.loadGuides();
    }, [ adminRevamp, dispatch, getRoute, history, isHost, logEvent ]);

    const adminRevampEnabled = useIsAdminRevampEnabled();

    useEffect(() => {
        if (adminRevampEnabled && isTenantsRoute) {
            history.push(getRoute(RouteNames.OrganizationAdminHome));
        } else if (!adminRevampEnabled && isOrgAdminRoute) {
            history.push(getRoute(RouteNames.Services));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        window.location,
        adminRevampEnabled,
        getRoute,
        history,
        isOrgAdminRoute,
        isTenantsRoute,
    ]);

    useEffect(() => {
        if (EnableAdminRevampDefaultExperience) {
            if (UiStorage.getItem('adminRevamp') == null) {
                dispatch(toggleAdminRevamp());
                if (!isPortalHome && !isResourceCenter) {
                    history.push(getRoute(RouteNames.OrganizationAdminHome));
                }
                logEvent('PortalAdminRevamp.Toggle', {
                    OldRevampValue: false,
                    NewRevampValue: true,
                });
                (window as any).pendo?.updateOptions({ visitor: { newExperienceOn: true } });
                (window as any).pendo?.loadGuides();
            }
        }
    }, [ EnableAdminRevampDefaultExperience, dispatch, getRoute, history, isPortalHome, isResourceCenter, logEvent ]);

    return (
        <>
            <OrganizationSwitcherDialogComponent />
            <div className={classes.root}>
                <div className={classes.contentArea}>
                    <div className={clsx(classes.leftRail, isNoLeftNavRoute && classes.hidden)}>
                        <LeftNav />
                    </div>
                    <div className={classes.content}>
                        <portal-app-bar
                            ref={portalAppBarElementCallback}
                            product={productName}
                            show-trademark={showTrademark}
                            data-cy="portal-app-bar-title"
                            show-logo-trademark={process.buildConfigs.forceLogoTrademark || isSettingsRoute}
                            token={token || undefined}
                            feature={isResourceCenter ? translate({ id: 'CLIENT_RESOURCE_CENTER' }) : undefined}
                            show-waffle={isNoLeftNavRoute}
                            focus-mode={adminRevampEnabled && !isPortalHome}
                        >
                            {EnableAdminRevampToggle && isSettingsRoute &&
                                <div
                                    slot="slot-end"
                                    className={classes.adminToggle}>
                                    <img
                                        src={adminRevampIcon}
                                        alt='adminRevampIcon'
                                        style={{ marginRight: '4px' }}
                                        width='18px'
                                    />
                                    <FormControlLabel
                                        style={{ marginLeft: '4px' }}
                                        control={
                                            <Switch
                                                data-cy="new-admin-experience-toggle"
                                                checked={adminRevamp}
                                                onChange={adminRevampToggleChanged}
                                            />
                                        }
                                        label={
                                            <Typography className={classes.adminToggleText}>
                                                {translate({ id: 'CLIENT_ADMIN_REVAMP_TOGGLE' })}
                                            </Typography>
                                        }
                                        labelPlacement='start'
                                        data-state={adminRevampToggleState}
                                    />
                                </div>}
                            {EnableHelpCopyright && isHelpPage && (
                                <div
                                    slot="slot-end"
                                    className={classes.copyRightSlot}>
                                    <Typography className={classes.copyRightText}>
                                        {`© UiPath ${new Date().getFullYear()}`}
                                        {' '}
                                    </Typography>
                                    <portal-divider />
                                </div>
                            )}
                            {showNotificationsBell && (
                                <IconButton
                                    color="default"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    data-cy="App_Bar_Notifications_Button"
                                    onClick={openNotificationsFlyout}
                                    className={classes.notificationsIcon}
                                >
                                    <Badge
                                        badgeContent={unreadAnnouncementsCount}
                                        color="error">
                                        <NotificationsOutlinedIcon style={{ transform: 'scale(1.25)' }} />
                                    </Badge>
                                </IconButton>
                            )}
                            <Popover
                                anchorEl={notificationsAnchorEl}
                                open={Boolean(notificationsAnchorEl)}
                                onClose={closeNotificationsFlyout}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{ className: classes.popoverDefaults }}
                                transitionDuration={200}
                            >
                                <Notifications announcements={announcements} />
                            </Popover>
                        </portal-app-bar>
                        {isEcommerceEnabled && !EnableAppBarEnterpriseTrial && !EnableEcommercePromotionalDiscounts &&
                                AccountLicense[currentAccountType] === AccountLicense.TRIAL && <EcommerceAlertBarComponent />}
                        {adminRevampEnabled
                            && !isPortalHome
                            && <>
                                <UiLicenseInGracePeriodBanner />
                                <UiLicenseExpiredBanner />
                                <UiOverAllocationBanner />
                                <UiTrialPerSkuLicenseInGracePeriodBanners />
                            </>}
                        <div
                            className={classes.appContent}
                            id="portal-root">
                            {isSettingsRoute && (!adminRevampEnabled
                                ? <SecondaryNav pages={listOfPages} />
                                : <TenantShell />
                            )}
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default (props: PropsWithChildren<{}>) => (
    <ProtectedRoute>
        <PrivateShell {...props} />
    </ProtectedRoute>
);
