import { once } from 'lodash';
import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
} from 'react';

interface IUiData<T> {
    data: T;
    setData: React.Dispatch<React.SetStateAction<T>>;
}

const createUiDataContext = once(<T extends {}>() => createContext<IUiData<T>>({
    data: {} as T,
    setData: () => {},
}));

export const useUiDataContext = <T extends {}>() => {
    return useContext(createUiDataContext<T>());
};

export const UiDataContextProvider = <T extends {}>({
    children, initialState,
}: PropsWithChildren<{ initialState: T }>) => {
    const UiDataContext = createUiDataContext<T>();

    const [ data, setData ] = useState<T>(initialState);

    return <UiDataContext.Provider value={{
        data,
        setData,
    }}>
        {children}
    </UiDataContext.Provider>;
};
