import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    LanguageCulture,
    useTranslatedCountries,
} from '@experiences/locales';
import { portalTelemetry } from '@experiences/telemetry';
import {
    post,
    UiStorage,
    useAuthContext,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
    IDiscountBase,
    IPackagesConfig,
} from '../interfaces/ecommerce';
import {
    accountGlobalId,
    accountType,
    isAdminSelector,
    profile,
    userGlobalId,
} from './EcommerceSelectors';

const EnableEcommerceIntegration = getFeatureFlagValue(Features.EnableEcommerceIntegration.name);
const EnableEcommerceInUs = getFeatureFlagValue(Features.EnableEcommerceInUs.name);
const EnableEcommerceInEurope = getFeatureFlagValue(Features.EnableEcommerceInEurope.name);
const EnableEcommerceInWorld = getFeatureFlagValue(Features.EnableEcommerceInWorld.name);
const EnableEcommerceInJapan = getFeatureFlagValue(Features.EnableEcommerceInJapan.name);

const COUNTRY_CODE_KEY = 'countryCode';
export const DIRECT_BUY_SKU = 'directBuySku';
export const DIRECT_BUY_ORDER_CONFIRMATION = 'directBuyOrderConfirmation';
export const DIRECT_BUY_FLOW = 'DirectBuy';
export const TRY_BUY_FLOW = 'TryBuy';
export const INCREMENTAL_FLOW = 'Incremental';
export const ECOMMERCE_DISCOUNT_BANNER_KEY = 'Portal.DisplayEcommerceDiscountBanner';
// eslint-disable-next-line max-len
export const JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH = 'https://www.uipath.com/hubfs/legalspot/jp-uipath-the-indication-of-advertisement-based-on-the-specified-commercial-transactions-act.pdf';

export const europeCountries = [
    'AX',
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'GB',
    'GE',
    'DE',
    'GI',
    'GR',
    'GL',
    'HU',
    'VA',
    'IS',
    'IE',
    'IM',
    'IT',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MD',
    'MC',
    'ME',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'CS',
    'RS',
    'SK',
    'SI',
    'SJ',
    'ES',
    'SE',
    'CH',
    'TR',
    'TC',
    'UA',
];
export const telemetryUrl = '/api/telemetry';
export const ECOMMERCE_PRODUCT_CODES = [ 'RPADEVPRONU', 'UNATT', 'CTZDEVNU', 'ATTUNU' ];
export const ECOMMERCE_PRODUCT_EXTENDED_CODES = [ 'RPADEVPRONU', 'RU', 'CTZDEVNU', 'ATTUNU', 'UNATT' ];
export const getPriceString = (price: number, currency: string, language: string, tax?: number, withDecimals: boolean = true) => {
    if (currency.toUpperCase() === 'JPY') {
        return `${(tax ? (price + tax) : price).toLocaleString(language, {
            style: 'currency',
            currency: currency,
        })}`;
    }

    return `${(tax ? (price + tax) / 100 : price / 100).toLocaleString(language, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: withDecimals ? 2 : 0,
        maximumFractionDigits: withDecimals ? 2 : 0,
    })}`;
};

export const getTaxPercentageString = (taxRate: number, language: string) => {
    return `${(taxRate / 100).toLocaleString(language, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
    })}`;
};

export const storeCountryCode = (countryCode: string) => {
    return sessionStorage.setItem(COUNTRY_CODE_KEY, countryCode);
};

export const getStoredCountryCode = () => {
    return sessionStorage.getItem(COUNTRY_CODE_KEY) ?? '';
};

export const getUniqueDiscountFromList = (discounts: IDiscountBase[]) => {
    const couponIds = discounts?.map(d => d?.couponId);
    const uniqueCouponIds = [ ...new Set(couponIds) ];
    if (uniqueCouponIds.length === 1) {
        return discounts[0];
    }
};

export const getUniqueDiscountPackagesConfig = (packagesData: IPackagesConfig) => {
    const uniqueDiscountsPerPackage = packagesData?.packages
        .map(p => getUniqueDiscountFromList(p.productDiscounts))
        .filter((d): d is IDiscountBase => !!d);
    return getUniqueDiscountFromList(uniqueDiscountsPerPackage);
};

export const useIsDirectBuyInProgressFlow = () => {
    return !!UiStorage.getItem(DIRECT_BUY_SKU);
};

export const useIsSignupDirectBuyFlow = () => {
    const accountId = useSelector(accountGlobalId);
    const isDirectBuyInProgress = useIsDirectBuyInProgressFlow();
    return (
        (isDirectBuyInProgress || !!UiStorage.getItem(DIRECT_BUY_ORDER_CONFIRMATION)) && !accountId
    );
};

export const useIsEcommerceEnabled = () => {
    const { accountCountryCode } = useTranslatedCountries();
    const isAdmin = useSelector(isAdminSelector);

    const isEcommerceEnabled = useMemo(
        () =>
            isAdmin &&
      EnableEcommerceIntegration &&
      ((EnableEcommerceInUs && (accountCountryCode === 'US' || accountCountryCode === 'CA')) ||
        (EnableEcommerceInEurope && europeCountries.includes(accountCountryCode)) ||
        (EnableEcommerceInWorld &&
          accountCountryCode !== 'US' &&
          accountCountryCode !== 'CA' &&
          !europeCountries.includes(accountCountryCode) &&
          accountCountryCode !== 'JP') ||
        (EnableEcommerceInJapan && accountCountryCode === 'JP')),
        [ isAdmin, accountCountryCode ],
    );

    return isEcommerceEnabled;
};

export const useEcommerceEnabledCountries = () => {
    const {
        accountCountryCode, translatedCountries, countryCodeCountries, englishCountryCodeCountries,
    } =
    useTranslatedCountries();

    const availableCountryCodes = useMemo(() => {
        const countryCodes: string[] = [];
        if (EnableEcommerceInUs) {
            countryCodes.push('US');
            countryCodes.push('CA');
        }
        if (EnableEcommerceInEurope) {
            countryCodes.push(...europeCountries);
        }
        if (EnableEcommerceInWorld) {
            countryCodes.push(
                ...Object.keys(englishCountryCodeCountries).filter(
                    code => code !== 'US' && code !== 'CA' && code !== 'JP' && !europeCountries.includes(code),
                ),
            );
        }
        if (EnableEcommerceInJapan) {
            countryCodes.push('JP');
        }

        return countryCodes;
    }, [ englishCountryCodeCountries ]);

    const filteredCountryCodeCountries = useMemo(() => {
        return Object.fromEntries(
            Object.entries(countryCodeCountries).filter(entry => availableCountryCodes.includes(entry[0])),
        );
    }, [ countryCodeCountries, availableCountryCodes ]);

    const filteredTranslatedCountries = useMemo(() => {
        const filteredCountryNames = Object.values(filteredCountryCodeCountries);
        return Object.fromEntries(
            Object.entries(translatedCountries).filter(entry => filteredCountryNames.includes(entry[1])),
        );
    }, [ translatedCountries, filteredCountryCodeCountries ]);

    return {
        accountCountryCode,
        filteredTranslatedCountries,
        filteredCountryCodeCountries,
        englishCountryCodeCountries,
    };
};

export const useEcommerceTelemetry = () => {
    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);
    const currentProfile = useSelector(profile);
    const {
        claims, token,
    } = useAuthContext();
    const email = currentProfile?.emailId ? currentProfile.emailId : claims?.email;

    return useCallback(
        async (name: string, details: any = {}) => {
            const logEventUrl = `${telemetryUrl}/logCustomEvent`;
            try {
                await post(logEventUrl, {
                    body: {
                        name: name,
                        properties: {
                            ApplicationName: 'UiPath.AutomationCloud',
                            CloudOrganizationId: accountId,
                            CloudUserId: userId,
                            SubscriptionType: subscriptionType,
                            IsTestAccount: !!email?.endsWith('uipath-qa.com'),
                            ...details,
                        },
                    },
                    accessToken: token,
                });
            } catch (e) {
                portalTelemetry.trackTrace({
                    message: `Error tracking ecommerce event ${name}`,
                    severityLevel: SeverityLevel.Error,
                });
            }
        },
        [ accountId, userId, subscriptionType, email, token ],
    );
};

export function setEcommerceParamsInStorage(
    directBuySku: string,
    ecommerceRedirect: string,
    subscriptionPlan?: string,
) {
    const hasDirectBuySkuParam = !!directBuySku;
    if (hasDirectBuySkuParam) {
        UiStorage.setItem(DIRECT_BUY_SKU, directBuySku);
    } else {
        UiStorage.setItem('ecommerceRedirect', ecommerceRedirect);
        if (subscriptionPlan) {
            UiStorage.setItem('subscriptionPlan', subscriptionPlan);
        }
        UiStorage.removeItem(DIRECT_BUY_SKU);
    }
}

export function formatDate(dateToFormat: string | undefined, language: LanguageCulture) {
    return dateToFormat
        ? new Date(dateToFormat).toLocaleDateString(language === 'en' ? 'en-US' : language, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })
        : dateToFormat;
}
