import {
    useAppError,
    useGetErrorInfo,
} from '@experiences/error';
import {
    getUserLanguageFromLocalStorage,
    LanguageCulture,
    useUpdateLanguage,
} from '@experiences/locales';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { getUserOrganizationInfo } from '../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../store/action/UserProfileAction';
import useUserInfo from './UserInfo';

export default function useInit() {
    const { formatMessage: translate } = useIntl();
    const { getErrorObject } = useGetErrorInfo();

    const updateLanguage = useUpdateLanguage();

    const {
        ErrorComponent, renderError, setError, clearError,
    } = useAppError('login');

    const { token } = useUserInfo();

    const changeLanguage = useCallback((id: LanguageCulture) => updateLanguage(id), [ updateLanguage ]);

    const init = useCallback(async () => {
        try {
            const userOrganizationInfo = await getUserOrganizationInfo(token);
            setUserProfile(userOrganizationInfo);
            changeLanguage(getUserLanguageFromLocalStorage());
        } catch (error) {
            const errorObject = await getErrorObject(error);
            setError(error, 'Error occurred while getting user organization info', clearError, {
                title: translate({ id: 'CLIENT_ERROR_ORGANIZATION_INFO' }),
                message: errorObject,
            });
        }
    }, [ token, changeLanguage, getErrorObject, setError, clearError, translate ]);

    return {
        init,
        ErrorComponent,
        renderError,
    };
}
