import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { AccountType } from '@experiences/interfaces';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { accountType } from '../../store/selectors';

const useCheckLicense = () => {

    const licensePlan = useSelector(accountType);

    const EnableOffersRevampAdmin = useFeatureFlagValue(Features.EnableOffersRevampAdmin.name);

    const includesLicense = useCallback((licenses: AccountLicense[]) => {
        return licenses.includes(AccountLicense[licensePlan]);
    }, []);

    const notIncludesLicense = useCallback((licenses: AccountLicense[]) => {
        return !licenses.includes(AccountLicense[licensePlan]);
    }, []);

    const isOffersRevampAndCommunity = useMemo(() => {
        return EnableOffersRevampAdmin &&
        AccountLicense[licensePlan] <= AccountLicense.FREEKIT && AccountLicense[licensePlan] >= AccountLicense['PRO-TRIAL'];
    }, []);

    const isAccountLicenseEnterpriseOrPro = useCallback((accountLicense?: AccountType) => {
        return accountLicense
            ? AccountLicense[accountLicense] === AccountLicense.ENTERPRISE || AccountLicense[accountLicense] === AccountLicense.PRO
            : includesLicense([ AccountLicense.ENTERPRISE, AccountLicense.PRO ]);
    }, []);

    const isUserEnterprise = useMemo(() => {
        return AccountLicense[licensePlan] <= AccountLicense.TRIAL;
    }, [ licensePlan ]);

    return {
        includesLicense,
        notIncludesLicense,
        isOffersRevampAndCommunity,
        isUserEnterprise,
        isAccountLicenseEnterpriseOrPro,
    };
};

export default useCheckLicense;
