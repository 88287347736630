import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import GlobalStyles from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useLocalizedLinks,
    useModalState,
    useShowDialog,
} from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import { KerberosSetupLinks } from '../../../common/constants/KerberosLinks';
import * as RouteNames from '../../../common/constants/RouteNames';
import { secretPlaceholder } from '../../../common/constants/SecretConstant';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import {
    createLDAPDirectoryConnection,
    DirectoryConnectionType,
    directoryConnectionUrl,
    getDirectoryConnection,
    IDirectoryConnectionPayload,
    testConnectionConfiguration,
    updateDirectoryConnection,
} from '../../../services/identity/DirectoryConnectionService';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapADDataToDirectoryConnectionDto,
    mapDirectoryConnectionToADConfigData,
} from '../../../util/DirectoryConnectionUtil';
import { isValidDomain } from '../../../util/DomainUtil';
import {
    mapADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToADConfigData,
} from '../../../util/ExternalIdentityProviderUtil';
import validateDownLevelLogonName from '../../../util/validators/DownLevelLogonNameValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import EditIdentityProviderFormComponent, {
    defaultEditIdentityProviderData,
    IEditIdentityProviderData,
} from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        section: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        warningText: {
            color: theme.palette.semantic.colorWarningText,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '20px',
            paddingRight: '8px',
        },
        labelWithIcon: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        infoIcon: {
            marginLeft: '8px',
            color: theme.palette.semantic.colorInfoForeground,
            width: '20px',
        },
        switches: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
}));

interface IADDirectoryConnectionData {
    domain?: string;
    username?: string;
    password?: string;
    ldaps?: boolean;
    useKerberosAuth?: boolean;
}

const defaultADDirectoryConnectionData: IADDirectoryConnectionData = {
    domain: '',
    username: '',
    password: '',
    ldaps: false,
    useKerberosAuth: true,
};

export const defaultADConfigData: IADConfigData = {
    ...defaultEditIdentityProviderData,
    ...defaultADDirectoryConnectionData,
};

export interface IADConfigData extends IEditIdentityProviderData, IADDirectoryConnectionData { }

const ConfigureADComponent: FC = () => {
    const { formatMessage: translate } = useIntl();

    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();
    const organizationName = useOrganizationName();

    const {
        open, close,
    } = useModalState(isSecuritySettingsRevampEnabled
        ? RouteNames.SecuritySettings : RouteNames.AuthSettings);

    const classes = useStyles();
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const partitionGlobalId = useSelector(accountGlobalId);
    const setErrorDialog = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const [ errorMessage, setErrorMessage ] = useState<string>(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const getLocalizedLink = useLocalizedLinks();
    const [ showPasswordPlaceholder, setShowPasswordPlaceholder ] = useState(false);
    const methods = useForm<IADConfigData>({
        mode: 'onSubmit',
        defaultValues: { ...defaultADConfigData },
    });
    const {
        control, handleSubmit, formState, errors, reset, setValue, watch,
    } = useMemo(() => methods, [ methods ]);
    const {
        isDirty, isSubmitting, dirtyFields,
    } = formState;
    const { data: fetchedConfigurationData } = useSWR<IDirectoryConnectionPayload[], Error>(
        [ directoryConnectionUrl, partitionGlobalId ],
        getDirectoryConnection,
        { shouldRetryOnError: false },
    );
    const fetchedExternalIdentity = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);
    const fetchedDirectoryConnection = useMemo(
        () =>
            fetchedConfigurationData?.find(
                connection =>
                    connection.type === DirectoryConnectionType.AD ||
                    connection.type === DirectoryConnectionType.LDAPAD,
            ),
        [ fetchedConfigurationData ],
    );

    const { isAD } = useMemo(() => ({
        isAD: fetchedDirectoryConnection?.type === DirectoryConnectionType.AD,
        isLDAPAD: fetchedDirectoryConnection?.type === DirectoryConnectionType.LDAPAD,
    }), [ fetchedDirectoryConnection ]);

    const isUpdatingConnection = Object.keys(dirtyFields)
        .some(field => [ 'domain', 'username', 'password', 'ldaps', 'useKerberosAuth', 'isActive' ].includes(field));

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );
    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                if (name === 'useKerberosAuth') {
                    setValue('ldaps', false, { shouldDirty: true });
                }
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const onSubmit = useCallback(
        async (data: IADConfigData) => {
            const dto = mapADDataToDirectoryConnectionDto(data);
            const externalIdentityPayload = mapADConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);

            if (isUpdatingConnection) {
                try {
                    await testConnectionConfiguration(dto);
                } catch (error) {
                    const message = await getErrorMessage(error);
                    if (isSecuritySettingsRevampEnabled) {
                        setErrorDialog(message);
                    } else {
                        setErrorMessage(
                            message
                                ? translate({ id: 'CLIENT_CONFIGURE_AD_TEST_CONFIG_CODE_ERROR' }, { 0: message })
                                : translate({ id: 'CLIENT_CONFIGURE_AD_TEST_CONFIG_ERROR' }),
                        );
                        setShowDrawerError(true);
                    }
                    return;
                }
            }

            try {
                if (isSecuritySettingsRevampEnabled) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'Active Directory' }),
                        body: <Typography>
                            {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'Active Directory' })}
                        </Typography>,
                        icon: 'info',
                        showCancel: true,
                        primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                        cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                    });
                    externalIdentityPayload.isActive = proceed;
                }
                await Promise.all([
                    fetchedExternalIdentity
                        ? updateExternalIdentityProvider(externalIdentityPayload)
                        : createExternalIdentityProvider(externalIdentityPayload),
                    (isUpdatingConnection || externalIdentityPayload.isActive) &&
                    (fetchedDirectoryConnection
                        ? updateDirectoryConnection(
                            partitionGlobalId,
                            fetchedDirectoryConnection.id,
                            DirectoryConnectionType.LDAPAD,
                            dto,
                        )
                        : createLDAPDirectoryConnection(partitionGlobalId, dto)),
                ]);
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }));
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                if (isSecuritySettingsRevampEnabled) {
                    setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
                    setShowDrawerError(true);
                }
            }
        },
        [
            fetchedExternalIdentity,
            isUpdatingConnection,
            getErrorMessage,
            isSecuritySettingsRevampEnabled,
            setErrorDialog,
            translate,
            fetchedDirectoryConnection,
            partitionGlobalId,
            createNotification,
            showRestartMessageDialog,
            close,
            createDialog,
        ],
    );

    useEffect(() => {
        const mappedExternalIdentity = fetchedExternalIdentity
            ? mapExternalIdentityPayloadToADConfigData(fetchedExternalIdentity)
            : defaultEditIdentityProviderData;
        const mappedDirectoryConnection = fetchedDirectoryConnection
            ? mapDirectoryConnectionToADConfigData(fetchedDirectoryConnection)
            : defaultADDirectoryConnectionData;
        reset({
            ...mappedExternalIdentity,
            ...mappedDirectoryConnection,
            useKerberosAuth: (!process.buildConfigs.showKerberosAuth
                || mappedDirectoryConnection.useKerberosAuth)
                ?? false,
        });
        if (fetchedDirectoryConnection) {
            setShowPasswordPlaceholder(true);
        }
    }, [ fetchedDirectoryConnection, fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: isSecuritySettingsRevampEnabled ? RouteNames.SecuritySettings : RouteNames.AuthSettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
    ], [ isSecuritySettingsRevampEnabled, organizationName, translate ]);

    const form = useMemo(() =>
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton)}
                        onClick={() => close()}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        disabled={!isDirty}
                        variant="contained"
                        data-cy="configure-ad-submit-button"
                    >
                        {translate({ id: isUpdatingConnection ? 'CLIENT_TEST_AND_SAVE' : 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            isDrawer={!isSecuritySettingsRevampEnabled}
            centerChild={isSecuritySettingsRevampEnabled}
            addScrollPadding={!isSecuritySettingsRevampEnabled}

        >
            <FormProvider {...methods}>
                <EditIdentityProviderFormComponent
                    switches={
                        <div className={classes.switches}>
                            {!isAD && <Controller
                                name="ldaps"
                                control={control}
                                render={props => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    (!process.buildConfigs.showKerberosAuth ||
                                                            watch('useKerberosAuth') === false) &&
                                                        props.value
                                                }
                                                disabled={
                                                    process.buildConfigs.showKerberosAuth &&
                                                        watch('useKerberosAuth') === true
                                                }
                                                onChange={e =>
                                                    handleSwitch('ldaps', e.target.checked, props.onChange)}
                                                color="primary"
                                                data-cy="configure-ad-ldaps-switch"
                                            />
                                        }
                                        label={
                                            <div className={classes.labelWithIcon}>
                                                <div className={classes.switchLabel}>
                                                    {translate({ id: 'CLIENT_USE_LDAP_OVER_SSL' })}
                                                </div>
                                                <Tooltip
                                                    arrow
                                                    title={translate({ id: 'CLIENT_LDAPS_WARNING' })}>
                                                    <InfoOutlinedIcon
                                                        className={classes.infoIcon}
                                                        tabIndex={0}
                                                        aria-label={translate({ id: 'CLIENT_LDAPS_WARNING' })}
                                                    />
                                                </Tooltip>
                                            </div>
                                        }
                                    />
                                )}
                            />}
                            {process.buildConfigs.showKerberosAuth && (
                                <Controller
                                    name="useKerberosAuth"
                                    control={control}
                                    render={props => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={props.value}
                                                    onChange={e =>
                                                        handleSwitch(
                                                            'useKerberosAuth',
                                                            e.target.checked,
                                                            props.onChange,
                                                        )}
                                                    color="primary"
                                                    data-cy="configure-ad-kerberos-switch"
                                                />
                                            }
                                            label={
                                                <div className={classes.labelWithIcon}>
                                                    <div className={classes.switchLabel}>
                                                        {translate({ id: 'CLIENT_USE_KERBEROS_AUTH' })}
                                                    </div>
                                                    <Tooltip
                                                        arrow
                                                        role="tooltip"
                                                        title={
                                                            translate(
                                                                { id: 'CLIENT_KERBEROS_AUTH_TOOLTIP' },
                                                                {
                                                                    link: (
                                                                        <a
                                                                            className={classes.a}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            href={getLocalizedLink(
                                                                                KerberosSetupLinks,
                                                                            )}
                                                                        >
                                                                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                                                                        </a>
                                                                    ),
                                                                },
                                                            ) as any
                                                        }
                                                    >
                                                        <InfoOutlinedIcon
                                                            className={classes.infoIcon}
                                                            tabIndex={0}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                        />
                                    )}
                                />
                            )}
                            {(!process.buildConfigs.showKerberosAuth || watch('useKerberosAuth') === false) &&
                                    !isAD && watch('ldaps') === false && (
                                <>
                                    <portal-alert-bar
                                        status="warning"
                                        cancelable={false}
                                        data-cy="ad-warning-1"
                                    >
                                        <Typography className={classes.warningText}>
                                            {translate({ id: 'CLIENT_RECOMMENDED_ACTION_WARNING_1' })}
                                        </Typography>
                                    </portal-alert-bar>
                                    <div style={{ marginBottom: '4px' }} />
                                </>
                            )}
                            {process.buildConfigs.showKerberosAuth && watch('useKerberosAuth') === false && (
                                <portal-alert-bar
                                    status="warning"
                                    cancelable={false}
                                    data-cy="ad-warning-2">
                                    <Typography className={classes.warningText}>
                                        {translate({ id: 'CLIENT_RECOMMENDED_ACTION_WARNING_2' })}
                                    </Typography>
                                </portal-alert-bar>
                            )}
                        </div>
                    }
                />
            </FormProvider>
            {!watch('useKerberosAuth') && (
                <>
                    <div className={classes.section}>
                        <Controller
                            label={translate({ id: 'CLIENT_DEFAULT_DOMAIN' })}
                            as={TextField}
                            control={control}
                            required={isUpdatingConnection}
                            rules={{
                                required: isUpdatingConnection,
                                validate: { validDomain: value => !isUpdatingConnection || !value || isValidDomain(value) },
                            }}
                            error={!!errors.domain}
                            helperText={
                                (errors.domain?.type === 'required' &&
                                        translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_DEFAULT_DOMAIN' }) },
                                        )) ||
                                    (errors.domain?.type === 'validDomain' &&
                                        translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' }))
                            }
                            name="domain"
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            inputProps={{ 'aria-labelledby': 'defaultDomainLabel' }}
                            data-cy="configure-ad-domain"
                        />
                    </div>
                    <div className={classes.section}>
                        <Controller
                            label={translate({ id: 'CLIENT_USERNAME' })}
                            as={TextField}
                            control={control}
                            required={isUpdatingConnection}
                            rules={{
                                required: isUpdatingConnection,
                                validate: { valid: value => !isUpdatingConnection || validateDownLevelLogonName(value) },
                            }}
                            error={!!errors.username}
                            helperText={
                                (errors.username?.type === 'required' &&
                                        translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_USERNAME' }) },
                                        )) ||
                                    (errors.username?.type === 'valid' &&
                                        translate({ id: 'CLIENT_INVALID_DOWNLEVEL_LOGON_NAME_ERROR' }))
                            }
                            name="username"
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            inputProps={{ 'aria-labelledby': 'usernameLabel' }}
                            data-cy="configure-ad-username"
                        />
                    </div>
                    <div className={classes.section}>
                        <Controller
                            label={translate({ id: 'CLIENT_USER_PASSWORD' })}
                            as={TextField}
                            control={control}
                            name="password"
                            variant="outlined"
                            type="password"
                            autoComplete="new-password"
                            required={isUpdatingConnection}
                            rules={{ required: isUpdatingConnection }}
                            error={!!errors.password}
                            helperText={
                                errors.password?.type === 'required' &&
                                    translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_USER_PASSWORD' }) },
                                    )
                            }
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            inputProps={{ 'aria-labelledby': 'userPasswordLabel' }}
                            placeholder={showPasswordPlaceholder ? secretPlaceholder : undefined}
                            data-cy="configure-ad-password"
                        />
                    </div>
                </>
            )}
        </UiForm>
    , [
        isSecuritySettingsRevampEnabled,
        classes,
        close,
        control,
        errors,
        getLocalizedLink,
        handleSubmit,
        handleSwitch,
        isAD,
        isDirty,
        isSubmitting,
        isUpdatingConnection,
        methods,
        onSubmit,
        showPasswordPlaceholder,
        translate,
        watch,
    ]);

    return (
        isSecuritySettingsRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                maxWidth="900px"
                position='center'>
                {form}
            </UiPageContainer>
            :
            <UiDrawer
                title={translate({ id: 'CLIENT_CONFIGURE_AD_INTEGRATION' })}
                drawerProps={{
                    anchor: 'right',
                    open,
                    onClose: () => close(),
                }}
                error={{
                    showError: showDrawerError,
                    message: errorMessage,
                }}
                themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            >
                {form}
            </UiDrawer>
    );
};

export default ConfigureADComponent;
