import { useMemo } from 'react';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import useDirectoryConnectionType from '../../../common/hooks/useDirectoryConnectionType';
import { DirectoryConnectionType } from '../../../services/identity/DirectoryConnectionService';
import { UiPeoplePickerType } from '.';

const useUiPeoplePickerStringIds = (peoplePickerType: UiPeoplePickerType = 'all') => {
    const directoryConnectionType = useDirectoryConnectionType();
    const { checkTokenTypeIsAuth0: isAuth0 } = useCheckAuthTypes();

    const defaultHelperTextId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_HELPER_TEXT';
        }
        if (isAuth0) {
            return 'CLIENT_PEOPLE_PICKER_DEFAULT_HELPER_TEXT';
        }
        switch (directoryConnectionType) {
            case DirectoryConnectionType.AAD:
                return peoplePickerType === 'user'
                    ? 'CLIENT_PEOPLE_PICKER_DEFAULT_HELPER_TEXT'
                    : 'CLIENT_PEOPLE_PICKER_ALL_AAD_HELPER_TEXT';
            case DirectoryConnectionType.AD:
            case DirectoryConnectionType.LDAPAD:
                return 'CLIENT_PEOPLE_PICKER_AD_LDAP_HELPER_TEXT';
            default:
                return 'CLIENT_PEOPLE_PICKER_DEFAULT_HELPER_TEXT';
        }
    }, [ directoryConnectionType, isAuth0, peoplePickerType ]);

    const defaultPlaceholderId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER';
        }
        if (isAuth0) {
            return 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER';
        }
        switch (directoryConnectionType) {
            case DirectoryConnectionType.AAD:
                return peoplePickerType === 'user'
                    ? 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER'
                    : 'CLIENT_PEOPLE_PICKER_ALL_PLACEHOLDER';
            case DirectoryConnectionType.AD:
            case DirectoryConnectionType.LDAPAD:
            default:
                return 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER';
        }
    }, [ directoryConnectionType, isAuth0, peoplePickerType ]);

    const invalidErrorMessageId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_ERROR';
        }
        switch (directoryConnectionType) {
            case DirectoryConnectionType.AD:
            case DirectoryConnectionType.LDAPAD:
                return 'CLIENT_INVALID_GROUP_MEMBER_AD_LDAP_ERROR';
            case DirectoryConnectionType.AAD:
            default:
                return 'CLIENT_INVALID_EMAIL_ERROR';
        }
    }, [ directoryConnectionType, peoplePickerType ]);

    return {
        defaultHelperTextId,
        defaultPlaceholderId,
        invalidErrorMessageId,
    };
};

export default useUiPeoplePickerStringIds;
