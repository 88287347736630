import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import React, {
    FC,
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { isHostModeSelector } from '../../../store/selectors';
import { AdvancedSettingsTabComponent } from './AdvancedSettingsTabContent';
import EmailSettingsComponent from './EmailSettingsComponent';
import GeneralSettingsTabContent from './GeneralSettingsTabContent';
import LoginCustomizationComponent from './LoginCustomizationComponent';

const OrganizationSettingsTab = {
    GENERAL: 'general',
    ADVANCED: 'advanced',
    EMAIL: 'email',
    LOGIN_CUSTOMIZATION: 'loginCustomization',
};

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case OrganizationSettingsTab.GENERAL:
            return <GeneralSettingsTabContent />;
        case OrganizationSettingsTab.ADVANCED:
            return <AdvancedSettingsTabComponent />;
        case OrganizationSettingsTab.EMAIL:
            return <EmailSettingsComponent />;
        case OrganizationSettingsTab.LOGIN_CUSTOMIZATION:
            return <LoginCustomizationComponent />;
    }

    return null;
};

const OrganizationSettingsTabs: FC = () => {
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const isEmail = useRouteMatch(RouteNames.EmailSettings);
    const isLoginCustomization = useRouteMatch(RouteNames.LoginCustomization);
    const isAdvancedSettings = useRouteMatch(RouteNames.OrganizationSettingsAdvanced);

    const hostMode = useSelector(isHostModeSelector);

    const [ tabValue, setTabValue ] = useState(
        (isEmail && OrganizationSettingsTab.EMAIL)
        || (isLoginCustomization && OrganizationSettingsTab.LOGIN_CUSTOMIZATION)
        || (isAdvancedSettings && OrganizationSettingsTab.ADVANCED)
        || OrganizationSettingsTab.GENERAL,
    );

    const handleChange = useCallback((_, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={
                    isAdminRevampEnabled
                        ? {
                            width: '100%',
                            alignItems: 'center',
                        }
                        : undefined
                }>
                {!hostMode && !process.buildConfigs.disableGeneralOrganizationSettings && (
                    <Tab
                        value={OrganizationSettingsTab.GENERAL}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_GENERAL_TAB' })}
                        onClick={() => history.push(getRoute(RouteNames.OrganizationSettings))}
                        data-cy="organization-settings-general-tab"
                        className="default"
                    />
                )}
                {!hostMode && !process.buildConfigs.showForMSI && isAdminRevampEnabled && (
                    <Tab
                        value={OrganizationSettingsTab.ADVANCED}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_ADVANCED_TAB' })}
                        onClick={() => history.push(getRoute(RouteNames.OrganizationSettingsAdvanced))}
                        data-cy="organization-settings-advanced-tab"
                        className="default"
                    />
                )}
                {process.buildConfigs.enableEmailSettings && (
                    <Tab
                        value={OrganizationSettingsTab.EMAIL}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_EMAIL_TAB' })}
                        onClick={() => history.push(getRoute(RouteNames.EmailSettings))}
                        data-cy="organization-settings-email-tab"
                        className="default"
                    />
                )}
                {process.buildConfigs.enableLoginCustomization && hostMode && (
                    <Tab
                        value={OrganizationSettingsTab.LOGIN_CUSTOMIZATION}
                        label={translate({ id: 'CLIENT_ORGANIZATION_SETTINGS_LOGIN_CUSTOMIZATION_TAB' })}
                        onClick={() => history.push(getRoute(RouteNames.LoginCustomization))}
                        data-cy="organization-settings-login-customization-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default OrganizationSettingsTabs;
