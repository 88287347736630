import { merge } from 'lodash';
import { DeepPartial } from 'redux';

import { EmailSettingKey } from '../../common/constants/EmailSettingConstant';
import { IEmailSettingsData } from '../../common/interfaces/emailSetting';
import { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import { defaultEmailSettingsData } from '../../component/organizationsettings/subcomponents/EmailSettingsComponent';
import { ISetting } from '../../services/identity/SettingService';

export function mapSettingArrayToEmailSettingsData(fetchedSettings?: ISetting[]): IEmailSettingsData {
    if (!fetchedSettings) {
        return defaultEmailSettingsData;
    }

    const domain = fetchedSettings.find((setting) => setting.key === EmailSettingKey.Domain);
    const enableSsl = fetchedSettings.find((setting) => setting.key === EmailSettingKey.EnableSsl);
    const fromDisplayName = fetchedSettings.find((setting) => setting.key === EmailSettingKey.FromDisplayName);
    const fromEmail = fetchedSettings.find((setting) => setting.key === EmailSettingKey.FromEmail);
    const host = fetchedSettings.find((setting) => setting.key === EmailSettingKey.Host);
    const port = fetchedSettings.find((setting) => setting.key === EmailSettingKey.Port);
    const useDefaultCredentials = fetchedSettings.find(
        (setting) => setting.key === EmailSettingKey.UseDefaultCredentials,
    );
    const userName = fetchedSettings.find((setting) => setting.key === EmailSettingKey.UserName);
    return merge({}, defaultEmailSettingsData, {
        domain: domain?.value,
        enableSsl: enableSsl?.value === 'true',
        fromDisplayName: fromDisplayName?.value,
        fromAddress: fromEmail?.value,
        host: host?.value,
        password: '', // Do not include password since it is taken care of by a placeholder.
        port: port?.value ? parseInt(port.value) : undefined,
        useDefaultCredentials: useDefaultCredentials?.value === 'true',
        userName: userName?.value,
    });
}

export function mapEmailSettingDataToKeyValuePairs(
    data: IEmailSettingsData,
    originalData: DeepPartial<IEmailSettingsData>,
): IKeyValuePair[] {
    const {
        host, password,
    } = data;
    const result: IKeyValuePair[] = [
        {
            key: EmailSettingKey.Domain,
            value: data.domain.toString(),
        },
        {
            key: EmailSettingKey.EnableSsl,
            value: data.enableSsl.toString(),
        },
        {
            key: EmailSettingKey.FromDisplayName,
            value: data.fromDisplayName.toString().trim(),
        },
        {
            key: EmailSettingKey.FromEmail,
            value: data.fromAddress.toString(),
        },
        {
            key: EmailSettingKey.Port,
            value: data.port.toString(),
        },
        {
            key: EmailSettingKey.UseDefaultCredentials,
            value: data.useDefaultCredentials?.toString() ?? false,
        },
        {
            key: EmailSettingKey.UserName,
            value: data.userName.toString().trim(),
        },
        {
            key: EmailSettingKey.Password,
            value: password,
        },
    ];
    if (host && host !== originalData.host) {
    // The host requires other fields to be updated when it is updated; only update when it is different than original.
        result.push({
            key: EmailSettingKey.Host,
            value: host,
        });
    }
    return result;
}

export function mapEmailSettingsDataToVerifySmtpSettingsData(data: IEmailSettingsData): IEmailSettingsData {
    return { ...data };
}
