import {
    ECOMMERCE_DISCOUNT_BANNER_KEY,
    getUniqueDiscountPackagesConfig,
    IPackagesConfig,
    useEcommerceEnabledCountries,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import CloseIcon from '@mui/icons-material/Close';
import LocalOfferOutlined from '@mui/icons-material/LocalOfferOutlined';
import {
    Button,
    IconButton,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import {
    billingUrl,
    getPackagesConfig,
} from '../../../services/licensing/BillingService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
    userGlobalId,
} from '../../../store/selectors';

const useStyles = makeStyles((theme) =>
    createStyles({
        discountBanner: {
            backgroundColor: Tokens.Colors.ColorBlue700,
            minHeight: '48px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        discountBannerText: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: Tokens.Colors.ColorForegroundInverseLight,
        },
        discountIcon: {
            fontSize: '18px',
            marginRight: '4px',
            color: Tokens.Colors.ColorForegroundInverseLight,
        },
        buyNowButton: {
            maxHeight: '32px',
            color: Tokens.Colors.ColorForegroundInverseLight,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            backgroundColor: Tokens.Colors.ColorBlue700,
            marginLeft: '16px',
        },
        discountMessageCenterAlignment: {
            alignSelf: 'center',
            marginLeft: 'auto',
        },
        discountMessageContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        closeIcon: { color: Tokens.Colors.ColorForegroundInverseLight },
        closeIconAlignment: { marginLeft: 'auto' },
    }),
);

const EcommerceDiscountBanner: React.FC<{
    shouldDisplayBuyOption?: boolean;
    closeable?: boolean;
}> = ({
    shouldDisplayBuyOption = false, closeable = false,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const logEcommerceEvent = useEcommerceTelemetry();
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);
    const [ closed, setClosed ] = useState(!!closeable);

    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const {
        data: packagesData,
        isValidating: isPackagesDataLoading,
    } = useSWR<IPackagesConfig, Error>(
        !closed ? [ currentAccountName, accountCountryCode, packagesConfigUrl, closed ] : null,
        () => {
            return getPackagesConfig(currentAccountName, accountCountryCode);
        },
    );

    useEffect(() => {
        (async () => {
            if (closeable) {
                const result = await getSetting(settingUrl, [ ECOMMERCE_DISCOUNT_BANNER_KEY ], partitionGlobalId, userId);
                const getSettingWithKey = !!result?.length && result.find(setting => setting.key === ECOMMERCE_DISCOUNT_BANNER_KEY);

                if (getSettingWithKey) {
                    setClosed(getSettingWithKey.value === 'false');
                } else {
                    setClosed(false);
                }
            }
        })();
    }, [ partitionGlobalId, userId, closeable ]);

    const discountDetails = useMemo(
        () => {
            if (packagesData) {
                return getUniqueDiscountPackagesConfig(packagesData);
            }
        },
        [ packagesData ],
    );

    const existAnyDiscounts = useMemo(() => {
        return !isPackagesDataLoading && discountDetails && EnableEcommercePromotionalDiscounts;
    }, [ isPackagesDataLoading, discountDetails, EnableEcommercePromotionalDiscounts ]);

    const onClose = useCallback(async () => {
        await saveSetting(settingUrl, {
            settings: [
                {
                    key: ECOMMERCE_DISCOUNT_BANNER_KEY,
                    value: 'false',
                },
            ],
            partitionGlobalId,
            userId,
        });
        setClosed(true);
    },
    [ partitionGlobalId, userId ],
    );

    return (
        <>
            {isAdmin && !closed && existAnyDiscounts && (
                <div
                    className={classes.discountBanner}
                    data-cy="ecommerce-discount-banner">
                    <div className={clsx(classes.discountMessageContainer, closeable && classes.discountMessageCenterAlignment)}>
                        <LocalOfferOutlined className={classes.discountIcon} />
                        <Typography
                            className={classes.discountBannerText}
                            data-cy="ecommerce-discount-banner-text">
                            <FormattedMessage
                                id="CLIENT_ECOMMERCE_DISCOUNT_BANNER"
                                values={{
                                    b: (chunk: string) => <b>
                                        {chunk}
                                    </b>,
                                    percentageOff: discountDetails!.percentageOff,
                                    durationInMonths: discountDetails!.durationInMonths,
                                }}
                            />
                        </Typography>
                        {shouldDisplayBuyOption && (
                            <Button
                                variant="outlined"
                                className={classes.buyNowButton}
                                onClick={() => {
                                    logEcommerceEvent('Licenses.UpgradeToEnterprise', { InvocationMethod: 'Discount Banner' });
                                    history.push(getRoute(RouteNames.BuyEnterprise));
                                }}
                                data-cy="ecommerce-discount-banner-buy-button"
                            >
                                {translate({ id: 'CLIENT_BUY_NOW' })}
                            </Button>
                        )}
                    </div>
                    {closeable && (
                        <div className={classes.closeIconAlignment}>
                            <IconButton
                                data-cy="ecommerce-discount-banner-close-icon"
                                aria-label={translate({ id: 'CLIENT_CLOSE' })}
                                className={classes.closeIcon}
                                onClick={() => onClose()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            )}
        </>);
};

export default EcommerceDiscountBanner;
