import { productCodeToTranslationCode } from '../../../../common/constants/Constant';
import { history } from '../../../../History.default';
import { TenantStatusConstants } from '../../TenantConstants';

export const sortedServiceTypes = [ 'orchestrator', 'aifabric', 'dataservice' ];
export const sortedProductCodes = [
    'UNATT',
    'NONPR',
    'TAUNATT',
    'ACR',
    'ATTNU',
    'ATTCU',
    'STDNU',
    'STDCU',
    'STDXNU',
    'STDXCU',
    'AIW',
    'AIG',
    'STDPRONU',
    'STDPROCU',
    'DSU',
];

export const concurrencyMapping: Record<string, string> = {
    ATTNU: 'ATTCU',
    ATTCU: 'ATTNU',
    STDNU: 'STDCU',
    STDCU: 'STDNU',
    STDXNU: 'STDXCU',
    STDXCU: 'STDXNU',
    STDPRONU: 'STDPROCU',
    STDPROCU: 'STDPRONU',
};

export const serviceToProductMapping: Record<string, string[]> = {
    orchestrator: [
        'UNATT',
        'NONPR',
        'TAUNATT',
        'ACR',
        'ATTNU',
        'ATTCU',
        'STDNU',
        'STDCU',
        'STDXNU',
        'STDXCU',
        'STDPRONU',
        'STDPROCU',
        'RU',
    ],
    aifabric: [
        'AIW',
        'AIG',
    ],
    dataservice: [ 'DSU' ],
};

export const getServiceFromProduct = (product: string) => {
    const matchedKV = Object.entries(serviceToProductMapping).find(([ _service, productList ]) => {
        return productList.includes(product);
    });

    return matchedKV?.[0];
};

export function navigateToLicenseView(route: string, tenantName: string) {
    history.push({
        pathname: route,
        state: {
            status: status === TenantStatusConstants.ENABLED,
            name: tenantName,
        },
    });
}

export function navigateToLicenseConfigure(route: string, previousRoute: string) {
    history.push({
        pathname: route,
        state: { previousLocation: previousRoute },
    });
}

export const productSubLabel = (licenseType: string) => {
    if (licenseType.toLowerCase().indexOf('named') > -1) {
        return 'NAMED_USER';
    } else if (licenseType.toLowerCase() === 'attendedrobots') {
        return 'NAMED_USER';
    } else if (licenseType.toLowerCase().includes('concurrent')) {
        return 'CONCURRENT_USER';
    } else if (licenseType.toLowerCase().includes('dataserviceunit')) {
        return '';
    }
    return 'RUNTIME';
};

export const translationCode = (productCode: string) => {
    return productCodeToTranslationCode.get(productCode) ?? productCode;
};
