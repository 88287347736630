import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { EmailSettingKey } from '../../../common/constants/EmailSettingConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import {
    getSetting,
    ISetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import { isValidDomain } from '../../../util/DomainUtil';
import {
    mapEmailSettingDataToKeyValuePairs,
    mapSettingArrayToEmailSettingsData,
} from '../../../util/setting/EmailSettingUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import UiForm from '../../common/UiForm';

const EnableEmptyEmailSettingsUsernamePassword: boolean = process.buildConfigs.enableEmptyEmailSettingsUsernamePassword;

const useStyles = makeStyles(theme =>
    createStyles({
        emailSettingsPage: {
            width: '100%',
            height: 'calc(100% - 40px)',
        },
        accordionContentDivided: {
            padding: '21px 16px 0px 0px',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '52px',
        },
        section: { marginBottom: '14px' },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        leftColumn: {
            flex: 1.5,
            minWidth: '200px',
            width: '450px',
        },
        rightColumn: { flex: 1 },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        outlinedInput: {
            display: 'flex',
            marginTop: '8px',
        },
        outlinedInputWrapper: { marginBottom: '20px' },
        indentedSection: { marginLeft: '8px' },
    }),
);

export const defaultEmailSettingsData: IEmailSettingsData = {
    domain: '',
    enableSsl: false,
    fromDisplayName: '',
    fromAddress: '',
    host: '',
    password: '',
    port: 25,
    useDefaultCredentials: false,
    userName: '',
};

const EmailSettingsComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const partitionGlobalId = useSelector(accountGlobalId);
    const getRoute = useRouteResolver();
    const history = useHistory();
    const location = useLocation<{ verifiedSettings?: IEmailSettingsData } | undefined>();

    const {
        handleSubmit, setValue, reset, formState, control, errors, watch,
    } = useForm<IEmailSettingsData>({
        mode: 'onSubmit',
        defaultValues: defaultEmailSettingsData,
    });
    const {
        isDirty, isSubmitting,
    } = formState;

    const keys = useMemo(
        () => [
            EmailSettingKey.Domain,
            EmailSettingKey.EnableSsl,
            EmailSettingKey.FromDisplayName,
            EmailSettingKey.FromEmail,
            EmailSettingKey.Host,
            EmailSettingKey.Password,
            EmailSettingKey.Port,
            EmailSettingKey.UseDefaultCredentials,
            EmailSettingKey.UserName,
        ],
        [],
    );
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const emailSettingsData = useMemo(() => watch(), [ watch ]);
    const originalData = useMemo(() => mapSettingArrayToEmailSettingsData(fetchedSettings), [ fetchedSettings ]);
    const useDefaultCredentials = useMemo(() => watch('useDefaultCredentials'), [ watch ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const onSubmit = useCallback(
        async (data: IEmailSettingsData) => {
            try {
                if (isEqual(emailSettingsData, location.state?.verifiedSettings)) {
                    const savedData = await saveSetting(settingUrl, {
                        settings: mapEmailSettingDataToKeyValuePairs(data, originalData),
                        partitionGlobalId,
                    });
                    mutate(savedData);
                    createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
                } else {
                    createNotification(translate({ id: 'CLIENT_SETTINGS_NOT_TESTED_ERROR' }), notificationType.ERROR);
                }
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [
            createNotification,
            emailSettingsData,
            location.state?.verifiedSettings,
            mutate,
            originalData,
            partitionGlobalId,
            translate,
        ],
    );

    const handleTestEmailSettings = useCallback(() => {
        history.push({
            pathname: getRoute(RouteNames.EmailSettingsTest),
            state: { emailSettingsData },
        });
    }, [ emailSettingsData, getRoute, history ]);

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    useEffect(() => {
        if (fetchedSettings) {
            reset(originalData);
        }
    }, [ reset, fetchedSettings, originalData ]);

    useEffect(() => {
        setValue('userName', useDefaultCredentials ? '' : originalData.userName);
        setValue('password', useDefaultCredentials ? '' : originalData.password);
    }, [ originalData.password, originalData.userName, setValue, useDefaultCredentials ]);

    return (
        <div
            className={classes.emailSettingsPage}
            data-cy="email-settings-page">
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        {isDirty && (
                            <Button
                                className={classes.cancelButton}
                                onClick={() => handleCancel()}
                                color="primary"
                                data-cy="email-settings-cancel-button"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                        )}
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="email-settings-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
            >
                <div className={classes.accordionContentDivided}>
                    <div className={classes.leftColumn}>
                        <div className={classes.section}>
                            <Typography
                                className={classes.subHeading}
                                role="heading"
                                aria-level={2}>
                                {translate({ id: 'CLIENT_SMTP_EMAIL_SETUP' })}
                            </Typography>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_HOST' })}
                                    InputLabelProps={{ id: 'smtpHostLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpHostLabel' }}
                                    name="host"
                                    control={control}
                                    rules={{ required: true }}
                                    error={!!errors.host}
                                    helperText={
                                        errors.host?.type === 'required' &&
                  translate(
                      { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                      { 0: translate({ id: 'CLIENT_SMTP_HOST' }) },
                  )
                                    }
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    className={classes.outlinedInput}
                                    as={TextField}
                                    data-cy="email-settings-host-input"
                                    required
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_DOMAIN' })}
                                    InputLabelProps={{ id: 'smtpDomainLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpDomainLabel' }}
                                    name="domain"
                                    control={control}
                                    rules={{ validate: { validDomain: value => !value || isValidDomain(value) } }}
                                    error={!!errors.domain}
                                    helperText={
                                        (errors.domain?.type === 'validDomain' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' })) ||
                  (errors.domain?.type === 'required' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' }))
                                    }
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    data-cy="email-settings-domain-input"
                                    className={classes.outlinedInput}
                                    as={TextField}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_PORT' })}
                                    InputLabelProps={{ id: 'smtpPortLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpPortLabel' }}
                                    name="port"
                                    type="number"
                                    control={control}
                                    error={!!errors.port}
                                    rules={{
                                        required: true,
                                        min: 1,
                                        max: 65535,
                                    }}
                                    helperText={
                                        errors.port?.type === 'required' &&
                  translate(
                      { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                      { 0: translate({ id: 'CLIENT_SMTP_PORT' }) },
                  )
                                    }
                                    data-cy="email-settings-port-input"
                                    variant="outlined"
                                    as={TextField}
                                    className={classes.outlinedInput}
                                    required
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_USERNAME' })}
                                    InputLabelProps={{ id: 'smtpUsernameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpUsernameLabel' }}
                                    rules={{ required: EnableEmptyEmailSettingsUsernamePassword ? !!watch('password') : !useDefaultCredentials }}
                                    helperText={errors.password?.type === 'required' && translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' })}
                                    name="userName"
                                    control={control}
                                    disabled={useDefaultCredentials}
                                    error={!!errors.userName}
                                    className={classes.outlinedInput}
                                    as={TextField}
                                    variant="outlined"
                                    data-cy="email-settings-username-input"
                                    required={EnableEmptyEmailSettingsUsernamePassword ? !!watch('password') : !useDefaultCredentials}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_PASSWORD' })}
                                    InputLabelProps={{ id: 'smtpPasswordLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpPasswordLabel' }}
                                    data-cy="email-settings-password-input"
                                    name="password"
                                    control={control}
                                    rules={{ required: EnableEmptyEmailSettingsUsernamePassword ? !!watch('userName') : !useDefaultCredentials }}
                                    disabled={useDefaultCredentials}
                                    error={!!errors.password}
                                    helperText={
                                        errors.password?.type === 'required' && translate({ id: 'CLIENT_PASSWORD_MUST_BE_UPDATED_ERROR' })
                                    }
                                    className={classes.outlinedInput}
                                    as={TextField}
                                    variant="outlined"
                                    type="password"
                                    autoComplete="new-password"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    required={EnableEmptyEmailSettingsUsernamePassword ? !!watch('userName') : !useDefaultCredentials}
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_FROM_ADDRESS' })}
                                    InputLabelProps={{ id: 'smtpFromAddressLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpFromAddressLabel' }}
                                    name="fromAddress"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: { valid: value => !value || validateEmail(value) },
                                    }}
                                    error={!!errors.fromAddress}
                                    variant="outlined"
                                    type="email"
                                    helperText={
                                        (errors.fromAddress?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_SMTP_FROM_ADDRESS' }) },
                    )) ||
                  (errors.fromAddress?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                                    }
                                    autoComplete="off"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    className={classes.outlinedInput}
                                    as={TextField}
                                    data-cy="email-settings-from-email-input"
                                    required
                                />
                            </div>
                            <div className={classes.outlinedInputWrapper}>
                                <Controller
                                    label={translate({ id: 'CLIENT_SMTP_FROM_DISPLAY_NAME' })}
                                    InputLabelProps={{ id: 'smtpFromDisplayNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'smtpFromDisplayNameLabel' }}
                                    name="fromDisplayName"
                                    control={control}
                                    error={!!errors.fromDisplayName}
                                    data-cy="email-settings-from-display-name-input"
                                    className={classes.outlinedInput}
                                    as={TextField}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <Button
                            data-cy="email-settings-test-button"
                            variant="outlined"
                            onClick={handleSubmit(handleTestEmailSettings)}
                            disabled={
                                !useDefaultCredentials && (errors.password?.type === 'required' || errors.userName?.type === 'required')
                            }
                        >
                            {translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS' })}
                        </Button>
                    </div>
                    <div className={classes.rightColumn}>
                        <div className={classes.section}>
                            <Typography className={classes.subHeading}>
                                {translate({ id: 'CLIENT_SMTP_EMAIL_PREFERENCE' })}
                            </Typography>
                            <Controller
                                name="enableSsl"
                                control={control}
                                error={!!errors.enableSsl}
                                render={props => (
                                    <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                            <Switch
                                                className={classes.indentedSection}
                                                checked={props.value}
                                                onChange={e => handleSwitch('enableSsl', e.target.checked, props.onChange)}
                                                color="primary"
                                                data-cy="email-settings-enable-ssl-switch"
                                            />
                                        }
                                        label={<div className={classes.switchLabel}>
                                            {translate({ id: 'CLIENT_SMTP_ENABLE_SSL' })}
                                        </div>}
                                    />
                                )}
                            />
                            {!process.buildConfigs.disableUseDefaultCredentials && (
                                <Controller
                                    name="useDefaultCredentials"
                                    control={control}
                                    error={!!errors.useDefaultCredentials}
                                    render={props => (
                                        <FormControlLabel
                                            className={classes.switchFormControl}
                                            control={
                                                <Switch
                                                    className={classes.indentedSection}
                                                    checked={props.value}
                                                    onChange={e => handleSwitch('useDefaultCredentials', e.target.checked, props.onChange)}
                                                    color="primary"
                                                    data-cy="email-settings-use-default-credentials-switch"
                                                />
                                            }
                                            label={
                                                <div className={classes.switchLabel}>
                                                    {translate({ id: 'CLIENT_SMTP_USE_DEFAULT_CREDENTIALS' })}
                                                </div>
                                            }
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </UiForm>
        </div>
    );
};

export default EmailSettingsComponent;
