import { IUiLinkMap } from '@experiences/interfaces';

export const SupportLinks: IUiLinkMap = new Map([
    [ 'en', 'https://www.uipath.com/support/' ],
    [ 'ja', 'https://www.uipath.com/ja/' ],
    [ 'de', 'https://www.uipath.com/de' ],
    [ 'zh-CN', 'https://www.uipath.com.cn/' ],
    [ 'zh-TW', 'https://www.uipath.com.cn/' ],
    [ 'fr', 'https://www.uipath.com/fr' ],
    [ 'ko', 'https://www.uipath.com/ko' ],
    [ 'es', 'https://www.uipath.com/es' ],
    [ 'pt-BR', 'https://www.uipath.com/pt_BR' ],
]);

export const DocsLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/' ],
    [ 'ja', 'https://docs.uipath.com/lang-ja/' ],
    [ 'de', 'https://docs.uipath.com/lang-de' ],
    [ 'zh-CN', 'https://docs.uipath.com/lang-zh_CN/' ],
    [ 'zh-TW', 'https://docs.uipath.com/lang-zh_CN/' ],
    [ 'fr', 'https://docs.uipath.com/lang-fr' ],
    [ 'es', 'https://docs.uipath.com/lang-es' ],
    [ 'pt-BR', 'https://docs.uipath.com/lang-pt_BR' ],
]);
