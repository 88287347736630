import IExternalIdentity from '../../common/interfaces/externalIdentity';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const externalIdentityProviderUrl = `${process.buildConfigs.identityApiBaseRoute}/ExternalIdentityProvider`;

export function getExternalIdentityProvider(url: string, partitionGlobalId: string) {
    return get<IExternalIdentity[]>(externalIdentityProviderUrl, { urlParams: { partitionGlobalId } });
}

export function createExternalIdentityProvider(payload: IExternalIdentity) {
    return post<IExternalIdentity>(externalIdentityProviderUrl, { body: payload });
}

export function updateExternalIdentityProvider(payload: IExternalIdentity) {
    return put<IExternalIdentity>(`${externalIdentityProviderUrl}/${payload.id}`, { body: payload });
}

export function deleteExternalIdentityProvider(externalIdentityProviderId: number, directoryConnectionId: number) {
    return axiosDelete(externalIdentityProviderUrl, {
        body: {
            externalIdentityProviderId,
            directoryConnectionId,
        },
    });
}
