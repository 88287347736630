import { Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import {
    apolloMaterialUiThemeDark as darkTheme,
    apolloMaterialUiThemeLight as lightTheme,
} from '@uipath/apollo-mui5';
import {
    ApolloThemeType,
    getApolloThemeFromTheme,
    getCachedTheme,
    ThemeType,
} from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import GlobalStyles from './GlobalStyles';
import MuiTab from './MuiTab';
import MuiTable from './TableOverrides/MuiTable';
import MuiTableCell from './TableOverrides/MuiTableCell';
import MuiTableHead from './TableOverrides/MuiTableHead';
import MuiTablePagination from './TableOverrides/MuiTablePagination';
import MuiTableRow from './TableOverrides/MuiTableRow';

const themeProps = { MuiButton: { defaultProps: { disableElevation: true } } };

export const mergedLightTheme = createTheme(lightTheme, {
    components: {
        MuiTab: MuiTab,
        MuiTable: MuiTable,
        MuiTableCell: MuiTableCell(lightTheme),
        MuiTableHead: MuiTableHead,
        MuiTablePagination: MuiTablePagination(lightTheme),
        MuiTableRow: MuiTableRow,
        MuiCssBaseline: GlobalStyles(lightTheme),
        themeProps,
    },
});

export const mergedDarkTheme = createTheme(darkTheme, {
    components: {
        MuiTab: MuiTab,
        MuiTable: MuiTable,
        MuiTableCell: MuiTableCell(darkTheme),
        MuiTableHead: MuiTableHead,
        MuiTablePagination: MuiTablePagination(darkTheme),
        MuiTableRow: MuiTableRow,
        MuiCssBaseline: GlobalStyles(darkTheme),
        themeProps,
    },
});

const UiThemeContext = React.createContext<{
    theme: Theme;
    themeId: ApolloThemeType;
    selectedThemeId: ThemeType;
    updateTheme: (theme: ApolloThemeType) => void;
    updateSelectedTheme: (theme: ThemeType) => void;
}>({
            selectedThemeId: 'autoTheme',
            theme: mergedLightTheme,
            themeId: 'light',
            updateTheme: () => {},
            updateSelectedTheme: () => {},
        });

export const useApolloTheme = () => {
    return React.useContext(UiThemeContext);
};

export const ApolloThemeProvider: React.FC = ({ children }) => {
    const autoThemeRef = useRef(false);
    const [ themeId, setThemeId ] = useState<ApolloThemeType>('light');
    const [ selectedThemeId, setSelectedThemeId ] = useState<ThemeType>('autoTheme');

    const theme = useMemo(() => (themeId === 'dark' ? mergedDarkTheme : mergedLightTheme), [ themeId ]);

    const updateTheme = useCallback((newThemeId: ApolloThemeType) => {
        autoThemeRef.current = false;
        setThemeId(newThemeId);
    }, []);

    const updateSelectedTheme = useCallback((newSelectedThemeId: ThemeType) => {
        setSelectedThemeId(newSelectedThemeId);
    }, []);

    useEffect(() => {
        const cachedSelectedTheme = getCachedTheme();
        setThemeId(getApolloThemeFromTheme(cachedSelectedTheme));
        setSelectedThemeId(cachedSelectedTheme);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <UiThemeContext.Provider value={{
                theme,
                themeId,
                selectedThemeId,
                updateTheme,
                updateSelectedTheme,
            }}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </UiThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export default GlobalStyles;

export * from './ThemeConstants';
