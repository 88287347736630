import {
    Button,
    ButtonTypeMap,
    CircularProgress,
    ExtendButtonBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        display: 'inline',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    deleteButtonContained: {
        color: `${theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon)}`,
        backgroundColor: `${theme.palette.semantic.colorErrorIcon} !important`,
        borderColor: `${theme.palette.semantic.colorErrorIcon} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.semantic.colorErrorText} !important`,
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
    },
    deleteButtonOutlined: {
        border: '1px solid',
        color: `${theme.palette.semantic.colorErrorText} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
        },
        borderColor: `${theme.palette.semantic.colorErrorText} !important`,
    },
}));

export const UiDeleteButton: ExtendButtonBase<ButtonTypeMap<
{ loading?: boolean; variant?: string; component?: any; target?: any; innerButtonClass?: string }
>> = (props: any) => {
    const classes = useStyles();
    const {
        loading, variant, disabled, className, innerButtonClass, ...rest
    } = props;

    return (
        <div className={clsx(classes.wrapper, className ?? '')}>
            <Button
                {...rest}
                aria-label="ui-delete-button"
                className={clsx({
                    [classes.deleteButtonOutlined]: variant === 'outlined',
                    [classes.deleteButtonContained]: variant === 'contained',
                    [innerButtonClass]: true,
                })}
                disabled={loading || disabled} />
            {props.loading && <CircularProgress
                size={24}
                className={classes.buttonProgress}
                aria-label="ui-progress-button-loading"
                data-cy="ui-progress-button-loading" />}
        </div>
    );
};
