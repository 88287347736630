import { portalTelemetry } from '@experiences/telemetry';
import { getEnvironment } from '@experiences/util';
import { SeverityLevel } from 'applicationinsights/out/Declarations/Contracts';
import { ILogger } from 'oidc-client-ts';

export default class AuthLogger implements ILogger {
    private static instance: AuthLogger;

    private env: string;

    private constructor() {
        this.env = getEnvironment();
    }

    static getInstance() {
        if (!AuthLogger.instance) {
            AuthLogger.instance = new AuthLogger();
        }

        return AuthLogger.instance;
    }

    error(message?: any, ...optionalParams: any[]): void {
        this.send(message, SeverityLevel.Error, ...optionalParams);
    }
    info(message?: any, ...optionalParams: any[]): void {
        this.send(message, SeverityLevel.Information, ...optionalParams);
    }
    debug(message?: any, ...optionalParams: any[]): void {
        this.send(message, SeverityLevel.Information, ...optionalParams);
    }
    warn(message?: any, ...optionalParams: any[]): void {
        this.send(message, SeverityLevel.Warning, ...optionalParams);
    }

    private send = (message: any, severityLevel: SeverityLevel, ...optionalParams: any[]) => {
        if (this.env === 'local' || localStorage.getItem('EnableAuthLogDebugger') === 'true') {
            if (severityLevel === SeverityLevel.Error) {
                console.error('[Portal Auth Logger]', message, ...optionalParams);
            } else if (severityLevel === SeverityLevel.Warning) {
                console.warn('[Portal Auth Logger]', message, ...optionalParams);
            } else {
                console.log('[Portal Auth Logger]', message, ...optionalParams);
            }
        } else {
            portalTelemetry.trackTrace({
                message,
                severityLevel,
            }, { ...optionalParams });
        }
    };
}
