import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useSelector } from 'react-redux';

import { adminRevampSelector } from '../../store/selectors';

export const useIsAdminRevampEnabled = () => {
    const EnableAdminRevampToggle = useFeatureFlagValue(Features.EnableAdminRevampToggle.name);

    const adminRevamp = useSelector(adminRevampSelector);

    const isAdminRevampEnabled = !!EnableAdminRevampToggle && adminRevamp;

    return isAdminRevampEnabled;
};

