import {
    IOrganizationMigrationStatus,
    IOrganizationStartMoveRequest,
    IOrganizationStartMoveResponse,
} from '../../common/interfaces/gws';
import {
    get,
    post,
} from '../utility/Requests.default';

export const orgMigrationUrl = '/api/move/organizations';

export function getOrgMigrationStatuses(): Promise<IOrganizationMigrationStatus[]> {
    return get<IOrganizationMigrationStatus[]>(`${orgMigrationUrl}/getOrgMigrationStatuses`);
}

export function getOrgMigrationStatus(migrationInstanceId: string): Promise<IOrganizationMigrationStatus> {
    const urlParams: any = {};
    urlParams['migrationInstanceId'] = migrationInstanceId;

    return get<IOrganizationMigrationStatus>(`${orgMigrationUrl}/getOrgMigrationStatus`, { urlParams });
}

export function startOrganizationMove(startMoveRequest: IOrganizationStartMoveRequest): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${orgMigrationUrl}/startOrgMove`, { body: startMoveRequest });
}

export function startOrganizationMoveDryRun(startMoveRequest: IOrganizationStartMoveRequest): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${orgMigrationUrl}/startOrgMoveDryRun`, { body: startMoveRequest });
}
