import { useMemo } from 'react';

import { useCheckAuthenticationSetting } from '../../../src/common/hooks/useCheckAuthenticationSetting';
import { AuthenticationSettingType } from '../../services/identity/AuthenticationSettingService';

export const useLoginProvider = () => {
    const authenticationSetting = useCheckAuthenticationSetting();
    const loginProvider = useMemo(() => {
        if (authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.NoSetting) {
            return undefined;
        }

        return authenticationSetting?.authenticationSettingType?.toLowerCase();
    }, [ authenticationSetting ]);

    return loginProvider;
};
