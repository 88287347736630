import { AccountLicense } from '@experiences/constants';
import {
    ICreateEditTenantPayload,
    ILrmProduct,
} from '@experiences/interfaces';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getServiceLicenses } from '../../../../services/licensing/LicenseManagementService';
import {
    accountGlobalId,
    accountType,
    concurrentLicensesOpted,
} from '../../../../store/selectors';
import UiLicenseAllocationPerServiceComponent from '../../../common/UiLicenseAllocationComponent/UiLicenseAllocationComponent';
import { ITenantServiceLicense } from '../../interfaces/service';
import NeedMoreLicensesComponent from '../forms/NeedMoreLicensesComponent';
import { getProductsFromServices } from '../helpers/getProductsForServices';
import { getServiceFromProduct } from '../helpers/ManageLicensesHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        groups: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginBottom: '6px',
        },
        serviceLicense: {
            maxWidth: '900px',
            width: '100%',
        },
    }),
);

const TenantCreateAllocateLicenseComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const partitionGlobalId = useSelector(accountGlobalId);
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);
    const currentAccountType = useSelector(accountType);

    const {
        watch, setValue,
    } = useFormContext<ICreateEditTenantPayload>();

    const services = useMemo(() => watch('services'), [ watch ]);

    const { data: licenseData } = useSWR<ILrmProduct[], Error>(
        services ? [
            `/api/manageLicense/${partitionGlobalId}/service-licenses`,
            partitionGlobalId,
        ] : null,
        getServiceLicenses,
    );

    const filteredByServiceLicenseData = useMemo(() => {
        const productsToService: ITenantServiceLicense[] = [];
        licenseData
            ?.forEach(product => {
                const service = getServiceFromProduct(product.code);
                if (service && services.includes(service)
                    && (!process.buildConfigs.unlicensedServices?.includes(service) ?? true)) {
                    const productExistsInArray = productsToService.find(entry => entry.serviceType === service);
                    if (productExistsInArray) {
                        productExistsInArray.products.push({
                            code: product.code,
                            available: product.total - product.allocated,
                            allocated: product.allocated,
                            used: 0,
                            quantity: product.total,
                            reserved: 0,
                            type: '',
                        });
                    } else {
                        productsToService.push({
                            serviceType: service,
                            products: [
                                {
                                    code: product.code,
                                    available: product.total - product.allocated,
                                    allocated: product.allocated,
                                    used: 0,
                                    quantity: product.total,
                                    reserved: 0,
                                    type: '',
                                },
                            ],
                        });
                    }
                }
            });
        return productsToService;
    }, [ services, licenseData ]);

    const getAvailableProductsFromServices = useMemo(() => getProductsFromServices(filteredByServiceLicenseData, concurrentLicenseEnabled),
        [ filteredByServiceLicenseData, concurrentLicenseEnabled ]);

    useEffect(() => {
        setValue('customProperties.allocatedProductLicenses', getAvailableProductsFromServices);
    }, [ getAvailableProductsFromServices, setValue ]);

    const {
        tenantHasServiceLicenses, tenantHasLicenseProductUnavailable,
    } = useMemo(
        () => ({
            tenantHasServiceLicenses: !!filteredByServiceLicenseData?.some(serviceLicense => serviceLicense.products.length > 0),
            tenantHasLicenseProductUnavailable: !!filteredByServiceLicenseData?.some(serviceLicense =>
                serviceLicense.products.some(product => product.available === 0),
            ),
        }),
        [ filteredByServiceLicenseData ],
    );

    return <>
        {!licenseData && <CircularProgress style={{ margin: 'auto' }} />}
        {licenseData
            && <>
                {tenantHasServiceLicenses ? (
                    filteredByServiceLicenseData
                        ?.filter(serviceLicense => serviceLicense.products.length > 0)
                        .map((serviceLicense, i) => (
                            <div
                                key={i}
                                className={classes.serviceLicense}>
                                <UiLicenseAllocationPerServiceComponent
                                    type="add"
                                    name="customProperties.allocatedProductLicenses"
                                    serviceLicense={serviceLicense} />
                            </div>
                        ))
                ) : (
                    <div className={clsx(classes.groups, classes.groupColumn)}>
                        <Typography
                            className={classes.noLicensesAvailable}
                            data-cy="case-no-license-available">
                            {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                        </Typography>
                    </div>
                )}
                <div style={{ marginBottom: '36px' }} />
                {tenantHasLicenseProductUnavailable && AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
                <NeedMoreLicensesComponent />}
            </>}
    </>;
};

export default TenantCreateAllocateLicenseComponent;
