import { TranslatedRegionName } from '@experiences/constants';
import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Chip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { TenantRegionDocsLinks } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useTelemetry } from '../../../common/hooks/useTelemetry';
import { IOrganizationMigrationStatus } from '../../../common/interfaces/gws';
import { getOrgMigrationStatuses } from '../../../services/global-worklow-service/OrgMigration';
import {
    accountLogicalName,
    organizationRegion,
} from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        moveOrganizationBox: {
            marginTop: '24px',
            marginBottom: '20px',
            margin: '0 2px',
        },
        moveOrganizationTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        moveOrganizationTitleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        moveOrganizationDetails: {
            fontSize: '14px',
            fontWeight: 600,
            marginLeft: '12px',
        },
        requestMove: {
            display: 'flex',
            flexDirection: 'row',
            width: 'inherit',
            alignItems: 'center',
            outline: 'solid 1px',
            marginTop: '16px',
            borderRadius: '2px',
            outlineColor: theme.palette.semantic.colorBorderDeEmp,
            padding: '5px',
            position: 'relative',
            height: '44px',
        },
        moveButton: {
            position: 'absolute',
            right: '4px',
            display: 'block',
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
    }),
}),
);

export const OrganizationMigration: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });
    const logCustomEvent = useTelemetry();

    const { formatMessage: translate } = useIntl();

    const currentAccountName = useSelector(accountLogicalName);
    const currentRegion = useSelector(organizationRegion);

    const [ isMigrationRunning, setIsMigrationRunning ] = useState< boolean | undefined >();

    const { data: orgMigrationStatuses } = useSWR<IOrganizationMigrationStatus[], Error>(
        [ currentAccountName ],
        getOrgMigrationStatuses,
    );
    const navigateToMigration = useCallback(async () => {
        logCustomEvent('OrgSelfServeMigration.RequestMove', { ServerRegion: currentRegion });
        history.push(getRoute(RouteNames.OrganizationMigration));
    }, [ currentRegion, getRoute, history, logCustomEvent ]);

    useEffect(() => {
        const IsOrganizationLocked = orgMigrationStatuses?.some(orgMigrationStatus => !orgMigrationStatus.isFinished);
        setIsMigrationRunning(IsOrganizationLocked);
    }, [ orgMigrationStatuses ]);

    const dataRegionLink = useCallback((str: string) =>
        <a
            href={getLocalizedLink(TenantRegionDocsLinks)}
            target='_blank'
            rel="noreferrer">
            {str}
        </a>
    , [ getLocalizedLink ]);

    return (
        <div
            className={classes.moveOrganizationBox}
            data-cy="organization-migration-section">
            <div className={classes.moveOrganizationTitleSection}>
                <Typography
                    className={classes.moveOrganizationTitle}
                    data-cy="organization-migration-settings"
                >
                    {translate({ id: 'CLIENT_SETTINGS_MIGRATION' })}
                </Typography>
                <Chip
                    label={translate({ id: 'CLIENT_PREVIEW' })}
                    size="small"
                    className="mini"
                    style={{ marginLeft: '4px' }} />
            </div>
            <Typography>
                <FormattedMessage
                    data-cy="delete-organization-title"
                    id="CLIENT_MIGRATION_GEO_DATA"
                    values={{ a: (str: string) => dataRegionLink(str) }}
                />
            </Typography>
            <div className={classes.requestMove}>
                <Typography
                    className={classes.moveOrganizationDetails}
                    data-cy="organization-migration-title">
                    {translate({ id: TranslatedRegionName[currentRegion] ?? 'CLIENT_NONE' })}
                </Typography>
                <Button
                    disabled={isMigrationRunning}
                    className={classes.moveButton}
                    size="small"
                    onClick={navigateToMigration}
                    data-cy="organization-migration-button"
                    variant="text"
                >
                    {isMigrationRunning
                        ? translate({ id: 'CLIENT_JOBSTATUS_INPROGRESS' })
                        : translate({ id: 'CLIENT_REQUEST_MOVE' })}
                </Button>
            </div>
        </div>
    );
};

export default OrganizationMigration;
