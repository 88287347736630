import { TextField } from '@mui/material';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ISubscriptionForm } from '../interfaces/ecommerce';

const EcommerceCity: React.FC<{
    disabled: boolean;
    leftMargin?: boolean;
}> = ({
    disabled, leftMargin,
}) => {
    const { formatMessage: translate } = useIntl();
    const {
        control, errors,
    } =
    useFormContext<ISubscriptionForm>();

    return (
        <Controller
            as={TextField}
            control={control}
            style={{ marginLeft: leftMargin ? '12px' : '0px' }}
            variant="outlined"
            rules={{ required: true }}
            id="city"
            label={translate({ id: 'CLIENT_CITY' })}
            name="city"
            error={!!errors.city}
            helperText={
                (errors.city?.type === 'required' &&
          translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
        (errors.city?.type === 'addressError' &&
          translate({ id: 'CLIENT_CHECK_CITY' }))
            }
            InputProps={{ className: 'Tall' }}
            fullWidth
            disabled={disabled}
            data-cy="ecommerce-form-city"
        />
    );
};

export default EcommerceCity;
