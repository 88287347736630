import {
    BillingEntityCode,
    EcommerceDiscountedPrice,
    EcommercePrice,
    formatDate,
    INCREMENTAL_FLOW,
    IPlanDetails,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import GlobalStyles from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    Button,
    CircularProgress,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import {
    fetchPlanDetails,
    goToStripeCustomerPortalSession,
} from '../../../services/licensing/BillingService';
import { accountLogicalName } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        infoSection: { display: 'flex' },
        supportSection: {
            display: 'flex',
            marginTop: '14px',
        },
        alignRight: { marginLeft: 'auto' },
        column: { marginLeft: '60px' },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        value: {
            marginTop: '4px',
            letterSpacing: '0em',
            textAlign: 'left',
        },
        fontSizeM: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
        },
        colorForeground: {
            color: theme.palette.semantic.colorForeground,
            marginTop: '4px',
        },
        fontSizeMBold: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
        },
        contactSupport: { marginLeft: '4px' },
        box: {
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
            borderRadius: '3px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '17px',
            paddingRight: '17px',
            marginBottom: '19px',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
        },
        infoTooltipText: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundInverse,
        },
        discountInfoIcon: {
            fontSize: '16px',
            marginLeft: '4px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
        },
    }),
}));

const EcommerceLicenseInfo: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);
    const language = useLocalization();
    const logEcommerceEvent = useEcommerceTelemetry();
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);
    const {
        data: planDetails, isValidating: loading,
    } = useSWR<IPlanDetails, Error>(
        [ currentAccountName ],
        fetchPlanDetails,
    );

    const [ endDate, nextPayment, upcomingBillValue, currency, subscriptionId, billingEntityCode, discount ] = useMemo(
        () => [
            planDetails?.endDate,
            planDetails?.nextPayment,
            planDetails?.upcomingBill,
            planDetails?.currency,
            planDetails?.subscriptionId,
            planDetails?.billingEntityCode,
            planDetails?.discount,
        ],
        [ planDetails ],
    );

    const shouldDisplayDiscount = useMemo(() => {
        return !!(EnableEcommercePromotionalDiscounts && discount && discount.discountedPrice > 0);
    }, [ EnableEcommercePromotionalDiscounts, discount ]);

    const shouldDisplayDiscountInfoTooltipIcon = useMemo(() => {
        return !!(EnableEcommercePromotionalDiscounts && discount?.end);
    }, [ EnableEcommercePromotionalDiscounts, discount ]);

    const shouldDisplayUpdateLicenseButton = useMemo(() => {
        return !(EnableEcommercePromotionalDiscounts && discount?.end);
    }, [ EnableEcommercePromotionalDiscounts, discount ]);

    const getUpcomingBillValue = useCallback((upcomingBillCurrency) => {
        return shouldDisplayDiscount ? (
            <EcommerceDiscountedPrice
                fullPrice={upcomingBillValue}
                discountedPrice={discount?.discountedPrice}
                discountDetails={discount}
                loading={false}
                currency={upcomingBillCurrency}
                shouldDisplaySavesPercentageOffText={false}
                dataCy="ecommerce-upcoming-bill-full-price"
            />
        ) : (
            <EcommercePrice
                value={upcomingBillValue}
                loading={false}
                gap={true}
                currency={upcomingBillCurrency}
                dataCy="ecommerce-upcoming-bill-price"
            />
        );
    }, [ discount, upcomingBillValue, shouldDisplayDiscount ]);

    const getUpcomingBillSection = useCallback(() => {
        return upcomingBillValue ? (
            <div className={clsx(classes.row, classes.colorForeground)}>
                {currency && getUpcomingBillValue(currency)}
                <Typography className={clsx(classes.fontSizeM)}>
                    {translate({ id: 'CLIENT_TAX' })}
                </Typography>
                {shouldDisplayDiscountInfoTooltipIcon && (
                    <Tooltip
                        classes={{ tooltip: classes.tooltipBox }}
                        title={
                            <Typography
                                data-cy="discount-info-tooltip"
                                className={classes.infoTooltipText}>
                                <FormattedMessage
                                    id="CLIENT_ECOMMERCE_DISCOUNT_TOOLTIP_TEXT"
                                    values={{
                                        percentageOff: discount?.percentageOff,
                                        discountEndDate: moment(discount?.end).format('DD MMMM, YYYY'),
                                    }}
                                />
                            </Typography>
                        }
                    >
                        <InfoOutlined
                            className={classes.discountInfoIcon}
                            data-cy="discount-info-icon" />
                    </Tooltip>
                )}
            </div>
        ) : (
            <Typography className={clsx(classes.fontSizeM, classes.colorForeground)}>
                {translate({ id: 'CLIENT_NONE' })}
            </Typography>
        );
    }, [ classes, currency, discount, shouldDisplayDiscountInfoTooltipIcon, getUpcomingBillValue, translate, upcomingBillValue ]);

    return loading ? (
        <div className={classes.box}>
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        </div>
    ) : (planDetails ? (
        <div className={classes.box}>
            <div className={classes.infoSection}>
                <div>
                    <Typography
                        className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_PLAN_TYPE' })}
                    </Typography>
                    <Typography
                        className={clsx(classes.fontSizeM, classes.colorForeground)}
                        data-cy="ecommerce-plan-type">
                        {translate({ id: 'CLIENT_MONTHLY_PAYMENT' })}
                    </Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_NEXT_PAYMENT_DATE' })}
                    </Typography>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-next-payment-date">
                        {nextPayment ? (
                            <Typography className={clsx(classes.fontSizeM, classes.colorForeground)}>
                                {formatDate(endDate, language)}
                            </Typography>
                        ) : (
                            <Typography
                                className={clsx(classes.fontSizeM, classes.colorForeground)}
                                data-cy="plan-end-message">
                                {translate(
                                    { id: 'CLIENT_PLAN_END_MESSAGE' },
                                    { 0: formatDate(endDate, language) },
                                )}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_HEADER_UPCOMING_BILL' })}
                    </Typography>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-upcoming-bill">
                        {getUpcomingBillSection()}
                    </div>
                </div>
                {shouldDisplayUpdateLicenseButton && (
                    <div className={classes.alignRight}>
                        {billingEntityCode && billingEntityCode !== BillingEntityCode.JP && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    logEcommerceEvent('Licenses.UpdateLicenseQuantity', { Flow: INCREMENTAL_FLOW });
                                    history.push(getRoute(RouteNames.UpdateLicenseQuantity), { subscriptionId });
                                }}
                                id="updateLicenseQuantity"
                                data-cy="ecommerce-license-update-license-quantity"
                                disabled={!upcomingBillValue}
                            >
                                {translate({ id: 'CLIENT_UPDATE_LICENSE_QUANTITY' })}
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <div className={classes.supportSection} >
                <Typography className={classes.fontSizeM}>
                    {translate({ id: 'CLIENT_HAVE_ANY_QUESTIONS' })}
                </Typography>
                <Link
                    className={clsx(classes.contactSupport, classes.fontSizeMBold)}
                    href="mailto:cloud.billing@uipath.com"
                    data-cy="ecommerce-questions"
                    onClick={() => {
                        logEcommerceEvent('ManagePlan.ContactSupport');
                    }}
                >
                    {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                </Link>
                <Link
                    className={clsx(classes.alignRight, classes.fontSizeMBold)}
                    onClick={() => {
                        goToStripeCustomerPortalSession(currentAccountName);
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-cy="ecommerce-update-payment-info"
                >
                    {translate({ id: 'CLIENT_UPDATE_PAYMENT_INFO_VIEW_BILLING_HISTORY_CANCEL_PLAN' })}
                </Link>
            </div>
        </div>) : (<div />)
    );
};

export default EcommerceLicenseInfo;
