import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useSelector } from 'react-redux';

import { adminRevampSelector } from '../../store/selectors';

export const useIsSecuritySettingsRevampEnabled = () => {
    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);

    const adminRevamp = useSelector(adminRevampSelector);

    const isSecuritySettingsRevampEnabled = !!EnableSecuritySettingsRevamp && adminRevamp;

    return isSecuritySettingsRevampEnabled;
};

