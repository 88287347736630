import { duration } from '@mui/material';
import {
    useCallback,
    useState,
} from 'react';
import { useHistory } from 'react-router';

import { useRouteResolver } from './useRouteResolver';

// default material "closing" animation speed
export const ANIMATION_DELAY_MS = duration.leavingScreen;

export function useModalState(parentRoute: string) {
    const history = useHistory();
    const getRoute = useRouteResolver();

    // default state is open because each modal is on a unique route
    const [ open, setOpen ] = useState(true);

    const close = useCallback(
        (refresh?: boolean, otherState?: any) => {
            setOpen(false);

            const state = {
                ...(refresh && { refresh: true }),
                ...otherState,
            };

            setTimeout(() => {
                history.push({
                    pathname: getRoute(parentRoute),
                    state,
                });
            }, ANIMATION_DELAY_MS);
        },
        [ getRoute, history, parentRoute ],
    );

    return {
        open,
        close,
    };
}
