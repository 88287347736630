import { TextField } from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ISubscriptionForm } from '../interfaces/ecommerce';

const useStyles = makeStyles(() =>
    createStyles({ spacing: { marginBottom: '16px' } }),
);

const EcommerceAddressLine2: React.FC<{
    disabled: boolean;
}> = ({ disabled }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { control } =
    useFormContext<ISubscriptionForm>();

    return (
        <Controller
            as={TextField}
            className={classes.spacing}
            control={control}
            variant="outlined"
            rules={{ required: false }}
            id="addressLine2"
            label={translate({ id: 'CLIENT_ADDRESS_LINE_2' })}
            name="addressLine2"
            InputProps={{ className: 'Tall' }}
            fullWidth
            disabled={disabled}
            data-cy="ecommerce-form-address-line-2"
        />
    );
};

export default EcommerceAddressLine2;
