import {
    AccountLicense,
    Region,
} from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    ICreateEditTenantPayload,
    ILrmProduct,
    IProductRequested,
} from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Fade,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    FC,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
    useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useTenantOperations } from '../../common/hooks/useTenantOperations';
import { getServiceLicenses } from '../../services/licensing/LicenseManagementService';
import { getAvailableServices } from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../store/selectors';
import validateName from '../../util/validators/NameValidator';
import UiForm from '../common/UiForm';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import TenantGeneralForm from '../tenantsv2/TenantGeneralForm';
import { IServiceMetadata } from './interfaces/service';
import { addHiddenDependenciesHelper } from './subcomponents/helpers/AddHiddenDependenciesHelper';
import { getServiceFromProduct } from './subcomponents/helpers/ManageLicensesHelper';
import TenantCreateAllocateLicensesComponent from './subcomponents/TenantCreateSubcomponents/TenantCreateAllocateLicensesComponent';
import TenantCreateServicesComponent from './subcomponents/TenantCreateSubcomponents/TenantCreateServices';

const useStyles = makeStyles(theme =>
    createStyles({
        standardLayout: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '40px',
            width: '100%',
        },
        componentLayout: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            marginLeft: '8px',
            minWidth: '86px',
        },
        button: {
            marginLeft: '8px',
            minWidth: '172px',
        },
        stepper: {
            width: '100%',
            paddingTop: '48px',
        },
        noBannerHeader: {
            alignSelf: 'flex-start',
            fontSize: '20px',
            fontWeight: 600,
            marginTop: '20px',
        },
    }),
);

export interface ICreateEditTenantForm extends Omit<ICreateEditTenantPayload, 'customProperties'> {
    customProperties?: {
        allocatedProductLicenses: { [product: string]: string | number | undefined };
        isCanaryTenant: boolean;
    };
}

const flow = {
    TENANT_CREATE_GENERAL_INFO: TenantGeneralForm,
    TENANT_CREATE_ADD_SERVICE: TenantCreateServicesComponent,
    TENANT_CREATE_LICENSE_ALLOCATION: TenantCreateAllocateLicensesComponent,
};

const TenantCreateComponent: FC = () => {
    const [ activeStep, setActiveStep ] = useState(0);

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const tenantOperations = useTenantOperations();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountPlan = useSelector(accountType);

    const EnableTenantCreateLicenseAllocation = useFeatureFlagValue(Features.EnableTenantCreateLicenseAllocation.name);

    const { data: licenseData } = useSWR<ILrmProduct[], Error>(
        EnableTenantCreateLicenseAllocation ? [
            `/api/manageLicense/${partitionGlobalId}/service-licenses`,
            partitionGlobalId,
        ] : null,
        getServiceLicenses,
    );

    const [ steps, _updateSteps ] = useState(() => {
        const initialSteps = [ 'CLIENT_GENERAL', 'CLIENT_ADD_SERVICES' ];
        if (EnableTenantCreateLicenseAllocation && licenseData?.length) {
            initialSteps.push('CLIENT_LICENSE_ALLOCATION');
        }
        return initialSteps;
    });

    const handleStepper = useCallback((isForward?: boolean) => {
        setActiveStep(prevStep => (isForward ? prevStep + 1 : prevStep - 1));
    }, []);

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ '/api/tenant/availableservices', partitionGlobalId, accountName ],
        getAvailableServices,
    );

    const methods = useForm<ICreateEditTenantForm>({
        mode: 'onChange',
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: {
            name: '',
            color: '',
            region: '' as Region,
            services: [],
            customProperties: {
                allocatedProductLicenses: {},
                isCanaryTenant: false,
            },
        },
    });

    const {
        control, handleSubmit, setError, getValues, formState: {
            errors, isDirty, isSubmitting,
        },
    } = methods ;

    const region: string | undefined = useWatch({
        name: 'region',
        control,
    });

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ],
    );

    const onSubmit = useCallback(async (data: ICreateEditTenantForm) => {
        data.name = data.name.trim();

        if (!Object.values(data.services).some(service => !!service)) {
            setError('services', { type: 'required' });
            return;
        }

        if (!validateName(data.name)) {
            setError('name', { type: 'invalid' });
            return;
        }

        try {
            data.services = addHiddenDependenciesHelper(data.services, availableServices);
            const {
                customProperties, ...payload
            } = data;
            const createPayload: ICreateEditTenantPayload = {
                ...payload,
                CustomProperties: {
                    isCanaryTenant: customProperties?.isCanaryTenant ?? false,
                    allocatedProductLicenses: {},
                },
            };
            if (customProperties?.allocatedProductLicenses) {
                const serviceProductMap: { [service: string]: IProductRequested[] } = {};
                Object.entries(customProperties?.allocatedProductLicenses).forEach(([ product, quantity ]) => {
                    const service = getServiceFromProduct(product);
                    if (!service) {
                        return;
                    }
                    if (!serviceProductMap[service]) {
                        serviceProductMap[service] = [];
                    }

                    serviceProductMap[service].push({
                        code: product,
                        quantity: typeof quantity === 'string' ? parseInt(quantity) : (quantity ?? 0),
                    });
                });
                createPayload.CustomProperties!.allocatedProductLicenses = serviceProductMap;
            }
            await tenantOperations.tenantCreate(createPayload);

            history.push(getRoute(RouteNames.OrganizationAdminHome));
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ availableServices, getErrorMessage, getRoute, history, setError, setErrorMessage, tenantOperations ]);

    const onCheckAvail = useCallback(async () => {
        if (!activeStep) {
            handleStepper(true);
            return ;
        }

        handleStepper(true);
    }, [ activeStep, handleStepper ],
    );

    const checkNextStep = useCallback(() => {
        switch (activeStep) {
            case 0: return !!errors.name || !getValues('name') || (isRegionEnabled && !region);
            case 1: return !!errors.services || getValues('services')?.length === 0;
            default: return false;
        }
    }, [ activeStep, errors.name, errors.services, getValues, isRegionEnabled, region ],
    );

    const checkShowCreateButton = useMemo(() => {
        switch (activeStep) {
            case 0: return false;
            case 1: return EnableTenantCreateLicenseAllocation && licenseData?.length ? false : true;
            case 2: return true;
        }
    }, [ EnableTenantCreateLicenseAllocation, activeStep, licenseData ]);

    return (
        <UiPageContainer maxWidth="1100px">
            <div className={classes.noBannerHeader}>
                {translate({ id: 'CLIENT_TENANT_CREATE' })}
            </div>
            <div className={classes.stepper}>
                <Stepper
                    activeStep={activeStep}
                    orientation="horizontal"
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step
                                key={index}
                                {...stepProps}>
                                <StepLabel {...labelProps}>
                                    {translate({ id: label })}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={<div className={classes.footer}>
                    <Button
                        onClick={history.goBack}
                        className={classes.footerButton}
                        data-cy="tenant-create-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    {activeStep > 0 && (
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => handleStepper(false)}
                            data-cy="tenant-create-back-button">
                            {translate({ id: 'CLIENT_PREVIOUS' })}
                        </Button>
                    )}
                    {!checkShowCreateButton && <Button
                        variant="contained"
                        className={classes.button}
                        onClick={onCheckAvail}
                        disabled={checkNextStep()}
                        data-cy="tenant-create-next-button">
                        {translate({ id: 'CLIENT_NEXT' })}
                    </Button>}
                    {checkShowCreateButton && <Button
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        disabled={!isDirty || isSubmitting}
                        data-cy="tenant-create-submit-button">
                        {translate({ id: 'CLIENT_CREATE' })}
                    </Button>}
                </div>}>
                <div className={classes.standardLayout}>
                    <FormProvider {...methods}>
                        {Object.values(flow)
                            .filter((_, index) => index === activeStep)
                            .map(Component =>
                                activeStep === 0 ? (
                                    <div className={classes.componentLayout}>
                                        <Component
                                            width='100%'
                                            type='add' />
                                    </div>
                                ) : (
                                    <Fade
                                        in={true}
                                        timeout={300}
                                        key={activeStep}>
                                        <div className={classes.componentLayout}>
                                            <Component />
                                        </div>
                                    </Fade>
                                ),
                            )}
                    </FormProvider>
                </div>
            </UiForm>
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantCreateComponent />
        {renderRoutes(route?.routes)}
    </>
);
