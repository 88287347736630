import { stateInfo } from '@experiences/constants';
import { validateEmailBlacklist } from '@experiences/ecommerce';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useLocalization,
    useTranslatedCountries,
} from '@experiences/locales';
import { portalTelemetry } from '@experiences/telemetry';
import GlobalStyles from '@experiences/theme';
import {
    UiDialog,
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import {
    getDnBObject,
    useRouteResolver,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
    Button,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import clsx from 'clsx';
import * as emailValidator from 'email-validator';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router';

import { ReactComponent as UpgradeOrangeIcon } from '../../../src/images/upgradeOrange.svg';
import { UserRole } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useTriggerPortalShellRefresh } from '../../common/hooks/useTriggerPortalShellRefresh';
import { upgradeToTrial } from '../../services/licensing/LicenseService';
import { setUserProfile } from '../../store/action/UserProfileAction';
import {
    accountCreatedOn,
    accountGlobalId,
    accountLogicalName,
    profile,
    userGlobalId,
} from '../../store/selectors';
import { EnterpriseBenefitStringIds } from '../tenants/subcomponents/constant/EnterpriseTrailConstant';
import { useTenantOperationTrackerContext } from '../tenants/TenantOperationTrackerContextProvider';

const EnableDnBForms = getFeatureFlagValue(Features.EnableDnBForms.name);
const EnableShowEnterpriseBenefitsPanel = getFeatureFlagValue(Features.EnableShowEnterpriseBenefitsPanel.name);

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        groupFields: {
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        groupInputField: { flex: 1 },
        subText: {
            fontSize: '13px',
            lineHeight: 1.25,
            color: theme.palette.semantic.colorForeground,
        },
        footerText: { marginTop: '16px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '24px',
            paddingBottom: '12px',
        },
        cancelButton: { marginRight: '10px' },
        successText: {
            width: 410,
            marginBottom: '20px',
        },
        successIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            height: '32px',
            width: '32px',
        },
        iconAdjustments: {
            width: '32px',
            height: '32px',
        },
        trialNudgeBox: {
            borderRadius: '3px',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            marginTop: '10px',
            marginBottom: '20px',
            padding: '14px',
        },
        trialNudgeBoxTitle: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            marginBottom: '10px',
        },
        sixtyDaysFreeTrial: { color: theme.palette.semantic.colorForegroundHigh },
        checkIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            marginRight: '10px',
        },
        benefitList: { display: 'flex' },
        checkIconWithBenefits: {
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
        },
        leftBenefits: { width: '50%' },
        rightBenefits: {
            marginLeft: '13px',
            width: '45%',
        },
    }),
}));

interface IEnterpriseUpgrade {
    organizationName: string;
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    country: string;
    state?: string;
}

export interface IEnterpriseUpgradeDto extends IEnterpriseUpgrade {
    businessEmail: string;
    userId: string;
    accountUrl: string;
    userLanguage: string;
    accountCreatedDate: string;
}

const UpgradeToEnterpriseDialogComponent: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const { translatedCountries: countries } = useTranslatedCountries();
    const setErrorMessage = useCentralErrorSetter();
    const location = useLocation();

    const { getErrorMessage } = useGetErrorInfo();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();

    const profileState = useSelector(profile);
    const currentAccountLanguage = useLocalization();
    const logicalName = useSelector(accountLogicalName);
    const accountCreatedDate = useSelector(accountCreatedOn).substring(0, 10);
    const userId = useSelector(userGlobalId);
    const accountGUID = useSelector(accountGlobalId);

    const [ showRequesting, setShowRequesting ] = useState(false);
    const [ showSuccess, setShowSuccess ] = useState(false);

    const organizationNameRef = useRef<HTMLInputElement | null>(null);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const {
        control, errors, handleSubmit, setError, watch, setValue, trigger,
    } = useForm<IEnterpriseUpgrade>({
        mode: 'onSubmit',
        defaultValues: {
            organizationName: profileState.accountUserDto.companyName ?? '',
            firstName: profileState.accountUserDto.firstName ?? '',
            lastName: profileState.accountUserDto.lastName ?? '',
            email: profileState.emailId ?? '',
            jobTitle: '',
            country: profileState.accountUserDto.country ?? '',
            state: '',
        },
    });

    const { triggerPortalShellRefresh } = useTriggerPortalShellRefresh();

    useEffect(() => {
        if (EnableDnBForms) {
            const DnB = getDnBObject('upgrade-to-enterprise-form', 'email', 'organizationName');
            DnB?.attach();
        }
    }, []);

    const states = useMemo(() => (stateInfo as Record<string, string[]>)[watch('country')], [ watch ]);

    useEffect(() => {
        const currentState = watch('state');
        if (states && currentState && !states.includes(currentState)) {
            setValue('state', '');
            trigger('state');
        }
    }, [ watch, states, setValue, trigger ]);

    const closeDirectRoute = useMemo(() => {
        if (location.pathname.includes('resourceCenter')) {
            return RouteNames.ResourceCenter;
        }
        return location.pathname.includes('licensing') ? RouteNames.Licensing : RouteNames.Services;
    }, [ location.pathname ]);

    const close = useCallback(() => history.push(`${getRoute(closeDirectRoute)}`), [ history, getRoute, closeDirectRoute ]);
    const organizationNameCallBack = useCallback(
        (currentValue?: string) => {
            if (currentValue && currentValue !== watch('organizationName')) {
                setValue('organizationName', currentValue);
            }
        },
        [ watch, setValue ],
    );

    const submit = useCallback(
        async (data: IEnterpriseUpgrade) => {
            setShowRequesting(true);

            const payload = {
                organizationName: organizationNameRef.current?.value.trim() || data.organizationName.trim(),
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                businessEmail: data.email.trim(),
                jobTitle: data.jobTitle.trim(),
                country: data.country?.trim(),
                state: states && data.state && states.indexOf(data.state?.trim()) > -1 ? data.state?.trim() : undefined,
                userId: profileState.userGlobalId,
                email: profileState.emailId,
                accountUrl: `${window.location.origin}/${logicalName}`,
                userLanguage: currentAccountLanguage,
                accountCreatedDate: accountCreatedDate,
            };

            if (!(await validateEmailBlacklist(payload.businessEmail))) {
                setError('email', { type: 'invalidDomain' });
                setShowRequesting(false);
                return;
            }

            try {
                if (profileState.accountRoleType === UserRole.ACCOUNT_ADMIN) {
                    await upgradeToTrial(userId, accountGUID, payload);
                    triggerPortalShellRefresh();
                    setUserProfile({
                        ...profileState,
                        accountUserDto: {
                            ...profileState.accountUserDto,
                            accountType: 'TRIAL',
                        },
                    });
                    refreshAfterComplete('', true);
                    setShowSuccess(true);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_FORBIDDEN_ERROR_MESSAGE' }));
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                setShowRequesting(false);
            }
        },
        [
            states,
            profileState,
            logicalName,
            currentAccountLanguage,
            setError,
            userId,
            accountGUID,
            setErrorMessage,
            translate,
            accountCreatedDate,
            getErrorMessage,
            refreshAfterComplete,
        ],
    );

    return (
        <UiDialog
            title={showSuccess ? translate({ id: 'CLIENT_THANK_YOU' }) : translate({ id: 'CLIENT_UPGRADE_ENTERPRISE' })}
            icon={
                showSuccess ? (
                    <CheckIcon className={classes.successIcon} />
                ) : EnableShowEnterpriseBenefitsPanel ? (
                    <UpgradeOrangeIcon className={classes.iconAdjustments} />
                ) : null
            }
            bodyActions={true}
            close={close}
            width={showSuccess ? undefined : '590px'}
        >
            {showSuccess ? (
                <Typography
                    className={classes.successText}
                    data-cy="upgrade-to-enterprise-success-text">
                    {translate({ id: 'CLIENT_SUCCESS_MESSAGE_UPGRADE_TO_ENTERPRISE' })}
                </Typography>
            ) : (
                <>
                    {EnableShowEnterpriseBenefitsPanel ? (
                        <div className={classes.trialNudgeBox}>
                            <Typography
                                className={classes.trialNudgeBoxTitle}
                                data-cy="sign-up-for-free-trial-title">
                                <FormattedMessage
                                    id="CLIENT_SIGN_UP_FOR_FREE_TRIAL"
                                    values={{
                                        red: (chunk: string) => <strong className={classes.sixtyDaysFreeTrial}>
                                            {chunk}
                                        </strong>,
                                    }}
                                />
                            </Typography>
                            <div
                                className={classes.benefitList}
                                data-cy="enterprise-trial-benefits">
                                {Object.entries(EnterpriseBenefitStringIds).map(([ part, benefits ]) => (
                                    <>
                                        <div className={part === 'leftPart' ? classes.leftBenefits : classes.rightBenefits}>
                                            {benefits.map(benefit => (
                                                <>
                                                    <Typography className={classes.checkIconWithBenefits}>
                                                        <CheckIcon className={classes.checkIcon} />
                                                        {translate({ id: benefit })}
                                                    </Typography>
                                                </>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <Typography
                            className={clsx(classes.subText)}
                            data-cy="sign-up-for-free-trial-title">
                            {translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_SUBHEADING' })}
                        </Typography>
                    )}
                    <form
                        id="upgrade-to-enterprise-form"
                        onSubmit={handleSubmit(submit)}>
                        <div className={clsx(classes.groupFields)}>
                            <Controller
                                as={TextField}
                                control={control}
                                className={clsx(classes.groupInputField)}
                                style={{ marginRight: '8px' }}
                                variant="outlined"
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    validate: p => !!p.trim(),
                                }}
                                id="firstName"
                                label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_FIRSTNAME' })}
                                name="firstName"
                                disabled={showRequesting}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                InputProps={{ className: 'Tall' }}
                                data-cy="upgrade-to-enterprise-modal-first-name"
                            />
                            <Controller
                                as={TextField}
                                control={control}
                                className={clsx(classes.groupInputField)}
                                variant="outlined"
                                rules={{
                                    required: true,
                                    minLength: 1,
                                    validate: p => !!p.trim(),
                                }}
                                id="lastName"
                                label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_LASTNAME' })}
                                name="lastName"
                                disabled={showRequesting}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                InputProps={{ className: 'Tall' }}
                                data-cy="upgrade-to-enterprise-modal-last-name"
                            />
                        </div>
                        <Controller
                            as={TextField}
                            control={control}
                            variant="outlined"
                            rules={{
                                required: true,
                                validate: { invalid: value => emailValidator.validate(value) },
                            }}
                            id="email"
                            name="email"
                            label={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_EMAIL' })}
                            disabled={showRequesting}
                            error={!!errors.email}
                            helperText={
                                (errors.email?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                    || (errors.email?.type === 'invalid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' }))
                                    || (errors.email?.type === 'invalidDomain' && translate({ id: 'CLIENT_INVALID_EMAIL_DOMAIN_ERROR' }))
                            }
                            InputProps={{
                                className: 'Tall',
                                endAdornment: errors.email?.type === 'invalidDomain' && (
                                    <InputAdornment position="end">
                                        <ErrorIcon color="error" />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            data-cy="upgrade-to-enterprise-modal-email"
                        />
                        <Controller
                            as={TextField}
                            control={control}
                            className={clsx(classes.groupInputField)}
                            variant="outlined"
                            rules={{
                                required: true,
                                minLength: 1,
                                validate: p => !!p.trim(),
                            }}
                            id="jobTitle"
                            label={translate({ id: 'CLIENT_JOB_TITLE' })}
                            name="jobTitle"
                            disabled={showRequesting}
                            error={!!errors.jobTitle}
                            helperText={errors.jobTitle?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            InputProps={{ className: 'Tall' }}
                            fullWidth
                            data-cy="upgrade-to-enterprise-modal-job-title"
                        />
                        <UiSelect
                            control={control}
                            name="country"
                            isTranslated
                            inputLabel={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_COUNTRY' })}
                            options={countries}
                            defaultValue={profileState.accountUserDto.country}
                            required
                            fullWidth
                            dataCy="upgrade-to-enterprise-modal-country"
                            error={!!errors.country}
                            helperText={errors.country?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            disabled={showRequesting}
                        />
                        {states && (
                            <UiSelect
                                control={control}
                                name="state"
                                inputLabel={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_STATE' })}
                                options={states}
                                required
                                fullWidth
                                dataCy="upgrade-to-enterprise-modal-state"
                                error={!!errors.state}
                                helperText={errors.state?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                disabled={showRequesting}
                            />
                        )}
                        <Controller
                            as={TextField}
                            control={control}
                            rules={{
                                required: true,
                                minLength: 1,
                                validate: p => !!p.trim(),
                            }}
                            variant="outlined"
                            id="organizationName"
                            label={translate({ id: 'CLIENT_ORGANIZATION_NAME' })}
                            name="organizationName"
                            disabled={showRequesting}
                            error={!!errors.organizationName}
                            helperText={
                                errors.organizationName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                            }
                            fullWidth
                            InputProps={{
                                className: 'Tall',
                                onFocus: (event: any) => organizationNameCallBack(event.target?.value),
                                inputProps: {
                                    ref: organizationNameRef,
                                    autocomplete: 'off',
                                },
                            }}
                            data-cy="upgrade-to-enterprise-modal-company"
                        />

                        <div className={clsx(classes.subText, classes.footerText)}>
                            <FormattedMessage
                                id="CLIENT_UPGRADE_TO_ENTERPRISE_TERMS_AND_CONDITIONS"
                                values={{
                                    a: (msg: any) =>
                                        (
                                            <a
                                                className={classes.a}
                                                href={
                                                    currentAccountLanguage === 'ja'
                                                        ? 'https://www.uipath.com/ja/legal/terms-of-use'
                                                        : 'https://www.uipath.com/legal/terms-of-use'
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {msg}
                                            </a>
                                        ) as any,
                                }}
                            />
                        </div>
                        <div
                            className={classes.actions}
                            onClick={() => organizationNameCallBack(organizationNameRef.current?.value)}
                        >
                            <Button
                                key="cancel"
                                className={classes.cancelButton}
                                onClick={close}
                                data-cy="upgrade-to-enterprise-cancel"
                                color="primary"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                key="request"
                                data-cy="upgrade-to-enterprise-submit"
                                loading={showRequesting}
                                onClick={() => {
                                    portalTelemetry.trackTrace({
                                        message: `submit request enterprise trial form with account name ${logicalName}`,
                                        severityLevel: SeverityLevel.Information,
                                    }, { revampEnabled: isAdminRevampEnabled });
                                }}
                                type="submit"
                                variant="contained"
                            >
                                {translate({ id: 'CLIENT_SEND_REQUEST_TO_FREE_TRIAL' })}
                            </UiProgressButton>
                        </div>
                    </form>
                </>
            )}
        </UiDialog>
    );
};

export default UpgradeToEnterpriseDialogComponent;
