import { IPortalUser } from '@experiences/interfaces';
import _ from 'lodash';

import { IAccountUserDto } from '../../component/users/interfaces/accountUser';
import store from '../index';
import {
    FETCH_PROFILE_SUCCESS,
    UPDATE_ACCOUNT_USER,
    UPDATE_USER_GROUPS,
} from './ActionTypes';

const receiveUserProfile = (newProfile: IPortalUser) => {
    return {
        type: FETCH_PROFILE_SUCCESS,
        profile: newProfile,
    };
};

const updateUserGroupsDispatch = (groups: string[]) => {
    return {
        type: UPDATE_USER_GROUPS,
        profile: {
            ...store.getState().profile,
            groups,
        },
    };
};

const updateAccountUser = (accountUserDto: IAccountUserDto) => {
    return {
        type: UPDATE_ACCOUNT_USER,
        profile: {
            ...store.getState().profile,
            accountUserDto,
        },
    };
};

export async function setUserProfile(profile: IPortalUser) {
    store.dispatch(receiveUserProfile(profile));
}

export async function updateUserGroups(type: 'create' | 'delete' | 'update', groupIds: string[]) {
    const groups = store.getState().profile.groups as string[];
    const groupsToUpdate =
    type === 'create' ? groups.concat(...groupIds) : type === 'delete' ? _.difference(groups, groupIds) : groupIds;
    store.dispatch(updateUserGroupsDispatch(groupsToUpdate));
}

export function setUnlicensedState() {
    return (dispatch: (arg0: { type: string; profile: IPortalUser }) => void) => {
        const oldAccountUser = store.getState().profile.accountUserDto as IAccountUserDto;
        const accountUserDto = {
            ...oldAccountUser,
            accountType: 'UNLICENSED',
            licenseCode: 'Unlicensed',
            licenseType: '',
        } as IAccountUserDto;

        dispatch(updateAccountUser(accountUserDto));
    };
}
