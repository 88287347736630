export async function parseFile(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => {
            const content = event.target?.result as string;
            resolve(content);
        };

        reader.onerror = () => {
            reader.abort();
            reject('file parse failed');
        };

        reader.readAsText(file);
    });
}
