import { portalTelemetry } from '@experiences/telemetry';
import {
    post,
    useAuthContext,
} from '@experiences/util';
import { SeverityLevel } from 'applicationinsights/out/Declarations/Contracts';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
    accountGlobalId,
    accountType,
    profile,
    userGlobalId,
} from '../../store/selectors';

export const telemetryUrl = '/api/telemetry';
export const useTelemetry = () => {
    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);
    const currentProfile = useSelector(profile);
    const {
        claims, token,
    } = useAuthContext();
    const email = currentProfile ? currentProfile.emailId : claims?.email;

    return useCallback(
        async (name: string, details: any = {}) => {
            const logEventUrl = `${telemetryUrl}/logCustomEvent`;
            try {
                await post(logEventUrl, {
                    body: {
                        name: name,
                        properties: {
                            ApplicationName: 'UiPath.AutomationCloud',
                            CloudOrganizationId: accountId,
                            CloudUserId: userId,
                            SubscriptionType: subscriptionType,
                            IsTestAccount: !!email?.endsWith('uipath-qa.com'),
                            ...details,
                        },
                    },
                    accessToken: token,
                });
            } catch (e) {
                portalTelemetry.trackTrace({
                    message: `Error tracking ecommerce event ${name}`,
                    severityLevel: SeverityLevel.Error,
                });
            }
        },
        [ accountId, userId, subscriptionType, email, token ],
    );
};
