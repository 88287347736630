import {
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            padding: '0px',
            marginLeft: '-8px',
            marginRight: '-8px',
            backgroundColor: 'unset',
            maxWidth: '800px',
            marginBottom: '24px',
        },
        optionalLabel: {
            fontSize: '10px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const SecuritySettingsSAMLFormStepper: React.FC<{ activeStep: number }> = ({ activeStep }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const steps = useMemo(
        () => [
            { label: 'CLIENT_GENERAL_DETAILS' },
            { label: 'CLIENT_PROVISIONING_SETTINGS' },
            {
                label: 'CLIENT_ADVANCED_DETAILS',
                isOptional: true,
            },
        ],
        [],
    );

    return (
        <Stepper
            data-cy='saml-form-stepper'
            activeStep={activeStep}
            className={classes.root}>
            {steps.map((step, index) => {
                return (
                    <Step key={index}>
                        <StepLabel
                            optional={
                                step.isOptional ? (
                                    <Typography className={classes.optionalLabel}>
                                        {translate({ id: 'CLIENT_OPTIONAL' })}
                                    </Typography>
                                ) : undefined
                            }
                        >
                            {translate({ id: step.label })}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default SecuritySettingsSAMLFormStepper;
