import { useLocalization } from '@experiences/locales';
import {
    CircularProgress,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import { getPriceString } from '../helpers/EcommerceHelpers';

const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        circularLoading: { borderRadius: '30px' },
    }),
);

export const EcommercePrice: React.FC<{
    value?: number;
    loading?: boolean;
    fontSize?: string;
    fontWeight?: number;
    gap?: boolean;
    currency: string;
    billingPeriod?: string;
    dataCy?: string;
}> = ({
    value,
    loading,
    fontSize,
    fontWeight,
    gap,
    currency,
    billingPeriod,
    dataCy,
}) => {
    const classes = useStyles();
    const language = useLocalization();
    const theme = useTheme();

    const { formatMessage: translate } = useIntl();
    const style = {
        fontSize: fontSize ?? '14px',
        fontWeight,
        color: theme.palette.semantic.colorForeground,
    };

    return (
        <div
            className={classes.row}
            data-cy={dataCy}>
            {loading ? (
                <CircularProgress
                    size={16}
                    className={classes.circularLoading} />
            ) : (
                <Typography style={style}>
                    {getPriceString(value!, currency, language)}
                </Typography>
            )}
            {billingPeriod && (
                <>
                    <Typography
                        style={{
                            ...style,
                            margin: `0px ${gap ? '4px' : '0px'}`,
                        }}
                    >
                /
                    </Typography>
                    <Typography style={style}>
                        {translate({ id: `CLIENT_PER_${billingPeriod.toUpperCase()}` })}
                    </Typography>
                </>
            )}
        </div>
    );
};
