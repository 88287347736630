export const getDnBObject = (formName: string, emailField: string, organizationNameField: string) => {
    let DnB: any = null;

    if ((window as any).DnBLeadForm) {
        DnB = new (window as any).DnBLeadForm({
            // Replace all @PLACEHOLDERS@ with appropriate values, including @ symbols
            visitorIntelligenceApiKey: 'vff4839',
            defaultCompanyCountry: 'US',
            // This should always be an ID attribute
            leadFormName: formName,
            // The following three fields must exist, but can be hidden
            companyCountrySearchFieldName: 'country',
            contactEmailSearchFieldName: emailField,
            companyNameSearchFieldName: organizationNameField,

            useLIDropdowns: true,
            visitorIDEnabled: false,
            clearFieldsIfNoEmailSearchMatch: false,
            clearCompanyAfterCountryChange: false,
            // Determines mapping type; eligible values are "name" or "id", including quotation marks
            attributeForFieldLookup: 'name',
            // Leave as-is to disable automatic focus-stealing, or declare a field name in lieu of ""
            initialFocusFieldName: '',
            dunsFieldName: 'DNBoptimizer__DNB_D_U_N_S_Number__c',
            companyNameFieldName: organizationNameField,

            cityFieldName: 'DnB_City__c',
            stateFieldName: 'DnB_State_Province__c',
            countryFieldName: 'DnB_Country__c',

            parentDunsFieldName: 'DNBoptimizer__DNB_Parent_DUNS__c',
            parentPrimaryNameFieldName: 'DNBoptimizer__DNB_Parent_Name__c',

            domainFieldName: 'D_B_Domain__c',
        });
    }

    return DnB;
};

